import React from 'react';
import { useTranslation } from 'react-i18next';

import { PerkStyle } from './styles';

import Paragraph from '../../../../components/Paragraph';
import LoadImage from '../../../../components/common/LoadImage';

const index = ({ txt, type, planType }) => {
  const { t } = useTranslation('subscriptions');

  return (
    <PerkStyle planType={planType} type={type}>
      {type === 'red'
        ? (<img src={LoadImage('red-x-icon.svg')} alt="perkIcon" />)
        : (type === 'blue' ? <img src={LoadImage('black-line.svg')} alt="perkIcon" />
          : (planType === 'premium' ? <img src={LoadImage('checkmark-white.svg')} alt="perkIcon" />
            : <img src={LoadImage('checkmark.svg')} alt="perkIcon" />))}
      <Paragraph className="perkText">{t(txt)}</Paragraph>
    </PerkStyle>
  );
};

export default index;
