import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearClubInfoData, fetchClub } from '../reducers';

import {
  ClubAvatar,
  ClubCover,
  ClubHeader,
  ClubLocation,
  ClubName,
  Container,
  ContainSides,
} from './styles';

import ContentLoaderHandler from '../../../components/ContentLoader';
import LoadImage from '../../../components/common/LoadImage';

import { Contain } from '../../../components/Collection';

import SocialMediaButtonsComponent from './components/SocialMediaButtons';
import RightSideComponent from './components/RightSide';
import LeftSideComponent from './components/LeftSide';
import { Paragraph } from '../../../components';
import ExclusiveOffer from './components/ExclusiveOffer';

const images = require.context('../../../assets/images', true);

const ClubInfo = () => {
  const { id: clubId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation('clubs');
  const { data: clubInfo, status } = useSelector((state) => state.clubs?.info);

  useEffect(() => {
    if (clubId !== clubInfo?.id) {
      if (clubInfo?.id) {
        dispatch(clearClubInfoData());
      }
      dispatch(fetchClub(clubId));
    }
  }, [clubId]);

  if (status === 'loading' || (status === 'idle' && !clubInfo)) {
    return (
      <Contain margin="250px auto" width="max-content">
        <ContentLoaderHandler title={false} />
      </Contain>
    );
  }

  if (status === 'failed') {
    return (
      <Contain margin="250px auto" width="max-content">
        <Paragraph>
          <Trans ns="clubs" i18nKey="failedToFetchData">
            Failed to fetch club data.
          </Trans>
        </Paragraph>
      </Contain>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>
          {t('seoTitle', {
            club: clubInfo?.clubName,
            location: `${clubInfo?.location?.city}, ${clubInfo?.location?.country}`,
          })}
        </title>
      </Helmet>

      <ClubHeader>
        {clubInfo?.clubId && (
          <ClubCover
            src={LoadImage(
              `clubs/${clubId}/${clubInfo?.coverImage}`,
              true,
              'cover',
            )}
            // eslint-disable-next-line no-param-reassign
            onError={(e) => (e.target.src = images(
                `./placeholders/placeholder-image-cover.svg`,
            ).default)}
          />
        )}
        {clubInfo?.clubId && (
          <ClubAvatar
            src={LoadImage(
              `clubs/${clubId}/${clubInfo?.logoImage}`,
              true,
              'logo',
            )}
            // eslint-disable-next-line no-param-reassign
            onError={(e) => (e.target.src = images(
                `./placeholders/placeholder-image-logo.svg`,
            ).default)}
          />
        )}
      </ClubHeader>

      <ClubName>
        {clubInfo?.clubName}
        {clubInfo?.partnerClub && (
          <img
            src={LoadImage('clubs/sportya-partner-club.svg')}
            alt="Partner Club"
          />
        )}
      </ClubName>

      {clubInfo?.location && (
        <>
          <ClubLocation>
            <img src={LoadImage('location-icon.svg')} alt="" />
            {clubInfo?.location?.name}
          </ClubLocation>
          <ClubLocation small>
            {`${clubInfo?.location?.city}, ${clubInfo?.location?.country}`}
          </ClubLocation>
        </>
      )}

      <ExclusiveOffer clubInfo={clubInfo} className="hidden-desktop-sm mt20" />

      <SocialMediaButtonsComponent clubInfo={clubInfo} />

      <ContainSides>
        <LeftSideComponent clubInfo={clubInfo} clubId={clubId} />
        <RightSideComponent clubInfo={clubInfo} />
      </ContainSides>
    </Container>
  );
};

export default ClubInfo;
