import { isEmpty } from 'lodash';

const isRegisteredToCompetition = (userId, competitionInfo) => {
  const competitionLists = [
    competitionInfo?.mainList,
    competitionInfo?.waitingList,
    competitionInfo?.preRegistrationList];
  // eslint-disable-next-line array-callback-return
  return !isEmpty(competitionLists.map((competition) => {
    if (typeof competition === 'object') {
      return competition?.filter(v => v?.status === 'CONFIRMED'
        && (v?.userId === Number(userId) || v?.partnerId === Number(userId)));
    }
  }).filter((index) => !isEmpty(index)));
};

export default isRegisteredToCompetition;
