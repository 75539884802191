export default {
  title: 'Влез в акаунта си',
  subTitle: 'Добре дошъл',
  seoTitle: 'Влизане | Sportya',
  placeholders: {
    email: 'Email',
    password: 'Парола',
  },
  forgotPassword: 'Забравена парола?',
  remember: 'Запомни',
  button: 'Влез',
  socialSignTitle: 'Или влезте с',
  noAccount: 'Все още нямате акаунт?',
  signUp: 'Регистрирай се',
  termsAndConditions: 'С влизането си потвърждавам, че съм прочел и съм съгласен с <1> Общите условия</1> Sportya и <1>Политика за поверителност</1>',
  form: {
    AUTH_FAILED: 'Невалидни данни..',
    something_wrong_happened: 'Възникна грешка.',
  },
};
