import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div(props => ({
  height: '40px',
  padding: '0 16px',
  borderRadius: '75px',
  margin: '0 0 0 10px',
  width: 'max-content',
  lineHeight: '40px',
  background: props.active ? props.theme.colors.primary : '#EAF6F6',
  color: props.active ? '#FFFFFF' : 'inherit',
  whiteSpace: 'nowrap',
  ...props.margin && {
    margin: props.margin,
  },
  '@media (max-width: 880px)': {
    margin: '0 10px 10px 0',
  },
  '&:hover': {
    background: props.theme.colors.primary,
    color: '#FFFFFF',
    cursor: 'pointer',
  },
}));

const FilterButton = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
);

export default FilterButton;
