import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { H3, P } from '../../../../../../components/Collection';
import LoadImage from '../../../../../../components/common/LoadImage';
import {
  AccountType, AccountTypePopup, Avatar as AvatarComponent, AvatarContainer, CameraIcon, ChangePhoto,
} from './styles';

/**
* Avatar component that renders an avatar with
* the user's profile picture or initial letter of their first and last name.
* @param {object} props - The props of the component
* @param {object} props.user - The user object containing the user's information
* @param {string} props.user.userId - The user's id
* @param {string} props.user.profilePicture - The user's profile picture
* @param {string} props.user.firstName - The user's first name
* @param {string} props.user.lastName - The user's last name
* @param {string} props.user.membership.plan - The user's membership plan
* @param {boolean} props.isMyProfile - Indicates whether the profile being viewed is the current user's profile
* @returns {JSX.Element} - The JSX that renders the avatar
*/

const Avatar = ({ user, isMyProfile }) => {
  const history = useHistory();
  const {
    userId, profilePicture, firstName, lastName, membership: { plan },
  } = user || {};
  const nameInitials = `${(firstName.charAt(0) + lastName.charAt(0)).toUpperCase()}`;

  const iconsAccount = {
    premium: 'premium-account.svg',
    free: 'free-account.svg',
  };

  return (
    <AvatarContainer>
      <AvatarComponent
        isMyProfile={isMyProfile}
        {...isMyProfile && { onClick: () => history.push('/my-account/profile-picture/editCurrentPhoto') }}
        bg={profilePicture ? LoadImage(`accounts/${userId}/${profilePicture}`, true) : null}
      >
        {isMyProfile && (
          <ChangePhoto>
            <CameraIcon src={LoadImage('dslr-camera.svg')} alt="Change Photo" />
            <P bold color="white">
              <Trans ns="player" i18nKey="changePhoto">
                Change Photo
              </Trans>
            </P>
          </ChangePhoto>
        )}
        {!profilePicture && (<H3>{nameInitials}</H3>)}
      </AvatarComponent>
      <AccountType bg={LoadImage(`icons/${iconsAccount[plan]}`)}>
        <AccountTypePopup>
          <Trans ns="settings" i18nKey={`profileSettings.playerDetails.accountMembership.${plan}`}>
            Account Type
          </Trans>
        </AccountTypePopup>
      </AccountType>
    </AvatarContainer>
  );
};

export default Avatar;
