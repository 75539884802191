import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadImage from '../../../../common/LoadImage';
import { Contain, P } from '../../../../Collection';
import Button from '../../../../Button';
import Link from '../../../../Link';
import { setDisplayNewsHeaderBanner } from '../../../reducers/settings';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  gap: 20,
  background: 'linear-gradient(91deg, rgba(0,145,127,1) 0%, rgba(0,64,94,1) 100%)',
  overflow: 'auto',
  padding: "0 50px",
  span: {
    fontWeight: 'bold',
  },
  a: {
    textDecoration: 'underline',
  },

  '@media (max-width: 767px)': {
    padding: "0 50px 0 15px",
  },

  '@media (max-width: 479px)': {
    flexDirection: 'column',
    gap: 5,
    padding: "10px 15px",
    height: "auto",

    p: {
      width: 'auto',
    },

    ".close-icon": {
      top: 10,
      right: 10,
    },
  },
});

const NewsBanner = () => {
  const dispatch = useDispatch();
  const { data: { newsHeaderBanner }, displayNewsHeaderBanner } = useSelector(state => state.settings);
  const seenBanner = localStorage.getItem(newsHeaderBanner.key);

  const closeBanner = () => {
    localStorage.setItem(newsHeaderBanner.key, 'seen');
    dispatch(setDisplayNewsHeaderBanner(false));
  };

  if (seenBanner === 'seen' || !displayNewsHeaderBanner || !newsHeaderBanner.active) {
    return (<></>);
  }

  return (
    <Container>
      <img src={LoadImage('megafone.svg')} alt="" width={24} height={24} className="img-megafone" />
      <P color="white" textAlign="center" width="max-content">
        <Trans ns="common" i18nKey={newsHeaderBanner.key}>
          <strong>BIG NEWS!</strong> You can now SEARCH and BOOK a court in Sportya Partner Clubs
        </Trans>
      </P>
      <Button smaller transparent>
        <Link to="/clubs" className="text-decoration-none">
          <Trans ns="common" i18nKey="bookNow">Book Now</Trans>
        </Link>
      </Button>
      {newsHeaderBanner.close && (
        <Contain onClick={closeBanner} className="close-icon cursor-pointer p5" position="absolute" right={15}>
          <img src={LoadImage('close-white-icon.svg')} alt="" width={16} height={16} />
        </Contain>
      )}
    </Container>
  );
};

export default NewsBanner;
