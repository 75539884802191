import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { P } from '../../Collection';
import { EventData } from '../styles';

export const PopupContainer = styled.li({
  display: 'inline-block',
  marginRight: '15px',
  position: 'relative',
  '&:hover > .popup': {
    visibility: 'visible',
  },

  '&:after': {
    content: '"•"',
    position: 'absolute',
    padding: '0 5px',
  },
  '&:last-of-type': {
    marginRight: 'unset',
  },
  '&:last-of-type:after': {
    display: 'none',
    padding: 'unset',
  },
});

const Popup = styled.div({
  position: 'absolute',
  visibility: 'hidden',
  padding: '5px 10px',
  borderRadius: '4px',
  top: '-40px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'rgb(15, 36, 44)',
  width: 'max-content',
  zIndex: 1,
  p: {
    whiteSpace: 'unset',
  },
  '@media (max-width: 670px)': {
    left: '0 !important',
    top: '-40px !important',
    transform: 'unset',
  },
});

const TextWithPopup = ({ text, popUpText }) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', afterShow);
    return () => {
      window.addEventListener('resize', afterShow);
    };
  });

  const afterShow = () => {
    const popup = tooltipRef?.current?.getBoundingClientRect();
    const overflownLeft = popup?.left < 0;
    const overflownRight = popup?.right > window?.innerWidth;
    if (overflownLeft) {
      tooltipRef?.current?.style.setProperty('transform', 'translateX(0%)');
    } else if (overflownRight) {
      tooltipRef?.current?.style.setProperty('left', '-30%');
      tooltipRef?.current?.style.setProperty('transform', 'translateX(-70%)');
    }
  };

  return (
    <PopupContainer onMouseOver={() => afterShow()}>
      <Popup className="popup" ref={tooltipRef}>
        <P xSmall color="white">{popUpText}</P>
      </Popup>

      <EventData small>{text}</EventData>

    </PopupContainer>
  );
};

export default TextWithPopup;
