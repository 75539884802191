import React from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadImage from '../../../../common/LoadImage';
import { P } from '../../../../Collection';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px',
  background: '#FF8298',
  overflow: 'auto',
  span: {
    fontWeight: 'bold',
  },
  a: {
    textDecoration: 'underline',
  },
  '@media (max-width: 457px)': {
    fontSize: '11px',
  },
});

const Stop = styled.img({
  margin: '0 12px 20px 0',
  '@media (max-width: 890px)': {
    display: 'none',
  },
});

const BannedBanner = () => {
  const { accountStatus } = useSelector(state => state.session);
  const { t: commonT } = useTranslation('common');
  const duration = accountStatus.time > 1
    ? commonT('weeks', { time: accountStatus.time })
    : commonT('singleWeek');

  return (
    <Container>
      <Stop src={LoadImage('stop-icon.svg')} />
      <P color="white" textAlign="center" width="max-content">
        <Trans ns="common" i18nKey="banned">
          <span>{{ time: duration }}</span>
          <span>{{ until: accountStatus.until }}</span>
          <a href="mailto:contact@sportya.net">contact@sportya.net</a>
        </Trans>
      </P>
    </Container>
  );
};

export default BannedBanner;
