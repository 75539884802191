import React from 'react';
import styled from '@emotion/styled';
import ContentLoader from 'react-content-loader';

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.absolute
  && `position: absolute; 
    left: 50%;
    transform: translateX(-50%);`}

  div {
    position: relative;
    width: 80px;
    height: 30px;
  }
  
  span {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #06262D;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
    &:nth-of-type(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    
    &:nth-of-type(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-of-type(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-of-type(4) {
    left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
`;

const ContentLoaderHandler = ({
  title = false, type, items, absolute,
}) => {
  switch (type) {
  case 'listCompetitions':
    return (
      <>
        {title
          && (
            <ContentLoader
              speed={2}
              width={800}
              height={50}
              viewBox="0 0 800 50"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="10" rx="3" ry="3" width="370" height="30" />
            </ContentLoader>
          )}
        {[...Array(items)].map((value, key) => (
          <ContentLoader
            key={key}
            speed={2}
            width={800}
            height={150}
            viewBox="0 0 800 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="125" y="20" rx="3" ry="3" width="250" height="15" />
            <rect x="125" y="53" rx="3" ry="3" width="350" height="25" />
            <rect x="126" y="93" rx="3" ry="3" width="400" height="20" />
            <rect x="20" y="20" rx="0" ry="0" width="80" height="95" />
          </ContentLoader>
        ))}
      </>
    );
  case 'eventsList':
    return (
      <>
        {title
          && (
            <ContentLoader
              speed={2}
              width={800}
              height={50}
              viewBox="0 0 800 50"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="10" rx="3" ry="3" width="370" height="30" />
            </ContentLoader>
          )}
        {[...Array(items)].map((value, key) => (
          <ContentLoader
            key={key}
            speed={2}
            width={900}
            height={230}
            viewBox="0 0 900 230"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="250" y="125" rx="3" ry="3" width="222" height="15" />
            <rect x="15" y="15" rx="0" ry="0" width="205" height="205" />
            <rect x="250" y="65" rx="0" ry="0" width="600" height="37" />
            <rect x="250" y="167" rx="0" ry="0" width="600" height="45" />
            <rect x="250" y="25" rx="0" ry="0" width="300" height="20" />
          </ContentLoader>
        ))}
      </>
    );
  case 'userCardGrid':
    return (
      <>
        {[...Array(items)].map((value, key) => (
          <ContentLoader
            key={key}
            speed={2}
            width={285}
            height={245}
            viewBox="0 0 285 245"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="51" y="105" rx="3" ry="3" width="178" height="14" />
            <circle cx="140" cy="54" r="38" />
            <rect x="67" y="130" rx="0" ry="0" width="146" height="13" />
            <rect x="87" y="162" rx="22" ry="22" width="101" height="37" />
            <rect x="0" y="0" rx="0" ry="0" width="285" height="1" />
            <rect x="0" y="244" rx="0" ry="245" width="285" height="1" />
            <rect x="0" y="0" rx="0" ry="0" width="1" height="245" />
            <rect x="284" y="0" rx="0" ry="0" width="1" height="245" />
          </ContentLoader>
        ))}
      </>
    );
  case 'userCardList':
    return (
      <>
        {[...Array(items)].map((value, key) => (
          <ContentLoader
            key={key}
            speed={2}
            width={601}
            height={111}
            viewBox="0 0 601 111"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            className="mb20"
          >
            <rect x="118" y="27" rx="3" ry="3" width="202" height="13" />
            <rect x="526" y="35" rx="18" ry="18" width="65" height="30" />
            <rect x="118" y="85" rx="3" ry="3" width="115" height="12" />
            <rect x="510" y="81" rx="3" ry="3" width="80" height="10" />
            <rect x="119" y="57" rx="3" ry="3" width="148" height="10" />
            <circle cx="60" cy="60" r="40" />

            <rect x="0" y="0" rx="0" ry="0" width="600" height="1" />
            <rect x="0" y="110" rx="0" ry="245" width="600" height="1" />
            <rect x="0" y="0" rx="0" ry="0" width="1" height="111" />
            <rect x="600" y="0" rx="0" ry="0" width="1" height="111" />
          </ContentLoader>
        ))}
      </>
    );
  default:
    return (
      <Loader absolute={absolute} className="content-loader">
        <div>
          <span />
          <span />
          <span />
          <span />
        </div>
      </Loader>
    );
  }
};

export default ContentLoaderHandler;
