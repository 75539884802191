/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { css, useTheme } from '@emotion/react';
import Card from '../../../components/Card';
import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import { setPassword, getAccount } from './actions';
import { store as socketStore } from '../../../components/Socket/store';

const SetPassword = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('forgotPassword');
  const { t: commonT } = useTranslation('common');
  const theme = useTheme();
  const { functions, colors } = theme;
  const { id, resetCode } = useParams();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  useEffect(() => {
    const onMount = async () => {
      const { data } = await getAccount(id, resetCode);
      const expiredCode = Math.round(
        (new Date(data?.resetPasswordExpirationDate).getTime() - new Date().getTime()) / 60000,
      ) < 0;
      if (!data || data?.resetPasswordCode !== resetCode || expiredCode) {
        addToast(t('invalidResetPasswordLink'), {
          appearance: 'error',
          autoDismiss: true,
        });

        history.push('/login');
      }
    };

    onMount();
  }, []);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        history.push('/login');
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'accounts-response',
      );
      socket.on(
        'accounts-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'accounts-response',
        );
      };
    }
  }, [socketClientId]);

  const forgotPassword = Yup.object().shape({
    rawPassword: Yup.string()
      .required(commonT('requiredField'))
      .min(8, commonT('minChars', { chars: 8 }))
      .matches(/[^A-Za-z 0-9]/g, commonT('mustContainerSpecialChars'))
      .max(30, commonT('maxChars', { chars: 30 })),
    passwordConfirmation: Yup.string()
      .required(commonT('requiredField'))
      .oneOf([Yup.ref('rawPassword'), null], commonT('passwordMustMatch')),
  });

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-top: ${functions?.toEm(30)};
        margin-bottom: ${functions?.toEm(80)};
      `}
    >
      <Card
        css={css`
          display: flex;
          justify-content: center;
          max-width: ${functions?.toEm(600)};
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: ${functions?.toEm(340)};
          `}
        >
          <h2
            css={css`
              text-align: center;
              color: ${colors?.black};
              font-size: ${functions?.toEm(38)};
              font-weight: 700;
              margin-bottom: ${functions?.toEm(5)};
            `}
          >
            {t('setPassword')}
          </h2>
          <h3
            css={css`
              text-align: center;
              color: ${colors?.black};
              font-size: ${functions?.toEm(20)};
              margin-top: ${functions?.toEm(0)};
              margin-bottom: ${functions?.toEm(40)};
              font-weight: 400;
            `}
          >
            {t('setNewPassword')}
          </h3>
          <Formik
            initialValues={{
              rawPassword: '',
              passwordConfirmation: '',
            }}
            validationSchema={forgotPassword}
            onSubmit={async (values, { setSubmitting }) => {
              await setPassword({
                id,
                resetCode,
                values,
                addToast,
                setSubmitting,
                socketClientId,
                dispatch,
                history,
                t,
              });
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form>
                <div
                  css={css`
                    margin-bottom: ${functions?.toEm(40)};
                  `}
                >
                  <Input
                    name="rawPassword"
                    placeholder={t('placeholders.password')}
                    type="password"
                    {...{ errors, touched }}
                  />
                </div>
                <div
                  css={css`
                    margin-bottom: ${functions?.toEm(40)};
                  `}
                >
                  <Input
                    name="passwordConfirmation"
                    placeholder={t('placeholders.passwordConfirmation')}
                    type="password"
                    {...{ errors, touched }}
                  />
                </div>

                <Button
                  css={css`
                    width: 100%;
                    margin: ${functions?.toEm(20)} 0;
                  `}
                  black
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('reset')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </div>
  );
};

export default SetPassword;
