export const timeUntil = (date, t, maxUnits = 'years') => {
  const seconds = Math.floor((date - new Date()) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1 && maxUnits === 'years') {
    return t('yearsUntil', { time: Math.floor(interval) });
  }

  interval = seconds / 2592000;
  if (interval > 1 && ['years', 'months'].includes(maxUnits)) {
    return t('monthsUntil', { time: Math.floor(interval) });
  }

  interval = seconds / 86400;
  if (interval > 1 && ['years', 'months', 'days'].includes(maxUnits)) {
    return t('daysUntil', { time: Math.floor(interval) });
  }

  interval = seconds / 3600;
  if (interval > 1 && ['years', 'months', 'days', 'hours'].includes(maxUnits)) {
    return t('hoursUntil', { time: Math.floor(interval) });
  }

  interval = seconds / 60;
  if (interval >= 1 && ['years', 'months', 'days', 'hours', 'minutes'].includes(maxUnits)) {
    return t('minutesUntil', { time: Math.floor(interval) });
  }

  return t('justNow');
};
