import { isEmpty } from 'lodash';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Column, Container, RanksContainer, RanksHeader,
} from './styles';
import LoadImage from '../../../../components/common/LoadImage';
import { Contain, P } from '../../../../components/Collection';
import { fetchTabData, getActiveYears, setActiveFilters } from '../../reducers';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/ContentLoader';
import Select from '../../../../components/Form/Select';
import { FiltersContainer, TabOptionsContainer } from '../Activity/styles';
import { RefPosition, TabPlaceholder } from '../components/styles';
import GameType from '../components/GameType';
import Rank from './components/Rank';
import { UserContext } from '../../index';

const defaultOptions = {
  limit: 20,
  page: 1,
};

const RankingsTab = ({ activeSports, masterSport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const [gameType, setGameType] = useState('singles');
  const [filters, setFilters] = useState({ sportType: masterSport });
  const [options, setOptions] = useState(defaultOptions);
  const user = useContext(UserContext);
  const { userId, profile } = user || {};
  const {
    data,
    status,
    activeFilters,
  } = useSelector(state => state.accounts.info.tabs[profile].rankings[gameType]);
  const years = useSelector(state => state.accounts.info.tabs[profile].years[gameType]);
  const listRef = useRef(null);

  const isLoading = generalStatus === 'loading';

  useEffect(() => {
    if (options.page > 1) {
      handlePagination({ selected: 0 });
    }
  }, [gameType]);

  useEffect(() => {
    if ((isEmpty(data) && status === 'idle') || (JSON.stringify(filters) !== JSON.stringify(activeFilters))) {
      dispatch(fetchTabData({
        userId,
        tab: 'rankings',
        gameType,
        filters,
        options,
        profile,
      }));

      dispatch(setActiveFilters({
        gameType,
        profile,
        tab: 'rankings',
        filters,
      }));
    }
  }, [status, dispatch, gameType, filters]);

  const handleYears = () => {
    dispatch(getActiveYears({
      userId,
      gameType,
      profile,
      sportType: filters.sportType,
      tab: 'rankings',
    }));
  };

  const handlePagination = (value) => {
    const { selected } = value || {};
    const selectedPage = selected + 1;

    if (options.page !== selectedPage) {
      const newOptions = {
        ...options,
        page: selectedPage,
      };

      setOptions(newOptions);

      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      return dispatch(fetchTabData({
        userId,
        tab: 'rankings',
        gameType,
        filters,
        options: newOptions,
        profile,
      }));
    }
    return null;
  };

  function handleFilters(filtersData, name) {
    const newFilters = { ...filters };
    if (!filtersData) {
      delete newFilters[name];
    } else {
      newFilters[name] = filtersData?.value;
    }

    const newOptions = {
      ...options,
      page: 1,
    };

    setFilters(newFilters);
    setOptions(newOptions);

    dispatch(setActiveFilters({
      gameType,
      profile,
      tab: 'rankings',
      filters: newFilters,
    }));

    return dispatch(fetchTabData({
      userId,
      tab: 'rankings',
      gameType,
      filters: newFilters,
      options: newOptions,
      profile,
    }));
  }

  const mapYears = () => {
    if (!isEmpty(years)) {
      const sortedYears = years?.slice().sort((a, b) => (a > b ? -1 : 1));

      return sortedYears?.map(year => ({
        label: `${year}`,
        value: `${year}`,
      }));
    }
  };

  if (isLoading && options.page === 1) {
    return (
      <Container hasLoader>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <RefPosition ref={listRef} />
      <TabOptionsContainer>
        <GameType gameType={gameType} setGameType={setGameType} />
        <FiltersContainer>
          <Select
            name="year"
            placeholder={t('allYears')}
            onChange={(year) => handleFilters(year, "year")}
            onFocus={handleYears}
            options={mapYears()}
            label={t('year')}
            isClearable
            isSearchable={false}
            lowInput
            {...filters.year && {
              labelOn: true,
              defaultValue: { label: filters.year, value: filters.year },
            }}
          />
          <Select
            name="sportType"
            placeholder={t('sports.all')}
            onChange={(e) => handleFilters(e, "sportType")}
            options={activeSports.map((sport) => ({
              label: t(`sports.${sport}`),
              value: sport,
            }))}
            label={t('form.sport', { ns: 'clubs' })}
            value={filters.sportType}
            lowInput
            width="150px"
            {...filters.sportType && {
              labelOn: true,
              value: ['all', ...activeSports]
                .filter(sport => sport === filters.sportType)
                .map((sport) => ({
                  label: t(`sports.${sport}`),
                  value: sport,
                })),
            }}
          />
        </FiltersContainer>
      </TabOptionsContainer>

      {!isEmpty(data.results) && (
        <RanksContainer>
          <RanksHeader>
            <Column maxWidth={35} flex justify="center">
              <P xSmall>
                <Trans ns="player" i18nKey="week">
                  Week
                </Trans>
              </P>
            </Column>
            <Column maxWidth={520} flex justify="center">
              <P xSmall>
                <Trans ns="player" i18nKey="calculationDate">
                  Calculation Date
                </Trans>
              </P>
            </Column>
            <Column maxWidth={520} flex justify="center">
              <P xSmall>
                <Trans ns="player" i18nKey="leaderboard">
                  Rank
                </Trans>
              </P>
            </Column>
            <Column maxWidth={35} flex justify="center">
              <P xSmall>
                <Trans ns="player" i18nKey="Move">
                  Move
                </Trans>
              </P>
            </Column>
          </RanksHeader>
          {data?.results.map((rank, key) => <Rank key={key} rank={rank} isDoubles={gameType === 'doubles'} />)}
        </RanksContainer>
      )}

      {isEmpty(data.results) && status === "succeeded" && (
        <Contain
          width="fit-content"
          align="center"
          direction="column"
          margin="40px auto"
        >
          <TabPlaceholder src={LoadImage('tab-placeholder.png')} alt="No results found" />
          <P color="#78ADAB">
            <Trans ns="common" i18nKey="noResultsFound">
              No results found
            </Trans>
          </P>
        </Contain>
      )}

      {data?.totalPages > 1 && (
        <Pagination
          pageCount={data?.totalPages || 0}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePagination}
          forcePage={options.page - 1}
        />
      )}
    </Container>
  );
};

export default RankingsTab;
