import styled from '@emotion/styled';

export const Container = styled.div({
  position: 'relative',
  width: '100%',
  padding: '0 15px',
});

export const Input = styled.input({
  width: '100%',
  height: '60px',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  padding: '20px 70px',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '23px',
  ':focus': {
    outline: 'none',
    outlineWidth: 0,
    borderColor: 'black',
    borderWidth: 2,
  },
  '&::-webkit-input-placeholder': {
    color: 'black',
  },
});

export const SearchResults = styled.div({
  position: 'absolute',
  top: '65px',
  left: 0,
  maxHeight: '319px',
  height: 'max-content',
  width: '100%',
  padding: '23px 15px',
  background: '#fff',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  zIndex: 1,
  overflow: 'auto',
});

export const ClearResults = styled.span({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  right: '25px',
  top: 'calc(50% - 0px)',
  transform: 'translate(0, -50%)',
  cursor: 'pointer',
  img: {
    marginRight: '5px',
  },
  '@media (max-width: 875px)': {
    right: 10,

    span: {
      display: 'none',
    },
  },
});

export const SearchIcon = styled.img({
  position: 'absolute',
  left: '30px',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
});
