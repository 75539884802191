import homepage from './homepage';
import login from './login';
import register from './register';
import header from './header';
import footer from './footer';
import common from './common';
import activate from './activate';
import clubs from './clubs';
import events from './events';
import forgotPassword from './forgotPassword';
import dashboard from './dashboard';
import accounts from './accounts';
import rules from './rules';
import myEvents from './myEvents';
import settings from './settings';
import player from './player';
import leaderboards from './leaderboards';
import paymentConfirmation from './paymentConfirmation';
import comingSoon from './comingSoon';
import subscriptions from './subscriptions';
import forPlayers from './forPlayers';
import notFound from './notFound';
import shop from './shop';
import campaign from './campaign';
import guide from './guide';
import conversations from './conversations';
import friends from './friends';
import friendlyMatches from './friendlyMatches';
import time from './time';

export default {
  homepage,
  login,
  register,
  campaign,
  header,
  footer,
  activate,
  common,
  clubs,
  events,
  forgotPassword,
  dashboard,
  accounts,
  rules,
  myEvents,
  settings,
  player,
  leaderboards,
  paymentConfirmation,
  subscriptions,
  comingSoon,
  forPlayers,
  notFound,
  shop,
  guide,
  conversations,
  friends,
  friendlyMatches,
  time,
};
