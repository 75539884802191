export default {
  seoTitle: 'Find an Event | Sportya',
  leaderboardSeoTitle: 'Player Rankings | Sportya',
  leaderboards: 'Rankings',
  results: 'Results',
  newEventsNearYou: 'Featured Events',
  seeAll: 'See All',
  seeEvent: 'See Event',
  seeMyEvents: 'See my Events',
  goToEvent: 'Go to Event ⟶',
  goToEvent2: 'Go to Event',
  newEvent: 'New event',
  undisputed: 'Undisputed',
  pickLocation: 'Location',
  recommendedEvents: 'Featured Events',
  announcedWithdrawal: 'Announced Withdrawal',
  penalty: 'Penalty',
  pickCategory: 'Category',
  pickYear: 'Year',
  pickMonth: 'Month',
  all: 'All',
  filter_all: 'Filters {{amount}}',
  clearAll: 'Clear All',
  wildcard: 'Wildcard',
  filters: 'Filters',
  prizes: 'Prizes',
  gameType: 'Game Type: {{ gameType }}',
  gameTypeText: 'Game Type',
  eventLevel: 'Event level',
  eventStatus: 'Event status',
  age: 'Age',
  playMode: 'Play mode',
  singles: 'Singles',
  doubles: 'Doubles',
  Singles: 'Singles',
  Doubles: 'Doubles',
  viewLocation: 'View Location',
  eventCategory: 'Category: ',
  pendingPlayer: 'Undisputed',
  eventPeriod: 'Event period: ',
  surfaceType: 'Surface type: ',
  noOfCourts: 'No. of courts: ',
  organizerName: 'Organizer: ',
  organizerPhone: 'Phone: ',
  organizerEmail: 'E-mail: ',
  tournamentDirector: 'Tournament director: ',
  refereeName: 'Referee name: ',
  supervisor: 'Sportya Representative: ',
  followEvent: 'Follow',
  unfollowEvent: 'Unfollow',
  viewAllUpdates: 'View all updates ({{ total }})',
  viewLessUpdates: 'View less updates',
  officialUpdates: 'Official Updates',
  viewCompetition: 'Go to Competition',
  competitions: 'Competitions',
  priceNotAvailable: 'Price Unavailable',
  topUpNow: 'Top-Up Now',
  allWinners: 'All Winners',
  joinNowLink: '<0>Join Now</0>',
  level: 'Level',
  champion: 'Champion',
  champions: 'Champions',
  firstServe: '1st Serve',
  league: 'League',
  finalist: 'Finalist',
  finalists: 'Finalists',
  readMore: 'Read More',
  officialSponsors: 'Official Sponsors',
  waitingList: 'Waiting List',
  mainList: 'Main List',
  acceptanceList: 'Acceptance List',
  preRegistrationList: 'Sorting List',
  sortingList: 'Sorting List',
  sortList: 'Sorting List',
  avgLevel: 'Avg Level',
  avgRank: 'Avg Rank',
  avgPoints: 'Avg Points',
  matchStart: 'Est. Match Start: <1>not earlier than {{ date }}</1>',
  restrictions: 'Restrictions',
  restrictionsDoubleLevel: 'Max. Doubles player lvl. {{ level }}',
  restrictionsTeamLevel: 'Team max. level - {{ level }}',
  confirmPayment: 'Confirm your payment',
  restrictionsGender: 'Gender {{ gender }}',
  summerAndPremium: 'Only Premium Accounts',
  gameLevel: 'Level <1>{{ level }}</1>',
  playerGameLevel: 'Game Level',
  playerRank: 'Rank',
  closed: 'Closed',
  tablePlayers: 'Draw:<1>{{ players }} Players</1>',
  tableGroups: 'Draw:<1>{{ players }} Teams</1>',
  eliminatory: 'Eliminatory',
  groups: 'Groups',
  group: 'Group {{type}}',
  eventFound: '<0>{{ totalResults }} event</0> found in <2>{{ location }}</2>',
  eventFound_tennis: '<0>{{ totalResults }} tennis event</0> found in <2>{{ location }}</2>',
  eventFound_padel: '<0>{{ totalResults }} padel event</0> found in <2>{{ location }}</2>',
  eventsFound: '<0>{{ totalResults }} events</0> found in <2>{{ location }}</2>',
  eventsFound_tennis: '<0>{{ totalResults }} tennis events</0> found in <2>{{ location }}</2>',
  eventsFound_padel: '<0>{{ totalResults }} padel events</0> found in <2>{{ location }}</2>',
  eventFoundNc: '<0>{{ totalResults }} event</0> found',
  eventFoundNc_tennis: '<0>{{ totalResults }} tennis event</0> found',
  eventFoundNc_padel: '<0>{{ totalResults }} padel event</0> found',
  eventsFoundNc: '<0>{{ totalResults }} events</0> found',
  eventsFoundNc_tennis: '<0>{{ totalResults }} tennis events</0> found',
  eventsFoundNc_padel: '<0>{{ totalResults }} padel events</0> found',
  in: 'in',
  noResults: 'No events found.',
  joinAndPay: 'Join and Pay',
  ballToBeAnnounced: 'Soon to be announced',
  proximityRadius: 'Radius',
  qualifying: 'Qualifying',
  phaseStatuses: {
    waitingList: 'Waiting List',
    mainList: 'Main List',
    sortingList: 'Sorting List',
    undefined: 'Not Available',
    upcomingCompetitions: {
      upcoming: 'UPCOMING',
      playing: 'PLAYING',
    },
    openingSoon: 'Opening Soon',
    openingSoonStartDate: 'Registrations on the {{ listType }} will open on {{ date }}',
    openingSoonEndDate: '',

    preRegistrationsOpen: 'Pre-Open',
    preRegistrationsOpenStartDate: 'Registrations on the Sorting List will close on {{ date }}',
    preRegistrationsOpenEndDate: 'Sorting will take place on {{ date }}',

    sortPending: 'Sort Pending',
    sortPendingStartDate: 'Registrations on the Main List will open on {{ date }}',
    sortPendingEndDate: '',

    registrationsOpen: 'Open',
    registrationsOpenStartDate: '',
    registrationsOpenEndDate: 'Registrations on the Main List will close on {{ date }}',

    drawPending: 'Draw Pending',
    drawPendingStartDate: '',
    drawPendingEndDate: 'Registrations still open on the Waiting List until {{ date }}',

    readyToPlay: 'Ready to Play',
    readyToPlayStartDate: '',
    readyToPlayEndDate: 'Registrations still open on the Waiting List until {{ date }}',

    playing: 'Playing',
    playingStartDate: '',
    playingEndDate: 'Registrations still open on the Waiting List until {{ date }}',

    resultsPending: 'Results Pending',
    resultsPendingStartDate: '',
    resultsPendingEndDate: '',

    ended: 'Ended',
    endedStartDate: '',
    endedEndDate: '',
  },
  registrationsOpenMainList: '<0>Main List</0>: {{ available }} slot available - Hurry Up!',
  registrationsOpenMainList_plural: '<0>Main List</0>: {{ available }} Slots available',
  registrationsOpenMainListFull: '<0>Main List</0>: Full',
  registrationsOpenMainListFull_plural: '<0>Main List</0>: Full',
  registrationClosedMainList: '<0>Main List</0>: Closed',
  registrationClosedMainList_plural: '<0>Main List</0>: Closed',
  registrationsOpenWaitingList: '<0>Waiting List</0>: Open ({{ occupied }} Slot occupied)',
  registrationsOpenWaitingList_plural: '<0>Waiting List</0>: Open ({{ occupied }} Slots occupied)',
  registrationsOpenWaitingListAll: '<0>Waiting List</0>: Open ({{ occupied }} Slots occupied)',
  registrationClosedWaitingListAll: '<0>Waiting List</0>: Closed',
  registrationClosedWaitingList: '<0>Waiting List</0>: Closed',
  preRegistrationsOpenSortList: '<0>Sorting List</0>',
  preRegistrationClosedSortList: '<0>Sorting List</0>: Closed',

  slotsAvailable_space: ' Slots Available',
  slotAvailable_space: ' Slot Available',

  slotsAvailable: ' Slots Available',
  slotAvailable: ' Slot Available',
  availableSlot: 'Available Slot',
  availableSlots: 'Available Slots',
  slotsOccupied: '{{occupiedSlots}} Slots Occupied',
  slotOccupied: '{{occupiedSlots}} Slot Occupied',

  joinCompetition: {
    seoTitle: 'Join Competition | Sportya',
  },

  eventDetails: {
    seoTitle: '{{eventName}} - Events | Sportya',
  },

  competitionDetails: {
    seoTitle: 'Level {{competitionLevel}}, {{gameType}}, {{eventName}} - Events | Sportya',
    title: 'Local League Technical Guide & FAQ',
    text: 'Sign up <1>FOR FREE</1> in a Sportya Local League and play tennis <1>WHEN YOU WANT, WHERE YOU WANT</1> with opponents of the same level. The Local League proposes, as a play format, the play in a group with several players, in a round robin format (each competes against all other members of the group), which takes place over several weeks.',
  },

  openingSoon: 'Opening Soon',
  preRegistrationsOpen: 'Pre-Registration Open',
  sortPending: 'Sort Pending',
  registrationsOpen: 'Open',
  drawPending: 'Draw Pending',
  readyToPlay: 'Ready to Play',
  playing: 'Playing',
  resultsPending: 'Results Pending',
  ended: 'Ended',
  endedStartDate: '',
  endedEndDate: '',

  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  mixt: 'Mixt',
  weekdays: 'Weekdays',
  weekend: 'Weekends',
  resetToDefault: 'Reset to default',
  apply: 'Apply',
  officialBall: 'Official Ball',
  playerPackageTitle: 'Player Package',
  playerPackage: `The standard participation package consists of:
  - two new balls for each singles match (to be returned to the organizer at the end of the match)
  - bottle of water for each player.`,
  winners: {
    title: 'Main Draw',
    subtitle: 'Winners',
    downloadPdf: 'Download PDF',
    quarterfinals: 'Quarter - Finals',
    semifinals: 'Semi - Finals',
    final: 'Final',
  },
  join: {
    matchStart: 'Est. Match Start',
    package: {
      title: 'Player Package (Free)',
      subtitle: 'The standard participation package consists of:',
      1: 'two new balls for each singles match',
      2: 'bottle of water for each player',
      return: 'At the end of the match, the balls will be returned to the organizer. The included Player Package (Free) and any additional products purchased from the Event Shop will be delivered to and can be picked up from the reception of the Club hosting the event.',
    },
    sprinten: {
      title: 'Make change happen!',
      1: 'Get involved and support paediatric physiotherapy scholarships that help dozens of children with disabilities to enjoy exercise and to have a chance at a normal life.',
      2: 'Program implemented by the ProAm Events Foundation through the SPRINTEN project.',
      donate: 'Donate now!',
    },
    duoJoin: 'Add your Doubles partner',
    searchPartner: 'Search for your partner and join this event',
    playerAdded: 'The player has been added to your team!',
    changePlayer: 'Change Player',
    payment: {
      title: 'Payment Method',
      selectMethod: 'Select Payment Method',
      virtualWallet: 'Virtual Wallet',
      creditCard: 'Credit Card',
      availableBalance: 'Available Balance',
      insufficientFunds: 'Insufficient Funds',
      negativeFunds: 'Negative Funds',
      topUpFirst: 'Please <0>Top-Up your Virtual Wallet</0> first, in order to pay with this payment method, or pay with your Credit / Debit card',
      confirmPay: 'Confirm & Pay',
      redirect: 'You will be redirected to',
    },
  },
  amountToBePaid: 'Amount to be Paid',
  remainingBalance: 'Remaining Balance',
  pleaseConfirmPaymentForEvent: 'Please confirm your final payment with your virtual wallet for this selected event.',
  summerPremiumAccountPrice: '<0>Premium Account: {{ price }} {{ currency }}</0>',
  clubEntryFee: 'Club Entry Fee: <1><2>{{ primaryClubFee }}</2></1>',
  clubEntryFee2: 'Club Entry Fee',
  premiumDiscount: 'Discount for Premium',
  getPremium: 'Get Premium',
  withdraw: 'Withdraw',
  joined: 'Joined',
  summary: 'Summary',
  bookingFee: 'Sportya Service Fee',
  premiumDiscountIncluded: '(incl. -{{ amount }} {{ currency }} Premium Discount)',
  secondGameDiscountIncluded: '(incl. -{{ amount }} {{ currency }} Discount for the 2nd Competition)',
  partnerBookingFee: 'Sportya Partner Service Fee',
  bookingFeeDiscounted: 'Sportya Service Fee (Discounted)',
  partnerBookingFeeDiscounted: 'Sportya Partner Service Fee (Discounted)',
  getPremiumDiscount: 'Get a Premium Account and pay only <1>{{ amount }} {{ currency }}</1>',
  learnMore: 'Learn more',
  participationFee: 'Club Entry Fee',
  participationFeeInfo: 'Is paid directly to organizer, on location, at the event. Organizer Club: <1>{{ clubName }}</1>.',
  yourTShirtSize: 'Your T-Shirt Size',
  sprintenDonation: 'SPRINTEN Donation',
  sprintenDonationProduct: 'SPRINTEN Project Donation: {{ amount }} {{ currency }}',
  tShirtProduct: 'T-Shirt Premium Sportya Play On {{ gender }} {{ size }}',
  shoppingBasket: 'Shopping Basket',
  couponDiscount: 'Coupon <1>Remove Coupon</1>',
  totalToBePaid: 'Total to be paid',
  inclVAT: 'incl. VAT',
  addCoupon: 'Add Coupon',
  joinLevelMissing: 'Please set your game level before joining a competition!',

  payRule: `By registering to the event, I confirm that I have read, understood and agree with <1>Sportya - Rules and Regulations</1>. I confirm that I am physically fit and I assume full responsibility for my participation. I agree my phone number to be distributed to the organizer of this event and / or, in the case of Local Leagues, to the players of the group I belong to.

  I also confirm that I have read, understood and agree with the <3>Return Policy</3>. I agree that the provision of services will begin during the 14-day withdrawal period. I acknowledge that my right of withdrawal will be forfeited after the full execution of the purchased services.`,

  learnMoreDiscount: 'Get a Discount <1>Learn More</1>',
  discountModalTitle: 'Registration Discount for 2 Competitions',
  discountModalDescription: 'Players with <1>FREE</1> accounts benefit from a discount of <3>15 RON / 6 BGN</3> when registering for 2 competitions taking place in the same Silver or Special event from Romania, respectively <5>25 RON / 10 BGN</5> for registering for 2 competitions within a Gold event from Romania or any event from Bulgaria.\n\n The discount is applied automatically at the time of registration, the condition being that the registration in the first competition, regardless of whether it is singles, doubles or team, to be validated in the system (payment made) before making the reservation in the 2nd competition.',
  change: 'Change',
  players: 'Players',
  cancel: 'Cancel',
  withdrawText1: 'Are you sure you want to withdraw from this event? \n Withdrawals from tournaments can be penalized by points or restriction of the right to register for tournaments depending on the period in which they take place and their number. Withdrawal from the waiting list is not penalized.',
  withdrawText2: 'More information on withdrawals and their consequences is available in the <1> General Regulation </1> (Chapter 5). The return of the amount related to the online order will be made according to the <3>Return Policy.</3>',
  filtersList: {
    champions: 'Champions',
    firstServe: '1st Serve',
    silver: 'Silver',
    gold: 'Gold',
    special: 'Special',
    platinum: 'Platinum',
    league: 'Local League',
  },
  sortby: 'Sort by',
  startDate: 'Start Date',
  newest: 'Newest',
  allActive: 'All Active',
  availableEntries: 'Available Entries',
  showEndedEvents: 'Ended Events',
  joinedEvents: 'My Events Only',
  reservedSlot: 'Reserved Slot /',
  underMaintenance: 'This event is Under Maintenance. Registrations are paused at the moment.',
  underMaintenanceButton: 'Under Maintenance',
  competitionsUnavailable: 'Competitions for this event will be announced soon.',
  min1event: 'Min. one event played',
  min3event: 'Min. 3 events played',
  joinNowButton: {
    ended: 'Competition Ended',
    playing: 'Playing',
    resultsPending: 'Results Pending',
    drawPending: 'Draw Pending',
    readyToPlay: 'Ready to Play',
  },
  withdrawal: {
    early: {
      title: 'EARLY Withdrawal',
      subtitle: 'Are you sure you want to withdraw from the competition?',
      desc: 'If you confirm the withdrawal, then you will lose your place on the competition list, and the Sportya Service Fee will be refunded to the Virtual Wallet of the player that submitted the registration.',
    },
    announced: {
      title: 'ANNOUNCED Withdrawal from the {{list}}',
      subtitle: 'Are you sure you want to withdraw from the competition?',
      desc: 'If you confirm the withdrawal, then you will lose your place on the competition list, and the Sportya Service Fee will be refunded to the Virtual Wallet of the player that submitted the registration.',
    },
    late: {
      title: 'LATE Withdrawal from the {{list}}',
      subtitle: 'Are you sure you want to withdraw from the competition?',
      desc: 'If you confirm the withdrawal, then you will lose your place on the competition list, and the Sportya Service Fee will NOT be refunded anymore.',
    },
    denied: {
      title: 'Online Withdrawals are not allowed anymore',
      subtitle: '',
      desc: 'At this moment, online withdrawals are not allowed anymore. You can still withdraw from this competition by contacting the Tournament Director. This allows him to mitigate the impact of your withdrawal. Please note that your Sportya Service Fee will NOT be refunded.',
    },
    disclaimer: 'Please check our <1>Sportya Rules and Regulations</1> and <3>Return Policy</3> for more information on withdrawals and refunds.',
  },
  frozenLeaderboard: 'The official rankings will be recalculated weekly starting with the 28th of December 2021. Thank you for your patience.',
  sortListOpen: 'Registrations open on the Sorting List',
  pending: 'Pending Registration',
  stages: {
    1: 'Final',
    2: 'Semifinals',
    3: 'Quarterfinals',
    4: 'Quarterfinals',
  },
  tennisNationalCalendar: 'Choose your National Tennis Calendar',
  padelNationalCalendar: 'Choose your National Padel Calendar',
  tennisQuickFindTitle: 'Quickly find the best <1>Tennis</1> event for you',
  padelQuickFindTitle: 'Quickly find the best <1>Padel</1> event for you',
  specificMonth: 'Set custom dates',
  showFeaturedEvents: 'Show Featured Events',
  hideFeaturedEvents: 'Hide Featured Events',
  newEvents: 'New Events',
  teamGameLevel: 'Team Level',
  joinNow: 'Join Now',
  restrictionsLevel: 'Max level {{ level }}',
  noEventsFound: 'No event was found.',
  onlymale: 'Only Men',
  onlyfemale: 'Only Women',
  onlymixt: 'Mixt',
  allAges: 'All ages',
  customAge: 'Choose interval',
  shirtSize: 'T-shirt size',
  lfp: {
    noPartner: 'Don\'t have a partner for Doubles yet?',
    whosInterested: 'See who\'s interested',
    findAPartner: 'Find a Partner for Doubles',
    eligible: `Before joining the list or contacting any of the players on the list please make sure you are eligible for joining this competition and check additional eligibility criteria for the team.
    
    Joining this list or contacting a potential partner for Doubles does not impose any obligation to actually join this competition. <1>Find out more</1>`,
    registrationNotRequired: 'Joining the list of players interested in finding a partner for Doubles does not impose any obligation to actually join this competition.',
    findMore: 'Find out more',
    imLooking: 'I\'m looking',
    optOut: 'Opt out',
    contact: 'Chat',
    playersLFP: '{{ total }} players looking for partners',
    playerLFP: '{{ total }} player looking for a partner',
    join: 'Join the list!',
    beFirst: 'Be the 1st one to show that you\'re looking for a partner for this Doubles competition.',
    eligibilityCriteria: `Please check the eligibility criteria for this competition before looking for a potential partner.
    
    Joining the list of players interested in finding a partner for Doubles does not impose any obligation to actually join this competition. <1>Find out more</1>`,
    iAmLFP: 'I\'m looking for a partner',
  },
  fmBanner: {
    desc: `While you're waiting for new events to be announced in your country, you can play a friendly match with your friends and fellow players on Sportya`,
  },
  goldNutritionCampaign2: `This is a Sportya & Arena Tennis event. 
  Registrations for Doubles draws are available online on <1>Arena Tennis</1>. Players can register both Singles & Doubles.`,
  arenaTennis: `This is a Sportya & Arena Tennis event. Registrations for Doubles draws are available online on <1>Arena Tennis</1>
  Players can register both Singles & Doubles.`,
};
