import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../../../components/common/http';

// Actions
export const fetchSlides = createAsyncThunk('settings/fetchSlides', async () => {
  const { data: { data } } = await get('/settings/homeSlides');
  return data;
});

// Slice
const slice = createSlice({
  name: 'slide',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchSlides.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSlides.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchSlides.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});
export default slice.reducer;
