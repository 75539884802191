import styled from '@emotion/styled';

export const Bold = styled.span({
  fontWeight: 700,
});

export const Header = styled.div(({ login, hasReferral }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100px',
  width: '100%',
  ...!hasReferral && {
    margin: `0 0 ${login ? '10px' : '20px'} 0`,
  },
  '.clubManager': {
    position: 'absolute',
    top: '30px',
    right: '70px',
    '@media (max-width: 995px)': {
      display: 'none',
    },
  },
}));

export const LanguageSelectorPosition = styled.div({
  position: 'absolute',
  top: '42px',
  right: '300px',
  '@media (max-width: 995px)': {
    right: '0px',
  },
});

export const ReferralBanner = styled.div({
  width: '100%',
  background: '#EAF6F6',
  marginBottom: 20,
  padding: 10,
  display: 'flex',
  cursor: "pointer",

  '@media (max-width: 650px)': {
    borderRadius: '10px',
    minHeight: '60px',
    height: 'unset',
    padding: '0 5px',
  },
});

export const Icon = styled.img(({ flip }) => ({
  margin: '0 15px 0 0',
  ...flip && {
    margin: '0 0 0 15px',
    transform: 'scaleX(-1)',
  },
}));

export const Country = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const Flag = styled.img({
  margin: '0 6px 0 0',
  borderRadius: '50%',
});

export const Logo = styled.img({
  width: '161px',
  height: '22px',
});

export const Container = styled.div(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 0 80px 0',
  alignItems: 'center',
  ...!props.Login && { paddingBottom: '100px' },
  '@media (max-width: 650px)': {
    margin: '0 10px',
  },
  '.clubManagerMobile': {
    position: 'absolute',
    left: '50%',
    bottom: '30px',
    width: 'max-content',
    transform: 'translateX(-50%)',
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
}));

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '600px',
  borderRadius: '10px',
  padding: '40px 125px 95px',
  border: '2px solid #EAF6F6',
  'form > div > input': {
    border: '1px solid #10242C !important',
  },
  '[class*="-control"]': {
    border: '1px solid #10242C',
  },
  '[class*="-ValueContainer"]': {
    overflow: 'visible',
  },
  'label > p': {
    margin: 'unset',
    a: {
      color: '#9ac2c0 !important',
      textDecoration: 'underline !important',
    },
  },
  '.entryForm': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
    div: {
      width: '100%',
    },
  },
  '@media (max-width: 650px)': {
    padding: '40px 20px 60px',
  },
});
