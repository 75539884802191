export default {
  chats: 'Konverzace',
  startChat: 'Začněte konverzaci',
  findPlayersAndStartConversation: 'Najděte hráče a začněte konverzovat',
  seeFriendsList: 'Viz Seznam přátel',
  disconnected: 'Odpojeno. Pokus o opětovné připojení...',
  isTyping: '{{ user }} napsat...',
  online: 'Online',
  offline: 'Offline',
  viewProfile: 'Prohlédnout profil',
  startGroupChat: 'Zahajte skupinový chat s {{ user }}',
  muteConversation: 'Ztlumit oznámení',
  unmuteConversation: 'Zapněte oznámení',
  leaveConversation: 'Ukončete konverzaci',
  deleteConversation: 'Smazat konverzaci',
  allMessagesLoaded: 'Všechny zprávy byly načteny.',
  messageDeleted: 'This message has been deleted.',
  system: 'Systém',
  deleteMessage: 'Smažte zprávu',
  typeMessage: 'Napište zprávu...',
  groupChat: 'Skupinová konverzace',
  groupMembers: 'Účastníci',
  noOfParticipants: '{{ participants }} účastníci',
  groupName: 'Vy, {{ groupNamesList }}',
  addParticipants: 'Přidejte účastníky',
  seeGroupMembers: 'Viz Účastníci',
  muteGroup: 'Ztlumit oznámení',
  unmuteGroup: 'Zapněte oznámení',
  leaveGroup: 'Ukončete konverzaci',
  deleteGroup: 'Smazat konverzaci',
  cancel: 'Storno',
  createGroup: 'Vytvořit',
  yourFriends: 'Tví přátelé',
  add: 'Přidat',
  you: 'Vy',
  leave: 'Ukončete',
  delete: 'Vymazat',
  playerBlocked: 'You’ve blocked this player',
  left: 'Left',
  openOrStartChat: 'Otevřete jednu ze stávajících <br/> konverzací nebo zahajte novou.',
  leaveConfirmationGroup: 'Opravdu chcete opustit tento <br/> skupinový chat?',
  deleteConfirmationGroup: 'Opravdu chcete smazat tento <br/> skupinový chat?',
  deleteConfirmationConversation: 'Opravdu chcete smazat <br/> tuto konverzaci?',
  USER_HAS_LEFT_CONVERSATION: '<i><strong>{{ user }}</strong> opustil rozhovor.</i>',
  PLAYER_ADDED_TO_CONVERSATION: '<strong>{{ addedBy }}</strong> přidal <i><strong>{{ user }}</strong> do konverzace.</i>',
  SUCCESSFULLY_INITIATED_CHAT: 'Konverzace byla úspěšně zahájena.',
  COULD_NOT_INITIATE_CHAT: 'Konverzaci nebylo možné zahájit. Zkuste to později.',
  SUCCESSFULLY_MUTED_CONVERSATION: 'Oznámení pro tuto konverzaci byla vypnuta.',
  SUCCESSFULLY_UNMUTED_CONVERSATION: 'Oznámení pro tuto konverzaci byla znovu povolena.',
  SUCCESSFULLY_LEFT_CONVERSATION: 'Úspěšně jste ukončili konverzaci.',
  SUCCESSFULLY_DELETED_CONVERSATION: 'Konverzace byla smazána.',
  INTERNAL_ERROR: 'Vnitřní chyba služby. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.',
  tryAgain: 'Zkus to znovu',
  failedToLoadConversations: 'Konverzace se nepodařilo načíst',
  leftGroupChat: 'V této skupinové konverzaci již nemůžete posílat zprávy, protože již nejste jejím účastníkem.',
  unreadMessages: '{{ messages }} nepřečtených zpráv',
};
