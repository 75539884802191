import React from 'react';
import styled from '@emotion/styled';

const P = styled.p`
color: ${props => ({ theme }) => (props.color ? theme?.colors?.[props.color] : theme?.colors?.primary)};
font-size: ${({ theme }) => theme?.functions?.toEm(16)};
line-height: inherit;
font-family: "DM Sans";
font-weight: ${props => () => (props.bold ? 'bold' : 'normal')};
font-size: ${props => ({ theme }) => (props.larger ? theme.functions?.toEm(24) // larger
  : (props.large ? theme.functions?.toEm(20) // large
    : (props.medium ? theme.functions?.toEm(18) // medium
      : (props.small ? theme.functions?.toEm(14) // small
        : (props.smaller ? theme.functions?.toEm(12) // smaller
          : theme.functions?.toEm(16) // normal
        )
      )
    )
  ))
};
${props => (props.position ? `position: ${props.position};` : '')}
${props => (props.right ? `right: ${props.right};` : '')}
${props => (props.left ? `left: ${props.left};` : '')}
${props => (props.top ? `top: ${props.top};` : '')}
${props => (props.bottom ? `bottom: ${props.bottom};` : '')}
${props => (props.margin ? `margin: ${props.margin};` : '5px 0')}

a {
text-decoration: underline;
color: ${({ theme }) => theme?.colors?.secondary};
}
`;

/**
 *
 * @param children
 * @param props: larger, large, medium, small, smaller, bold
 * @returns {JSX.Element}
 * @constructor
 */
const Paragraph = ({ children, ...props }) => (
  <P {...props}>
    {children}
  </P>
);

export default Paragraph;
