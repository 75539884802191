import React from 'react';
import { Trans } from "react-i18next";
import { isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import dateFormat from "dateformat";

import { Paragraph } from "../../../../../components";

const LastMessage = ({
  conversation, typingMessage, systemMessage, shortName, isBlocked,
}) => {
  if (isBlocked) {
    return (
      <div className="last-message">
        <p className="is-blocked">
          <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.youBlockedThisPlayer">
            You’ve blocked this player
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div className="last-message">
      {typingMessage?.isTyping && (
        <p className="is-typing">
          <Trans ns="conversations" i18nKey="isTyping">
            {{ user: typingMessage?.users }} typing...
          </Trans>
        </p>
      )}

      {!typingMessage?.isTyping && !isEmpty(conversation?.lastMessage) && (
        <>
          <Paragraph>
            {systemMessage && (
              <Trans ns="conversations" i18nKey={conversation.lastMessage?.message}>
                {conversation.lastMessage?.message}
                {{ user: shortName(conversation.lastMessage?.userInfo) }}
                {{ addedBy: shortName(conversation.lastMessage?.addedBy) }}
              </Trans>
            )}

            {!systemMessage && (
              conversation.lastMessage?.status === 'deleted'
                ? (
                  <i className="fw-normal">
                    <Trans ns="conversations" i18nKey="messageDeleted">This message has been deleted.</Trans>
                  </i>
                ) : (
                  CryptoJS.AES.decrypt(conversation.lastMessage?.message, conversation.id).toString(CryptoJS.enc.Utf8)
                )
            )}
          </Paragraph>
          <span>&bull;</span>
          <time>{
            dateFormat(conversation.lastMessage?.createdAt, "DDDD") === 'Today'
              ? dateFormat(conversation.lastMessage?.createdAt, "h:MM TT")
              : dateFormat(conversation.lastMessage?.createdAt, "mmm dd")
          }
          </time>
        </>
      )}
    </div>
  );
};

export default LastMessage;
