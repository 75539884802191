export default {
  title_tennis: 'Tennis Rankings',
  title_padel: 'Padel Rankings',
  seoTitle: 'Player Rankings | Sportya',
  lastUpdate: 'Last Update',
  timeframe: 'Ranking calculation timeframe',
  searchBar: 'Search {{results}} players',
  filters: {
    title: 'Filters (All)',
    modalTitle: 'Filters',
    singles: 'Singles',
    doubles: 'Doubles',
    gender: 'Gender',
    age: 'Age',
    reset: 'Reset to default',
    apply: 'Apply',
  },
  level: {
    4: 'Level 4',
    5: 'Level 5',
    6: 'Level 6',
    7: 'Level 7',
    8: 'Level 8',
    9: 'Level 9',
  },
  playerTableCard: {
    rank: 'Rank',
    player: 'Player',
    points: 'Points',
    participations: 'Events',
    elo: 'ELO Rating',
    friendlyMatches: 'Ranked Friendly Matches',
    friend: 'Friend',
    rankedFriendlyMatches: 'Ranked Friendly Matches',
    generalRank: 'General Rank',
    rankedMatches: 'Ranked Matches',
    unrankedMatches: 'Unranked Matches',
    totalMatches: 'Total Matches',
  },
  noPlayersFound: 'No players found.',
  generalRankings_tennis: 'General Tennis Rankings',
  generalRankings_padel: 'General Padel Rankings',
  friendsRankings_tennis: 'Friends Tennis Rankings',
  friendsRankings_padel: 'Friends Padel Rankings',
};
