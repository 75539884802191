import React from 'react';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import LoadImage from '../../../../components/common/LoadImage';
import LevelsBadges from '../../../../components/LevelsBadges';
import { FriendsIcon } from '../../../Friends/components/FriendCard/styles';
import RaiseHand from '../../../../components/Layout/components/Header/components/RaiseHand';

import {
  LevelBadgesContainer,
  OnlyMobileText,
  PlayerInformation,
  PlayerInformationContainer,
  PlayerInformationResponsiveContainer,
  PlayerStatsColumn,
  RaisedHandContainer,
  RelativeContainer,
  TablePlayerContainer,
} from './styles';

import AvatarComponent from '../../../../components/Misc/Avatar';
import { P, Link, Contain } from '../../../../components/Collection';

import {
  FriendlyMatchesColumn, PlayerColumn, PointsColumn, RankingColumn, RegistrationColumn,
} from '../TableCard/styles';

import { ID } from '../PlayerCard/styles';

const TablePlayer = ({ player }) => {
  const { accountInfo } = useSelector(state => state.session);
  const {
    firstName,
    lastName,
    userId,
    _id,
    birthDate,
    createdAt,
    gameLevel,
    points,
    rank,
    location,
    handRaised,
    rankedFriendlyMatches,
  } = player || {};

  const isFriend = (id) => accountInfo?.friendsIds?.includes(id);

  return (
    <TablePlayerContainer>
      <PlayerColumn>
        <PlayerInformationContainer>
          <PlayerInformationResponsiveContainer>
            <Contain>
              <RelativeContainer>
                <Link to={`/accounts/${_id}`}>
                  <AvatarComponent {...{ ...player }} />
                </Link>
                <LevelBadgesContainer>
                  <LevelsBadges
                    white
                    small
                    level={gameLevel?.singles ?? '?'}
                    levelDouble={gameLevel?.doubles ?? '?'}
                    className="badge"
                  />
                </LevelBadgesContainer>
              </RelativeContainer>
              <PlayerInformation>
                <Link to={`/accounts/${_id}`}>
                  <P bold margin="unset">
                    {isFriend(userId) && (
                      <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                    )}
                    {`${firstName || ''} ${lastName || ''}`} <ID>#{userId}</ID>
                  </P>
                </Link>

                <P small margin="unset">
                  {birthDate !== null ? `${getAgeFromDate(birthDate)} - ` : ''}
                  {location?.city ? `${location?.city}, ` : ''}
                  {location?.country ? `${location?.country}` : ''}
                </P>
              </PlayerInformation>
            </Contain>

            {!!handRaised && (
              <RaisedHandContainer>
                <RaiseHand
                  noPaddingOnMobile
                  handRaisedData={handRaised}
                  maxContent
                  interactive={false}
                />
              </RaisedHandContainer>
            )}
          </PlayerInformationResponsiveContainer>
        </PlayerInformationContainer>
      </PlayerColumn>

      <PlayerStatsColumn customMinWidthOnDesktop="320px">
        <RegistrationColumn center bold>
          <OnlyMobileText>
            <Trans ns="player" i18nKey="registrationDate">Registration Date</Trans>
          </OnlyMobileText>
          {dateFormat(createdAt, 'd mmm yyyy')}
        </RegistrationColumn>

        <FriendlyMatchesColumn center bold>
          <OnlyMobileText>
            <Trans ns="player" i18nKey="friendlyMatches">Ranked Friendly Matches</Trans>
          </OnlyMobileText>
          {rankedFriendlyMatches}
        </FriendlyMatchesColumn>
      </PlayerStatsColumn>

      <PlayerStatsColumn customMinWidthOnDesktop="220px">
        <RankingColumn center bold>
          <OnlyMobileText>
            <Trans ns="player" i18nKey="leaderboard">Leaderboard</Trans>
          </OnlyMobileText>
          {rank?.singles ?? 0}
        </RankingColumn>

        <PointsColumn center right bold>
          <OnlyMobileText>
            <Trans ns="player" i18nKey="points">Points</Trans>
          </OnlyMobileText>
          {points?.singles ?? 0}
        </PointsColumn>
      </PlayerStatsColumn>
    </TablePlayerContainer>
  );
};

export default TablePlayer;
