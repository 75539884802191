/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import Menu from './components/menu';
import { Container } from './styles';

const LocalLeague = () => {
  const { t } = useTranslation('rules');

  const menuItems = [];
  const sectionsLengths = [1, 1, 1];

  for (let j = 0; j < sectionsLengths.length; j++) {
    const sectionNr = j + 1;

    menuItems.push({
      id: `section${sectionNr}`,
      title: t(`liga_locala.section${sectionNr}.subtitle1`),
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle4')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-3 position-relative">
          <Menu menuItems={menuItems} />
        </div>
        <div className="col-sm-9">
          <H1 className="mb30">{t('liga_locala.mainTitle')}</H1>
          <P large className="mb40">{t('liga_locala.mainDescription')}</P>

          <div id="section1">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="liga_locala.section1.subtitle1">
                  CONCEPT
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="liga_locala.section1.p1">
                  SLL are of two types:
                  a) OPEN SLL;

                  b) SLL coordinated by a host club (usually, a club organizing Sportya tournaments).

                  The difference between the two types of leagues consists in the degree of support received from the clubs in terms of the coordination of the matches schedule and the progress of the Leagues. OPEN type leagues depend exclusively on the involvement of players in programming the matches with opponents and monitoring the progress of the League.

                  SLL proposes, as a play format, the play in a group with several players, in a round robin format (each competes against all other members of the group), which takes place over several weeks. A league can have one or more groups, for one or more levels of play.

                  The duration of a SLL, respectively of the matches in a group, is given by the number of stages of each (a group of N players will be played in maximum N weeks if N is odd, or (N1) weeks if N is even) . The stages are predefined and presented to the players, on the official competition page on the Sportya website, after the registrations for a League are ended.

                  Group matches can be played at any time. However, there is a limit to the timing of each match: the end of the week allocated to the respective stage, which the match is part of.

                  Registrations are made online, exclusively through the League&apos;s presentation page, in the first came, first served system. Registrations begin one week before the League&apos;s opening week at 22:00 on Monday and close on the day before the automatic draw procedure, at 20:00.

                  Communication

                  The contacts of players from a component group of a SLL will be available to all opponents enrolled in that group, as well as the host club (where applicable). The representative of the host club can mediate the communication between players. Registration in a SLL implies the default consent for the distribution of the telephone number, without which the organization of the SLL would not be possible.

                  Tennis courts / location

                  If the SLL is coordinated by a host club, the location of the club&apos;s tennis courts will be deemed as the official location, and all matches in the SLL will be associated with that location. However, players have the option to decide by mutual agreement to play the match in another location. In the event that misunderstandings arise between players regarding the determination of the location, the official location will always take precedence.

                  In the case of OPEN type SLL, the location of a match will be determined by the players, by mutual agreement. If players fail to finalize the match scheduling details, the match will be marked with WO for both players.

                  Costs/Courts rental

                  Online registration in a SLL is free.

                  The carrying out of the matches in SLL may involve renting a tennis court, which cost is not directly covered by SLL registration. The cost of the courts will be supported equally by the two players. We recommend renting an interval of 90-120 minutes per match or ensuring, as far as possible, that the period can be extended if the reserved time does not allow the completion of the match.

                  Match scheduling

                  All scheduling, regardless of the type of SLL, is determined by the mutual agreement of the opponents, each match having a deadline at the end of the week allocated to the stage which the match is part of.

                  Example: a match in the 3rd stage of a SLL can be played at any time in the first three weeks of the league, but not later than the Sunday of the third week.

                  If the SLL is coordinated by a host club, the club’s representative may mediate communication between the players for the programming of the match, but the ultimate responsibility rests with the players. If the players fail to finalize the match scheduling details, the match will be marked with WO for both players.

                  Play balls

                  The play balls are provided by the players for each match, the choice of the brand and the number of balls to be used being also determined by mutual agreement by the players. We recommend that the match be played with a minimum of 2 new balls, or 3 balls played, but in good condition and the costs involved will be shared by mutual agreement.

                  Match Format

                  We recommend that all matches be played with super tiebreak (10 points) at 1 set all. However, given the flexible nature of SLL, players can only choose to play with decisive set if both agree.

                  To level the scores, the decisive set will be considered with the score 1-0 (+1 at the game score), regardless of the super tiebreak or the decisive set score.

                  Results

                  The results will be updated online by one of the players, from the personal page on the website. We recommend that players determine who will take responsibility, namely the score reporting into the system to take place by the end of the day on which the match was played. Any changes will be requested, in due course, from the representative of the host club or by e-mail at contact@sportya.net.

                  League results / scores

                  The League results are determined after the finalization of the last stage of a League. Participation in SLL counts in the Sportya general ranking. Participation is scored with 5 points; each victory will be scored with 10 points.

                  Awards

                  The top two players in the League will be rewarded with diplomas. Any additional prizes will be indicated on the official League page.

                  Withdrawals / penalties

                  A player may give up from participating until the end of the registration period, such situation being not deemed as a withdrawal from the technical point of view.

                  The withdrawal after the closing of the registrations and until the time of the first match will be announced to the representative of the host club or by e-mail at contact@sportya.net and it will be penalized with the suspension of the right to enroll in a new League for the next 5 weeks.

                  Non-attendance (unannounced withdrawal) at a match scheduled and confirmed by the opponent may result in the elimination from the League. The decision will be made by the representative of the host club or by the Coordinator of the Sportya system, depending on the circumstances.
                </Trans>
              </P>
            </div>

            <div id="section2">
              <div>
                <P large bold className="mb40">
                  <Trans ns="rules" i18nKey="liga_locala.section2.subtitle2">
                    RESPONSIBILITY – GOOD UNDERSTANDING
                  </Trans>
                </P>
                <P large className="mb40">
                  <Trans ns="rules" i18nKey="liga_locala.section2.p2">
                    In compliance with the Sportya Rulebook, the participation in the SLL play system is made on each person’s own responsibility, both from the point of view of interaction with other players, and considering the medical/physical condition necessary for physical activities.

                    Moreover, the smooth running of a SLL depends on the degree of availability and understanding of each player, in all aspects of the League: location / schedule / play balls.

                    We recommend flexibility and availability against the unforeseen situations (last minute rescheduling, postponements, etc.). All players will need understanding, from opponents, at some point.

                    Both the players and the representatives of the host club (where appropriate) can provide the necessary feedback to maintain an atmosphere favorable for evolution within Sportya. We are looking forward to receiving your impressions at contact@sportya.net.

                  </Trans>
                </P>
              </div>
            </div>

            <div id="section3">
              <div>
                <P large bold className="mb40">
                  <Trans ns="rules" i18nKey="liga_locala.section3.subtitle3">
                    FREQUENTLY ASKED QUESTIONS
                  </Trans>
                </P>
                <P large className="mb40">
                  <Trans ns="rules" i18nKey="liga_locala.section3.p3">
                    1. What is the size of the groups?
                    The standard size will be 5 players (5 stages in 5 weeks, with 4 matches for each player), but may vary depending on several aspects. No groups smaller than 4 players will be made.

                    2. What is the structure of a League?

                    The structure is flexible. A league may consist of a single group for a single level of play, or it can reach multiple groups for multiple levels of play.

                    3. How do I communicate with the other players in my group?

                    The players&apos; phone numbers will be available to all members of a group, as well as to the host clubs / organizers. Players or club representatives can form a group on social networks (e.g., WhatsApp, Viber) for quick communication and supervision of the program.

                    4. Is it mandatory to offer the phone number to opponents?

                    Yes, as the players mutually agree on their matches; the club representative has no obligation to determine the scheduling of the matches.

                    5. What happens if the players do not agree on the location?

                    If the League is coordinated by a host club, that match will be played in that club. In the case of an OPEN type League, WO will be noted for both players.

                    6. What happens if players don&apos;t agree on the date/time?

                    If the League is coordinated by a host club, players can resort to the club representative to mediate the situation. However, there is no obligation for the representative to schedule the match (as in classic Sportya tournaments). Thus, if a common denominator is not reached, the game can also be marked with WO for both players. We recommend avoiding these problems and approaching each situation as balanced as possible. Following the feedback received, consistent with the number of WO registered, players may risk suspension for participating in other events within the Local Leagues.

                    7. What if a player asks to reschedule the match?

                    If this happens more than 24 hours before the match, the opponent must offer the chance of a rescheduling. If it happens less than 24 hours before the match, the opponent may agree to a reschedule or request to win the match via WO. We recommend players to approach each situation with maximum flexibility and understanding towards the playing partner.

                    8. How are the League final results calculated if some matches are played with a super tiebreak and others with a decisive set?

                    The official format for making the calculation is the one with super tiebreak up to 10 points (two points difference at 9-9). In this case, the super tiebreak is considered a set won with 1-0 (one game to zero; +1 for the game scoring). If a decisive set is played, it will be considered 1-0, regardless of the final score.

                    9. What happens in case of a tie in the League final results?

                    The differentiation is made following the result of the direct match. If there are three or more players with the same number of victories, the ranking is determined by taking into account in the following order: the set scoring, the game scoring, the highest number of sets / games won, considering all the matches in the group. If one of the above criteria generates a better or weaker player than the other two, a direct victory will be considered when differentiating between the two remaining players.

                    10. Are groups followed by other phases of the gameplay?

                    In the first period of the development of the Leagues, the group closes with the League final results being determined after the last stage related to group matches. Different variations of the gameplay will be considered for the future.
                  </Trans>
                </P>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LocalLeague;
