/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import LoadImage from '../common/LoadImage';
import Paragraph from '../Paragraph';
import SetRatingModal from './components/SetRatingModal';

import {
  Container,
  PopUpContainer,
  PopUp,
  PlayerProfile,
} from './styles';

const PlayerRating = ({
  ratingInfo,
  section,
  myProfile,
  user,
}) => {
  const theme = useTheme();
  const { functions } = theme;

  const [ratePlayerModal, setRatePlayerModal] = useState(false);
  let avgRating = ratingInfo?.avgRating;
  let totalRatings = ratingInfo?.totalRatings;

  if (ratingInfo?.totalRatings < 3 && ratingInfo?.givenRating) {
    avgRating = ratingInfo?.givenRating;
    totalRatings = 1;
  }

  return (
    <>
      <Container>
        {/* displayed on friendly matches cards */}
        {ratingInfo?.totalRatings >= 3 && section !== 'profile' && (
          <PopUpContainer className="mr10">
            <img src={LoadImage('player-profile/star-rating.svg')} alt="Rating" width={12} />
            <PopUp small>
              <Trans ns="accounts" i18nKey={`receivedRating${totalRatings !== 1 ? 's' : ''}`}>
                {{ total: totalRatings }} Ratings Received
              </Trans>
            </PopUp>
            <Paragraph smaller>{ratingInfo?.avgRating?.toFixed(1)}</Paragraph>
          </PopUpContainer>
        )}

        {/* displayed on user profile page */}
        {section === 'profile' && (
          <PlayerProfile>
            {/* display if:
              - is current user profile has no ratings
              - or is not current user profile and has not ratings or less than 3 ratings and didn't gave any rating
            */}
            {(
              (
                !myProfile
                && (ratingInfo?.totalRatings < 3 || !ratingInfo?.avgRating)
                && !ratingInfo?.givenRating
              )
              || (myProfile && !ratingInfo?.avgRating)
            ) && (
              <>
                <img src={LoadImage('player-profile/star-rating-outline.svg')} alt="Rating" width={25} />
                <Paragraph>
                  <Trans ns="accounts" i18nKey="noRatings">No ratings</Trans>
                </Paragraph>

                <PopUpContainer>
                  <PopUp outline>
                    <Trans ns="accounts" i18nKey="ratePlayerInfo">
                      You can rate players only after a friendly or competition match. <br />
                      Explain when a player can rate another player, and when it cannot, <br />
                      what are ratings used for, etc lorem ipsum dolor si amet
                    </Trans>
                  </PopUp>
                  <img src={LoadImage('info-icon.svg')} alt="Info" width={15} />
                </PopUpContainer>
              </>
            )}

            {/* display if:
              - is current user profile
              - or user received at least 3 ratings or
              - or user received less than 3 ratings and profile viewer already gave a rating
            */}
            {(
              (myProfile && ratingInfo?.avgRating)
              || ratingInfo?.totalRatings >= 3
              || (ratingInfo?.totalRatings < 3 && ratingInfo?.givenRating)
            ) && (
              <>
                <PopUpContainer>
                  <PopUp small>
                    <Trans ns="accounts" i18nKey={`receivedRating${totalRatings !== 1 ? 's' : ''}`}>
                      {{ total: totalRatings }} Ratings Received
                    </Trans>
                  </PopUp>
                  <img src={LoadImage('player-profile/star-rating.svg')} alt="Rating" width={25} />
                  <Paragraph large bold>{avgRating?.toFixed(1)}</Paragraph>
                </PopUpContainer>

                <PopUpContainer>
                  <PopUp outline>
                    <Trans ns="accounts" i18nKey="ratePlayerInfo">
                      You can rate players only after a friendly or competition match. <br />
                      Explain when a player can rate another player, and when it cannot, <br />
                      what are ratings used for, etc lorem ipsum dolor si amet
                    </Trans>
                  </PopUp>
                  <img src={LoadImage('info-icon.svg')} alt="Info" width={15} />
                </PopUpContainer>
              </>
            )}

            {ratingInfo?.ratePlayer && (
              <Paragraph
                small
                bold
                className="text-underline cursor-pointer ml5"
                onClick={() => setRatePlayerModal(true)}
              >
                <Trans ns="accounts" i18nKey="ratePlayer">Rate Player</Trans>
              </Paragraph>
            )}

            {ratingInfo?.changeRating && (
              <Paragraph
                small
                bold
                className="text-underline cursor-pointer ml5"
                onClick={() => setRatePlayerModal(true)}
              >
                <Trans ns="accounts" i18nKey="changeRating">Change Rating</Trans>
              </Paragraph>
            )}
          </PlayerProfile>
        )}
      </Container>

      {section === 'profile' && myProfile && ratingInfo?.totalRatings < 3 && (
        <Paragraph
          css={css`
            margin: 10px auto;
            max-width: ${functions?.toEm(600)};
          `}
        >
          <Trans ns="accounts" i18nKey="ownRatingInfo">
            Your average Player Rating will be publicly displayed on your profile once you have
            received a minimum of 3 ratings from players you have interacted with.
          </Trans>
        </Paragraph>
      )}

      {ratePlayerModal && (
        <SetRatingModal
          ratingInfo={ratingInfo}
          user={user}
          onRequestClose={() => setRatePlayerModal(false)}
          onSubmit={() => setRatePlayerModal(false)}
          showCloseIcon
        />
      )}
    </>
  );
};

export default PlayerRating;
