import styled from '@emotion/styled';
import { Paragraph as P } from '../../../components';
import ComponentContainer from '../../../components/Container';

export const Container = styled(ComponentContainer)({
  margin: '0 auto 80px auto',
});

export const TabsContainer = styled.div({
  display: 'flex',
  height: '50px',
  width: '100%',
  margin: '0 0 40px 0',
  '@media (max-width: 910px)': {
    flexDirection: 'column',
    height: 'unset',
  },
});

export const TabContainer = styled.div(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '238px',
  cursor: 'pointer',
  borderBottom: '3px solid #EAF6F6',

  '@media (max-width: 910px)': {
    width: '100%',
    height: '50px',
  },

  '&:hover': {
    borderBottom: '3px solid #10242C',
    '> p': {
      color: '#10242C',
    },
  },

  ...active && {
    borderBottom: '3px solid #10242C',
    '> p': {
      color: '#10242C',
    },
  },

  '&:not(:last-of-type)': {
    margin: '0 11px 0 0',
  },
}));

export const Tab = styled(P)({
  color: '#78ADAB',
  '&:hover': {
    color: '#10242C',
  },
});

export const TabContent = styled.div({
  minHeight: '350px',
});
