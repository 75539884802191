/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-shadow */
import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Contain, H3, P } from '../Collection';
import LoadImage from '../common/LoadImage';

const Modal = ({ children, ...props }) => {
  const theme = useTheme();
  const ModalBackground = styled.div({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 20,
    width: '100vw',
    height: '100vh',
    maxWidth: 'unset !important',
    touchAction: 'none',
    background: props.nobackground ? 'rgba(0,0,0, 0.7)' : theme.colors?.white,
  });

  const Modal = styled.div(props => ({
    ...(props.header || props.headerList) && {
      display: 'flex',
      flexDirection: 'column',
    },
    position: 'fixed',
    top: '50%',
    left: '50%',
    maxWidth: props.extrawide ? '960px' : props.wide ? '918px' : props.small ? '600px' : '660px',
    border: props.noBorder
      ? '1px solid #C1E6E5'
      : props.type2
        ? `1px solid ${props.theme.colors.disabledBlue}`
        : 'unset',
    boxShadow: props.noBorder ? '0px 2px 4px rgba(16, 36, 44, 0.1)' : props.type2 ? '' : '4px 4px 0px #fff00',
    maxHeight: 'calc(-50px + 100vh)',
    width: '100%',
    transform: 'translate(-50%,-50%)',
    borderRadius: props.smallRadius || props.header ? '10px' : '20px',
    background: '#fff',
    ...!props.nooverflow && {
      overflowY: 'auto',
    },
    ...props.noMarginFormGroup && {
      '.form-group': {
        margin: 'unset',
      },
    },
    zIndex: 21,

    '@media (max-width: 700px)': {
      maxWidth: 'calc(100vw - 40px)',
    },
  }));

  const Header = styled.div(props => ({
    ...props.headerList && {
      display: 'flex',
      flexDirection: 'column',
      height: '80px',
      margin: '30px 0px 10px 40px',
    },
    ...props.header && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80px',
      borderBottom: '1px solid #EAF6F6',
    },
  }));

  const Close = styled.img({
    position: 'absolute',
    padding: '10px',
    cursor: 'pointer',
    ...props.headerList || props.header ? {
      right: '25px',
    } : {
      right: '30px',
      top: '15px',
    },
  });

  return props.isShowing
    ? ReactDOM.createPortal(<>
      <ModalBackground onClick={props.hide} />
      <Modal {...props}>
        <Header
          header={props.header}
          headerList={props.headerList}
        >
          {props.header && {
            ...props.title && (<H3>{props.title}</H3>),
          }}
          {props.headerList && (
            <>
              {props.title && (<H3>{props.title}</H3>)}
              {props.slots.title && (
                <Contain>
                  <P small bold>{props.slots.title}</P>
                  {props.slots.availableSlots > 0 && (
                    <P small margin="5px 5px">
                      {`${props.slots.availableSlots} ${props.slots.subtitle}`}
                    </P>
                  )}
                </Contain>
              )}
            </>
          )}
          {!props.hideClose && (<Close src={LoadImage('close-icon.svg')} onClick={props.hide} />)}
        </Header>
        {children}
      </Modal>
    </>, document.body) : '';
};

export default Modal;
