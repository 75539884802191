/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw, EditorState } from 'draft-js';

import ArrowDown from '../../../../assets/images/arrow-down.svg';
import Button from '../../../../components/Button';
import H3 from '../../../../components/H3';
import P from '../../../../components/Paragraph';

const ContentWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors?.lightBlue2};
  padding: ${({ theme }) => theme.functions?.toEm(10)};
  border-radius: 10px;
  margin: 20px 0;

  ${({ theme }) => theme.functions?.minTablet(`
      padding: ${theme.functions?.toEm(30)};
  `)}

  .item {
    margin-bottom: ${({ theme }) => theme.functions?.toEm(20)};
    padding-bottom: ${({ theme }) => theme.functions?.toEm(20)};
    border-bottom: 1px solid ${({ theme }) => theme.colors?.lightTurquoise};

    &--withoutBorder {
      border-bottom: none;
      padding-bottom: 0;
    }

    &__text {
      font-size: ${({ theme }) => theme.functions?.toEm(16)};
    }
  }

  .rotate_arrow {
    transform: rotate(180deg);
  }
`;

const OfficialUpdates = ({ announcements, ...props }) => {
  const { t } = useTranslation('events');
  const [viewMore, setViewMore] = useState(false);

  const list = announcements.slice().reverse();

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <ContentWrapper {...props}>
      <H3 className="mb40">{t('officialUpdates')}</H3>

      {viewMore
        ? list.map((item, key) => (
          <div
            key={key}
            className={`row item ${
                key >= (list.length - 1) && 'item--withoutBorder'
              }`}
          >
            <div className="col-sm-4 position-relative">
              <P bold className="item__text">
                {item.title}
              </P>
              <P smaller className="item__subtext">
                {dateFormat(item.createdAt, 'd mmmm, HH:MM')}
              </P>
            </div>
            <div className="col-sm-8">
              <P
                className="item__text"
                dangerouslySetInnerHTML={{
                  __html: stateToHTML(
                    (EditorState
                      .createWithContent(convertFromRaw(JSON.parse(item?.message)))
                    ).getCurrentContent(),
                  ),
                }}
              />
            </div>
          </div>
        ))
        : list.slice(0, 2).map((item, key) => (
          <div
            key={key}
            className={`row item ${key === 1 && 'item--withoutBorder'}`}
          >
            <div className="col-sm-4 position-relative">
              <P bold className="item__text">
                {item.title}
              </P>
              <P smaller className="item__subtext">
                {dateFormat(item.createdAt, 'd mmmm, HH:MM')}
              </P>
            </div>
            <div className="col-sm-8">
              <P
                className="item__text"
                dangerouslySetInnerHTML={{
                  __html: stateToHTML(
                    (EditorState.createWithContent(convertFromRaw(JSON.parse(item?.message))))
                      .getCurrentContent(),
                  ),
                }}
              />
            </div>
          </div>
        ))}

      {list.length > 2 && (
        <div className="text-center">
          <Button small outline>
            <span className="cursor-pointer" onClick={handleViewMore}>
              {viewMore
                ? <Trans ns="events" i18nKey="viewLessUpdates">View less updates</Trans>
                : (
                  <Trans ns="events" i18nKey="viewAllUpdates">
                    View all updates (
                    {{ total: list.length }}
                    )
                  </Trans>
                )}
              <img className={viewMore ? 'rotate_arrow ml5' : 'ml5'} src={ArrowDown} alt="" />
            </span>
          </Button>
        </div>
      )}
    </ContentWrapper>
  );
};

export default OfficialUpdates;
