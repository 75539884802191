/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import CookieConsent from 'react-cookie-consent';
import { Trans, useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';
import { isEmpty, debounce } from 'lodash';
import { useToasts } from 'react-toast-notifications';

// styles
import i18next from 'i18next';
import { Main, Layout as LayoutStyle } from './styles';

// components
// import setLanguageOnPage from '../common/setLanguageOnPage';
import ConfirmEmail from '../../pages/Dashboard/components/ConfirmEmail';
import { store as socketStore } from '../Socket/store';
import LoadingSpinner from '../LoadingSpinner';
import LoadImage from '../common/LoadImage';
import Footer from './components/Footer';
import Header from './components/Header';
import { P, Link } from '../Collection';
import getWindowSize from '../common/getWindowSize';
import { slugify } from '../common/slugify';
import mixpanel from '../../mixpanel';
import { Protect } from '../../pages/Dashboard/components/ConfirmEmail/styles';
import { resendActivationEmail } from '../../pages/Auth/ActivateAccount/actions';

// reducers
import {
  getAccountInfo,
  logout,
  setBanned,
  setIp,
  updateAccountInfoState,
} from './reducers/session';
import { fetchSettings } from './reducers/settings';
import { setOnlineUsers, setUserOffline, setUserOnline } from './reducers/onlineUsers';
import { fetchNewMessages, setNewMessage } from '../../pages/Chat/reducers';
import { fetchUnreadNotifications, setNewNotification } from './reducers/notifications';
import { setCurrentLanguage } from './reducers/languages';
import { fetchInvitedUsers } from '../../pages/Referral/reducers';

const Layout = ({
  Header: CustomHeader,
  Footer: CustomFooter,
  isPrivate,
  Component,
  route,
  name,
  allowPendingAccount,
  customProps,
}) => {
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    accountInfo,
    isLoggedIn,
    accountStatus,
    ip,
    showRaiseHandModal,
    masterSport,
  } = useSelector(state => state.session);
  const { data: onlineUsers } = useSelector(state => state?.dashboard?.onlineUsers);
  const {
    status:
    unreadNotificationsStatus,
  } = useSelector(state => state?.dashboard?.notifications?.unreadNotifications);
  const { status: newMessagesStatus } = useSelector(state => state?.conversations?.newMessages);
  const { data: { newsHeaderBanner } = {}, displayNewsHeaderBanner } = useSelector(state => state.settings);

  const { width } = getWindowSize();
  const isMobile = width <= 1023;
  const { currentLanguage } = useSelector(state => state.languages);
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const [emailReset, setEmailReset] = useState(false);
  const componentName = slugify(name);
  const hasHeader = ((CustomHeader || CustomHeader === undefined) && CustomHeader !== false);
  const hasCustomHeader = CustomHeader && CustomHeader !== undefined && CustomHeader !== false;
  const hasFooter = (CustomFooter || CustomFooter === undefined) && CustomFooter !== false;
  const [hasBurger, setBurger] = useState(false);
  const isActivated = accountInfo?.status === 'activated';
  const isPlayer = accountInfo?.role === 'player';
  // This code will be reused next summer ( Promo Popup )
  // const [openPromo, setOpenPromo] = useState(false);

  const redirectAfterLogin = () => {
    let placetoRedirectAfterLogin = route;
    Object.entries(useParams()).forEach(([key, value]) => {
      placetoRedirectAfterLogin = placetoRedirectAfterLogin.replace(`:${key}`, value);
    });

    return placetoRedirectAfterLogin.replaceAll('/', '~').substring(1);
  };

  // Pages to display PromoOverlay on
  // const promoOverlay = ['Events'];

  const minimumAccountRequirements = accountInfo?.birthDate
    && accountInfo?.gender
    && accountInfo?.dominantHand
    && !isEmpty(accountInfo?.location);

  const allowedPageWithNoMinimumRequirements = ['Welcome', 'Set profile details', 'Logout'];

  const allowedPageWithNoActivation = ['Dashboard', 'Logout'];
  const isActivationPage = name === 'Activate account';

  // const pwaInstallPages = [
  //   'Events',
  //   'Starting Guide',
  //   'RulesRegulations',
  //   'GameFormatInfo',
  //   'LocalLeague',
  // ];

  const globalSubscriber = async (topic, data) => {
    switch (topic) {
    case 'api-error-handler':
      if (data?.message === 'ACCOUNT_NOT_FOUND') {
        history.push('/404');
      }

      if (['SESSION_EXPIRED', 'ACCOUNT_BLOCKED', 'GET_SESSION_ERROR'].includes(data?.message)) {
        if (!data?.hasNotification) {
          addToast(commonT(data.message), {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        // eslint-disable-next-line no-promise-executor-return
        await new Promise(r => setTimeout(r, 2000));

        mixpanel.reset();

        const pathName = (history.location.pathname || '').split('/').filter(v => v).join('~');
        dispatch(logout(`/login/${pathName}`));
      }
      break;
    default:
      break;
    }
  };

  useEffect(() => {
    const token = PubSub.subscribe('api-error-handler', debounce(globalSubscriber, 25));

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    const getIp = async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      dispatch(setIp(data.ip));
    };

    if (!ip && process.env.REACT_APP_UNDER_MAINTENANCE === 'true') {
      getIp();
    }

    if (
      ip
      && process.env.REACT_APP_UNDER_MAINTENANCE === 'true'
      && !process.env.REACT_APP_UNDER_MAINTENANCE_WHITELISTED_IPS.split(',').includes(ip)
      && name !== 'Maintenance'
    ) {
      history.push('/under-maintenance');
    }
  }, [ip]);

  useEffect(() => {
    const onMount = async () => {
      try {
        dispatch(getAccountInfo({ masterSport }));
        dispatch(fetchSettings());
      } catch (error) { /* empty */ }
    };

    if (accountInfo === null) {
      onMount();
    }
  }, []);

  useEffect(() => {
    const listenForResponse = async ({ message, data }) => {
      if (accountInfo?.isImpersonate) {
        return;
      }

      switch (message) {
      case 'USER_CONNECTED':
        await dispatch(setUserOnline(data));
        break;
      case 'USER_DISCONNECTED':
        await dispatch(setUserOffline(data));
        break;
      case 'SET_ONLINE_USERS':
        if (!onlineUsers.length) {
          await dispatch(setOnlineUsers(data));
        }
        break;
      case 'SUCCESSFULLY_ADDED_MESSAGE':
        await dispatch(setNewMessage());
        break;
      case 'SUCCESSFULLY_ADDED_NOTIFICATION':
        await dispatch(setNewNotification());
        break;
      case 'RAISE_HAND_SUCCESS':
      case 'LOWER_HAND_SUCCESS':
      case 'ACCOUNT_MEMBERSHIP_UPDATED':
      case 'ACCOUNT_WALLET_BALANCE_UPDATED':
      case 'ACCOUNT_GAME_LEVEL_UPDATED':
      case 'ACCOUNT_STATUS_UPDATED':
      case 'EVENT_REGISTRATION_SUCCESSFULLY':
      case 'EVENT_WITHDRAWAL_SUCCESSFULLY':
        await dispatch(getAccountInfo({ masterSport }));
        break;
      case 'FIRST_TASK_COMPLETION_REWARD_RECEIVED':
        dispatch(updateAccountInfoState({
          receivedReward: true,
        }));
        localStorage.setItem('showRewardsInfoBar', 'yes');
        break;
      case 'NEW_REFERRAL_REWARD_RECEIVED':
        dispatch(fetchInvitedUsers());
        break;
      default:
      }
    };

    if (socket) {
      socket.on('global-response', listenForResponse);

      return () => socket.removeAllListeners('global-response');
    }
  }, [socket]);

  const startDate1 = new Date(accountInfo?.penalties?.[accountInfo?.penalties?.length - 1]?.startDate);
  const startDate2 = new Date(accountInfo?.penalties?.[accountInfo?.penalties?.length - 1]?.startDate);

  const ban = {
    time: accountInfo?.penalties?.[accountInfo?.penalties?.length - 1]?.banned,
    ban: accountInfo?.penalties?.[accountInfo?.penalties?.length - 1],

    get until() {
      const calc = startDate1.setDate(startDate1.getDate() + this.time * 7);
      return dateFormat(calc, 'd mmmm yyyy');
    },

    get isBanned() {
      const now = new Date();
      const calc = startDate2.setDate(startDate2.getDate() + this.time * 7);
      return now < calc && this.ban.status !== 'DISABLED';
    },

    get bannedPadding() {
      return ['My events', 'My orders', 'My settings'].includes(name) ? '230px' : '150px';
    },
  };

  useEffect(() => {
    if (
      !isEmpty(accountInfo)
      && accountInfo?.gameLevel?.singles
      && !accountInfo?.isImpersonate
    ) {
      if (newMessagesStatus !== 'succeeded') {
        dispatch(fetchNewMessages());
      }

      if (unreadNotificationsStatus !== 'succeeded') {
        dispatch(fetchUnreadNotifications());
      }
    }
  }, [accountInfo]);

  useEffect(() => {
    if (accountInfo?.language && isEmpty(currentLanguage)) {
      i18next
        .changeLanguage(accountInfo.language)
        .then(() => {
          dispatch(setCurrentLanguage({
            value: accountInfo.language,
            label: commonT(`languages.${accountInfo.language}`),
          }));
        });
    }
  }, [accountInfo?.language]);

  // useEffect(() => {
  //   setLanguageOnPage({
  //     data,
  //     status,
  //     currentLanguage,
  //     dispatch,
  //     history,
  //     commonT,
  //   });
  // }, [status]);

  useEffect(() => {
    dispatch(setBanned({
      banned: ban.isBanned,
      time: ban.time,
      until: ban.until,
    }));
  }, [accountInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  dateFormat.i18n = {
    dayNames: [
      commonT('Sun'),
      commonT('Mon'),
      commonT('Tue'),
      commonT('Wed'),
      commonT('Thu'),
      commonT('Fri'),
      commonT('Sat'),
      commonT('Sunday'),
      commonT('Monday'),
      commonT('Tuesday'),
      commonT('Wednesday'),
      commonT('Thursday'),
      commonT('Friday'),
      commonT('Saturday'),
    ],
    monthNames: [
      commonT('Jan'),
      commonT('Feb'),
      commonT('Mar'),
      commonT('Apr'),
      commonT('May'),
      commonT('Jun'),
      commonT('Jul'),
      commonT('Aug'),
      commonT('Sep'),
      commonT('Oct'),
      commonT('Nov'),
      commonT('Dec'),
      commonT('January'),
      commonT('February'),
      commonT('March'),
      commonT('April'),
      commonT('May'),
      commonT('June'),
      commonT('July'),
      commonT('August'),
      commonT('September'),
      commonT('October'),
      commonT('November'),
      commonT('December'),
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
  };

  const handleResetPassword = async () => {
    if (emailReset) return;
    await resendActivationEmail({
      id: accountInfo.id,
      activationCode: accountInfo.activationCode,
      socketClientId,
    });
    setEmailReset(true);
  };

  // This code will be reused next summer ( Promo Popup )
  // useEffect(() => {
  //   if (!Cookies.get('promoChecked') && promoOverlay.includes(name)){
  //     Cookies.set('promoChecked', 'true', {
  //       expires: 30,
  //       path: '',
  //     });
  //     setOpenPromo(true);
  //   }
  // },[])

  // get account info state
  if (accountInfo === null) {
    return <LoadingSpinner />;
  }

  // if not logged and private route -> redirect to login
  if (!accountInfo?.id && isPrivate) {
    return <Redirect to={`/${currentLanguage?.value || 'en'}/login${`/${redirectAfterLogin()}`}`} />;
  }

  if (isLoggedIn
    && isPlayer && isActivated && !minimumAccountRequirements && !allowedPageWithNoMinimumRequirements.includes(name)) {
    return <Redirect to={`/${currentLanguage?.value || 'en'}/dashboard/welcome`} />;
  }

  if (isLoggedIn
    && !allowPendingAccount && accountInfo.status === 'pending' && accountInfo.role !== 'club' && !isActivationPage) {
    return <Redirect to={`/${currentLanguage?.value || 'en'}/dashboard`} />;
  }

  if (isLoggedIn && isPlayer && !isActivated && !allowedPageWithNoActivation.includes(name) && !isActivationPage) {
    return <Redirect to={`/${currentLanguage?.value || 'en'}/dashboard`} />;
  }

  if (accountInfo?.isImpersonate && ['Friends', 'Conversations', 'Notifications'].includes(name)) {
    return <Redirect to={`/${currentLanguage?.value || 'en'}/dashboard`} />;
  }

  // Pages that require no-header (even if the header is not a CustomHeader)
  const noHeader = ['Homepage', 'For players', 'ForPlayersLearnMore', 'Starting Guide', 'Events', 'Campaign'];

  const bg = {
    black: ['Starting Guide'].includes(name) && '#000',
    custom: ['Starting Guide'].includes(name) && LoadImage('getting-started-guide/bg-bottom.svg'),
  };

  /*
  window.addEventListener('beforeinstallprompt', (e) => {
    if (!pwaInstallPages.includes(name)) {
      e.preventDefault();
      window.pwaInstallPrompt = e;
    }
  });

  if (window.pwaInstallPrompt && pwaInstallPages.includes(name)) {
    window.pwaInstallPrompt.prompt();
    delete window.pwaInstallPrompt;
  }
   */

  return (
    <LayoutStyle className={componentName} {...bg}>
      {/* // This code will be reused next summer ( Promo Popup ) */}
      {/* {openPromo && accountInfo?.membership?.plan === 'free' && (<PromoOverlay />)} */}

      {hasHeader && (
        <Header
          {...hasCustomHeader && {
            CustomHeader,
          }}
          blur={!isActivated && isLoggedIn}
          handle={{ get: hasBurger, set: setBurger }}
          name={name}
          isLoggedIn={isLoggedIn}
          accountInfo={accountInfo}
          isBanned={accountStatus.banned}
          newsBanner={newsHeaderBanner?.active}
          showRaiseHandModal={showRaiseHandModal}
        />
      )}

      {!isActivated && isLoggedIn && !isActivationPage && (
        <>
          <Protect />
          <ConfirmEmail
            handleResetPassword={handleResetPassword}
            emailReset={emailReset}
            isDashboard
          />
        </>
      )}

      <Main
        id="page-wrap"
        blur={!isActivated && isLoggedIn && !isActivationPage}
        smoothBlur={hasBurger}
        name={name}
        {...(newsHeaderBanner?.active && displayNewsHeaderBanner && !localStorage.getItem(newsHeaderBanner?.key)) && {
          newsBannerPaddingTop: 130,
        }}
        {...accountStatus.banned && { bannedPaddingTop: accountStatus.bannedPadding }}
        headless={(CustomHeader !== undefined || noHeader.includes(name))}
      >
        <Component
          isMobile={isMobile}
          isLoggedIn={isLoggedIn}
          accountInfo={accountInfo}
          name={componentName}
          masterSport={masterSport}
          {...customProps && { ...customProps }}
          isBanned={accountStatus.banned}
        />
      </Main>

      {hasFooter && (
        <Footer name={name} LoggedIn={isLoggedIn} {...bg} blur={!isActivated && isLoggedIn} />)}

      <CookieConsent
        location="bottom"
        disableStyles
        containerClasses="cookieConsent"
        buttonClasses="cookieButton"
        contentClasses="cookieContent"
        buttonText={commonT('cookieConsentButton')}
        expires={30}
      >
        <P small color="white">
          <Trans ns="common" i18nKey="cookieConsent">
            This website uses cookies to provide you with the best experience.
            By using Sportya, you consent to the use of cookies in accordance with our
            {' '}
            <Link to="/confidentiality">Privacy Policy</Link>
          </Trans>
        </P>
      </CookieConsent>

      {(process.env.REACT_APP_UNDER_MAINTENANCE === 'true' && name !== 'Maintenance') && (
        <P className="under-maintenance">Under Maintenance!</P>
      )}
    </LayoutStyle>
  );
};

export default Layout;
