import styled from '@emotion/styled';

export const CardStyle = styled.div`
    width: 286px;
    border: 1px solid ${({ theme }) => theme.colors?.lightBlue2};
    border-radius: 10px;
    margin-bottom: 20px;

    .schedule {
        padding: 23px 30px 26px 30px;

        .scheduleHeader {
            display: flex;
            align-items: center;

            img {
                height: 20px;
                width: 20px;
                margin-right: 12px;
            }

            p {
                margin: 0;
                color: ${({ theme }) => theme.colors?.black};
            }
        }

        .timeInterval {
            display: flex;
            justify-content: space-between;
            margin: 23px 0 0;
        }
    }

    .contact {
        padding: 20px 30px 22px 30px;

        h4 {
            line-height: 31px;
            margin-bottom: 3px;
        }

        .listedItem {
            align-items: center;
            margin-top: 21px;
            display: flex;

            img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: unset !important;
    }
`;
