import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import i18n from 'i18next';

const Link = ({ children, to, ...props }) => {
  const baseUrl = i18n.language === 'en' ? '' : `/${i18n.language}`;

  return (
    <ReactLink to={baseUrl + to} {...props}>
      {children}
    </ReactLink>
  );
};

export default Link;
