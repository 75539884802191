import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';

import { Trans } from 'react-i18next';

import {
  ActionContainer,
  HeaderDescription,
  HeaderMeta,
  HeaderTitle,
  JoinNow,
  LandingHeader,
  LandingPageContainer,
  LandingSection,
  MobileOverlay,
  SectionDescription,
} from './styles';

import LoadImage from '../../components/common/LoadImage';

import {
  H1, H3, P,
} from '../../components/Collection';

import mixpanel from '../../mixpanel';

const FriendlyMatches = () => {
  const history = useHistory();
  const { accountInfo } = useSelector(state => state.session);

  const isLoggedIn = !!accountInfo?.id;
  const baseUrl = i18next.language === 'en' ? '' : `/${i18next.language}`;

  if (isLoggedIn) {
    history.push(`${baseUrl}/friendly-matches/start`);
  }

  useEffect(() => {
    mixpanel.track('Visit friendly matches page for guests');
  }, []);

  return (
    <>
      <LandingHeader>
        <HeaderMeta>
          <HeaderTitle>
            <Trans ns="friendlyMatches" i18nKey="landingPage.header.title">
              Play Your Perfect Match
            </Trans>
          </HeaderTitle>

          <HeaderDescription>
            <Trans ns="friendlyMatches" i18nKey="landingPage.header.description">
              Play Your Perfect Match
            </Trans>
          </HeaderDescription>
        </HeaderMeta>
        <MobileOverlay />
      </LandingHeader>

      <LandingPageContainer>
        <LandingSection>
          <img src={LoadImage('friendly-matches/landing/friendly-matches.png')} alt="Competitive Friendly Matches" />
          <SectionDescription>
            <H3>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section1.title">
                Revitalize Your Competitive Spirit
              </Trans>
            </H3>
            <P>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section1.description">
                Unleash your passion for tennis by connecting with other amateur players in your area through Sportya.
                Whether its challenging old friends or seeking new rivals,
                experience the thrill of Competitive and Practice matches.
                Keep tabs on your progress and witness who emerges victorious at the end of each season.
              </Trans>
            </P>
          </SectionDescription>
        </LandingSection>
        <LandingSection>
          <SectionDescription>
            <H3>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section2.title">
                Flex Play - Customize Your Play, Your Way
              </Trans>
            </H3>
            <P>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section2.description">
                Enjoy the ultimate flexibility with Friendly Matches, available anytime and anywhere.
                Take charge by using Chat to connect with players,
                set the time and location, and get ready to hit the court.
                Need suggestions for playing venues? Weve got you covered,
                ensuring a seamless and expedient path to your next match.
              </Trans>
            </P>
          </SectionDescription>
          <img src={LoadImage('friendly-matches/landing/flex-play.png')} alt="Competitive Friendly Matches" />
        </LandingSection>
        <LandingSection>
          <img src={LoadImage('friendly-matches/landing/elevate-skills.png')} alt="Competitive Friendly Matches" />
          <SectionDescription>
            <H3>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section3.title">
                Flex Play - Customize Your Play, Your Way
              </Trans>
            </H3>
            <P>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section3.description">
                Every player presents a unique challenge,
                offering you the chance to evolve and become a better tennis player.
                Embrace diverse play styles and seize the opportunities to enhance your game with each match.
              </Trans>
            </P>
          </SectionDescription>
        </LandingSection>
        <LandingSection>
          <SectionDescription>
            <H3>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section4.title">
                Flex Play - Customize Your Play, Your Way
              </Trans>
            </H3>
            <P>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section4.description">
                Raise your hand and make your presence known to fellow players in your region.
                Use the Raise Hand feature to express your readiness for a match in the upcoming days,
                creating a buzz of anticipation for your next thrilling encounter.
              </Trans>
            </P>
          </SectionDescription>
          <img src={LoadImage('friendly-matches/landing/raise-hand.png')} alt="Competitive Friendly Matches" />
        </LandingSection>
        <LandingSection>
          <img src={LoadImage('friendly-matches/landing/match-found.png')} alt="Competitive Friendly Matches" />
          <SectionDescription>
            <H3>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section5.title">
                Match found?
              </Trans>
            </H3>
            <P>
              <Trans ns="friendlyMatches" i18nKey="landingPage.section5.description">
                Discover available courts in your area and gather your friends for an unforgettable match.
                Once the Booking system is active, Sportya will fast-track your journey from match
                finder to court booking in just a few clicks. Say goodbye to tedious phone calls and wasted time,
                and welcome more time to revel in the joy of your game.
              </Trans>
            </P>
          </SectionDescription>
        </LandingSection>
      </LandingPageContainer>
      <ActionContainer>
        <H1>
          <Trans ns="friendlyMatches" i18nKey="landingPage.actions.title">
            Land your best match!
          </Trans>
        </H1>

        <JoinNow
          background="#F42B03"
          color="white"
          onClick={() => {
            mixpanel.track('Click Join Sportya from friendly matches page for guests');
            history.push('/register');
          }}
        >
          <Trans ns="friendlyMatches" i18nKey="landingPage.actions.button">
            Join Sportya
          </Trans>
        </JoinNow>
      </ActionContainer>
    </>
  );
};

export default FriendlyMatches;
