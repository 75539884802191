import React from 'react';
import LoadImage from '../../common/LoadImage';
import { Contain } from '../../Collection';

const VaillantPartner = () => (
  <Contain margin="20px 0 10px 0">
    <a href="https://vaillant.com.ro/campanii/the-sign/" target="_blank" rel="noopener noreferrer">
      <img
        src={LoadImage('campaign/romania-joaca-tenis/vaillant.png')}
        alt="Vaillant partner"
        className="img-responsive w100"
      />
    </a>
  </Contain>
);

export default VaillantPartner;
