const buttonsActions = ({ match, myPosition, isOnHold }) => {
  const {
    player,
    playerPartner,
    opponent,
    opponentPartner,
    gameType,
    startDate,
  } = match;
  const matchStartDate = new Date(startDate);
  const now = new Date();

  const isAfterStartDate = matchStartDate < now;
  if (['declined', 'withdrawn', 'noMatch'].includes(match[myPosition]?.invitation)) {
    return { status: match[myPosition]?.invitation };
  }
  if (match.status !== 'active') {
    return { status: match.status };
  }
  if (match[myPosition]?.invitation === 'pending' && !match.pastMatch) {
    return {
      accept: true,
      decline: true,
      chat: true,
    };
  }
  if (gameType === 'singles' && opponent?.invitation === 'accepted' && (player.score || []).length) {
    if (match[myPosition].scoreConfirmed) {
      return {
        changeScore: true,
        chat: true,
        edit: myPosition === 'player',
        cancel: myPosition === 'player',
      };
    }
    return {
      confirmScore: true,
      noMatch: !(myPosition === 'player' && match.pastMatch),
      chat: true,
      edit: myPosition === 'player',
      cancel: myPosition === 'player',
    };
  }
  if ((player.score || []).length) {
    let buttons = { chat: true };
    if (match[myPosition].scoreConfirmed) {
      buttons = {
        ...buttons,
        changeScore: true,
      };
    } else {
      buttons = {
        ...buttons,
        confirmMatch: match.pastMatch,
        confirmScore: !(myPosition === 'player' && isOnHold) && match[myPosition]?.invitation === 'accepted',
        noMatch: !(myPosition === 'player' && (isOnHold || match.pastMatch)),
      };
    }
    if (myPosition === 'player') {
      buttons = {
        ...buttons,
        edit: true,
        cancel: true,
        confirmMatch: undefined,
      };
    }
    return buttons;
  }
  if (match.status === 'active') {
    if (myPosition === 'player') {
      return {
        addScore: isAfterStartDate
        && [playerPartner?.invitation, opponent?.invitation, opponentPartner?.invitation].includes('accepted'),
        noMatch: isAfterStartDate
        && [playerPartner?.invitation, opponent?.invitation, opponentPartner?.invitation].includes('accepted'),
        edit: true,
        cancel: true,
        chat: true,
      };
    }
    if (match[myPosition]?.invitation === 'accepted') {
      return {
        addScore: isAfterStartDate,
        withdraw: !isAfterStartDate,
        noMatch: isAfterStartDate,
        chat: true,
      };
    }
  }
  if (gameType === 'singles' && ['declined', 'withdrawn'].includes(opponent?.invitation)) {
    if (myPosition === 'player') {
      return {
        edit: true,
        cancel: true,
      };
    }
    return {};
  }
};

export default buttonsActions;
