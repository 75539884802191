import styled from '@emotion/styled';
import LoadImage from '../../../components/common/LoadImage';

export const Container = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100%',
  form: {
    width: '100%',
  },
  '.form-group': {
    width: '100%',
    margin: 'unset',
  },
  h2: {
    margin: '20px 0',
  },
  p: {
    textAlign: 'center',
  },
  '@media (min-width: 800px)': {
    background: `#fff url('${LoadImage('welcome/bg.png')}') no-repeat center`,
    backgroundSize: 'contain',
  },
  '@media (max-width: 800px)': {
    padding: '0px 10px 40px 10px',
  },
});
