import React from 'react';

import { LevelSelector } from './styles';

import Paragraph from '../../../../components/Paragraph';

const index = ({ children, clickHandler, active }) => (
  <LevelSelector active={active} onClick={clickHandler}>
    <Paragraph medium bold>{children}</Paragraph>
  </LevelSelector>
);

export default index;
