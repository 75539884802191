/* eslint-disable max-len */
import styled from '@emotion/styled';

export const LevelSelector = styled.div`
  border-bottom: ${props => ({ theme }) => (props.active ? `3px solid  ${theme.colors?.primary};` : `3px solid  ${theme.colors?.disabledBlue};`)};
  max-width:196px;
  width:100%;
  height: 39px;
  cursor:pointer;
  display: flex;
  
  p {
    color:${props => ({ theme }) => (props.active ? theme.colors?.primary : theme.colors?.darkTurquoise)};
    margin:0;
    position: relative;
    left: 50%; 
    transform: translateX(-50%);
    display: flex;
    line-height: 1;
    align-items: center;
  }
`;
