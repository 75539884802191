import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import { useHistory } from 'react-router-dom';

import { Badge, Badge as ShieldBadge } from '../../../../components/LevelsBadges/styles';

// eslint-disable-next-line max-len
import { DropdownItemContainer } from '../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/styles';
import {
  ActivityButton,
  Bold,
  Checkmark,
  Details,
  Dropdown,
  FirstPart,
  MatchContainer,
  MatchStatus,
  MatchType,
  OpponentMetaData,
  OpponentScore,
  PlayerMetaData,
  RankedIcon,
  ScoresContain,
  SecondPart,
  ThirdPart,
  WLBox,
} from '../styles';
import { MatchGametype } from '../../Matches/styles';

import { Button } from '../../../../components';
import { Contain, H5, P } from '../../../../components/Collection';
import compareScores from '../../../../components/common/compareScores';
import LoadImage from '../../../../components/common/LoadImage';
import Player from './components/Player';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import LinkWrapper from '../../../../components/Link';
import { formatMatchId, getLocalDate, getWinner } from '../../helpers';
import { OptionIcon } from '../../StartMatch/components/WantToPlay/styles';
import mixpanel from '../../../../mixpanel';
import getUserPosition from '../../Matches/helpers/getUserPosition';
import { timeSince } from '../../../../components/common/timeSince';

const Match = ({ match, setIsModalVisible }) => {
  const history = useHistory();
  const { accountInfo } = useSelector(state => state.session);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    ref,
    isComponentVisible: isDropdownVisible,
    setIsComponentVisible: setIsDropdownVisible,
  } = ToggleVisible(false);

  const { t } = useTranslation('friendlyMatches');
  const { t: accountsT } = useTranslation('accounts');
  const { t: conversationsT } = useTranslation('conversations');

  const isDoubles = match?.competition?.gameType === 'doubles';
  const matchTypeIcon = isDoubles ? 'doubles' : 'singles';

  const handleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
  const matchDate = getLocalDate(match.date);
  const userKey = getUserPosition(match, accountInfo?.userId);

  const player = match[userKey];
  const playerPartner = userKey === 'player' ? match?.playerPartner
    : userKey === 'playerPartner' ? match?.player
      : userKey === 'opponent' ? match?.opponentPartner
        : match.opponent;

  const teamLevel = ((player?.level ?? 0) + (playerPartner?.level ?? 0)) / 2;

  const opponent = ['player', 'playerPartner'].includes(userKey) ? match.opponent : match.player;
  const opponentPartner = ['player', 'playerPartner'].includes(userKey) ? match.opponentPartner : match.playerPartner;

  const opponentTeamLevel = ((opponent?.level ?? 0) + (opponentPartner?.level ?? 0)) / 2;

  const winner = getWinner(match);
  const dynamicWinner = match?.winner === 'opponent' && (userKey === 'opponent' || userKey === 'opponentPartner')
    ? 'player'
    : match?.winner === 'opponent' && (userKey === 'player' || userKey === 'playerPartner') ? 'opponent'
      : match?.winner === 'player' && (userKey === 'opponent' || userKey === 'opponentPartner') ? 'opponent'
        : 'player';

  const formattedDate = dateFormat(matchDate, 'd mmmm (ddd), HH:MM');
  const createdAt = timeSince(new Date(match.createdAt));

  const handleRematch = () => {
    mixpanel.track('Initiate CREATE MATCH', { sp_action_source: 'Scores - Rematch' });
    let players = {
      opponent: userKey === 'player' ? match.opponent : match.player,
    };
    if (match.competition.gameType === 'doubles') {
      if (userKey === 'player') {
        players = {
          playerPartner: match.playerPartner,
          opponent: match.opponent,
          opponentPartner: match.opponentPartner,
        };
      } else if (userKey === 'playerPartner') {
        players = {
          playerPartner: match.player,
          opponent: match.opponent,
          opponentPartner: match.opponentPartner,
        };
      } else if (userKey === 'opponent') {
        players = {
          playerPartner: match.opponentPartner,
          opponent: match.player,
          opponentPartner: match.playerPartner,
        };
      } else if (userKey === 'opponentPartner') {
        players = {
          playerPartner: match.opponent,
          opponent: match.player,
          opponentPartner: match.playerPartner,
        };
      }
    }
    history.push('/friendly-matches/rematch', {
      fetch: true,
      match: {
        gameType: match.competition.gameType,
        ...players,
        format: match.format,
      },
    });
  };

  const expandMatch = () => {
    setIsExpanded(true);
    mixpanel.track('Friendly Matches EXPAND Match View', {
      sp_asset_type: match.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
      sp_match_type: match.ranked ? 'Ranked' : 'Unranked',
      sp_game_type: match.competition ? match.competition.gameType : match.gameType,
      sp_action_source: 'scores',
    });
  };

  return (
    <MatchContainer expanded={isExpanded}>
      <FirstPart>
        <MatchType src={LoadImage(`friendly-matches/matches/${matchTypeIcon}-match.svg`)} alt="" />
        <PlayerMetaData>
          <MatchGametype>
            <P bold margin="0 0 2px 0" className="d-flex align-middle">
              {t(isDoubles ? 'doublesMatch' : 'singlesMatch')}
              {match?.ranked && <RankedIcon src={LoadImage('ranked.svg')} alt="" />}
            </P>
            <span className="match-id">{formatMatchId(match.matchId)}</span>
          </MatchGametype>

          {!isExpanded && (
            <P margin="2px 0 0 0">
              {`${formattedDate} - ${match?.location?.isClub ? match?.location?.clubName : match?.location?.name}`}
            </P>
          )}

          {!isExpanded && (match?.reportedBy || []).includes(accountInfo.userId) && (
            <P className="reported-match d-flex align-middle">
              <img src={LoadImage('friendly-matches/info-red.svg')} className="mr5" alt="" />
              {' '}
              {t('reportedMatch')}
            </P>
          )}

          {isExpanded && (
            <div className="mb20">
              <P margin="2px 0 0 0">{formattedDate}</P>

              <P margin="20px 0 0 0">
                {' '}
                <Bold>
                  {' '}
                  {accountsT('completeProfile.location')}
                  :
                  {' '}
                </Bold>
                {' '}
              </P>
              <P>
                {match?.location?.isClub ? `${match?.location?.clubName } - ` : ''}
                {' '}
                {match?.location?.name}
              </P>

              <P margin="20px 0 0 0">
                {' '}
                <Bold>
                  {' '}
                  {t('matchType')}&nbsp;
                  {match?.sportType && (
                    <>
                      (<Trans ns="common" i18nKey={`sports.${match?.sportType}`}>{match?.sportType}</Trans>)
                    </>
                  )}
                </Bold>
                {' '}
              </P>
              <P className="d-flex">
                {match?.ranked ? t('ranked') : t('unranked')}
                {match?.ranked && (
                  <img src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="" className="ml5" />
                )}
              </P>

              <P margin="20px 0 0 0">
                {' '}
                <Bold>
                  {' '}
                  {t('matchFormat')}
                  :
                  {' '}
                </Bold>
                {' '}
              </P>
              <P>{t(`${match?.format}`)}</P>

              <P margin="20px 0 0 0">
                <Bold>
                  <Trans ns="friendlyMatches" i18nKey="createdBy">Created By</Trans>:
                </Bold>
                {' '}
                <br />
                {userKey === 'player'
                  ? conversationsT('you')
                  : `${match?.player?.firstName} ${match?.player?.lastName}`}
                <LinkWrapper to={`/accounts/${match?.player?.id}`} className="text-decoration-none ml5 mr5">
                  <span className="color-secondary">#{match?.player?.userId}</span>
                </LinkWrapper>
                <span>({createdAt})</span>
              </P>
            </div>
          )}
        </PlayerMetaData>
      </FirstPart>

      <SecondPart>
        {!isExpanded && (
          <>
            <OpponentMetaData>
              <P xSmall margin="0 0 15px 0" className="opponent-title">
                {t(isDoubles ? 'scoresData.opponents' : 'scoresData.opponent')}
              </P>

              <P small bold={dynamicWinner === 'opponent'}>
                <Badge small margin="0 4px 0 2px" double={isDoubles} display="inline-flex">
                  <P xSmall bold>
                    {opponent?.level ?? '?'}
                  </P>
                </Badge>
                {`${opponent.firstName} ${opponent.lastName}`}
                {dynamicWinner === 'opponent' && (
                  <Checkmark src={LoadImage('checkmark.svg')} alt="" />
                )}
              </P>

              {isDoubles && (
                <P small bold={dynamicWinner === 'opponent'}>
                  <Badge small margin="0 4px 0 2px" double display="inline-flex">
                    <P xSmall bold>
                      {match?.opponent?.level ?? '?'}
                    </P>
                  </Badge>
                  {`${opponentPartner?.firstName ?? 'N/A'} ${opponentPartner?.lastName ?? ''}`}
                  {dynamicWinner === 'opponent' && (
                    <Checkmark src={LoadImage('checkmark.svg')} alt="" />
                  )}
                </P>
              )}
            </OpponentMetaData>
            <OpponentScore>
              <P xSmall margin="0 0 15px 0">{t('score')}</P>
              <Contain justify="space-evenly" position="relative">
                {match?.opponent?.type === 'BYE' && (<>Ab.&nbsp;</>)}
                {match?.opponent?.score.slice().sort((a, b) => (a.set > b.set ? 1 : -1)).map((score, keyScore) => (
                  <React.Fragment key={keyScore}>
                    {compareScores(match, score, keyScore)}
                  </React.Fragment>
                ))}
              </Contain>
            </OpponentScore>
          </>
        )}

        {isExpanded && (
          <ScoresContain direction="column">
            <H5 margin="0 0 10px 0">{t('players')}</H5>

            {!isDoubles && (
              <Contain direction="column">

                <Contain direction="row" className="player-data">
                  <Contain direction="column" width="315px">
                    <Player player={match.player} currentPlayer={userKey === 'player'} />
                  </Contain>

                  <Contain direction="row" margin="5px 0 0 0">
                    {match?.player?.type === 'BYE' && (<P className="bye">Ab.&nbsp;</P>)}
                    {(match?.player?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'player'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'opponent'
                      ? <WLBox lose top="-3px" margin="0 0 0 10px">L</WLBox>
                      : (
                        <Contain position="relative">
                          <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>
                          {match?.[winner]?.points > 0 && (
                            <P xSmall margin="5px 0 5px 10px" position="absolute" right="-65px">
                              Points:
                              <Bold>
                                +
                                {match?.[winner]?.points}
                              </Bold>
                            </P>
                          )}
                        </Contain>
                      )}
                  </Contain>
                </Contain>

                <Contain direction="row" className="player-data">
                  <Contain direction="column" width="315px">
                    <Player player={match.opponent} currentPlayer={userKey === 'opponent'} />
                  </Contain>

                  <Contain direction="row" margin="5px 0 0 0">
                    {match?.opponent?.type === 'BYE' && (<P className="bye">Ab.&nbsp;</P>)}
                    {(match?.opponent?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'opponent'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'player'
                      ? <WLBox lose top="-3px" margin="0 0 0 10px">L</WLBox>
                      : (
                        <Contain position="relative">
                          <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>
                          {match?.opponent?.points > 0 && (
                            <P xSmall margin="5px 0 5px 10px" position="absolute" right="-65px">
                              Points:
                              <Bold>
                                +
                                {match?.opponent?.points}
                              </Bold>
                            </P>
                          )}
                        </Contain>
                      )}
                  </Contain>
                </Contain>

                <Contain justify="end" className={isExpanded ? 'hidden' : ''}>
                  {match[winner]?.points > 0 && (
                    <P xSmall margin="5px 0 5px 10px">
                      Points:
                      <Bold>
                        +
                        {match[winner]?.points}
                      </Bold>
                    </P>
                  )}
                </Contain>

              </Contain>
            )}

            {isDoubles && (
              <Contain direction="column">
                <Contain direction="row" className="player-data">
                  <Contain direction="column" width="345px">
                    <Contain direction="row">
                      <P margin="10px 0" xSmall bold>
                        {userKey.includes('player') ? t('yourTeam') : t('opponentTeam')}
                      </P>

                      <ShieldBadge double padding="0" medium position="relative" top="6px" margin="0 0 0 6px">
                        <P xxSmall bold>{teamLevel}</P>
                      </ShieldBadge>
                    </Contain>

                    <Player player={match?.player} isDoubles currentPlayer={userKey === 'player'} />
                    <Player player={match?.playerPartner} isDoubles currentPlayer={userKey === 'playerPartner'} />
                  </Contain>

                  <Contain direction="row" justify="center" align="center">
                    {match?.player?.type === 'BYE' && (<P className="bye">Ab.&nbsp;</P>)}
                    {(match?.player?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'player'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'opponent'
                      ? <WLBox lose margin="0 0 0 10px">L</WLBox>
                      : (
                        <Contain position="relative">
                          <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>
                          {match[winner]?.points > 0 && (
                            <P xSmall margin="5px 0 5px 10px" position="absolute" right="-65px">
                              Points:
                              <Bold>
                                +
                                {match[winner]?.points}
                              </Bold>
                            </P>
                          )}
                        </Contain>
                      )}
                  </Contain>
                </Contain>

                <Contain direction="row" className="player-data">
                  <Contain direction="column" width="345px">
                    <Contain direction="row">
                      <P margin="10px 0" xSmall bold>
                        {userKey.includes('opponent') ? t('yourTeam') : t('opponentTeam')}
                      </P>

                      <ShieldBadge double padding="0" medium position="relative" top="6px" margin="0 0 0 6px">
                        <P xxSmall bold>{opponentTeamLevel}</P>
                      </ShieldBadge>
                    </Contain>

                    <Player player={match?.opponent} isDoubles currentPlayer={userKey === 'opponent'} />
                    <Player player={match?.opponentPartner} isDoubles currentPlayer={userKey === 'opponentPartner'} />
                  </Contain>

                  <Contain direction="row" justify="center" align="center">
                    {match?.opponent?.type === 'BYE' && (<P className="bye">Ab.&nbsp;</P>)}
                    {(match?.opponent?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'opponent'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'player'
                      ? <WLBox lose margin="0 0 0 10px">L</WLBox>
                      : (
                        <Contain position="relative">
                          <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>
                          {match[winner]?.points > 0 && (
                            <P xSmall margin="5px 0 5px 10px" position="absolute" right="-65px">
                              Points:
                              <Bold>
                                +
                                {match[winner]?.points}
                              </Bold>
                            </P>
                          )}
                        </Contain>
                      )}
                  </Contain>
                </Contain>
              </Contain>
            )}

          </ScoresContain>
        )}
      </SecondPart>

      <ThirdPart expanded={isExpanded}>
        {!isExpanded && (
          <MatchStatus>
            <P xSmall margin="0 0 15px 0">W/L</P>
            <Contain>
              {dynamicWinner === 'opponent' ? <WLBox lose>L</WLBox> : <WLBox win>W</WLBox>}
              {player?.points > 0 && (
                <P xSmall margin="5px 0 5px 10px">
                  Points:
                  <Bold>
                    +
                    {player?.points}
                  </Bold>
                </P>
              )}
            </Contain>
          </MatchStatus>
        )}

        <OptionIcon
          margin="0 10px 0 0"
          dropdownIcon
          src={LoadImage('friendly-matches/matches/button-more.svg')}
          alt=""
          onClick={() => { handleDropdown(); }}
          hideTablet
        />

        {isDropdownVisible && (
          <Dropdown ref={ref}>
            <DropdownItemContainer onClick={() => handleRematch()}>
              <img src={LoadImage('rematch-icon.svg')} width="20px" height="20px" alt="" />
              {t('rematch')}
            </DropdownItemContainer>
            <DropdownItemContainer
              disabled={(match?.reportedBy || []).includes(accountInfo.userId)}
              onClick={() => {
                if (!(match?.reportedBy || []).includes(accountInfo.userId)) {
                  setIsModalVisible(match);
                  setIsDropdownVisible(!isDropdownVisible);
                }
              }}
            >
              <img src={LoadImage('report-icon.svg')} width="20px" height="20px" alt="" />
              {t('reportMatch')}
            </DropdownItemContainer>
          </Dropdown>
        )}
        {!isExpanded && (
          <OptionIcon
            hideTablet
            src={LoadImage('friendly-matches/matches/button-dropdown.svg')}
            alt=""
            onClick={() => expandMatch()}
          />
        )}

        {isExpanded && (
          <OptionIcon
            hideTablet
            src={LoadImage('friendly-matches/matches/button-dropup.svg')}
            alt=""
            onClick={() => setIsExpanded(false)}
          />
        )}
      </ThirdPart>

      {isExpanded && (
        <ActivityButton>
          <Button outline small>
            <LinkWrapper to="/my-account">
              {t('viewInActivity')}
            </LinkWrapper>
          </Button>
        </ActivityButton>
      )}

      <Details onClick={() => setIsExpanded(!isExpanded)}>
        <ActivityButton mobileOnly isExpanded={isExpanded}>
          {isExpanded && (
            <>
              <Button outline small fullwidth onClick={() => handleRematch()}>
                <span>{t('rematch')}</span>
              </Button>

              <Button
                outline
                small
                fullwidth
                disabled={(match?.reportedBy || []).includes(accountInfo.userId)}
                onClick={() => {
                  if (!(match?.reportedBy || []).includes(accountInfo.userId)) {
                    setIsModalVisible(match);
                  }
                }}
              >
                <span>{t('reportMatch')}</span>
              </Button>

              <Button outline small fullwidth>
                <LinkWrapper to="/my-account">
                  {t('viewInActivity')}
                </LinkWrapper>
              </Button>
            </>
          )}
        </ActivityButton>
        <OptionIcon
          noMargin
          src={LoadImage(`friendly-matches/matches/button-${isExpanded ? 'dropup' : 'dropdown'}.svg`)}
          alt=""
        />
      </Details>

    </MatchContainer>
  );
};

export default Match;
