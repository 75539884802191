import React from 'react';
import dateFormat from 'dateformat';
import { inRange, isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Paragraph } from '../../../../../../components';
import LoadImage from '../../../../../../components/common/LoadImage';
import timeToInteger from '../helpers/timeToInteger';
import addMinutesToTime from '../helpers/addMinutesToTime';
import { Td, PopUp, PopUpContainer } from '../styles';
import timeToString from '../helpers/timeToString';
import { getClubCourts } from '../../../../actions';
import getCourtsWorkingHours from '../helpers/getCourtsWorkingHours';

let options = {};
let skipIteration = false;
const debug = false;
let lastIndex = 1;
let lastUnavailableIndex = 0;

const handleCourt = ({
  court,
  bookings,
  lastBookingHour,
  bookingHours,
  language = 'en',
  setBookingInfo,
  maxDateScheduleExceeded,
}) => {
  const { t } = useTranslation(['clubs', 'common']);
  const history = useHistory();

  lastIndex = 1;
  skipIteration = false;
  options = {};
  lastUnavailableIndex = 0;

  const { date } = Object.fromEntries(new URLSearchParams(window.location.search));
  const workingHours = getCourtsWorkingHours([court], date, true);

  const handleClick = ({ bookingInfo, startHour, courtId }) => {
    // available interval
    if (!bookingInfo) {
      const filters = {
        timestamp: `${date} ${startHour}`,
        courtId,
      };
      getClubCourts(filters).then(clubInfo => {
        if (!isEmpty(clubInfo)) {
          setBookingInfo({
            clubInfo,
            startDate: date,
            startHour,
            courtId,
          });
        }
      });

      return;
    }

    const { myBooking, _id } = bookingInfo;

    if (myBooking) {
      return history.push(`/${language}/my-account/bookings/${_id}`);
    }
  };

  return (
    <tr key={court._id}>
      <td colSpan={4}>
        <div className="d-flex">
          <Paragraph className="ellipsis">{court.name}</Paragraph>
          <PopUpContainer inline className="ml5">
            <PopUp>{formatFacilities(court, t)}</PopUp>
            <img src={LoadImage('info-icon.svg')} alt="Info" width={15} />
          </PopUpContainer>
        </div>
      </td>
      {bookingHours.map((hour, key) => mapBookingIntervals({
        key,
        hour,
        bookings,
        court,
        date,
        lastUnavailableIndex,
        handleClick,
        workingHours,
        lastBookingHour,
        maxDateScheduleExceeded,
      }))}
    </tr>
  );
};

const mapBookingIntervals = (data) => {
  const {
    key,
    hour,
    bookings,
    court,
    date,
    handleClick,
    workingHours,
    lastBookingHour,
    maxDateScheduleExceeded,
  } = data;
  const intHour = timeToInteger(hour);
  const intHourNextHalf = timeToInteger(addMinutesToTime(hour, 30));
  const index = key + 1;

  if (!bookings) return;

  const now = new Date();
  now.setMinutes((Math.ceil(now.getMinutes() / 30) * 30) % 60);
  if (now.getMinutes() === 0) {
    now.setHours(now.getHours() + 1);
  }
  const currentHour = timeToInteger(`${dateFormat(now, "HH:MM")}`);
  const isToday = dateFormat(date, "DDDD") === 'Today';

  const courtBookings = (bookings || [])
    .filter(({ courtInfo }) => court._id === courtInfo.id);
  const bookingInfo = (courtBookings || [])
    .filter(({ startHour, endHour }) => inRange(intHour, startHour, endHour))
    .shift();
  const upcomingBooking = (courtBookings || [])
    .filter(({ startHour, endHour }) => inRange(intHourNextHalf, startHour, endHour))
    .shift();

  if (debug) {
    console.log('start', {
      date,
      intHour,
      intHourNextHalf,
      currentHour,
      isToday,
      options,
      upcomingBooking,
      bookingInfo,
    });
  }

  const currentInterval = workingHours.map((interval) => {
    if (intHour >= interval.startHour && intHour < interval.endHour) {
      return interval;
    }
    return false;
  }).filter((v) => !!v).shift();

  // set unavailability
  if (
    ((intHour < currentHour && isToday) || !currentInterval || maxDateScheduleExceeded || court.status !== "active")
    && isEmpty(bookingInfo)
  ) {
    options = {
      isUnavailable: true,
    };

    skipIteration = true;

    // save cell
    const nextInterval = workingHours.map((interval) => {
      if (intHourNextHalf >= interval.startHour && intHourNextHalf < interval.endHour) {
        return interval;
      }
      return false;
    }).filter((v) => !!v).shift();

    if (
      (intHourNextHalf === currentHour && isEmpty(upcomingBooking))
      || (!isEmpty(upcomingBooking) && upcomingBooking.startHour > intHour)
      || (Number(`${lastBookingHour}00`) === intHourNextHalf) // end interval cells
      || (!currentInterval && nextInterval) // unavailable cells
    ) {
      skipIteration = false;
    }

    if (debug) {
      console.log('is unavailable', {
        intHour, intHourNextHalf, currentHour, skipIteration, options,
      });
    }
  }

  // set booking interval
  if (!isEmpty(bookingInfo)) {
    const { startHour, endHour, myBooking } = bookingInfo;

    if (debug) {
      console.log('pre-set-booking', {
        intHour, intHourNextHalf, startHour, endHour, options, skipIteration,
      });
    }

    if (intHour === startHour) {
      options = {
        isBooked: true,
        index,
        myBooking,
        startHour,
        endHour,
      };
      // console.log('set', options, intHour, startHour, endHour);
    }

    // increment booking indexes
    if (endHour > intHour) {
      skipIteration = true;
    }

    // set cell and continue to new cell
    if (intHourNextHalf === endHour) {
      skipIteration = false;
    }
  }

  if (debug) {
    console.log('PRE-SET', {
      index, lastIndex, options, intHour, currentHour, skipIteration,
    });
  }

  if (skipIteration) return false;

  // set colspan for each booked/unavailable interval
  if (!isEmpty(options)) {
    options.colSpan = index;
    if (lastIndex !== 1) {
      options.colSpan = index - lastIndex;
    }
    if (options.isBooked) {
      options.colSpan = index - options.index + 1;
    }
    lastUnavailableIndex = index;
  }

  // set last booked column index
  lastIndex = index;

  if (debug) {
    console.log('SET', {
      index, lastIndex, options, intHour, intHourNextHalf, currentHour,
    });
    console.log('');
    console.log('');
  }
  let startHour = timeToString(intHour);

  // round half hours
  if (index - 1 !== lastUnavailableIndex) {
    const [tmpHour] = startHour.split(":");
    startHour = `${tmpHour}:00`;
  }

  const result = (
    <Td
      key={index}
      colSpan={1}
      hasRightBorder={index % 2 === 0}
      {...options}
      onClick={() => handleClick({
        bookingInfo,
        courtId: court._id,
        startHour,
      })}
    >
      {options.myBooking && (
        <PopUpContainer myBooking>
          <PopUp myBooking>
            <Trans ns="clubs" i18nKey="myBookingTooltip">
              My booking <br />
              {{ startHour: timeToString(bookingInfo.startHour) }} - {{ endHour: timeToString(bookingInfo.endHour) }}
              ({{ duration: (options.colSpan / 2) * 60 }} min)
            </Trans>
          </PopUp>
          <img src={LoadImage('checkmark-white.svg')} alt="My booking" />
        </PopUpContainer>
      )}
    </Td>
  );

  // reset options
  options = {};

  return result;
};

const formatFacilities = (court, t) => {
  const arr = [];
  arr.push(...[
    t(`surfaceType.${court.surface}`),
    t(`courtSizes.${court.size}`),
    t(`courtTypes.${court.type}`),
  ]);

  let str = arr.join(", ");
  if (court?.hasLighting) {
    str += ` (${t('withLighting')})`;
  }

  return str;
};

export default handleCourt;
