import styled from '@emotion/styled';

export const Container = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
});

export const PopUpContainer = styled.div(({ inline }) => ({
  display: inline ? 'inline-flex' : 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  gap: 5,

  '&:hover > span': {
    display: 'block',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },

  p: {
    margin: 0,
  },
}));

export const PopUp = styled.span(({ small, outline, fromModal }) => ({
  position: 'absolute',
  display: 'none',
  padding: '5px 10px',
  borderRadius: '4px',
  bottom: small ? 30 : 20,
  left: '50%',
  transform: 'translateX(-50%)',
  background: outline ? '#ffffff' : 'rgb(15, 36, 44)',
  zIndex: 9,
  color: outline ? '#000000' : '#ffffff',
  whiteSpace: 'nowrap',

  '@media (max-width: 767px)': {
    whiteSpace: small ? 'nowrap' : 'initial',
    minWidth: small ? "auto" : "50vw",
  },

  '@media (max-width: 479px)': {
    minWidth: small ? "auto" : "50vw",
    ...fromModal && {
      position: "fixed",
      left: 0,
      top: 0,
      bottom: "auto",
      transform: "none",
    },
  },
}));

export const PlayerProfile = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  gap: 5,
});
