export default {
  events: 'Esemény keresése',
  courts: 'Pályák',
  membership: 'Tagság',
  comingSoon: 'Hamarosan',
  findEvent: 'Esemény keresése',
  book_court: 'Pálya lefoglalása',
  forPlayers: 'Játékosoknak',
  forClubs: 'Kluboknak',
  blog: 'Blog',
  help: 'Segítség',
  aboutUs: 'Rólunk',
  motto: 'Hozd össze a legjobb meccsed!',
  joinNow: 'Csatlakozz most',
  privacy: 'Adatvédelmi irányelvek',
  termsAndConditions: 'Felhasználási feltételek',
  returnPolicy: 'Visszatérítési szabályzat',
  language: 'Nyelv',
  english: 'Angol',
  romanian: 'Román',
  English: 'Angol',
  hungarian: 'Magyar',
  copyrightFooter: 'Minden jog fenntartva.',
  rules: 'Sportya  - Szabályok és előírások',
  groupsPlaying: 'Csoportok játékformátumai',
  localLeaguesRules: 'Helyi ligák – Szabályok és előírások',
  sportyaRomania: 'Sportya Románia',
  sportyaBulgaria: 'Sportya Bulgária',
  sportya: 'Sportya',
  sportyaDesc: 'Célunk, hogy összekapcsoljuk és inspiráljuk a világ sportolóit versenyplatformok, események és speciális eszközök létrehozásával, amelyek segítenek nekik a legtöbbet kihozni a sportéletükből.',
  findPlayers: 'Keressen egy játékost',
  rankings: 'Rangsorok',

};
