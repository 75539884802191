import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { UserContext } from '../../../../..';
import LoadImage from '../../../../../../../../../components/common/LoadImage';
import mixpanel from '../../../../../../../../../mixpanel';
import { addConversation } from '../../../../../../../../Chat/actions';
import { addConversationToList } from '../../../../../../../../Chat/reducers';
import { VisitedProfileButton } from '../../../styles';

const ChatOption = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: commonT } = useTranslation('common');
  const {
    user, socketClientId, socket, friendStatus, iAmBlocked, isBlocked,
  } = useContext(UserContext);

  const [initiatingChat, setInitiatingChat] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (message === 'SUCCESSFULLY_INITIATED_CHAT') {
        const { isNew } = data || {};
        if (success) {
          if (isNew) {
            dispatch(addConversationToList(data));
          }

          addToast(commonT(message), {
            appearance: success ? 'success' : 'error',
            autoDismiss: true,
          });

          return history.push(`/conversations/${data.id}`);
        }
      }
      return setInitiatingChat(false);
    };

    if (socket) {
      socket.removeAllListeners('chat-response');
      socket.on('chat-response', listenForResponse);

      return () => {
        socket.removeAllListeners('chat-response');
      };
    }
  }, [socketClientId]);

  const initiateConversation = async () => {
    await addConversation({
      payload: { members: [user] },
      addToast,
      t: commonT,
    });

    setInitiatingChat(true);
    return mixpanel.track('Start Chat from Player Profile');
  };

  return !iAmBlocked && !isBlocked && (
    <VisitedProfileButton
      margin="0 10px"
      outline={!friendStatus}
      onClick={() => initiateConversation()}
      disabled={initiatingChat}
    >
      <img src={LoadImage(!friendStatus ? 'chat-icon.svg' : 'chat-white-icon.svg')} alt="chat" />
      <Trans ns="settings" i18nKey="chat">
        Chat
      </Trans>
    </VisitedProfileButton>
  );
};

export default ChatOption;
