import React from 'react';
import styled from '@emotion/styled';

const H2Styled = styled.h2`
color: ${({ theme }) => theme?.colors?.primary};
font-size: ${({ theme }) => theme?.functions?.toEm(28)};
line-height: 40px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;

${props => props.theme.functions?.minDesktopSmall(`
font-size: ${ props.theme?.functions?.toEm(38) };
line-height: 50px;
`)}
`;

const H2 = ({ children, ...props }) => (
  <H2Styled {...props}>
    {children}
  </H2Styled>
);

export default H2;
