import styled from '@emotion/styled';
import { H2 } from '../../../components/Collection';
import ComponentContainer from '../../../components/Container';

export const MatchFormContainer = styled(ComponentContainer)({

});

export const MatchFormOptions = styled.div({
  display: 'flex',
  height: '39px',
  borderRadius: '110px',
  background: '#EAF6F6',
  width: 'max-content',
  margin: '0 auto',
});

export const MatchFormOption = styled.div(({ isSelected }) => ({
  padding: '9px 18px',
  borderRadius: '80px',
  userSelect: 'none',
  cursor: 'pointer',

  ...isSelected && {
    background: '#10242C',
    color: '#fff',
  },
}));

export const MatchFormTitle = styled(H2)(({ addMarginBottom }) => ({
  position: 'relative',
  margin: `30px auto ${addMarginBottom ? '30px' : '0'} auto`,
  width: 'max-content',
}));

export const FormikContainer = styled.div({
  position: 'relative',
  maxWidth: '602px',
  padding: '30px',
  minHeight: '300px',
  borderRadius: '10px',
  border: '1px solid #EAF6F6',
  margin: '0 auto 60px auto',
  '@media (max-width: 480px)': {
    padding: '15px',
  },
});

export const BackButton = styled.img({
  position: 'absolute',
  top: '-75px',
  left: '0',
  cursor: 'pointer',
  width: '46px',
  height: '46px',
  objectFit: 'none',
});
