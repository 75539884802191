import React from 'react';
import H4 from '../../../../../components/H4';
import Paragraph from '../../../../../components/Paragraph';

import { Prizes, Prize, Medal } from './styles';

const index = ({ prizes }) => (
  <Prizes>
    {prizes?.map((prize, key) => (
      prize.title !== ''
        ? (
          <Prize key={key} place={key + 1}>
            <Medal place={key + 1}>
              <span>
                {key + 1}
              </span>
            </Medal>
            <H4>{prize.title}</H4>
            <Paragraph>{prize.prize}</Paragraph>
          </Prize>
        ) : ''
    ))}
  </Prizes>
);

export default index;
