import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '../../common/http';

// Actions
export const fetchLanguages = createAsyncThunk('settings/fetchLanguages', async () => {
  const { data: { data } } = await get('/settings/languages');

  return data;
});

// Slice
const slice = createSlice({
  name: 'languages',
  initialState: {
    data: {},
    currentLanguage: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    setCurrentLanguage(state, action) {
      state.currentLanguage = action.payload;
    },
  },
  extraReducers: {
    [fetchLanguages.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchLanguages.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchLanguages.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  setCurrentLanguage,
} = slice.actions;

export default slice.reducer;
