export default {
  title: 'Play local. Dream global.',
  subtitle: 'Join a growing community of international amateur players',
  seoTitle: 'Sportya este pasul următor în evoluția comunității Tenis Partener | Sportya',
  subheaderText: {
    1: '12 years in the making, Sportya is today a nation governed by our passion for sport and the next step in the evolution of the Tenis Partener community',
    2: 'Energized by the spirit of competition. Undivided by age, gender, color or other beliefs.',
    3: 'The land of Sportya has no geographical borders, no passports are needed and we are guided only by the spirit of fair-play. We all speak the same language, and that’s the language of SPORT.',
    learnMore: 'Learn More',
  },
  infoItem1: {
    title: 'Level of Play',
    1: 'All Sportya members are required to evaluate their level of play when they join the community, based on clear standards and representations of game play.',
    2: 'There are 6 levels of play available, from beginner to expert. The level of play determines proper matching both in competitive tournament play or social friendly play and is adjusted dynamically based on results.',
    3: 'We welcome all players and we strive to offer balanced match experiences, according to technical ability, fitness shape and projected match difficulty.',
    4: 'The final result – we encourage fun and engaging competition, the foundation of sport, that ultimately leads to progress in your game and in everyday life.',
  },
  infoItem2: {
    title: 'Tennis Tournaments for Amateur Players',
    1: 'With each event, we are celebrating competition and developing social connections.',
    2: 'Sportya members have access to a centralized calendar of events, organized in close partnership with clubs around the world, where they can LAND THEIR BEST MATCH, discover new lands and connect with people from different cultures, all speaking the same language of SPORT.',
    3: 'Choose your play, from classic, knockout events, organized during the weekend, to group format events, night events and more. Showcase your profile, connect with other players, compete in local and global amateur rankings and make the most out of your tennis life.',
  },
  infoItem3: {
    title: 'Local Tennis Leagues',
    1: 'We know that time is an issue for many Sportya members and that the fixed structure of match scheduling for classic tennis events can be prohibitive. That\'s how Local Leagues came into being in Sportya.',
    2: 'Local Leagues allow you to play one match a week, following a flexible time schedule, while still keeping the spirit of the competition alive and discovering many different play styles.',
  },
  infoItem4: {
    title: 'Friendly Matches',
    subtitle: 'Re-Launching Soon',
    1: 'Friendly matches are the most flexible way to experience tennis in Sportya. Once you become a Sportya member, the entire nation becomes your playground. Friendly matches can be played anytime, anywhere, with other Sportya members.',
    2: 'Embrace the diversity of play each new match provides, connect with people wherever you are, have fun and enjoy balanced play, all in sync with your personal life and preferences.',
  },
  readyToPlay: 'Ready? Play!',
  findAnEvent: 'Find an Event ⟶',
  buttonText: 'Join now ⟶',

  learnMore: {
    title: '12 years in the making, Sportya is the next step in the evolution of the Tenis Partener community.',
    subtitle: 'Sportya is more than a project or a series of events. Sportya is the place that we all long to be part of, a nation governed by our passion for sport. Energized by the spirit of competition. Undivided by age, gender, color or other beliefs. The land of Sportya has no geographical borders, no passports are needed and we are guided only by fair-play. We all speak the same language, and that’s the language of SPORT.',
    content: {
      first: {
        title: 'Why did we do it?',
        1: `We love the Tenis Partener brand and all the experiences and memories that were part of our long 12-year journey since its launch.
        This change marks the end of a great period of our lives, but also makes way for new and exciting developments that could not have been accommodated by the current Tenis Partener platform.
        Nevertheless, many of the things you know and love about Tenis Partener will remain unchanged as we transition to Sportya.`,
        2: 'While Tenis Partener was developed with the Romanian tennis community of amateur players in mind, Sportya addresses players at a global level. You will see this shift starting from July 2021, with the launch of the Bulgarian calendar of Sportya tennis events. Expect to see us in new places around the world, in the following months.',
        3: 'With Sportya, we are going back to our roots and reaffirming our drive for technological innovation, just as we did 12 years ago, with the launch of the first Tenis Partener platform. The new Sportya platform is built completely from scratch and holds true potential in terms of future developments. We are also embracing a more active and consistent iterative launch schedule – expect new features on a bi-monthly basis. ',
        4: 'Last, but not least, we will be expanding the community into new sports, in the next few years. Our contribution to a better and more united world starts with Sportya. Think of international multi-sport events that bring people of all cultures together. Imagine people being more active and building healthier habits from an early age.',
        5: `For us, everything we do is driven by our desire to connect athletes of the world and inspire them to stay active throughout life.
        This has never been as important as it is today.`,
      },
      second: {
        title: 'How does this affect current Tenis Partener players?',
        1: `All Tenis Partener accounts have been automatically changed to Sportya accounts (sportya.net). All membership benefits are intact. Log in credentials remain unchanged.
        Your private information linked to your account has not been transferred to any third-party entity. Both Sportya and Tenis Partener are brands owned and managed by MPG Romania.`,
        2: 'Thank you for letting us be part of your lives. We can’t wait to discover, together, what the future holds.',
        3: `Meciuri frumoase,
        Team Sportya / Tenis Partener`,
      },
    },
    button: 'Go Back',
  },
};
