import React from 'react';
import styled from '@emotion/styled';

const H1Styled = styled.h1`
color: ${({ theme }) => theme?.colors?.primary};
font-size: ${({ theme }) => theme?.functions?.toEm(33)};
line-height: 47px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;

${props => props.theme.functions?.minDesktopSmall(`
font-size: ${ props.theme?.functions?.toEm(48) };
line-height: 62px;
`)}
`;

const H1 = ({ children, ...props }) => (
  <H1Styled {...props}>
    {children}
  </H1Styled>
);

export default H1;
