// 0 - Account Activity
// 1 - Account Type
// 2 - Gender
// 3 - Min Age
// 4 - Max Age
// 5 - Rank
// 6 - forbiddenToTeams
// 7 - teamAverageLevel

const competition = (competitionInfo) => {
  const doublesRestrictionLevel = competitionInfo?.level;

  const restrictions = [(competitionInfo?.restrictions?.accountActivity !== ''
    && competitionInfo?.restrictions?.accountActivity !== 'any'),
  (competitionInfo?.restrictions?.accountType !== '' && competitionInfo?.restrictions?.accountType !== 'any'),
  (competitionInfo?.restrictions?.gender !== '' && competitionInfo?.restrictions?.gender !== 'any'),
  (competitionInfo?.restrictions?.minAge !== null && competitionInfo?.restrictions?.minAge > 0),
  (competitionInfo?.restrictions?.maxAge !== null && competitionInfo?.restrictions?.maxAge > 0),
  (competitionInfo?.restrictions?.rank > 0),
  competitionInfo?.restrictions?.forbiddenToTeams,
  (competitionInfo?.restrictions?.teamAverageLevel
    && competitionInfo?.gameType === 'doubles' ? doublesRestrictionLevel > 0 : false)];

  return restrictions;
};

export default competition;
