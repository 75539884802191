import { isEmpty } from 'lodash';

const getCompetitionListTypeEntry = (userId, competitionInfo) => {
  const competitionLists = ['mainList', 'waitingList', 'preRegistrationList'];
  return competitionLists.map((list) => {
    if (typeof competitionInfo?.[list] === 'object') {
      const isRegistered = (competitionInfo[list]
        ?.filter(v => v?.userId === Number(userId) || v?.partnerId === Number(userId))
        .shift());

      if (isRegistered) return list;
    }
    return null;
  })
    .filter((index) => !isEmpty(index))
    .shift();
};

export default getCompetitionListTypeEntry;
