import React, { useContext, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

import { UserContext } from '../../../../..';
import ReportModal from './components/ReportModal';
import mixpanel from '../../../../../../../../../mixpanel';
import { Button, Link } from '../../../../../../../../../components/Collection';
import LoadImage from '../../../../../../../../../components/common/LoadImage';
import {
  ContainOptions, Dropdown, DropdownItemContainer, ThreeDotsButton, ThreeDotsIcon,
} from './styles';
import ToggleVisible from '../../../../../../../../../components/common/ToggleVisible';
import BlockModal from './components/BlockModal';
import { getAccountInfo } from '../../../../../../../../../components/Layout/reducers/session';

const BlockAndReportOptions = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const optionsDropdownRef = useRef(null);
  const {
    isBlocked,
    socket,
    setIsBlocked,
  } = useContext(UserContext);
  const {
    isComponentVisible: isBlockModalVisible,
    setIsComponentVisible: setBlockModalVisible,
  } = ToggleVisible(false);
  const {
    ref: dropdownRef,
    isComponentVisible: isDropdownVisible,
    setIsComponentVisible: setDropdownVisible,
  } = ToggleVisible(false);
  const {
    isComponentVisible: isReportModalVisible,
    setIsComponentVisible: setReportModalVisible,
  } = ToggleVisible(false);
  const { t } = useTranslation('settings');

  const blockModal = (skipMixpanel) => {
    if ((!isBlockModalVisible && !isBlocked) && !skipMixpanel) {
      mixpanel.track('Block Player from Player Profile');
    }

    if ((!isBlockModalVisible && isBlocked) && !skipMixpanel) {
      mixpanel.track('Unblock Player from Player Profile');
    }

    setDropdownVisible(false);
    return setBlockModalVisible(!isBlockModalVisible);
  };

  const reportModal = (skipMixpanel) => {
    if (!isReportModalVisible && !skipMixpanel) {
      mixpanel.track('Report Player from Player Profile');
    }

    setDropdownVisible(false);
    return setReportModalVisible(!isReportModalVisible);
  };

  const afterDropdownIsVisible = () => {
    const dropdown = optionsDropdownRef?.current?.getBoundingClientRect();
    const overflownLeft = dropdown?.left < 0;
    const overflownRight = dropdown?.right > window?.innerWidth;
    if (overflownLeft) {
      return optionsDropdownRef?.current?.style.setProperty('transform', 'translateX(0%)');
    } if (overflownRight) {
      optionsDropdownRef?.current?.style.setProperty('left', '-30%');
      return optionsDropdownRef?.current?.style.setProperty('transform', 'translateX(-65%)');
    }

    return false;
  };

  useEffect(() => {
    window.addEventListener('resize', afterDropdownIsVisible);
    return () => {
      window.addEventListener('resize', afterDropdownIsVisible);
      afterDropdownIsVisible();
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      switch (message) {
      case 'BLOCK_PLAYER_SUCCESS':
        setIsBlocked(true);
        dispatch(getAccountInfo());
        break;
      case 'UNBLOCK_PLAYER_SUCCESS':
        setIsBlocked(false);
        dispatch(getAccountInfo());
        break;
      default:
        break;
      }
      return addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });
    };

    if (socket) {
      socket.removeAllListeners('friends-response');
      socket.on('friends-response', listenForResponse);

      return () => socket.removeAllListeners('friends-response');
    }
  });

  const transKey = isBlocked ? 'unblockPlayer' : 'blockPlayer';

  return (
    <>
      {isBlocked && (
        <Button
          padding="0 20px 0 10px"
          margin="0 10px 0 0"
          outline
          onClick={() => mixpanel.track('Click [Manage Blocked Players] from Player Profile')}
        >
          <Link to="/my-account/settings?openTab=BlockedPlayers">
            <img
              src={LoadImage('manage-cog-icon.svg')}
              alt={t('profileSettings.blockedPlayers.manageBlockedPlayers')}
            />
            <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.manageBlockedPlayers">
              Manage Blocked Players
            </Trans>
          </Link>
        </Button>
      )}

      <ContainOptions ref={dropdownRef}>
        <ThreeDotsButton outline onClick={() => setDropdownVisible(!isDropdownVisible)}>
          <ThreeDotsIcon src={LoadImage('three-dots-icon.svg')} alt="Player options" />
        </ThreeDotsButton>
        {isDropdownVisible && (
          <Dropdown ref={optionsDropdownRef}>
            <DropdownItemContainer onClick={() => blockModal()}>
              <img src={LoadImage('block-icon.svg')} alt={t(`profileSettings.blockedPlayers.${transKey}`)} />
              <Trans ns="settings" i18nKey={`profileSettings.blockedPlayers.${transKey}`}>
                Unblock Player
              </Trans>
            </DropdownItemContainer>

            <DropdownItemContainer onClick={() => reportModal()}>
              <img src={LoadImage('report-icon.svg')} alt={t('profileSettings.blockedPlayers.reportPlayer')} />
              <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.reportPlayer">
                Report Player
              </Trans>
            </DropdownItemContainer>
          </Dropdown>
        )}
      </ContainOptions>

      {isReportModalVisible && (
        <ReportModal modalHandler={reportModal} />
      )}

      {isBlockModalVisible && (
        <BlockModal modalHandler={blockModal} />
      )}
    </>
  );
};

export default BlockAndReportOptions;
