import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DayPicker } from 'react-day-picker';
import { useSelector } from 'react-redux';
import dateFormat from "dateformat";
import { useHistory } from 'react-router-dom';

import { CalendarInfo } from '../styles';
import { H3, Paragraph } from '../../../../../../components';
import Select from '../../../../../../components/Form/Select';
import { DatePickerContainer } from '../../../../Listing/components/Filters/styles';
import { datePickerInterval, getLocaleByCountry } from '../../../../helpers';
import ToggleVisible from '../../../../../../components/common/ToggleVisible';
import ToggleButton from '../../../../../../components/Form/ToggleButton';
import { getClubCourts } from '../../../../actions';
import timeToInteger from '../helpers/timeToInteger';

const duration = [60, 90, 120];

const CalendarOnMobile = ({
  date,
  idClub,
  bookings,
  bookingHours,
  setBookingInfo,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['clubs', 'friendlyMatches', 'common']);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { accountInfo, masterSport } = useSelector(state => state.session);
  const { data: { activeSports } = {} } = useSelector(state => state.settings);
  const [clubInfo, setClubInfo] = useState({});
  const [availableCourts, setAvailableCourts] = useState([]);
  const [sportType, setSportType] = useState(masterSport);
  const listRef = useRef(null);

  const [payload, setPayload] = useState({
    clubId: idClub,
    courtId: null,
    clubInfo: null,
    startHour: null,
    startDate: date,
    duration: 60,
    availableOnly: false,
  });

  useEffect(() => {
    const filters = {
      timestamp: `${date} 06:00`,
      idClub,
    };
    getClubCourts(filters).then(res => {
      setPayload({
        ...payload,
        clubInfo: res,
      });
      setClubInfo(res);
    });
  }, [date]);

  useEffect(() => {
    if (payload.duration && payload.startHour) {
      const courts = clubInfo?.courts?.filter((court) => (
        court.status === "active"
        && court.sportType === sportType
        && court.pricing.find((price) => (
          price.startHour === payload.startHour
          && price.bookingDuration?.[payload.duration]?.available
        ))
      ));
      setAvailableCourts(courts);
      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [clubInfo, payload.duration, payload.startHour, sportType]);

  useEffect(() => {
    if (masterSport) {
      setSportType(masterSport);
    }
  }, [masterSport]);

  const hourInfo = (hour) => {
    const classNames = ['hour-box'];
    let hourUnavailable = false;

    // filter available hours based on booking duration
    if (clubInfo?.availableHours?.includes(hour)) {
      const courts = clubInfo?.courts?.find((court) => (
        court.status === "active"
        && court.sportType === sportType
        && court.pricing.find((price) => (
          price.startHour === hour
          && price.bookingDuration?.[payload.duration]?.available
        ))
      ));
      if (!courts) {
        hourUnavailable = true;
        classNames.push('hour-box__unavailable');
      }
    }

    // set unavailable hours
    if (!clubInfo?.availableHours?.includes(hour)) {
      hourUnavailable = true;
      classNames.push('hour-box__unavailable');
    }

    // flag my booking
    bookings?.map(({ startHour, myBooking }) => (
      (startHour === timeToInteger(hour) && myBooking) && classNames.push('hour-box__my-booking')
    ));

    if (payload.startHour === hour) {
      classNames.push('hour-box__selected');
    }

    if ((payload.availableOnly && hourUnavailable) || hour === '23:00') {
      return <></>;
    }

    return (
      <div
        key={hour}
        className={classNames.join(" ")}
        onClick={() => setPayload({ ...payload, startHour: hour })}
      >
        {hour}
      </div>
    );
  };

  return (
    <>
      <CalendarInfo mobile>
        <H3><Trans ns="clubs" i18nKey="bookACourt" context={masterSport}>Book a Court</Trans></H3>
        <div className="d-flex row gap-10">
          <div ref={ref} className="position-relative w100">
            <Select
              name="sportType"
              placeholder={t('sportType')}
              onChange={({ value }) => setSportType(value)}
              options={activeSports.map((sport) => ({
                label: t(`sports.${sport}`, { ns: 'common' }),
                value: sport,
              }))}
              width="100%"
              height="50px"
              label={t('sportType')}
              isSearchable
              value={sportType && {
                label: t(`sports.${sportType}`, { ns: 'common' }),
                value: sportType,
              }}
              alignLabel
              lowInput
              className="select-time"
            />

            <Select
              name="date"
              placeholder={t('date')}
              width="100%"
              height="50px"
              label={t('date')}
              isSearchable={false}
              value={payload.startDate && {
                label: payload.startDate,
                value: payload.startDate,
              }}
              alignLabel
              lowInput
              onFocus={() => setIsComponentVisible(!isComponentVisible)}
              menuIsOpen={false}
              className="select-date"
            />

            {isComponentVisible && (
              <DatePickerContainer>
                <DayPicker
                  mode="single"
                  required
                  showOutsideDays
                  locale={getLocaleByCountry(accountInfo?.location?.country)}
                  selected={new Date(payload.startDate)}
                  onSelect={(value) => {
                    const formattedDate = dateFormat(value, 'yyyy-mm-dd');

                    history.push({
                      pathname: history.location.pathname,
                      search: `?date=${formattedDate}`,
                    });

                    setIsComponentVisible(!isComponentVisible);

                    setPayload({
                      ...payload,
                      startDate: formattedDate,
                      startHour: null,
                    });
                  }}
                  {...datePickerInterval()}
                />
              </DatePickerContainer>
            )}
          </div>

          <div className="position-relative w100">
            <Select
              name="time"
              placeholder={t('minBookingTime')}
              onChange={({ value }) => {
                setPayload({
                  ...payload,
                  duration: value,
                  startHour: null,
                });
              }}
              options={duration.map((value) => ({
                label: `${value} minutes`,
                value,
              }))}
              width="100%"
              height="50px"
              label={t('minBookingTime')}
              isSearchable
              value={payload.duration && {
                label: `${payload.duration} minutes`,
                value: payload.duration,
              }}
              alignLabel
              lowInput
              className="select-time"
            />
          </div>
        </div>

        <ToggleButton
          className="toggle"
          active={!!payload?.availableOnly}
          handler={() => {
            setPayload({
              ...payload,
              availableOnly: !payload.availableOnly,
            });
          }}
        >
          <Trans ns="clubs" i18nKey="onlyAvailableSlots">
            Show available slots only
          </Trans>
        </ToggleButton>

        <div className="hours-wrapper">
          {bookingHours.map((hour) => hourInfo(hour))}
        </div>

        <div className="d-flex justify-content-between" ref={listRef}>
          <Paragraph smaller>
            <span className="available" />
            <Trans ns="clubs" i18nKey="available">Available</Trans>
          </Paragraph>
          <Paragraph smaller>
            <span className="not-available" />
            <Trans ns="clubs" i18nKey="notAvailable">Not available</Trans>
          </Paragraph>
          <Paragraph smaller>
            <span className="your-booking" />
            <Trans ns="clubs" i18nKey="yourBooking">Your booking</Trans>
          </Paragraph>
        </div>

        {payload.startHour && (
          <div className="list-courts d-flex flex-column">
            {availableCourts?.map((court) => {
              const pricing = court.pricing.find(({ startHour }) => startHour === payload.startHour);
              return (
                <div key={court.id}>
                  <Paragraph bold className="mb0">{court?.name}</Paragraph>
                  <Paragraph smaller className="mt0">{formatFacilities(court, t)}</Paragraph>
                  <div className="list-courts__prices">
                    {duration.filter((v) => v >= payload.duration).map((v) => (
                      <div
                        className={`
                          list-courts__price-info
                           ${!pricing?.bookingDuration?.[v]?.available ? 'list-courts__price-info--unavailable' : ''}
                        `}
                        onClick={() => {
                          setBookingInfo({
                            ...payload,
                            courtId: court._id,
                            duration: v,
                          });
                        }}
                      >
                        <Paragraph small bold>{v} min</Paragraph>
                        <Paragraph>{pricing?.bookingDuration?.[v]?.price} {clubInfo?.currency}</Paragraph>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </CalendarInfo>
    </>
  );
};

const formatFacilities = (court, t) => {
  const arr = [];
  arr.push(...[
    t(`surfaceType.${court.surface}`),
    t(`courtSizes.${court.size}`),
    t(`courtTypes.${court.type}`),
  ]);

  let str = arr.join(", ");
  if (court?.hasLighting) {
    str += ` (${t('withLighting')})`;
  }

  return str;
};

export default CalendarOnMobile;
