import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Contain,
  H4,
  P,
} from '../../../../../components/Collection';

import LoadImage from '../../../../../components/common/LoadImage';
import Checkbox from '../../../../../components/Form/VanillaCheckbox';
import mixpanel from '../../../../../mixpanel';

import { PlayerName } from '../../../Matches/styles';

import {
  handleScore,
  validateScore,
  formatMatchId,
  getLocalDate,
  getWinner,
} from '../../../helpers';

import {
  DoublesPlayerName,
  PlayerScoreContainer,
  PlayerScoreHeader,
  SetTitlesContainer,
} from '../../../MatchForm/components/CreateMatch/PlayedMatch/components/PlayerScore/styles';

import {
  AvatarAbsoluteContainer,
  AvatarRelativeContainer,
  PlayerAvatar,
} from '../../../MatchForm/components/CreateMatch/PlayedMatch/styles';

import { ModalBackground } from '../../../../../components/Layout/components/Header/components/RaiseHandStyles';
import { PlayerAvatarContainer } from '../../../StartMatch/components/WantToPlay/styles';
import { acceptMatch, addScoreFriendlyMatches } from '../../../reducers';

import {
  AddScoreButton,
  AddScoreInput,
  AddScoreModalContainer,
  AddScoreModalTeam,
  AddScoreTeamContainer,
  AddTieInput,
  CancelButton,
  ScoreInputTitle,
  ScoresContainer,
  TieInputTitle,
  TrophyIcon,
  VS,
} from './styles';

import deleteFromScoreForm from '../../../helpers/deleteFromScoreForm';
import parseScore from '../../../helpers/parseScore';

const AddScoreModal = ({
  modalData,
  closeModalHandler,
}) => {
  const {
    _id,
    matchId,
    player,
    playerPartner,
    opponent,
    opponentPartner,
  } = modalData?.match || {};

  const { isDoubles, setIsExpanded } = modalData || {};

  const dispatch = useDispatch();
  const { t } = useTranslation('friendlyMatches');
  const { accountInfo } = useSelector(state => state.session);
  const { userId } = accountInfo || {};

  const [winner, declareWinner] = useState(null);
  const [scoreError, setScoreError] = useState(false);
  const [winnerError, setWinnerError] = useState(false);
  const [isModalDisabled, disableModal] = useState(true);

  const winnerIsPlayer = winner === 'player';
  const winnerIsOpponent = winner === 'opponent';

  const initialScore = {
    player: {
      score: [
        ...modalData?.match?.player?.score ? [...modalData?.match?.player?.score] : [],
      ],
      ...modalData?.match?.player?.type && { type: modalData?.match?.player?.type, reason: 'ABANDONED' },
    },
    opponent: {
      score: [
        ...modalData?.match?.opponent?.score ? [...modalData?.match?.opponent?.score] : [],
      ],
      ...modalData?.match?.opponent?.type && { type: modalData?.match?.opponent?.type, reason: 'ABANDONED' },
    },
  };

  const [scoreForm, setScoreForm] = useState({ ...initialScore });

  const addScoreHandler = () => {
    if (winner === 'error') {
      setWinnerError(true);
      disableModal(true);
      return null;
    }
    setWinnerError(false);
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const matchStartDate = getLocalDate(modalData?.match?.startDate, modalData?.match?.timezone);
    if (modalData?.type === 'confirmMatchScore') {
      mixpanel.track('CONFIRM PLAYED MATCH', {
        sp_score_status: JSON.stringify(initialScore) !== JSON.stringify(scoreForm) ? 'CHANGED SCORE' : 'SAME SCORE',
        sp_match_type: modalData?.match?.ranked ? 'Ranked' : 'Unranked',
        sp_game_type: modalData?.match?.gameType,
        sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
        sp_start_date: matchStartDate.toString(),
        sp_game_location_city: modalData?.match?.location?.city,
        sp_game_location_country: modalData?.match?.location?.country,
        sp_game_format: modalData?.match?.format,
        sp_match_id: formatMatchId(modalData?.match?.matchId),
      });

      return dispatch(acceptMatch({ id: _id })).then(() => {
        dispatch(addScoreFriendlyMatches({ payload: scoreForm, id: _id }));
        disableModal(false);
        return setIsExpanded(false);
      });
    }
    if (['addScore', 'confirmScore', 'changeScore'].includes(modalData?.type)) {
      const mixpanelEvents = {
        addScore: 'Friendly matches ADD SCORE',
        confirmScore: 'Friendly matches CONFIRM SCORE',
        changeScore: 'Friendly matches CHANGE SCORE',
      };

      mixpanel.track(mixpanelEvents[modalData?.type], {
        ...modalData?.type !== 'addScore' && {
          sp_asset_type: modalData?.match?.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
          sp_score_status: JSON.stringify(initialScore) !== JSON.stringify(scoreForm) ? 'CHANGED SCORE' : 'SAME SCORE',
        },
        sp_match_type: modalData?.match?.ranked ? 'Ranked' : 'Unranked',
        sp_game_type: modalData?.match?.gameType,
        sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
        sp_start_date: matchStartDate.toString(),
        sp_game_location_city: modalData?.match?.location?.city,
        sp_game_location_country: modalData?.match?.location?.country,
        sp_game_format: modalData?.match?.format,
        sp_match_id: formatMatchId(modalData?.match?.matchId),
      });
    }

    const parsedPayload = parseScore(scoreForm);
    return dispatch(addScoreFriendlyMatches({ payload: parsedPayload, id: _id }));
  };

  const handleCheckboxes = (isPlayer) => {
    const newScoreForm = { ...scoreForm };
    const playerType = isPlayer ? 'player' : 'opponent';
    const hasBye = newScoreForm[playerType]?.type;

    if (isPlayer) {
      delete newScoreForm.opponent.type;
    }

    if (!isPlayer) {
      delete newScoreForm.player.type;
    }

    if (hasBye) {
      delete newScoreForm[playerType].type;
    } else {
      newScoreForm[playerType].type = 'BYE';
    }

    declareWinner(getWinner(scoreForm));
    return setScoreForm(newScoreForm);
  };

  const hasPlayerScoreValues = !!(scoreForm?.player?.score)?.filter(obj => obj?.points !== null).length;
  const hasOpponentScoreValues = !!(scoreForm?.opponent?.score)?.filter(obj => obj?.points !== null).length;

  const scoreFormHasPoints = hasPlayerScoreValues || hasOpponentScoreValues;
  const bothPlayersHavePoints = hasPlayerScoreValues && hasOpponentScoreValues;

  const getSet = (number, type) => scoreForm?.[type]?.score.find(item => item?.set === number);

  const [isSecondSetDisabled, setIsSecondSetDisabled] = useState(true);
  const [isThirdSetDisabled, setIsThirdSetDisabled] = useState(true);

  useEffect(() => {
    setIsSecondSetDisabled(
      !getSet(1, 'player')
      || !getSet(1, 'opponent'),
    );

    setIsThirdSetDisabled(
      !getSet(2, 'player')
      || !getSet(2, 'opponent'),
    );

    if (bothPlayersHavePoints) {
      declareWinner(getWinner(scoreForm));
    } else {
      declareWinner(null);
    }

    if (scoreFormHasPoints) {
      let isScoreError = false;
      ['player', 'opponent'].every(team => {
        scoreForm[team].score.every(set => {
          if (scoreForm[team]?.type) {
            return true;
          }

          if (set.points !== null && (set.points < 0 || set.points > 24)) {
            isScoreError = true;
            return false;
          }

          if (set.tie !== null && (set.tie < 0 || set.tie > 24)) {
            isScoreError = true;
            return false;
          }
          return true;
        });
        if (isScoreError) {
          return false;
        }
        return true;
      });

      setScoreError(isScoreError);
      disableModal(isScoreError);
    } else {
      setScoreError(false);
      disableModal(true);
    }
  }, [scoreForm]);

  const areYouTheOpponent = userId === opponent?.userId;
  const areYouTheOpponentPartner = userId === opponentPartner?.userId;
  const areYouThePlayerPartner = userId === playerPartner?.userId;
  const areYouThePlayer = !areYouTheOpponent && !areYouTheOpponentPartner && !areYouThePlayerPartner;

  const nameInitials = {
    get player() {
      const first = player.firstName ? player.firstName?.charAt(0).toUpperCase() : '';
      const last = player.lastName ? player.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get opponent() {
      const first = opponent.firstName ? opponent?.firstName?.charAt(0).toUpperCase() : '';
      const last = opponent.lastName ? opponent?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get playerPartner() {
      const first = playerPartner?.firstName ? playerPartner?.firstName?.charAt(0).toUpperCase() : '';
      const last = playerPartner?.lastName ? playerPartner?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get opponentPartner() {
      const first = opponentPartner?.firstName ? opponentPartner?.firstName?.charAt(0).toUpperCase() : '';
      const last = opponentPartner?.lastName ? opponentPartner?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
  };

  const opponentTranslation = { ns: 'friendlyMatches', i18nKey: isDoubles ? 'opponentTeam' : 'scoresData.opponent' };

  const firstScoreColumnTabOrder = {
    0: 1,
    1: 3,
    2: 5,
  };

  const secondScoreColumnTabOrder = {
    0: 2,
    1: 4,
    2: 6,
  };

  return (
    <>
      <ModalBackground />
      <AddScoreModalContainer>
        <H4>
          <Trans ns="friendlyMatches" i18nKey={modalData?.type}>Add Score</Trans>
        </H4>

        <P className="text-center">
          <Trans ns="friendlyMatches" i18nKey="matchId">
            Match ID:
            {{ matchId: formatMatchId(matchId) }}
          </Trans>
        </P>

        <PlayerScoreContainer>
          <PlayerScoreHeader>
            <AddScoreModalTeam marginOnMobile>
              <AvatarRelativeContainer>
                <AvatarAbsoluteContainer isDoubles={isDoubles && playerPartner}>
                  <PlayerAvatarContainer disableMargin>
                    <PlayerAvatar
                      isDoubles={isDoubles && playerPartner}
                      winner={winnerIsPlayer}
                      hasInitials={!player?.profilePicture}
                      bg={LoadImage(`accounts/${player?.userId}/${player?.profilePicture}`, true)}
                    >
                      {!player?.profilePicture
                        && (<P bold>{nameInitials?.player}</P>)}
                    </PlayerAvatar>
                    {!isDoubles && (
                      <PlayerName small noWidth>
                        {winnerIsPlayer && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                        {areYouThePlayer ? (
                          <Trans ns="conversations" i18nKey="you">
                            You
                          </Trans>
                        ) : (player?.firstName)}
                      </PlayerName>
                    )}
                  </PlayerAvatarContainer>
                </AvatarAbsoluteContainer>

                {isDoubles && playerPartner && (
                  <AvatarAbsoluteContainer isPartner isDoubles={isDoubles && playerPartner}>
                    <PlayerAvatarContainer disableMargin isPartner>
                      <PlayerAvatar
                        isPartner
                        isDoubles={isDoubles}
                        winner={winnerIsPlayer}
                        hasInitials={!playerPartner?.profilePicture}
                        bg={LoadImage(`accounts/${playerPartner?.userId}/${playerPartner?.profilePicture}`, true)}
                      >
                        {!playerPartner?.profilePicture && (<P bold>{nameInitials?.playerPartner}</P>)}
                      </PlayerAvatar>
                    </PlayerAvatarContainer>
                  </AvatarAbsoluteContainer>
                )}

                {isDoubles && (
                  <DoublesPlayerName>
                    <PlayerName small maxWidth={playerPartner} winner={winnerIsPlayer} noWidth disalbeAdditionalMargins>
                      {winnerIsPlayer && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                      {areYouThePlayer ? (
                        <Trans ns="conversations" i18nKey="you">
                          You
                        </Trans>
                      ) : (player?.firstName)}
                      {playerPartner && (<>&nbsp; &amp;</>)}
                    </PlayerName>
                    {playerPartner && (
                      <PlayerName small maxWidth winner={winnerIsPlayer}>
                        {areYouThePlayerPartner ? (
                          <Trans ns="conversations" i18nKey="you">
                            You
                          </Trans>
                        ) : playerPartner?.firstName || (
                          <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                            Player 2
                          </Trans>
                        )}
                      </PlayerName>
                    )}
                  </DoublesPlayerName>
                )}
              </AvatarRelativeContainer>
            </AddScoreModalTeam>
            <VS>VS</VS>
            <AddScoreModalTeam>
              <AvatarRelativeContainer>
                <AvatarAbsoluteContainer isDoubles={isDoubles && opponentPartner}>
                  <PlayerAvatarContainer disableMargin>
                    <PlayerAvatar
                      isDoubles={isDoubles}
                      winner={winnerIsOpponent}
                      hasInitials={!opponent?.profilePicture}
                      bg={LoadImage(`accounts/${opponent?.userId}/${opponent?.profilePicture}`, true)}
                    >
                      {!opponent?.profilePicture && (<P bold>{nameInitials?.opponent}</P>)}
                    </PlayerAvatar>
                    {!isDoubles && (
                      <PlayerName small noWidth>
                        {winnerIsOpponent && (
                          <TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />
                        )}
                        {areYouTheOpponent ? (
                          <Trans ns="conversations" i18nKey="you">
                            You
                          </Trans>
                        ) : opponent?.firstName
                        || (
                          <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                            Player 2
                          </Trans>
                        )}
                      </PlayerName>
                    )}
                  </PlayerAvatarContainer>
                </AvatarAbsoluteContainer>

                {isDoubles && opponentPartner && (
                  <AvatarAbsoluteContainer isPartner isDoubles={isDoubles && opponentPartner}>
                    <PlayerAvatarContainer disableMargin isPartner>
                      <PlayerAvatar
                        isPartner
                        isDoubles={isDoubles}
                        winner={winnerIsOpponent}
                        hasInitials={!opponentPartner?.profilePicture}
                        profilePicture={opponentPartner?.profilePicture}
                        bg={LoadImage(`accounts/${opponentPartner?.userId}/${opponentPartner?.profilePicture}`, true)}
                      >
                        {!opponentPartner?.profilePicture && (<P bold>{nameInitials?.opponentPartner}</P>)}
                      </PlayerAvatar>
                    </PlayerAvatarContainer>
                  </AvatarAbsoluteContainer>
                )}

                {isDoubles && (
                  <DoublesPlayerName>
                    {opponent && (
                      <PlayerName
                        small
                        maxWidth={opponentPartner}
                        winner={winnerIsOpponent}
                        noWidth
                        disalbeAdditionalMargins
                      >
                        {winnerIsOpponent && (
                          <TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />
                        )}
                        {areYouTheOpponent ? (
                          <Trans ns="conversations" i18nKey="you">
                            You
                          </Trans>
                        ) : opponent?.firstName}
                        {opponentPartner && (<>&nbsp; &amp;</>)}
                      </PlayerName>
                    )}
                    <PlayerName small maxWidth winner={winnerIsOpponent} noWidth>
                      {areYouTheOpponentPartner ? (
                        <Trans ns="friendlyMatches" i18nKey="you">
                          You
                        </Trans>
                      ) : opponentPartner?.firstName || (
                        <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                          Player 2
                        </Trans>
                      )}
                    </PlayerName>
                  </DoublesPlayerName>
                )}
              </AvatarRelativeContainer>
            </AddScoreModalTeam>
          </PlayerScoreHeader>

          <Contain direction="column" align="center">
            <ScoresContainer>
              <AddScoreTeamContainer marginOnMobile>
                <Contain width="100%">
                  <ScoreInputTitle>
                    <Trans ns="friendlyMatches" i18nKey="score">
                      Score
                    </Trans>
                  </ScoreInputTitle>
                  <TieInputTitle>
                    <Trans ns="friendlyMatches" i18nKey="tie">
                      Tie
                    </Trans>
                  </TieInputTitle>
                </Contain>
                {[...Array(3)]?.map((_, key) => (
                  <AddScoreModalTeam row key={key}>
                    <AddScoreInput
                      tabIndex={firstScoreColumnTabOrder[key]}
                      defaultValue={scoreForm.player?.score?.[key]?.points}
                      onKeyDown={(e) => (
                        e.key === 'Backspace'
                        && e.target.value < 10
                        && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), true))
                      )}
                      onInput={(e) => validateScore(e)}
                      onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, true, false))}
                      {...key === 1 && isSecondSetDisabled && { disabled: true }}
                      {...key === 2 && isThirdSetDisabled && { disabled: true }}
                    />

                    <AddTieInput
                      defaultValue={scoreForm.player?.score?.[key]?.tie}
                      onKeyDown={(e) => (
                        e.key === 'Backspace'
                        && e.target.value < 10
                        && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), true, true))
                      )}
                      onInput={(e) => validateScore(e)}
                      onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, true, true))}
                      {...key === 1 && isSecondSetDisabled && { disabled: true }}
                      {...key === 2 && isThirdSetDisabled && { disabled: true }}
                    />
                  </AddScoreModalTeam>
                ))}
                <Contain width="100%" margin="5px 0 0 0">
                  <Checkbox
                    onChange={() => handleCheckboxes(true)}
                    checked={!!scoreForm.player?.type}
                    label={t('abandoned')}
                  />
                </Contain>
              </AddScoreTeamContainer>

              <SetTitlesContainer>
                {[...Array(3)]?.map((_, key) => (
                  <AddScoreModalTeam middle key={key}>
                    Set
                    {' '}
                    {key + 1}
                  </AddScoreModalTeam>
                ))}
              </SetTitlesContainer>

              <AddScoreTeamContainer>
                <Contain width="100%">
                  <ScoreInputTitle>
                    <Trans ns="friendlyMatches" i18nKey="score">
                      Score
                    </Trans>
                  </ScoreInputTitle>
                  <TieInputTitle>
                    <Trans ns="friendlyMatches" i18nKey="tie">
                      Tie
                    </Trans>
                  </TieInputTitle>
                </Contain>

                {[...Array(3)]?.map((_, key) => (
                  <AddScoreModalTeam row key={key}>
                    <AddScoreInput
                      tabIndex={secondScoreColumnTabOrder[key]}
                      defaultValue={scoreForm.opponent?.score?.[key]?.points}
                      onKeyDown={(e) => (
                        e.key === 'Backspace'
                        && e.target.value < 10
                        && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), false))
                      )}
                      onInput={(e) => validateScore(e)}
                      onChange={(e) => (
                        setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, false, false, e.key))
                      )}
                      {...key === 1 && isSecondSetDisabled && { disabled: true }}
                      {...key === 2 && isThirdSetDisabled && { disabled: true }}
                    />
                    <AddTieInput
                      defaultValue={scoreForm.opponent?.score?.[key]?.tie}
                      onKeyDown={(e) => (
                        e.key === 'Backspace'
                        && e.target.value < 10
                        && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), false, true))
                      )}
                      onInput={(e) => validateScore(e)}
                      onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, false, true))}
                      {...key === 1 && isSecondSetDisabled && { disabled: true }}
                      {...key === 2 && isThirdSetDisabled && { disabled: true }}
                    />
                  </AddScoreModalTeam>
                ))}

                <Contain width="100%" margin="5px 0 0 0">
                  <Checkbox
                    onChange={() => handleCheckboxes(false)}
                    checked={!!scoreForm.opponent?.type}
                    label={t('abandoned')}
                  />
                </Contain>
              </AddScoreTeamContainer>
            </ScoresContainer>
          </Contain>
        </PlayerScoreContainer>

        {isModalDisabled && scoreError && (
          <P xSmall color="error" textAlign="center">
            <Trans ns="friendlyMatches" i18nKey="scoreValidationMaxValue">Accepted values: 0-24</Trans>
          </P>
        )}

        {isModalDisabled && winnerError && (
          <P xSmall color="error" textAlign="center">
            <Trans ns="friendlyMatches" i18nKey="scoreValidationInvalidWinner">
              Please double-check your score and fill in the correct and
              complete score so that a winner/winning team can be determined.
            </Trans>
          </P>
        )}

        <P xSmall textAlign="center" margin="0 0 20px 0">
          <Trans ns="friendlyMatches" i18nKey={`${modalData?.type}Info`}>
            The score will be pending acceptance from your opponent within 48h.
          </Trans>
        </P>

        <AddScoreButton disabled={isModalDisabled} onClick={() => addScoreHandler()}>
          <Trans ns="friendlyMatches" i18nKey={modalData?.type}>Add Score</Trans>
        </AddScoreButton>
        <CancelButton outline onClick={() => closeModalHandler()}>
          <Trans ns="friendlyMatches" i18nKey="cancel">Cancel</Trans>
        </CancelButton>
      </AddScoreModalContainer>
    </>
  );
};

export default AddScoreModal;
