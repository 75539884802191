import React from 'react';
import { Trans } from 'react-i18next';

import { BaskedItemStyle, ChangeButton } from './styles';
import Paragraph from '../../../../../components/Paragraph';

const BasketItem = ({
  qty, name, price, currency, change = true,
}) => (
  <>
    <BaskedItemStyle>
      <Paragraph className="productName" small>
        {qty}
        x
        {' '}
        {name}
      </Paragraph>
      <Paragraph small className="price">
        {price}
        {' '}
        {currency}
      </Paragraph>
    </BaskedItemStyle>
    {change
      && (
        <ChangeButton>
          <Paragraph smaller className="change">
            <Trans ns="events" i18nKey="change">Change</Trans>
          </Paragraph>
        </ChangeButton>
      )}
  </>
);

export default BasketItem;
