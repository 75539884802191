import styled from '@emotion/styled';
import LoadImage from '../../../../../components/common/LoadImage';

export const Badge = styled.div(props => ({
  ...props.position && { position: props.position },
  ...props.top && { top: props.top },
  ...props.left && { left: props.left },
  ...props.right && { right: props.right },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: props.small ? '16px' : '50px',
  height: props.small ? '18px' : '56px',
  background:
    props.gold ? `url(${LoadImage('shield-gold.svg')})`
      : props.silver ? `url(${LoadImage('shield-silver.svg')})`
        : `url(${LoadImage('shield.svg')})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  p: {
    color: `${props.gold ? props.theme.colors.gold : props.theme.colors.white} !important`,
    padding: '0 0 1px 0 !important',
  },
}));
