import styled from '@emotion/styled';
import { P } from '../../../../../components/Collection';

export const CompetitionFee = styled(P)(props => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: props.bottom ? '0' : '20px',
  width: 'max-content',
  '@media (max-width: 1109px)': {
    bottom: props.bottom ? '-35px' : '-20px',
  },
  '@media (max-width: 767px)': {
    bottom: props.bottom ? '-10px' : '10px',
  },
}));

export const LevelModalContainer = styled.div({
  textAlign: 'center',
  padding: '40px',
});

export const WithdrawModalStyle = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 40px;

  .withdrawTitle
  {
    margin-bottom: 20px;
  }
  
  .closeWrap
  {
    margin: 20px 0 40px;
    width: max-content;

    > button
    {
      width: 270px;
    }
  }

  h3
  {
    max-width: 450px;
    width: 100%;
  }

  .mt50
  {
    margin-top: 50px;
  }

  .host-info
  {
    background: #EAF6F6;
    border-radius: 10px;
    margin-top: 40px;
    padding: 20px;

    p > a
    {
      color: #10242C;
    }
  }

  ${props => props.theme.functions?.mediaQueryMax('800px', `
    .modalButtonsWrapp{
      flex-direction:column;
      align-items:center;
      button{
        max-width:290px !important;
        margin:10px 0;
      }
    }
  `)}

    h3
    {
      font-weight: bold;
      line-height: 36px;
      text-align:center;
      margin-bottom:20px;
    }

    .withdrawInfo{
      margin-top:20px;
    }

    p
    {
      text-align:center;
      span{color:${({ theme }) => theme?.colors?.turquoise}};
      max-width: 490px;
     }

  .modalButtonsWrapp{
    display:flex;
    // max-width: 347px;
    width:100%;
    margin: 0 auto;
    justify-content:space-evenly;
    margin-top:35px;
    button:first-of-type{
      max-width:158px;
      width:100%;
    }
    button{
      max-width:174px;
      width:100%;
    }
  }
`;

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '170px',
  margin: '0 0 26px 0',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
    height: 'unset',
    padding: '20px',
    margin: '0 0 20px 0',
  },
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const LevelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '136px',
  height: '100%',
  minWidth: '143px',
  '@media (max-width: 1190px)': {
    borderRight: 'unset',
  },
});

export const DataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const Info = styled.div({
  padding: '50px 40px 0 0',
  width: 'fit-content',
  '&:last-of-type': {
    padding: '50px 0 0 0',
  },
  '@media (max-width: 1190px)': {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'unset',
    '&:last-of-type': {
      padding: 'unset',
    },
  },
});

export const Lists = styled.div({
  display: 'flex',
  padding: '0 0 40px 40px',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
  },
});

export const Data = styled.div({
  display: 'flex',
  height: 'inherit',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
  },
});

export const Item = styled(P)({

});

export const Value = styled.span({
  height: '100%',
  margin: '0 0 0 5px',
  fontWeight: 400,
  '&:after': {
    content: '","',
  },
  '&:last-of-type:after': {
    content: '""',
  },
});

export const Badge = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  height: 'max-content',
  borderRadius: '20px',
  width: 'fit-content',
  margin: '10px 10px 10px 0',
  background: props.theme.colors?.[props.bg],
  color: props.theme.colors?.[props.textcolor],
  '@media (max-width: 1190px)': {
    margin: '10px 0',
  },
}));
