import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PubSub from 'pubsub-js';

import { get } from '../../../../components/common/http';

// Actions
export const fetchCompetitions = createAsyncThunk('events/fetchCompetitions', async (userId) => {
  try {
    const { data: { data } } = await get(`/accounts/${userId}/events/upcoming`);

    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// Slice
const slice = createSlice({
  name: 'competitions',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    removeCompetitions: (state) => {
      state.data = {};
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: {
    [fetchCompetitions.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCompetitions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchCompetitions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  removeCompetitions,
} = slice.actions;

export default slice.reducer;
