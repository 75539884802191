import { get } from '../../../../components/common/http';

export const firstTaskRewardCompleted = async () => {
  try {
    const { data } = await get('/accounts/rewards/first-task-completion');
    return data?.data;
  } catch (error) {
    return false;
  }
};
