import styled from '@emotion/styled';

export const PlayerContainer = styled.div(({ margin }) => ({
  display: 'flex',
  margin: margin || '8.5px',
  height: '38px',
  width: '100%',
}));

export const PlayerMeta = styled.div({
  display: 'flex',
  width: '100%',
});

export const Avatar = styled.img({
  width: '38px',
  height: '38px',
  margin: '0 8px 0 0',
  objectFit: 'contain',
});

export const Button = styled.div(({
  contact, emptyLFP, lfpBanner, playerButton,
}) => ({
  display: 'flex',
  minHeight: '34px',
  minWidth: '144px',
  alignItems: 'center',
  justifyContent: 'center',
  background: (contact || lfpBanner) ? '#fff' : '#10242C',
  color: (contact || lfpBanner) ? '#10242C' : '#fff',
  borderRadius: '4px',
  padding: emptyLFP ? '14.5px 40px' : lfpBanner ? '10px 20px' : '7px 27px',
  margin: playerButton ? '0 20px 0 auto' : emptyLFP ? '20px auto 0 auto' : lfpBanner ? '0 0 0 20px' : '0 0 0 auto',
  cursor: 'pointer',
  ...(contact || lfpBanner) && {
    border: '2px solid #10242C',
  },

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: emptyLFP ? '16px' : '12px',
  lineHeight: emptyLFP ? '21px' : '16px',

  ...!contact && !lfpBanner && {
    transition: '.2s all ease-out',
    '&:hover': {
      background: '#1F3239',
    },
  },
  '@media (max-width: 700px)': {
    margin: 'unset',
    ...!lfpBanner && {
      width: '95%',
    },
  },
}));
