import styled from '@emotion/styled';

export const Bell = styled.div(props => ({
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  '> img': {
    padding: 8,
  },

  '.new-notifications': {
    position: 'absolute',
    top: '-2px',
    right: '-5px',
    border: `2px solid ${props.bellWhite ? '#092e37' : '#ffffff'}`,
    marginLeft: 5,
    background: props.theme.colors.darkRed,
    color: 'white',
    width: 22,
    height: 22,
    textAlign: 'center',
    padding: 2,
    borderRadius: '50%',
    fontSize: 11,
    fontWeight: 700,
    pointerEvents: 'none',

    '&.Myaccount': {
      borderColor: props.theme.colors.lightBlue,
    },

    '&.FindPlayers': {
      borderColor: props.theme.colors.lightBlue,
    },

    '&.StartingGuide': {
      borderColor: '#106a74',
    },
  },

  '@media (max-width: 767px)': {
    marginRight: 10,
  },

  '@media (max-width: 479px)': {
    marginRight: 0,
  },
}));

export const NotificationDropdownList = styled.div(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50px',
  left: 'auto',
  right: -55,
  border: '1px solid #C1E6E5',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  background: '#fff',
  overflow: 'hidden',
  padding: '20px 7px 0 7px',
  minWidth: '380px',
  zIndex: 10,
  cursor: 'initial',

  '.see-all': {
    a: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: 56,
      color: theme.colors.primary,
    },
  },

  '@media (max-width: 1023px)': {
    position: 'fixed',
    top: '15px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 30px)',
    maxHeight: 'calc(100vh - 30px)',
    minWidth: 'unset',
  },
}));

export const NotificationsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%',
  maxHeight: '65vh',
});

export const ProfileDropdownContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: '46px',
  width: '46px',
  '@media (max-width: 1023px)': {
    height: '40px',
    width: '40px',
  },
});

export const Avatar = styled.img(props => ({
  objectFit: 'cover',
  objectPosition: 'top',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '50%',
  border: `1px solid ${props.borderColor ? '#ffffff4f' : '#10242C'}`,

  '@media (max-width: 767px)': {
    height: '40px',
    width: '40px',
  },
}));

export const Initials = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '50%',
  border: `1px solid ${props.borderColor ? '#ffffff4f' : '#10242C'}`,

  '@media (max-width: 767px)': {
    height: '40px',
    width: '40px',
  },
}));

export const DropdownMenu = styled.div({
  display: 'flex',
  minWidth: '260px',
  height: 'max-content',
  borderRadius: '10px',
  position: 'absolute',
  border: '1px solid #C1E6E5',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  padding: '15px 20px 0px 20px',
  background: '#fff',
  overflow: 'auto',
  zIndex: 2,
  top: '60px',
  right: 0,
  '.header > div:first-of-type': {
    marginBottom: '10px',
  },
  '.logout': {
    display: 'flex',
    textDecoration: 'none',
  },
  '@media (max-width: 767px)': {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    maxHeight: '85vh',
    width: 'calc(100% - 50px)',
  },
  '@media (max-width: 479px)': {
    width: 'calc(100% - 20px)',
  },
  '@media screen and (max-height: 740px) and (max-width: 900px)': {
    top: '10px',
    maxHeight: 'calc(100vh - 135px)',
  },

  hr: {
    width: "100%",
    border: "none",
    borderBottom: '1px solid #EAF6F6',
    margin: "15px 0",
  },
});

export const ProfileDropdownLogout = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: '60px',
  borderTop: '1px solid #EAF6F6',
});

export const List = styled.ul(({
  listStyle: 'none',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  // marginTop: '20px',
  '@media (max-width: 479px)': {
    maxHeight: 'calc(100vh - 300px)',
  },
}));

export const Item = styled.li({
  margin: "2px 0",

  p: {
    marginTop: 0,
    marginBottom: 0,
  },

  "&:first-of-type": {
    a: {
      paddingTop: 0,
    },
  },

  a: {
    display: 'flex',
    padding: '10px 0',
  },
});
