/** @jsxImportSource @emotion/react */
import React from 'react';

import { css, useTheme } from '@emotion/react';

const Badge = ({ children, ...props }) => {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <div
      css={css`
        background-color: ${props.bg ? colors[props.bg] : 'transparent' };
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
        padding: ${props.smallbadge ? '4px 8px' : '8px 22px'};
        border-radius: 40px;
        border: 2px solid ${props.border ? props.border : 'transparent' };
        color: ${props.textcolor ? props.textcolor : '#000' };
        font-size: ${props.smallbadge ? '12px' : '14px'};
        font-weight: ${props.textweight ? props.textweight : '600' };
        width: max-content;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Badge;
