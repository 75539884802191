import React from 'react';
import { Trans } from 'react-i18next';
import { Contain } from '../../../../components/Collection';
import { Tab } from '../../styles';
import { GameTypeTab } from './styles';

const GameType = ({ gameType, setGameType }) => {
  const active = tab => tab === gameType;

  return (
    <Contain width="fit-content">
      <GameTypeTab active={active('singles')} onClick={() => setGameType('singles')}>
        <Tab bold active={active('singles')}>
          <Trans ns="events" i18nKey="singles">
            Singles
          </Trans>
        </Tab>
      </GameTypeTab>
      <GameTypeTab active={active('doubles')} onClick={() => setGameType('doubles')}>
        <Tab bold active={active('doubles')}>
          <Trans ns="events" i18nKey="doubles">
            Doubles
          </Trans>
        </Tab>
      </GameTypeTab>
    </Contain>
  );
};

export default GameType;
