import React from 'react';
import { Trans } from 'react-i18next';
import { Item, Value } from '../styles';

const Index = ({ restrictions, competitionInfo }) => {
  const doublesRestrictionLevel = competitionInfo?.level;
  const doublesMaxPlayerLevel = competitionInfo?.level - 0.5;
  return (
    <>
      {restrictions?.includes(true) ? (
        <Item bold>
          <Trans ns="events" i18nKey="restrictions">Restrictions</Trans>
          :&nbsp;
          {restrictions[2] ? (
            <Value><Trans ns="common" i18nKey={`only${competitionInfo?.restrictions?.gender}`}>Gender</Trans></Value>
          ) : ''}
          {restrictions[3] && !restrictions[4] ? (
            <Value>
              <Trans ns="common" i18nKey="minage">Min. Age</Trans>
              {' '}
              {`${competitionInfo?.restrictions?.minAge}+`}
            </Value>
          ) : ''}
          {restrictions[4] && !restrictions[3] ? (
            <Value>
              <Trans ns="common" i18nKey="maxage">Max. Age</Trans>
              {' '}
              {competitionInfo?.restrictions?.maxAge}
            </Value>
          ) : ''}
          {restrictions[4] && restrictions[3] ? (
            <Value>
              <Trans ns="common" i18nKey="age">Age</Trans>
              {' '}
              {`${competitionInfo?.restrictions?.minAge ?? 0}-${competitionInfo?.restrictions?.maxAge}`}
            </Value>
          ) : ''}
          {restrictions[0] ? (
            <Value><Trans ns="events" i18nKey={`min${competitionInfo?.restrictions?.accountActivity}event`} /></Value>
          ) : ''}
          {restrictions[1] ? (
            <Value><Trans ns="events" i18nKey={competitionInfo?.restrictions?.accountType} /></Value>
          ) : ''}
          {restrictions[5] ? (
            <Value>
              <Trans ns="common" i18nKey="rank">Rank</Trans>
              {' '}
              {`${competitionInfo?.restrictions?.rank ?? 0}+`}
            </Value>
          ) : ''}
          {restrictions[6] ? (
            <Value>
              <Trans ns="events" i18nKey="restrictionsDoubleLevel">
                Maximum Doubles player level
                {{ level: doublesRestrictionLevel }}
              </Trans>
            </Value>
          ) : ''}
          {restrictions[7] ? (
            <Value>
              <Trans ns="events" i18nKey="restrictionsTeamLevel">
                Team max.level -
                {{ level: doublesMaxPlayerLevel }}
              </Trans>
            </Value>
          ) : ''}
        </Item>
      ) : ''}
    </>
  );
};

export default Index;
