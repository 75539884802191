import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Contain,
  H4,
  P,
} from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import { logout } from '../../../../components/Layout/reducers/session';
import {
  Alert,
  Container,
  EmailIcon,
  NonBlurContainer,
} from './styles';

const ConfirmEmail = ({ handleResetPassword, emailReset, isDashboard }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return isDashboard ? (
    <Container>
      <NonBlurContainer>
        <EmailIcon src={LoadImage('email-icon.svg')} alt="Check your Email" />
        <H4 margin="20px 0" textAlign="center" maxWidth="320px">
          <Trans ns="dashboard" i18nKey="confirmEmailTitle">
            Please check your email.
            {' '}
            <br />
            {' '}
            We need to verify your email address before using
            {' '}
            {process.env.REACT_APP_SITE_NAME}
            .
          </Trans>
        </H4>

        <P margin="20px 0" textAlign="center" maxWidth="320px">
          <Trans ns="dashboard" i18nKey="confirmEmailSubtitle">
            Didn&apos;t receive the mail? Resend the verification email to finish confirming your email address.
          </Trans>
        </P>

        <Contain
          direction="column"
          minWidth="286px"
        >
          <Button
            width="100%"
            margin="0 0 10px 0"
            outline
            onClick={handleResetPassword}
            disabled={emailReset}
          >
            <Trans ns="dashboard" i18nKey="sendItAgain">Send it again</Trans>
          </Button>
          <Button onClick={handleLogout}>
            <Trans ns="common" i18nKey="logout">Log Out</Trans>
          </Button>
        </Contain>
      </NonBlurContainer>
    </Container>
  ) : (
    <Alert>
      <P small margin="unset">
        <Trans ns="dashboard" i18nKey="verifyYourEmailAddress">
          <strong>Please check your mail.</strong>
          {' '}
          We need you to verify your email address before using our platform.
        </Trans>
      </P>
      <P small bold onClick={handleResetPassword} margin="0 0 0 auto" cursor="pointer">
        <Trans ns="dashboard" i18nKey="sendItAgain">Send it again</Trans>
      </P>
    </Alert>
  );
};

export default ConfirmEmail;
