import React from 'react';
import dateFormat from 'dateformat';
import { Trans } from 'react-i18next';
import { PenaltyEventContainer, PenaltyPoints, PenaltyReason } from '../styles';
import { EventName } from '../../Activity/styles';
import { Contain, P } from '../../../../../components/Collection';

const PenaltyEvent = ({ event }) => {
  const {
    name, _id, startDate, points,
  } = event || {};
  return (
    <PenaltyEventContainer>
      <Contain direction="column">
        <EventName to={`/events/${_id}`}>{name}</EventName>
        <P>{dateFormat(startDate, 'dd mmm yyyy')}</P>
      </Contain>
      <PenaltyReason>
        <P small margin="12px 0 0 0">
          <Trans ns="events" i18nKey="penalty">
            Penalty
          </Trans>
        </P>
        <P small bold margin="unset">
          <Trans ns="events" i18nKey="announcedWithdrawal">
            Announced Withdrawal
          </Trans>
        </P>
      </PenaltyReason>
      <PenaltyPoints small bold>
        {`${points || 0} pts.`}
      </PenaltyPoints>
    </PenaltyEventContainer>
  );
};

export default PenaltyEvent;
