export default {
  chats: 'Разговори',
  startChat: 'Започнете разговор',
  findPlayersAndStartConversation: 'Намерете играчи и започнете разговор',
  seeFriendsList: 'Вижте Списък с приятели',
  disconnected: 'Прекъсната връзка. Опит за повторно свързване...',
  isTyping: '{{ user }} пишете...',
  online: 'Онлайн',
  offline: 'Извън линия',
  viewProfile: 'Виж профил',
  startGroupChat: 'Започнете групов чат с {{ user }}',
  muteConversation: 'Изключете известията',
  unmuteConversation: 'Включете известията',
  leaveConversation: 'Напусни разговора',
  deleteConversation: 'Изтрий разговора',
  allMessagesLoaded: 'Всички съобщения бяха заредени.',
  messageDeleted: 'This message has been deleted.',
  system: 'Система',
  deleteMessage: 'Изтрийте съобщението',
  typeMessage: 'Въведете съобщение...',
  groupChat: 'Групов разговор',
  groupMembers: 'Участници',
  noOfParticipants: '{{ participants }} участника',
  groupName: 'Ти, {{ groupNamesList }}',
  addParticipants: 'Добавете участници',
  seeGroupMembers: 'Вижте Участници',
  muteGroup: 'Изключете известията',
  unmuteGroup: 'Включете известията',
  leaveGroup: 'Напусни разговора',
  deleteGroup: 'Изтрий разговора',
  cancel: 'Отказ',
  createGroup: 'Създавайте',
  yourFriends: 'Вашите приятели',
  add: 'Добавете',
  you: 'Ти',
  leave: 'Напусни',
  delete: 'Изтрий',
  playerBlocked: 'You’ve blocked this player',
  left: 'Left',
  openOrStartChat: 'Отворете един от съществуващите <br/> разговори или започнете нов.',
  leaveConfirmationGroup: 'Сигурни ли сте, че искате да напуснете този групов чат?',
  deleteConfirmationGroup: 'Сигурни ли сте, че искате да изтриете този групов чат?',
  deleteConfirmationConversation: 'Сигурни ли сте, че искате да изтриете този разговор?',
  USER_HAS_LEFT_CONVERSATION: '<i><strong>{{ user }}</strong> напусна разговора.</i>',
  PLAYER_ADDED_TO_CONVERSATION: '<i><strong>{{ user }}</strong> беше добавен към разговора от <strong>{{ addedBy }}</strong>.</i>',
  SUCCESSFULLY_INITIATED_CHAT: 'Разговорът започна успешно.',
  COULD_NOT_INITIATE_CHAT: 'Разговорът не можа да бъде започнат. Опитайте отново по-късно.',
  SUCCESSFULLY_MUTED_CONVERSATION: 'Известията за този разговор са изключени.',
  SUCCESSFULLY_UNMUTED_CONVERSATION: 'Известията за този разговор са активирани отново.',
  SUCCESSFULLY_LEFT_CONVERSATION: 'Успешно излязохте от разговора.',
  SUCCESSFULLY_DELETED_CONVERSATION: 'Разговорът е изтрит.',
  INTERNAL_ERROR: 'Системата идентифицира вътрешна грешка. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.',
  tryAgain: 'Опитай пак',
  failedToLoadConversations: 'Неуспешно зареждане на разговорите',
  leftGroupChat: 'Вече не можете да изпращате съобщения в този групов разговор, защото вече не сте участник.',
  unreadMessages: '{{ messages }} непрочетени съобщения',
};
