import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';
import ComponentContainer from '../../components/Container';
import { Link } from '../../components';

export const Container = styled(ComponentContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 920,
  margin: "60px auto",

  ".heading": {
    "@media all and (min-width: 992px)": {
      maxWidth: "calc(100% - 100px)",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  ".actions": {
    listStyleType: "none",

    li: {
      padding: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,

      img: {
        marginRight: 10,
      },
    },
  },
});

export const AvatarContainer = styled.div({
  userSelect: 'none',
  position: "relative",
  margin: "auto",

  ".checked": {
    position: "absolute",
    right: -12,
    top: -2,
  },
});

export const Avatar = styled.img({
  objectFit: 'cover',
  width: '80px',
  height: '80px',
  userSelect: 'none',
  borderRadius: '50%',
  border: '2px solid #fff',
});

export const Initials = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '80px',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '50%',
  border: '1px solid #10242C',
  margin: '95px 0 10px 0',
  background: '#fff',
  '@media (max-height: 745px)': {
    margin: '40px 0 10px 0',
  },
}));

export const URLContainer = styled.div({
  alignItems: "center",
  borderRadius: 4,
  border: '1px solid #C1E6E5',
  background: '#fff',
  display: 'flex',
  justifyContent: "space-between",
  width: "100%",
  flexDirection: "column",

  '@media (max-width: 480px)': {
    flexDirection: 'column',
  },
});

export const InnerSection = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  paddingLeft: 20,

  ".d-flex": {
    minWidth: "75%",
    justifyContent: "end",
  },
});

export const ReferralContainer = styled.div({
  background: `url(${LoadImage('mgm/refer-via-bg.png')})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '10px',
  width: '100%',
  padding: 40,
  marginBottom: 40,
  textAlign: "center",

  "@media all and (max-width: 767px)": {
    padding: 20,
  },
});

export const InvitedPeopleContainer = styled.div(({ theme }) => ({
  margin: "40px auto",
  width: "100%",
  textAlign: "center",

  ".badge": {
    padding: "10px 30px",
    background: "#FFCD1A",
    borderRadius: 100,
    display: "inline-block",
  },

  ul: {
    listStyleType: "none",
    width: "100%",
    display: "grid",
    gridGap: "10px 65px",
    gridTemplateColumns: "repeat(5, auto)",
    marginTop: 40,

    li: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10px 0 20px 0",

      ".placeholder-text": {
        color: theme.colors.lightTurquoise,
      },
    },
  },

  "@media all and (max-width: 991px)": {
    ul: {
      gridTemplateColumns: "repeat(4, auto)",
    },
  },

  "@media all and (max-width: 767px)": {
    ul: {
      gridGap: "10px 35px",
      gridTemplateColumns: "repeat(3, auto)",
    },
  },

  "@media all and (max-width: 479px)": {
    ul: {
      gridGap: "10px 25px",
      gridTemplateColumns: "repeat(2, auto)",
    },
  },
}));

export const CopyButton = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 18px',
  cursor: 'pointer',
  userSelect: 'none',

  '&:active': {
    filter: 'grayscale(100%)',
  },

  "@media all and (max-width: 479px)": {
    padding: '12px 8px',
  },
}));

export const SocialMedia = styled.a({
  height: '44px',
  transition: '.2s all ease-out',
  margin: '2px 5px 0 0',
  cursor: 'pointer',
  '&:hover': {
    marginTop: '0',
  },
});

export const InviteURL = styled.input(() => ({
  textAlign: 'right',
  width: '100%',
  height: '50px',
  cursor: 'text',
  border: 'none',
  background: '#fff',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#10242C',
  paddingLeft: 10,
  paddingRight: 5,
}));

export const TermsLink = styled(Link)({
  color: '#10242C',
  textDecoration: 'underline',
});
