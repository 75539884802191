export default {
  hero: {
    title: 'Добре дошли в \nSportya',
    desc: 'Има много неща, които да научите за Sportya, затова ще започнем с основите. Разгледайте ръководството по-долу, за да разберете как работят мачовете, събитията и класиранията.',
  },
  firstPhase: {
    title: 'Какво е Sportya?',
    desc: `Sportya е конкурентна спортна екосистема, в която десетки хиляди аматьорски играчи участват в приятелски мачове, лиги и събития, за да се насладят на балансирана игра или да се състезават за най-добрите места в националните и международните класации. В момента Sportya е отворен за тенис и падел, а в бъдеще са планирани и други спортове с ракета и спортове отбори.`,
    articles: {
      1: {
        title: 'Открийте',
        desc: 'След като се присъедините към Sportya, ставате част от голяма и страстна общност от аматьорски играчи и получавате достъп до събития, организирани в стотици спортни клубове. Разширяваме се в нови региони и държави, предоставяйки ви по-голямо разнообразие от опции за всяка избрана от вас посока – дали ще играете локално в партньорски клубове или ще изследвате и търсите нови изживявания по целия свят.',
      },
      2: {
        title: 'Предизвикателство',
        desc: 'Започнете с няколко приятелски мача, присъединете се към местна лига, ако гъвкавостта на графика е важна за вас, или се потопете направо в конкурентни събития и се борете за върха в националните и международните класации за аматьорски играчи.',
      },
      3: {
        title: 'Свържете се',
        desc: 'Дръжте приятелите си близо, срещайте нови, споделяйте опитите си. Създайте и покажете своя профил на играч и се възползвайте максимално от любимите си конкурентни спортове.',
      },
      4: {
        title: 'Ниво на Играта',
        subtitle: 'Играйте Балансирани Мачове',
        desc: 'Всеки член на Sportya играе на специфично Ниво на Играта за всеки наличен спорт. Нивото определя събитията, на които можете да се присъедините, и улеснява правилното съвпадение както в конкурентни турнири, така и в приятелски мачове.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 Нива на Играта',
      desc: 'Посрещаме всички играчи, от начинаещи до опитни. Оценете Нивото на Играта си при създаване на акаунта, излезте и играйте и се насладете на балансирани мачове. Предлагаме 6 нива на игра, за всеки спорт, определени от комбинация от умения и опит. Можете да играете като начинаещ в тениса и като средно напреднал играч в падела, например.',
    },
    2: {
      title: 'Нива на Singles и Doubles',
      desc: 'В Sportya ги разглеждаме поотделно. Първоначално ще зададете ниво на игра, което показва потенциала ви за Singles. Това автоматично ще бъде вашето начално ниво на игра за Doubles мачовете. С напредването си, и двете нива автоматично ще се развиват заедно с вас.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Местна Лига',
      subtitle: 'Поддържайте Гъвкавост на Играта',
      desc: 'Местните Лиги ви позволяват да играете по един мач на седмица, следвайки гъвкав график, като същевременно запазвате духа на конкуренцията. Допълнителни подробности ще бъдат предоставени, когато се доближим до официалната дата на стартиране.',
    },
    2: {
      title: 'Приятелски Мачове',
      subtitle: 'Намирайте Нови Партньори за Игра',
      desc: 'Приятелските мачове са най-гъвкавият начин да изпитате балансирани мачове в Sportya. Приятелски мачове могат да се играят по всяко време, навсякъде, с други членове на Sportya. Нашата система за съвпадение ще ви помогне да намерите подходящ партньор за игра, да намерите подходящо място и да докладвате резултата онлайн, което ще допринесе за вашето класиране и профил на играч. Някои функции са в разработка в момента, но си заслужава да се изчака.',
    },
    comingSoon: 'Очаква се в 2023',
    comingSoonFM: 'Очаква се в Q1 2023',
    inDevelopment: 'В развитие',
  },
  fifthPhase: {
    title: 'Класирани Събития',
    subtitle: 'Вашият Най-Добър Мач Ви Очаква',
    desc: 'Основният елемент на Sportya, Класираните Събития се организират в последователен годишен календар, за всеки спорт, позволявайки ви да се състезавате в местни и глобални аматьорски класации и да се стремите към върха. Sportya е единственото място, където аматьорските спортисти могат да изпитат тръпката от това да бъдат професионални играчи.',
    articles: {
      1: {
        title: 'Категории на Класираните Събития',
        desc: '1st Serve, Silver, Gold, Platinum, Special и Tournament of Champions. Всяка категория има специфична роля в календара и изискванията за участие се различават от категория на категория. За всяко събитие и спорт организираме едно или повече състезания на базата на Нива на Играта, за Singles или Doubles.',
      },
      2: {
        title: 'Формати на Събитията',
        desc: 'Насладете се на множество формати, от класически, елиминационни събития, организирани през уикенда, до събития с групов формат, вечерни събития и други.',
      },
      3: {
        title: 'Формати на Мачовете',
        desc: 'Повечето мачове в Класираните Събития по Тенис в Sportya се играят на 2 Tie Break Сета с тай брек (10 точки) при 1 равен сет. Падел следва подобна схема, докато всички други формати на спортове ще бъдат обявени веднага щом отворим нови спортове.',
      },
      4: {
        title: 'Пакет за Участие',
        desc: 'Освен достъпа до събитието и всички съответни последици от провеждането на събитието в Sportya (координация на събитието, резултати, класирания, актуализации на активността, обслужване на клиенти и т.н.), таксите за участие в Sportya обикновено включват 2 нови премиум топки на мач (тенис, падел) и бутилка вода на мач.',
      },
    },
  },
  sixthPhase: {
    title: 'Започнете да Играете',
    desc: 'В края на деня всичко се свежда до това – насърчаваме забавната и вълнуваща конкуренция, основата на спорта, която в крайна сметка води до напредък в играта ви и в ежедневието.',
  },
  playVideo: 'Видео',
};
