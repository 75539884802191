import styled from '@emotion/styled';
import { P } from '../../../components/Collection';
import LoadImage from '../../../components/common/LoadImage';

export const Container = styled.div({
  paddingBottom: '107px',
  margin: '10px auto 0',
  maxWidth: '1234px',
  width: '100%',
  '@media (max-width: 1280px)': {
    padding: '20px 10px',
  },
});

export const Banner = styled.div({
  position: 'relative',
  div: {
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
});

export const BlackSerama = styled.div({
  margin: '30px 0 0 0',
  width: '100%',
  height: '370px',
  background: `url(${LoadImage('campaign/blackserama/d-bg.jpg')}) no-repeat center center`,
  backgroundSize: 'cover',
  '@media (max-width: 992px)': {
    background: `url(${LoadImage('campaign/blackserama/m-bg.jpg')}) no-repeat center center`,
    backgroundSize: 'contain',
    height: '525px',
  },
});

export const Bold = styled.span({
  fontWeight: 700,
});

export const BannerImg = styled.img({
  width: '100%',
  height: '500px',
  borderRadius: '10px',
  objectPosition: 'center center',
  objectFit: 'cover',
  '@media (max-width: 1120px)': {
    height: '300px',
  },
});

export const Club = styled.div({
  display: 'flex',
});

export const ClubAvatar = styled.img({
  height: '60px',
  width: '60px',
  objectFit: 'cover',
  borderRadius: '50%',
  objectPosition: 'center',
});

export const ClubDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
});

export const ClubName = styled(P)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  img: {
    margin: '0 0 0 10px',
  },
});

export const EventDetails = styled.div({
  display: 'flex',
  maxWidth: '1050px',
  width: '100%',
  '@media (max-width: 1120px)': {
    flexDirection: 'column',
  },
});

export const Info = styled.div({
});

export const Circle = styled.span(({ black, restrain }) => ({
  display: 'block',
  textAlign: 'center',
  lineHeight: '15px',
  marginRight: '10px',
  border: `2px solid ${black ? 'rgb(16, 36, 44)' : '#fff'}`,
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  ...restrain && {
    minWidth: '20px',
  },
}));

export const Text = styled.span(({ smallFont }) => ({
  maxWidth: '100px',
  ...smallFont && {
    fontSize: '13px',
  },
}));

export const List = styled.ul({
  margin: '20px 0 0 0',
  '&:first-of-type': {
    margin: '20px 190px 0 0',
    '@media (max-width: 1120px)': {
      margin: '40px 0 0 0',
    },
  },
  '@media (max-width: 1120px)': {
    '.event-period': {
      flexDirection: 'column',
    },
    '&:last-of-type': {
      margin: '0 0 40px 0',
    },
  },
});

export const Item = styled.li({
  display: 'flex',
  'p:first-of-type': {
    margin: '5px 5px 5px 0',
  },
});

export const ClubAndEvent = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Details = styled.div({
  display: 'flex',
  '@media (max-width: 1120px)': {
    flexDirection: 'column',
  },
});

export const Follow = styled.div({
  width: '180px',
  margin: '0 0 0 auto',
  '@media (max-width: 1120px)': {
    margin: '0 auto 40px',
  },
});

export const EventLogo = styled.img({
  width: '180px',
  height: '180px',
  margin: '0 0 10px 0',
  objectFit: 'cover',
  objectPosition: 'center',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
});

export const MapContainer = styled.div({
  margin: '0 0 60px 0',
  '#map': {
    height: '500px !important',
    width: '100%',
    border: 0,
    borderRadius: '10px',
  },
});

export const ContentContainer = styled.div({
  display: 'flex',
  '@media (max-width: 1250px)': {
    flexDirection: 'column',
  },
});

export const Content = styled.div({
  maxWidth: '900px',
  width: '100%',
  '@media (max-width: 1250px)': {
    maxWidth: 'unset',
  },
});

export const AsideContent = styled.aside({
  width: '286px',
  margin: '0 0 0 auto',
  padding: '40px 0 0 0',
  '@media (max-width: 1250px)': {
    padding: 'unset',
    margin: '60px auto 0 auto',
  },
});
