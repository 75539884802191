import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../../..';
import { P } from '../../../../../../../components/Collection';
import getAgeFromDate from '../../../../../../../components/common/getAgeFromDate';
import { InfoContainer } from '../styles';

const MetaInfo = () => {
  const { t: commonT } = useTranslation('common');
  const { user, isMyProfile } = useContext(UserContext);
  const { location, birthDate, dominantHand } = user || {};

  return (
    <InfoContainer column separator>
      {location ? (
        <P>
          <strong>
            <Trans ns="accounts" i18nKey="completeProfile.location">
              Location
            </Trans>
            :&nbsp;
          </strong>
          {location?.name}
        </P>
      ) : isMyProfile ? (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.locationNotSet">
            <strong>Location:</strong>
            {' '}
            Set your location
          </Trans>
        </P>
      ) : (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.userLocationNotSet">
            <strong>Location:</strong>
            {' '}
            Not set yet
          </Trans>
        </P>
      )}

      {birthDate ? (
        <P>
          <strong>
            <Trans ns="accounts" i18nKey="completeProfile.age">
              Age
            </Trans>
            :&nbsp;
          </strong>
          {getAgeFromDate(birthDate)}
        </P>
      ) : isMyProfile ? (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.ageNotSet">
            <strong>Age:</strong>
            {' '}
            Set your age
          </Trans>
        </P>
      ) : (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.userAgeNotSet">
            <strong>Age:</strong>
            {' '}
            Not set yet
          </Trans>
        </P>
      )}

      {dominantHand ? (
        <P>
          <strong>
            <Trans ns="player" i18nKey="playerHand">
              Playing Hand
            </Trans>
            :&nbsp;
          </strong>
          {commonT(dominantHand)}
        </P>
      ) : isMyProfile ? (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.handNotSet">
            <strong>Playing Hand:</strong>
            {' '}
            Set your playing hand
          </Trans>
        </P>
      ) : (
        <P>
          <Trans ns="accounts" i18nKey="completeProfile.userHandNotSet">
            <strong>Playing Hand:</strong>
            {' '}
            Not set yet
          </Trans>
        </P>
      )}
    </InfoContainer>
  );
};

export default MetaInfo;
