const parseScore = (scoreForm) => {
  const newForm = JSON.parse(JSON.stringify(scoreForm));
  const playerScore = newForm.player.score;
  const opponentScore = newForm.opponent.score;

  for (let i = 0; i < playerScore.length; i++) {
    const playerScoreItem = playerScore[i];
    const opponentHasSet = opponentScore.find(item => item.set === playerScoreItem.set);

    if (!opponentHasSet) {
      const newOpponentScoreItem = { set: i + 1, points: 0, tie: null };
      opponentScore.push(newOpponentScoreItem);
    }
  }

  return newForm;
};

export default parseScore;
