/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme, css } from '@emotion/react';

import crownDark from '../assets/images/icons/crown-dark.svg';
import crownLight from '../assets/images/icons/crown-light.svg';
import LevelBadge from './LevelBadge';
import P from './Paragraph';
import H4 from './H4';

const ContentWrapper = styled.section`
  border: 2px solid ${({ theme }) => theme.colors?.turquoise};
  border-radius: 10px;
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 30px;

  .crown {
    padding: 12px;
    display: block;
    position: absolute;
    top: 0;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;

    &__img {
      width: 18px;

      &--light {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    &.light {
      border: 2px solid ${({ theme }) => theme.colors?.turquoise};
      border-radius: 50%;
    }
  }

  .winner {
    &__title {
      font-size: 12px;
      margin-top: 15px;
      position: relative;
      font-weight: 700;

      &--light {
        padding-top: 15px;
        margin-top: 0;
        background: #fff;
        border-radius: 10px;
        z-index: 1;
      }
    }

    &__avatar {
      margin: 0 auto 10px;
      border-radius: 50%;
      width: 80px;
      height: 80px;

      &--img {
        display: block;
        width: 100%;
      }

      &--default {
        border: 1px solid ${({ theme }) => theme.colors?.lightBlue2};
        background-color: ${({ theme }) => theme.colors?.white};
        color: ${({ theme }) => theme.colors?.turquoise};
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        span {
          width: 100%;
        }
      }
    }
  }

  .player__wrapp {
    padding: 0 10px 25px;

    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

const WinnersElement = ({ content, isWinner, ...props }) => {
  const { t } = useTranslation('events');
  const theme = useTheme();
  const team = content.length > 0 ? content : [content];

  return (
    <ContentWrapper
      {...props}
      css={css`
        background: ${isWinner
        ? theme.colors?.turquoise
        : theme.colors?.white};
      `}
    >
      {team.map((player, key) => (
        <div
          key={key}
          className="player__wrapp"
          css={css`
            ${props.double
            && `border-bottom: 2px solid ${theme.colors?.turquoise};`}
            ${props.double
            && isWinner
            && `border-bottom: 1px solid ${theme.colors?.white};`}
          `}
        >
          <span
            className={isWinner ? 'crown dark' : 'crown light'}
            css={css`
              background: ${isWinner
              ? theme.colors?.turquoise
              : theme.colors?.white};
            `}
          >
            {isWinner && <img src={crownLight} className="crown__img" alt="Winner" />}
          </span>
          {!isWinner && (
            <img src={crownDark} className="crown__img crown__img--light" alt="Winner" />
          )}
          <p
            className={
              isWinner
                ? 'winner__title text-center mb10'
                : 'winner__title winner__title--light text-center mb10'
            }
            css={css`
              color: ${isWinner
              ? theme.colors?.lightTurquoise
              : theme.colors?.turquoise};
            `}
          >
            {props.single && (isWinner ? t('champion') : t('finalist'))}
            {props.double && (isWinner ? t('champions') : t('finalists'))}
          </p>
          <div className="winner__avatar">
            {player?.profilePicture ? (
              <img className="winner__avatar--img" src={player?.profilePicture} alt="Winner" />
            ) : (
              <div className="winner__avatar--default">
                <H4>
                  {player?.firstName?.charAt(0)?.toUpperCase() + player?.lastName?.charAt(0)?.toUpperCase()}
                </H4>
              </div>
            )}
          </div>
          <P
            bold
            css={css`
              color: ${isWinner
              ? theme.colors?.white
              : theme.colors?.turquoise};
            `}
            className="mb10"
          >
            {player?.firstName}
            {' '}
            {player?.lastName}
          </P>
          <LevelBadge
            small
            border={isWinner ? theme.colors?.white : theme.colors?.turquoise}
            centered
          >
            {player?.level}
          </LevelBadge>
        </div>
      ))}
    </ContentWrapper>
  );
};

export default WinnersElement;
