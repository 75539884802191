import styled from '@emotion/styled';

export const BannerContainer = styled.div(({ noPadding }) => ({
  ...!noPadding && {
    padding: '0 40px',
    '@media (max-width: 767px)': {
      padding: '0 10px',
    },
  },
}));

export const PlayersList = styled.div({
  overflow: 'auto',
  maxHeight: '350px',
  '@media (max-width: 767px)': {
    maxHeight: '200px',
  },
});

export const Container = styled.div(({ withPadding }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#EAF6F6',
  borderRadius: '10px',
  border: '1px solid #EAF6F6',
  height: '80px',
  width: '100%',
  margin: withPadding ? '0 auto 20px auto' : '0 0 30px 0',
  '@media (max-width: 1310px)': {
    flexDirection: 'column',
    height: '120px',
    width: '100%',
  },
}));

export const FindOutMore = styled.a({
  textDecoration: 'underline !important',
});

export const ModalBackground = styled.div({
  display: 'flex',
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: '#0000004d',
  zIndex: 98,
  maxWidth: 'unset !important',
  useSelect: 'none',
  touchAction: 'none',
});

export const Modal = styled.div(({ center }) => ({
  ...center && {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  maxHeight: '100vh',
  borderRadius: '10px',
  background: '#fff',
  padding: '40px',
  zIndex: 99,
  '@media (max-width: 620px)': {
    padding: '30px 15px',
  },
}));

export const TennisRacket = styled.img({
  margin: '0 0 10px 0',
});

export const ButtonIcon = styled.img({
  margin: '0 10px 0 0',
});

export const CloseIcon = styled.img({
  position: 'absolute',
  cursor: 'pointer',
  right: '20px',
  top: '20px',
  '@media (max-width: 620px)': {
    top: '10px',
    right: '10px',
    maxWidth: '25px',
  },
});
