import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '../../common/http';

// Actions
export const fetchSettings = createAsyncThunk('settings/fetchSettings', async () => {
  const { data: { data } } = await get('/settings');

  return data;
});

// Slice
const slice = createSlice({
  name: 'settings',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
    displayNewsHeaderBanner: true,
  },
  reducers: {
    setDisplayNewsHeaderBanner: (state, action) => {
      state.displayNewsHeaderBanner = action.payload;
    },
  },
  extraReducers: {
    [fetchSettings.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  setDisplayNewsHeaderBanner,
} = slice.actions;

export default slice.reducer;
