import styled from '@emotion/styled';

export const MainDrawStyle = styled.div`

.groupWrapp{
    display:flex;
    justify-content:space-evenly;
    margin-left:-10px;
    margin-right:-14px;
    flex-wrap:wrap;
  }

.mainDrawButtonsWrapp{
  margin-top:20px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-wrap:wrap;
  padding: 0 40px;

@media screen and (max-width: 1024px){
  max-width: 400px;
  margin-top:10px;
  margin:0 auto;
  div{
    margin:10px  auto 0;
    height:60px;
      }
}
}

`;
