export default {
  minutesUntil: 'uzávěrka {{time}} min',
  hoursUntil: 'uzávěrka {{time}} h',
  daysUntil: 'uzávěrka {{time}} dny',
  monthsUntil: 'uzávěrka {{time}} měsíce',
  yearsUntil: 'uzávěrka {{time}} roky',
  minutesAgo: 'před {{time}} minutami',
  hoursAgo: 'před {{time}} hodinami',
  daysAgo: 'před {{time}} dny',
  monthsAgo: 'před {{time}} měsíci',
  yearsAgo: 'před {{time}} roky',
  justNow: 'právě teď',
};
