import styled from '@emotion/styled';

export const Avatar = styled.img({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '60px',
  width: '60px',
  objectFit: 'cover',
  objectPosition: 'top',
  borderRadius: '50%',

  cursor: 'pointer',
});
