import styled from '@emotion/styled';
import ComponentContainer from '../../components/Container';

export const FriendsStyle = styled.div`
  padding: 0 0 40px 0;

  .dropdown-text {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }

  h1{
    font-size: ${({ theme }) => theme.functions?.toEm(48)};
    line-height: 62px;
  }

  .friendsHeader{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:30px;
    .friendsHeaderInfo{
      display:flex;
      flex-direction:column;
      align-items:flex-end;
      justify-content:space-between;
      min-height:36px;
      p{margin:0}
    }
  }

  .selectWrapp{
    margin-top:30px;
  }

  .filtersWrapp{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;

    .filtersStart {
      justify-content: start;
    }

    .filtersEnd {
      justify-content: end;
      margin-right: 10px;
    }

    .filtersButtons{
      max-width:581px;
      width:100%;
      display:flex;

      button{
        height:50px;
        font-size: ${({ theme }) => theme.functions?.toEm(16)};
        font-weight:700;
        background-color: ${({ theme }) => theme.colors?.white};
        color: ${({ theme }) => theme.colors?.darkTurquoise};
        border:none;
        cursor:pointer;
        outline:none;
        border-radius:4px;
        margin: 0 10px;
      }

      .allFriends{
        max-width: 260px;
        white-space: nowrap;
        padding: 10px;
      }

      .friendRequests{
        max-width: 260px;
        white-space: nowrap;
        padding: 10px;
      }

      .sentRequests{
        max-width: 260px;
        white-space: nowrap;
        padding: 10px;
      }
      
      .selectedFilter{
        background-color:${({ theme }) => theme.colors?.lightBlue2};
        color: ${({ theme }) => theme.colors?.primary};
      }

    }
      .filterModalButton{
        .filters-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 2px;
          background: #000;
          border-radius: 50%;
          height: 21px;
          width: 21px;
        }
      }
  }

  .levelSelectorWrapp{
    min-height:50px;
    margin-bottom:21px;
    display:flex;
    justify-content:space-between;
  }

  .inviteFriends {
    font-size: 16px;
    font-weight: 700;

    .inviteFriendsIcon {
      margin-right: 6px;
      vertical-align: sub;
      width: 18px;
      height: 18px;
    }
  }


  ${props => props.theme.functions?.mediaQueryMax('768px', `
  .friendsHeader{
    flex-direction:column;
    align-items:flex-start;
    .friendsHeaderInfo{
      align-items:flex-start;
    }
  }

  .filtersWrapp{
    flex-direction:column;
    align-items:flex-start;

    .filtersButtons{
      flex-direction: column;
      align-items: center;
      max-width: 768px;
      width: 100%;
    }

    > div:last-of-type
    {
      margin-top: 20px;
    }

    .filterModalButton{
      margin-left:20px;
    }
  }

    .levelSelectorWrapp{
      width:100%;
      overflow-x:scroll;

      > div
      {
        p
        {
          width: max-content;
          margin: 0 10px;
        }
      }
    }
  `)}
`;

export const Container = styled(ComponentContainer)`
  padding: 0;
  min-height: 60vh;
  .hello {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

export const FriendsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1234px',
  width: '100%',
  margin: '0 auto',
  '@media (max-width: 1235px)': {
    h3: {
      textAlign: 'center',
    },
  },
});

export const FriendsList = styled.div({
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(auto-fill, minmax(286px, 286px))',
  width: '100%',
  padding: 'unset',
  margin: '0 auto',
  gap: '30px',
});
