/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

const Checkbox = (props) => {
  const theme = useTheme();
  const { functions } = theme;
  const { t } = useTranslation(props.transCategory);

  return (
    <div
      css={css`
        display: flex;
        margin-bottom: ${functions?.toEm(20)};
        ${props.isDisabled ? `
        cursor: no-drop;
        user-select: none;
        touch-action: none;` : ''}

        label {
          ${props.isDisabled ? `
          user-select: none;
          touch-action: none;` : ''}

          display: block;
          position: relative;
          padding-left: ${functions?.toEm(30)};
          margin-bottom: ${functions?.toEm(12)};
          cursor: ${props.isDisabled ? 'no-drop' : 'pointer'};
          line-height: ${functions?.toEm(24)};
          user-select: none;
          opacity: ${props.isDisabled ? '30%' : '1'};
          color: ${theme.colors?.black};
          top: 0;
          left: 0;
          pointer-events: all;
          ${props.maxlabel ? 'width: max-content;' : ''}

          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            & ~ .checkmark {
              height: 22px;
              width: 22px;
              background-color: ${theme.colors?.white};
            }

            &:checked ~ .checkmark {
              background-color: ${theme.colors?.black};

              &:after {
                display: block;
              }
            }
          }
        }

        .checkmark {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          height: ${functions?.toEm(25)};
          width: ${functions?.toEm(25)};
          background-color: ${theme.colors?.white};
          border: 2px solid #10242C;
          border-radius: 4px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }

        label .checkmark:after {
          width: ${functions?.toEm(3)};
          height: ${functions?.toEm(8)};
          top: 3px;
          left: 6px;
          border: solid ${theme.colors?.white};
          border-width: 0 ${functions?.toEm(3)} ${functions?.toEm(3)} 0;
          transform: rotate(45deg);
        }
      `}
    >
      <label>
        {props.translate
          ? props.trans.map(e => (`${t(e)} `))
          : props.label}

        <input
          css={css`
            font-size: ${functions?.toEm(14)};
          `}
          type="checkbox"
          name={props.name}
          {...(props.value && { value: props.value })}
          {...(props.handler && !props.isDisabled && { onClick: props.handler })}
          {...props}
        />
        <span className="checkmark" />
      </label>
    </div>
  );
};

export default Checkbox;
