const filtersList = (t) => [
  { value: 'all', label: t('all') },
  { value: 'openingSoon', label: t('openingSoon') },
  { value: 'preRegistrationsOpen', label: t('preRegistrationsOpen') },
  { value: 'sortPending', label: t('sortPending') },
  { value: 'registrationsOpen', label: t('registrationsOpen') },
  { value: 'drawPending', label: t('drawPending') },
  { value: 'readyToPlay', label: t('readyToPlay') },
  { value: 'playing', label: t('playing') },
  { value: 'resultsPending', label: t('resultsPending') },
  { value: 'ended', label: t('ended') },
];

export default filtersList;
