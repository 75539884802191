import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ClubPage from '../Clubs/Info';
import PlayerProfile from '../PlayerProfile';
import { clearAllTabs, fetchAccountDetails } from '../PlayerProfile/reducers';
import LoadingSpinner from '../../components/LoadingSpinner';

const Profile = ({ name }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { accountInfo, masterSport } = useSelector(state => state.session);
  const { data, sportType, status } = useSelector(state => state.accounts.info.general);
  const isMyProfile = (name === 'my-account') || (accountInfo?.id === id);
  const isClub = [isMyProfile ? accountInfo : data]?.role === 'club';

  if (isClub) {
    return (<ClubPage />);
  }

  useEffect(() => {
    if (!isMyProfile
      && (status === 'idle' || data?.id !== id || (sportType && sportType !== masterSport && status === 'succeeded'))
    ) {
      if (data?.id !== id) {
        dispatch(clearAllTabs({ profile: 'visitedProfile' }));
      }

      dispatch(fetchAccountDetails(id));
    }
  }, [id, masterSport, sportType]);

  return ((isMyProfile || (!isMyProfile && !isEmpty(data) && status === "succeeded")))
    ? <PlayerProfile isMyProfile={isMyProfile} profileInfo={isMyProfile ? accountInfo : data} />
    : <LoadingSpinner />;
};

export default Profile;
