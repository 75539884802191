import styled from '@emotion/styled';

export const CalendarWrapper = styled.div(() => ({
  marginBottom: 60,

  ".mobile-only": {
    display: "none",
  },

  ".select-wrapper": {
    ".form-group": {
      marginBottom: 0,
    },
  },

  '@media (max-width: 991px)': {
    marginBottom: 30,
  },

  '@media (max-width: 767px)': {
    ".mobile-only": {
      display: "block",
    },
    ".desktop-only": {
      display: "none",
    },

    ".heading": {
      flexDirection: "column",
      gap: 5,
      marginBottom: 10,

      ".gap-10": {
        flexDirection: "column",
        width: "100%",

        span: {
          whiteSpace: "nowrap",
        },
      },
    },
  },
}));

export const Container = styled.div(({ theme }) => ({
  overflowX: "auto",
  boxShadow: "0px 2px 4px 0px rgba(16, 36, 44, 0.10)",
  borderRadius: 10,
  border: `1px solid ${theme.colors.lightBlue}`,

  ".table-responsive": {
    width: "100%",
    borderSpacing: 0,
    border: 0,
    borderCollapse: "collapse",
    tableLayout: "fixed",

    th: {
      width: 42,
      height: 42,
      borderCollapse: "collapse",
      borderBottom: `1px solid ${theme.colors.lightBlue}`,
      borderLeft: `1px solid ${theme.colors.lightBlue}`,
      fontWeight: "normal",
      color: theme.colors.turquoise,
      opacity: 0.5,

      "&:first-of-type": {
        width: 150,
        opacity: 1,
        cursor: "pointer",
      },
    },

    tr: {
      borderCollapse: "collapse",

      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.colors.lightBlue}`,
      },

      td: {
        borderCollapse: "collapse",
        height: 42,
        // zIndex: 1,
        position: "relative",

        "&:first-of-type": {
          background: "white",
          padding: "5px 20px",
          borderRight: `1px solid ${theme.colors.lightBlue}`,
        },
      },
    },

    '@media (max-width: 767px)': {
      width: 900,

      "th:first-of-type, tr td:first-of-type": {
        width: 115,
        padding: 5,
        position: "sticky",
        left: 0,
        zIndex: 2,
        background: "white",

        p: {
          fontSize: 14,
        },
      },
    },
  },

}));

export const Td = styled.td(({
  theme,
  isUnavailable,
  myBooking,
  isBooked,
  hasRightBorder,
}) => ({
  background: "white",
  padding: 5,
  textAlign: "center",
  cursor: "pointer",

  ...hasRightBorder && {
    borderRight: `1px solid ${theme.colors.lightBlue}`,
  },

  img: {
    zIndex: 1,
    position: "relative",
  },

  ...(isUnavailable && !isBooked && !myBooking) && {
    borderLeft: `1px solid ${theme.colors.lightBlue}`,
    borderRight: `1px solid ${theme.colors.lightBlue}`,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    overflow: "hidden",
    pointerEvents: "none",

    "&:before": {
      content: '""',
      position: "absolute",
      right: "-1px",
      top: 0,
      height: "100%",
      width: 2,
      background: theme.colors.turquoise,
      borderRight: `1px solid ${theme.colors.lightBlue} !important`,
      zIndex: 1,
      display: "none",
    },
  },

  ...isBooked && !myBooking && {
    pointerEvents: "none",
  },

  "&:after": {
    content: '""',
    position: "absolute",
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
    top: 2,
    left: 2,
    borderRadius: 4,

    ...(isUnavailable && !isBooked && !myBooking) && {
      // background: "repeating-linear-gradient(to right, #d9d9d933, #d9d9d933 15px, #fff 5px, #fff 20px)",
      // transform: "skew(25deg)",
      background: "#D2D2D2",
      borderRadius: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },

    ...isBooked && {
      background: "#D2D2D2",
    },

    ...myBooking && {
      background: theme.colors.lime2,
      cursor: "pointer",
    },
  },

}));

export const CalendarInfo = styled.div(({ theme, mobile }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  ...mobile && {
    flexDirection: "column",
    justifyContent: "end",
  },
  marginTop: 5,
  width: "100%",

  "> div": {
    display: "flex",
    gap: 10,
  },

  p: {
    display: "flex",
    alignItems: "center",
    gap: 5,

    span: {
      width: 12,
      height: 12,
      borderRadius: 2,

      "&.available": {
        border: `1px solid ${theme.colors.lightTurquoise}`,
      },

      "&.not-available": {
        background: "#D2D2D2",
      },

      "&.your-booking": {
        background: theme.colors.lime2,
      },
    },

  },

  ".form-group": {
    marginBottom: 10,
  },

  ".toggle": {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    marginBottom: 10,
  },

  ".hours-wrapper": {
    display: "grid",
    gap: 10,
    gridTemplateColumns: "repeat(auto-fit, calc(20% - 8px))",
    marginBottom: 10,

    ".hour-box": {
      cursor: "pointer",
      border: `1px solid ${theme.colors.lightTurquoise}`,
      textAlign: "center",
      borderRadius: 4,
      padding: "10px 5px",

      "&__unavailable": {
        cursor: "default",
        pointerEvents: "none",
        backgroundColor: "#D2D2D2",
        color: "#999",
        borderColor: "#D2D2D2",
      },

      "&__selected": {
        background: theme.colors.turquoise,
        color: theme.colors.white,
      },

      "&__my-booking": {
        cursor: "default",
        pointerEvents: "none",
        background: theme.colors.lime2,
        color: theme.colors.white,
      },
    },
  },

  ".list-courts": {
    gap: 0,
    marginTop: 10,

    "&__prices": {
      marginTop: 15,
      marginBottom: 30,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 10,
    },

    "&__price-info": {
      cursor: "pointer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: theme.colors.turquoise,
      borderRadius: 4,
      padding: "7px 4px",

      "&--unavailable": {
        cursor: "default",
        pointerEvents: "none",
        opacity: 0.3,
      },

      p: {
        color: theme.colors.white,
        margin: 2,
      },
    },
  },
}));

export const DatePickerContainer = styled.div(() => ({
  position: "absolute",
  zIndex: 9,
  background: "#FFFFFF",
  border: "1px solid #C1E6E5",
  boxSizing: "border-box",
  boxShadow: "0px 8px 34px rgba(0, 0, 0, 0.15)",
  borderRadius: 10,
  marginTop: 40,
}));

export const PopUpContainer = styled.div(({ inline, myBooking }) => ({
  display: inline ? 'inline-flex' : 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  gap: 5,

  ...myBooking && {
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 1,
  },

  '&:hover > span': {
    display: 'block',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

export const PopUp = styled.span(({ small, fromModal, myBooking }) => ({
  position: 'absolute',
  display: 'none',
  padding: '5px 10px',
  borderRadius: '4px',
  bottom: 30,
  left: 0,
  background: 'rgb(15, 36, 44)',
  zIndex: 9,
  color: '#ffffff',
  whiteSpace: 'nowrap',

  ...myBooking && {
    bottom: 36,
    left: '50%',
    transform: 'translateX(-50%)',
  },

  '@media (max-width: 767px)': {
    whiteSpace: small ? 'nowrap' : 'initial',
    minWidth: small ? "auto" : "50vw",
  },

  '@media (max-width: 479px)': {
    minWidth: small ? "auto" : "50vw",
    ...fromModal && {
      position: "fixed",
      left: 0,
      top: 0,
      bottom: "auto",
      transform: "none",
    },
  },
}));
