import React from 'react';
import styled from '@emotion/styled';

const ComponentsContainer = styled.div(({ theme }, ...props) => ({
  position: 'relative',
  width: '100%',
  maxWidth: props.fullwidth ? '100%' : theme.container?.maxWidth,
  margin: props.mt ? `${props.mt} auto` : '0 auto',
  padding: '0 15px',

  '.club-info': {
    '&:hover': {
      textDecoration: 'none',
    },
  },

  ...props.noPadding && {
    padding: 'unset',
    '@media (max-width: 1260px)': {
      padding: '0 15px',
    },
  },
}));

const Container = ({ children, ...props }) => (
  <ComponentsContainer {...props}>
    {children}
  </ComponentsContainer>
);

export default Container;
