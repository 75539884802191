import styled from '@emotion/styled';

export const Container = styled.div(({ bg }) => ({
  minHeight: '350px',
  height: '100%',
  padding: '60px 0 0 0',
  ...bg && {
    background: bg,
  },

  '@media (max-width: 800px)': {
    padding: '60px 10px 0 10px',
  },
}));

export const UserID = styled.span({
  color: '#78ADAB',
  fontWeight: 'initial',
});

export const MobileGameLevelContainer = styled.div({
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'flex',
    justifyContent: 'center',
    '> div': {
      width: 'fit-content',
    },
  },
});

export const PlayerRating = styled.div({
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  borderRadius: '80px',
  background: '#fff',
  padding: '0 12px',
  margin: '0 10px 0 0',
});

export const StarIcon = styled.img({
  margin: '0 5px 0 0',
});

export const BlockedPlayer = styled.div({

});

export const BlockedIcon = styled.img({
  margin: '0 10px 0 0',
});
