import styled from '@emotion/styled';
import LoadImage from '../../../../../components/common/LoadImage';

export const ContentWrapper = styled.section`
  .react-tabs {
    &__tab {
      padding: ${({ theme }) => theme.functions?.toEm(10)};

      h3 {
        color: ${({ theme }) => theme.colors?.mediumTurquoise};
      }

      ${({ theme }) => theme.functions?.minTablet(`
          padding: ${() => theme.functions?.toEm(20)}
            ${() => theme.functions?.toEm(40)}
            ${() => theme.functions?.toEm(20)} 0;
      `)}

      &:focus {
        box-shadow: none;
      }

      &-list {
        border-bottom: 1px solid transparent;
      }

      &--selected {
        border-color: transparent;

        h3 {
          color: ${({ theme }) => theme.colors?.turquoise};
        }
      }
    }
  }

  .secondary_tabs {
    border: 1px solid ${({ theme }) => theme.colors?.lightBlue2};
    padding: 0 ${({ theme }) => theme.functions?.toEm(10)};
    border-radius: 10px;

    ${({ theme }) => theme.functions?.minTablet(`
        padding: 0 ${() => theme.functions?.toEm(30)};
      `)}

    &--panel {
      width: 100%;
      display: flex;

      ${({ theme }) => theme.functions?.minTablet(`
        width: 60%;
        margin: 0 auto;
      `)}
    }

    .react-tabs {
      &__tab {
        padding: ${({ theme }) => theme.functions?.toEm(10)}
          ${({ theme }) => theme.functions?.toEm(10)};
        border-bottom: 1px solid ${({ theme }) => theme.colors?.mediumTurquoise};
        flex-grow: 1;

        ${({ theme }) => theme.functions?.minTablet(`
            padding: ${() => theme.functions?.toEm(20)}
              ${() => theme.functions?.toEm(60)};
        `)}

        &--selected {
          border-bottom: 1px solid ${({ theme }) => theme.colors?.turquoise};
        }
      }
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  .winners {
    padding: ${({ theme }) => theme.functions?.toEm(30)};
    max-width: ${({ theme }) => theme.functions?.toEm(250)};
    margin: 0 auto;
  }

  .slick-next,
  .slick-prev {
    &:before {
      color: ${({ theme }) => theme.colors?.black};
      background-color: #fff;
      background-image: url('${LoadImage('black-right-arrow-small.svg')}');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px auto;
      content: '';
      width: 45px;
      height: 45px;
      border-radius: 50%;
      position: absolute;
      opacity: 1;
      left: 0;
    }

    top: calc(50% - 15px);
    bottom: auto;
    z-index: 9;
    width: 45px;
    height: 45px;
    position: absolute;
  }

  .slick-prev {
    &:before {
      transform: rotate(-180deg);
    }
  }

  .slick-disabled {
    &:before {
      opacity: 0.2;
    }
  }

  .slick-slide div {
    text-align: center;
  }

  .slick-dots {
    bottom: 10px;
  }

  .separator {
    margin-bottom: 30px;
  }
`;
