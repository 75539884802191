import React from 'react';
import { Trans } from 'react-i18next';

import { P } from '../../../../components/Collection';
import {
  ColumnsContainer,
  FriendlyMatchesColumn,
  ParentContainer,
  PlayerColumn,
  PointsColumn,
  RankingColumn,
  RegistrationColumn,
} from './styles';
import { PlayerStatsColumn } from '../TablePlayer/styles';

const TableCard = ({ children }) => (
  <ParentContainer>
    <ColumnsContainer>
      <PlayerColumn addLeftPadding>
        <P xSmall>
          <Trans ns="player" i18nKey="player">Player</Trans>
        </P>
      </PlayerColumn>

      <PlayerStatsColumn customMinWidthOnDesktop="320px">
        <RegistrationColumn>
          <P xSmall>
            <Trans ns="player" i18nKey="registrationDate">Registration Date</Trans>
          </P>
        </RegistrationColumn>

        <FriendlyMatchesColumn>
          <P xSmall>
            <Trans ns="player" i18nKey="friendlyMatches">Ranked Friendly Matches</Trans>
          </P>
        </FriendlyMatchesColumn>
      </PlayerStatsColumn>

      <PlayerStatsColumn customMinWidthOnDesktop="220px">
        <RankingColumn>
          <P xSmall>
            <Trans ns="player" i18nKey="leaderboard">Leaderboard</Trans>
          </P>
        </RankingColumn>

        <PointsColumn>
          <P xSmall>
            <Trans ns="player" i18nKey="points">Points</Trans>
          </P>
        </PointsColumn>
      </PlayerStatsColumn>
    </ColumnsContainer>
    {children}
  </ParentContainer>
);

export default TableCard;
