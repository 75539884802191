import React from 'react';

const getTie = (type, game, score, keyScore) => {
  const player = {
    player: game?.[keyScore]?.tie,
    opponent: score?.tie,
  };

  return player?.[type]
    && keyScore < 2 && (<sup>{player?.[type]}</sup>);
};

const compareScores = (game, score, keyScore, partners) => {
  const player = partners
    ? game?.playerPartner?.score?.slice().sort((a, b) => (a.set > b.set ? 1 : -1))
    : game?.player?.score?.slice().sort((a, b) => (a.set > b.set ? 1 : -1));

  return player[keyScore]?.points || score.points
    ? player[keyScore]?.points === score.points ? (
      <span
        key={keyScore}
        className={
          keyScore !== game.opponent?.score.length - 1
            ? 'mr10'
            : null
        }
      >
        {player[keyScore]?.points}
        {getTie('player', player, score, keyScore)}
        -
        {score.points}
        {getTie('opponent', game, score, keyScore)}
      </span>
    ) : player[keyScore]?.points > score.points ? (
      <span
        key={keyScore}
        className={
          keyScore !== game.opponent?.score.length - 1
            ? 'mr10'
            : null
        }
      >
        <strong>{player[keyScore]?.points}</strong>
        {getTie('player', player, score, keyScore)}
        -
        {score.points}
        {getTie('opponent', game, score, keyScore)}
      </span>
    ) : (player[keyScore]?.points < score.points) ? (
      <span
        key={keyScore}
        className={
          keyScore !== game.opponent?.score.length - 1
            ? 'mr10'
            : null
        }
      >
        {player[keyScore]?.points}
        {getTie('player', player, score, keyScore)}
        -
        <strong>{score.points}</strong>
        {getTie('opponent', game, score, keyScore)}
      </span>
    ) : '' : '';
};

export default compareScores;
