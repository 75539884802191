import React from 'react';
import LoadImage from '../../../../../../components/common/LoadImage';

const mapLocations = (array, commonT) => {
  const newArray = [...new Set(array)];
  newArray.unshift({ value: 'currentLocation', name: 'currentLocation' });
  return newArray?.map(value => ({
    value: value.city || value.country ? `${value.city}, ${value.country}` : value.value,
    label: (
      value.city
        ? `${commonT(value.city)}, ${commonT(value.country)} ${value.total ? `(${value.total})` : ''} `
        : value.name === 'currentLocation'
          ? (
            <>
              <img src={LoadImage('location-icon.svg')} className="mr5 my-location-img" alt="" />
              <span className="my-location">{commonT(value.name)}</span>
            </>
          )
          : commonT(value.name)
    ),
    coordinates: value.coordinates,
  }));
};

export default mapLocations;
