import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import P from '../../../../components/Paragraph';
import Checkbox from '../../../../components/Form/VanillaCheckbox';
import CheckboxHandler from '../../../../components/CheckboxHandler';
import Button from '../../../../components/Form/Button';

const AdvancedFilters = ({
  handleModalFilters, toggle, resetAdvancedFilters, filters,
}) => {
  const [form, setForm] = useState({});

  // [This can be used in future for an All version for filters]
  // const genderList = ['male', 'female'];
  // const countriesList = ['romania', 'moldova', 'bulgaria']
  // const allGenders = genderList.every(e => form['gender']?.split(',').includes(e))
  // || genderList.every(e => filters['gender']?.split(',').includes(e));
  // const allCountries = countriesList.every(e => form['country']?.split(',').includes(e))
  // || countriesList.every(e => filters['country']?.split(',').includes(e));

  const { t } = useTranslation('events');
  const { t: commonT } = useTranslation('common');

  const genders = [
    { name: commonT('men'), key: 'male' },
    { name: commonT('women'), key: 'female' },
  ];

  const countries = [
    { name: commonT('countriesByKey.ro'), key: 'Romania' },
    { name: commonT('countriesByKey.bg'), key: 'Bulgaria' },
    { name: commonT('countriesByKey.md'), key: 'Moldova' },
    { name: commonT('countriesByKey.bh'), key: 'Bahrain' },
    { name: commonT('countriesByKey.cz'), key: 'Czechia' },
    { name: commonT('countriesByKey.hu'), key: 'Hungary' },
  ];

  return (
    <form className="centered-form">
      <div className="form-content-small">
        <div className="form-group bd-bottom">
          <P large bold className="mb20">
            {t('gender')}
          </P>
          <div className="row">
            {genders.map((selector, key) => (
              <div key={key} className="col-sm-4">
                <Checkbox
                  name="gender"
                  handler={() => setForm(CheckboxHandler('gender', selector.key, form, filters, true))}
                  checked={(form.gender?.split(',').includes(selector.key))}
                  {...(filters?.gender?.split(',').includes(selector.key)) && { defaultChecked: true }}
                  label={selector.name}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <P large bold className="mb20">
            {commonT('country')}
          </P>
          <div className="row">
            {countries.map((selector, key) => (
              <div key={key} className="col-sm-4">
                <Checkbox
                  name="country"
                  handler={() => setForm(CheckboxHandler('country', selector.key, form, filters, true))}
                  label={selector.name}
                  {...(filters?.country?.split(',').includes(selector.key)) && { defaultChecked: true }}
                  checked={(form.country?.split(',').includes(selector.key))}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        <span
          className="reset-to-default"
          onClick={() => resetAdvancedFilters()}
        >
          {t('resetToDefault')}
        </span>
        <Button
          onClick={() => {
            handleModalFilters(form);
            toggle();
          }}
          maxwidth
          black
          className="mb20"
        >
          {t('apply')}
        </Button>
      </div>
    </form>
  );
};

export default AdvancedFilters;
