import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import mixpanel from '../../../../mixpanel';
import LoadImage from '../../../../components/common/LoadImage';
import RaiseHand from '../../../../components/Layout/components/Header/components/RaiseHand';
import { getAge } from '../../helpers';

import {
  ButtonTopPicksIcon,
  InviteOrRematchButton,
  InviteOrRematchButtonContainer,
} from '../styles';
import {
  PlayerAvatarContainer,
  PlayerContainer,
  PlayerMetaData,
  PlayerAvatar,
  Initials,
  FriendIcon,
  PlayerID,
  Username,
  Location,
  Distance,
  FirstPart,
  SecondPart,
  FullName,
} from './WantToPlay/styles';
import { Badge } from '../../../../components/LevelsBadges/styles';
import { P } from '../../../../components/Collection';
import LinkWrapper from '../../../../components/Link';
import { Paragraph } from '../../../../components';
import PlayerRating from '../../../../components/PlayerRating';
import { RadiusIcon } from '../../../../components/Event/styles';

const nameInitials = ({ firstName, lastName }) => {
  const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
  const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
  return `${first} ${last}`;
};

const Player = ({ data, topPicks }) => {
  const history = useHistory();
  const {
    userInfo,
    distance,
    isFriend,
    timeframe,
    opponentLevel,
    matchType,
    playedBefore,
    ratingInfo,
  } = data || {};

  const raisedHandData = {
    timeframe,
    opponentLevel,
    matchType,
  };

  const name = `${userInfo?.firstName} ${userInfo?.lastName}`;
  const kmDistance = (distance / 1000).toFixed(2);

  const handleInvite = (type) => {
    mixpanel.track(
      'Initiate CREATE MATCH',
      { sp_action_source: type === 'rematch' ? 'Other Top Picks - Rematch' : 'Other Top Picks - Invite to Match' },
    );
    history.push(`/friendly-matches/${type === 'rematch' ? 'rematch' : 'create/invite'}`, {
      match: {
        gameType: 'singles',
        opponent: userInfo,
      },
    });
  };

  return (
    <PlayerContainer>
      <FirstPart>
        <PlayerAvatarContainer>
          <LinkWrapper
            to={`/accounts/${userInfo?.id}`}
            className="text-decoration-none"
            onClick={() => mixpanel.track(
              topPicks ? 'View Player from Other Top Picks' : 'View Player from Want to Play',
            )}
          >
            <PlayerAvatar>
              {userInfo?.profilePicture
                ? <img src={LoadImage(`accounts/${userInfo?.userId}/${userInfo?.profilePicture}`, true)} alt={name} />
                : <Initials><Paragraph bold>{nameInitials(userInfo)}</Paragraph></Initials>}
            </PlayerAvatar>
          </LinkWrapper>
          <div className="game-level">
            <Badge small><P xSmall bold>{userInfo?.gameLevel?.singles ?? '-'}</P></Badge>
            <Badge small double><P xSmall bold>{userInfo?.gameLevel?.doubles ?? '-'}</P></Badge>
          </div>
        </PlayerAvatarContainer>
        <PlayerMetaData>
          <LinkWrapper
            to={`/accounts/${userInfo?.id}`}
            className="text-decoration-none"
            onClick={() => mixpanel.track(
              topPicks ? 'View Player from Other Top Picks' : 'View Player from Want to Play',
            )}
          >
            <Username>
              <PlayerRating
                ratingInfo={ratingInfo}
              />
              {isFriend && (
                <FriendIcon
                  src={LoadImage('friendly-matches/start-match/friends.svg')}
                  alt="One of your friends"
                />
              )}
              <FullName>{name}</FullName>
              <PlayerID>#{userInfo?.userId}</PlayerID>
            </Username>
          </LinkWrapper>
          <Location small>
            {getAge(userInfo?.birthDate)}
            {' '}
            -
            {' '}
            {userInfo?.location?.name}
          </Location>
          {!topPicks && (
            <Distance xSmall>
              {kmDistance < 1
                ? (
                  <>
                    <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
                    {' '}
                    <Trans ns="friendlyMatches" i18nKey="nearby">Nearby</Trans>
                  </>
                )
                : (
                  <>
                    <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
                    {' '}
                    <Trans ns="friendlyMatches" i18nKey="distanceAway">
                      {{ kmDistance }}
                      {' '}
                      km away
                    </Trans>
                  </>
                )}
            </Distance>
          )}
        </PlayerMetaData>
      </FirstPart>
      <SecondPart>
        <PlayerMetaData autoLeftMargin justifyCenter>
          {topPicks ? (
            <InviteOrRematchButtonContainer>
              <InviteOrRematchButton
                outline
                onClick={!playedBefore ? () => handleInvite() : () => handleInvite('rematch')}
              >
                <ButtonTopPicksIcon
                  src={LoadImage(`friendly-matches/start-match/button-${!playedBefore ? 'invite' : 'rematch'}.svg`)}
                  alt="Invite to play"
                />
                {!playedBefore
                  ? <Trans ns="friendlyMatches" i18nKey="invite">Invite</Trans>
                  : <Trans ns="friendlyMatches" i18nKey="rematch">Rematch</Trans>}
              </InviteOrRematchButton>
            </InviteOrRematchButtonContainer>
          ) : (
            <RaiseHand handRaisedData={raisedHandData} interactive={false} details="down" />
          )}
        </PlayerMetaData>
      </SecondPart>
    </PlayerContainer>
  );
};

export default Player;
