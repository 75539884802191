/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
// import Link from "../../../../components/Link";

import LoadImage from '../../../../components/common/LoadImage';

const ContentWrapper = styled.section`
  border: 1px solid #eaf6f6;
  border-radius: 10px;
  padding: ${({ theme }) => theme.functions?.toEm(10)};
  margin-bottom: ${({ theme }) => theme.functions?.toEm(30)};

  ${({ theme }) => theme.functions?.minTablet(`
      padding: ${theme.functions?.toEm(30)};
  `)}

  h6 {
    font-size: ${({ theme }) => theme.functions?.toEm(24)};
    font-weight: 700;
    margin: 0 0 ${({ theme }) => theme.functions?.toEm(10)};
  }

  img {
    margin: 0 auto;
    display: block;
  }
`;

const Sponsors = ({ content, section, ...props }) => {
  const { t: commonT } = useTranslation('common');

  const mapContent = {
    officialBall: {
      title: commonT(section),
      subtitle: content?.name,
      description: null,
      image: `balls/${content?.image}`,
      centered: true,
    },
    eventPrizes: {
      title: commonT(section),
      subtitle: null,
      description: commonT('eventPrizesDescription'),
      image: null,
      centered: true,
    },
    playerPackage: {
      title: content.title,
      subtitle: null,
      description: content.summary,
      image: null,
      centered: false,
    },
  };
  const data = mapContent[section];

  return (
    <ContentWrapper {...props}>
      {data?.title && <h6 className={data?.centered ? 'text-center' : ''}>{data.title}</h6>}
      {data?.subtitle && <p className={data?.centered ? 'text-center mb20' : 'mb20'}>{data.subtitle}</p>}
      {data?.image && (
        // <Link to={data.link}>
        <img className="img-responsive" src={LoadImage(data.image)} alt="" />
        // </Link>
      )}
      {data?.description && <p className="fs16 pre-line">{data.description}</p>}
    </ContentWrapper>
  );
};

export default Sponsors;
