import styled from '@emotion/styled';

export const SliderWrapper = styled.div({
  height: 'calc(100vh - 350px)',
  minHeight: '500px',
  backgroundColor: '#0b2e37',

  '.slick-slider, .slick-track, .slick-list': {
    height: '100%',
  },

  '.slick-slide > div': {
    height: '100%',
  },

  '.slick-dots': {
    position: 'relative',
    bottom: 0,
    marginTop: '-60px',
    '@media (max-width: 650px)': {
      marginTop: '10px',
    },
    color: '#fff',
    'button:before': {
      width: '8px',
      height: '8px',
      marginTop: '5px',
      border: '2px solid #fff',
      borderRadius: '50%',
      content: '""',
      '@media (max-width: 650px)': {
        border: '2px solid #000',
      },
    },
    '.slick-active': {
      'button:before': {
        background: '#fff',
        '@media (max-width: 650px)': {
          background: '#000',
        },
      },
    },
  },
});

export const SliderContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '750px',
  zIndex: 11,

  h1: {
    whiteSpace: 'break-spaces',
  },

  button: {
    marginTop: '20px',
  },

  '@media (max-width: 800px)': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 'unset',
    width: 'unset',
    padding: '20px',
  },
}));

export const InnerSlider = styled.div(props => ({
  display: 'flex !important',
  paddingLeft: '20%',
  alignItems: 'center',
  minHeight: '350px',
  height: '100%',
  background: `#000 url(${props.background})`,
  backgroundSize: 'cover',
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  objectFit: 'cover',
  objectPosition: 'right',
  '@media (max-width: 990px)': {
    paddingLeft: 'unset',
    justifyContent: 'center',
  },
}));
