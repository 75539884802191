import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';

// components
import LoadImage from '../../../../components/common/LoadImage';
import { store as socketStore } from '../../../../components/Socket/store';

// actions
import { addMessage } from '../../actions';

// reducers
import { pushMessageToConversation, updateLastMessage } from '../../reducers';

const shortName = (data) => `${data.firstName} ${data.lastName.charAt(0)}.`;

const AddMessage = ({ isBlocked, lastMessageRef }) => {
  const { conversationId } = useParams();
  const dispatch = useDispatch();
  const globalState = useContext(socketStore);
  const { state: { socket: { socket } = {} } = {} } = globalState;
  const { addToast } = useToasts();
  const { t } = useTranslation('conversations');

  const { accountInfo } = useSelector(state => state?.session);
  const { list: { data: conversations } } = useSelector(state => state?.conversations);
  const { data: onlineUsers } = useSelector(state => state?.dashboard?.onlineUsers);

  const [isTyping, setIsTyping] = useState(false);
  const [prevConversationId, setPrevConversationId] = useState(conversationId);
  const [focusButton, setFocusButton] = useState(false);
  const [channels, setChannels] = useState([]);

  const conversationInfo = (conversations || [])
    .filter(({ id }) => id?.toString() === conversationId).shift();

  // set socket emit channels
  useEffect(() => {
    if (conversations.length > 0) {
      const ids = (conversations || [])
        .reduce((a, { members, leftBy }) => {
          (members || [])
            .filter((member) => !leftBy.includes(member.userId))
            .map(({ id }) => a.push(id));
          return a;
        }, [])
        .filter((id) => id !== accountInfo?.id)
        .filter((v, i, a) => a.indexOf(v) === i);
      setChannels(ids);
    }
  }, []);

  useEffect(() => {
    const message = document.getElementById('message');

    if (channels && socket && prevConversationId && message?.value?.length > 0) {
      setIsTyping(false);
      socket.emit('typing-message', {
        channels,
        conversationId: prevConversationId,
        fullName: shortName(accountInfo),
        isTyping: false,
        conversationType: conversationInfo?.type,
      });
    }

    if (message) {
      message.value = '';
    }

    setPrevConversationId(conversationId);
  }, [channels, conversationId, socket]);

  const typingMessage = (e) => {
    setIsTyping(!!e.target.value.length);

    if (socket && (e.target.value.length === 0 || isTyping === false)) {
      socket.emit('typing-message', {
        channels,
        conversationId,
        fullName: shortName(accountInfo),
        isTyping: !!e.target.value.length,
        conversationType: conversationInfo?.type,
      });
    }
  };

  const submitMessage = async (e) => {
    e.preventDefault();

    const message = e.target.message.value;

    if (!message.length) return;

    // reset input text
    e.target.reset();

    const payload = {
      conversationId,
      userId: accountInfo?.userId,
      userInfo: {
        id: accountInfo?.id,
        userId: accountInfo.userId,
        firstName: accountInfo.firstName,
        lastName: accountInfo.lastName,
        profilePicture: accountInfo.profilePicture,
      },
      message: CryptoJS.AES.encrypt(message, conversationId).toString(),
      tempId: new Date().getTime(),
      onlineUsers,
    };

    // reset is typing message
    setIsTyping(false);
    await socket.emit('typing-message', {
      channels,
      conversationId,
      fullName: shortName(accountInfo),
      isTyping: false,
      conversationType: conversationInfo?.type,
    });

    // send message
    try {
      // Push message to conversation
      dispatch(pushMessageToConversation({
        ...payload,
        id: payload.tempId,
        status: 'pending',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }));

      dispatch(updateLastMessage({
        conversationId,
        lastMessage: {
          message: payload.message,
          createdAt: new Date().toISOString(),
          status: 'pending',
        },
      }));

      await addMessage({
        payload,
        addToast,
        t,
      });
    } catch (err) {
      // console.log(err);
    }

    lastMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const onFocusHandler = async () => {
    setFocusButton(true);
  };

  const hasLeftGroup = conversationInfo?.type === 'group' && (conversationInfo?.leftBy || [])
    .includes(accountInfo?.userId);

  if (hasLeftGroup) {
    return (
      <p className="left-conversation-message">
        <Trans ns="conversations" i18nKey="leftGroupChat">
          You can&apos;t send messages to this group chat because you are no longer a participant.
        </Trans>
      </p>
    );
  }

  return (
    <form onSubmit={submitMessage}>
      <input
        placeholder={isBlocked(conversationInfo) ? t("playerBlocked") : t("typeMessage")}
        type="text"
        name="message"
        id="message"
        onChange={typingMessage}
        onFocus={onFocusHandler}
        onBlur={() => setFocusButton(false)}
        autoComplete="off"
        autoFocus
        maxLength={1000}
        disabled={isBlocked(conversationInfo)}
      />
      <button type="submit" className={focusButton ? 'is-focused' : ''} disabled={isBlocked(conversationInfo)}>
        <img src={LoadImage('send-new-message-icon.svg')} alt="Send message" />
      </button>
    </form>
  );
};

export default AddMessage;
