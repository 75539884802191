/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const colourStyles = ({ hasValue, lowInput }) => ({
  container: styles => ({
    ...styles,
    lineHeight: 1,
    cursor: 'pointer',
  }),
  control: styles => ({
    ...styles,
    boxShadow: 'none',
    minHeight: '51px',
    marginTop: 0,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '4px 16px',
    border: '1px solid #78ADAB',
    '&:hover': {
      border: '1px solid #78ADAB',
    },
    ...hasValue && {
      '.label': {
        left: '8px !important',
      },
    },
    ...lowInput && {
      '> div': {
        overflow: 'visible',
      },
    },
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0',
  }),
  singleValue: styles => ({
    ...styles,
    margin: '0',
    marginTop: hasValue || lowInput ? '7px' : 0,
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.1s ease',
    padding: '2px 0',
  }),
  menu: styles => ({
    ...styles,
    fontSize: '14px',
    zIndex: 10,
  }),
  input: styles => ({
    ...styles,
    margin: '0',
    fontFamily: 'DM Sans',
    marginTop: hasValue ? '7px' : 0,
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.1s ease',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: 0,
    ...lowInput && {
      top: '15px',
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    marginTop: 0,
    marginRight: '-10px',
    width: 'fit-content',
    height: 'inherit',
  }),
});

const SelectGoogleAutocomplete = ({
  autocompletionRequest, minLengthAutocomplete, hook, ...props
}) => {
  const theme = useTheme();
  const { colors, functions } = theme;
  const {
    form: {
      setFieldTouched, setFieldValue, setFieldError, errors, touched,
    } = {},
  } = props || {};

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&language=en`;
    const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

    if (!existingScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptSrc;
      script.onload = () => setScriptLoaded(true);

      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  const handleChange = async (address) => {
    if (scriptLoaded && address) {
      if (!address && setFieldTouched && setFieldValue) {
        setFieldTouched(props?.field?.name);
        setFieldValue(props?.field?.name, '');
        return false;
      }

      const coords = await geocodeByPlaceId(address.value.place_id)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => ({ lat, lng }));

      const data = {
        label: address.label.split(',')[0],
        value: {
          name: address.label,
          city: address.value.terms.length >= 2 ? address.value.terms[address.value.terms.length - 2]?.value : null,
          country: address.value.terms.length >= 2 ? address.value.terms[address.value.terms.length - 1]?.value : null,
          coords,
        },
      };

      if (setFieldTouched && setFieldValue) {
        setFieldTouched(props?.field?.name);
        setFieldValue(props?.field?.name, data);
      }

      if (hook) {
        hook(data);
      }

      return data;
    }

    if (hook) {
      hook(address);
    }
  };

  const handleError = error => {
    setFieldError(props?.field?.name, error);
  };

  const setNoMessageText = () => (props?.field?.value?.length < 3
    ? `Type at least ${minLengthAutocomplete} chars to show results...`
    : 'No results found.');

  const hasValue = props?.field?.value?.label;

  return (
    <div className="form-group form-group--select">
      <label className={hasValue || props.labelOn ? 'validLocation' : ''}>{props.label}</label>
      {scriptLoaded
        ? (
          <GooglePlacesAutocomplete
            autocompletionRequest={autocompletionRequest}
            minLengthAutocomplete={minLengthAutocomplete}
            selectProps={{
              value: props?.field?.value,
              onChange: handleChange,
              onError: handleError,
              styles: colourStyles({ hasValue, lowInput: hasValue, ...props }),
              noOptionsMessage: setNoMessageText,
              ...props,
            }}
          />
        ) : ''}
      {errors && errors[name] && touched && touched[name]
        ? (
          <div css={css`
        color: ${colors?.red}; 
        font-weight: 500;
        text-align: center;
        position: relative;
        top: ${functions?.toEm(-15)};
        `}
          >
            {errors[name]}
          </div>
        ) : null}
    </div>
  );
};

export default SelectGoogleAutocomplete;
