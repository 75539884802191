export default {
  events: 'Find an Event',
  courts: 'Courts',
  membership: 'Membership',
  comingSoon: 'Coming Soon',
  findEvent: 'Find an Event',
  book_court: 'Book a Court',
  findPlayers: 'Find a Player',
  forPlayers: 'For Players',
  rankings: 'Rankings',
  forClubs: 'For Clubs',
  blog: 'Blog',
  help: 'Help',
  aboutUs: 'About Us',
  motto: 'Land your best match!',
  joinNow: 'Join Now',
  privacy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  returnPolicy: 'Return Policy',
  language: 'Language',
  english: 'English',
  romanian: 'Romanian',
  English: 'English',
  Romanian: 'Romanian',
  copyrightFooter: 'All rights reserved.',
  rules: 'Sportya  - Rules & Regulations',
  groupsPlaying: 'Groups Playing Formats',
  localLeaguesRules: 'Local Leagues - Rules & Regulations',
  sportyaRomania: 'Sportya Romania',
  sportyaBulgaria: 'Sportya Bulgaria',
  sportya: 'Sportya',
  sportyaDesc: 'We have set out to connect and inspire athletes of the world by creating competitive platforms, events and specific tools that help them make the most out of their sport life.',
};
