import styled from '@emotion/styled';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Link from './Link';
import Paragraph from './Paragraph';
import LoadImage from './common/LoadImage';

const Container = styled.div`
position: absolute;
top: 20px;
right: 30px;

${props => props.theme.functions?.mediaQueryMax('1024px', `
top: unset !important;
right: unset !important;
bottom: ${props.bottom ? props.bottom : '40px'};
`)}

p 
{
    display: flex;
    align-items: center;
    
    > a
    {
        text-decoration: none;
        margin-right: 5px;
        font-weight: bold;
        font-size: 14px;
        color: #FF1717;
    }
} 
`;

const Logout = ({ bottom }) => {
  const { t: commonT } = useTranslation('common');

  return (
    <Container bottom={bottom}>
      <Paragraph>
        <Link to="/logout">{ commonT('logout') }</Link>
        <img src={LoadImage('red-logout-icon.svg')} alt="Logout" />
      </Paragraph>
    </Container>
  );
};

export default Logout;
