import React from 'react';

import Paragraph from '../../../../components/Paragraph';
import LoadImage from '../../../../components/common/LoadImage';
import { BenefitsListItem } from './styles';

const index = ({ children }) => (
  <BenefitsListItem>
    <div className="benefits-list">
      <img src={LoadImage('checkmark-blue.svg')} alt="check" />
      <Paragraph>{children}</Paragraph>
    </div>
  </BenefitsListItem>
);

export default index;
