export default {
  seoTitle: 'Hamarosan új funkciók érkeznek | Sportya',

  item1: {
    title: 'Bolgár',
    subtitle: '(Új nyelv)',
    text: 'Keményen dolgozunk a Sportya bolgár verziójának indításán. Tudjuk, hogy ez mennyire kritikus fontosságú. Rajta vagyunk a témán.',
  },

  item2: {
    title: 'Játékosok üzenetei',
    text: 'Úgy döntöttünk, hogy elhalasztjuk az új üzenetküldő rendszer bevezetését, mivel úgy tervezzük, hogy kibővítjük annak az egyéb Sportya-szolgáltatásokkal való összekapcsolhatóságát. Ez elengedhetetlen a Barátságos mérkőzéseknek a Sportya-n belüli kapcsolataid kezelésére szolgáló új rendszerrel együtt történő újraindításához.',
  },

  item3: {
    title: 'Barátságos mérkőzések',
    subtitle: '(Újraindítás)',
    text: 'Ez nem egy teljesen új funkció, a veterán játékosok jól ismerik. A fejlesztés alatt álló frissítések lehetővé teszik, hogy a korábbinál gyorsabban, illetve a versenyre való képesség és a várható mérkőzésélmény szempontjából nagyobb pontossággal találjatok partnert egy kompetitív mérkőzésre.',
  },

  item4: {
    title: 'Barátok listája',
    text: 'Ez lesz az új módja annak, hogy a Sportya felületén kapcsolatba lépj barátaiddal, bővítsd a hálózatodat és fenntartsd a kapcsolataidat egész évben. Ahogy fentebb említettük, az üzenetküldő rendszerrel és a barátságos mérkőzésekkel való további integrációk lehetővé fogják tenni, hogy még többet hozz ki a teniszéletedből.',
  },

  item5: {
    title: 'Blog a hírekről és frissítésekről',
    text: 'A Sportya-nál hiszünk abban, hogy téged is be kell vonni a beszélgetésbe. Ezért a Hírek és frissítések rovatunkat blogként indítjuk újra. Úgy tervezzük, hogy részletesebben tájékoztatunk titeket a Sportya-nál történtekről, és lehetőséget adunk arra, hogy hozzájáruljatok a Sportya jövőjéhez.',
  },

  item6: {
    title: 'Ismerd meg a párodat',
    subtitle: '(párosoknak)',
    text: 'Hamarosan véget ér a küszködés, hogy játékostársat találj a Páros mérkőzésekhez. Bemutatunk egy gyakran kért funkciót: a lehetőséget, hogy más játékosokat találj, akik szívesen csatlakoznak egy csapathoz, egy adott eseményre. Hamarosan elérhető lesz, egy hozzád közeli eseményen.',
  },

  item7: {
    title: 'Foglalás',
    text: 'Felszámoljuk közted és a pálya között lévő távolságot. Ez a Sportya Foglalás, ami sokkal több, mint egyszerű foglalás. A pályák széles választékára számíts és szoros integrációra más Sportya szolgáltatásokkal. A végeredmény: minden eddiginél gyorsabban állíthatsz össze egy mérkőzést, legyen szó barátságos vagy versenymérkőzésről.',
  },

  item8: {
    title: 'Élő eredmények',
    text: 'A rendszer első iterációjában újraindítjuk a lehetőséget, hogy mind az eseményvezetők, mind a játékosok gyorsabban és egyszerűbben adhassák meg a mérkőzések eredményeit, mint korábban. A továbbiakban az új rendszer lehetővé fogja tenni számunkra, hogy olyan szoftveres és hardveres megoldásokat fejlesszünk ki, amelyek átalakítják a mérkőzésélményt, és lehetővé teszik, hogy a játékra koncentrálj és élvezd a mérkőzést.',
  },

  item9: {
    title: 'Események kezelése',
    subtitle: '(Klubok és szervezők)',
    text: 'Mély integrációs folyamatot fogunk kezdeni a Sportya összes partnerklubjával, összekapcsolva szolgáltatásainkat az operatív rendszereikkel. Ez lehetővé fogja tenni számunkra, hogy új szolgáltatásokat fejlesszünk ki a játékosok részére, és hogy a klubok mélyebb szinten lépjenek kapcsolatba veletek, és gyorsabb és pontosabb szolgáltatásokat és jobb általános élményt nyújtsanak tagjaik és ügyfeleik számára.',
  },

  comingSoon: 'Hamarosan érkezik',
  worthTheWait: 'Megéri a várakozást',

  stayTuned: 'Maradjon velünk, ha többet szeretne megtudni!',
};
