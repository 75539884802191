import { post } from '../../../components/common/http';

export const authorizePayment = async (props) => {
  const {
    payload,
    addToast,
    setSubmitting,
    socketClientId,
    t,
  } = props;

  try {
    await post('/payments/authorize', { socketClientId, data: payload });

    setSubmitting(false);
  } catch (error) {
    setSubmitting(false);

    return (
      addToast
      && addToast(t(error?.response?.data?.message || 'COULD_NOT_AUTHORIZE_PAYMENT'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};
