function addMinutesToTime(time, duration) {
  try {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(hours, Number(minutes) + Number(duration));

    return `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  } catch (e) {
    throw new Error(e);
  }
}

export default addMinutesToTime;
