import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import dateFormat from 'dateformat';

import { TabPlaceholder } from '../components/styles';
import { fetchTabData, setActiveFilters } from '../../reducers';
import { Contain, P } from '../../../../components/Collection';
import {
  Container, Date, Matches, Title, TitleContent, TitleHeader, TitleHeaderIcon, TitleMatches, TitlesContainer,
} from './styles';
import GameType from '../components/GameType';
import LoadImage from '../../../../components/common/LoadImage';
import Loader from '../../../../components/ContentLoader';
import Select from '../../../../components/Form/Select';
import { FiltersContainer, TabOptionsContainer } from '../Activity/styles';
import { Paragraph } from '../../../../components';
import { UserContext } from '../../index';

const categoryBg = {
  firstServe: '#E9E9E9',
  gold: '#FFCC18',
  silver: '#B0B0B0',
  platinum: '#5754FF',
  champions: '#CB5DFF',
  special: '#CB5DFF',
  league: '#39D377;',
};

const TitlesTab = ({ activeSports, masterSport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [gameType, setGameType] = useState('singles');
  const [filters, setFilters] = useState({ sportType: masterSport });

  const user = useContext(UserContext);
  const { userId, profile } = user || {};
  const {
    data,
    status,
    activeFilters,
  } = useSelector(state => state.accounts.info.tabs[profile].titles[gameType]);
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);

  const isLoading = generalStatus === 'loading';
  const hasNoResults = !data?.length;

  useEffect(() => {
    if ((isEmpty(data) && status === 'idle') || (JSON.stringify(filters) !== JSON.stringify(activeFilters))) {
      dispatch(fetchTabData({
        userId,
        tab: 'titles',
        filters,
        gameType,
        options: {},
        profile,
      }));
      dispatch(setActiveFilters({
        gameType,
        profile,
        tab: 'titles',
        filters,
      }));
    }
  }, [dispatch, filters, gameType, status]);

  if (isLoading) {
    return (
      <Container hasLoader>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <TabOptionsContainer>
        <GameType gameType={gameType} setGameType={setGameType} />
        <FiltersContainer>
          <Select
            name="sportType"
            placeholder={t('sports.all')}
            onChange={(e) => setFilters({ ...filters, sportType: e.value })}
            options={['all', ...activeSports].map((sport) => ({
              label: t(`sports.${sport}`),
              value: sport,
            }))}
            label={t('form.sport', { ns: 'clubs' })}
            value={filters.sportType}
            lowInput
            width="150px"
            {...(filters.sportType && {
              labelOn: true,
              value: ['all', ...activeSports]
                .filter((sport) => sport === filters.sportType)
                .map((sport) => ({
                  label: t(`sports.${sport}`),
                  value: sport,
                })),
            })}
          />
        </FiltersContainer>
      </TabOptionsContainer>

      {data.map(({ sportType, categories }) => (
        <div key={sportType} className="mb40">
          <Paragraph large bold key={sportType} className="text-capitalize mb20">
            {sportType}
          </Paragraph>

          {(categories || [])?.map(({ category, events }, key) => {
            const hasAnyTitles = !!events?.length;
            const championList = events?.filter((event) => event?.title === 'champion');
            const finalistList = events?.filter((event) => event?.title === 'finalist');
            const hasChampionTitles = !!championList?.length;
            const hasFinalistTitles = !!finalistList?.length;
            return (
              hasAnyTitles && (
                <TitlesContainer key={key} border={categoryBg[category]}>
                  <TitleHeader bg={categoryBg[category]}>
                    <TitleHeaderIcon
                      src={LoadImage(`player-profile/titles-tab/${category}.svg`)}
                      alt={t(category)}
                    />
                    <P large bold color="white">
                      <Trans ns="common" i18nKey={category}>
                        {category}
                      </Trans>
                    </P>
                  </TitleHeader>
                  <TitleContent>
                    {hasChampionTitles && (
                      <TitleMatches>
                        <Title large bold>
                          <Trans ns="player" i18nKey="champion">
                            Champion
                          </Trans>
                        </Title>
                        {championList?.map((championTitle, championKey) => (
                          <Matches
                            key={championKey}
                            to={`/events/${championTitle?.eventId}/competitions/${championTitle?.competitionId}`}
                          >
                            {championTitle?.eventName}
                            <Date>
                              {dateFormat(championTitle?.date, 'd mmm yyyy')}
                            </Date>
                          </Matches>
                        ))}
                      </TitleMatches>
                    )}
                    {hasFinalistTitles && (
                      <TitleMatches
                        addMarginTop={hasChampionTitles && hasFinalistTitles}
                      >
                        <Title large bold>
                          <Trans ns="player" i18nKey="finalist">
                            Finalist
                          </Trans>
                        </Title>
                        {finalistList?.map((finalistTitle, finalistKey) => (
                          <Matches
                            key={finalistKey}
                            to={`/events/${finalistTitle?.eventId}/competitions/${finalistTitle?.competitionId}`}
                          >
                            {finalistTitle?.eventName}
                            <Date>
                              {dateFormat(finalistTitle?.date, 'd mmm yyyy')}
                            </Date>
                          </Matches>
                        ))}
                      </TitleMatches>
                    )}
                  </TitleContent>
                </TitlesContainer>
              )
            );
          })}
        </div>
      ))}

      {hasNoResults && (
        <Contain
          width="fit-content"
          align="center"
          direction="column"
          margin="40px auto"
        >
          <TabPlaceholder
            src={LoadImage('tab-placeholder.png')}
            alt="No results found"
          />
          <P color="#78ADAB">No results found</P>
        </Contain>
      )}
    </Container>
  );
};

export default TitlesTab;
