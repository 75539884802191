import styled from '@emotion/styled';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flexDirection: "column",
  marginBottom: 30,

  a: {
    textDecoration: "none",
  },
});

export const GameLevel = styled.div({
  position: 'absolute',
  bottom: -9,
  left: "calc(50% - 10px)",
});

export const Avatar = styled.div(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  width: 80,
  height: 80,
  border: `1px solid ${theme.colors.mediumTurquoise}`,
  borderRadius: "50%",
  marginBottom: 15,

  ".profilePicture": {
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
  },
}));

export const Initials = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 80,
  width: 80,

  userSelect: 'none',
  cursor: 'pointer',

  fontWeight: 700,
  fontSize: '18px',

  '&:hover': {
    textDecoration: 'none !important',
  },
});
