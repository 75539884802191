/* eslint-disable no-tabs */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import BeforePlaying from '../../common/beforePlaying';
import LoadImage from '../../common/LoadImage';
import { P } from '../../Collection';

const Competition = styled.li({
  display: 'flex',
  position: 'relative',
  borderRadius: '36px',
  alignItems: 'center',
  margin: '0 4px 4px 0',
  padding: '0 8px',
  height: '24px',
  '&:hover > .popup': {
    visibility: 'visible',
  },
  '@media (max-width: 650px)': {
    position: 'unset',
  },
  cursor: 'pointer',
  userSelect: 'none',
});

export const Popup = styled.span({
  position: 'absolute',
  visibility: 'hidden',
  padding: '5px 10px',
  borderRadius: '4px',
  top: '-40px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'rgb(15, 36, 44)',
  width: 'max-content',
  zIndex: 1,
  color: '#fff !important',
  whiteSpace: 'unset',
  '@media (max-width: 670px)': {
    left: '0 !important',
    top: '-40px !important',
    transform: 'unset',
  },
});

const Labels = ({
  event, competition, double, eventPhaseStatus, followedEvent,
}) => {
  const availableSlots = Math.max(0, (Number(competition?.mlMaxNumber) - Number(competition?.mainList) - Number(competition?.wildCards)));

  const tooltipRef = useRef(null);
  const { t } = useTranslation('events');
  const { t: commonT } = useTranslation('common');
  const isBeforePlaying = BeforePlaying(event, eventPhaseStatus);

  useEffect(() => {
    window.addEventListener('resize', afterShow);
    return () => {
      window.addEventListener('resize', afterShow);
    };
  });

  const listType = (availableSlots === 0 && ['preRegistrationsOpen', 'registrationsOpen'].includes(eventPhaseStatus)) || ['drawPending', 'readyToPlay', 'playing'].includes(eventPhaseStatus) ? 'waitingList'
    : !['openingSoon', 'preRegistrationsOpen'].includes(eventPhaseStatus) ? 'mainList'
      : ['preRegistrationsOpen'].includes(eventPhaseStatus) ? 'sortList'
        : ['openingSoon'].includes(eventPhaseStatus) && 'sortListSoon';

  const textColor = {
    availableMl: '#65C763',
    availableOs: '#7CC9C6',
    availableWl: '#FFA800',
    closed: '#FC878E',
    ended: '#C8C8C8',
  };

  const badgeStatus = {
    sortList: {
      bg: 'availableMl',
    },
    sortListSoon: {
      bg: 'availableOs',
    },
    mainList: {
      bg: eventPhaseStatus === 'registrationsOpen' ? 'availableMl' : 'closed',
    },
    waitingList: {
      bg: isBeforePlaying ? 'availableWl' : 'closed',
    },
  };

  const popup = {
    level: competition.level,
    minAge: competition.restrictions?.minAge ?? 15,
    maxAge: competition.restrictions?.maxAge,

    genderIcon: {
      male: 'men-icon.svg',
      female: 'women-icon.svg',
      mixt: 'mixt-icon.svg',
    },

    hasRestrictions: {
      age: competition?.restrictions?.minAge > 15 || competition.restrictions?.maxAge,
      teamAverageLevel: double && competition.restrictions?.teamAverageLevel,
      gender: competition.restrictions?.gender === 'male' ? 'onlymale'
        : competition.restrictions?.gender === 'female' ? 'onlyfemale'
          : competition.restrictions?.gender === 'mixt' && 'onlymixt',
    },

    get occupiedSlots() {
      if (followedEvent) {
        return listType === 'waitingList'
          ? competition.waitingList?.length : listType === 'sortList'
            ? competition.preRegistrationList?.length : competition.mainList?.length;
      }
      return listType === 'waitingList'
        ? competition.waitingList : listType === 'sortList'
          ? competition.preRegistrationList : competition.mainList;
    },

    get ageRestriction() {
      return this.minAge && this.maxAge
        ? `${this.minAge}-${this.maxAge}`
        : this.minAge > 15 ? `${this.minAge}+` : '';
    },

    get content() {
      return `${commonT('level')} ${competition.level} ${this.hasRestrictions.gender ? commonT(this.hasRestrictions.gender) : ''} - 
			${listType === 'mainList' ? `${availableSlots} ${t(availableSlots !== 1 ? 'slotsAvailable' : 'slotAvailable')} (${t(listType)})`
        : listType === 'waitingList' ? `${this.occupiedSlots} ${t(this.occupiedSlots !== 1 ? 'slotsOccupied' : 'slotOccupied')} (${t(listType)})`
          : listType === 'sortList' ? t('sortListOpen') : t('openingSoon')}`;
    },
  };

  const afterShow = () => {
    const popupRef = tooltipRef?.current?.getBoundingClientRect();
    const overflownLeft = popupRef?.left < 0;
    const overflownRight = popupRef?.right > window?.innerWidth;
    if (overflownLeft) {
      tooltipRef?.current?.style.setProperty('transform', 'translateX(0%)');
    } else if (overflownRight) {
      tooltipRef?.current?.style.setProperty('left', '-30%');
      tooltipRef?.current?.style.setProperty('transform', 'translateX(-70%)');
    }
  };

  return (
    <Competition className={badgeStatus?.[listType]?.bg} onMouseOver={() => afterShow()}>
      <Popup className="popup" ref={tooltipRef}>
        <P xSmall color="white">{popup.content}</P>
      </Popup>

      {popup.hasRestrictions.gender
        && (<img src={LoadImage(popup.genderIcon?.[competition.restrictions?.gender])} alt="Gender Icon" />)}

      <P small color="white">{popup.level}</P>

      {popup.hasRestrictions.age
        && (
          <P
            xSmall
            customColor={textColor?.[badgeStatus?.[listType]?.bg]}
            margin="0 0 0 5px"
            background="#fff"
            padding="1px 4px"
            borderRadius="23px"
          >
            {popup.ageRestriction}
          </P>
        )}

      {popup.hasRestrictions.teamAverageLevel && (
        <P xSmall color="white" margin="0 0 0 5px">
          {popup.level - 0.5}
        </P>
      )}
    </Competition>
  );
};

export default Labels;
