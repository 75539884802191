/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { isEmpty, groupBy } from 'lodash';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import Slider from 'react-slick';

import 'react-tabs/style/react-tabs.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ContentWrapper } from './styles';
import { getEventMatches } from '../../../actions';
import LevelBadge from '../../../../../components/LevelBadge';
import WinnersElement from '../../../../../components/WinnersElement';
import H3 from '../../../../../components/H3';
import P from '../../../../../components/Paragraph';
import Competition from '../../../EventDetails/components/competition';
import ContentLoader from '../../../../../components/ContentLoader';

const settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

const CompetitionsWinners = ({ eventInfo, ...props }) => {
  const [winners, setWinners] = useState([]);
  const [loadingWinners, setLoadingWinners] = useState(false);

  const handleTab = async () => {
    if (isEmpty(winners) && eventInfo?._id) {
      setLoadingWinners(true);
      let result = await getEventMatches(eventInfo?._id);
      result = result.filter(match => !!match.winner && match.competition.stage === match.competition.finalStage);
      if (result) {
        const data = groupBy(result, 'competition.gameType');

        data.singles = (data.singles || []).map(match => ({
          ...match,
          players: [
            { accountType: 'player', ...match.player },
            { accountType: 'opponent', ...match.opponent },
          ],
        }));

        data.doubles = (data.doubles || []).map(match => ({
          ...match,
          players: [
            {
              accountType: 'player',
              winner: match.winner === 'player',
              team: [match.player, match.playerPartner],
            },
            {
              accountType: 'opponent',
              winner: match.winner === 'opponent',
              team: [match.opponent, match.opponentPartner],
            },
          ].sort((x, y) => y.winner - x.winner),
        }));

        setWinners(data);
        setLoadingWinners(false);
      }
    }
  };

  return (
    <ContentWrapper {...props}>
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <TabList>
          <Tab>
            <H3><Trans ns="events" i18nKey="competitions">Competitions</Trans></H3>
          </Tab>
          {eventInfo?.currentPhase?.status === 'ended'
            && (
              <Tab onClick={handleTab}>
                <H3><Trans ns="events" i18nKey="allWinners">All Winners</Trans></H3>
              </Tab>
            )}
        </TabList>

        <TabPanel>
          <ul>
            {eventInfo?.competitions?.map((competitionInfo, key) => (
              <Competition
                key={key}
                {...{ competitionInfo, eventInfo, eventStatus: eventInfo?.currentPhase?.status }}
              />
            ))}
          </ul>
        </TabPanel>

        {eventInfo?.currentPhase?.status === 'ended'
          && (
            <TabPanel>
              {(loadingWinners) ? <ContentLoader title={false} /> : (
                <Tabs forceRenderTabPanel defaultIndex={0} className="secondary_tabs">
                  <TabList className="text-center secondary_tabs--panel">
                    <Tab>
                      <P medium bold><Trans ns="events" i18nKey="singles">Singles</Trans></P>
                    </Tab>
                    {!isEmpty(winners?.doubles)
                    && (
                      <Tab>
                        <P medium bold><Trans ns="events" i18nKey="doubles">Doubles</Trans></P>
                      </Tab>
                    )}
                  </TabList>

                  <TabPanel>
                    {/* solo case */}
                    <Slider {...settings}>
                      {winners?.singles?.map((match, key) => {
                        const sortedPlayers = [];
                        if (match?.winner === 'opponent') {
                          sortedPlayers[0] = { ...match?.opponent, accountType: 'opponent' };
                          sortedPlayers[1] = { ...match?.player, accountType: 'player' };
                        } else if (match?.winner === 'player') {
                          sortedPlayers[0] = { ...match?.player, accountType: 'player' };
                          sortedPlayers[1] = { ...match?.opponent, accountType: 'opponent' };
                        }

                        return (
                          <li key={key} className="winners">
                            <div className="text-center mb10">
                              <Trans ns="events" i18nKey="level">Level</Trans>
                            </div>
                            <LevelBadge centered>{match[match.winner].level}</LevelBadge>
                            <div className="separator" />
                            {sortedPlayers.map((player, idx) => ( // match.players
                              <WinnersElement
                                key={idx}
                                single
                                content={player}
                                isWinner={player.accountType === match.winner}
                              />
                            ))}
                          </li>
                        );
                      })}
                    </Slider>
                  </TabPanel>
                  {/* duo case */}
                  {!isEmpty(winners?.doubles)
                  && (
                    <TabPanel>
                      <Slider {...settings}>
                        {winners?.doubles?.map((match, key) => (
                          <li key={key} className="winners">
                            <div className="text-center mb10">
                              <Trans ns="events" i18nKey="level">Level</Trans>
                            </div>
                            <LevelBadge centered>{match[match.winner].level}</LevelBadge>
                            <div className="separator" />
                            {match.players.map((player, idx) => (
                              <WinnersElement
                                key={idx}
                                double
                                content={player.team}
                                isWinner={player.accountType === match.winner}
                              />
                            ))}
                          </li>
                        ))}
                      </Slider>
                    </TabPanel>
                  )}
                </Tabs>
              )}
            </TabPanel>
          )}
      </Tabs>
    </ContentWrapper>
  );
};

export default CompetitionsWinners;
