import styled from '@emotion/styled';

export const Header = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px',
  background: props.theme.colors.lightBlue2,
  marginBottom: '60px',
  '@media (max-width: 1240px)': {
    padding: '20px',
  },
}));

export const NewestContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1234px',
  width: '100%',
  margin: '0 auto',
  '@media (max-width: 1235px)': {
    h3: {
      textAlign: 'center',
    },
  },
});

export const NewestPlayers = styled.div({
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(auto-fill, minmax(286px, 286px))',
  width: '100%',
  padding: 'unset',
  margin: '0 auto',
  gap: '30px',

  '@media (max-width: 601px)': {
    'div:nth-of-type(n+4)': {
      display: 'none',
    },
  },
});

export const List = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1234px',
  width: '100%',
  margin: '0 auto 40px',
  '@media (max-width: 1260px)': {
    padding: '0 10px',
  },
});

export const ListOptions = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 0 20px',
  width: '100%',
  '@media (max-width: 850px)': {
    flexDirection: 'column',
  },
});

export const FiltersContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '.filters-amount': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2px',
    background: '#000',
    borderRadius: '50%',
    height: '21px',
    width: '21px',
  },
  '.form-group': {
    margin: '0 0 0 10px',
    width: '155px',
    '[class*="container"]': {
      margin: 'unset',
    },
  },
  '@media (max-width: 850px)': {
    margin: '25px 0 0 0',
  },
  '@media (max-width: 700px)': {
    flexDirection: 'column',
    width: '100%',
    '.form-group': {
      margin: '10px 0',
      width: '100%',
    },
    '> button': {
      width: '100%',
      margin: 'unset',
    },
    span: {
      margin: '0 0 20px 0',
    },
  },
});

export const FilterSpan = styled.span({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
});

export const FiltersAmount = styled.span({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 10px',
  background: '#000',
  borderRadius: '50%',
  width: '21px',
  height: '21px',
});

export const RefPosition = styled.div({
  position: 'absolute',
  top: '-30px',
});
