import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Paragraph from '../../../../../components/Paragraph';
import Level from '../Level';
import PlayerBadge from '../PlayerBadge';
import LoadImage from '../../../../../components/common/LoadImage';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';

const Match = ({
  x,
  isFinalStage,
  matchNumber,
  type,
  rowIdx,
  qualificationMatches,
}) => {
  const { accountInfo } = useSelector(state => state.session);
  const { t: commonT } = useTranslation('common');

  const qualificationPendingPlayer = (player, matches) => (
    matches?.length && player?.type === 'PENDING' && !player?.userId
  );

  const reasonOfLeave = {
    ABANDONED: <p>AB.</p>,
    NO_SHOW: <p>WO</p>,
  };

  const sortedPlayerScore = x?.player?.score?.slice()?.sort((a, b) => a?.set - b?.set);
  const sortedOpponentScore = x?.opponent?.score?.slice()?.sort((a, b) => a?.set - b?.set);
  const matches = qualificationMatches
    ?.filter((y) => y?.competition?.nextStagePosition === x?.competition?.position * 2
    || y?.competition?.nextStagePosition === x?.competition?.position * 2 - 1);

  const getScore = (main, player, opponent, key) => {
    const scores = {
      player,
      opponent,
    };

    return scores?.player?.[key]?.points || scores?.opponent?.[key]?.points ? scores?.[main]?.[key]?.points : null;
  };

  const getName = (userType, game) => {
    const player = {
      firstName: game?.[userType]?.firstName,
      lastName: game?.[userType]?.lastName,
    };
    return player.firstName && player.lastName
      ? `${player.firstName} ${player.lastName}`
      : commonT('notAvailable');
  };

  const isFriend = (userId) => accountInfo?.friendsIds?.includes(userId);

  return (
    <>
      {matches?.map((qualificationMatch, idx) => {
        const currentQuals = qualificationMatches
          ?.filter((y) => y?.competition?.nextStagePosition === x?.competition?.position * 2
          || y?.competition?.nextStagePosition === x?.competition?.position * 2 - 1);
        const qualPlayerScore = qualificationMatch?.player?.score?.slice()?.sort((a, b) => a?.set - b?.set);
        const qualOpponent = qualificationMatch?.opponent?.score?.slice()?.sort((a, b) => a?.set - b?.set);
        const isWinner = qualificationMatch?.competition?.finalStage === qualificationMatch?.competition?.stage;

        return (
          qualificationMatch && !rowIdx && (
            <div
              key={idx}
              className={`
                tournament-bracket__match
                ${currentQuals?.length === 1
                ? 'qualificationMatch'
                : (idx === 0 ? 'moreQualificationsFirstMatch ' : 'moreQualificationsSecondMatch')}
              `}
            >
              <Paragraph className="qualificationMatchIdentifier" small bold>
                Q
                {qualificationMatch?.competition?.position}
              </Paragraph>
              <div className="bracketInfo"><Paragraph>{qualificationMatch?.courtDetails}</Paragraph></div>
              <div className="playerWrapp">
                <div>
                  <div className={`player one ${accountInfo?.userId === qualificationMatch?.player ? 'you' : ''}`}>
                    <div className="playerInfo">
                      <Level
                        silver={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'}
                        gold={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'}
                        level={qualificationMatch?.player?.level}
                        small
                      />

                      <Paragraph
                        small
                        className={`
                          name
                          ${x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'
                          ? 'goldText'
                          : ''}
                          ${qualificationMatch?.winner === 'player' ? 'boldText' : ''}
                        `}
                      >
                        {qualificationMatch?.player?.id
                          ? (
                            <a href={`/accounts/${qualificationMatch?.player.id}`} target="_blank" rel="noreferrer">
                              {isFriend(qualificationMatch?.player?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {qualificationMatch?.player ? getName('player', qualificationMatch) : 'BYE'}
                            </a>
                          )
                          : (
                            <>
                              {isFriend(qualificationMatch?.player?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {qualificationMatch?.player ? getName('player', qualificationMatch) : 'BYE'}
                            </>
                          )}
                      </Paragraph>
                      {qualificationMatch?.winner === 'player' && type ? (
                        <PlayerBadge
                          winner={isWinner}
                        />
                      ) : qualificationMatch?.winner === 'player' && <PlayerBadge winner={isWinner} />}
                    </div>
                    {qualificationMatch?.opponent?.reason && qualificationMatch?.player?.reason
                      ? reasonOfLeave[qualificationMatch?.player?.reason]
                      : qualificationMatch?.opponent?.reason === 'NO_SHOW'
                        ? <div className="playerScore"><div className="emptyScore">-</div></div>
                        : (
                          <div
                            className={qualificationMatch?.opponent?.reason === 'ABANDONED'
                              ? 'playerScore abandonMargin'
                              : 'playerScore'}
                          >
                            {qualPlayerScore && qualPlayerScore.map((y, key) => {
                              const winnerScore = qualPlayerScore[key]?.points > qualOpponent?.[key]?.points;
                              return (
                                <div key={key} className="positionRelative">
                                  {/* tie score */}
                                  {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                                  <Paragraph
                                    key={y}
                                    small
                                    className={`
                                      score
                                      ${y?.competition?.finalStage === y?.competition?.stage && y?.winner === 'player'
                                      ? 'goldText' : ''}
                                      ${winnerScore ? 'boldText' : ''}`}
                                  >
                                    {getScore('player', qualPlayerScore, qualOpponent, key)}
                                  </Paragraph>
                                </div>
                              );
                            })}
                            {reasonOfLeave[qualificationMatch?.player?.reason]}
                          </div>
                        )}
                  </div>

                  {/* qualification player partner */}
                  {type && (
                    <div className={`
                      player
                      one
                      mt8
                      ${accountInfo?.userId === qualificationMatch?.playerPartner?.userId ? 'you' : ''}
                      `}
                    >
                      <div className="playerInfo">
                        <Level
                          silver={isWinner && qualificationMatch?.winner === 'opponent'}
                          gold={isWinner && qualificationMatch?.winner === 'player'}
                          level={qualificationMatch?.playerPartner?.level}
                          small
                        />

                        <Paragraph
                          small
                          className={`
                            name
                            ${isWinner && qualificationMatch?.winner === 'player' ? 'goldText' : ''}
                            ${qualificationMatch?.winner === 'player' ? 'boldText' : ''}
                          `}
                        >
                          {qualificationMatch?.playerPartner?.id
                            ? (
                              <a
                                href={`/accounts/${qualificationMatch?.playerPartner.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {isFriend(qualificationMatch?.playerPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {qualificationMatch?.playerPartner
                                  ? getName('playerPartner', qualificationMatch)
                                  : 'BYE'}
                              </a>
                            )
                            : (
                              <>
                                {isFriend(qualificationMatch?.playerPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {qualificationMatch?.playerPartner
                                  ? getName('playerPartner', qualificationMatch)
                                  : 'BYE'}
                              </>
                            )}
                        </Paragraph>
                        {qualificationMatch?.winner === 'player' && type ? (
                          <PlayerBadge winner={isWinner} />
                        ) : qualificationMatch?.winner === 'player' && <PlayerBadge winner={isWinner} />}
                      </div>
                    </div>
                  )}
                </div>

                {/* qualification opponent */}
                <div>
                  <div className="player one">
                    <div className="playerInfo">
                      <Level
                        silver={isWinner && qualificationMatch?.winner === 'player'}
                        gold={isWinner && qualificationMatch?.winner === 'opponent'}
                        level={qualificationMatch?.opponent?.level}
                        small
                      />

                      <Paragraph
                        small
                        className={`
                          name
                          ${isWinner && qualificationMatch?.winner === 'opponent' ? 'goldText' : ''}
                          ${qualificationMatch?.winner === 'opponent' ? 'boldText' : ''}
                        `}
                      >
                        {isFriend(qualificationMatch?.opponent?.userId) && (
                          <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                        )}
                        {qualificationMatch?.opponent ? getName('opponent', qualificationMatch) : 'BYE'}
                      </Paragraph>
                      {qualificationMatch?.winner === 'opponent' && type ? (
                        <PlayerBadge winner={isWinner} />
                      ) : qualificationMatch?.winner === 'opponent' && <PlayerBadge winner={isWinner} />}
                    </div>

                    {qualificationMatch?.opponent?.reason && qualificationMatch?.player?.reason
                      ? reasonOfLeave[qualificationMatch?.player?.reason]
                      : qualificationMatch?.player?.reason === 'NO_SHOW'
                        ? <div className="playerScore"><div className="emptyScore">-</div></div>
                        : (
                          <div className={qualificationMatch?.player?.reason === 'ABANDONED'
                            ? 'playerScore abandonMargin'
                            : 'playerScore'}
                          >
                            {qualOpponent && qualOpponent.map((y, key) => {
                              const winnerScore = qualOpponent[key]?.points > qualPlayerScore?.[key]?.points;
                              return (
                                <div key={key} className="positionRelative">
                                  {/* tie score */}
                                  {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                                  <Paragraph
                                    key={y}
                                    small
                                    className={`
                                      score
                                      ${y?.competition?.finalStage === y?.competition?.stage && y?.winner === 'opponent'
                                      ? 'goldText'
                                      : ''}
                                      ${winnerScore ? 'boldText' : ''}`}
                                  >
                                    {getScore('opponent', qualPlayerScore, qualOpponent, key)}
                                  </Paragraph>
                                </div>
                              );
                            })}
                            {reasonOfLeave[qualificationMatch?.opponent?.reason]}
                          </div>
                        )}
                  </div>
                  {/* qualification opponent partner */}
                  {type && (
                    <div className="player one mt8">
                      <div className="playerInfo">
                        <Level
                          silver={isWinner && qualificationMatch?.winner === 'player'}
                          gold={isWinner && qualificationMatch?.winner === 'opponent'}
                          level={qualificationMatch?.opponentPartner?.level}
                          small
                        />

                        <Paragraph
                          small
                          className={`
                            name
                            ${isWinner && qualificationMatch?.winner === 'opponent' ? 'goldText' : ''} 
                            ${qualificationMatch?.winner === 'opponent' ? 'boldText' : ''}
                          `}
                        >
                          {qualificationMatch?.opponentPartner?.id
                            ? (
                              <a
                                href={`/accounts/${qualificationMatch?.opponentPartner.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {isFriend(qualificationMatch?.opponentPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {qualificationMatch?.opponentPartner
                                  ? getName('opponentPartner', qualificationMatch)
                                  : 'BYE'}
                              </a>
                            )
                            : (
                              <>
                                {isFriend(qualificationMatch?.opponentPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {qualificationMatch?.opponentPartner
                                  ? getName('opponentPartner', qualificationMatch)
                                  : 'BYE'}
                              </>
                            )}
                        </Paragraph>
                        {qualificationMatch?.winner === 'opponent' && type ? (
                          <PlayerBadge winner={isWinner} />
                        ) : qualificationMatch?.winner === 'opponent' && <PlayerBadge winner={isWinner} />}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        );
      })}

      {/* FIRST STAGE OF THE ELIM MAIN DRAW */}
      {!Object.keys(x).length || x?.placeholderMatch ? (
        <div className={`tournament-bracket__match ${isFinalStage && 'darkBracketMatch'}`}>
          <div className="tbaWrapp">
            <img
              src={LoadImage(isFinalStage ? 'icons/whiteTbaIcon.svg' : 'icons/blackTbaIcon.svg')}
              alt={isFinalStage ? 'Final Stage - To be announced' : 'Stage - To be announced'}
            />
            <Paragraph bold>TBA</Paragraph>
          </div>
        </div>
      ) : (
        <div className={`tournament-bracket__match ${isFinalStage && 'darkBracketMatch'}`}>
          {/* COMPLETED MATCHES */}
          {/* <div className="bracketInfo">
            <Paragraph>{`${x?.courtDetails ?? ''} ${dateFormat(new Date(x?.date), 'd mmm, HH:MM')}`}</Paragraph>
          </div> */}
          <div className="playerWrapp">
            <div>
              <div
                className={`
                  player
                  one
                  ${accountInfo?.userId === x?.player?.userId ? (!isFinalStage ? 'you' : 'you__winner') : ''}
                `}
              >
                {x?.competition?.stage === 1 && <Paragraph className="matchNumber" small bold>{matchNumber}</Paragraph>}
                <div className="playerInfo">
                  <Level
                    silver={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'}
                    gold={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'}
                    level={x?.player?.level}
                    small
                  />

                  <Paragraph
                    small
                    className={`
                      ${x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'
                      ? 'goldText' : ''}
                      name
                      ${x?.winner === 'player' ? 'boldText' : ''}
                    `}
                  >
                    {x?.player?.id
                      ? (
                        <a href={`/accounts/${x?.player?.id}`} target="_blank" rel="noreferrer">
                          {isFriend(x?.player?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {!x?.player?.firstName && x?.player?.type !== 'PENDING'
                            ? 'BYE'
                            : x?.player
                              ? (matches?.length
                                && x?.player?.type === 'PENDING'
                                && matches[0]?.player?.reason
                                && matches[0]?.opponent?.reason
                                ? 'BYE'
                                : qualificationPendingPlayer(x?.player, matches)
                                  ? `Q${ matches[0]?.competition?.position}`
                                  : (getName('player', x))
                              )
                              : ''}
                        </a>
                      )
                      : (
                        <>
                          {isFriend(x?.player?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {!x?.player?.firstName && x?.player?.type !== 'PENDING'
                            ? 'BYE'
                            : x?.player
                              ? (
                                matches?.length && x?.player?.type === 'PENDING'
                                && matches[0]?.player?.reason
                                && matches[0]?.opponent?.reason
                                  ? 'BYE'
                                  : qualificationPendingPlayer(x?.player, matches)
                                    ? `Q${ matches[0]?.competition?.position}`
                                    : (getName('player', x))
                              )
                              : ''}
                        </>
                      )}
                  </Paragraph>

                  {x?.winner === 'player' && type ? (
                    <PlayerBadge winner={x?.competition?.finalStage === x?.competition?.stage} />
                  ) : (
                    x?.winner === 'player' && (
                      <PlayerBadge winner={x?.competition?.finalStage === x?.competition?.stage} />
                    )
                  )}
                </div>

                {x?.opponent?.reason && x?.player?.reason
                  ? reasonOfLeave[x?.player?.reason]
                  : (x?.opponent?.reason === 'NO_SHOW'
                    ? <div className="playerScore"><div className="emptyScore">-</div></div>
                    : (
                      <div className={x?.opponent?.reason === 'ABANDONED'
                        ? 'playerScore abandonMargin' : 'playerScore'}
                      >
                        {sortedPlayerScore && sortedPlayerScore.map((y, idx) => {
                          const winnerScore = sortedPlayerScore[idx]?.points > sortedOpponentScore?.[idx]?.points;
                          return (
                            <div key={idx} className="positionRelative">
                              {/* tie score */}
                              {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                              {/* main score */}
                              <Paragraph
                                key={y}
                                small
                                className={`
                                  score
                                  ${y?.competition?.finalStage === y?.competition?.stage && y?.winner === 'player'
                                  ? 'goldText' : ''} 
                                  ${winnerScore ? 'boldText' : ''}`}
                              >
                                {getScore('player', sortedPlayerScore, sortedOpponentScore, idx)}
                              </Paragraph>
                            </div>
                          );
                        })}
                        {reasonOfLeave[x?.player?.reason]}
                      </div>
                    )
                  )}
              </div>
              {/* player partner */}
              {type && (
                <div className="player one">
                  <div className={`playerInfo ${x?.player?.userId === accountInfo?.userId ? 'you' : ''}`}>
                    <Level
                      silver={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'}
                      gold={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'}
                      level={x?.playerPartner?.level}
                      small
                    />

                    {/* <Paragraph
                      small
                     className={`${x?.competition?.finalStage === x?.competition?.stage && x?.winner === "player"
                     ? 'goldText' : ''}  name ${x?.winner === "player" ? 'boldText' : ''}`}
                     >
                     {x?.playerPartner ? x?.playerPartner?.firstName + " " + x?.playerPartner?.lastName : "BYE"}
                     </Paragraph> */}

                    <Paragraph
                      small
                      className={`
                        name
                        ${x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'
                        ? 'goldText' : ''}
                         ${x?.winner === 'player' ? 'boldText' : ''}
                      `}
                    >
                      {x?.playerPartner?.id
                        ? (
                          <a href={`/accounts/${x?.playerPartner.id}`} target="_blank" rel="noreferrer">
                            {isFriend(x?.playerPartner?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {!x?.player?.firstName && x?.player?.type !== 'PENDING'
                              ? 'BYE'
                              : x?.player
                                ? (
                                  matches?.length && x?.player?.type === 'PENDING'
                                  && matches[0]?.player?.reason && matches[0]?.opponent?.reason
                                    ? 'BYE'
                                    : qualificationPendingPlayer(x?.player, matches)
                                      ? `Q${ matches[0]?.competition?.position}`
                                      : (getName('playerPartner', x))
                                ) : ''}
                          </a>
                        )
                        : (
                          <>
                            {isFriend(x?.playerPartner?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {!x?.player?.firstName && x?.player?.type !== 'PENDING'
                              ? 'BYE'
                              : x?.player
                                ? (
                                  matches?.length && x?.player?.type === 'PENDING'
                                  && matches[0]?.player?.reason && matches[0]?.opponent?.reason
                                    ? 'BYE'
                                    : qualificationPendingPlayer(x?.player, matches)
                                      ? `Q${ matches[0]?.competition?.position}`
                                      : (getName('playerPartner', x))
                                ) : ''}
                          </>
                        )}
                    </Paragraph>
                    {((x?.winner === 'player' && type) || x?.winner === 'player') && (
                      <PlayerBadge winner={x?.competition?.finalStage === x?.competition?.stage} />
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* opponent */}
            <div>
              <div className="player one">
                {x?.competition?.stage === 1 && (
                  <Paragraph className="matchNumber" small bold>{matchNumber + 1}</Paragraph>
                )}

                <div className="playerInfo">
                  <Level
                    silver={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'}
                    gold={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'}
                    level={x?.opponent?.level}
                    small
                  />

                  <Paragraph
                    small
                    className={`
                      name
                      ${x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'
                      ? 'goldText' : ''} 
                      ${x?.winner === 'opponent' ? 'boldText' : ''}
                    `}
                  >
                    {x?.opponent?.id
                      ? (
                        <a href={`/accounts/${x?.opponent.id}`} target="_blank" rel="noreferrer">
                          {isFriend(x?.opponent?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {!x?.opponent?.firstName && x?.opponent?.type !== 'PENDING'
                            ? 'BYE'
                            : x?.opponent
                              ? matches?.length && x?.opponent?.type === 'PENDING'
                              && matches[0]?.player?.reason && matches[0]?.opponent?.reason
                                ? 'BYE'
                                : (qualificationPendingPlayer(x?.opponent, matches)
                                  ? `Q${ matches[0]?.competition?.position}`
                                  : (getName('opponent', x))
                                ) : ''}
                        </a>
                      )
                      : (
                        <>
                          {isFriend(x?.opponent?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {!x?.opponent?.firstName && x?.opponent?.type !== 'PENDING'
                            ? 'BYE'
                            : x?.opponent
                              ? matches?.length
                              && x?.opponent?.type === 'PENDING'
                              && matches[0]?.player?.reason
                              && matches[0]?.opponent?.reason
                                ? 'BYE'
                                : (qualificationPendingPlayer(x?.opponent, matches)
                                  ? `Q${ matches[0]?.competition?.position}`
                                  : (getName('opponent', x))
                                ) : ''}
                        </>
                      )}
                  </Paragraph>
                  {((x?.winner === 'opponent' && type) || x?.winner === 'opponent') && (
                    <PlayerBadge winner={x?.competition?.finalStage === x?.competition?.stage} />
                  )}
                </div>

                {x?.opponent?.reason && x?.player?.reason
                  ? matches?.length && x?.opponent?.type === 'PENDING'
                    && matches[0]?.player?.reason && matches[0]?.opponent?.reason
                    ? 'BYE'
                    : reasonOfLeave[x?.player?.reason] : x?.player?.reason === 'NO_SHOW'
                    ? <div className="playerScore"><div className="emptyScore">-</div></div>
                    : (
                      <div className={x?.player?.reason === 'ABANDONED' ? 'playerScore abandonMargin' : 'playerScore'}>
                        {sortedOpponentScore && sortedOpponentScore.map((y, idx) => {
                          const winnerScore = sortedOpponentScore[idx]?.points > sortedPlayerScore?.[idx]?.points;
                          return (
                            <div key={idx} className="positionRelative">
                              {/* tie score */}
                              {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                              <Paragraph
                                key={y}
                                small
                                className={`
                                  score 
                                  ${y?.competition?.finalStage === y?.competition?.stage && y?.winner === 'opponent'
                                  ? 'goldText' : ''}
                                  ${winnerScore ? 'boldText' : ''}
                                `}
                              >
                                {getScore('opponent', sortedPlayerScore, sortedOpponentScore, idx)}
                              </Paragraph>
                            </div>
                          );
                        })}
                        {reasonOfLeave[x?.opponent?.reason]}
                      </div>
                    )}
              </div>

              {/* opponent partner */}
              {type && (
                <div className="player one">
                  <div className="playerInfo">
                    <Level
                      silver={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'player'}
                      gold={x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'}
                      level={x?.opponentPartner?.level}
                      small
                    />

                    <Paragraph
                      small
                      className={`
                        name 
                        ${x?.competition?.finalStage === x?.competition?.stage && x?.winner === 'opponent'
                        ? 'goldText' : ''} 
                        ${x?.winner === 'opponent' ? 'boldText' : ''}
                      `}
                    >
                      {x?.opponentPartner?.id
                        ? (
                          <a href={`/accounts/${x?.opponentPartner?.id}`} target="_blank" rel="noreferrer">
                            {isFriend(x?.opponentPartner?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {!x?.opponent?.firstName && x?.opponent?.type !== 'PENDING'
                              ? 'BYE'
                              : x?.opponent
                                ? (qualificationPendingPlayer(x?.opponent, matches)
                                  ? `Q${ matches[0]?.competition?.position}`
                                  : (getName('opponentPartner', x))
                                ) : ''}
                          </a>
                        )
                        : (
                          <>
                            {isFriend(x?.opponentPartner?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {!x?.opponent?.firstName && x?.opponent?.type !== 'PENDING'
                              ? 'BYE'
                              : x?.opponent
                                ? (qualificationPendingPlayer(x?.opponent, matches)
                                  ? `Q${ matches[0]?.competition?.position}`
                                  : (getName('opponentPartner', x))
                                ) : ''}
                          </>
                        )}
                    </Paragraph>

                    {((x?.winner === 'opponent' && type) || x?.winner === 'opponent') && (
                      <PlayerBadge winner={x?.competition?.finalStage === x?.competition?.stage} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Match;
