import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Paragraph } from '../../../../components';
import Checkmark from '../../../../assets/images/checkmark-white.svg';

import Button from '../../../../components/Form/Button';
import Select from '../../../../components/Form/Select';
import { updateAccount } from './actions';

import { Container, Group, CustomRadio } from './styles';
import { Badge } from '../../../../components/LevelsBadges/styles';
import { H3 } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

const ProfileDetails = ({ accountInfo }) => {
  const { t } = useTranslation(['settings', 'dashboard', 'common']);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { activeSports } = useSelector(state => state.settings?.data);

  const tShirtSizes = [
    { value: 'NONE', label: t('profileSettings.profileDetails.shirtNone') },
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: 'XXL', label: 'XXL' },
  ];

  return (
    <Container>
      <H3 margin="0 0 20px 0">
        <Trans ns="settings" i18nKey="profileSettings.profileDetails.title">
          Profile Details
        </Trans>
      </H3>

      <Paragraph large bold className="mb20">
        <Trans ns="settings" i18nKey="profileSettings.profileDetails.generalDetails">
          General Details
        </Trans>
      </Paragraph>

      <Formik
        initialValues={{
          dominantHand: accountInfo?.dominantHand,
          tShirtSize: accountInfo?.tShirtSize?.toUpperCase() ?? 'NONE',
          favoriteSport: accountInfo?.favoriteSport,
        }}
        enableReinitialize
        validationOnChange
        onSubmit={async (values, { setSubmitting }) => {
          const payload = {
            dominantHand: values.dominantHand,
            tShirtSize: values.tShirtSize.value,
            favoriteSport: values.favoriteSport.value,
          };

          await updateAccount({
            id: accountInfo.id,
            payload,
            addToast,
            setSubmitting,
            dispatch,
            t,
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="mb20">
              <Paragraph>
                {t('profileSettings.profileDetails.dominantHand')}
              </Paragraph>
              <CustomRadio role="group" aria-labelledby="dominantHand">

                <Field
                  type="radio"
                  id="leftHand"
                  name="dominantHand"
                  value="left"
                  {...(values.dominantHand === 'left' && { checked: true })}
                />

                <label htmlFor="leftHand">
                  <img src={Checkmark} alt="" />
                  {t('profileSettings.profileDetails.left')}
                </label>

                <Field
                  type="radio"
                  id="rightHand"
                  name="dominantHand"
                  value="right"
                  {...(values.dominantHand === 'right' && { checked: true })}
                />

                <label htmlFor="rightHand">
                  <img src={Checkmark} alt="" />
                  {t('profileSettings.profileDetails.right')}
                </label>
              </CustomRadio>
            </div>

            <Select
              name="tShirtSize"
              placeholder={t('profileSettings.profileDetails.shirtSize')}
              labelOn
              defaultValue={tShirtSizes.filter(obj => obj.value === values.tShirtSize)}
              lowInput
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={tShirtSizes}
              label={t('profileSettings.profileDetails.shirtSize')}
              {...{ errors, touched }}
            />

            <Select
              name="favoriteSport"
              placeholder={t('completeProfile.favoriteSport', { ns: 'dashboard' })}
              labelOn
              defaultValue={activeSports.filter(sport => sport === values.favoriteSport).map((sport) => ({
                label: t(`sports.${sport}`, { ns: 'common' }),
                value: sport,
              }))}
              lowInput
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={activeSports.map((sport) => ({
                label: t(`sports.${sport}`, { ns: 'common' }),
                value: sport,
              }))}
              label={t('completeProfile.favoriteSport', { ns: 'dashboard' })}
              {...{ errors, touched }}
            />

            <Button wide type="submit" disabled={isSubmitting || !(isValid && dirty)}>
              {t('saveChanges', { ns: 'common' })}
            </Button>
          </Form>
        )}
      </Formik>

      <Paragraph large bold className="mt40 mb20">
        <Trans ns="settings" i18nKey="profileSettings.profileDetails.gameLevels">
          Game Levels
        </Trans>
      </Paragraph>

      <Paragraph small className="mb20">
        <Trans ns="settings" i18nKey="profileSettings.profileDetails.needOfChange">
          If you need to change the level please get in touch at
          <a href="mailto:contact@sportya.net">contact@sportya.net</a>
          .
        </Trans>
      </Paragraph>

      {activeSports.map((sport) => {
        const gameLevelSet = (
          accountInfo?.sports[sport]?.singles?.gameLevel
          && accountInfo?.sports[sport]?.doubles?.gameLevel
        );
        return (
          <Group>
            <Paragraph
              large
              bold
              className="d-flex align-items-center gap-10 justify-content-center mb20"
            >
              <img src={LoadImage(`sports/${sport}.svg`)} alt={sport} />
              <Trans ns="common" i18nKey={`sports.${sport}`}>
                {sport}
              </Trans>
            </Paragraph>
            <div className="wrap-content">
              <div className="d-flex flex-column align-items-center">
                <Paragraph small>
                  <Trans ns="settings" i18nKey="profileSettings.profileDetails.singleGameLevel">
                    Singles Game Level
                  </Trans>
                </Paragraph>
                <Badge large>
                  <H3>{accountInfo?.sports[sport]?.singles?.gameLevel || "-"}</H3>
                </Badge>
              </div>

              <div className="d-flex flex-column align-items-center">
                <Paragraph small>
                  <Trans ns="settings" i18nKey="profileSettings.profileDetails.doublesGameLevel">
                    Doubles Game Level
                  </Trans>
                </Paragraph>
                <Badge large double>
                  <H3>{accountInfo?.sports[sport]?.doubles?.gameLevel || "-"}</H3>
                </Badge>
              </div>
            </div>

            {!gameLevelSet && (
              <Link to={`/my-account/game-level?sportType=${sport}`}>
                <Button outline fullwidth small>Set Your Game Level</Button>
              </Link>
            )}
          </Group>
        );
      })}
    </Container>
  );
};

export default ProfileDetails;
