import React from 'react';
import styled from '@emotion/styled';
import { uniqueId } from 'lodash';

const ID = uniqueId('radio-');

const Checkbox = styled.input({
  position: 'absolute',
  height: '100%',
  width: '100%',
  opacity: 0,
  cursor: 'pointer',
  zIndex: 2,
});

const Checkmark = styled.span(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '23px',
  width: '23px',
  background: '#fff',
  borderRadius: '50%',
  border: `2px solid ${props.theme.colors.black}`,
  zIndex: 1,
  '&:after': {
    display: 'none',
    content: '""',
    position: 'absolute',
  },
}));

const Label = styled.label({
  margin: '0 0 0 30px',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  cursor: 'pointer',
});

const Container = styled.div({
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  margin: '0 0 20px 0',
  '&:hover': {
    span: {
      '&:after': {
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        background: '#000',
        display: 'block',
      },
    },
  },
  input: {
    '&:checked': {
      '& ~ span:after': {
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        background: '#000',
        display: 'block',
      },
    },
  },
});

const Radio = ({
  label, name, value, checked, onChange, hideCheckmark, ...props
}) => (
  <Container>
    <Checkbox
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      id={ID}
      {...props}
    />
    <Label htmlFor={ID}>{label}</Label>
    {!hideCheckmark && <Checkmark />}
  </Container>
);

export default Radio;
