export default {
  friendsSeoTitle: 'Přátelé | Sportya',
  title: 'Přátelé',
  tabs: {
    allFriends: 'Všichni přátelé ({{friendsCount}})',
    friendRequests: 'Žádost o přátelství ({{friendsCount}})',
    sentRequests: 'Odeslané požadavky ({{friendsCount}})',
  },
  unfriendText: 'Smazat přítele {{name}}',
  unfriend: 'Smazat přítele',
  unfriendConfirmationText: 'Jsi si jistý, že se s {{name}} nekamarádíš?',
  noRequestsSent: 'Neobdrželi jste žádné nové žádosti o přátelství',
  noRequestsReceived: 'Neposlali jste žádné nové žádosti o přátelství',
  wantToAddFirstFriend: 'Chcete přidat svého prvního přítele na Sportya?',
  confirm: 'Akceptovat',
  decline: 'Odmítnout',
  cancelRequest: 'Zrušit žádost',
};
