import { isEmpty } from 'lodash';

const images = require.context('../../assets/images', true);
const cdnPath = `${process.env.REACT_APP_API_HOST}assets`;

const LoadImage = (imageName, cdn = false, size = 'featured', category = '', sportType = '') => {
  if (cdn) {
    if (isEmpty(imageName)) {
      try {
        if (category && sportType) {
          return (images(`./placeholders/${sportType}-${category}-${size}.png`).default);
        }
        return (images(`./placeholders/placeholder-image-${size}.svg`).default);
      } catch (e) {
        return null;
      }
    }

    return `${cdnPath}/${imageName}`;
  }

  try {
    return (images(`./${imageName}`)?.default);
  } catch (e) {
    return null;
  }
};

export default LoadImage;
