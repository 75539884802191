import React from 'react';
import { Trans } from 'react-i18next';

import { TableStyle } from './styles';
import Paragraph from '../../../../components/Paragraph';
// import LoadImage from '../../../../components/common/LoadImage'

const PlayerTableCard = ({ children, isFriendsTable }) => (
  <TableStyle className="mb30">
    {!isFriendsTable && (
      <div className="headerCard">
        <div className="rank">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.rank">Rank</Trans></Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="player">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.player">Player</Trans></Paragraph>
        </div>

        <div className="points">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.points">Points</Trans></Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="participations">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.participations">Participations</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="friendlyMatches">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.rankedFriendlyMatches">Ranked Friendly Matches</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>
      </div>
    )}

    {isFriendsTable && (
      <div className="headerCardFriend">
        <div className="rankFriend">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.rank">Rank</Trans></Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="playerFriend">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.player">Player</Trans></Paragraph>
        </div>

        <div className="generalRankFriend">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.generalRank">General Rank</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="pointsFriend">
          <Paragraph smaller><Trans ns="leaderboards" i18nKey="playerTableCard.points">Points</Trans></Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="participationsFriend">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.participations">Participations</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="friendlyMatchesFriend">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.rankedMatches">Ranked Matches</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="friendlyMatchesFriend">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.unrankedMatches">Unranked Matches</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>

        <div className="friendlyMatchesFriend">
          <Paragraph smaller>
            <Trans ns="leaderboards" i18nKey="playerTableCard.totalMatches">Total Matches</Trans>
          </Paragraph>
          {/* <img src={LoadImage("down-arrow.svg")} /> */}
        </div>
      </div>
    )}

    {children}
  </TableStyle>
);

export default PlayerTableCard;
