/** @jsxImportSource @emotion/react */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

import { store as socketStore } from '../../../components/Socket/store';
import Input from '../../../components/Form/Input';

import {
  Button,
  P,
  H2,
  Link,
} from '../../../components/Collection';

import SelectLang from '../../../components/Layout/components/LangSelect';

import {
  Container,
  Header,
  Logo,
  FormContainer,
  Country,
  Flag,
  ReferralBanner,
  Icon,
  LanguageSelectorPosition,
} from './styles';

import { login } from '../Login/actions';
import LoadImage from '../../../components/common/LoadImage';
import Select from '../../../components/Form/Select';
import SelectGoogleAutocomplete from '../../../components/Form/SelectGoogleAutocomplete';
import SEO from '../../../components/SEO';
import { getReferredAccount, registerHandler } from './actions';
import {
  H3,
  Modal,
  Paragraph,
} from '../../../components';
import mixpanel from '../../../mixpanel';

let oldValues;

const Register = () => {
  const { referralCode } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('register');
  const history = useHistory();
  const globalState = useContext(socketStore);
  const [country, setCountry] = useState([]);
  const [userLocation, setUserLocation] = useState();
  const [referredAccount, setReferredAccount] = useState();
  const [getRewardedModal, setGetRewardedModal] = useState(false);
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [forcedError, setForcedError] = useState(false);
  const { eligibleCountries } = useSelector(state => state.settings.data);
  const { isLoggedIn } = useSelector(state => state.session);
  const formRef = useRef();
  const tosRef = useRef(null);

  const { state: { socket: { socketClientId, socket } = {} } = {} } = globalState;

  const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  const onlyLatinAlphabet = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;

  const registerSchema = Yup.object().shape({
    email: Yup.string().email(commonT('invalidEmail')).required(commonT('requiredField')),
    password: Yup.string()
      .required(commonT('requiredField'))
      .min(8, commonT('minChars', { chars: 8 }))
      .max(30, commonT('maxChars', { chars: 30 }))
      .matches(/[^A-Za-z 0-9]/g, commonT('mustContainerSpecialChars')),
    phone: Yup.string().matches(phoneRegExp, commonT('nrNotValid')).required(commonT('requiredField')),
    firstName: Yup.string().required(commonT('requiredField'))
      .matches(onlyLatinAlphabet, commonT('onlyLatin')),
    lastName: Yup.string().required(commonT('requiredField'))
      .matches(onlyLatinAlphabet, commonT('onlyLatin')),
    tosAgree: Yup.boolean().required(commonT('requiredField')),
    referralCode: Yup.string().nullable(),
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn]);

  const handleRegisterWithSSO = () => {
    if (!acceptedTOS) {
      setForcedError(true);

      if (tosRef.current) {
        tosRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        return addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
          maxOpened: 1,
        });
      }
      const { password, email } = oldValues;
      await login({
        values: {
          password,
          email,
        },
        history,
        t: commonT,
        addToast,
        dispatch,
        isSignUp: true,
      });
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  useEffect(() => {
    mixpanel.track('Visit sign up');
  }, []);

  useEffect(() => {
    const init = async () => {
      const data = await getReferredAccount({
        referralCode,
        commonT,
        addToast,
      });
      if (data) {
        localStorage.setItem('referralCode', referralCode);
        setReferredAccount(data);
        addToast(commonT('REFERRAL_CODE_APPLIED_SUCCESSFULLY'), {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        formRef.current.setFieldValue('referralCode', '');
        setReferredAccount(null);
        localStorage.removeItem('referralCode');
      }
    };
    if (referralCode && referralCode.length === 11) {
      init();
    }
  }, [referralCode]);

  const clickedLogin = () => {
    mixpanel.track('User clicked login from the sign up page');
  };

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(value => ({
    value: (value.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${value.key}.svg`)} />
        <P small bold>{commonT(`countriesByKey.${value.key}`)}</P>
      </Country>
    ),
  }));

  const handleReferralCode = debounce((e) => {
    const { value } = e?.target;
    if (value.length === 11) {
      history.replace({ pathname: `/join/${value}` });
    } else {
      formRef.current.setFieldValue('referralCode', '');
      setReferredAccount(null);
    }
  }, 500);

  return (
    <Container>
      {SEO({
        title: t('seoTitle'),
        facebook: {
          title: 'Join me on Sportya and get rewarded!',
          type: 'website',
          url: process.env.REACT_APP_WEB_URL,
          description: `Join me on Sportya, the competitive sports platform for amateur tennis players! 
          We can connect with other players, play friendly matches together, or join tournaments and compete 
          for the top spots in national and international rankings!
          Sign up using my unique invitation link and get rewarded:
          ${window.location.href}`,
          image: LoadImage('invite-friends/og.png'),
        },
        twitter: {
          title: t('ogTitle'),
          card: process.env.REACT_APP_WEB_URL + LoadImage('invite-friends/og.png'),
          url: process.env.REACT_APP_WEB_URL,
          description: t('ogDescription', { link: window.location.href }),
          image: LoadImage('invite-friends/og.png'),
        },
      })}
      <Header>
        <LanguageSelectorPosition>
          <SelectLang />
        </LanguageSelectorPosition>
        <Link to="/">
          <Logo src={LoadImage('logo.svg')} />
        </Link>
        <Button
          outline
          small
          height="40px"
          padding="0 40px"
          className="clubManager"
        >
          <Link to="/for-clubs">
            <Trans ns="common" i18nKey="imClubManager">
              I&aposm a Club Manager
            </Trans>
          </Link>
        </Button>
      </Header>

      <FormContainer>
        <H2>
          <Trans ns="register" i18nKey="title">Sign Up</Trans>
        </H2>

        <P textAlign="center">
          <Trans ns="register" i18nKey="subTitle">
            Sportya is available to players in
            <strong>
              Romania, Republic of Moldova, Bulgaria, Bahrain,
              Hungary, and Czechia
            </strong>
            .
          </Trans>
        </P>

        <P textAlign="center" margin="20px 0 0 0">
          <Trans ns="register" i18nKey="countriesComingSoon">New countries coming soon!</Trans>
        </P>

        <Formik
          innerRef={formRef}
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            phone: '',
            lastName: '',
            location: userLocation,
            communicationPreferences: {
              newsletter: false,
            },
            tosAgree: false,
            referralCode,
          }}
          validationSchema={registerSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const parsedValues = {
              ...values,
              location: userLocation,
              mixpanelId: mixpanel.get_distinct_id(),
            };
            oldValues = parsedValues;

            await registerHandler({
              values: parsedValues,
              addToast,
              setSubmitting,
              socketClientId,
              commonT,
            });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            isSubmitting,
            values,
            isValid,
            dirty,
          }) => (
            <Form className="mt40">
              <Input
                name="firstName"
                type="text"
                placeholder={commonT('firstName')}
                {...{ errors, touched }}
                value={values.firstName}
              />
              <Input
                name="lastName"
                type="text"
                placeholder={commonT('lastName')}
                {...{ errors, touched }}
                value={values.lastName}
              />
              <Input
                name="email"
                placeholder={commonT('email')}
                type="email"
                {...{ errors, touched }}
                value={values.email}
              />
              <Input
                name="password"
                type="password"
                placeholder={commonT('password')}
                {...{ errors, touched }}
                value={values.password}
              />

              <Input
                name="phone"
                type="tel"
                placeholder={commonT('phoneNumber')}
                {...{ errors, touched }}
                value={values.phone}
              />

              <Select
                name="country"
                placeholder={commonT('country')}
                label={commonT('country')}
                options={countries}
                isSearchable={false}
                onChange={setCountry}
                height="50px"
                higher
                lowInput
                inputLabel
                {...!isEmpty(country) && { labelOn: true }}
                {...{ errors, touched }}
              />

              {country?.value && (
                <SelectGoogleAutocomplete
                  name="city"
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: [country?.value || country],
                    },
                  }}
                  minLengthAutocomplete={3}
                  hook={(e) => setUserLocation(e?.value)}
                  placeholder={commonT('city')}
                  label={commonT('city')}
                  isClearable
                  {...userLocation && {
                    value: { label: userLocation?.city, value: userLocation },
                  }}
                  {...!isEmpty(userLocation) && { lowInput: true }}
                  {...!isEmpty(userLocation) && { labelOn: true }}
                  {...{ errors, touched }}
                />
              )}

              <div>
                <Input
                  name="referralCode"
                  type="string"
                  placeholder={commonT('referralCode')}
                  {...{ errors, touched }}
                  value={values.referralCode}
                  onChange={(e) => {
                    handleChange(e);
                    handleReferralCode(e);
                  }}
                />

                {referredAccount && (
                  <ReferralBanner onClick={() => {
                    setGetRewardedModal(!getRewardedModal);
                    mixpanel.track('Find out more about MGM (click on Find out more on Signup)');
                  }}
                  >
                    <Icon src={LoadImage('invite-friends/party-popper.svg')} alt="Yeeey!" />
                    <div>
                      <Paragraph smaller>
                        <Trans ns="accounts" i18nKey="referral.invitedBy">
                          You&apos;ve been invited by
                          <strong>{{ referee: `${referredAccount?.firstName} ${referredAccount?.lastName}` }}</strong>.
                        </Trans>
                      </Paragraph>
                      <Paragraph smaller>
                        <Trans ns="accounts" i18nKey="referral.joinSportya">
                          Join Sportya and get rewarded! <strong className="text-underline">Find out more</strong>.
                        </Trans>
                      </Paragraph>
                    </div>
                  </ReferralBanner>
                )}
              </div>

              <Input
                name="communicationPreferences[newsletter]"
                onChange={handleChange}
                type="checkbox"
                // label={t('step2.promotions', { siteName: process.env.REACT_APP_SITE_NAME })}
                label={(
                  <Trans ns="register" i18nKey="newsletterSubscribe">
                    I subscribe to {process.env.REACT_APP_SITE_NAME} &apos;s newsletter
                  </Trans>
                )}
                id="subscribeToNewsletter"
              />

              <Input
                ref={tosRef}
                name="tosAgree"
                type="checkbox"
                onClick={() => setAcceptedTOS(!acceptedTOS)}
                forcedError={forcedError}
                label={(
                  <Trans ns="register" i18nKey="tosAgree">
                    I agree to {process.env.REACT_APP_SITE_NAME} &apos;s&nbsp;
                    <Link to="/terms-conditions" className="display-inline-block" target="_blank">
                      Terms and Conditions
                    </Link>
                    and
                    <Link to="/confidentiality" className="display-inline-block" target="_blank">
                      Privacy Policy
                    </Link>
                    , which includes my consent to be contacted with regard to the use of
                    {process.env.REACT_APP_SITE_NAME}
                    services including but not limited to events registration and
                    participation, management of friendly matches, and any other internal communication.
                  </Trans>
                )}
                id="tosAgree"
                required
              />

              <Button
                width="100%"
                type="submit"
                disabled={isSubmitting || !(isValid && dirty) || !country?.value || !userLocation}
              >
                <Trans ns="register" i18nKey="createAccount">
                  Create Account
                </Trans>
              </Button>
            </Form>
          )}
        </Formik>

        <P xSmall margin="40px 0 20px">
          <Trans ns="register" i18nKey="signupWith">
            Or sign up with
          </Trans>
        </P>

        <Button outline width="100%" onClick={() => handleRegisterWithSSO()}>
          <Link {...acceptedTOS && { to: `${process.env.REACT_APP_API_HOST}accounts/auth/google` }} external>
            <img height="20" src={LoadImage('google-icon.svg')} alt="Google register" />
          </Link>
        </Button>

        <Button outline width="100%" margin="10px 0 0 0" onClick={() => handleRegisterWithSSO()}>
          <Link {...acceptedTOS && { to: `${process.env.REACT_APP_API_HOST}accounts/auth/facebook` }} external>
            <img height="23" src={LoadImage('facebook-letter.svg')} alt="Facebook register" />
          </Link>
        </Button>

        {forcedError && (
          <P small textAlign="center" color="error">
            {/* <Trans ns="register" i18nKey="ageAgree"> */}
            You must agree in order to continue
            {/* </Trans> */}
          </P>
        )}

        <P xSmall textAlign="center" margin="10px 0 40px 0">
          <Trans ns="register" i18nKey="ageAgree">
            By registering, I confirm I am at least <strong>15 years old</strong>
          </Trans>
        </P>

        <P small textAlign="center" margin="20px 0 10px 0">
          <Trans ns="register" i18nKey="alreadyHaveAnAccount">
            Already have an account?
          </Trans>
        </P>

        <Button outline minWidth="120px" onClick={() => clickedLogin()}>
          <Link to="/login">
            <Trans ns="header" i18nKey="login">Log In</Trans>
          </Link>
        </Button>
      </FormContainer>
      <Button
        outline
        small
        height="40px"
        padding="0 40px"
        className="clubManagerMobile"
      >
        <Link to="/for-clubs">
          <Trans ns="common" i18nKey="imClubManager">
            I am a Club Manager
          </Trans>
        </Link>
      </Button>

      {getRewardedModal && (
        <Modal
          isOpen={getRewardedModal}
          onRequestClose={() => setGetRewardedModal(false)}
        >
          <div className="modal-header">
            <H3 large bold>
              <Trans ns="accounts" i18nKey="referral.title2">Join Sportya and Get Rewarded!</Trans>
            </H3>
          </div>
          <div className="modal-content">
            <Paragraph large>
              <Trans ns="accounts" i18nKey="referral.subtitle2">
                Get rewarded as soon as you complete ONE of the following actions for the 1st time:
              </Trans>
            </Paragraph>
            <ul className="actions">
              <li>
                <img src={LoadImage('mgm/item1.png')} alt="" />
                <Trans ns="accounts" i18nKey="referral.item11">
                  Get your First Validated Result in a Sportya tournament
                </Trans>
              </li>
              <li>
                <img src={LoadImage('mgm/item2.png')} alt="" />
                <Trans ns="accounts" i18nKey="referral.item22">
                  Top-Up your Virtual Wallet
                </Trans>
              </li>
              <li>
                <img src={LoadImage('mgm/item3.png')} alt="" />
                <Trans ns="accounts" i18nKey="referral.item3">
                  Purchase a Premium account with Virtual Wallet or Credit Card
                </Trans>
              </li>
              <li>
                <img src={LoadImage('mgm/item4.png')} alt="" />
                <Trans ns="accounts" i18nKey="referral.item4">
                  Participate in a Friendly Match with a validated score for the 1st time.
                </Trans>
              </li>
            </ul>
          </div>
          <div className="modal-footer flex-column justify-content-center">
            <Button onClick={() => setGetRewardedModal(false)} modalButton>
              <span><Trans ns="friendlyMatches" i18nKey="gotIt">Got it</Trans></span>
            </Button>
          </div>
        </Modal>
      )}

    </Container>
  );
};

export default Register;
