/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import { Container } from './styles';

const ReturnPolicy = () => {
  const { t } = useTranslation('rules');

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-12">
          <H1 className="mb30">
            <Trans ns="rules" i18nKey="returnPolicy.mainTitle">
              RETURN POLICY
            </Trans>
          </H1>
          <P large className="mb40">
            <Trans ns="rules" i18nKey="returnPolicy.mainDescription">
              1. On the Sportya.net website you can pay the membership fee by purchasing one of the available accounts (ex: Premium Account), you can sign up for a tennis tournament by paying an online fee which represents the value of the digital services offered by the site in connection with the event (Sportya Services Fee), you can top up your Virtual Wallet with an amount that you can use later on Sportya.net and you can buy products during the online entry process for an event, as part of the participation kit that you will receive during the registration process, at the tennis club / sports base where the event takes place.
              2. If after the purchase on Sportya.net of one of the services / products mentioned in art. (1) you want to cancel your purchase and implicitly, to get a refund, you can apply for the standard return procedure of products within 14 days, according to the provisions of “Emergency Ordinance no. 34/2014” (hereinafter referred to as “Ordinance”).

              According to the Ordinance, a distance contract means the contract for the supply of goods or services concluded between a trader and a consumer, within a sales system organized by the trader, which uses exclusively, before and at the conclusion of this contract, one or more distance communication techniques.
              3. As a consumer who has purchased services and / or products online from the Sportya.net website, you have the right to unilaterally withdraw from the contract without invoking any reason, within 14 days from the date of entering into the contract, respectively making the purchase of services / products, by sending a written request, to the e-mail address corporate@sportya.net, under the following conditions:

              a. Member Account / Membership. In case of purchasing a membership account (ex: Premium Account), if during the 14 days:

              i. you have not used the online entry for an event and / or have not participated in any event in the Sportya calendar in which you benefit from the conditions offered by the Account (reduced fee and / or access to Platinum Tournaments, Champions Tournaments, Masters Tournaments, International Tournaments and Special Tournaments), the amount received as payment will be refunded in full and your Member Account will be changed to a Free Account. All benefits offered by the Account for which you requested the refund are withdrawn, including the right to participate in events conditioned by the existence of a specific active Account, even if the entries were made before requesting the cancelation of the Account, respectively the refund of the amount paid for the Account;

              ii. you have made a reservation for an event and / or participated in an event in the Sportya calendar where you have benefited from the conditions offered by the Account (reduced fee and / or access to Platinum Tournaments, Champions Tournaments, Masters Tournaments, International Tournaments and Special Tournaments), the amount paid is not refunded and your Member Account / Membership remains unchanged.

              b. Sportya Services Fee. In case of payment of the online fee which represents the value of the digital services offered by the site in connection with the event (Sportya Services Fee) during the online registration process for an event, the services are fully provided at the time of payment and the amount received as payment cannot be refunded. However, if you have used the online withdrawal feature for an event and the withdrawal is validated by the withdrawal system, the Sportya Services Fee is transferred to your personal Virtual Wallet and can be used to enter other Sportya events.

              c. Virtual Wallet Top Up. In case of topping up the Virtual Wallet, if during the 14 days after topping up:

              i. you have not used the amount from your Virtual Wallet, respectively you have not made an entry for a Sportya event, the amount received as payment is refunded in full;

              ii. you used the amount from your Virtual Wallet, partially or totally, respectively you made an entry for a Sportya event, the amount received as payment cannot be refunded.

              d. Specific products. If during the 14 days elapsed from the moment you purchased specific products offered during the online entry for an event, respectively as part of your participation kit:

              i. in case the entries for the event have not yet closed - if you use the online withdrawal function and the withdrawal is validated by the withdrawal system, the amount corresponding to the purchased products is withheld, in order to process the order, the procedure for preparing it for expedition being already initiated. However, the amount can be either transferred to the personal Virtual Wallet or fully refunded, if you make a formal request to withdraw from the contract;

              ii. in case the event entries have closed and the participation kits have been prepared for shipment to the organizer - the amount related to the payment of the products is refunded in full only after the products have been returned to Sportya and the return has been processed and validated. The costs associated with the return of the products by courier service are borne by you, as a buyer (fixed fee of 15 RON for standard deliveries). The return period of the purchased products expires in this case within 14 days from the moment you took possession of these products, delivered to the tennis club organizing the event / competition for which you registered. The following general conditions regarding the condition of the returned products apply:

              1. Returned products must be in the same condition in which they were shipped. The return will be made with all the original labels of the products intact, together with any accompanying documents with which the products were received, including, if applicable, the original guarantee certificate. If the product has been accompanied by accessories or gifts, they must also be returned, provided they have been shipped.

              2. Unsealed can of balls (the metal seal has been removed and the box has been depressurized) cannot be returned.

              3. If you have bought several products of the same kind and want to return them all, make sure that only one of them has been unsealed. All other products must be returned sealed for the return to be accepted.

              4. You can return the consumables (grips, bandanas / cuffs, etc.) only if they are sealed and have no traces of use, respectively the packaging is in the same condition in which it was shipped.

              5. Products that have been modified, those that show signs of wear / use, bumps, shocks, scratches, or products that lack accessories are not accepted. In the case of clothing or footwear products, including fashion accessories, they must be returned in the same condition in which they were delivered, not worn, without stains or signs of wear.

              6. The return of nutrition products (ex: sports drinks, energy bars, etc.), cosmetics or personal care products is not accepted if they have been unsealed or tested. If a package of several products has been purchased, all products must be returned.

              7. If the return is requested for a product accompanied by a service (ex: activation of the Premium Account following the purchase of a product), validation of the return implies the cancellation of the service and all rights or benefits resulting from the use of the service.

              8. The products for which the return is requested must be delivered to the courier exactly as communicated in the return request message, together with the Return Form completed and signed by you. If the returned package sent by you contains different products than those mentioned in the return request message, respectively those that appear indicated in the Return Form, validation for the return will not take place, respectively the return will be refused.

              e. In any case, the withdrawal from the contract requires that you send us a request by e-mail, at corporate@sportya.net, in which you express your decision to withdraw from the contract, respectively giving up the services and / or products purchased on Sportya.net. We will send you a confirmation of receipt by e-mail and, in case you apply for the return of physical products, a Return Form including the list of products indicated in your message. The Return Form must be completed by you with the missing information, signed and then sent to Sportya together with the returned products. The return will be checked based on this Form. The return will be refused in case the Return Form signed by you is missing from the returned package.

              f. The right of unilateral withdrawal from the contract does not apply in the case of legal persons.

              4. Due to the particularities of the Sportya system, the purchase of a Member Account / Membership (ex: Premium Account), the payment of a Sportya Services Fee and the top up of the Virtual Wallet on Sportya.net is a direct confirmation by the consumer that he agrees the delivery of services to begin in the 14 days of withdrawal from the contract and acknowledges that the right of withdrawal will be lost after the full execution of the contract, respectively the delivery of purchased services.

              5. Due to the particularities of the Sportya system, regarding the option offered to participants to add products to their participation kits, due to be received during the registration process, at the tennis club / sports base where the event takes place, the ordered products will be returned by the competition organizer to Sportya, if the player did not show up for the event, respectively did not take possession of the participation kit and implicitly of the purchased products. Subsequently, it remains the responsibility of the buyer / player to contact Sportya and establish another method of direct shipping of purchased products, and will bear both the courier cost of the return made by the organizer of the competition as well as the courier cost for the new delivery. If the buyer requests to cancel the products and withdraw from the contract, Sportya is entitled to deduct from the price of the products the return cost incurred by the competition organizer and only refund the difference.

              6. If the return has been accepted in accordance with all the conditions described in this document, the amount to which you are entitled will be refunded no later than 14 days from the date the return was validated (the products have been returned and the return has been processed), in accordance with the provisions of the law.

              The amount will be refunded using the same payment methods as those used for the initial purchase, unless you have agreed to another payment method and provided that you do not incur the payment of commissions for the reimbursement.

              For any details or questions regarding the return of the products, please contact us by email at corporate@sportya.net or by phone, using one of the numbers displayed on Sportya.net.
            </Trans>
          </P>
        </div>
      </div>
    </Container>
  );
};

export default ReturnPolicy;
