import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

const outter = axios.create({});

instance.defaults.withCredentials = true;
instance.defaults.credentials = 'include';

const request = instance;

const errorHandler = (error) => Promise.reject(error);

export const get = (url, responseDetails) => request.get(url, responseDetails).then(undefined, errorHandler);

export const post = (url, data, config) => request.post(url, data, config).then(undefined, errorHandler);

export const put = (url, data, config) => request.put(url, data, config).then(undefined, errorHandler);

export const patch = (url, data, config) => request.patch(url, data, config).then(undefined, errorHandler);

export const remove = (url, config) => request.delete(url, config).then(undefined, errorHandler);

export const external = {
  get: (url, responseDetails) => outter.get(url, responseDetails).then(undefined, errorHandler),
};
