import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import { isEmpty } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { Container } from "./styles";

import { cancelBooking, getBookingInfo } from "./actions";

import { H2, H3, Paragraph } from "../../../../components";
import UserAvatar from "../../../../components/UserAvatar";
import Button from "../../../../components/Button";
import timeToString from "../../../Clubs/Info/components/Calendar/helpers/timeToString";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import LoadImage from "../../../../components/common/LoadImage";
import Modal from "../../../../components/Modal";
import { Link } from "../../../../components/Collection";
import { store as socketStore } from "../../../../components/Socket/store";
import { formatMatchId } from "../../../FriendlyMatches/helpers";

const BookingInfo = () => {
  const history = useHistory();
  const { id } = useParams();
  const { addToast } = useToasts();
  const { t } = useTranslation('common');
  const globalState = useContext(socketStore);
  const { state: { socket: { socket } = {} } = {} } = globalState;
  const { accountInfo } = useSelector(state => state?.session);
  const [bookingInfo, setBookingInfo] = useState({});
  const [cancellationModal, setCancellationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(async () => {
    if (id) {
      const data = await getBookingInfo({ id, accountId: accountInfo.id });
      if (isEmpty(data)) {
        return history.push('/my-account/bookings');
      }
      setBookingInfo(data);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [id, accountInfo]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      addToast(t(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
        maxOpened: 1,
      });
      setIsSubmitting(false);

      if (success) {
        if (message === 'BOOKING_CANCELED_SUCCESSFULLY') {
          setBookingInfo(data);
        }
        setCancellationModal(false);
      }
    };

    if (socket) {
      socket.removeAllListeners('clubs-response');
      socket.on('clubs-response', listenForResponse);
      return () => socket.removeAllListeners('clubs-response');
    }
  }, [socket]);

  if (isEmpty(bookingInfo)) {
    return (
      <LoadingSpinner />
    );
  }

  const {
    amount,
    currency,
    clubInfo,
    courtInfo,
    friendlyMatch,
    paymentInfo,
  } = bookingInfo;

  const { bookingSettings, location } = clubInfo;

  const {
    matchId,
    player,
    playerPartner,
    opponent,
    opponentPartner,
  } = friendlyMatch ?? {};

  let startDate = new Date();

  if (bookingInfo?.startDate) {
    startDate = new Date(bookingInfo?.startDate);
    const [hour, minute] = timeToString(bookingInfo?.startHour).split(':');
    startDate.setHours(Number(hour), Number(minute));
  }

  const handleCancellation = async () => {
    setIsSubmitting(true);
    await cancelBooking({
      id,
      setIsSubmitting,
      addToast,
      t,
    });
  };

  const now = new Date();
  const maxAllowedCancellationDate = new Date(startDate);
  maxAllowedCancellationDate.setHours(startDate.getHours() - bookingSettings.minCancellationTime);

  const cancellationType = now.getTime() < maxAllowedCancellationDate.getTime() ? 'early' : 'late';
  const canBeCancelled = now.getTime() < startDate.getTime();

  return (
    <Container>
      <H2>
        <img src={LoadImage('back-arrow.svg')} alt="Back" onClick={() => history.goBack()} />
        {clubInfo.clubName}
        <span className={bookingInfo.status}>
          <Trans ns="clubs" i18nKey={`${bookingInfo.status}Status`}>{bookingInfo.status}</Trans>
        </span>
      </H2>
      <div className="description">
        <Paragraph small>
          <Trans ns="clubs" i18nKey="bookingId">
            Booking ID: <span>{{ bookingId: bookingInfo.bookingId }}</span>
          </Trans>
        </Paragraph>
        <Paragraph medium bold className="location">
          {location?.name}
          <a
            href={`https://maps.google.com/maps?q=${location?.coords?.lat},${location?.coords?.lng}&zoom=9`}
            target="_blank"
            rel="noreferrer"
            className="text-underline"
          >
            <Trans ns="friendlyMatches" i18nKey="seeLocation">See Location</Trans>
          </a>
        </Paragraph>
        <hr />
        <ul className="overview">
          <li>
            <Paragraph><Trans ns="events" i18nKey="startDate">Start Date</Trans></Paragraph>
            <Paragraph>{dateFormat(startDate, 'dd mmmm (ddd)')}</Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="startTime">Start Time</Trans></Paragraph>
            <Paragraph>{dateFormat(startDate, 'HH:MM')}</Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="duration">Duration</Trans></Paragraph>
            <Paragraph>
              <Trans ns="clubs" i18nKey="durationMin">{{ duration: bookingInfo?.duration }} min</Trans>
            </Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="court">Court</Trans></Paragraph>
            <Paragraph>{courtInfo?.name}</Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="surface">Surface</Trans></Paragraph>
            <Paragraph>
              <Trans ns="clubs" i18nKey={`surfaceType.${courtInfo?.surface}`}>{courtInfo?.surface}</Trans>
            </Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="courtType">Court Type</Trans></Paragraph>
            <Paragraph>
              <Trans ns="clubs" i18nKey={`courtTypes.${courtInfo?.type}`}>{courtInfo?.type}</Trans>
            </Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="gameType">Game Type</Trans></Paragraph>
            <Paragraph>
              <Trans ns="clubs" i18nKey={`courtSizes.${courtInfo?.size}`}>{courtInfo?.size}</Trans>
              {!isEmpty(friendlyMatch) && (
                <span>, <Trans ns="clubs" i18nKey="friendlyMatch">Friendly Match</Trans></span>
              )}
            </Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="sportType">Sport Type</Trans></Paragraph>
            <Paragraph>
              <Trans ns="common" i18nKey={`sports.${courtInfo?.sportType}`}>{courtInfo?.sportType}</Trans>
            </Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="selfService">Self-Service</Trans></Paragraph>
            <Paragraph>
              <Trans ns="common" i18nKey={courtInfo?.selfService ? 'yes' : 'no'}>
                {courtInfo?.selfService ? 'Yes' : 'No'}
              </Trans>
            </Paragraph>
          </li>
          {courtInfo?.hasLighting && (
            <li>
              <Paragraph><Trans ns="clubs" i18nKey="additionalInfo">Additional Info</Trans></Paragraph>
              <Paragraph className="text-capitalize">
                <Trans ns="common" i18nKey="withLighting">With Lighting</Trans>
              </Paragraph>
            </li>
          )}
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="orderId">Order ID</Trans></Paragraph>
            <Paragraph>{paymentInfo?.paymentId ?? 'N/A'}</Paragraph>
          </li>
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="totalWithVat">Total (incl. VAT)</Trans></Paragraph>
            <Paragraph bold className="text-capitalize total-amount">
              {amount} {currency}
              <span>
                <Trans ns="clubs" i18nKey={`${paymentInfo?.status}PaymentStatus`}>{paymentInfo?.status}</Trans>
              </span>
            </Paragraph>
          </li>
          {!isEmpty(friendlyMatch) && (
            <li className="friendly-match">
              <Link to="/friendly-matches/available">
                <Paragraph bold className="friendly-match__title">
                  <Trans ns="clubs" i18nKey="friendlyMatch">Friendly Match</Trans>
                  <img src={LoadImage('pagination-right-arrow.svg')} alt="" width="8" />
                </Paragraph>
                <Paragraph smaller color="grey">{formatMatchId(matchId)}</Paragraph>
              </Link>
              <ul>
                <li><UserAvatar user={player} size={38} /></li>
                {!isEmpty(playerPartner) && (<li><UserAvatar user={playerPartner} size={38} /></li>)}
                <li><UserAvatar user={opponent} size={38} /></li>
                {!isEmpty(opponentPartner) && (<li><UserAvatar user={opponentPartner} size={38} /></li>)}
              </ul>
            </li>
          )}
        </ul>

        {bookingInfo.status === 'confirmed' && paymentInfo?.status === 'success' && canBeCancelled && (
          <div className="buttons">
            <Button red outline fullwidth>
              <span onClick={() => setCancellationModal(true)}>
                <Trans ns="clubs" i18nKey="cancelBooking">Cancel Booking</Trans>
              </span>
            </Button>
            <Paragraph smaller className="mt10 text-center ml10 mr10">
              <Trans ns="paymentConfirmation" i18nKey="cancellationWithRefundInfo">
                This Booking can be canceled with Full Refund up to
                <strong>{{ hours: bookingSettings?.minCancellationTime }}h</strong> before the Start Time.
              </Trans>
            </Paragraph>
          </div>
        )}
      </div>

      {cancellationModal && (
        <Modal
          isOpen={cancellationModal}
          onRequestClose={() => {
            if (!isSubmitting) {
              setCancellationModal(false);
            }
          }}
          style={{
            padding: "50px 70px",
          }}
          showCloseIcon={!isSubmitting}
        >
          <div className="modal-header">
            <H3>
              <Trans ns="clubs" i18nKey={`${cancellationType}Cancellation`}>Late Cancellation</Trans>
            </H3>
          </div>
          <div className="modal-content text-center">
            <Paragraph medium className="cancellation-text">
              <Trans ns="clubs" i18nKey={`${cancellationType}CancellationText`}>
                Are you sure you want to cancel this booking you’ve created?
                According to the Cancellation Policy of the Club,
                the Booking will be canceled <strong className={cancellationType}>WITH REFUND</strong>.
              </Trans>
            </Paragraph>
          </div>
          <div className="modal-footer flex-row justify-content-center gap-10">
            <Button fullwidth outline onClick={() => setCancellationModal(false)}>
              <span><Trans ns="friendlyMatches" i18nKey="goBack">Go Back</Trans></span>
            </Button>
            <Button fullwidth red onClick={handleCancellation} disabled={isSubmitting}>
              {isSubmitting && <LoadingSpinner className="white-dots" />}
              {!isSubmitting && <span><Trans ns="clubs" i18nKey="cancelBooking">Cancel Booking</Trans></span>}
            </Button>
          </div>
          <Paragraph smaller className="mt20 text-center">
            <Trans ns="events" i18nKey="withdrawal.disclaimer">
              Please check our
              <Link className="color-secondary" to="/rules-regulations">Sportya Rules and Regulations</Link> and
              <Link className="color-secondary" to="/return-policy">Return Policy</Link>
              for more information on withdrawals and refunds.
            </Trans>
          </Paragraph>
        </Modal>
      )}
    </Container>
  );
};

export default BookingInfo;
