export default {
  title: 'Získat Prémiový úèet',
  subtitle: 'Chcete-li využívat všechny funkce, které platforma Sportya nabízí, a ušetøit na vstupní poplatcích do turnajù, zvolte si <1>Prémiové</1> úèty',
  seoTitle: 'Získat Prémiový úèet | Sportya',

  benefits: {
    title: 'Výhody ve všech úètech',
    1: 'Osobní profil hráèe, podrobné výsledky zápasù, žebøíèky a statistiky',
    2: 'Národní a mezinárodní žebøíèky pro amatérské tenisty',
    3: '10% sleva na všechny nákupy v TENNIS WAREHOUSE EUROPE, což je nejvìtší specializovaný prodejce v Evropì',
    4: 'Standard hry pro všechny události, vèetnì nových míèù pro každé utkání ve dvouhøe a dárky (podle události).',
    5: 'Pøátelská utkání s jakýmkoli zaregistrovaným èlenem platformy Sportya',
    6: 'Osobní zprávy, systém upozornìní',
    7: 'Pøístup ke statistikám utkání, výsledky událostí a srovnání pro všechny èleny platformy Sportya.',
    8: 'Technická podpora (e-mailem nebo telefonem)',
  },

  info: {
    feeReduction: '1. Cenovì zvýhodnìné vstupy pro prémiové úèty závisí na události a zemi a pohybují se od 3 do 5 EUR za registraci na událost (platí automaticky). Další slevy se mohou vztahovat na urèité události v kalendáøi. Jedná se napø. o události speciálního formátu, turnaje kategorie Platinum, mezinárodní turnaje apod.',
  },

  expirationDate: '{{value}} platnost v mìsících',
  activePlan: 'Váš plán <1>Premium</1> je aktivní do <3>{{expDate}}</3>',

  planConfirmation: {
    bestValue: 'Nejlepší hodnota',
    noExpiration: 'Neomezená platnost',
    currentPlan: 'Aktuální plán',
    included: 'Zahrnuto',
    extend: 'Rozšíøit',
    upgrade: 'Upgradovat',
    learnMore: 'Podrobné informace',
    confirmFinalPayment: 'Potvrïte prosím svou koneènou platbu za <1>Premium {{period}}</1>',
    validity: 'Platnost',
    holder: 'Majitel',
    totalOrder: 'Celková objednávka',
    confirmPay: 'Potvrdit a zaplatit',
    availableBalance: 'Dostupný zùstatek',
    redirected: 'Budete pøesmìrováni na',
    redirecting: 'Pøesmìrování na Stripe...',
    redirectingClean: 'Pøesmìrování na',
  },

  bottomSection: {
    findMore: 'Zjistit více',
    remindMe: 'Pøipomenout pozdìji',
  },

	plans: {
		free: {
			title: 'Úèet zdarma',
			1: 'PLNÝ přístup k přátelským zápasům s přáteli nebo jinými hráči',
			2: 'PLNÁ cena za vstupy do turnaje',
			3: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
			expDate: 'Neomezená platnost',
			price: 'Zdarma',
		},

    summer: {
      title: 'Letní sezónní úèet',
      1: 'PLNÝ přístup k přátelským zápasům s přáteli nebo jinými hráči',
      2: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
      3: 'ZVÝHODNĚNÁ cena za vstupy do turnaje',
      info: 'Letní sezónní úèet je urèen hráèùm, kteøí se velmi angažují na okruhu a chtìjí neustále zlepšovat svou hru a poøadí na žebøíèku, a úèastnit se bìhem léta co nejvìtšího poètu turnajù, a to jak turnajù kategorie PLATINUM a mezinárodních turnajù, tak i speciálních turnajù, jako jsou Turnaje Mistrù a Turnaje Masters. Letní sezónní úèet vám nabízí pøístup ke všem možnostem a výhodám online platformy. Registrace na turnaje zahrnuje zaplacení online rezervaèního poplatku za sníženou cenu v okamžiku pøihlášení na turnaj, a úèastnického poplatku poøadateli v místì konání soutìže.',
      expDate: 'Platnost 6 mìsícù',
      timePeriod: '1. dubna 2021 – 31. øíjna 2021',
    },

		premium: {
			title: 'Prémiový úèet',
			1: 'PLNÝ přístup k přátelským zápasům s přáteli nebo jinými hráči',
			2: 'ZVÝHODNĚNÁ cena za vstupy do turnaje',
			3: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
			info: 'Prémiový úèet je urèen hráèùm, kteøí se bìhem roku chtìjí zúèastnit co nejvìtšího poètu turnajù, a to nejen turnajù kategorie PLATINUM a mezinárodních turnajù, ale i speciálních turnajù, jako jsou Turnaje Mistrù a Turnaje Masters. Prémiový úèet vám nabízí pøístup ke všem možnostem a výhodám online platformy. Registrace na turnaje zahrnuje zaplacení online rezervaèního poplatku za sníženou cenu v okamžiku pøihlášení na turnaj, a úèastnického poplatku poøadateli v místì konání soutìže.',
			expDate: 'Platnost 12 mìsícù',
			timePeriod: '1. ledna 2021 – 31. prosince 2021',
		},

    overlay: {
      free: {
        1: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
        2: 'PLNÁ cena za vstupy do turnaje',
      },

      summer: {
        1: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
        2: 'SNÍŽENÁ cena za vstupy do turnaje',
      },

      premium: {
        1: 'Pøístup na turnaje Platina, Mistøi, Masters, Mezinárodní a Speciální turnaje',
        2: 'SLEVY na online registraèní poplatky + úèastnický poplatek, který se platí poøadateli turnaje',
      },
    },
  },
};
