import { Trans } from 'react-i18next';
import React from 'react';
import { P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

const shortDayNames = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

const ExclusiveOffer = ({ clubInfo, ...props }) => (
  <div {...props}>
    {clubInfo?.exclusiveOffer?.status === "active" && new Date(clubInfo?.exclusiveOffer?.endDate) > new Date() && (
      <div className="exclusive-offer-container">
        {['weekday', 'weekend'].map((day) => (
          clubInfo?.exclusiveOffer?.[day]?.pricePerHour && (
            <P className={`offer-${day}`} key={day}>
              <img src={LoadImage('discount-icon-black.png')} alt="" width={18} height={18} />
              <P className="ml5">
                <Trans ns="clubs" i18nKey="pricePerHour">
                  {{ pricePerHour: clubInfo?.exclusiveOffer?.[day]?.pricePerHour }}
                  {{ currency: 'RON' }}
                  per hour
                </Trans>
              </P>
              <span>&nbsp;•&nbsp;</span>
              <span>
                <Trans ns="common" i18nKey={shortDayNames[clubInfo?.exclusiveOffer?.[day]?.startDay]}>
                  {clubInfo?.exclusiveOffer?.[day]?.startDay}
                </Trans>
                -
                <Trans ns="common" i18nKey={shortDayNames[clubInfo?.exclusiveOffer?.[day]?.endDay]}>
                  {clubInfo?.exclusiveOffer?.[day]?.endDay}
                </Trans>/&nbsp;
              </span>
              <span>
                {clubInfo?.exclusiveOffer?.[day]?.startHour}-{clubInfo?.exclusiveOffer?.[day]?.endHour}
              </span>
            </P>
          )
        ))}
      </div>
    )}
  </div>
);

export default ExclusiveOffer;
