import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  width: 1235,
  maxWidth: '100%',
  margin: '50px auto',
  border: `2px solid ${theme.colors.lightBlue}`,
  borderRadius: 10,
  height: '82vh',
  alignSelf: 'flex-end',

  '&.no-conversations': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingTop: 65,

    '> div': {
      maxWidth: 550,
    },

    h3: {
      marginBottom: 20,
    },

    input: {
      width: 550,
      maxWidth: '100%',
    },

    '@media (max-width: 767px)': {
      paddingTop: 0,
      margin: 0,
      justifyContent: 'center',

      input: {
        width: '100%',
        padding: '10px 20px 10px 50px',
        fontSize: 16,
      },
    },
  },

  '&.loading-content': {
    display: 'flex',
    border: 'none',
    justifyContent: 'center',
    margin: 0,
  },

  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '32%',
    borderRight: `2px solid ${theme.colors.lightBlue}`,

    '.heading': {
      alignItems: 'center',
      padding: 20,
    },

    '.is-typing': {
      fontStyle: 'italic',
      color: theme.colors.league,
    },

    '.is-blocked': {
      fontStyle: 'italic',
      color: theme.colors.red,
    },

    '.friends-list': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      borderTop: `2px solid ${theme.colors.lightBlue2}`,
      padding: '15px 25px',
      borderBottomLeftRadius: 10,
      height: 60,

      img: {
        marginRight: 5,
      },

      p: {
        fontSize: 14,
      },

      '&:hover': {
        textDecoration: 'none',
        background: theme.colors.lightBlue2,
      },
    },

    '@media (min-width: 1024px)': {
      '.new-conversation': {
        display: 'none',
      },
    },
  },

  '.new-conversation': {
    margin: 20,

    input: {
      height: 50,
      fontSize: 14,
      color: theme.colors.black,
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '68%',

    '&.no-selected-conversation': {
      justifyContent: 'center',
    },

    '.conversation-header': {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      borderBottom: `2px solid ${theme.colors.lightBlue2}`,
      height: 80,

      a: {
        textDecoration: 'none',
      },

      '.individual-conversation': {
        width: 40,
        height: 40,

        '.initials': {
          width: 40,
          height: 40,
        },
      },

      '.group-conversation': {
        width: 40,
        height: 40,

        '.plus-members': {
          display: 'none',
        },

        '.initials': {
          span: {
            fontSize: 10,
            letterSpacing: -1,
          },
        },
      },
    },

    '.conversation-name': {
      cursor: 'pointer',
      marginRight: 25,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '.is-typing': {
      fontStyle: 'italic',
      color: theme.colors.league,
      margin: 0,
      fontSize: 12,
    },

    '.is-blocked': {
      fontStyle: 'italic',
      color: theme.colors.red,
      margin: 0,
    },

    '.status': {
      fontSize: 12,
      margin: 0,

      '.is-online': {
        display: 'inline-block',
        minWidth: 7,
        width: 7,
        height: 7,
        background: theme.colors.lime2,
        borderRadius: '50%',
        marginRight: 5,
      },

      '.is-offline': {
        display: 'inline-block',
        minWidth: 7,
        width: 7,
        height: 7,
        background: theme.colors.error,
        borderRadius: '50%',
        marginRight: 5,
      },
    },

    '.actions-wrapper': {
      display: 'flex',
      alignItems: 'center',
    },

    '.create-match': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 30,
      padding: '2px 10px',

      img: {
        padding: 0,
        width: 18,
        marginRight: 5,
      },

      p: {
        padding: 0,
        fontSize: 12,
        lineHeight: 1,
      },
    },

    '.actions': {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',

      '.options': {
        background: '#FFFFFF',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        padding: 10,
        position: 'absolute',
        right: 0,
        width: 'max-content',
        top: '25px',
        zIndex: 99,
        cursor: 'initial',
        listStyleType: 'none',

        '&.is-last': {
          top: '-60px !important',
        },

        li: {
          color: theme.colors.primary,
          fontSize: '14px',
          lineHeight: '18px',
          padding: 10,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',

          img: {
            marginRight: 5,
          },

          a: {
            textDecoration: 'none',
            display: 'flex',
            width: '100%',
          },

          '&:hover': {
            background: theme.colors.lightGrey,
          },

          '&.delete': {
            color: theme.colors.red,
          },
        },
      },
    },

    '.back-arrow': {
      display: 'none',
    },
  },

  '.left-conversation-message': {
    padding: 15,
    color: theme.colors.mediumTurquoise,
    background: theme.colors.lightBlue2,
    borderRadius: 10,
    margin: 20,
    textAlign: 'center',
  },

  '.unread-messages-wrapper': {
    position: 'absolute',
    left: '50%',
    top: 100,
    zIndex: 2,
  },

  '.unread-messages': {
    position: 'relative',
    left: '-50%',
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',

    span: {
      alignItems: 'center',
      display: 'inline-flex',
      color: theme.colors.white,
      background: '#00A2E3',
      borderTopLeftRadius: 70,
      borderBottomLeftRadius: 70,
      padding: '5px 15px',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '18px',
      paddingRight: 15,

      img: {
        marginRight: 10,

        '&.arrow-down': {
          transform: 'rotate(180deg)',
        },
      },

      '&:hover': {
        background: '#0286C3FF',
      },
    },

    '.close': {
      display: 'flex',
      alignItems: 'center',
      borderTopRightRadius: 70,
      borderBottomRightRadius: 70,
      background: '#00A2E3',
      paddingLeft: 15,
      paddingRight: 15,

      '&:hover': {
        background: '#0286C3FF',
      },
    },
  },

  form: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 20,

    input: {
      width: '100%',
      height: '50px',
      background: '#FFFFFF',
      border: '2px solid #C1E6E5',
      borderRadius: '10px',
      paddingLeft: 10,
      paddingRight: 10,
      color: theme.colors.mediumTurquoise,
      fontSize: 16,
      fontWeight: 400,

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },

      '&:focus': {
        outline: 'none',
        borderColor: theme.colors.black,
        color: theme.colors.black,

        '&::-webkit-input-placeholder': {
          color: theme.colors.black,
        },
      },

      '&::-webkit-input-placeholder': {
        color: theme.colors.mediumTurquoise,
      },
    },

    button: {
      background: theme.colors.mediumTurquoise,
      border: 'none',
      borderRadius: '50%',
      marginLeft: 10,
      cursor: 'pointer',
      padding: '12px',
      width: 42,
      height: 42,

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },

      '&.is-focused': {
        background: theme.colors.black,
      },
    },
  },

  '.disconnected': {
    background: theme.colors.errorLight,
    padding: 5,
    color: theme.colors.white,
    marginTop: 5,
  },

  // responsive
  '@media (max-width: 1023px)': {
    minHeight: 'calc(100vh - 150px)',
    margin: 0,

    aside: {
      display: 'flex',
      width: '100%',

      '.heading': {
        padding: '10px 15px',
      },

      ul: {
        li: {
          marginBottom: 5,
          padding: 5,
        },
      },

      '.new-conversation': {
        flexGrow: 1,
        marginTop: 0,

        '> div': {
          padding: 0,
        },

        input: {
          padding: '10px 40px',
        },

        img: {
          left: 10,
        },
      },

      '.search-bar-visible': {
        display: 'none',
      },
    },

    section: {
      display: 'none',
      width: '100%',

      '.conversation-header': {
        padding: '5px 15px',
        // height: "auto",
        // position: "fixed",
        // top: 50,
        // width: "100%",
        // zIndex: 2,
        // background: "white",
      },

      '.conversation-name': {
        flexGrow: 2,
        marginLeft: 15,

        h3: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100vw - 275px)',
        },
      },

      '#conversation-content': {
        // marginTop: 50,
        // marginBottom: 60,

        ul: {
          margin: '0 15px',
          paddingTop: 10,

          'li:last-of-type': {
            '.message__has-spacing': {
              marginBottom: 15,
            },
          },
        },
      },

      form: {
        margin: 0,
        padding: '5px 15px',
        // position: "fixed",
        // bottom: 0,
        // width: "100%",
        // background: "white",
        // zIndex: 2,
      },
    },

    '&.conversation-selected': {
      aside: {
        display: 'none',
      },

      section: {
        display: 'flex',
      },

      '.back-arrow': {
        display: 'block',
        cursor: 'pointer',
      },
    },

    '.unread-messages-wrapper': {
      top: 75,
    },
  },
}));

export const ModalContainer = styled.div(({ theme }) => ({
  position: 'relative',

  '.modal-content': {
    minHeight: 300,
  },

  figure: {
    position: 'relative',
    width: 60,
    height: 60,
    borderRadius: '50%',
    marginBottom: 10,
    display: 'inline-block',

    '.image': {
      width: 60,
      height: 60,
    },

    img: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },

    '.remove': {
      position: 'absolute',
      top: 0,
      right: '-5px',
      padding: '2px',
      border: '2px solid black',
      borderRadius: '50%',
      width: '22px',
      height: '22px',
      background: 'white',
      cursor: 'pointer',

      img: {
        width: 'auto',
      },
    },

    '.initials': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
      width: 60,
      borderRadius: '50%',
      background: 'white',
      border: `1px solid ${theme.colors.lightBlue2}`,

      span: {
        fontWeight: 'bold',
      },
    },

    '&.smaller': {
      width: 38,
      height: 38,
      marginBottom: 0,

      '.image': {
        width: 38,
        height: 38,
      },

      '.initials': {
        width: 38,
        height: 38,
      },
    },
  },

  '.friends-list, .members-list': {
    marginTop: 10,

    li: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
      alignItems: 'center',

      p: {
        flexGrow: 2,
        fontSize: 16,
        fontWeight: 'bold',

        span: {
          marginLeft: 5,
          color: theme.colors.secondary,
          fontWeight: 400,
        },
      },
    },
  },

  '.group-members': {
    display: 'flex',
    listStyleType: 'none',
    marginTop: 30,
    marginBottom: 30,

    li: {
      margin: '0 25px',
      textAlign: 'center',
    },

    p: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
  },

  '.actions': {
    position: 'relative',

    '.options': {
      background: '#FFFFFF',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      padding: 10,
      position: 'absolute',
      width: 'max-content',
      zIndex: 99,
      cursor: 'initial',
      margin: 0,
      top: '24px',
      bottom: 'auto',
      right: '-5px',

      '&.is-last': {
        top: '-100px !important',
      },

      li: {
        color: theme.colors.primary,
        fontSize: '14px',
        lineHeight: '18px',
        padding: 10,
        cursor: 'pointer',
        marginBottom: 0,
        justifyContent: 'flex-start',

        img: {
          marginRight: 10,
        },

        '&:hover': {
          background: theme.colors.lightGrey,
        },

        '&.delete': {
          color: theme.colors.red,
        },
      },
    },

    '&__visible': {
      display: 'block',
    },
  },

}));

export const ConversationListStyle = styled.div(({ theme }) => ({
  overflowY: 'scroll',
  flex: 'auto',

  ul: {
    '.conversation-info': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexGrow: 2,

      '> div': {
        display: 'flex',
        alignItems: 'center',
      },

      '.name': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 16,
      },

      '.is-online': {
        display: 'inline-block',
        minWidth: 7,
        width: 7,
        height: 7,
        background: theme.colors.lime2,
        borderRadius: '50%',
        marginRight: 5,
      },

      '.is-offline': {
        display: 'inline-block',
        minWidth: 7,
        width: 7,
        height: 7,
        background: theme.colors.error,
        borderRadius: '50%',
        marginRight: 5,
      },

      '.user-id': {
        color: theme.colors.darkTurquoise,
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 5,
      },
    },

    '.conversation-flags': {
      marginLeft: 10,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',

      span: {
        marginLeft: 5,
        background: theme.colors.darkRed,
        color: 'white',
        width: 22,
        height: 22,
        textAlign: 'center',
        padding: 3,
        borderRadius: '50%',
        fontSize: 12,
      },
    },

    '.last-message': {
      display: 'flex',
      alignItems: 'center',

      p: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 12,
        margin: 0,
      },

      time: {
        fontSize: 12,
      },

      span: {
        margin: '0 5px',
      },
    },

    li: {
      padding: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 6,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      background: 'transparent',
      border: '1px solid transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&.is-active': {
        background: theme.colors.lightBlue,
      },

      '&:hover': {
        borderColor: theme.colors.lightBlue,
      },
    },
  },

}));

export const ConversationContentStyle = styled.div(({ theme }) => ({
  // position: "relative",
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column-reverse',
  flexGrow: 2,
  // paddingBottom: 20,

  '.conversation-header': {
    alignItems: 'center',
  },

  ul: {
    margin: '15px 20px 0 20px',
    listStyleType: 'none',
  },

  '.loading-messages': {
    position: 'absolute',
    top: 85,
    left: 'calc(50% - 40px)',
  },

  '.all-messages-loaded': {
    fontSize: 12,
    color: theme.colors.darkTurquoise,
    textAlign: 'center',
    padding: 10,
  },

  li: {
    display: 'block',

    '.author': {
      fontSize: 12,
      left: 0,

      span: {
        marginRight: 5,
        fontWeight: 'bold',
      },
    },

    '.message': {
      alignItems: 'end',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      position: 'relative',
      transition: 'background-color .5s ease-out',
      width: '100%',

      '.avatar': {
        width: 28,
        height: 28,
        border: '1px solid black',
        borderRadius: '50%',
        marginRight: 10,
        position: 'relative',
        overflow: 'hidden',

        span: {
          width: '100%',
          height: '100%',
          fontSize: 12,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },

        img: {
          position: 'absolute',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          borderRadius: '50%',
          objectFit: 'cover',
          cursor: 'pointer',
        },
      },

      '.message-content': {
        flex: 'initial',
        maxWidth: '75%',
        position: 'relative',
      },

      '.author': {
        position: 'absolute',
        whiteSpace: 'nowrap',
      },

      '.pending-message': {
        position: 'absolute',
        top: 'calc(50% - 10px)',
        left: '-30px',
      },

      '.actions': {
        display: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: 'calc(50% - 10px)',
        left: '-30px',

        '.options': {
          background: '#FFFFFF',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          borderRadius: '10px',
          padding: 10,
          position: 'absolute',
          width: 'max-content',
          zIndex: 99,
          cursor: 'initial',
          margin: 0,
          top: '24px',
          bottom: 'auto',
          right: '-5px',

          li: {
            color: theme.colors.primary,
            fontSize: '14px',
            lineHeight: '18px',
            padding: 10,
            cursor: 'pointer',

            '&:hover': {
              background: theme.colors.lightGrey,
            },

            '&.delete': {
              color: theme.colors.red,
            },
          },
        },

        '&__visible': {
          display: 'block',
        },
      },

      p: {
        padding: '10px',
        background: theme.colors.lightBlue,
        color: theme.colors.primary,
        borderRadius: '10px 10px 10px 0',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        margin: 0,
        minHeight: 30,
        lineHeight: '18px',
        fontWeight: 400,
        marginBottom: 3,
      },

      '&__has-spacing': {
        marginBottom: 25,

        p: {
          marginBottom: 2,
        },
      },
    },

    '&.is-user': {
      cursor: 'default',
      background: 'white',
      color: 'black',
      justifyContent: 'flex-end',

      '.author': {
        left: 'auto',
        right: 0,
      },

      '.message': {
        flexDirection: 'row-reverse',

        '.avatar': {
          marginRight: 0,
          marginLeft: 10,
        },

        p: {
          background: theme.colors.primary,
          color: theme.colors.white,
          borderRadius: '10px 10px 0 10px',
        },

        '&:hover': {
          '.actions': {
            display: 'block',
          },
        },

        '.show-options': {
          display: 'block',
        },
      },
    },
  },
}));
