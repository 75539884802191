import React from 'react';
import styled from '@emotion/styled';

const CardStyle = styled.div`
  padding: ${({ theme }) => theme?.functions?.toEm(20)};
  border-radius: ${({ theme }) => theme?.functions?.toEm(5)};
  background: ${({ theme }) => theme?.colors?.white};
  border: 2px solid ${({ theme }) => theme?.colors?.borderGrey};
  
  ${props => props.theme.functions?.minDesktopSmall(`
    padding: ${ props.theme?.functions?.toEm(40)};
  `)}
`;

const Card = ({ children, ...props }) => <CardStyle {...props}>{children}</CardStyle>;

export default Card;
