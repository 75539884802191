import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import PubSub from 'pubsub-js';
import { get, patch, post } from '../../components/common/http';

export const addScoreFriendlyMatches = createAsyncThunk('friendlyMatches/addScore', async ({ payload, id }) => {
  try {
    await post(`/friendly-matches/${id}/saveScore`, payload);
    return { payload, id };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const createFriendlyMatch = createAsyncThunk('friendlyMatches/create', async ({ payload }) => {
  try {
    await post('/friendly-matches', payload);
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const editFriendlyMatch = createAsyncThunk('friendlyMatches/edit', async ({ payload, id }) => {
  try {
    await patch(`/friendly-matches/${id}`, payload);
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchFriendlyMatches = createAsyncThunk('friendlyMatches/data', async ({ tab }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const isRequests = tab === 'requests';
    const { data: { data } } = await get(`/friendly-matches${isRequests ? '/requests' : ''}?sportType=${sportType}`);
    return { data, tab, sportType };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchInactiveMatches = createAsyncThunk('friendlyMatches/inactiveMatches', async ({ page }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const { data: { data } } = await get(`/friendly-matches/inactive?sportType=${sportType}&page=${page || 1}`);
    return {
      data,
      page,
      sportType,
    };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchInactiveRequests = createAsyncThunk('friendlyMatches/inactiveRequests', async ({ page }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const {
      data: { data },
    } = await get(`/friendly-matches/requests/inactive?sportType=${sportType}&page=${page || 1}`);
    return {
      data,
      page,
      sportType,
    };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchFriendlyMatchesScores = createAsyncThunk('friendlyMatches/scores', async ({ page, limit }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const {
      data: { data },
    } = await get(`/friendly-matches/scores?sportType=${sportType}&page=${page || 1}&limit=${limit || 6}`);
    return { page, data, sportType };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchRaisedHandsPlayers = createAsyncThunk('friendlyMatches/raisedHands', async ({ page, limit }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const {
      data: { data },
    } = await get(`/friendly-matches/raised-hands?sportType=${sportType}&page=${page || 1}&limit=${limit || 6}`);
    return { page, data, sportType };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchPlayersNearby = createAsyncThunk('friendlyMatches/playersNearby', async ({ page, limit }, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const {
      data: { data },
    } = await get(`/friendly-matches/players-nearby?sportType=${sportType}&page=${page || 1}&limit=${limit || 6}`);
    return { page, data, sportType };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchOtherTopPicks = createAsyncThunk(
  'friendlyMatches/otherTopPicks',
  async ({ page, limit }, { getState }) => {
    try {
      const sportType = getState()?.session?.masterSport;
      // eslint-disable-next-line max-len
      const { data: { data } } = await get(`/friendly-matches/other-top-picks?sportType=${sportType}&page=${page || 1}&limit=${limit || 6}`);
      return { data, sportType };
    } catch ({ response }) {
      PubSub.publish('api-error-handler', {
        message: response?.data?.message,
        hasNotification: false,
      });
    }
  },
);

export const fetchSummary = createAsyncThunk('friendlyMatches/summary', async () => {
  try {
    const { data: { data } } = await get('/friendly-matches/summary');
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const declineMatch = createAsyncThunk('friendlyMatches/declineMatch', async ({ id }) => {
  try {
    const { data: { data } } = await post(`/friendly-matches/${id}/decline`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const acceptMatch = createAsyncThunk('friendlyMatches/acceptMatch', async ({ id }) => {
  try {
    const { data: { data } } = await post(`/friendly-matches/${id}/accept`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const noMatch = createAsyncThunk('friendlyMatches/noMatch', async ({ id }) => {
  try {
    const { data: { data } } = await post(`/friendly-matches/${id}/noMatch`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const withdraw = createAsyncThunk('friendlyMatches/withdraw', async ({ id }) => {
  try {
    const { data: { data } } = await post(`/friendly-matches/${id}/withdraw`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const cancelMatch = createAsyncThunk('friendlyMatches/cancelMatch', async ({ id }) => {
  try {
    const { data: { data } } = await post(`/friendly-matches/${id}/cancel`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchPartnerClubs = createAsyncThunk(
  'friendlyMatches/fetchPartnerClubs',
  async ({ coords, page, limit }, { getState }) => {
    try {
      const sportType = getState()?.session?.masterSport;
      // eslint-disable-next-line max-len
      const { data: { data } } = await get(`/clubs/partner-clubs?sportType=${sportType}&coordinates=${coords?.lng},${coords?.lat}&page=${page || 1}&limit=${limit || 4}`);

      return { page, data, sportType };
    } catch ({ response }) {
      PubSub.publish('api-error-handler', {
        message: response?.data?.message,
        hasNotification: false,
      });
    }
  },
);

const friendlyMatches = createSlice({
  name: 'friendlyMatches',
  initialState: {
    matches: {
      data: {},
      status: 'idle',
      sportType: null,
    },
    requests: {
      data: {},
      status: 'idle',
      sportType: null,
    },
    scores: {
      data: {},
      status: 'idle',
      sportType: null,
    },
    generalStatus: true,
    error: null,
  },
  reducers: {
    replaceMatchInList: (state, action) => {
      const { match, list } = action?.payload || {};
      if (match && list && state[list].data?.results) {
        const matchIndex = (state[list].data?.results || []).findIndex(item => item.matchId === match.matchId);
        if (matchIndex > -1) {
          state[list].data.results[matchIndex] = match;
        }
      }
    },
    addMatchToList: (state, action) => {
      const { match, list } = action?.payload || {};
      if (match && list && state[list].data?.results) {
        state[list].data.results.unshift(match);
      }
    },
    updateMatchReportedBy: (state, action) => {
      const { matchId, list, userId } = action?.payload || {};
      const matchIndex = state[list].data.results.findIndex((match) => match.matchId === matchId);
      if (list && state[list].data.results?.[matchIndex]) {
        if (!state[list].data.results[matchIndex].reportedBy) {
          state[list].data.results[matchIndex].reportedBy = [];
        }
        state[list].data.results[matchIndex].reportedBy = [...state[list].data.results[matchIndex].reportedBy, userId];
      }
    },
    removePlaceholder: (state, action) => {
      const { list } = action?.payload || {};
      if (list && state[list].data?.results) {
        state[list].data.results = state[list].data.results.filter(item => !item.isPlaceholder);
      }
    },
  },
  extraReducers: {
    [addScoreFriendlyMatches.fulfilled]: (state, action) => {
      const { payload, id } = action.payload || {};
      const { player, opponent } = payload || {};

      if (id) {
        const matchToEdit = (state.matches?.data?.results)?.find((match) => match?._id === id);

        if (matchToEdit) {
          matchToEdit.player.score = [...player?.score];
          matchToEdit.opponent.score = [...opponent?.score];

          if (player?.type) {
            matchToEdit.player.type = player?.type;
          }

          if (opponent?.type) {
            matchToEdit.opponent.type = opponent?.type;
          }
        }
      }
    },
    [fetchFriendlyMatches.pending]: (state) => {
      state.generalStatus = false;
    },
    [fetchFriendlyMatches.fulfilled]: (state, action) => {
      const { data, tab, sportType } = action.payload || {};
      if (tab) {
        state[tab].sportType = sportType;
        state[tab].data = data;
        state[tab].data.results = [...new Set([...(state[tab]?.data?.results || []), ...(data?.results || [])])];
        state[tab].status = 'succeeded';
      }
    },
    [fetchFriendlyMatches.rejected]: (state, action) => {
      state.generalStatus = false;
      state.error = action.error.message;
    },
    [fetchInactiveRequests.pending]: (state) => {
      state.generalStatus = false;
    },
    [fetchInactiveRequests.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.requests.sportType = sportType;
      state.requests.data.results = [...new Set([...(state.requests?.data?.results || []), ...(data?.results || [])])];
      state.requests.data.loadMore = data?.totalPages > page;
      state.requests.status = 'succeeded';
    },
    [fetchInactiveRequests.rejected]: (state, action) => {
      state.generalStatus = false;
      state.error = action.error.message;
    },
    [fetchInactiveMatches.pending]: (state) => {
      state.generalStatus = false;
    },
    [fetchInactiveMatches.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.matches.sportType = sportType;
      state.matches.data.results = [...new Set([...(state.matches?.data?.results || []), ...(data?.results || [])])];
      state.matches.data.loadMore = data?.totalPages > page;
      state.matches.status = 'succeeded';
    },
    [fetchInactiveMatches.rejected]: (state, action) => {
      state.generalStatus = false;
      state.error = action.error.message;
    },
    [fetchFriendlyMatchesScores.pending]: (state) => {
      state.generalStatus = false;
    },
    [fetchFriendlyMatchesScores.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.scores.sportType = sportType;
      state.scores.data = {
        ...data,
        ...page > 1 && {
          results: [...new Set([...(state?.scores?.data?.results || []), ...(data?.results || [])])],
        },
      };

      state.scores.status = 'succeeded';
    },
    [fetchFriendlyMatchesScores.rejected]: (state, action) => {
      state.generalStatus = false;
      state.error = action.error.message;
    },
  },
});

export const {
  addMatchToList,
  replaceMatchInList,
  updateMatchReportedBy,
  removePlaceholder,
} = friendlyMatches.actions;

const raisedHands = createSlice({
  name: 'raisedHands',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchRaisedHandsPlayers.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchRaisedHandsPlayers.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.data = {
        ...data,
        ...page > 1 && {
          results: [...new Set([...(state?.data?.results || []), ...(data?.results || [])])],
        },
      };
      state.sportType = sportType;
      state.status = 'succeeded';
    },
    [fetchRaisedHandsPlayers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const partnerClubs = createSlice({
  name: 'partnerClubs',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchPartnerClubs.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchPartnerClubs.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.data = {
        ...data,
        ...page > 1 && {
          results: [...new Set([...(state?.data?.results || []), ...(data?.results || [])])],
        },
      };
      state.sportType = sportType;
      state.status = 'succeeded';
    },
    [fetchPartnerClubs.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const playersNearby = createSlice({
  name: 'playersNearby',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPlayersNearby.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchPlayersNearby.fulfilled]: (state, action) => {
      const { page, data, sportType } = action.payload || {};
      state.data = {
        ...data,
        ...page > 1 && {
          results: [...new Set([...(state?.data?.results || []), ...(data?.results || [])])],
        },
      };
      state.sportType = sportType;
      state.status = 'succeeded';
    },
    [fetchPlayersNearby.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const otherTopPicks = createSlice({
  name: 'otherTopPicks',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {},
  extraReducers: {
    [fetchOtherTopPicks.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOtherTopPicks.fulfilled]: (state, action) => {
      const { data, sportType } = action.payload || {};
      state.data = data;
      state.sportType = sportType;
      state.status = 'succeeded';
    },
    [fetchOtherTopPicks.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const summary = createSlice({
  name: 'summary',
  initialState: {
    data: {},
    unseenRequests: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    seenRequests: (state) => {
      state.unseenRequests = false;
    },
    changeSummaryTotals: (state, action) => {
      const { matches, requests } = action?.payload || {};
      state.data.totalMatches += matches;
      state.data.totalRequests += requests;
    },
  },
  extraReducers: {
    [fetchSummary.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSummary.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.unseenRequests = !!action.payload?.unseenRequests;
      state.status = 'succeeded';
    },
    [fetchSummary.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { seenRequests, changeSummaryTotals } = summary.actions;

const reducer = combineReducers({
  friendlyMatches: friendlyMatches.reducer,
  raisedHands: raisedHands.reducer,
  playersNearby: playersNearby.reducer,
  otherTopPicks: otherTopPicks.reducer,
  partnerClubs: partnerClubs.reducer,
  summary: summary.reducer,
});

export default reducer;
