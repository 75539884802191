import PubSub from 'pubsub-js';
import { post } from '../../components/common/http';

export const authorizePayment = async (props) => {
  const {
    payload,
    addToast,
    setSubmitting,
    t,
  } = props;

  try {
    await post('/payments/authorize', { data: payload });

    // setSubmitting(false);
  } catch (error) {
    setSubmitting(false);

    addToast(t(error?.response?.data?.message || 'COULD_NOT_AUTHORIZE_PAYMENT'), {
      appearance: 'error',
      autoDismiss: true,
    });

    PubSub.publish('api-error-handler', {
      message: error?.response?.data?.message,
      hasNotification: true,
    });
  }
};
