import styled from '@emotion/styled';

export const PlayerStyle = styled.div`
  border-bottom: ${props => ({ theme }) => (props.border ? `1px solid ${theme.colors?.lightBlue2}` : '')};
  display:flex;
  align-items:center;
  padding-bottom:20px;
  margin-bottom:10px;
  justify-content:space-between;

  p{ margin:0;}

  .rank{
    max-width:47px;
    width:100%;
  }

  @media screen and (max-width:1024px){
    .mainPlayerInfo{
      max-width:300px !important;
    }
  }

  @media screen and (max-width:768px){
width:700px;
}

.rank{
  display:flex;
  justify-content:space-between;
  align-items:center;
  div{
    display:flex;
    p{
      margin-left:4px;
    }
  }
}

.mainPlayerInfo{
max-width:560px;
width:100%;
display:flex;
align-items:center;

.imageWrapp{
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border: 1px solid #EAF6F6;
  border-radius: 100%;
}

.levelWrapp{
  position:absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom:-12px;
  text-align: center;
  width:20px;
}

.info{
  margin-left:10px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  span{
    color:${({ theme }) => theme.colors?.darkTurquoise};
    font-weight:400;
  }
}

.avatarImg{
    margin:0;
    object-fit: cover;
    border-radius:50%;
    width: 60px;
    height: 60px;
  }
}

.points{
  max-width:50px;
  width:100%;
  text-align:center;
}

.participations{
  max-width:90px;
  width:100%;
  text-align:center;
}

.elo{
  max-width:74px;
  width:100%;
  text-align:center;
}
  
.friendlyMatches{
  max-width:120px;
  width:100%;
  text-align:center;
}

.player__avatar {
  width: ${(props) => props.theme.functions?.toEm(60)};
  height: ${(props) => props.theme.functions?.toEm(60)};
  align-items: flex-end;
  margin: 0 auto;
  position: relative;

  &--wrapp {
    width: 100%;
    height: 100%;
  }

  &--default {
    border: 1px solid ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

}
`;
