const setWinnerOfTheSet = ({
  opponentPoints: { points: opponentPoints = 0, tie: opponentTie = 0 } = {},
  playerPoints: { points: playerPoints = 0, tie: playerTie = 0 } = {},
}) => {
  let winner;
  if (opponentPoints > playerPoints) {
    winner = 'opponent';
  }
  if (playerPoints > opponentPoints) {
    winner = 'player';
  }
  if (opponentPoints === playerPoints) {
    if (opponentTie !== playerTie) {
      winner = opponentTie > playerTie ? 'opponent' : 'player';
    } else {
      winner = false;
    }
  }
  return winner;
};

const getWinner = ({
  player: { score = [], type },
  opponent: { score: opponentScore = [], type: opponentType },
}) => {
  let setsWonByPlayer = 0;
  let setsWonByOpponent = 0;

  if (type === 'BYE' && opponentType !== 'BYE') {
    return 'opponent';
  }

  if (type !== 'BYE' && opponentType === 'BYE') {
    return 'player';
  }

  [...Array(3)].forEach((_, index) => {
    const [playerPoints] = score?.slice()
      ?.filter(({ set }) => set === index + 1)
      ?.map(({ points = 0, tie = 0 }) => ({
        points: Number(points),
        tie: Number(tie),
      }));

    const [opponentPoints] = opponentScore?.slice()
      ?.filter(({ set }) => set === index + 1)
      ?.map(({ points = 0, tie = 0 }) => ({
        points: Number(points),
        tie: Number(tie),
      }));

    const winnerOfTheSet = setWinnerOfTheSet({
      opponentPoints,
      setsWonByOpponent,
      playerPoints,
      setsWonByPlayer,
    });

    if (winnerOfTheSet === 'player') {
      setsWonByPlayer += 1;
    }

    if (winnerOfTheSet === 'opponent') {
      setsWonByOpponent += 1;
    }

    return false;
  });

  // if match type is single set calculate winner only for 1st set
  if (score?.length === 1 && (setsWonByPlayer > 0 || setsWonByOpponent > 0)) {
    return setsWonByPlayer > setsWonByOpponent ? 'player' : 'opponent';
  }

  if (setsWonByPlayer >= 2) {
    return 'player';
  }

  return setsWonByOpponent >= 2 ? 'opponent' : 'error';
};

export default getWinner;
