import styled from '@emotion/styled';
import { P } from '../../../../../components/Collection';
import { AvatarContainer } from '../../../../Events/JoinCompetition/components/Search/styles';

export const PlayerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  maxHeight: '245px',
  width: '100%',
});

export const ContainAvatar = styled(AvatarContainer)({
  margin: '0 0 10px 0',

  '.game-level': {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    bottom: -5,
    width: 40,
    display: 'flex',
    flexDirection: 'row',
  },
});

export const PlayersContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, calc(25% - 25px))',
  gap: '30px',
  '@media (max-width: 1199px)': {
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fit, calc(33% - 20px))',
  },

  '@media (max-width: 991px)': {
    gridTemplateColumns: 'repeat(auto-fit, calc(50% - 15px))',
  },

  '@media (max-width: 479px)': {
    justifyContent: 'initial',
    gridTemplateColumns: 'initial',
  },
  margin: '10px 0 0 0',
});

export const Location = styled(P)({
  margin: '0 0 10px 0',
});

export const Distance = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '5px 10px',
  border: '1px solid #EAF6F6',
  borderRadius: '70px',
});

export const DistanceRadiusIcon = styled.img({
  margin: '0 5px 0 0',
});

export const FullName = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 150,
});
