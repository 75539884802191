import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from '../../../components';
import H3 from '../../../components/H3';
import { Wrapper } from './styles';
import { logout } from '../../../components/Layout/reducers/session';
import { removeCompetitions } from '../../Dashboard/components/UpcomingCompetitions/reducers';
import mixpanel from '../../../mixpanel';

const Logout = () => {
  const { t } = useTranslation('accounts');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(removeCompetitions());
    mixpanel.reset();
    history.push('/');
  };

  return (
    <Wrapper className="text-center">
      <div className="container">
        <H3 className="mb20">{ t('logout.wantToLogout') }</H3>
        <p className="mb50">{ t('logout.logoutParagraph') }</p>
        <div className="buttons">
          <Button outline onClick={() => history.goBack()}>
            <span className="cursor-pointer">{ t('logout.btnCancel') }</span>
          </Button>
          <Button black onClick={handleLogout}>
            <span className="cursor-pointer">{ t('logout.btnLogout') }</span>
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Logout;
