import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';

import { Container, NoNotificationsAvailable, NotificationPanel } from './styles';
import { H1, H3, H4 } from '../../../components';
import Notification from '../../../components/Layout/components/Header/components/component/Notification';
import Pagination from '../../../components/Pagination';
import ContentLoader from '../../../components/ContentLoader';

import { fetchNotifications } from '../../../components/Layout/reducers/notifications';

const Notifications = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const { data: list, status } = useSelector(state => state.dashboard?.notifications.list);
  const { t } = useTranslation('accounts');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchNotifications());
    }
  }, [status, dispatch]);

  const handlePageClick = (e) => {
    const page = e.selected + 1;

    if (currentPage !== e.selected) {
      dispatch(fetchNotifications({ page }));
      setCurrentPage(e.selected);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{t('notifications.seoTitle')}</title>
      </Helmet>

      {!isEmpty(list?.results) ? (
        <>
          <H1><Trans ns="accounts" i18nKey="notifications.title">Notifications</Trans></H1>

          <NotificationPanel>
            <H3><Trans ns="accounts" i18nKey="notifications.subtitle">Latest Notifications</Trans></H3>

            <div className="notificationsWrap">
              {status === 'loading' ? (
                <ContentLoader title type="transactions" items={5} />
              ) : (
                <>
                  {!isEmpty(list?.results) && status !== 'loading' ? (
                    <>
                      {list.results.map((notification, idx) => (
                        <Notification key={idx} notification={notification} />
                      ))}

                      {list?.totalPages > 1
                        && (
                          <Pagination
                            pageCount={list?.totalPages || 0}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            initialPage={currentPage}
                          />
                        )}
                    </>
                  ) : (
                    <div className="noTransactionsWrapp">
                      <H4><Trans ns="accounts" i18nKey="notifications.noNotifications">No notifications</Trans></H4>
                    </div>
                  )}
                </>
              )}
            </div>
          </NotificationPanel>
        </>
      ) : (
        <NoNotificationsAvailable>
          <H4><Trans ns="accounts" i18nKey="notifications.noNotifications">No notifications</Trans></H4>
        </NoNotificationsAvailable>
      )}
    </Container>

  );
};

export default Notifications;
