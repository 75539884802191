import React from 'react';
import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import Paragraph from '../../../../../Paragraph';
import Link from "../../../../../Link";

const Notification = styled.div`
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #EAF6F6;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .2s all ease-out;
  border-radius: 5px;
  
  &:last-of-type {
    margin-bottom: 0;
  },

  &.unseen {
    background: #7CC9C64F;
    border-radius: 10px;
  };

  .seeDetails {
    display: inline-flex;
    color: #7cc9c6;
    font-weight: bold;
  }

  a {
    display: inline-flex;
    text-decoration: none;
  }

  &:hover {
    background: #EAF6F6;
  }
`;

const SeenBox = styled.div`
  text-align: center;
  padding-top: 4px;
  width: 30px;
  min-width: 20px;

  .bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: ${props => (props.seen ? 'transparent' : '#F42B03')};
    border-radius: 100%;
  }
`;

const NotificationDropdown = ({ notification }) => {
  const { t } = useTranslation('events');
  const { seen } = useSelector(state => state.dashboard.notifications.list);

  let detailsLink;
  if (['topUp', 'penaltyAnnouncedWithdrawal', 'newRewardReceived'].includes(notification.type)) {
    detailsLink = '/my-account/wallet';
  }

  if ([
    'gameLevelChangedByAdmin',
    'eventRegistrationBan',
    'penaltyAdministrative',
    'playerRatingReceived',
  ].includes(notification.type)) {
    detailsLink = '/my-account';
  }

  if ([
    'teamWithdrawalByAdmin',
    'registrationByAdminOnSLForDoubles',
    'registrationByAdminOnSLForSingles',
    'registrationByAdminOnWLForDoubles',
    'registrationByAdminOnWLForSingles',
    'registrationByAdminOnMLForDoubles',
    'registrationByAdminOnMLForSingles',
    'promotionToMLForDoubles',
    'promotionToMLForSingles',
    'playerWithdrawalByAdmin',
    'teamWithdrawalByAdmin',
  ].includes(notification.type)) {
    detailsLink = `/events/${notification?.eventId}/competitions/${notification?.competitionId}`;
  }

  if ([
    'eventRegistrationSLOpen',
    'eventRegistrationSLClosed',
    'eventRegistrationMLOpen',
    'eventRegistrationMLClosed',
    'eventMainDrawPublished',
    'eventStarted',
    'eventEnded',
    'eventClosed',
    'eventAnnouncementUpdates',
  ].includes(notification.type)) {
    detailsLink = `/events/${notification?.eventId}`;
  }

  if ([
    'teamWithdrawalFromDoubles',
    'friendRequestAccepted',
    'friendJoined',
    'friendRaisedHand',
  ].includes(notification.type)) {
    detailsLink = `/accounts/${notification?.partnerId}`;
  }

  if (notification.type === 'friendRequestReceived') {
    detailsLink = '/friends?tab=received';
  }

  if ([
    'invitationReceived',
    'pastMatchInvite',
  ].includes(notification.type)) {
    detailsLink = '/friendly-matches/requests';
  }

  if ([
    'matchAccepted',
    'matchDeclined',
    'matchDenied',
    'playerWithdrew',
    'addScoreReminder',
    'scoreAdded',
    'scoreChanged',
  ].includes(notification.type)) {
    detailsLink = '/friendly-matches/available';
  }

  if ([
    'scoreValidated',
  ].includes(notification.type)) {
    detailsLink = '/friendly-matches/scores';
  }

  if ([
    'pastMatchConfirmed',
    'canceledMatch',
    'updatedMatch',
    'upcomingMatchReminder',
  ].includes(notification.type)) {
    detailsLink = `/friendly-matches/${notification.ctaLink}`;
  }

  if (['bookingCancellation', 'bookingRescheduled'].includes(notification.type)) {
    detailsLink = `/my-account/bookings/${notification.params._id}`;
  }

  return (
    <Notification {...!notification.seen && !seen && { className: 'unseen' }}>
      <SeenBox seen={notification.seen}>
        {!seen && (
          <span className="bullet" />)}
      </SeenBox>

      <Link to={detailsLink} className="d-flex flex-column">
        <Paragraph smaller className="mb0">{dateFormat(notification.createdAt, 'dd mmmm yyyy, HH:MM')}</Paragraph>
        <Paragraph small>
          <Trans ns="accounts" i18nKey={`notifications.${notification.type}`}>
            <span className="seeDetails">See details</span>
            {{ gameLevelForSingles: notification.gameLevel?.singles || notification?.gameLevelSingle }}
            {{ gameLevelForDoubles: notification.gameLevel?.doubles || notification?.gameLevelDouble }}
            {{ sportType: notification.sportType }}
            {{ eventId: notification.eventId }}
            {{ eventName: notification?.eventName }}
            {{ competitionId: notification.competitionId }}
            {{ partnerId: notification.partnerId }}
            {{ partnerName: notification.partnerName }}
            {{ matchGameType: t(notification.matchGameType) }}
            {{ matchFormatedId: notification.matchFormatedId }}
            {{ clubName: notification?.params?.clubName }}
            {{ bookingId: notification?.params?.bookingId }}
          </Trans>
          {/* eslint-disable-next-line */}
          {notification.announcement && <div dangerouslySetInnerHTML={{ __html: parse(notification.announcement) }} />}
        </Paragraph>
      </Link>
    </Notification>
  );
};

export default NotificationDropdown;
