import React from 'react';
import { Trans } from 'react-i18next';

const GoogleMapsComponent = ({ coords }) => {
  if (coords) {
    return (
      <iframe
        title="Event Location"
        id="map"
        iframe="true"
        src={`https://maps.google.com/maps?q=${coords?.lat},${coords?.lng}&z=16&output=embed`}
      />
    );
  }

  if (!coords) {
    return (
      <span>
        <Trans ns="common" i18nKey="missingLocation">
          Location was not set yet
        </Trans>
      </span>
    );
  }
};

export default GoogleMapsComponent;
