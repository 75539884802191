import styled from '@emotion/styled';

export const MatchContainer = styled.div(({ expanded }) => ({
  display: 'flex',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  padding: '20px',
  position: 'relative',
  alignItems: 'flex-start',
  paddingBottom: expanded ? 80 : 20,

  '&:not(last-of-type)': {
    marginBottom: '10px',
  },

  '@media (max-width: 1199px)': {
    flexDirection: 'column',
    alignItems: 'unset',
    height: 'unset',
    padding: '20px',
    width: '100%',
    margin: '0 auto',
  },
  '.reported-match': {
    color: '#F42B03',
  },
}));

export const FirstPart = styled.div({
  display: 'flex',
  width: '40%',

  '@media (max-width: 1199px)': {
    margin: '10px 0 10px 0',
    width: 'auto',
  },
});

export const SecondPart = styled.div({
  display: 'flex',
  width: '35%',
  paddingLeft: 50,
  alignItems: 'baseline',
  justifyContent: 'space-between',
  '@media (max-width: 1199px)': {
    margin: '0',
    width: 'auto',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    paddingLeft: 0,
  },
});

export const ThirdPart = styled.div(({ expanded }) => ({
  width: '25%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: expanded ? 'end' : 'space-between',
  padding: '0 0 0 50px',
  '@media (max-width: 1199px)': {
    margin: '10px 0',
    padding: '0',
    width: 'auto',
    alignItems: 'start',
    justifyContent: 'start',
    display: expanded ? 'none' : 'flex',
  },
}));

export const MatchType = styled.img({
  width: '48px',
  height: '48px',
  margin: '0 10px 0 0',
  '@media (max-width: 1199px)': {
    display: 'none',
  },
});

export const Checkmark = styled.img({
  width: '12px',
  height: '12px',
  margin: '0 0 0 4px',
});

export const RankedIcon = styled.img({
  width: '16px',
  height: '16px',
  margin: '0 0 0 4px',
});

export const RankedText = styled.span({
  fontWeight: 'normal',
  '@media (min-width: 1200px)': {
    display: 'none',
  },
});

export const PlayerMetaData = styled.div(({ autoLeftMargin, justifyCenter }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...autoLeftMargin && {
    margin: '0 0 0 auto',
  },
  ...justifyCenter && {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 1225px)': {
    margin: 'unset',
    alignItems: 'unset',
    justifyContent: 'unset',
    width: '100%',
  },
}));

export const OpponentMetaData = styled.div({
  margin: '0 120px 0 0',

  '@media (max-width: 1199px)': {
    margin: '0',

    '.opponent-title': {
      margin: '10px 0 10px 0',
    },
  },
});

export const ScoreElement = styled.span(({ bold }) => ({
  fontWeight: bold ? 'bold' : 'normal',
}));

export const OpponentScore = styled.div({
  textAlign: 'center',
  minWidth: 100,
  lineHeight: 1,

  sup: {
    position: 'absolute',
    marginTop: -10,
  },

  '@media (max-width: 1199px)': {
    margin: '20px 0 0 0',
    textAlign: 'left',

    '> div': {
      justifyContent: 'space-between',
      marginTop: 10,
    },

    p: {
      marginBottom: 0,
    },
  },
});

export const MatchStatus = styled.div({
  flexGrow: 2,

  '@media (max-width: 1199px)': {
    marginTop: 20,
  },
});

export const WLBox = styled.span(({
  win, lose, top, margin,
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  background: win ? '#8FFFA7' : lose ? '#FF8298' : '#EAF6F6',
  top: top ?? '0',
  position: 'relative',
  margin: margin ?? '0',
  height: '28px',

  minWidth: '28px',
  minHeight: '28px',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',

  ...lose && {
    color: '#fff',
  },
}));

export const Dropdown = styled.div(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  backgroundColor: 'white',
  top: 70,
  right: 0,

  maxWidth: '199px',
  zIndex: 1000,
}));

export const Details = styled.span(() => ({
  display: 'flex',
  justifyContent: 'center',

  '@media (min-width: 1200px)': {
    display: 'none',
  },

  '@media (max-width: 1199px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const ActivityButton = styled.div(({ mobileOnly, isExpanded }) => ({
  position: mobileOnly ? 'relative' : 'absolute',
  bottom: 0,
  right: mobileOnly ? 0 : 20,
  width: mobileOnly ? '100%' : 'calc(100% - 40px)',
  textAlign: 'right',
  borderTop: '1px solid #EAF6F6',
  padding: 20,

  '@media (max-width: 1199px)': {
    display: mobileOnly ? 'unset' : 'none',
    borderTop: 'none',
    textAlign: 'center',
    padding: '10px 0',

    ...(isExpanded && {
      padding: 0,
      display: 'flex',
      gridGap: 10,
      flexDirection: 'column',
      marginBottom: 10,
    }),
  },

  '@media (min-width: 1200px)': {
    display: mobileOnly ? 'none' : 'unset',
  },
}));

export const Bold = styled.span({ fontWeight: 700 });

export const ScoresContain = styled.div({
  flexDirection: 'column',

  '.bye': {
    position: 'absolute',
    marginLeft: -35,
    marginTop: 0,
  },

  sup: {
    position: 'absolute',
    marginTop: -10,
  },

  '@media (max-width: 1199px)': {
    width: '297px',

    '.player-data': {
      flexDirection: 'column',
      marginBottom: 20,

      '> div': {
        marginBottom: 0,
        justifyContent: 'left',

        '> div': {
          marginBottom: 10,
        },
      },
    },

    '.bye': {
      position: 'relative',
      marginLeft: 0,
    },
  },
});
