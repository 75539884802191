const timeToInteger = (time) => {
  const [hours, minutes] = time.split(":");
  const result = Number(hours + minutes);
  if (result === 0) {
    return 2400;
  }

  return result;
};

export default timeToInteger;
