export default {
  title: 'Log in',
  subTitle: 'Welcome back',
  seoTitle: 'Log In | Sportya',
  placeholders: {
    email: 'Email',
    password: 'Password',
  },
  forgotPassword: 'Forgot Password?',
  remember: 'Remember me',
  button: 'Log In',
  socialSignTitle: 'Or sign in with',
  noAccount: 'Don’t have an account?',
  termsAndConditions: 'By logging in, I confirm that I have read and I agree with the <1>Terms and Conditions</1> and <1>Privacy Policy</1>',
  form: {
    AUTH_FAILED: 'Invalid credentials.',
    something_wrong_happened: 'Something wrong happened.',
  },
};
