import PubSub from 'pubsub-js';
import {
  get,
  post,
} from '../../../../components/common/http';

const catchError = ({
  error,
  addToast,
  t,
  fallbackMessage = 'API_ERROR',
}) => {
  const message = error?.response?.data?.message ?? error?.message ?? fallbackMessage;

  addToast(t(message), {
    appearance: 'error',
    autoDismiss: true,
  });

  PubSub.publish('api-error-handler', {
    message,
    hasNotification: true,
  });
};

export const cancelBooking = async ({
  id,
  payload = {},
  setIsSubmitting,
  addToast,
  t,
}) => {
  try {
    await post(`/bookings/${id}/cancel`, payload);
  } catch (error) {
    setIsSubmitting(false);
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'FAILED_TO_CANCEL_BOOKING',
    });
  }
};

export const getBookingInfo = async ({ accountId, id }) => {
  try {
    const { data: { data } } = await get(`/accounts/${accountId}/bookings/${id}`);
    return data;
  } catch (error) {
    return false;
  }
};
