import React from 'react';
import { useSelector } from 'react-redux';

import { GroupStyle } from './styles';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';

import Paragraph from '../../../../../components/Paragraph';
import Level from '../Level';
import PlayerBadge from '../PlayerBadge';
import LoadImage from '../../../../../components/common/LoadImage';

const reasonOfLeave = {
  ABANDONED: <p>AB.</p>,
  NO_SHOW: <p>WO</p>,
};

const getScore = (main, player, opponent, key) => {
  const scores = {
    player,
    opponent,
  };

  return scores?.player?.[key] || scores?.opponent?.[key] ? scores?.[main]?.[key]?.points : null;
};

const Group = ({
  letter, type, groups, matches, phase,
}) => {
  const { accountInfo } = useSelector(state => state.session);

  const isFriend = (userId) => accountInfo?.friendsIds?.includes(userId);

  return (
    <GroupStyle phase={phase} absolute={type}>
      <Paragraph className="groupTitle" medium bold>
        Group
        {phase ? 'A' : letter}
      </Paragraph>
      <div className="groupCard">
        {/* list unique players */}
        <div className="mainPlayerList">
          <Paragraph className="rank" smaller>Rank</Paragraph>
          {groups?.map((player, idx) => (
            <div className="listPlayer" key={idx}>
              <div>
                <div className={`listPlayerInfo ${accountInfo?.userId === player?.userId ? 'you' : ''}`}>
                  <Level level={player?.level} small />
                  {player?.id
                    ? (
                      <a href={`/accounts/${player?.id}`} target="_blank" rel="noreferrer">
                        <Paragraph bold small>
                          {isFriend(player?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {player?.userId ? `${player?.firstName} ${player?.lastName}` : 'BYE'}
                        </Paragraph>
                      </a>
                    )
                    : (
                      <Paragraph bold small>
                        {isFriend(player?.userId) && (
                          <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                        )}
                        {player?.userId ? `${player?.firstName} ${player?.lastName}` : 'BYE'}
                      </Paragraph>
                    )}
                </div>
                {/* partner */}
                {player?.partner && (
                  <div className={`listPlayerInfo partner ${accountInfo?.userId === player?.userId ? 'you' : ''}`}>
                    <Level level={player?.partner?.level} small />
                    <Paragraph bold small>
                      {player?.partner?.id
                        ? (
                          <a href={`/accounts/${player?.partner?.id}`} target="_blank" rel="noreferrer">
                            {isFriend(player?.partner?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {player?.partner?.userId
                              ? `${player?.partner?.firstName} ${player?.partner?.lastName}`
                              : 'BYE'}
                          </a>
                        )
                        : (
                          <>
                            {isFriend(player?.userId) && (
                              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                            )}
                            {player?.partner?.userId
                              ? `${player?.partner?.firstName} ${player?.partner?.lastName}`
                              : 'BYE'}
                          </>
                        )}
                    </Paragraph>
                  </div>
                )}
              </div>
              <div className="listPlayerRankg"><Paragraph bold small>{idx + 1}</Paragraph></div>
            </div>
          ))}
        </div>

        {/* list matches */}
        {matches?.map((match, idx) => {
          const sortedPlayerScore = match?.player?.score?.slice()?.sort((a, b) => a?.set - b?.set);
          const sortedOpponentScore = match?.opponent?.score?.slice()?.sort((a, b) => a?.set - b?.set);
          return (
            <div key={idx} className="matchCard">
              {/* <Paragraph className="matchInfo">{match?.courtDetails}</Paragraph> */}
              <div className="playersWrapp">

                {/* player */}
                <div className="cardListPlayer">
                  <div className="cardListPlayerInfoWrapp">
                    <div className={`cardListPlayerInfo ${accountInfo?.userId === match?.player?.userId ? 'you' : ''}`}>
                      <Level level={match?.player?.level} small />
                      {/* <Paragraph bold={match?.winner === "player" ? true : false} small>
                      {match?.player?.type !== "BYE" ? match?.player?.firstName + " " + match?.player?.lastName : "BYE"}
                      </Paragraph> */}

                      <Paragraph bold={match?.winner === 'player'} small>
                        {match?.player?.id
                          ? (
                            <a href={`/accounts/${match?.player?.id}`} target="_blank" rel="noreferrer">
                              {isFriend(match?.player?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {!match?.player?.firstName && match?.player?.type !== 'PENDING'
                                ? 'BYE'
                                : (match?.player
                                  ? (`${match?.player?.firstName} ${match?.player?.lastName}`)
                                  : '')}
                            </a>
                          )
                          : (
                            <>
                              {isFriend(match?.player?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {!match?.player?.firstName && match?.player?.type !== 'PENDING'
                                ? 'BYE'
                                : (match?.player
                                  ? (`${match?.player?.firstName} ${match?.player?.lastName}`)
                                  : '')}
                            </>
                          )}
                      </Paragraph>

                      <div className="relative">{match?.winner === 'player' && <div><PlayerBadge /></div>}</div>
                      {/* <div className="relative">
                      {match?.winner === "player" && <div className={type && "trophyIcon"}><PlayerBadge /></div>}
                      </div> */}
                    </div>
                    {type ? (
                      <div
                        className={`
                        cardListPlayerInfo
                        partner
                        ${accountInfo?.userId === match?.playerPartner?.player?.userId ? 'you' : ''}
                        `}
                      >
                        <Level level={match?.playerPartner?.level} small />
                        {/* <Paragraph bold={match?.winner === "player" ? true : false} small>
                        {match?.playerPartner
                          ? match.playerPartner?.firstName + " " + match.playerPartner?.lastName
                          : "BYE"}
                        </Paragraph> */}

                        <Paragraph bold={match?.winner === 'player'} small>
                          {match?.playerPartner?.id
                            ? (
                              <a href={`/accounts/${match?.playerPartner?.id}`} target="_blank" rel="noreferrer">
                                {isFriend(match?.playerPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {!match?.playerPartner?.firstName && match?.playerPartner?.type !== 'PENDING'
                                  ? 'BYE'
                                  : (match?.playerPartner
                                    ? (`${match?.playerPartner?.firstName} ${match?.playerPartner?.lastName}`)
                                    : '')}
                              </a>
                            )
                            : (
                              <>
                                {isFriend(match?.playerPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {!match?.playerPartner?.firstName && match?.playerPartner?.type !== 'PENDING'
                                  ? 'BYE'
                                  : (match?.playerPartner
                                    ? (`${match?.playerPartner?.firstName} ${match?.playerPartner?.lastName}`)
                                    : '')}
                              </>
                            )}

                        </Paragraph>

                        <div className="relative">{match?.winner === 'player' && <div><PlayerBadge /></div>}</div>
                      </div>
                    ) : ''}
                  </div>

                  {match?.opponent?.reason && match?.player?.reason
                    ? reasonOfLeave[match?.player?.reason]
                    : (match?.opponent?.reason === 'NO_SHOW'
                      ? <div className="playerScore"><div className="emptyScore">-</div></div>
                      : (
                        <div className="scoreWrapp">
                          <div className="playerScore">
                            {sortedPlayerScore && sortedPlayerScore.map((y, key) => {
                              const biggerScore = sortedPlayerScore[key]?.points > sortedOpponentScore?.[key]?.points;
                              return (
                                <div key={key} className="positionRelative">
                                  {/* tie score */}
                                  {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                                  {/* main score */}
                                  <Paragraph
                                    key={y}
                                    small
                                    className={`
                                    ${y?.competition?.finalStage === y?.competition?.stage && y?.winner === 'player'
                                      ? 'goldText'
                                      : ''}
                                    score
                                    ${biggerScore ? 'boldText' : ''}
                                    `}
                                  >{getScore('player', sortedPlayerScore, sortedOpponentScore, key)}
                                  </Paragraph>
                                </div>
                              );
                            })}
                            {reasonOfLeave[match?.player?.reason]}
                          </div>
                        </div>
                      ))}
                </div>

                {/* opponent */}
                <div className="cardListPlayer">
                  <div className="cardListPlayerInfoWrapp">
                    <div className="cardListPlayerInfo">
                      <Level level={match?.opponent?.level} small />
                      {/* <Paragraph bold={match?.winner === "opponent" ? true : false} small>
                      {match?.opponent?.type !== "BYE"
                        ? match?.opponent?.firstName + " " + match?.opponent?.lastName
                        : "BYE"}
                      </Paragraph> */}
                      <Paragraph bold={match?.winner === 'opponent'} small>
                        {match?.playerPartner?.id
                          ? (
                            <a href={`/accounts/${match?.playerPartner?.id}`} target="_blank" rel="noreferrer">
                              {isFriend(match?.opponent?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {!match?.opponent?.firstName && match?.opponent?.type !== 'PENDING'
                                ? 'BYE'
                                : (match?.opponent
                                  ? (`${match?.opponent?.firstName} ${match?.opponent?.lastName}`)
                                  : '')}
                            </a>
                          )
                          : (
                            <>
                              {isFriend(match?.opponent?.userId) && (
                                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                              )}
                              {!match?.opponent?.firstName && match?.opponent?.type !== 'PENDING'
                                ? 'BYE'
                                : (match?.opponent
                                  ? (`${match?.opponent?.firstName} ${match?.opponent?.lastName}`)
                                  : '')}
                            </>
                          )}
                      </Paragraph>
                      {/* <div className="relative">
                      {match?.winner === "opponent" &&  <div className={type && "trophyIcon"}><PlayerBadge /></div>}
                      </div> */}
                      <div className="relative">{match?.winner === 'opponent' && <div><PlayerBadge /></div>}</div>
                    </div>
                    {type ? (
                      <div className="cardListPlayerInfo partner">
                        <Level level={match?.opponentPartner?.level} small />
                        {/* <Paragraph bold={match?.winner === "opponent" ? true : false} small>
                        {match?.opponentPartner
                          ? match.opponentPartner?.firstName + " " + match?.opponentPartner?.lastName
                            : "BYE"}
                            </Paragraph> */}
                        <Paragraph bold={match?.winner === 'opponent'} small>
                          {match?.opponentPartner?.id
                            ? (
                              <a href={`/accounts/${match?.opponentPartner?.id}`} target="_blank" rel="noreferrer">
                                {isFriend(match?.opponentPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {!match?.opponentPartner?.firstName && match?.opponentPartner?.type !== 'PENDING'
                                  ? 'BYE'
                                  : (match?.opponentPartner
                                    ? (`${match?.opponentPartner?.firstName} ${match?.opponentPartner?.lastName}`)
                                    : '')}
                              </a>
                            )
                            : (
                              <>
                                {isFriend(match?.opponentPartner?.userId) && (
                                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                                )}
                                {!match?.opponentPartner?.firstName && match?.opponentPartner?.type !== 'PENDING'
                                  ? 'BYE'
                                  : (match?.opponentPartner
                                    ? (`${match?.opponentPartner?.firstName} ${match?.opponentPartner?.lastName}`)
                                    : '')}
                              </>
                            )}
                        </Paragraph>

                        <div className="relative">{match?.winner === 'opponent' && <div><PlayerBadge /></div>}</div>
                      </div>
                    ) : ''}
                  </div>

                  {match?.opponent?.reason && match?.player?.reason
                    ? (
                      (matches?.length
                        && match?.opponent?.type === "PENDING"
                        && matches[0]?.player?.reason
                        && matches[0]?.opponent?.reason
                      ) ? "BYE" : reasonOfLeave[match?.player?.reason]
                    )
                    : (match?.player?.reason === "NO_SHOW" ? (
                      <div className="playerScore">
                        <div className="emptyScore">-</div>
                      </div>
                    ) : (
                      <div className="scoreWrapp">
                        <div className="playerScore">
                          {sortedOpponentScore && sortedOpponentScore.map((y, key) => (
                            <div key={key} className="positionRelative">
                              {/* tie score */}
                              {y.tie && <Paragraph smaller className="tieScore">{y?.tie}</Paragraph>}
                              <Paragraph
                                key={y}
                                small
                                className={`
                                    score
                                    ${y?.competition?.finalStage === y?.competition?.stage
                                    && y?.winner === "opponent"
                                  ? 'goldText' : ''}
                                    ${sortedOpponentScore[key]?.points > sortedPlayerScore[key]?.points
                                    ? 'boldText' : ''}
                                  `}
                              >
                                {getScore('opponent', sortedPlayerScore, sortedOpponentScore, key)}
                              </Paragraph>
                            </div>
                          ))}
                          {reasonOfLeave[match?.opponent?.reason]}
                        </div>
                      </div>
                    )
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </GroupStyle>
  );
};

export default Group;
