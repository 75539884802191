import React from 'react';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from "react-redux";

// import ContentLoader from "../../../../components/ContentLoader";
import Link from '../../../../components/Link';
import { Paragraph, H3, Button } from '../../../../components';
import { Content, ListArticles, Article } from './styles';

const Guides = () => {
  const { t } = useTranslation('dashboard');

  const data = [{}, {}];

  const guides = [
    {
      title: t('guides.1.title'),
      text: t('guides.1.text'),
      data: '25 Aug 2020',
      link: '/rules-regulations',
    },
    {
      title: t('guides.2.title'),
      text: t('guides.2.text'),
      data: '25 Aug 2020',
      link: '/groups-playing-formats',
    },
    {
      title: t('guides.3.title'),
      text: t('guides.3.text'),
      data: '25 Aug 2020',
      link: '/local-leagues-rules',
    },
  ];

  return (
    <>
      {!isEmpty(data) && (
        <Content>
          <H3 className="mb20 title">
            <Trans ns="dashboard" i18nKey="websiteGuides">
              {{ siteTitle: process.env.REACT_APP_SITE_NAME }}
              {' '}
              Guides
            </Trans>
          </H3>

          <ListArticles>
            {guides.map((guide, key) => (
              <Article key={key}>
                {/* <Paragraph smaller>{guide.data}</Paragraph> */}
                <div className="description">
                  <Link to={guide.link}>
                    <Paragraph large bold>{guide.title}</Paragraph>
                  </Link>
                  <Paragraph>{guide.text}</Paragraph>
                </div>
                <Button
                  small
                  outline={guide.link !== "/local-leagues-rules"}
                  className="mt20"
                  disabled={guide.link === "/local-leagues-rules"}
                >
                  <Link to={guide.link}>
                    <Trans
                      ns="dashboard"
                      i18nKey={guide.link === "/local-leagues-rules" ? "pendingUpdate" : "learnMore"}
                    >
                      Learn more
                    </Trans>
                  </Link>
                </Button>
              </Article>
            ))}
          </ListArticles>
        </Content>
      )}
    </>
  );
};

export default Guides;
