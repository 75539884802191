import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PubSub from 'pubsub-js';

import { get } from '../../../components/common/http';

// Actions
export const fetchMyEvents = createAsyncThunk('accounts/fetchMyEvents', async ({ id, activeTab, options }) => {
  try {
    const filterParams = new URLSearchParams(Object.assign(options)).toString();
    const { data: { data } } = await get(`/accounts/${id}/events/${activeTab}?${filterParams}`);
    return { results: data, type: activeTab };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// Slice
const slice = createSlice({
  name: 'myEvents',
  initialState: {
    joined: {
      status: 'idle',
      data: [],
    },
    lookingForPartners: {
      status: 'idle',
      data: [],
    },
    followed: {
      status: 'idle',
      data: [],
    },
    closed: {
      status: 'idle',
      data: [],
    },
    withdrawals: {
      status: 'idle',
      data: [],
    },
    generalStatus: 'idle',
    error: null,
  },
  reducers: {
    clearMyEvents: (state, action) => {
      const { type } = action?.payload || {};
      if (type) {
        state[type] = {
          status: 'idle',
          data: [],
        };
        state.error = null;
      }
    },
  },
  extraReducers: {
    [fetchMyEvents.pending]: (state) => {
      state.generalStatus = 'loading';
    },
    [fetchMyEvents.fulfilled]: (state, action) => {
      const { results, type } = action?.payload || {};
      if (type) {
        state.generalStatus = 'succeeded';
        state[type].status = 'succeeded';
        state[type].data = results || [];
      }
    },
    [fetchMyEvents.rejected]: (state, action) => {
      state.generalStatus = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { clearMyEvents } = slice.actions;
export default slice.reducer;
