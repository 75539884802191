import React from 'react';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

import { P, Link } from '../../../../components/Collection';
import {
  Avatar, ContainAvatar, ID, Initials, JoinedDate, JoinedDateText, Player,
} from './styles';

import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import LoadImage from '../../../../components/common/LoadImage';
import LevelsBadges from '../../../../components/LevelsBadges';
import { FriendsIcon } from '../../../Friends/components/FriendCard/styles';
import RaiseHand from '../../../../components/Layout/components/Header/components/RaiseHand';

const PlayerCard = ({ handRaised, playerInfo = {} }) => {
  const { accountInfo } = useSelector(state => state?.session);
  const {
    _id,
    userId,
    firstName,
    lastName,
    gameLevel,
    location,
    profilePicture,
    birthDate,
    createdAt,
  } = playerInfo;

  return (
    <Player>
      {handRaised && (
        <RaiseHand playerInfo={playerInfo} />
      )}

      {!handRaised && (
        <JoinedDate>
          <JoinedDateText xSmall bold>
            <Trans ns="player" i18nKey="joined">Joined</Trans>
            :
            {' '}
            <time>{dateFormat(createdAt, 'd mmm')}</time>
          </JoinedDateText>
        </JoinedDate>
      )}
      <ContainAvatar to={`/accounts/${_id}`}>
        {profilePicture
          ? (
            <Avatar
              src={LoadImage(`accounts/${userId}/${profilePicture}`, true)}
              alt={`${firstName.charAt(0)} ${lastName.charAt(0)}`}
            />
          ) : (
            <Initials>
              <P large bold>
                {firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ''}
              </P>
            </Initials>
          )}
        <LevelsBadges
          white
          small
          level={gameLevel?.singles ?? '?'}
          levelDouble={gameLevel?.doubles ?? '?'}
          className="badge"
        />
      </ContainAvatar>

      <Link to={`/accounts/${_id}`} className="Player Name">
        <P bold className="ellipsis">
          {accountInfo?.friendsIds?.includes(userId) && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          {`${firstName} ${lastName}`}
          <ID>
            #
            {userId}
          </ID>
        </P>
      </Link>
      {birthDate && location?.name ? (
        <P small>
          {birthDate ? `${getAgeFromDate(birthDate)} - ` : ''}
          {location?.name || ''}
        </P>
      ) : ''}
    </Player>
  );
};

export default PlayerCard;
