import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Modal, Paragraph } from '../../../../../components';
import { formatMatchId } from '../../../helpers';

const modalMessages = {
  accept: 'modalAcceptMatch',
  decline: 'modalDeclineMatch',
  cancel: 'modalCancelMatch',
  noMatchFromRequestsTab: 'modalNoMatchFromRequests',
  noMatchFromMatchesTab: 'modalNoMatchFromMatches',
  withdraw: 'modalWithdraw',
};

const buttonsMessage = {
  first: {
    accept: 'cancel',
    decline: 'cancel',
    cancel: 'goBack',
    noMatchFromRequestsTab: 'cancel',
    noMatchFromMatchesTab: 'cancel',
    withdraw: 'cancel',
  },
  second: {
    accept: 'accept',
    decline: 'decline',
    cancel: 'cancelMatch',
    noMatchFromRequestsTab: 'mark',
    noMatchFromMatchesTab: 'mark',
    withdraw: 'withdraw',
  },
};

const AcceptDeclineCancelModal = ({ modalData }) => {
  const { actions, type } = modalData || {};
  const buttonHasDeclineState = [
    'decline', 'cancel', 'noMatchFromRequestsTab', 'noMatchFromMatchesTab', 'withdraw',
  ].includes(type);

  return (
    <Modal
      isOpen
      onRequestClose={() => actions?.cancelModal()}
      className="report-match-modal"
    >
      <div className="modal-header">
        <Paragraph larger bold className="text-center">
          <Trans ns="friendlyMatches" i18nKey={`${modalMessages[type]}Title`}>{`${modalMessages[type]}Title`}</Trans>
        </Paragraph>
      </div>
      <div className="modal-content">
        <Paragraph className="text-center mb40">
          <Trans ns="friendlyMatches" i18nKey="matchId">
            Match ID:
            {{ matchId: formatMatchId(modalData?.match?.matchId) }}
          </Trans>
        </Paragraph>

        <Paragraph large className="text-center">
          <Trans ns="friendlyMatches" i18nKey={`${modalMessages[type]}Subtitle`}>
            {`${modalMessages[type]}Subtitle`}
          </Trans>
        </Paragraph>
      </div>

      <div className="modal-footer">
        <Button outline fullwidth onClick={() => actions?.first()} className="mr10">
          <span>
            <Trans ns="friendlyMatches" i18nKey={buttonsMessage.first[type]}>
              {buttonsMessage.first[type]}
            </Trans>
          </span>
        </Button>
        <Button {...{ red: buttonHasDeclineState }} fullwidth onClick={() => actions?.second()}>
          <span>
            <Trans ns="friendlyMatches" i18nKey={buttonsMessage.second[type]}>
              {buttonsMessage.second[type]}
            </Trans>
          </span>
        </Button>
      </div>
    </Modal>
  );
};

export default AcceptDeclineCancelModal;
