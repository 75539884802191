import styled from '@emotion/styled';
import ComponentContainer from '../../../components/Container';

export const Container = styled(ComponentContainer)`
padding: 40px 0 40px 0;
${props => props.theme.functions?.mediaQueryMax('600px', `
  h1
  {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAF6F6;
  }
`)}
`;

export const PlayerSettings = styled.div`
display: flex;
padding-top: 30px;

${props => props.theme.functions?.mediaQueryMax('600px', `
  display: grid;
`)};
`;

export const SettingsMenu = styled.div`
${props => props.theme.functions?.mediaQueryMax('600px', `
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #EAF6F6;
  margin-bottom: 10px;
`)};

> ul
{
  list-style: none;
  margin-right: 60px;

  ${props => props.theme.functions?.mediaQueryMax('600px', `
    margin-right: 0px;
  `)}

  .active
  {
    background: ${({ theme }) => theme.colors?.lightBlue};

    >p
    {
      color: black;
    }
  }

  > li
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 236px;
    border-radius: 4px;

    > p
    {
      color: ${({ theme }) => theme.colors?.darkTurquoise};
      ${props => props.theme.functions?.mediaQueryMax('600px', `
        width: 100%;
        text-align: center;
      `)}
    }
  }
}
`;
