import { Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { external } from '../../components/common/http';
import LoadImage from '../../components/common/LoadImage';
import { fetchTotalEventsInCountry } from '../Events/reducers';
import {
  Button,
  H1,
  H3,
  H4,
  Link,
  P,
} from '../../components/Collection';
import LangSelect from '../../components/Layout/components/LangSelect';
import {
  Container,
  Contain,
  CallToAction,
  Totals,
  Icon,
  PremiumBenefits,
  PremiumBenefitsContainer,
  RedText,
  Logo,
  BenefitIcon,
  Header,
} from './styles';

function Index({ isMobile }) {
  const dispatch = useDispatch();
  const { eventsInYourCountry } = useSelector(state => state.events.list);

  useEffect(() => {
    const fetchAPI = async () => {
      const { data } = await external.get('https://ipapi.co/json/');
      if (data?.country_name) {
        dispatch(fetchTotalEventsInCountry(data?.country_name));
      }
    };

    fetchAPI();
  }, []);

  return (
    <Container isPremium="true">
      <Header isPremium="true">
        <Link to="/">
          <Logo src={LoadImage('logo-white.svg')} alt="Sportya logo" />
        </Link>
        <LangSelect color="white" margin="0 0 0 auto" />
      </Header>
      <Contain isPremium="true">
        <H1 color="white" fontSize="48px" lineHeight="62px">
          <Trans ns="campaign" i18nKey="free.title">
            Get Premium and join Silver & Gold* events with
            {' '}
            <RedText>100% DISCOUNTED</RedText>
            {' '}
            Sportya Service Fee**
          </Trans>
        </H1>
        <H4
          color="white"
          fontSize="24px"
          lineHeight="31px"
          {...isMobile && { margin: '20px 0 0 0' }}
        >
          <Trans ns="campaign" i18nKey="free.subtitle">
            Access top-tier events like Platinum & Champions Tournament.
          </Trans>
        </H4>
        <CallToAction noBg="true">
          <Button
            {...isMobile && { margin: '0 auto 10px auto' }}
            color="white"
            background="#F42B03"
            padding="0 20px"
            maxWidth="290px"
            width="100%"
            noBorder="true"
          >
            <Link to="/plans">
              Get Premium
            </Link>
          </Button>
          <Totals hasBg="true">
            <Icon src={LoadImage('campaign/calendar.png')} />
            <P bold color="secondary">
              <Trans ns="campaign" i18nKey="free.eventsTotal">
                {{ amount: eventsInYourCountry || 0 }}
                {' '}
                Events in your country
              </Trans>
            </P>
          </Totals>
        </CallToAction>
        <PremiumBenefitsContainer>
          <PremiumBenefits>
            <BenefitIcon src={LoadImage('campaign/star.png')} />
            <H3 color="white" margin="12px 0">
              <Trans ns="campaign" i18nKey="free.list.item1.title">
                Top tier events
              </Trans>
            </H3>
            <P color="white" maxWidth="540px">
              <Trans ns="campaign" i18nKey="free.list.item1.desc">
                Join us at Grand Slam-like events for amateurs, with up to 200 players, in 6 countries.
                Rise in the rankings and compete with the best, at the  Champions Tournament.
              </Trans>
            </P>
          </PremiumBenefits>
          <PremiumBenefits>
            <BenefitIcon src={LoadImage('campaign/savings.png')} />
            <H3 color="white" margin="12px 0">
              <Trans ns="campaign" i18nKey="free.list.item2.title">
                Great savings
              </Trans>
            </H3>
            <P color="white" maxWidth="540px">
              <Trans ns="campaign" i18nKey="free.list.item2.desc">
                The more you play, the more you save. Get discounts up to 12$ with each entry.
              </Trans>
            </P>
          </PremiumBenefits>
        </PremiumBenefitsContainer>
        {isMobile && (
          <Button
            color="white"
            background="#F42B03"
            padding="0 20px"
            height="60px"
            width="100%"
            margin="0 0 40px 0"
            noBorder="true"
          >
            <Link to="/plans">
              Get Premium
            </Link>
          </Button>
        )}
        <P
          xSmall
          opacity={0.3}
          color="white"
        >
          <Trans ns="campaign" i18nKey="readme">
            *1st Serve, Silver, Gold, Platinum, Special, and Champions Tournament. Each event category offers
            different rewards in points and prizes, and provides a different level of challenge. For each event,
            we organize one or more competitions based on Game Levels, either for Singles or Doubles.

            **Sportya Service Fee is an online fee that represents the value of the services offered by the site in
            connection with the event. This helps us run our platform and offer services like customer support.
          </Trans>
        </P>
      </Contain>
    </Container>
  );
}

export default Index;
