import styled from '@emotion/styled';

export const Button = styled.button(props => ({
  display: props.display || 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ...props.position && {
    position: props.position,
    ...props.right && {
      right: props.right,
    },
    ...props.left && {
      left: props.left,
    },
    ...props.top && {
      top: props.top,
    },
    ...props.bottom && {
      bottom: props.bottom,
    },
  },

  ...props.maxWidth && { maxWidth: props.maxWidth },
  ...props.direction && { flexDirection: props.direction },
  ...props.minWidth && { minWidth: props.minWidth },
  ...props.width && { width: props.width },
  ...props.minHeight && { minHeight: props.minHeight },
  ...props.margin && { margin: props.margin },
  ...props.padding && { padding: props.padding },
  ...props.zIndex && { zIndex: props.zIndex },

  height: props.height ? props.height
    : props.xSmall ? '30px'
      : props.small ? '40px'
        : props.large ? '60px' : '50px',

  fontFamily: 'DM Sans',

  fontSize:
    props.xSmall ? props.theme.functions?.toEm(10)
      : props.small ? props.theme.functions?.toEm(12)
        : props.large ? props.theme.functions?.toEm(18) : props.theme.functions?.toEm(16),

  lineHeight:
    props.xSmall ? '15px'
      : props.small ? '18px'
        : props.large ? '24px' : '12px',

  fontWeight: '700',

  background: props.background ? props.background : (props.outline || props.noBorder) && !props.primary
    ? '#fff'
    : props.theme.colors.primary,

  color: props.color ? props.color : (props.outline || props.noBorder) && !props.primary
    ? props.theme.colors.primary
    : '#fff',

  ...!props.shadowColour && {
    border: props.border ? props.border : 0,
  },

  borderRadius: props.borderRadius ? props.borderRadius : '4px',

  userSelect: 'none',
  cursor: 'pointer',

  ...props.outline && {
    border: `2px solid ${props.shadowColour ? props.shadowColour : '#000'}`,
  },

  ...!props.noBorder && !props.outline && {
    boxShadow: `inset 0 0 0 2px ${props.border ? props.theme.colors?.[props.border] : props.theme.colors?.black}`,
  },

  a: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: (props.outline || props.noBorder) && !props.primary
      ? props.theme.colors.primary
      : '#fff',
    textDecoration: 'none',
  },

  ...!props.noImgMargin && {
    img: {
      margin: '0 10px',
    },
  },

  transition: 'all 0.3s ease-out',

  '&:hover': {
    ...!props.disabled && {
      ...!props.noBorder && !props.outline && { background: '#193642' },
      ...props.noBorder && props.activeHover ? {
        boxShadow: `inset 0 0 0 ${props.outline ? props.xSmall ? '1px' : '2px' : '3px'} 
        ${(props.outline && props.shadowColour)
          ? props.shadowColour
          : props.outline
          && !props.shadowColour
            ? props.theme.colors.primary
            : props.theme.colors.secondary}`,
        a: {
          transition: 'all 0.5s ease-out',
          color: props.outline ? props.theme.colors.primary : props.theme.colors.secondary,
        },
      } : !props.noBorder && {
        boxShadow: `inset 0 0 0 ${props.outline ? props.xSmall ? '1px' : '2px' : '3px'} 
        ${(props.outline && props.shadowColour)
          ? props.shadowColour
          : (props.outline && !props.shadowColour)
            ? props.theme.colors.primary
            : props.theme.colors.secondary}`,
        a: {
          transition: 'all 0.5s ease-out',
          color: props.outline ? props.theme.colors.primary : props.theme.colors.secondary,
        },
      },
    },
  },

  ...props.disabled && {
    background: 'rgb(193, 230, 229) none repeat scroll 0% 0%',
    boxShadow: 'none !important',
    cursor: 'not-allowed',
    color: 'rgb(255, 255, 255)',
    ...props.outline && {
      border: 'unset',
    },
  },
}));
