import React from 'react';
import { Trans } from 'react-i18next';
import { Contain, H3 } from '../../../../../../../components/Collection';
import { Badge as ShieldBadge } from '../../../../../../../components/LevelsBadges/styles';
import { TeamAVG } from './styles';

const index = ({ p1, p2 }) => (
  <TeamAVG>
    <div className="teamInfo">
      <p><Trans ns="events" i18nKey="teamGameLevel">Team Game Level</Trans></p>
      <Contain align="center" justify="center">
        <ShieldBadge double padding="0" fontSize="1.6em">
          <H3>{(p1 + p2) / 2}</H3>
        </ShieldBadge>
      </Contain>
    </div>
  </TeamAVG>

);

export default index;
