import React from 'react';
import { Trans } from 'react-i18next';

import { H2, H4 } from '../../../components';
import Paragraph from '../../../components/Paragraph';

import {
  Container,
  ComingSoon,
  FeatureBlock,
  Elipse,
} from './styles';

export const FeatureItem = ({
  title,
  desc,
  label,
  icon,
  type,
  hasAdditional,
  additionalText,
}) => (
  <Container>
    {type === '0' ? (
      <ComingSoon>
        <img src={icon} alt="" />
        {label && (
          <div className="worth-the-wait">
            <Trans ns="comingSoon" i18nKey="comingSoon">Coming Soon</Trans>
          </div>
        )}
        <H2>{title}</H2>
        {hasAdditional && (<Paragraph large bold>{additionalText}</Paragraph>)}
        <div className="mt20">
          <Paragraph medium>{desc}</Paragraph>
        </div>
      </ComingSoon>

    ) : (

      <FeatureBlock>
        <Elipse>
          <img src={icon} alt="" />
        </Elipse>
        {label && (
          <div className="worth-the-wait-1">
            <Trans ns="comingSoon" i18nKey="worthTheWait">Worth the Wait</Trans>
          </div>
        )}
        <H4>{title}</H4>
        {hasAdditional && (<Paragraph large bold>{additionalText}</Paragraph>)}
        <div className="mt20">
          <Paragraph>{desc}</Paragraph>
        </div>
      </FeatureBlock>

    )}
  </Container>
);

export default FeatureItem;
