import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  background: `#000 url(${LoadImage('notFound-visuals/bg.png')}) no-repeat center`,
});

export const Logo = styled.img({
  height: '60px',
  width: '160px',
});

export const Visual = styled.img({
  maxWidth: '750px',
  width: '100%',
  marginTop: '110px',
});
