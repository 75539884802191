import React from 'react';

import { Trans } from 'react-i18next';
import LoadImage from '../../../components/common/LoadImage';
import { P } from '../../../components/Collection';
import { Arrow, Container, Logo } from './styles';

const GettingStartedBanner = () => (
  <Container to="/for-players">
    <Logo src={LoadImage('getting-started-guide/logo.png')} />
    <P textDecoration="none" color="white">
      <Trans ns="dashboard" i18nKey="gettingStartedDescription">
        Enjoy all the features and make the first steps with
        {process.env.REACT_APP_SITE_NAME}
      </Trans>
    </P>
    <Arrow src={LoadImage('right-arrow-white.svg')} alt="Visit Guide" />
  </Container>
);

export default GettingStartedBanner;
