import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';

export const Container = styled.div(props => ({
  maxWidth: '1225px',
  margin: '0 auto',
  paddingBottom: '40px',
  '.mobile': {
    display: 'none',
  },
  '.tablet': {
    display: 'none',
  },
  '.desktop': {
    display: 'flex',
  },

  '@media (max-width: 1270px)': {
    margin: '0 20px',
    maxWidth: 'unset',
    '.eventListing': {
      flexDirection: 'column',
    },
    '.tablet': {
      display: 'flex',
    },
    '.minTablet': {
      display: 'none',
    },
  },

  '@media (max-width: 880px)': {
    '.mobile': {
      display: 'flex',
    },
    '.desktop': {
      display: 'none',
    },
  },

  ...props.grid && {
    maxWidth: 'unset',
    margin: '0 30px',
    '@media (max-width: 880px)': {
      margin: '0 10px',

      '.mobile': {
        display: 'flex',
      },
      '.desktop': {
        display: 'none',
      },
    },
  },
}));

export const ListHeader = styled.div({
  position: 'relative',
  marginTop: '40px',
  padding: '40px 0 12px 0',
  '@media (max-width: 880px)': {
    marginTop: 'unset',
    'p:first-of-type': {
      margin: 'unset',
    },
  },
});

export const EventsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '@media (max-width: 1270px)': {
    maxWidth: 'unset',
  },
});

export const ListFormat = styled.span(props => ({
  cursor: 'pointer',
  ...props.margin && { margin: props.margin },
  img: {
    filter: 'opacity(0.2)',
    ...props.active && {
      filter: 'opacity(1)',
    },
  },
}));

export const GroupedEvents = styled.div({
  position: 'relative',
});

export const Events = styled.div(props => ({
  '@media (max-width: 880px)': {
    margin: '30px 0 0 0',
  },
  '.groupedEvents:first-of-type': {
    '.eventsDate': {
      position: 'absolute',
      margin: 'unset',
      top: '-45px',
    },
  },
  '.eventsDate': {
    display: 'flex',
    alignItems: 'center',
    margin: '60px 0 10px',
    '&:before': {
      content: '""',
      display: 'block',
      border: '2px solid #7CC9C6',
      borderRadius: '10px',
      marginRight: '10px',
      height: '16px',
      transform: 'rotate(0deg)',
    },
  },
  ...props.grid && {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 347px)',
    gap: '26px',
    '@media (max-width: 880px)': {
      justifyContent: 'center',
      gridTemplateColumns: 'repeat(auto-fit, 330px)',
    },
  },
}));

export const CurrentPhaseStatusStyle = styled.section`
  display: flex;
  align-items: center;
  ${props => props.theme.functions?.mediaQueryMax('920px', `
  flex-direction: column;
  ${!props.center ? 'align-items: start;'
  : `padding-top: 25px;
     margin: 0 auto;
     max-width: 510px;`}
  `)}

  .playing__description {
    display: block;

    &__phase {
      display: flex;
      align-items: center;
    }

    span {
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
      }

      ${props => props.theme.functions?.mediaQueryMax('920px', `
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: ${!props.center ? 'left' : 'center'};
      `)}
      
      ${props => props.theme.functions?.mediaQueryMax('768px', `
        margin-left: 0;
      `)}
    }

    
    ul {
      display: inline-flex;
      overflow: hidden;

      ${props => props.theme.functions?.mediaQueryMax('920px', `
      display: flex;
      flex-direction: column;
      list-style-type: none;
     `)}

      li {
        float: left;
        margin-left: 30px;
        ${props => props.theme.functions?.mediaQueryMax('920px', `
        margin-left: unset !important;
        `)}
        
        &:first-of-type {
          list-style-type: none;
          margin-left: 10px;

          ${props => props.theme.functions?.mediaQueryMax('920px', `
          margin-left: 30px;
         `)}
        }
      }
    }
  }
`;

export const MaintenanceBar = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.theme.functions?.mediaQueryMax('920px', `
    flex-direction: column;
  `)}
  align-items: center;
  padding: 10px;
  height: max-content;
  width: 100%;
  border-radius: 4px;
  background: ${props => (props.age ? '#AAFFFC' : '#FFE071')};
  ${props => props.theme.functions?.mediaQueryMax('920px', `
  padding: 40px;
  height: min-content;
  text-align: center;
  `)}

  margin-bottom: 20px;

  img {
    width: 16.67px;
    height: 16.67px;
    margin-right: 10px;
  }`;

export const Hero = styled.div(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `#0a2e37 url(${LoadImage('bg-findplayer.png')}) bottom no-repeat`,
  backgroundSize: 'cover',
  padding: '170px 0 140px',
  maxHeight: '630px',
  height: '100%',
  '.showFeatured': {
    img: {
      margin: 'unset',
    },
    position: 'absolute',
    bottom: '-20px',
    zIndex: 1,
  },
  '@media (max-width: 1000px)': {
    maxHeight: 'unset',
    padding: '100px 0 73px',
    ...props.isBanned && {
      paddingTop: 150,
    },
    backgroundPosition: 'unset',
    'h2:first-of-type': {
      padding: '0 30px',
    },
  },
}));

export const Controls = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  '@media (max-width: 880px)': {
    textAlign: 'center',
    height: 'unset',
  },
});

export const ContainFeatured = styled.div(props => ({
  display: 'block',
  height: '0px',
  opacity: '0%',
  transition: 'all .2s ease-out;',
  overflow: 'hidden',

  '> div': {
    visibility: 'hidden',
    transition: 'visibility opacity 2s .2s ease-in',
  },

  ...props.displayFeatured && {
    height: '450px',
    opacity: '100%',
    '> div': {
      visibility: 'visible',
    },
  },
}));

export const NationalCalendar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '80px',
  '.countries': {
    button: {
      margin: '5px 5px',
      img: {
        margin: '0 10px 0 0',
      },
    },
    '.selected': {
      background: '#fff',
      color: '#10242c',
    },
    '@media (max-width: 880px)': {
      flexDirection: 'column',
      width: '100%',
      padding: '0 20px',
      button: {
        margin: '5px 0',
        width: '100%',
      },
    },
  },
  '@media (max-width: 880px)': {
    marginTop: '50px',
    width: '100%',
  },
});

export const EventsOptions = styled.div(props => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  height: '60px',
  // Safari - Start
  '.toggle': {
    margin: '0 0 0 auto',
  },
  // Safari - End

  '.filters-amount': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2px',
    background: '#000',
    borderRadius: '50%',
    height: '21px',
    width: '21px',
  },
  '@media (min-width: 880px)': {
    '.filters': {
      boxShadow: 'unset',
    },
  },
  '@media (max-width: 1270px)': {
    '.grid': {
      display: 'unset !important',
      width: 'unset',
      height: 'unset',
      marginLeft: '20px',
    },
  },
  '@media (max-width: 880px)': {
    height: '190px',
    flexDirection: 'column',
    alignItems: 'unset',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '.listFormat': {
      margin: '0 0 0 auto',
    },
    button: {
      height: '50px !important',
      margin: '10px 0 20px 0',
    },
    '.grid': {
      marginLeft: 'auto',
    },
    '.sorting': {
      flexDirection: 'column',
      alignItems: 'unset',
      'p:first-of-type': {
        marginBottom: '10px',
      },
    },

    // Safari - Start
    '.toggle': {
      margin: 'unset',
    },
    // Safari - Stop

  },
  ...props.grid && {
    '@media (min-width: 1271px)': {
      '.grid': {
        display: 'unset !important',
        width: 'unset',
        height: 'unset',
        marginLeft: '20px',
      },
    },
  },
}));

export const RefPosition = styled.div({
  position: 'absolute',
  top: '-185px',
});

export const ScrollDown = styled.img(props => ({
  position: 'fixed',
  top: '177px',
  left: '10%',
  padding: '16px 9px',
  cursor: 'pointer',
  background: '#10242CE5',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  zIndex: 1,
  ...props.flip && {
    transform: 'scaleY(-1)',
  },
  '@media (max-width: 1660px)': {
    display: 'none',
  },
}));

export const PaginationWrapper = styled.div({});

export const TopInfoStyle = styled.div({});
