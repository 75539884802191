import styled from '@emotion/styled';

export const Container = styled.div`
overflow-x: hidden;

    >img
    {
        width: 101%;
    }
`;

export const Features = styled.div`

    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    padding: 40px;
    grid-gap: 150px;

    ${props => props.theme.functions?.mediaQueryMax('700px', `
        grid-template-columns: repeat(auto-fit, minmax(360px,480px));
    `)}
`;

export const Features2 = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    padding: 40px;
    grid-gap: 20px;
    margin-top: 50px;

    .stay-tuned
    {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        min-height: 350px;
        max-width: 350px;
        text-align: center;
        margin: 0 auto;

        >h3
        {
            z-index: 1;
        }
        
        >img
        {
            position: absolute;
        }
    }

    ${props => props.theme.functions?.mediaQueryMax('700px', `
        grid-template-columns: repeat(auto-fit, minmax(360px,480px));
    `)}
`;

export default Container;
