export default {
  chats: 'Chats',
  startChat: 'Start a Chat',
  findPlayersAndStartConversation: 'Find players and start a conversation',
  seeFriendsList: 'See Friends List',
  disconnected: 'Disconnected. Trying to reconnect...',
  isTyping: '{{ user }} typing...',
  online: 'Online',
  offline: 'Offline',
  viewProfile: 'View Profile',
  startGroupChat: 'Start Group Chat with {{ user }}',
  muteConversation: 'Mute Conversation',
  unmuteConversation: 'Unmute Conversation',
  leaveConversation: 'Leave Conversation',
  deleteConversation: 'Delete Conversation',
  allMessagesLoaded: 'All messages were loaded.',
  messageDeleted: 'This message has been deleted.',
  system: 'System',
  deleteMessage: 'Delete Message',
  typeMessage: 'Type a message...',
  groupChat: 'Group Chat',
  groupMembers: 'Participants',
  noOfParticipants: '{{ participants }} participants',
  groupName: 'You, {{ groupNamesList }}',
  addParticipants: 'Add Participants',
  seeGroupMembers: 'View Participants',
  muteGroup: 'Mute Conversation',
  unmuteGroup: 'Unmute Conversation',
  leaveGroup: 'Leave Group Chat',
  deleteGroup: 'Delete Group Chat',
  cancel: 'Cancel',
  createGroup: 'Create',
  yourFriends: 'Your Friends',
  add: 'Add',
  you: 'You',
  leave: 'Leave',
  delete: 'Delete',
  playerBlocked: 'You’ve blocked this player',
  left: 'Left',
  openOrStartChat: 'Open an existing conversation or <br/> start a new one to get things moving.',
  leaveConfirmationGroup: 'Are you sure you want to leave this <br/> group chat?',
  deleteConfirmationGroup: 'Are you sure you want to delete this <br/> group chat?',
  deleteConfirmationConversation: 'Are you sure you want to delete this <br/> conversation?',
  USER_HAS_LEFT_CONVERSATION: '<i><strong>{{ user }}</strong> has left the conversation.</i>',
  PLAYER_ADDED_TO_CONVERSATION: '<i><strong>{{ user }}</strong> was added to the conversation by <strong>{{ addedBy }}</strong>.</i>',
  SUCCESSFULLY_INITIATED_CHAT: 'The conversation was successfully initiated',
  COULD_NOT_INITIATE_CHAT: 'The conversation could not be initiated. Try again later.',
  SUCCESSFULLY_MUTED_CONVERSATION: 'The conversation was successfully muted.',
  SUCCESSFULLY_UNMUTED_CONVERSATION: 'The conversation was successfully unmuted.',
  SUCCESSFULLY_LEFT_CONVERSATION: 'You have successfully left the conversation.',
  SUCCESSFULLY_DELETED_CONVERSATION: 'The conversation was successfully deleted.',
  INTERNAL_ERROR: 'Internal service error. We should work on this, so please help us out and let us know what happened at hq@sportya.net.',
  tryAgain: 'Try Again',
  failedToLoadConversations: 'Failed to Load Conversations',
  leftGroupChat: "You can't send messages to this group chat because you are no longer a participant.",
  unreadMessages: '{{ messages }} unread messages',
};
