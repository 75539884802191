import React from 'react';
import { Trans } from 'react-i18next';
import DragScrollProvider from 'drag-scroll-provider';

import { BracketStyle } from './styles';
import Paragraph from '../../../../../components/Paragraph';
import Match from '../Match';

const Bracket = ({
  matches, qualifications, type, phase,
}) => {
  const sortedQualifications = [];
  let matchNumber = 0;

  return (
    <BracketStyle qualifications={qualifications?.length} type={type}>
      <div className="container">
        <DragScrollProvider>
          {({ onMouseDown, ref }) => (
            <div
              ref={ref}
              onMouseDown={onMouseDown}
              className={`${phase ? 'tournament-bracket-phase' : 'tournament-bracket'} tournament-bracket--rounded`}
            >
              {matches?.map((row, idx) => {
                const rowIdx = idx;
                const isFinalStage = (matches[0][0]?.competition?.finalStage
                  && matches[0][0]?.competition?.finalStage === idx + 1
                );
                return (
                  <div
                    key={idx}
                    className={`
                      ${!rowIdx && qualifications?.length ? 'bracketFirstRow' : ''}
                      tournament-bracket__round tournament-bracket__Round-of-16
                    `}
                  >
                    {!rowIdx && qualifications?.length
                      ? (
                        <div className="colTitle qualTitle">
                          <Paragraph medium bold>
                            <Trans ns="events" i18nKey="qualifying">Qualifying</Trans>
                          </Paragraph>
                        </div>
                      ) : ''}
                    <div className="colTitle">
                      <Paragraph medium bold>
                        {row.length > 4 ? `R - ${row.length * 2}`
                          : <Trans ns="events" i18nKey={`stages.${row.length}`} />}
                      </Paragraph>
                    </div>
                    <div className="tournament-bracket__list">
                      {row?.map((x, key) => {
                        /* display match number logic */
                        matchNumber += (!key ? 1 : 2);

                        qualifications?.filter((y) => y?.competition?.nextStagePosition === x?.competition?.position * 2
                          || y?.competition?.nextStagePosition === x?.competition?.position * 2 - 1)
                          ?.map(sortedMatch => sortedQualifications?.push(sortedMatch));

                        return (
                          <div key={key} className="tournament-bracket__item">
                            <Match
                              sortedQualifications={sortedQualifications}
                              qualificationMatches={qualifications}
                              rowIdx={rowIdx}
                              type={type}
                              isFinalStage={isFinalStage}
                              matchNumber={matchNumber}
                              x={x}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </DragScrollProvider>
      </div>
    </BracketStyle>
  );
};

export default Bracket;
