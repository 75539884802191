import React from 'react';
import { Trans } from 'react-i18next';
import { SoonToBeAnnounced } from './styles';
import H1 from '../../../../../components/H1';
import soon from '../../../../../assets/images/bg/soon.svg';

const index = () => (
  <SoonToBeAnnounced>
    <H1>
      <Trans ns="common" i18nKey="soonToBeAnnounced">
        Soon to be
        announced
      </Trans>
    </H1>
    <img src={soon} alt="soonImg" />
  </SoonToBeAnnounced>
);

export default index;
