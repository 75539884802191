import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PubSub from 'pubsub-js';

import { get } from '../common/http';

// Actions
export const fetchAccountStats = createAsyncThunk('events/fetchAccountStats', async ({ userId }) => {
  try {
    const { data: { data } } = await get(`/accounts/${userId}/stats`);

    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// Slice
const slice = createSlice({
  name: 'accountStats',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchAccountStats.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccountStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchAccountStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});
export default slice.reducer;
