import React, { useEffect, useState, useRef } from 'react';
import dateFormat from "dateformat";
import CryptoJS from "crypto-js";
import { Trans, useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';

// styles
import { ConversationContentStyle } from "../../styles";

// components
import LoadImage from "../../../../components/common/LoadImage";
import Link from "../../../../components/Link";
import ContentLoader from "../../../../components/ContentLoader";
import ToggleVisible from "../../../../components/common/ToggleVisible";
import mixpanel from '../../../../mixpanel';

// actions
import { deleteMessage, seenConversationMessages } from '../../actions';

// reducers
import {
  fetchConversation,
  readAllMessages,
  seenMessages,
  setAllMessagesLoaded,
} from '../../reducers';

const ConversationContent = ({
  conversationId,
  userId,
  systemMessages,
  shortName,
  setMobileToggleContent,
  lastMessageRef,
}) => {
  const { addToast } = useToasts();
  const { t } = useTranslation('conversations');
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const {
    list: { data: conversations },
    info: { data: messages, status, allMessagesLoaded },
  } = useSelector(state => state?.conversations);

  const [page, setPage] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const [updateScrollPosition, setUpdateScrollPosition] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('down');
  const [newMessages, setNewMessages] = useState(0);
  const newMessageRef = useRef(null);

  const chatMessages = document.getElementById('conversation-content');
  const conversationHeader = document.querySelector('.conversation-header');
  const conversationInfo = (conversations || []).filter(({ id }) => id?.toString() === conversationId).shift();
  const options = {
    page: 1,
    limit: 50,
  };

  useEffect(() => {
    if (conversationInfo?.newMessages > 0) {
      setScrollDirection('down');
      setNewMessages(conversationInfo?.newMessages);

      // if number of messages are not visible on screen, show view new messages
      if (newMessageRef?.current?.offsetTop < 0) {
        setScrollDirection('up');
      }

      // if number of new messages are inside chat box, mark them as read
      if (scrollTop === 0 && newMessageRef?.current?.offsetTop > 0) {
        markMessagesAsSeen();
      }
    } else {
      setNewMessages(0);
    }
  }, [messages, conversationInfo]);

  useEffect(() => {
    const onScroll = debounce(async () => {
      const currentPosition = Math.round(chatMessages.scrollTop === 0 ? 0 : -chatMessages.scrollTop);
      if (currentPosition > (chatMessages.scrollHeight - chatMessages.clientHeight - 1) && !allMessagesLoaded) {
        setPage(page + 1);
      }

      setScrollTop(currentPosition);

      // if user scroll is above first new message, mark all messages as seen
      if (
        (
          newMessageRef
          && (currentPosition - conversationHeader.clientHeight + newMessageRef.current?.offsetTop) > 0
          && newMessages > 0
          && scrollDirection === 'up'
        ) || (currentPosition === 0 && scrollDirection === 'down')
      ) {
        await markMessagesAsSeen();
      }
    }, 0);

    if (chatMessages) {
      chatMessages?.addEventListener("scroll", onScroll);
    }

    return () => chatMessages?.removeEventListener("scroll", onScroll);
  }, [chatMessages, messages, scrollDirection]);

  useEffect(() => {
    if (chatMessages && updateScrollPosition) {
      chatMessages.scrollTop = scrollTop;
      setUpdateScrollPosition(false);
    }
  }, [chatMessages, updateScrollPosition]);

  useEffect(async () => {
    if (page !== 1 && conversationId && conversationId !== 'undefined') {
      await dispatch(fetchConversation({
        conversationId,
        options: {
          ...options,
          page,
        },
      }));

      setScrollTop(-scrollTop);
      setUpdateScrollPosition(true);
    }
  }, [page]);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchConversation({
        conversationId,
        options: {
          ...options,
          limit: 50 + (conversationInfo?.newMessages > 0 ? conversationInfo?.newMessages : 0),
        },
      }));
    };

    if (conversationId && conversationId !== 'undefined') {
      init().then(async () => {
        if (scrollTop !== 0) {
          setScrollTop(0);
          setUpdateScrollPosition(true);
          setPage(1);
        }

        await dispatch(setAllMessagesLoaded(false));

        setMobileToggleContent(true);
      });
    }
  }, [conversationId]);

  const handleDeleteMessage = async (messageId) => {
    const payload = {
      conversationId,
    };
    await deleteMessage({
      messageId,
      payload,
      addToast,
      t,
    });

    mixpanel.track(
      conversationInfo?.type === 'individual'
        ? 'Delete Message in Private Conversation'
        : 'Delete Message in Group Chat',
    );
  };

  // mark new messages as seen
  const markMessagesAsSeen = async () => {
    if (conversations.length && conversationId) {
      const messageIndex = conversations.findIndex(({ id }) => id === conversationId);
      if (messageIndex !== -1 && conversationInfo?.newMessages > 0) {
        dispatch(seenMessages(conversationInfo?.newMessages ?? 0));
        dispatch(readAllMessages(conversationId));
        await seenConversationMessages({
          conversationId,
          addToast,
          t,
        });
      }
    }
  };

  if (
    (conversationId && status === 'failed')
    || (conversationId && !isEmpty(conversations) && isEmpty(conversationInfo))
  ) {
    return <Redirect to="/conversations" />;
  }

  const scrollToMessage = async () => {
    if (scrollDirection === 'down') {
      lastMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
      newMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }

    // mark new messages as seen
    await markMessagesAsSeen();
  };

  return (
    <ConversationContentStyle id="conversation-content">
      <div ref={lastMessageRef} />

      {status === 'loading' && (
        <div className="loading-messages">
          <ContentLoader />
        </div>
      )}

      {(newMessages > 0) && (
        <div className="unread-messages-wrapper">
          <div className="unread-messages">
            <span onClick={scrollToMessage}>
              <img
                src={LoadImage('white-long-up-arrow-icon.svg')}
                alt=""
                width={20}
                height={20}
                className={`arrow-${scrollDirection}`}
              />
              <Trans ns="conversations" i18nKey="unreadMessages">
                unread messages
                {{ messages: newMessages }}
              </Trans>
            </span>
            <div className="close" onClick={markMessagesAsSeen}>
              <img src={LoadImage('close-white-icon.svg')} alt="" width={10} height={10} />
            </div>
          </div>
        </div>
      )}

      {!isEmpty(messages) && (
        <ul>
          {messages.map((message, key) => {
            const systemMessage = systemMessages.includes(message.message);
            const showAuthor = (
              message?.userId !== messages[key + 1]?.userId || (
                message?.userId === messages[key + 1]?.userId
                && dateFormat(message.createdAt, "h:MM") !== dateFormat(messages[key + 1]?.createdAt, "h:MM")
              )
            );
            const pendingMessage = (messages[key - 1]?.status === 'pending'
              || (new Date().getTime() - new Date(message.createdAt).getTime() > 2000 && message.status === 'pending')
            );
            const userInfo = message?.userInfo;

            const firstNewMessage = (
              message.conversationId.toString() === conversationId
              && newMessages > 0
              && (message.id === messages?.[messages.length - newMessages]?.id)
            );

            return (
              <li
                key={key}
                className={Number(userId) === message.userId ? `is-user` : ''}
                {...(firstNewMessage && { ref: newMessageRef })}
              >
                <div className={`message ${showAuthor ? 'message__has-spacing' : ''}`}>
                  <div className="avatar">
                    <Link to={`/accounts/${userInfo?.id}`}>
                      {userInfo?.profilePicture ? (
                        <img
                          src={LoadImage(`accounts/${userInfo?.userId}/${userInfo?.profilePicture}`, true)}
                          alt={`${userInfo?.firstName} ${userInfo?.lastName}`}
                          title={`${userInfo?.firstName} ${userInfo?.lastName}`}
                        />
                      ) : (
                        <span>{`${userInfo?.firstName.charAt(0)} ${userInfo?.lastName.charAt(0)}`}</span>
                      )}
                    </Link>
                  </div>
                  <div className="message-content">
                    <p>
                      {systemMessage && (
                        <Trans ns="conversations" i18nKey={message.message}>
                          {message.message}
                          {{ user: shortName(message.userInfo) }}
                          {{ addedBy: shortName(message.addedBy) }}
                        </Trans>
                      )}
                      {!systemMessage && (
                        message.status === 'deleted'
                          ? (
                            <i className="fw-normal">
                              <Trans ns="conversations" i18nKey="messageDeleted">This message has been deleted.</Trans>
                            </i>
                          ) : (
                            CryptoJS.AES.decrypt(message.message, message.conversationId).toString(CryptoJS.enc.Utf8)
                          )
                      )}
                    </p>
                    {showAuthor && (
                      <div className="author">
                        <span>
                          {systemMessage
                            ? <Trans ns="conversations" i18nKey="system">System</Trans>
                            : userInfo?.firstName}
                        </span>
                        &bull; &nbsp;
                        <time>
                          {dateFormat(message.createdAt, "DDDD") === 'Today'
                            ? `${dateFormat(message.createdAt, "DDDD")}, ${dateFormat(message.createdAt, "h:MM TT")}`
                            : dateFormat(message.createdAt, "mmm dd, h:MM TT")}
                        </time>
                      </div>
                    )}
                    {pendingMessage && (
                      <div className="pending-message">
                        <img src={LoadImage('black-clock-icon.svg')} alt="Pending message" />
                      </div>
                    )}

                    {!['pending', 'deleted'].includes(message?.status) && (
                      <div
                        key={message.id}
                        ref={ref}
                        className={`actions ${isComponentVisible === message.id ? 'show-options' : ''}`}
                        onClick={() => setIsComponentVisible(message.id)}
                      >
                        <img src={LoadImage('three-dots-icon.png')} alt="Actions" />
                        <ul className={`
                            options
                            ${isComponentVisible === message.id ? '' : 'hidden'}
                            ${messages.length - 1 === key ? 'is-last' : ''}
                          `}
                        >
                          <li
                            className="delete"
                            onClick={() => handleDeleteMessage(message.id)}
                          >
                            <Trans ns="conversations" i18nKey="deleteMessage">Delete Message</Trans>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}

      {allMessagesLoaded
        && (
          <div className="all-messages-loaded">
            <Trans ns="conversations" i18nKey="allMessagesLoaded">All messages were loaded.</Trans>
          </div>
        )}
    </ConversationContentStyle>
  );
};

export default ConversationContent;
