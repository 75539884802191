import styled from '@emotion/styled';
import {
  ClearResults,
  SearchBar,
  SearchResults,
} from '../../../Events/JoinCompetition/components/Search/styles';

export const MatchFormSearchContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '&:not(:last-of-type)': {
    margin: '0 0 10px 0',
  },
});

export const MatchFormSearchBar = styled(SearchBar)(({ bottomMargin }) => ({
  padding: '0 15px',
  fontWeight: 700,
  border: '1px solid #78ADAB',

  ...bottomMargin && {
    margin: '0 0 10px 0',
  },

  '&:hover': {
    border: '1px solid hsl(0, 0%, 70%)',
  },
}));

export const MatchFormSearchResults = styled(SearchResults)({
  top: '60px',
  padding: 'unset',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  zIndex: 4,
});

export const MatchFormClearResults = styled(ClearResults)({
  top: '25px',
  right: '15px',
  '@media (max-width: 483px)': {
    top: '25px',
  },
});

export const InputLoader = styled.div({
  position: 'relative',
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  background: '#9880ff',
  color: '#9880ff',
  animation: 'flashing-dots 1s infinite linear alternate',
  animationDelay: '.5s',

  '&:before :after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
  },

  '&:before': {
    left: '-15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    background: '#9880ff',
    color: '#9880ff',
    animation: 'flashing-dots 1s infinite alternate',
    animationDelay: '0s',
  },

  '&:after': {
    left: '15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    background: '#9880ff',
    color: '#9880ff',
    animation: 'flashing-dots 1s infinite alternate',
    animationDelay: '1s',
  },
  '@keyframes flashing-dots': {
    '0%': {
      background: '#9880ff',
    },
    '50%, 100%': {
      background: 'rgba(152, 128, 255, 0.2)',
    },
  },
});
