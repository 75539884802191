import { useTheme } from '@emotion/react';
import React from 'react';
import dateFormat from 'dateformat';
import { capitalize } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import Badge from '../../../../../components/Badge';
import LoadImage from '../../../../../components/common/LoadImage';
import {
  BadgeCategory, BadgeIcon, EventMetaList, EventMetaListItem,
} from '../styles';

const EventMetaComponent = ({
  category,
  level,
  gameType,
  location,
  date,
  restriction,
  clubId,
}) => {
  const { t: commonT } = useTranslation('common');
  const { colors } = useTheme();
  const isFirstServe = category === 'firstServe';
  const hasGenderRestrictions = restriction !== 'any' && restriction !== '';
  return (
    <EventMetaList>
      <EventMetaListItem>
        <Badge
          bg={category}
          border={colors?.white}
          textcolor={isFirstServe ? colors?.darkGrey : colors?.white}
          smallbadge="true"
        >
          <BadgeIcon src={LoadImage(`${category}-category-icon.svg`)} alt={`${capitalize(category)} event!`} />
          <BadgeCategory>
            <Trans ns="common" i18nKey={category}>Category</Trans>
          </BadgeCategory>
        </Badge>
      </EventMetaListItem>
      <EventMetaListItem>
        <Trans ns="player" i18nKey="level">
          Level
        </Trans>
        {` ${level}`}
      </EventMetaListItem>
      <EventMetaListItem>
        <Trans ns="events" i18nKey={gameType}>{gameType}</Trans>
        {hasGenderRestrictions && (`, ${commonT(restriction)}`)}
      </EventMetaListItem>
      <EventMetaListItem>
        <a href={`/clubs/${clubId}`}>{location}</a>
      </EventMetaListItem>
      <EventMetaListItem>
        {dateFormat(date, 'dd mmm yyyy')}
      </EventMetaListItem>
    </EventMetaList>
  );
};

export default EventMetaComponent;
