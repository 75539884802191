import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

const isOnline = (conversationInfo, onlineUsers) => {
  if (!isEmpty(conversationInfo) && !isEmpty(onlineUsers)) {
    const user = onlineUsers.filter(
      ({ userId, online }) => userId === Number(conversationInfo?.userInfo?.userId) && online,
    ).shift();

    return !!user?.online;
  }
};

const ConversationName = ({ conversation, accountInfo, isBlocked }) => {
  const { data: onlineUsers } = useSelector(state => state?.dashboard?.onlineUsers);

  const groupNamesList = (conversation?.members || [])
    .filter(({ id }) => id !== accountInfo.id)
    .reduce((acc, { firstName }) => {
      acc.push(firstName);
      return acc;
    }, [])
    .join(', ');

  return (
    <div className="conversation-name">
      {conversation.type === 'individual' && (
        <>
          {!isBlocked && <span className={`is-${isOnline(conversation, onlineUsers) ? 'online' : 'offline'}`} />}
          <h3 className="name">
            {conversation.name ?? `${conversation?.userInfo.firstName} ${conversation?.userInfo.lastName}`}
          </h3>
          <span className="user-id"># {conversation.userInfo?.userId}</span>
        </>
      )}

      {conversation.type === 'group' && (
        <h3 className="name">
          {conversation.name ?? (
            <Trans ns="conversations" i18nKey="groupName">
              You, {{ groupNamesList }}
            </Trans>
          )}
        </h3>
      )}
    </div>
  );
};

export default ConversationName;
