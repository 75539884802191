import { patch } from '../../../../components/common/http';

export const updateAccount = async ({
  id, payload, addToast, t,
}) => {
  try {
    return await patch(`/accounts/${id}`, payload);
  } catch (error) {
    return (
      addToast
      && addToast(t(error?.response?.data?.message || 'UPDATE_ACCOUNT_FAILED', { ns: 'common' }), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};
