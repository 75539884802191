import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
// import reportWebVitals from './reportWebVitals';

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOGLE_ANALYTICS_ID,
};

TagManager.initialize(tagManagerArgs);

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
