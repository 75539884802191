import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import LoadImage from '../../../../components/common/LoadImage';
import FormInput from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import SelectGoogleAutocomplete from '../../../../components/Form/SelectGoogleAutocomplete';
import Button from '../../../../components/Form/Button';
import { Container, GroupSelect } from './styles';
import { updateAccount } from './actions';
import { Country, Flag } from '../../../Auth/Register/styles';
import { H3, P } from '../../../../components/Collection';
import getCountryIsoCode from '../../../../components/common/getCountryIsoCode';

const PersonalInformation = ({ accountInfo }) => {
  const { t } = useTranslation('settings');
  const { eligibleCountries } = useSelector(state => state.settings.data);
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const clubOwner = accountInfo?.role === 'club';
  const [country, setCountry] = useState(getCountryIsoCode(accountInfo?.location?.country).countryCode);
  const [userLocation, setUserLocation] = useState(accountInfo?.location);
  const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  const onlyLatinAlphabet = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;

  const formSchema = Yup.object().shape({
    firstName: Yup.string().matches(onlyLatinAlphabet, commonT('onlyLatin')),
    lastName: Yup.string().matches(onlyLatinAlphabet, commonT('onlyLatin')),
    phone: Yup.string().matches(phoneRegExp, commonT('nrNotValid')),
  });

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(eligibleCountry => ({
    value: (eligibleCountry.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${eligibleCountry.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${eligibleCountry.key}`)}</P>
      </Country>
    ),
  }));

  return (
    <Container>
      <H3 margin="0 0 20px 0">
        <Trans
          ns="settings"
          i18nKey="profileSettings.personalInformation.title"
        >
          Change Password
        </Trans>
      </H3>
      <Formik
        initialValues={!clubOwner ? {
          firstName: accountInfo?.firstName,
          lastName: accountInfo?.lastName,
          location: {
            label: userLocation?.city,
            value: userLocation,
          },
          phone: accountInfo?.phone,
        } : {
          firstName: accountInfo?.firstName,
          lastName: accountInfo?.lastName,
          jobTile: accountInfo?.ownerInfo?.jobTitle,
          phone: accountInfo?.phone,
        }}
        validationSchema={formSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = !clubOwner ? {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            location: userLocation || accountInfo?.location?.city,
          } : {
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.title,
            phone: values.phone,
          };

          await updateAccount({
            id: accountInfo.id,
            payload,
            addToast,
            setSubmitting,
            dispatch,
            t: commonT,
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
          values,
        }) => (
          <Form>
            <FormInput
              name="firstName"
              type="text"
              placeholder={t('profileSettings.personalInformation.firstName')}
              label={t('profileSettings.personalInformation.firstName')}
              value={values.firstName}
              {...{ errors, touched }}
              disabled
            />

            <FormInput
              name="lastName"
              type="text"
              placeholder={t('profileSettings.personalInformation.lastName')}
              label={t('profileSettings.personalInformation.lastName')}
              value={values.lastName}
              {...{ errors, touched }}
              disabled
            />
            <p className="nm">{commonT('birthdate')}</p>
            <GroupSelect>
              <Select
                name="year"
                placeholder={dateFormat(accountInfo?.birthDate, 'yyyy')}
                isDisabled
                value={dateFormat(accountInfo?.birthDate, 'yyyy')}
                lowPlaceholder
                label={t('profileSettings.personalInformation.year')}
              />

              <Select
                name="month"
                placeholder={dateFormat(accountInfo?.birthDate, 'mmmm')}
                isDisabled
                value={dateFormat(accountInfo?.birthDate, 'mmmm')}
                lowPlaceholder
                label={t('profileSettings.personalInformation.month')}
              />

              <Select
                name="day"
                placeholder={dateFormat(accountInfo?.birthDate, 'd')}
                isDisabled
                value={dateFormat(accountInfo?.birthDate, 'd')}
                lowPlaceholder
                label={t('profileSettings.personalInformation.day')}
              />
            </GroupSelect>

            <Select
              name="country"
              onChange={setCountry}
              placeholder={commonT('country')}
              label={commonT('country')}
              options={countries}
              isSearchable={false}
              height="50px"
              lowInput
              inputLabel
              {...accountInfo?.location?.country
                  && {
                    defaultValue: countries.filter(countryOption => (
                      countryOption.value === getCountryIsoCode(accountInfo?.location?.country).countryCode
                    )),
                  }}
              {...(country.value || accountInfo?.location?.country) && { labelOn: true }}
              {...{ errors, touched }}
            />

            <SelectGoogleAutocomplete
              name="city"
              autocompletionRequest={{
                componentRestrictions: {
                  country: [country?.value || country],
                },
              }}
              minLengthAutocomplete={3}
              hook={(e) => setUserLocation(e?.value)}
              placeholder={commonT('city')}
              label={commonT('city')}
              isClearable
              {...userLocation && {
                value: { label: userLocation?.city, value: userLocation },
              }}
              {...!isEmpty(userLocation) && { lowInput: true }}
              {...!isEmpty(userLocation) && { labelOn: true }}
              {...{ errors, touched }}
            />
            <FormInput
              name="phone"
              type="tel"
              placeholder={commonT('phoneNumber')}
              solidPlaceholder
              value={values.phone}
              {...{ errors, touched }}
            />
            <Button
              type="submit"
              disabled={isSubmitting || (!isValid && dirty)}
            >
              {commonT('saveChanges')}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PersonalInformation;
