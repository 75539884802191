import React from 'react';
import MyProfileOptions from './components/MyProfileOptions';
import VisitedProfileOptions from './components/VisitedProfileOptions';
import { Container } from './styles';

const Options = ({ isMyProfile, isImpersonate }) => {
  const isVisitedProfile = !isMyProfile && !isImpersonate;

  return (
    <Container>
      {isMyProfile && (<MyProfileOptions />)}
      {isVisitedProfile && (<VisitedProfileOptions />)}
    </Container>
  );
};

export default Options;
