import React from 'react';

import { Trans } from 'react-i18next';
import { P } from '../Collection';

const rankBeforeEvent = (event, id, accountInfo, commonT) => {
  const { matches } = event || [];
  const list = matches.slice().sort((a, b) => (a.stage < b.stage ? -1 : 1));

  const latest = list[list.length - 1];

  const players = {
    player: {
      name: `${latest?.player?.firstName} ${latest?.player?.lastName}`,
      rank: latest?.player?.rank,
      isMe: latest?.player?.id === accountInfo?.id,
      notVisited: latest?.player?.userId !== id,
      id: latest?.player?.id,
      get exists() { return latest?.player?.firstName; },
    },
    partner: {
      name: `${latest?.playerPartner?.firstName} ${latest?.playerPartner?.lastName}`,
      rank: latest?.playerPartner?.rank,
      isMe: latest?.playerPartner?.id === accountInfo?.id,
      id: latest?.playerPartner?.id,
      notVisited: latest?.playerPartner?.userId !== id,
      get exists() { return latest?.playerPartner?.firstName; },
    },
  };

  return (
    <P xSmall className="activityEvent__rankBefore">
      {event.matchType === 'friendly'
        ? (
          <Trans ns="friendlyMatches" i18nKey="rankBeforeMatch">
            Rank before match
          </Trans>
        )
        : (
          <Trans ns="player" i18nKey="eloDescription">
            ELO Rating before event
          </Trans>
        )}
      {players.player.exists && !players.player.isMe && players.player.notVisited ? (
        <a href={`/accounts/${players.player.id}`} target="_blank" rel="noreferrer" className="playerPartner">
          {` ${players.player.name}`}
          <span className="rankBefore">
            {players.player.rank ? players.player.rank : commonT('notAvailable')}
          </span>
        </a>
      ) : players.player.exists && (
        <span>
          {` ${players.player.name}`}

          <span className="rankBefore">
            {players.player.rank ? players.player.rank : commonT('notAvailable')}
          </span>
        </span>
      )}

      {players.partner.exists && !players.partner.isMe && players.partner.notVisited ? (
        <a href={`/accounts/${players.partner.id}`} target="_blank" rel="noreferrer" className="playerPartner">
          {` ${players.partner.name}`}
          <span className="rankBefore">
            {players.partner.rank ? players.partner.rank : commonT('notAvailable')}
          </span>
        </a>
      ) : players.partner.exists && (
        <span>
          {` ${players.partner.name}`}
          <span className="rankBefore">
            {players.partner.rank ? players.partner.rank : commonT('notAvailable')}
          </span>
        </span>
      )}
    </P>
  );
};

export default rankBeforeEvent;
