import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import {
  Contain, Link, P, Button,
} from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';

const Container = styled(Contain)({
  margin: '0 40px 0 auto',
  '@media (max-width: 1190px)': {
    margin: '0 auto',
  },
});

const Arrow = styled.img({
  margin: '0 0 0 5px',
  transform: 'rotate(180deg)',
});

const Index = ({
  eventInfo,
  competitionInfo,
  myAccount,
  isLoggedIn,
}) => {
  const maintenance = competitionInfo?.status === 'MAINTENANCE';
  const hasDiscount = competitionInfo.premiumDiscount > 0;
  const state = {
    content: maintenance
      ? <Trans ns="events" i18nKey="underMaintenanceButton">Under Maintenance</Trans>
      : <Trans ns="events" i18nKey="viewCompetition">View Competition</Trans>,
  };

  return (
    <Container
      direction="column"
      align="center"
    >
      <Button
        height="60px"
        minWidth="260px"
        margin="0 0 10px 0"
        large
        outline
      >
        <Link
          padding="0 20px"
          {...isLoggedIn
            ? { to: `/events/${eventInfo._id}/competitions/${competitionInfo?._id}` }
            : { to: `/login/events~${eventInfo._id}~competitions~${competitionInfo?._id}` }}
        >
          {state.content}
          {!maintenance && <Arrow src={LoadImage('back-arrow.svg')} alt="Join Competition" />}
        </Link>
      </Button>
      {!myAccount && (
        <>
          <P small margin="unset">
            <Trans ns="events" i18nKey="bookingFee">Sportya Service Fee</Trans>
            :
            {' '}
            <strong>
              {competitionInfo.fullPrice}
              {' '}
              {competitionInfo.currency}
            </strong>
          </P>
          {hasDiscount && (
            <P small margin="unset">
              <Trans ns="events" i18nKey="premiumDiscount">Discount for Premium</Trans>
              :
              {' '}
              <strong>
                -
                {competitionInfo.premiumDiscount}
                {' '}
                {competitionInfo.currency}
              </strong>
            </P>
          )}
          <P small margin="unset">
            <Trans ns="events" i18nKey="clubEntryFee2">Club Entry Fee</Trans>
            :
            {' '}
            <strong>
              {competitionInfo.clubPrice}
              {' '}
              {competitionInfo.clubCurrency}
            </strong>
          </P>
        </>
      )}
    </Container>
  );
};

export default Index;
