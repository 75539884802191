import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';

export const Container = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  background: `url('${LoadImage(props.isPremium ? 'campaign/bg2.png' : 'campaign/bg.png')}')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  '@media (max-width: 1190px)': {
    padding: '20px 0 0 0',
    justifyContent: 'unset',
    backgroundPosition: 'right',
    height: 'unset',
  },
}));

export const Header = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  height: '25px',
  margin: '50px auto 100px auto',
  maxWidth: props.isPremium ? '1234px' : '1120px',
  width: '100%',
  '@media (max-width: 1322px)': {
    padding: '0 20px',
  },

  '@media (max-width: 1190px)': {
    margin: '0 auto 50px auto',
  },

  // '@media (max-width: 1023px)': {
  //   display: 'none',
  // },
}));

export const Contain = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  maxWidth: props.isPremium ? '1234px' : '1120px',
  maxHeight: '650px',
  height: '100%',
  width: '100%',
  '@media (max-width: 1322px)': {
    padding: '20px',
  },
  '@media (max-width: 1190px)': {
    maxHeight: 'unset',
  },
}));

export const CallToAction = styled.div(props => ({
  display: 'flex',
  padding: '20px 60px 20px 20px',
  ...!props.noBg ? {
    background: '#021626',
    borderRadius: '10px',
  } : {
    padding: 'unset',
    alignItems: 'center',
    margin: '40px 0 80px 0',
  },
  '@media (max-width: 1190px)': {
    padding: '20px',
    flexDirection: 'column',
    margin: '20px 0',
    '> *': {
      margin: '10px 0',
    },
    ...props.noBg && {
      padding: 'unset',
      alignItems: 'center',
    },
  },
}));

export const ListsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
  },
});

export const List = styled.ul({
  maxHeight: '120px',
  '@media (max-width: 1190px)': {
    maxHeight: 'unset',
    padding: '0 20px',
  },
});

export const Item = styled.li({
  fontWeight: 700,
  lineHeight: '26px',
  fontSize: '20px',
  color: '#fff',
  margin: '0 0 40px 0',
});

export const RedText = styled.span({
  color: '#F42B03',
});

export const Totals = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  '&:first-of-type': {
    margin: '0 0 0 86px',
    '@media (max-width: 1190px)': {
      margin: '10px 0',
    },
  },
  '&:last-of-type': {
    margin: '0 0 0 auto',
    '@media (max-width: 1190px)': {
      margin: '10px 0',
      ...props.hasBg && {
        margin: '10px 0',
      },
    },
    ...props.hasBg && {
      margin: '0 0 0 20px',
    },
  },
  ...props.hasBg && {
    background: '#021626',
    borderRadius: '120px',
    padding: '0 35px',
    height: '60px',
  },
}));

export const Logo = styled.img({
  height: '22px',
});

export const Icon = styled.img({
  margin: '0 15px 0 0',
});

export const BenefitIcon = styled.img({
  height: '36px',
  width: '36px',
});

export const PremiumBenefitsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
  },
});

export const PremiumBenefits = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '&:last-of-type': {
    margin: '0 0 0 auto',
    '@media (max-width: 1190px)': {
      margin: '20px 0 20px 0',
    },
  },
});
