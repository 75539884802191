export default {
  minutesUntil: 'краен срок {{time}} мин',
  hoursUntil: 'краен срок {{time}} ч',
  daysUntil: 'краен {{time}} дни',
  monthsUntil: 'краен {{time}} месеца',
  yearsUntil: 'краен {{time}} години',
  minutesAgo: 'преди {{time}} минути',
  hoursAgo: 'преди {{time}} часа',
  daysAgo: 'преди {{time}} дни',
  monthsAgo: 'преди {{time}} месеца',
  yearsAgo: 'преди {{time}} години',
  justNow: 'точно сега',
};
