import React from 'react';

import { ResultsPlayersCard, ResultsPlayersGroupCard, ElimsLeftInfo } from './styles';

import Player from '../ResultsPlayer';
import Paragraph from '../../../../../components/Paragraph';

const index = ({
  data, league, duo, groups, final,
}) => (
  <>
    {league && groups?.length > 0
      ? (
        <ResultsPlayersGroupCard>
          <Player groupData={data} duo={duo} league={league} />
        </ResultsPlayersGroupCard>
      )
      : (data?.map((stages, idx) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} key={idx}>
          <ElimsLeftInfo>
            <Paragraph bold medium>AAA</Paragraph>
            <Paragraph smaller>BBB</Paragraph>
            <Paragraph smaller>CCC</Paragraph>
          </ElimsLeftInfo>

          <ResultsPlayersCard stages={stages} key={idx} final={final}>
            {/* player + duo */}
            <Player elimData={stages} league={league} final={final} duo={duo} />

            {/* opponent + duo */}
            <Player playerType="opponent" elimData={stages} league={league} final={final} duo={duo} />
          </ResultsPlayersCard>
        </div>
      )))}
  </>
);

export default index;
