export default {
  seoTitle: 'New Features Coming Soon | Sportya',

  item1: {
    title: 'Bulgarian',
    subtitle: '(New language)',
    text: `We’re hard at work to prepare the launch of the Bulgarian version of Sportya. We know how critical this is. 
        We’re on it.`,
  },

  item2: {
    title: 'Player Messaging',
    text: 'We decided to hold off the launch of the new messaging system as we are planning to expand its interconnectivity with other Sportya services. It is essential for the re-launch of Friendly Matches, together with the new system of managing your connections in Sportya.',
  },

  item3: {
    title: 'Friendly Matches',
    subtitle: '(Re-launch)',
    text: 'It’s not a new feature entirely and veteran players know it well. The upgrades that are being developed will allow you to find a partner for a competitive match faster than before, and with a higher degree of relevancy in terms of competitiveness and projected match experience.',
  },

  item4: {
    title: 'Friends List',
    text: 'It will be the new way of interacting with your friends in Sportya, growing your network and keeping in touch throughout the year. As mentioned above, further integrations with the messaging system and friendly matches will allow you to get more out of your tennis life.',
  },

  item5: {
    title: 'Blog for News & Updates',
    text: 'In Sportya, we believe that you should be part of the conversation. As such, our section for News & Updates will be re-launched as a Blog. We plan to keep you closer to what happens in Sportya and give you the means of contributing to its future.',
  },

  item6: {
    title: 'Meet Your Match',
    subtitle: '(for Doubles)',
    text: 'The struggle to find a playing partner for Doubles matches will soon be over. Introducing a frequently asked feature: the ability to find other players interested to join a team, for a specific event. Coming soon, to an event near you.',
  },

  item7: {
    title: 'Booking',
    text: 'We’re closing the gap between you and the court. It’s Sportya Booking and yet, much more than just booking. Expect to find a large variety of courts and close integrations with other Sportya services. The end result – you will be setting up a match faster than ever, whether it is a friendly or a competitive match.',
  },

  item8: {
    title: 'Live Scores',
    text: 'For the first iteration of the system, we will re-launch the ability for both event directors and players to enter match results, faster and easier than before. Further down the road, the new system will allow us to develop both software and hardware solutions that will transform your match experience and allow you to stay focused and enjoy the game.',
  },

  item9: {
    title: 'Events Management',
    subtitle: '(Clubs & organizers)',
    text: 'We will start a process of deep integration with all Sportya partner clubs, linking our services to their operational systems. In turn, this will allow us to develop new services for players and to allow clubs to interact with you at a deeper level, providing faster and more accurate services and a better overall experience for their members and customers.',
  },

  comingSoon: 'Coming Soon',
  worthTheWait: 'Worth the Wait',

  stayTuned: 'Stay Tuned for More!',
};
