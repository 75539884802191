/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import LoadImage from '../../../components/common/LoadImage';
import mixpanel from '../../../mixpanel';

import Select from '../../../components/Form/Select';
import Input from '../../../components/Form/Input';

import {
  Contain, P, H2, Button,
} from '../../../components/Collection';
import { FormContainerDetails, Container } from './styles';

import { updateAccount } from './actions';
import { store as socketStore } from '../../../components/Socket/store';
import CheckmarkWhite from '../../../assets/images/checkmark-white.svg';
import { getAccountInfo, updateAccountInfoState } from '../../../components/Layout/reducers/session';
import Logout from '../../../components/Logout';
import { Country, Flag } from '../../Auth/Register/styles';
import SelectGoogleAutocomplete from '../../../components/Form/SelectGoogleAutocomplete';

const currentYear = (new Date().getFullYear() - 14);

const years = [];
for (let i = (currentYear - 80); i < currentYear; i++) {
  years.push({
    label: i,
    value: i,
  });
}
years.reverse();

const days = [];
for (let i = 1; i <= 31; i++) {
  days.push({
    label: i,
    value: i,
  });
}

const SetProfileDetails = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion } = useSelector(state => state.session);
  const { eligibleCountries } = useSelector(state => state.settings.data);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(accountInfo?.location);

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const formSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegExp, commonT('nrNotValid')),
    year: Yup.object().required(commonT('requiredField')),
    month: Yup.object().required(commonT('requiredField')),
    day: Yup.object().required(commonT('requiredField')),
    gender: Yup.object().required(commonT('requiredField')),
    dominantHand: Yup.string().required(commonT('requiredField')),
  });

  const months = [
    { value: 1, label: commonT('january') },
    { value: 2, label: commonT('february') },
    { value: 3, label: commonT('march') },
    { value: 4, label: commonT('april') },
    { value: 5, label: commonT('may') },
    { value: 6, label: commonT('june') },
    { value: 7, label: commonT('july') },
    { value: 8, label: commonT('august') },
    { value: 9, label: commonT('september') },
    { value: 10, label: commonT('october') },
    { value: 11, label: commonT('november') },
    { value: 12, label: commonT('december') },
  ];

  const genders = [
    { value: 'male', label: commonT('male') },
    { value: 'female', label: commonT('female') },
  ];

  const getNextStep = () => {
    let list = [];
    profileCompletion?.forEach(step => {
      if (!step.completed) {
        list = [...list, step.path];
      }
    });

    const index = list.findIndex(path => path === '/my-account/profile-details') + 1;

    return list.length && list[index]
      ? list[index]
      : '/dashboard?congrats';
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (isSubmitting) {
        if (success) {
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(updateAccountInfoState({
            ...accountInfo,
            ...data,
          }));
          dispatch(getAccountInfo());
          // history.push('/my-account/profile-visibility');
          history.push(getNextStep());
        } else {
          addToast(commonT(message), {
            appearance: 'error',
            autoDismiss: true,
          });
          setIsSubmitting(false);
        }
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'accounts-response',
      );
      socket.on(
        'accounts-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'accounts-response',
        );
      };
    }
  }, [socketClientId, isSubmitting]); // eslint-disable-line

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(eligibleCountry => ({
    value: (eligibleCountry.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${eligibleCountry.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${eligibleCountry.key}`)}</P>
      </Country>
    ),
  }));

  const logProfileSubmission = (payload) => {
    if (accountInfo?.userId) {
      mixpanel.identify(accountInfo.mixpanelId);
      mixpanel.people.set({
        sp_profile_city: payload.location?.city,
        sp_profile_country: payload.location?.country,
        sp_gender: payload.gender,
        sp_dominant_hand: payload.dominantHand,
        sp_account_id: accountInfo.userId,
      });
      mixpanel.track('Fill in profile details');
    }
  };

  const isSSOAccount = accountInfo?.registrationMethod === 'google_account'
    || accountInfo?.registrationMethod === 'facebook';

  return (
    <Container details>
      <Logout />
      <H2 className="mb10">{t('completeProfile.profileDetails')}</H2>
      <P className="mb45">{t('completeProfile.fillProfileDetails')}</P>
      <FormContainerDetails>
        <Formik
          initialValues={{
            location: accountInfo?.location,
            phone: accountInfo?.phone,
            gender: commonT(accountInfo?.gender),
            dominantHand: accountInfo?.dominantHand,
          }}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            setIsSubmitting(true);

            let birthDate = new Date();
            birthDate.setFullYear(values?.year?.value);
            birthDate.setMonth(values?.month?.value - 1);
            birthDate.setDate(values?.day?.value);

            birthDate = dateFormat(birthDate, 'yyyy-mm-dd');

            const payload = {
              gender: values.gender.value,
              birthDate,
              dominantHand: values.dominantHand,
              location: userLocation,
              phone: values.phone,
              referralCode: localStorage.getItem('referralCode'),
            };

            localStorage.removeItem('referralCode');

            logProfileSubmission(payload);

            await updateAccount({
              id: accountInfo.id,
              payload,
              addToast,
              socketClientId,
              dispatch,
              history,
              t: commonT,
            });
          }}
        >
          {({
            errors,
            touched,
            dirty,
            isValid,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="w100">
              {isSSOAccount && (
                <>
                  <Select
                    name="country"
                    onChange={setCountry}
                    placeholder={commonT('country')}
                    label={commonT('country')}
                    options={countries}
                    isSearchable={false}
                    height="50px"
                    lowInput
                    inputLabel
                    {...!isEmpty(country) && { labelOn: true }}
                    {...{ errors, touched }}
                  />

                  {country?.value && (
                    <SelectGoogleAutocomplete
                      name="city"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: [country?.value || country],
                        },
                      }}
                      minLengthAutocomplete={3}
                      hook={(e) => setUserLocation(e?.value)}
                      placeholder={commonT('city')}
                      label={commonT('city')}
                      isClearable
                      {...userLocation && {
                        value: { label: userLocation?.city, value: userLocation },
                      }}
                      {...!isEmpty(userLocation) && { lowInput: true }}
                      {...!isEmpty(userLocation) && { labelOn: true }}
                      {...{ errors, touched }}
                    />
                  )}
                </>
              )}

              {isEmpty(accountInfo?.phone)
                && (
                  <Input
                    name="phone"
                    type="tel"
                    placeholder={commonT('phoneNumber')}
                    value={values.phone !== accountInfo?.phone && values.phone}
                    solidPlaceholder
                    {...{ errors, touched }}
                  />
                )}

              <P xSmall>{commonT('birthdate')}</P>
              <Contain height="65px" className="birthdate">
                <Select
                  name="year"
                  placeholder={t('completeProfile.year')}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={years}
                  label={commonT('year')}
                  isSearchable={false}
                  {...{ errors, touched }}
                />

                <Select
                  name="month"
                  placeholder={t('completeProfile.month')}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={months}
                  label={commonT('month')}
                  isSearchable={false}
                  {...{ errors, touched }}
                />

                <Select
                  name="day"
                  placeholder={t('completeProfile.day')}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={days}
                  label={commonT('day')}
                  isSearchable={false}
                  {...{ errors, touched }}
                />
              </Contain>
              <Select
                name="gender"
                placeholder={t('completeProfile.gender')}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                options={genders}
                label={t('completeProfile.gender')}
                isSearchable={false}
                {...{ errors, touched }}
              />

              <P className="text-center">{t('completeProfile.chooseDominantHand')}</P>
              <div className="custom-radio" role="group" aria-labelledby="dominantHand">
                <Field type="radio" id="leftHand" name="dominantHand" value="left" />
                <label htmlFor="leftHand">
                  <img src={CheckmarkWhite} alt="" />
                  {t('completeProfile.left')}
                </label>

                <Field type="radio" id="rightHand" name="dominantHand" value="right" />
                <label htmlFor="rightHand">
                  <img src={CheckmarkWhite} alt="" />
                  {t('completeProfile.right')}
                </label>
              </div>

              <Button
                width="100%"
                type="submit"
                disabled={isSubmitting
                  || !(isValid && dirty)
                  || ((!userLocation || !country?.value) && isSSOAccount)}
              >
                {t('completeProfile.continue')}
                <img src={LoadImage('white-arrow-right.svg')} alt="Submit Button" />
              </Button>
            </Form>
          )}
        </Formik>
      </FormContainerDetails>
    </Container>
  );
};

export default SetProfileDetails;
