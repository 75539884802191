import { useTranslation } from 'react-i18next';

export const timeSince = (date) => {
  const { t } = useTranslation('time');
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return t('yearsAgo', { time: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return t('monthsAgo', { time: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return t('daysAgo', { time: Math.floor(interval) });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return t('hoursAgo', { time: Math.floor(interval) });
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return t('minutesAgo', { time: Math.floor(interval) });
  }
  return t('justNow');
};
