import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat, { i18n } from 'dateformat';
import { useToasts } from 'react-toast-notifications';

import mixpanel from '../../../../mixpanel';
import { P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import { timeUntil } from '../../../../components/common/timeUntil';
import buttonsActions from '../helpers/buttonsActions';

import {
  FriendIcon,
  MatchType,
  PlayerMetaData,
  Username,
} from '../../StartMatch/components/WantToPlay/styles';

import {
  MatchContainer,
  MatchContent,
  SecondPart,
  FirstPart,
  ThirdPart,
  VSText,
  RankedIcon,
  MatchGametype,
  MatchStatusBadge,
  BoldCapitalize,
} from '../styles';

import {
  acceptMatch,
  declineMatch,
  noMatch,
  withdraw,
  cancelMatch,
} from '../../reducers';

import MatchesExpandedContainer from '../../components/Modals/MatchesDropdown';
import { MatchButtons } from './components/MatchButtons';
import { InvitationButtons } from './components/InvitationButtons';
import { formatMatchId, getLocalDate } from '../../helpers';
import { addConversation } from '../../../Chat/actions';
import LinkWrapper from '../../../../components/Link';
import MatchWarnings from './components/MatchWarnings';
import getUserPosition from '../helpers/getUserPosition';
import DisplayPlayerInfo from './components/DisplayPlayerInfo';

const Match = ({
  match,
  isARequest,
  setModalType,
  modalType,
  toggleModal,
}) => {
  const { t } = useTranslation('friendlyMatches');
  const { t: commonT } = useTranslation('common');
  const { t: chatT } = useTranslation('conversations');
  const { t: timeT } = useTranslation('time');

  i18n.dayNames = [
    commonT('Sun'),
    commonT('Mon'),
    commonT('Tue'),
    commonT('Wed'),
    commonT('Thu'),
    commonT('Fri'),
    commonT('Sat'),
  ];

  i18n.monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    commonT('January'),
    commonT('February'),
    commonT('March'),
    commonT('April'),
    commonT('May'),
    commonT('June'),
    commonT('July'),
    commonT('August'),
    commonT('September'),
    commonT('October'),
    commonT('November'),
    commonT('December'),
  ];

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { isModalVisible, setIsModalVisible } = toggleModal || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const { accountInfo } = useSelector(state => state?.session);
  const myPosition = getUserPosition(match, accountInfo?.userId);

  const {
    _id,
    gameType,
    player,
    playerPartner,
    opponent,
    opponentPartner,
  } = match || {};

  const isOnHold = !![playerPartner?.invitation, opponent.invitation, opponentPartner?.invitation]
    .filter(status => ['declined', 'withdrawn', 'noMatch'].includes(status)).length;

  const isDoubles = gameType === 'doubles';

  if (myPosition === 'none') {
    return '';
  }

  const opponentForSingles = myPosition === 'player' ? opponent : player;
  const age = getAgeFromDate(opponentForSingles.birthDate);
  const matchStartDate = getLocalDate(match.startDate, match.timezone);
  const matchStartDateFormated = match.pastMatch
    ? dateFormat(matchStartDate, 'd mmmm (ddd)') : dateFormat(matchStartDate, 'd mmmm (ddd), HH:MM');
  const scoreAddedDate = new Date(match.scoreAddedDate);

  const timeUntilScoreValidation = match.scoreAddedDate && !isOnHold
    ? timeUntil(scoreAddedDate.setHours(scoreAddedDate.getHours() + 48), timeT, 'hours') : '';

  const now = new Date();
  const displayPlayedTag = (
    !!(player.score || []).length
    || ((now > (new Date(match.startDate)).setHours(matchStartDate.getHours() + 2))
      && match[myPosition].invitation === 'accepted')
  )
    && match.status === 'active';

  const modalHandler = (type) => {
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const customDispatch = (action) => {
      dispatch(action);
      setIsExpanded(false);
    };
    const acceptMatchAction = () => {
      dispatch(acceptMatch({ id: _id }));
      mixpanel.track('ACCEPT MATCH (INVITE)', {
        sp_match_type: match.ranked ? 'Ranked' : 'Unranked',
        sp_game_type: match.gameType,
        sp_start_date: matchStartDate.toString(),
        sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
        sp_game_location_city: match.location?.city,
        sp_game_location_country: match.location?.country,
        sp_game_format: match.format,
        sp_match_id: formatMatchId(match.matchId),
      });
      setIsExpanded(false);
    };
    setModalType({
      ...modalType,
      isDoubles,
      match,
      type,
      actions: {
        first: () => setIsModalVisible(false),
        second: type === 'accept' ? () => acceptMatchAction()
          : type === 'decline' ? () => customDispatch(declineMatch({ id: _id }))
            : type === 'noMatchFromRequestsTab' ? () => customDispatch(noMatch({ id: _id }))
              : type === 'noMatchFromMatchesTab' ? () => customDispatch(noMatch({ id: _id }))
                : type === 'withdraw' ? () => customDispatch(withdraw({ id: _id }))
                  : type === 'cancel' ? () => customDispatch(cancelMatch({ id: _id }))
                    : () => { },
        cancelModal: () => setIsModalVisible(false),
      },
      setIsExpanded,
    });

    return setIsModalVisible(!isModalVisible);
  };

  const buttonsToShow = buttonsActions({
    match,
    myPosition,
    isOnHold,
  });

  const initiateConversation = async (source) => {
    mixpanel.track('Friendly matches CHAT', {
      sp_asset_type: match.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
      sp_match_type: match.ranked ? 'Ranked' : 'Unranked',
      sp_game_type: match.gameType,
      sp_game_location_city: match.location?.city,
      sp_game_location_country: match.location?.country,
      sp_game_format: match.format,
      sp_action_source: source,
      sp_match_id: formatMatchId(match.matchId),
    });
    const members = [match.player, match.opponent];
    if (match.gameType === 'doubles') {
      members.push(match.playerPartner, match.opponentPartner);
    }

    const payload = {
      members: members.filter(({ userId }) => Number(accountInfo?.userId) !== userId),
    };

    await addConversation({
      payload,
      addToast,
      t: chatT,
    });
  };

  const toggleExpanded = (expanded) => {
    if (expanded) {
      mixpanel.track('Friendly Matches EXPAND Match View', {
        sp_asset_type: match.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
        sp_match_type: match.ranked ? 'Ranked' : 'Unranked',
        sp_game_type: match.gameType,
        sp_action_source: isARequest ? 'requests' : 'matches',
      });
    }
    setIsExpanded(expanded);
  };

  return (
    <MatchContainer isExpanded={isExpanded}>
      {!isExpanded && (
        <MatchContent>
          <FirstPart isDoubles={isDoubles}>
            {isDoubles && (
              <>
                <DisplayPlayerInfo
                  match={match}
                  position="player"
                  isDoubles
                  myPosition={myPosition}
                />
                <DisplayPlayerInfo
                  match={match}
                  position="playerPartner"
                  isDoubles
                  myPosition={myPosition}
                />
                <VSText large bold>VS</VSText>
                <DisplayPlayerInfo
                  match={match}
                  position="opponent"
                  isDoubles
                  myPosition={myPosition}
                />
                <DisplayPlayerInfo
                  match={match}
                  position="opponentPartner"
                  isDoubles
                  myPosition={myPosition}
                />
              </>
            )}
            {!isDoubles && (
              <>
                <DisplayPlayerInfo
                  match={match}
                  position={myPosition === 'player' ? 'opponent' : 'player'}
                  myPosition={myPosition}
                />
                <PlayerMetaData>
                  <LinkWrapper to={`/accounts/${opponentForSingles.id}`}>
                    <Username>
                      {opponentForSingles?.isFriend && (
                        <FriendIcon
                          src={LoadImage('friendly-matches/start-match/friends.svg')}
                          alt="One of your friends"
                        />
                      )}
                      {`${opponentForSingles.firstName} ${opponentForSingles.lastName}`}
                    </Username>
                  </LinkWrapper>
                  <P small>
                    {(age && `${age} - `) || ''}
                    {' '}
                    {opponentForSingles.location?.name}
                  </P>
                </PlayerMetaData>
              </>
            )}
          </FirstPart>
          <SecondPart>
            <MatchType
              src={LoadImage(`friendly-matches/matches/${match.gameType}-match.svg`)}
              alt={t(match.gameType)}
            />
            <PlayerMetaData>
              <MatchGametype>
                <BoldCapitalize>
                  <Trans ns="friendlyMatches" i18nKey={match.gameType === 'singles' ? 'singlesMatch' : 'doublesMatch'}>
                    {match.gameType}
                    {' '}
                    Match
                  </Trans>
                </BoldCapitalize>
                {match.ranked
                  && <RankedIcon src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt={t('ranked')} />}
                {myPosition === 'player' && (
                  <MatchStatusBadge isYourMatch>
                    <Trans ns="friendlyMatches" i18nKey="yourMatch">Your Match</Trans>
                  </MatchStatusBadge>
                )}
                {displayPlayedTag && (
                  <MatchStatusBadge>
                    <Trans ns="friendlyMatches" i18nKey="played">Played</Trans>
                  </MatchStatusBadge>
                )}
                <span className="match-id">{formatMatchId(match.matchId)}</span>
              </MatchGametype>
              <P className="location">
                {matchStartDateFormated}
                {` - ${match.location?.clubName ? match.location?.clubName : match.location?.name}`}
              </P>
            </PlayerMetaData>
          </SecondPart>
          <ThirdPart height="max-content">
            <MatchWarnings
              match={match}
              myPosition={myPosition}
              isOnHold={isOnHold}
              timeUntilScoreValidation={timeUntilScoreValidation}
            />

            {isARequest
              ? (
                <InvitationButtons
                  isOnHold={isOnHold}
                  buttonsToShow={buttonsToShow}
                  modalHandler={modalHandler}
                  isExpanded={isExpanded}
                  setIsExpanded={toggleExpanded}
                  timeUntilScoreValidation={timeUntilScoreValidation}
                  initiateConversation={() => initiateConversation('requests')}
                />
              )
              : (
                <MatchButtons
                  isOnHold={isOnHold}
                  buttonsToShow={buttonsToShow}
                  modalHandler={modalHandler}
                  isExpanded={isExpanded}
                  setIsExpanded={toggleExpanded}
                  match={match}
                  timeUntilScoreValidation={timeUntilScoreValidation}
                  accountInfo={accountInfo}
                  initiateConversation={() => initiateConversation('matches')}
                />
              )}
          </ThirdPart>
        </MatchContent>
      )}

      {isExpanded && (
        <MatchesExpandedContainer
          isOnHold={isOnHold}
          buttonsToShow={buttonsToShow}
          match={match}
          timeUntilScoreValidation={timeUntilScoreValidation}
          myPosition={myPosition}
          displayPlayedTag={displayPlayedTag}
          matchStartDateFormated={matchStartDateFormated}
          modalHandler={modalHandler}
          exitHandler={() => setIsExpanded(false)}
          initiateConversation={() => initiateConversation(isARequest ? 'requests' : 'matches')}
          isDoubles={isDoubles}
        />
      )}
    </MatchContainer>
  );
};

export default Match;
