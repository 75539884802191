import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  flex: 'none',
  width: 60,
  height: 60,
  borderRadius: '50%',
  marginRight: 10,
  position: 'relative',
  overflow: 'hidden',

  '.image, .initials': {
    position: 'absolute',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
    cursor: 'pointer',

    img: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },

  '.initials': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 60,
    borderRadius: '50%',
    background: 'white',
    border: `1px solid ${theme.colors.lightBlue2}`,

    span: {
      fontWeight: 'bold',
    },
  },

  '&.group-conversation': {
    borderRadius: 0,

    '>:nth-of-type(1)': {
      right: 0,
      top: 0,
    },

    '>:nth-of-type(2)': {
      left: 0,
      bottom: 0,
    },

    '.plus-members': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
      borderRadius: '50%',
      border: `2px solid ${theme.colors.lightBlue2}`,
      background: theme.colors.white,
      right: 0,
      bottom: 5,
      fontWeight: 'bold',
      fontSize: 12,
    },

    '.image, .initials': {
      width: '65%',
      maxWidth: '65%',
      height: '65%',
      border: '2px solid white',
    },
  },
}));
