import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import LoadImage from '../../../../../../../../../components/common/LoadImage';
import ToggleVisible from '../../../../../../../../../components/common/ToggleVisible';
import { acceptFriend, addFriend, deleteFriend } from '../reducers';
import UnfriendModal from './components/UnfriendModal';
import mixpanel from '../../../../../../../../../mixpanel';
import { UserContext } from '../../../../..';
import { VisitedProfileButton } from '../../../styles';

const AddFriendOption = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    user, isBlocked, iAmBlocked, socketClientId, socket,
  } = useContext(UserContext);
  const {
    isComponentVisible: isUnfriendModalVisible,
    setIsComponentVisible: setUnfriendModalVisibility,
  } = ToggleVisible(false);
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');

  const [friendStatus, setFriendStatus] = useState(user?.friendStatus);
  const [sentRequests, setSentRequests] = useState(0);
  const [isFriendsButtonEnabled, enableFriendsButton] = useState(true);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      switch (message) {
      case 'CANCEL_FRIEND_SUCCESS':
      case 'REMOVE_FRIEND_SUCCESS':
        enableFriendsButton(true);
        setFriendStatus(null);
        return addToast(commonT(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
        });
      case 'ADD_FRIEND_SUCCESS':
        if (success) {
          enableFriendsButton(true);
          return addToast(commonT(message), {
            appearance: success ? 'success' : 'error',
            autoDismiss: true,
          });
        }
        break;
      default:
        break;
      }
    };

    if (socket) {
      socket.removeAllListeners('friends-response');
      socket.on('friends-response', listenForResponse);

      return () => {
        socket.removeAllListeners('friends-response');
      };
    }
  });

  const buttonAction = {
    addFriend: () => {
      if (sentRequests < 10) {
        dispatch(addFriend({ friendUserId: user?.userId, socketClientId }));
        setFriendStatus('sent');
        enableFriendsButton(false);
        setSentRequests(sentRequests + 1);
        return mixpanel.track('Add Friend (Sent request from Player Profile)');
      }
      return false;
    },
    confirmRequest: () => {
      dispatch(acceptFriend({ friendUserId: Number(user?.userId), socketClientId }));
      return setFriendStatus('accepted');
    },
    cancelRequest: () => {
      if (sentRequests < 10) {
        dispatch(deleteFriend({ data: { friendUserId: Number(user?.userId), socketClientId } }));
        setFriendStatus(null);
        setUnfriendModalVisibility(false);
        enableFriendsButton(false);
        setSentRequests(sentRequests + 1);
        return mixpanel.track('Cancel Friendship (Cancel request sent from Player Profile)');
      }
      return false;
    },
    friends: () => setUnfriendModalVisibility(!isUnfriendModalVisible),
  };

  // Dynamically defining our icon
  const addFriendIcon = ((!friendStatus || friendStatus === 'deleted') && !isBlocked) ? 'add-friend'
    : (friendStatus === 'sent' && !isBlocked) ? 'cancel-request'
      : (friendStatus === 'received' && !isBlocked) ? 'confirm-friend' : 'friend-confirmed';

  // Dynamically defining our trans key (used for buttonAction object)
  const addFriendTrans = ((!friendStatus || friendStatus === 'deleted') && !isBlocked) ? 'addFriend'
    : (friendStatus === 'sent' && !isBlocked) ? 'cancelRequest'
      : (friendStatus === 'received' && !isBlocked)
        ? 'confirmRequest' : (friendStatus === 'accepted' && !isBlocked) ? 'friends' : 'addFriend';

  const alreadyFriends = friendStatus === 'accepted';

  return !iAmBlocked && !isBlocked && (
    <>
      <VisitedProfileButton
        onClick={buttonAction[addFriendTrans]}
        disabled={!isFriendsButtonEnabled}
        outline={alreadyFriends}
      >
        <img src={LoadImage(`player-profile/${addFriendIcon}.svg`)} alt={t(addFriendTrans)} />
        <Trans ns="settings" i18nKey={addFriendTrans}>
          Add Friend
        </Trans>
      </VisitedProfileButton>
      {isUnfriendModalVisible && (
        <UnfriendModal
          handler={() => {
            setUnfriendModalVisibility(!isUnfriendModalVisible);
          }}
          setFriendStatus={setFriendStatus}
          isPlayerProfile
        />
      )}
    </>
  );
};

export default AddFriendOption;
