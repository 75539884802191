import { post, patch } from '../../../components/common/http';

export const activateAccount = async (id, activationCode, socketClientId) => {
  try {
    const { data } = await patch(
      `/accounts/${id}/activate/${activationCode}`,
      {
        id,
        activationCode,
        socketClientId,
      },
    );

    return data;
  } catch (error) {
    return error.response?.data;
  }
};

export const resendActivationEmail = async ({ id, activationCode, socketClientId }) => {
  try {
    const { data } = await post(
      `/accounts/${id}/activate/${activationCode}/resend`,
      {
        id,
        activationCode,
        socketClientId,
      },
    );

    return data;
  } catch (error) {
    return error.response?.data;
  }
};
