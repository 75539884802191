import React from "react";
import { Trans } from "react-i18next";
import Button from "../../../components/Button";
import Link from "../../../components/Link";

const FooterButtons = ({ purchaseType, paymentInfo }) => (
  <div className="buttonsWrap">
    {purchaseType === 'eventRegistration' && (
      <div className="btn">
        <Button large fullwidth>
          <Link to={`/events/${paymentInfo?.event?._id}/competitions/${paymentInfo?.competition?._id}`}>
            <Trans ns="paymentConfirmation" i18nKey="goToEvent">Go to Event</Trans>
          </Link>
        </Button>
      </div>
    )}
    {purchaseType === 'topUp' && (
      <div className="btn">
        <Button large fullwidth>
          <Link to="/my-account/wallet">
            <Trans ns="paymentConfirmation" i18nKey="goToVirtualWallet">Go to Virtual Wallet</Trans>
          </Link>
        </Button>
      </div>
    )}
    {purchaseType === 'upgradeMembership' && (
      <div className="btn">
        <Button large fullwidth>
          <Link to="/my-account">
            <Trans ns="paymentConfirmation" i18nKey="goToMyProfile">Go to My Profile</Trans>
          </Link>
        </Button>
      </div>
    )}

    {purchaseType !== 'courtBooking' && (
      <div className="btn">
        <Button large outline fullwidth>
          <Link to="/dashboard">
            <Trans ns="paymentConfirmation" i18nKey="goToDashboard">Go to Dashboard</Trans>
          </Link>
        </Button>
      </div>
    )}
  </div>
);

export default FooterButtons;
