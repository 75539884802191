import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { P } from '../../../components/Collection';
import LinkWrapper from '../../../components/Link';
import LoadImage from '../../../components/common/LoadImage';
import { formatMatchId } from '../helpers';
import { removePlaceholder } from '../reducers';
import { PlaceholderContainer, PlaceholderDestination, ClosePlaceholder } from "../styles";

const MatchMovedPlaceholder = ({ match, list }) => {
  const { placeholderTranslation: translation, matchId, placeholderDestination: destination } = match;
  const dispatch = useDispatch();
  const { t: friendlyMatchesT } = useTranslation('friendlyMatches');
  const { t: commonT } = useTranslation('common');
  return (
    <PlaceholderContainer>
      <P>
        {friendlyMatchesT(translation, { matchId: formatMatchId(matchId) })}
        <PlaceholderDestination>
          <LinkWrapper to={`/friendly-matches/${destination}`}>
            {friendlyMatchesT(destination === 'scores' ? 'scores' : 'matches')}
          </LinkWrapper>
        </PlaceholderDestination>
      </P>
      <ClosePlaceholder
        src={LoadImage('friendly-matches/matches/x-icon.svg')}
        alt={commonT('close')}
        onClick={() => dispatch(removePlaceholder({ list }))}
      />
    </PlaceholderContainer>
  );
};

export default MatchMovedPlaceholder;
