import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useToasts } from 'react-toast-notifications';

import {
  WithdrawModalStyle,
  LevelModalContainer,
  Container,
  LevelContainer,
  Data,
  Item,
  Value,
  DataContainer,
  Info,
} from './styles';

import GoToCompetition from './components/button';
import Restrictions from './components/restrictions';
import { Badge as ShieldBadge } from '../../../../../components/LevelsBadges/styles';

import {
  Link, P, Button, H3,
} from '../../../../../components/Collection';

import getCountryIsoCode from '../../../../../components/common/getCountryIsoCode';
import Modal from '../../../../../components/Modal/Modal';
import ShowList from '../ShowList';
import getRegistrationFee from '../../../../../components/common/getRegistrationFee';
import { fetchTransactions } from '../../../../MyAccount/Wallet/reducers';
import isRegisteredToCompetition from '../../../../../components/common/isRegisteredToCompetition';
import getCompetitionListTypeEntry from '../../../../../components/common/getCompetitionListTypeEntry';
import hasRestrictions from '../../../../../components/common/competitionHasRestrictions';
import mixpanel from '../../../../../mixpanel';
import PartnerForDoubles from '../../../components/PartnerForDoubles';

import { store as socketStore } from '../../../../../components/Socket/store';

import { withdrawFromEvent } from '../../../actions';

import { fetchEventDetails } from '../../../reducers';
import { getAccountInfo } from '../../../../../components/Layout/reducers/session';
import { fetchCompetitions } from '../../../../Dashboard/components/UpcomingCompetitions/reducers';
import { Paragraph } from '../../../../../components';

const CompetitionSummary = props => {
  const {
    eventInfo,
    competitionInfo,
    eventStatus,
    displayType,
  } = props;

  const { eventId } = useParams();
  const { addToast } = useToasts();
  const globalState = useContext(socketStore);
  const dispatch = useDispatch();
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('events');
  const { accountInfo, isLoggedIn } = useSelector(state => state.session);
  const [levelModal, setLevelModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [withdrawProgress, setWithdrawProgress] = useState(false);
  const [modal, setModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState();
  const [fees, setFees] = useState();
  const [priceInfo, setPriceInfo] = useState();
  const isDoubles = competitionInfo?.gameType === 'doubles';

  if (!competitionInfo) {
    return false;
  }

  const currentPhase = eventInfo?.currentPhase?.status;

  const isBeforeClosingRegistrations = new Date() < new Date(eventInfo?.phases?.playing?.endDate);

  const {
    mlMaxNumber,
    wildCards,
    mainList,
    preRegistrationList,
  } = competitionInfo;

  const wildCardsOnML = mainList?.filter(player => player?.slotType === 'WILD_CARD')?.length;
  const wildCardsOnSL = preRegistrationList?.filter(player => player?.slotType === 'WILD_CARD')?.length;

  const availableSlots = Math.max(
    0,
    Number(mlMaxNumber)
    - Number(wildCards - wildCardsOnML)
    - Number(mainList?.length),
  );

  const availableSlotsSl = Math.max(
    0,
    Number(mlMaxNumber)
    - Number(wildCards - wildCardsOnSL)
    - Number(preRegistrationList?.length),
  );

  const registrationMLStatus = eventStatus === 'registrationsOpen' ? 'registrationsOpen' : 'registrationClosed';
  const preRegistrationSLStatus = eventStatus === 'preRegistrationsOpen'
    ? 'preRegistrationsOpen' : 'preRegistrationClosed';

  const registrationMLListStatus = (eventStatus === 'registrationsOpen' && availableSlots === 0) ? 'Full' : '';
  const preRegistrationSLListStatus = (eventStatus === 'preRegistrationsOpen' && availableSlots === 0) ? 'Full' : '';

  const registrationWLStatus = isBeforeClosingRegistrations ? 'registrationsOpen' : 'registrationClosed';
  const registrationWLListStatus = competitionInfo?.waitingList.length === 0 ? 'All' : '';

  const badgeStatus = {
    sl: {
      bg: eventStatus === 'preRegistrationsOpen' ? 'lightGreen' : 'secondaryRed',
      color: 'black',
      availableSlotsSl,
      translationKey: `${preRegistrationSLStatus}SortList${preRegistrationSLListStatus}`,
    },
    ml: {
      bg: eventStatus === 'registrationsOpen' ? (availableSlots > 0 ? 'lightGreen' : 'grey') : 'secondaryRed',
      color: 'black',
      availableSlots,
      // eslint-disable-next-line max-len
      translationKey: `${registrationMLStatus}MainList${registrationMLListStatus}${availableSlots === 0 || availableSlots > 1 ? '_plural' : ''}`,
    },
    wl: {
      bg: ['resultsPending', 'ended'].includes(eventStatus) ? 'secondaryRed' : 'yellow',
      color: 'black',
      occupiedSlots: competitionInfo?.waitingList?.length,
      translationKey: `${registrationWLStatus}WaitingList${registrationWLListStatus}`,
    },
  };

  const restrictions = hasRestrictions(competitionInfo);
  // check competition fee tax
  if (competitionInfo) {
    const { currency } = getCountryIsoCode(eventInfo?.club?.location?.country);
    const [eventMainFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'RON' : accountInfo?.currency));
    const [eventSecondaryFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'BGN' : currency));

    if (isRegistered === undefined) {
      setIsRegistered(isRegisteredToCompetition(accountInfo?.userId, competitionInfo));
    }

    if (!fees) {
      setFees({
        primary: eventMainFees,
        secondary: eventSecondaryFees,
      });
    }

    if (!priceInfo) {
      setPriceInfo({
        primary: `${eventMainFees?.free} ${eventMainFees?.currency}`,
        secondary: `${eventSecondaryFees?.free} ${eventSecondaryFees?.currency}`,
        primaryClubFee: `${eventMainFees?.clubEntryFee} ${eventMainFees?.currency}`,
        secondaryClubFee: `${eventSecondaryFees?.clubEntryFee} ${eventSecondaryFees?.currency}`,
        membershipDiscountedPrice: getRegistrationFee({
          user: accountInfo,
          competitionInfo,
          competitions: eventInfo?.competitions,
          playerCurrency: accountInfo?.currency,
        }),
        currency: eventMainFees?.currency,
      });
    }
  }

  const modalHandler = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });

        setWithdrawProgress(false);
        setWithdrawModal(false);
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'events-response',
      );
      socket.on(
        'events-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'events-response',
        );
      };
    }
  }, [socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (success) {
        if (message === 'EVENT_WITHDRAWAL_SUCCESSFULLY') {
          dispatch(fetchTransactions());
          dispatch(getAccountInfo());
          dispatch(fetchEventDetails(eventId));
          dispatch(fetchCompetitions());
        }

        addToast(commonT(message), {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      setWithdrawProgress(false);
      setWithdrawModal(false);
    };

    if (socket) {
      socket.removeAllListeners(
        'payments-response',
      );
      socket.on(
        'payments-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'payments-response',
        );
      };
    }
  }, [socketClientId]);

  useEffect(() => {
    if (withdrawModal) {
      mixpanel.track('Withdrawal Initiated', {
        sp_whithdrawal_initiated_type: withdrawalModalType,
        sp_whithdrawal_initiated_competition_type: competitionInfo.gameType,
        sp_whithdrawal_initiated_competition_level: competitionInfo.level,
      });
    }
  }, [withdrawModal]);

  const withdrawHandler = async () => {
    setWithdrawProgress(true);

    await withdrawFromEvent({
      eventId: eventInfo._id,
      competitionId: competitionInfo._id,
      addToast,
      socketClientId,
      t: commonT,
    });

    mixpanel.track('Withdrawal Complete', {
      sp_whithdrawal_initiated_type: withdrawalModalType,
      sp_whithdrawal_initiated_competition_type: competitionInfo.gameType,
      sp_whithdrawal_initiated_competition_level: competitionInfo.level,
    });
    mixpanel.identify(accountInfo?.mixpanelId ?? accountInfo?.userId);
  };

  const listType = getCompetitionListTypeEntry(accountInfo?.userId, competitionInfo);

  const now = new Date();
  const date1 = new Date(competitionInfo?.startDate);
  const minus12 = new Date(date1.setHours(date1.getHours() - 12));
  const date2 = new Date(competitionInfo?.startDate);
  const minus24 = new Date(date2.setHours(date2.getHours() - 24));
  const end = new Date(eventInfo?.phases?.registrationsOpen?.endDate);
  const isWL = listType === 'waitingList';

  const withdrawalModalType = (currentPhase === 'preRegistrationsOpen' || currentPhase === 'registrationsOpen') && !isWL
    ? 'early'
    : (now > end && now < minus24) || isWL ? 'announced'
      : now > minus24 && now < minus12 ? 'late' : 'denied';

  const withdrawalInfo = {
    title: t(`withdrawal.${withdrawalModalType}.title`, { list: t(listType) }),
    subtitle: t(`withdrawal.${withdrawalModalType}.subtitle`),
    desc: t(`withdrawal.${withdrawalModalType}.desc`),
  };

  const hasGroups = competitionInfo.numberOfGroups > 0;
  const hasPhases = competitionInfo?.phases?.length > 0;

  return (
    <>
      <Container>
        <LevelContainer>
          <Trans ns="common" i18nKey="level">Level</Trans>
          <ShieldBadge large double={competitionInfo.gameType === 'doubles'}>
            <H3>{competitionInfo?.level}</H3>
          </ShieldBadge>
        </LevelContainer>
        <DataContainer>
          <Data>
            <Info>
              <Item bold>
                <Trans ns="events" i18nKey="gameTypeText">Game Type</Trans>
                :
                <Value><Trans ns="events" i18nKey={competitionInfo?.gameType} /></Value>
              </Item>
              <Item bold>
                <Trans ns="events" i18nKey={competitionInfo.gameType === 'singles' ? 'tablePlayers' : 'tableGroups'}>
                  Draw:
                  <Value>
                    {{ players: competitionInfo?.mlMaxNumber }}
                    {' '}
                    Players
                  </Value>
                </Trans>
                <Value>
                  (
                  {hasGroups && `${competitionInfo.numberOfGroups} `}
                  <Trans ns="events" i18nKey={competitionInfo?.drawModel}>{competitionInfo?.drawModel}</Trans>
                  {hasPhases && (
                    <>
                      {' '}
                      +
                      <Trans i18nKey={competitionInfo?.phases[0]?.drawModel} ns="events">
                        {` + ${competitionInfo?.phases[0]?.drawModel}`}
                      </Trans>
                    </>
                  )}
                  )
                </Value>
              </Item>
            </Info>
            <Info>
              <Restrictions
                competitionInfo={competitionInfo}
                restrictions={restrictions}
              />
              <Item bold>
                <Trans ns="events" i18nKey="matchStart">
                  Match Start
                  <Value>{{ date: dateFormat(competitionInfo?.startDate, 'd mmm (ddd), HH:MM') }}</Value>
                </Trans>
              </Item>
            </Info>
          </Data>
        </DataContainer>
        {(accountInfo?.role !== 'club') && (
          <GoToCompetition
            listType={listType}
            setLevelModal={setLevelModal}
            levelModal={levelModal}
            setWithdrawModal={setWithdrawModal}
            withdrawModal={withdrawModal}
            isRegistered={isRegistered}
            isLoggedIn={isLoggedIn}
            priceInfo={priceInfo}
            eventStatus={eventStatus}
            displayType={displayType}
            eventInfo={eventInfo}
            accountInfo={accountInfo}
            competitionInfo={competitionInfo}
          />
        )}

        <Modal type2 nobackground noborder isShowing={levelModal} hide={() => setLevelModal(!levelModal)} small>
          <LevelModalContainer>
            <P padding="20px" margin="0 0 20px 0">
              <Trans ns="events" i18nKey="joinLevelMissing">
                Please set your game level before joining a competition!
              </Trans>
            </P>

            <Button padding="0 10px" margin="0 auto">
              <Link to="/my-account/game-level">
                <Trans ns="player" i18nKey="setYourGameLevel">
                  Set Your Game Level
                </Trans>
              </Link>
            </Button>
          </LevelModalContainer>
        </Modal>
        <Modal type2 hideClose nobackground noborder isShowing={withdrawModal} small>
          <WithdrawModalStyle>
            {withdrawalModalType !== 'denied' ? (
              <>
                <P small bold className="withdrawTitle">{withdrawalInfo.title}</P>

                {withdrawalInfo.subtitle !== '' ? (
                  <H3>
                    {withdrawalInfo.subtitle}
                  </H3>
                )
                  : ''}

                <P className="withdrawInfo" small>
                  <Trans>
                    {withdrawalInfo.desc}
                  </Trans>
                </P>

                <div className="modalButtonsWrapp">
                  <Button outline wider onClick={() => setWithdrawModal(!withdrawModal)}>
                    <Trans ns="events" i18nKey="cancel">Cancel</Trans>
                  </Button>
                  <Button
                    wider
                    background="red"
                    noBorder
                    color="white"
                    onClick={debounce(withdrawHandler, 150)}
                    disabled={withdrawProgress}
                  >
                    <Trans ns="events" i18nKey="withdraw">Withdraw</Trans>
                  </Button>
                </div>

                <Paragraph smaller className="mt20">
                  <Trans ns="events" i18nKey="withdrawal.disclaimer">
                    Please check our
                    <Link className="color-secondary" to="/rules-regulations">Sportya Rules and Regulations</Link> and
                    <Link className="color-secondary" to="/return-policy">Return Policy</Link>
                    for more information on withdrawals and refunds.
                  </Trans>
                </Paragraph>
              </>
            ) : (
              <>
                <H3>{withdrawalInfo.title}</H3>
                <P className="withdrawInfo" small>{withdrawalInfo.desc}</P>

                <div className="host-info">
                  <P large bold>{`${eventInfo?.organizer?.firstName} ${eventInfo?.organizer?.lastName}`}</P>
                  <P>
                    <Trans ns="common" i18nKey="phone">Phone</Trans>: {eventInfo?.organizer?.phone}
                  </P>
                  <P>
                    <Trans ns="common" i18nKey="email">Email</Trans>:
                    <a href={eventInfo?.organizer?.email}>{eventInfo?.organizer?.email}</a>
                  </P>
                </div>

                <div className="closeWrap">
                  <Button outline onClick={() => setWithdrawModal(!withdrawModal)}>
                    <Trans ns="common" i18nKey="close">Close</Trans>
                  </Button>
                </div>

                <Paragraph smaller>
                  <Trans ns="events" i18nKey="withdrawal.disclaimer">
                    Please check our
                    <Link className="color-secondary" to="/rules-regulations">Sportya Rules and Regulations</Link> and
                    <Link className="color-secondary" to="/return-policy">Return Policy</Link>
                    for more information on withdrawals and refunds.
                  </Trans>
                </Paragraph>
              </>
            )}
          </WithdrawModalStyle>
        </Modal>
      </Container>
      {isDoubles && (<PartnerForDoubles withPadding />)}

      {['competitionInfo'].includes(displayType) && !['openingSoon'].includes(eventStatus) && (
        <>
          <div className={
            (availableSlots === 0 || ['readyToPlay', 'playing', 'drawPending'].includes(currentPhase))
              || (competitionInfo?.waitingList.length > 0
                && ['ended'].includes(currentPhase)) ? 'listWrap' : 'singleWrap'
          }
          >
            {['preRegistrationsOpen'].includes(eventStatus) ? (
              <ShowList
                modalHandler={modalHandler}
                type="sortList"
                gameType={competitionInfo?.gameType}
                data={badgeStatus.sl}
                players={competitionInfo?.preRegistrationList}
                availableSlots={availableSlots}
                totalSlots={mlMaxNumber}
                reservedSlots={wildCards}
              />
            ) : (
              <ShowList
                modalHandler={modalHandler}
                type="mainList"
                gameType={competitionInfo?.gameType}
                data={badgeStatus.ml}
                players={mainList}
                availableSlots={availableSlots}
                totalSlots={mlMaxNumber}
                reservedSlots={wildCards}
              />
            )}
            {(availableSlots === 0 || ['readyToPlay', 'playing', 'drawPending'].includes(currentPhase))
              || (competitionInfo?.waitingList.length > 0 && ['ended'].includes(currentPhase)) ? (
                <ShowList
                  modalHandler={modalHandler}
                  type="waitingList"
                  gameType={competitionInfo?.gameType}
                  data={badgeStatus.wl}
                  players={competitionInfo?.waitingList}
                />
              ) : ''}
          </div>

          {['competitionInfo'].includes(displayType) && competitionInfo?.competitionDetailsPdf && (
            <div className="pdfWrap">
              <ShowList
                type="pdf"
                gameType={competitionInfo?.gameType}
                data={badgeStatus.wl}
                players={(competitionInfo?.waitingList)}
                pdf={competitionInfo?.competitionDetailsPdf}
                eventId={eventId}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CompetitionSummary;
