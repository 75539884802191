import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Bracket from '../Bracket';
import Group from '../Group';
import Soon from '../Soon';

import { fetchCompetitionMainDraw } from '../../../reducers';

import { MainDrawStyle } from './styles';

const MainDraw = ({ currentPhase, competitionInfo }) => {
  const dispatch = useDispatch();
  const { competitionId } = useParams();
  const { data = {} } = useSelector(state => state.events?.mainDraw);

  const {
    matches = [],
    groups = [],
    phases = [],
    qualifications = [],
    drawType = null,
  } = data;

  const duo = competitionInfo?.gameType === 'doubles';
  const mlNumber = competitionInfo?.phases[0]?.mlNumber;
  const noMainDrawStatus = ['openingSoon', 'preRegistrationsOpen', 'registrationsOpen', 'drawPending'];

  useEffect(() => {
    if (!noMainDrawStatus.includes(currentPhase)) {
      dispatch(fetchCompetitionMainDraw({ id: competitionId, mlNumber }));
    }
  }, [competitionId, dispatch]);

  const groupLetter = Array(26)
    .fill(null)
    .map((letter, idx) => (idx + 10).toString(36).toUpperCase());

  return (
    <MainDrawStyle>
      {matches?.length && !noMainDrawStatus.includes(currentPhase)
        ? (
          <>
            {/* Competition Groups */}
            {drawType === 'groups' ? (
              <>
                {/* List Groups & Matches */}
                <div className="groupWrapp">
                  {groups?.map((group, idx) => (
                    <Group
                      key={idx}
                      groups={group}
                      matches={matches[idx]}
                      letter={groupLetter[idx]}
                      type={duo}
                    />
                  ))}
                </div>

                {/* List Groups Eliminatory Phase */}
                {phases?.length ? <Bracket phase matches={phases} type={duo} /> : ''}
              </>
            ) : ''}

            {/* Competition Eliminatory */}
            {drawType === 'eliminatory' ? <Bracket matches={matches} qualifications={qualifications} type={duo} /> : ''}

          </>
        ) : <Soon />}
    </MainDrawStyle>
  );
};

export default MainDraw;
