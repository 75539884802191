import { post, patch } from '../../components/common/http';

export const addConversation = async ({
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await post('/chat/conversation', payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const addMessage = async ({
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await post('/chat/message', payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const leaveConversation = async ({
  conversationId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/leave`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const muteConversation = async ({
  conversationId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/mute`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const unmuteConversation = async ({
  conversationId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/unmute`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const deleteConversation = async ({
  conversationId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/delete`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const seenConversationMessages = async ({
  conversationId,
  payload = {},
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/seen`, payload);
  } catch (error) {
    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const deleteMessage = async ({
  messageId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/message/${messageId}/delete`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const addParticipants = async ({
  conversationId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await patch(`/chat/conversation/${conversationId}/add-participants`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};
