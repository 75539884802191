/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Input from '../../../components/Form/Input';

import { login } from './actions';

import { FormContainer, RememberMe } from './styles';
import { Container, Header, Logo } from '../Register/styles';
import {
  Button,
  Link,
  H2,
  P,
} from '../../../components/Collection';
import LoadImage from '../../../components/common/LoadImage';

const Login = ({ masterSport }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('login');
  const { t: commonT } = useTranslation('common');
  const { isLoggedIn } = useSelector(state => state.session);
  const { redirect } = useParams();

  const loginSchema = Yup.object().shape({
    password: Yup.string().required(commonT('requiredField')),
    email: Yup.string().email(commonT('invalidEmail')).required(commonT('requiredField')),
    remember: Yup.boolean(),
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn]);

  return (
    <Container Login>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>
      <Header Login>
        <Link to="/">
          <Logo src={LoadImage('logo.svg')} />
        </Link>
      </Header>
      <FormContainer>
        <H2 className="text-center">{t('title')}</H2>
        <P margin="10px 0 40px 0">{t('subTitle')}</P>
        <Formik
          initialValues={{
            email: '',
            password: '',
            remember: false,
          }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting }) => login({
            values,
            addToast,
            setSubmitting,
            dispatch,
            history,
            t: commonT,
            redirect,
            masterSport,
          })}
        >
          {({
            errors,
            touched,
            isSubmitting,
            values,
            isValid,
            dirty,
          }) => (
            <Form>
              <Input
                name="email"
                placeholder={t('placeholders.email')}
                type="email"
                value={values.email}
                {...{ errors, touched }}
              />
              <Input
                name="password"
                type="password"
                placeholder={t('placeholders.password')}
                value={values.password}
                {...{ errors, touched }}
              />
              <RememberMe>
                <Input
                  type="checkbox"
                  name="remember"
                  label={t('remember')}
                  id="rememberMe"
                  checked={values.remember}
                />

                <Link className="text-underline color-secondary" to="/forgot-password">
                  {t('forgotPassword')}
                </Link>
              </RememberMe>
              <Button
                width="100%"
                margin="0 0 40px 0"
                type="submit"
                disabled={isSubmitting || !(isValid && dirty)}
              >
                {t('button')}
              </Button>
            </Form>
          )}
        </Formik>
        <P margin="0 0 10px 0">
          {t('socialSignTitle')}
        </P>

        <Button
          outline
          width="100%"
          borderRadius="10px"
        >
          <Link
            to={`${process.env.REACT_APP_API_HOST}accounts/auth/google${redirect ? `?redirect=${redirect}` : ''}`}
            external
          >
            <img height="20" src={LoadImage('google-icon.svg')} alt="Google login" />
          </Link>
        </Button>

        <Button
          outline
          width="100%"
          borderRadius="10px"
          margin="10px 0 0 0"
        >
          <Link
            to={`${process.env.REACT_APP_API_HOST}accounts/auth/facebook${redirect ? `?redirect=${redirect}` : ''}`}
            external
          >
            <img height="23" src={LoadImage('facebook-letter.svg')} alt="Facebook login" />
          </Link>
        </Button>

        <P className="text-center mt30">
          <Trans ns="login" i18nKey="noAccount">Don’t have an account?</Trans>
        </P>

        <Button
          outline
          padding="0 10px"
          minWidth="110px"
        >
          <Link to="/register">
            <Trans ns="header" i18nKey="register">Sign Up</Trans>
          </Link>
        </Button>

        <P xSmall textAlign="center" margin="50px 0 0 0" className="ToA">
          <Trans ns="login" i18nKey="termsAndConditions">
            By logging in I have read and agree to the
            <Link to="/terms-conditions">Terms of Service</Link>
            and
            <Link to="/confidentiality">Privacy Policy</Link>
          </Trans>
        </P>
      </FormContainer>
    </Container>
  );
};

export default Login;
