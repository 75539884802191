import React from 'react';
import styled from '@emotion/styled';
import Nav from '../Nav';
import { Contain, Button, Link } from '../../../../Collection';
import { Logo } from '../styles';
import LoadImage from '../../../../common/LoadImage';

const Container = styled.div(props => ({
  position: 'relative',
  marginRight: '10px',
  width: '30px',
  ...props.active && {
    '.navigator': {
      transform: 'none',
    },
  },

  '.close': {
    cursor: 'pointer',
  },
}));

const Burger = styled.div({
  display: 'block',
  cursor: 'pointer',
});

const Line = styled.span(props => ({
  display: 'block',
  width: '24px',
  height: '2px',
  marginBottom: '5px',
  position: 'relative',
  background: props.whiteBars ? '#fff' : props.theme.colors.primary,
  borderRadius: '3px',
  zIndex: 1,
}));

const Menu = styled.div({
  position: 'absolute',
  width: '320px',
  height: '100vh',
  padding: '40px',
  background: '#000',
  transformOrigin: '0% 0%',
  transform: 'translate(-100%, 0)',
  transition: 'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)',
  left: '-20px',
  top: '-14px',
  zIndex: 2,
  touchAction: 'none',
});

export const Mask = styled.div(props => ({
  display: props.active ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  touchAction: 'none',
  zIndex: 1,
}));

const Index = ({
  whiteBars, loggedIn, accountRole, t, handle,
}) => (
  <Container active={handle.get} className="onlyMobile">
    <Mask active={handle.get} onClick={() => handle.set(!handle.get)} />
    <Burger active={handle.get} onClick={() => handle.set(!handle.get)}>
      <Line whiteBars={whiteBars} />
      <Line whiteBars={whiteBars} />
      <Line whiteBars={whiteBars} />
    </Burger>
    <Menu className="navigator">
      <Contain
        width="100%"
        align="center"
        justify="space-between"
        margin="0 0 20px 0"
      >
        <Link to="/">
          <Logo
            src={LoadImage('logo-white.svg')}
            alt="Sportya Logo"
            className="logo"
          />
        </Link>
        <img
          src={LoadImage('header/x.svg')}
          alt="Close Menu"
          className="close"
          onClick={() => handle.set(!handle.get)}
        />
      </Contain>
      <Nav
        mobile
        isLoggedIn={loggedIn}
        accountRole={accountRole}
      />
      {!loggedIn && (
        <Contain direction="column" margin="20px 0 0 0">
          <Button small noBorder width="240px">
            <Link to="/login">
              {t('login')}
            </Link>
          </Button>
          <Button small noBorder primary width="240px" margin="10px 0 0" color="#fff">
            <Link to="/register">
              {t('register')}
            </Link>
          </Button>
        </Contain>
      )}
    </Menu>
  </Container>
);

export default Index;
