import React from 'react';
import styled from '@emotion/styled';

const H4Styled = styled.h4`
color: ${({ theme }) => theme?.colors?.primary};
font-size: ${({ theme }) => theme?.functions?.toEm(22)};
${props => (props.lineHeight ? `
line-height: 35px;` : '')}
font-family: DM Sans;
font-style: normal;
font-weight: bold;

${props => props.theme.functions?.minDesktopSmall(`
font-size: ${ props.theme?.functions?.toEm(24) };
${props.lineHeight ? `
line-height: 50px;` : ''}
`)}
`;

const H4 = ({ children, ...props }) => (
  <H4Styled {...props}>
    {children}
  </H4Styled>
);

export default H4;
