import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { DayPicker } from "react-day-picker";
import dateFormat from "dateformat";
import { ModalContainer } from './styles';

import Paragraph from '../../../../../components/Paragraph';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import Select from '../../../../../components/Form/Select';
import Radio from '../../../../../components/Form/VanillaRadio';
import ToggleButton from '../../../../../components/Form/ToggleButton';
import CheckoutModal from '../checkoutModal';
import FriendlyMatchFormData from './components/friendlyMatchFormData';
import checkPlayerEligibility from './helpers/checkPlayerEligibility';
import { DatePickerContainer } from "../Filters/styles";
import ToggleVisible from "../../../../../components/common/ToggleVisible";
import { formatFacilities, getLocaleByCountry, datePickerInterval } from "../../../helpers";
import { getClubCourts } from "../../../actions";
import getCountryIsoCode from "../../../../../components/common/getCountryIsoCode";

const Option = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="d-flex flex-column">
        <Paragraph bold className="mt0">{data.label}</Paragraph>
        <Paragraph smaller className="mb0 mt0">{data.facilities}</Paragraph>
      </div>
    </components.Option>
  );
};

const formatOptionLabel = ({ label, facilities }) => (
  <div className="d-flex align-middle">
    <Paragraph bold>{label}</Paragraph>
    <Paragraph smaller>&nbsp;- {facilities}</Paragraph>
  </div>
);

const bookCourtModal = ({
  isOpen,
  bookingInfo: {
    clubInfo,
    startDate,
    startHour,
    courtId,
    duration,
  },
  friendlyMatchInfo = {},
  onRequestClose,
}) => {
  const { accountInfo, masterSport } = useSelector(state => state.session);
  const { t } = useTranslation(['clubs', 'friendlyMatches', 'common']);
  const [checkout, setCheckout] = useState(undefined);
  const [friendlyMatchToggle, setFriendlyMatchToggle] = useState(!isEmpty(friendlyMatchInfo));
  const [friendlyMatch, setFriendlyMatch] = useState(friendlyMatchInfo);
  const [availableCourts, setAvailableCourts] = useState(clubInfo?.courts);
  const [availableHours, setAvailableHours] = useState(clubInfo?.availableHours ?? []);
  const [loadingCourts, setLoadingCourts] = useState(false);

  const [payload, setPayload] = useState({
    clubId: clubInfo?._id ?? clubInfo?.id,
    courtId,
    startHour,
    startDate,
    friendlyMatch,
    duration,
  });
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  useEffect(() => {
    if (payload.startDate !== startDate || !courtId) {
      setLoadingCourts(true);
      setAvailableCourts([]);
      const hour = payload.startHour ?? '06:00';
      const filters = {
        idClub: payload.clubId,
        timestamp: `${payload.startDate} ${hour}`,
        availableOnly: true,
      };
      getClubCourts(filters).then(result => {
        setLoadingCourts(false);
        setAvailableCourts(result?.courts?.filter(court => court.status === "active") ?? []);
        setAvailableHours(result?.availableHours ?? []);

        setPayload({
          ...payload,
          courtId: null,
          duration: null,
          startHour: payload.startHour || result?.availableHours?.[0] || hour,
        });
      });
    }
  }, [payload.startDate]);

  // reset chosen court and duration if hour is changed
  useEffect(() => {
    if (payload.startHour !== startHour) {
      setPayload({
        ...payload,
        courtId: null,
        duration: null,
      });
    }
  }, [payload.startHour]);

  const courts = (availableCourts || [])
    .filter(({ pricing }) => !isEmpty((pricing || []).filter(v => v?.startHour === payload.startHour)))
    .map((court) => ({
      label: court.name,
      value: court._id,
      facilities: formatFacilities(court, t),
    }));

  const [courtInfo] = (availableCourts || []).filter(({ _id }) => _id === payload.courtId);
  const [pricing] = (courtInfo?.pricing || [])
    .filter((price) => price?.startHour === payload.startHour);
  const currency = clubInfo?.currency ?? getCountryIsoCode(clubInfo?.location?.country).currency;

  const isDoubles = friendlyMatch.gameType === 'doubles';
  const myLevel = accountInfo?.gameLevel?.[friendlyMatch.gameType];
  const matchEligibility = checkPlayerEligibility({
    myLevel,
    isDoubles,
    isRanked: friendlyMatch.matchType === 'ranked',
    ...friendlyMatch,
  });

  const btnDisabled = (
    !payload?.duration
    || (friendlyMatchToggle && isEmpty(friendlyMatch))
    || (!isEmpty(friendlyMatch) && (
      !matchEligibility.isEligible()
      || (!friendlyMatch.matchType || !friendlyMatch.gameType)
      || (friendlyMatch.gameType === 'singles' && !friendlyMatch.opponent)
      || (friendlyMatch.gameType === 'doubles' && (
        !friendlyMatch.playerPartner
        || !friendlyMatch.opponent
        || !friendlyMatch.opponentPartner)
      ))
    )
  );

  if (checkout) {
    return (
      <CheckoutModal
        payload={payload}
        onRequestClose={onRequestClose}
        clubInfo={clubInfo}
        courtInfo={courtInfo}
        setCheckout={setCheckout}
        pricing={pricing}
        currency={currency}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        width: 500,
      }}
      mobileFullDisplay
    >
      <ModalContainer>
        <div className="modal-header">
          <Paragraph medium bold>
            <Trans ns="clubs" i18nKey="bookACourt" context={masterSport}>Book a Court</Trans>
          </Paragraph>
          <Paragraph className="mb20">{clubInfo?.clubName} - {clubInfo?.location?.name}</Paragraph>
        </div>

        <div className="modal-content">
          <div className="d-flex gap-10">
            <div ref={ref} className="position-relative w100">
              <Select
                name="date"
                placeholder={t('date')}
                width="100%"
                height="50px"
                label={t('date')}
                isSearchable={false}
                value={payload.startDate && {
                  label: payload.startDate,
                  value: payload.startDate,
                }}
                alignLabel
                lowInput
                onFocus={() => setIsComponentVisible(!isComponentVisible)}
                menuIsOpen={false}
                className="select-date"
              />

              {isComponentVisible && (
                <DatePickerContainer>
                  <DayPicker
                    mode="single"
                    required
                    showOutsideDays
                    locale={getLocaleByCountry(accountInfo?.location?.country)}
                    selected={new Date(payload.startDate)}
                    onSelect={(value) => {
                      setPayload({ ...payload, startDate: dateFormat(value, 'yyyy-mm-dd') });
                      setIsComponentVisible(!isComponentVisible);
                    }}
                    {...datePickerInterval()}
                  />
                </DatePickerContainer>
              )}
            </div>

            <Select
              name="time"
              placeholder={t('time')}
              onChange={({ value }) => {
                setPayload({
                  ...payload,
                  startHour: value,
                });
              }}
              options={availableHours.map((value) => ({
                label: value,
                value,
              }))}
              width="100px"
              height="50px"
              label={t('time')}
              isSearchable
              value={payload.startHour && {
                label: payload.startHour,
                value: payload.startHour,
              }}
              alignLabel
              lowInput
              className="select-time"
              isLoading={loadingCourts}
              isDisabled={loadingCourts || isEmpty(availableHours)}
            />
          </div>

          <Select
            name="court"
            placeholder={t('court')}
            onChange={({ value }) => setPayload({ ...payload, courtId: value, duration: null })}
            components={{ Option }}
            options={courts}
            formatOptionLabel={formatOptionLabel}
            height="50px"
            label={t('court')}
            value={payload.courtId && courts.filter(v => v.value === payload.courtId).shift()}
            alignLabel
            lowInput
            heightOptions={60}
            isLoading={loadingCourts}
            isDisabled={loadingCourts || isEmpty(availableHours)}
            noOptionsMessage={() => t('noAvailableCourts')}
          />

          {/* Pricing Options */}
          {payload.courtId && !isEmpty(pricing) && (
            <ul className="pricing">
              {Object.keys(pricing.bookingDuration).map((v) => (
                <li key={v} className={!pricing.bookingDuration[v].available ? 'is-disabled' : ''}>
                  <Radio
                    name="price"
                    value={v}
                    onChange={() => setPayload({ ...payload, duration: Number(v) })}
                    label={(
                      <div className="d-flex space-between">
                        <Paragraph>{`${v} min`}</Paragraph>
                        <Paragraph>{pricing.bookingDuration[v].price} {currency}</Paragraph>
                      </div>
                    )}
                    disabled={!pricing.bookingDuration[v].available}
                    checked={payload.duration === Number(v)}
                  />
                </li>
              ))}
            </ul>
          )}

          {/* Friendly Match Form Fields */}
          {(payload?.duration && (isEmpty(friendlyMatchInfo) || checkout === false)) && (
            <>
              <ToggleButton
                className="toggle mb10"
                active={friendlyMatchToggle}
                handler={() => {
                  setFriendlyMatchToggle(!friendlyMatchToggle);
                  if (friendlyMatchToggle) {
                    setFriendlyMatch({});
                    setPayload({
                      ...payload,
                      friendlyMatch: {},
                    });
                  }
                }}
              >
                <Trans ns="clubs" i18nKey="addPlayersToFriendlyMatches">Add players to Friendly Matches</Trans>
              </ToggleButton>

              {friendlyMatchToggle && (
                <FriendlyMatchFormData
                  t={t}
                  friendlyMatch={friendlyMatch}
                  setFriendlyMatch={setFriendlyMatch}
                  matchEligibility={matchEligibility}
                />
              )}
            </>
          )}
        </div>

        <div className="modal-footer">
          <Button
            fullwidth
            onClick={() => {
              setPayload({
                ...payload,
                friendlyMatch,
              });
              setCheckout(true);
            }}
            disabled={btnDisabled}
          >
            <span><Trans ns="clubs" i18nKey="continue">Continue</Trans></span>
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default bookCourtModal;
