export default {
  seoTitle: 'Pravidla pro vrácení | Sportya',
  seoTitle1: 'Zásady ochrany soukromí | Sportya',
  seoTitle2: 'Smluvní vztahy a podmínky | Sportya',
  seoTitle3: 'Formáty pro skupinovou hru | Sportya',
  seoTitle4: 'Místní ligy - Pravidla a předpisy | Sportya',
  seoTitle5: 'Pravidla a předpisy | Sportya',
  regulament_general: {
    mainTitle: 'SOUBOR PRAVIDEL SPORTYA – TENIS',
    title1: 'Systém Sportya',
    title2: 'Přátelské zápasy',
    title3: 'Turnaje',
    title4: 'Organizace hlavního losování',
    title5: 'Odstoupení z turnajů. Sankce',
    title6: 'Turnaje',
    title7: 'Zásady chování',
    title8: 'Hodnocení',
    title9: 'Změna úrovně hry',
    title10: 'Ustanovení závazku vstupu do systému Sportya ',
    title11: 'Příloha 1 - Zvláštní ustanovení',
    section1: {
      p1: `Sportya has set out to connect and inspire athletes around the world by creating competitive platforms, events and specific tools that help them make the most of their sporting lives.
      
      The Sportya system does not aim at performance but at increasing the frequency of playing tennis, for health and combating sedentary lifestyle. Thus, Sportya addresses all practitioners of this individual sport, regardless of age (but not less than 15 years) or gender, offering them a standardized and attractive framework for practicing the sport, both in organized competitions and individually, conditional to providing a balance in terms of the ability of the partners to play, so that playing tennis is both a challenge and a source of satisfaction and relaxation.
      
      The Sportya system comprises several important components, without being limited to them:
      
      1. A modern information platform (Sportya.net) that presents the offer of tennis tournaments of all tennis clubs and organizers interested in organizing local competitive events with a friendly character, for the community and its own members;
      
      2. A community of players interested in playing tennis in their spare time, as a way to relax and socialize, gathered together around the online platform Sportya;
      
      3. Competitive events, generically called the "Sportya Circuit", comprising all competitions organized by individual clubs and organizers and presented through the online platform, together with individual matches ("friendly matches") played by members of the Sportya community outside organized events;
      
      4. A system for ranking players by their level of play (regardless of age or gender);
      
      5. A system for recording and counting the activities of all members of the Sportya community using the results of all matches played, both in Circuit events and friendly matches, presented in the form of rankings for each level of play, relevant exclusively to the Sportya community and being used for the proper functioning of Sportya services, such as event registration.
      
      Within this Rulebook, the term "Sportya system" means all or some of the above components, as appropriate.
      `,
      p2: `The Sportya system and the online platform provide a well-defined framework for tennis enthusiasts and organizers of recreational tennis competitions and provide information about them or other events, products or services. Both the framework and the information are provided exclusively by the Sportya System and / or its partners and are subject to the acceptance by their users of this Rulebook, as well as other rules or conditions presented through the Sportya digital platform (referred altogether as "Terms and Conditions").

      BY ACCESSING, USING, VIEWING, FORWARDING OR SAVING DIGITAL THE SPORTYA DIGITAL PLATFORM OR ANY OF THE SERVICES, FUNCTIONS, FACILITIES OR CONTENT OF THE PLATFORM AND/OR OF SPORTYA SYSTEM, WHETHER AS AN AMATEUR PLAYER OR AS AN ORGANIZER OF COMPETITIONS, TOURNAMENTS AND EVENTS RECORDED AND PRESENTED ON THE SPORTYA PLATFORM, IT IS CONSIDERED BY DEFAULT THAT YOU AGREE TO EACH AND ALL OF THESE TERMS AND CONDITIONS, INCLUDING THIS RULEBOOK (THE "AGREEMENT") WITHOUT ANY AMENDMENT. IF YOU DO NOT AGREE, PLEASE DISCONTINUE USING THE SPORTYA SYSTEM, ITS SERVICES AND DIGITAL PLATFORM.`,
      p3: `In order to use the services offered by the Sportya platform (Sportya.net), a player must open an account in the Sportya system. The operation is performed through the digital platform. After creating the account, a player can access the facilities, services (e.g., registration for tournaments listed in the Sportya calendar) and digital products (e.g., Premium account) offered through the platform.

      Any amateur player may register on the Sportya platform, provided they are at least 15 years old in the year of registration in the system. Players with ATP or WTA rankings do not have the right to register.`,
      p4: `Any player who has registered on the Sportya platform receives a unique personal identification code valid throughout the system, thus becoming a Sportya player.

      Each Sportya player has at his disposal a personal page on the digital platform, where technical and personal information is centralized, as well as the entire activity registered as a result of using the services of the platform. Access to the personal page is protected.

      Enrollment in the Sportya system and the participation of a Sportya player in any of the competitions listed in the Sportya event calendar implies the player's implicit commitment to accept and respect the Sportya Rulebook, as well as the provisions of the Code of Conduct and the Rules of the Game of Tennis, approved by the ITF (International Tennis Federation), unless otherwise stated in this document.`,
      p5: `The level of play is a general indication of the quality of the game and the degree of skill that characterizes the Sportya player in a singles match. By bringing together players of the same level, the Sportya system increases the degree of satisfaction of the participants, due to the fact that the matches are more balanced.

      The Sportya system uses six levels of play, numbered 4.0, 5.0, 6.0, 7.0, 8.0 and 9.0, in ascending order of the quality of play and player’s abilities, with level 9.0 coming closest to the category of professional tennis players.

      The levels of play are separated for singles and doubles.
      
      The level of play of a Sportya player can change during the year, depending on the activity carried out within the Sportya Circuit and the results achieved. The level of play is complemented by the ELO rating, inspired by the algorithm invented by the physicist Arpad Elo, customized for each player. The ELO rating dynamically provides an evaluation of the play of a member of the Sportya system and adapts after each match registered in the system. If the level of play indicates the classification of a player in a category of players with similar skills and qualities of play, the ELO rating indicates with much greater finesse the power / competitiveness of the player within the level of play he is a part of.`,
      p6: `In order to use the facilities of the Sportya system, the player must self-assess his/her level of play, in the first phase, based on standard features presented on the digital platform.
      
      Even if the levels of play for singles and doubles are separated, the self-assessment will initially be done only for the singles category, the level for the doubles being automatically set to the same value. The change of level for doubles immediately after the self-evaluation will be possible only after the requests sent to the Sportya representatives, or as a consequence of the results obtained in Sportya tournaments.
      `,
      p7: `Boys:

        •    U12 category: at least level 6.0

        •    U14 category: at least level 7.0

        •    U16, U18 and Senior categories: at least level 8.0

      Girls:

        •    U12 category: at least level 5.0

        •    U14 category: at least level 6.0

        •    U16 category: at least level 7.0

        •    U18 and Senior categories: at least level 8.0`,
      p8: 'If a player participates in a tournament, especially at his first such participation, and it is found that his level of play is obviously underestimated, the competition referee may decide to remove the player from the competition and request the system coordinator to upgrade the respective player at the appropriate level of play through administrative decision.',
      p9: ' A player may ask the system coordinator to change his level of play if he/she concludes that he has misjudged himself/herself. When this request is analyzed, the entire activity of the player within the Circuit will be taken into account.',
      p10: 'The classification on a certain level of play may change during the year as a direct consequence of the results obtained, as well as a result of the application of the procedure for rebalancing the levels of play, in accordance with this Rulebook.',
      p11: 'Players who participate for the first time in a tournament within the Circuit, regardless of the year of their registration in the system, cannot register for Platinum tournaments, but only for Special, Silver or Gold category tournaments.',
      p12: `In the case of a doubles team, an equivalent level of play of the team is calculated as the arithmetic mean of the doubles level of each of the two players. It is allowed to form a doubles team only if the difference between the levels of the two players is a maximum of 2.0.
      Example: A team consisting of a level 6.0 player and a level 7.0 player has the equivalent level 6.5, and a team of level 6.0 and 8.0 players has the equivalent level 7.0; however, pairing a level 6.0 with a level 9.0 is not accepted.`,
      p13: 'The competition season includes all the tournaments recorded on the Sportya platform, together with the friendly matches played between the Sportya players outside the events. The calendar of events includes 2 competitive sub-seasons: the Outdoor (summer), from April untill October, and the Indoor (winter), held under the title "Winter Series", from November until March.',
      subtitle1: 'Terms and Conditions',
      subtitle2: 'The Sportya account',
      subtitle3: 'The Sportya player',
      subtitle4: 'Level of play',
      subtitle5: 'Determining the level of play',
      subtitle6: 'Players registered in the official rankings of the Tennis Federations must self-assess themselves at higher levels, as follows:',
      subtitle7: 'We recommend that all players entering the Sportya Circuit for the first time, before self-assessment, as far as possible, watch a tournament organized within the Circuit, in order to estimate as accurately as possible what level of play they fall into, or to identify fellow players they already know, who have a relevant activity in tournaments, in order to make the comparison with their own level.',
      subtitle8: 'Request to change the level of play',
      subtitle9: 'Changing the level of play',
      subtitle10: 'First participation in a tournament',
      subtitle11: 'Equivalent level of play',
      subtitle12: 'The competition season',
    },
    section2: {
      p1: `The friendly match is the singles match played by Sportya players outside of and unrelated to organized events, in any location agreed by them, provided that the players agree in advance that the result of the match will be reported through the Sportya platform, within 7 days from the date of the match.
      
      The Sportya platform offers the facility to record the result of the match, with an impact on the personal ranking. The scheduling of the match, the provision of the play balls but also any other characteristic related to the match are entirely decided by the players.
      
      Sportya players can agree in advance that the friendly match will be played either in the best-of-three-sets system, with a tiebreak at 6-6, including in the third set, or in the two-sets system (with a tiebreak at 6-6) + super tiebreak (up to 10 points), in case of a tie after two sets.`,
      p2: '',
      subtitle1: '',
    },
    section3: {
      p1: `All competition events organized by clubs, sports associations or other legal or natural persons, which comply with the format and standards of the Sportya system, and which have been registered by their organizers on the Sportya platform form the competitions calendar of the Sportya Circuit. All these events have the character of amicable and unofficial sports-recreational competitions, designed for recreation and socialization, in a standard format.

      In order to provide a quality experience, the organizers of these events have undertaken to comply with the standards indicated in the Rulebook, as well as the provisions of the Code of Conduct and the General Tennis Rules, approved by the ITF, unless otherwise provided in this document.`,
      p2: `The Sportya circuit includes 7 categories of tournaments: Local League, 1st Serve, Silver, Gold, Platinum, Specials and Champions Tournament. The 7 categories differ in importance, size (total number of participating players), benefit packages granted to players, points awarded, conditions of access on the main draw, level of media coverage and degree of visibility, as well as the adjacent events and activities, organized on the occasion of the tournament.
      
      The sporting events within the Circuit are open exclusively to players registered on the Sportya platform, within the limits of the positions on the main draws and in compliance with the registration rules. Players who participate in a tournament for the first time in the system may only register for Local League, 1st Serve, Special, Silver or Gold category tournaments.`,
      p3: `A Sportya tournament/event includes several competitions, each organized for a certain level of play. These can be singles, doubles or team competitions. For example, a tournament may have three singles for levels 5.0, 6.0, and 7.0 (referred to as S5.0, S6.0, and S7.0, respectively) and a doubles for level 7.0 (referred to as D7.0).
      
      In a singles competition, only Sportya players with a level equal to or immediately below its level may register. For example, only level 7.0 or level 6.0 players may participate in an S7.0 competition.
      
      In a doubles competition, only teams with an equivalent level of play equal to or less than one level (-1.0) of its level may register. For example, a pair including one level 5.0 and one level 7.0 players, thus having an equivalent level 6.0, may participate in D6.0 and D7.0 competitions. In order to offer the widest possible range of formats and a unique experience, a competition can benefit from restricted entries that will always be specified on the event or competition page. Such restrictions could be: gender (e.g., exclusively women’s event, or mixed doubles event, etc.); age (e.g., competition dedicated to players aged between 45 and 65); ranking (e.g., competition for players with a ranking higher than the 30th position); level of play (e.g., for a doubles organized for a maximum level of 6.5, pairs of two level 7 players and pairs with level 5.5 are not eligible); format (e.g., doubles competition for parent & child teams).
      `,
      p4: `The use of the facility to enter events listed in the competition calendar of the Circuit offered by the Sportya platform is allowed only to Sportya players who, at the time of registration for the competition: (1) have a personal identification code within the Sportya system, and (2) have set a level of play. With the exception of level 9, participation in Platinum tournaments is only allowed to players who have participated in at least three tournaments since joining the system. In the case of Platinum events organized outside the country, other restrictions may apply.
      
      The personal identity card could be requested by the tournament organizer in order to identify the player and validate his/her presence at the tournament.
      
      There is no limitation on the number of tournaments held in the same county/region a player could attend during a calendar month.
      
      In a tournament held in the summer season, a player may participate in only one singles competition and one doubles competition in the same event (equal to or immediately above the individual’s or team’s level of play). If a player chooses to participate in the same tournament in both a singles and a doubles competition, these competitions may have the same or different levels.
      
      In tournaments that are organized exclusively for doubles competitions, mostly found in the Winter Series calendar, a player may participate in two doubles competitions held in the same tournament (unless there are other restrictions announced for the respective tournament).
      `,
      p5: `Registrations for a tournament listed on the Sportya platform are opened differently depending on its type, as follows:
      
      - for Gold and Platinum tournaments: registrations open in the week which is two weeks before the tournament week, Monday at 20:00, and close one day prior to the draw, at 20:00;
      
      - for the Local League, 1st Serve, Special and Silver tournaments: registrations start one week before the tournament week, on Monday at 22:00, and close one day prior to the draw, at 20:00;
      
      - for Champions Tournaments, the registration procedure is customized for each event and will be announced in due course on the online page. This procedure will include all the details regarding the competitions (level of play, format, etc.), eligibility, wild cards, etc.
      
      Example: Assuming that a certain calendar month starts on Monday the 1st and that a tournament is scheduled to take place between Friday 19th to Sunday 21st in this month: if the tournament is Gold, then registrations start on Monday 1st, at 20:00, and close on Monday 8th, at 20:00; if the tournament is Silver, registrations start on Monday 8th, at 22:00, and close on Monday 15th, at 20:00, with the draw taking place on Tuesday 16th  or, in some cases, on Wednesday 17th. 
      
      Registration for a tournament listed on the Sportya platform can only be done online, using the existing option on the tournament online page. The registration procedure was successfully completed only if the player (players, in the case of a team playing doubles) receives (receive) the registration confirmation through the messaging system of the platform (confirmation is generated automatically, if the registration steps are correctly fulfilled). The registration made by a club or organizer (ultimately, by the Sportya event coordinator, on behalf of the club / organizer), through the club account opened in Sportya, is considered as online registration.
      
      Criterion of the week. A player may register simultaneously for two tournaments that take place in the same calendar week, provided that the gameplay formats do not coincide (e.g., a player may participate in a tournament during the week and one on the weekend, but cannot play two tournaments on the same weekend). Eligible tournaments to be played in the same week will be identified on the online event pages. A calendar week starts on Monday and ends on Sunday. Also, the week of a tournament is given by the day it starts.`,
      p6: `Sportya players may only register for singles competitions of a level equal to or immediately above their level of play. A player who participates in a tournament for the first time, from the moment of his registration in the system, may only register for a tournament of the following type: Local League, 1st Serve, Special, Silver or Gold.
      
      In all tournaments, except the Champions Tournaments, the organizer is entitled to 1 wild card for any of the competitions within the tournament. The organizers must communicate to the coordinator of the Sportya platform, at the latest at the time of the draw, the players who received wild cards. The organizer must know the players to whom he gives the wild card, being responsible for the correct determination of the level of play of those players, especially when they are at their first participation in a tournament within the Circuit.`,
      p7: `At the Local League, 1st Serve, Special and Silver tournaments, the registration on the digital platform is made according to the criteria First come, First served (first registered - first accepted, second registered - second accepted, and so on).
      
      Throughout the entire registration period (Monday, 22:00 until the pre-day of the draw, 20:00), registration is open to any players with a level of play equal to or immediately below that of the competition.
      
      Once the positions on the main draw have been filled, the entries on the waiting list open.
      `,
      p8: `Only players who do not have other participation restrictions (e.g., penalties, specific restrictions imposed by the organizers, etc.) are allowed to enter Gold and Platinum tournaments, in descending order of the place occupied in the singles ranking, valid at the time of registration for that tournament.
      
      With the exception of level 9, participation in Platinum tournaments is only allowed to players who have participated in at least three tournaments since joining the Sportya system. In the case of Platinum events held abroad, additional restrictions may apply.
      
      The registration procedure takes place in two stages:
      
      1. Registration - during the first seven days of registration - respectively from Monday, 20:00, until next Monday, 20:00 - for any of the singles competitions of the tournament, any player with equal or one level lower than that of the competition for which he/she wishes to register may do it. 
      
      Registration does not guarantee acceptance on the main draw. This will be decided only after the end of the first stage of the registration procedure. At the same time, the players whose position in the general ranking does not allow them to occupy a place on the main draw, will be automatically registered on the waiting list, within the available places.
      
      A player may register for only one singles competition in the tournament.
      
      2. Validation - immediately after the end of the registration period (Monday, 20:00), for each of the tournament competitions, the system will automatically order, in descending order of position in the singles general ranking, all players registered in the first stage for a particular competition, regardless of the time of their registration. In case of an equal ranking, separation between players will be based on the following, in this exact order: the number of Sportya tournaments played, the number of friendly matches played and reported in Sportya, and the seniority in the Sportya system.
      
      Players will then be distributed first on the main draw (less the positions already occupied by wild card) and then on the waiting list, after completing the main draw, in strictly descending order of the points accumulated in the singles ranking, first being distributed the players with a level of play equal to that of the competition and then those with one lower level (and so on). 
      
      The result of the validation (acceptance on the main draw, acceptance on the waiting list, or rejection) will be sent to all players who registered in stage 1, through the messaging facility of the system.
      
      On Monday, at 20:00, after the standard registration period, registrations continue on the main draw (as far as there are places available) or the waiting list. In case of reopening, the registration will be made according to the criterion First come, first served and not according to the position in the singles ranking.`,
      p9: `For all the singles competitions within the tournaments organized in the Circuit, there are waiting lists, in which the players who failed to occupy a place on the main draw will be registered. The waiting list is ordered so that the replacement of some players on the main draw with those on the waiting list is done in the strict order of the list.
      
      The waiting list is unlimited in terms of the number of positions. The registration on the waiting list is done as follows:
      
      - in the case of Local League, 1st Serve, Special, and Silver tournaments, access on the waiting list is based on the First come, first served rule.
      
      - in the case of Gold and Platinum tournaments, the waiting list is automatically generated by the system, during the stage of validation of entries for competitions, through the procedure of distribution of registered players, in the strictly descending order of the position in the singles ranking. Subsequently, the list is reopened for new registrations, observing the rule First come, first served (in this particular case the rule of position in the ranking no longer applies).
      
      Exceptionally, players may register on the waiting list even after its closing on the digital platform (the main draw can no longer be changed at this time, even if it is incomplete). The application for registration will be made to the coordinator of the Sportya platform no later than the moment of the start of the competition. A player registered on the waiting list of a tournament may register on the main draw or on the waiting list of another tournament starting in the same week, but only if the tournaments are eligible to be played during the same period.
      
      In case of withdrawal or no-show at the event of players registered on the main draw of a competition, their position will be occupied, in order, by the players on the waiting list for the respective competition.
      
      The filling of positions on the main draw with players from the waiting list is done automatically by the digital platform, in case of withdrawal of players from the main draw, until Monday evening, at 20:00, in the week of the event. In this situation, the players on the waiting list who have been assigned to the main draw will be notified via the system's electronic messaging. From the moment of the draw, the changes following any withdrawal will be operated by the coordinator of the digital platform or by the tournament director, after contacting and confirming the players on the waiting list. In case of no-show at the match in the 1st round, the tournament director can make substitutions with players from the waiting list, who confirmed their presence by showing up at the event, or can try to contact them and schedule the match without disrupting the tournament schedule. In the latter case, it is generally recommended that only players on top positions in the waiting list show up at the tournament venue, as there is no guarantee that they will be able to access the main draw.
      
      A player (or a team) on a waiting list can also be considered a viable substitute for the competition organized one level higher (player’s level plus one level), and will occupy the last position on the waiting list for that competition.
      `,
      p10: `If a player's withdrawal takes place after the draw was executed and the main draw was determined, the player coming from the waiting list will occupy the same position on the main draw as the substituted player, regardless of the ranking of one or the other.
      
      In case of no-show at the event of players on the main draw, the competition director may decide to replace them only with players on the waiting list, if they showed up at the event and are ready to play. If several players from the waiting list are present at the venue of the competition, the order in which they will fill in the places on the main draw left vacant due to such no shows, will be strictly the order in which they appear on the waiting list.
      
      It is forbidden to replace vacant positions on the main draw with players who are not on the waiting list, even if they are members of the Sportya community.`,
      p11: `In the Sportya system, a team for doubles is assimilated to a player whose level is the equivalent level of play, calculated as the average of the doubles level of play of each of the two partners.
      
      A pair in which one or both players are at their 1st participation in a Circuit tournament may only register for a doubles competitions organized in the Local League, 1st Serve, Special, Silver or Gold tournaments.
      
      A doubles team can only enter competitions of a level equal to or immediately higher than the team's equivalent doubles level of play.
      
      Example: A pair of 5.0 + 7.0, with an equivalent level of 6.0, may participate in level 6.0 or level 7.0 doubles competitions, while a pair of 6.0 + 7.0, with an equivalent level 6.5, may only participate in level 7.0 doubles competitions.
      
      Regardless of the tournament category, its organizer has the right to reserve 1 wild card for each of the main draws.
      
      The registration of a doubles team is done only online, through the Sportya platform. Online registration is similar to that for singles competitions.
      
      Either of the two Sportya players may register the team for a doubles competition. In the registration procedure, the system requires the identity of the partner.
      `,
      p12: `Once successfully passed the procedure for verifying the participation of the doubles team, in the Local League, 1st Serve, Special and Silver tournaments, the registration will be made according to the criteria First come, first served (first registered - first accepted, second registered - second accepted, and so on).

      Throughout the whole registration period, registration is open to any team that has an equivalent level of play equal to or immediately below that of the competition. Once the positions on the main draw have been filled, the entries on the waiting list open.`,
      p13: `Access to Platinum tournaments is reserved only for teams that do not have participation restrictions of any kind (e.g., penalties, reaching the maximum number of tournaments allowed per month, specific restrictions imposed by the organizers, etc.), in descending order of the doubles general ranking, in force at the time of registration for that tournament.
      
      With the exception of level 9, participation in Platinum tournaments is only allowed to players who have participated in at least three Sportya tournaments since joining the system. In the case of Platinum events from abroad, further restrictions may apply.
      
      The registration procedure takes place in two stages:
      
      1. Registration - during the first seven days of registration - respectively from Monday, 20:00, until next Monday, 20:00 - for any of the doubles competitions of the tournament, any team with an equivalent level of play equal or immediately below that of the competition may register. 
      
      Registration does not guarantee acceptance on the main draw. This will be decided only after the end of the registration period. Also at that moment, the teams whose position in the ranking does not allow them to occupy a place on the main draw, will be automatically placed on the waiting list.
      
      2. Validation - immediately after the end of the first registration period (Monday, 20:00), for each of the tournament competitions, the system will automatically order, in descending order of the average scores of the two team members in the doubles ranking, all teams registered in the first stage for a certain competition, regardless of the moment of their registration. In case of an equal score, for the tie, the equivalent place of the team will be taken into account as an average of the places occupied in the doubles ranking by the two members of the team; then, in this order, the number of tournaments played and the seniority in the Sportya system, calculated by using the Sportya subscription date of the older of the two players. The teams will then be distributed first on the main draw (minus the positions already occupied by the wild cards awarded to the organizing club) and then on the waiting list, after completing the main draw, in strictly descending order of the points accumulated in the doubles ranking, first being distributed the teams with a level of play equal to that of the competition and then those with one level lower.
      
      The result of the validation (validation of acceptance on the main draw, on the waiting list, or rejection) will be notified to all players who have registered in stage 1, through the electronic messaging of the system. On Monday, at 20:00, after the standard registration period, registrations continue on the main draw (as far as there are free positions) or the waiting list. In case of reopening, the registration will be made according to the criterion First come, first served and not according to the position in the doubles ranking.`,
      p14: `For all the doubles competitions within the tournaments listed on the Sportya platform, there are waiting lists, in which the teams that failed to occupy a position on the main draw will be entered. The waiting list is arranged in such a way that the replacement of some teams on the main draw with those on the waiting list is performed in a strict order.
      
      The waiting list is unlimited in terms of the number of positions. Registration on the waiting list is based on the First come, first served rule, regardless of the type of tournament.
      
      Exceptionally, teams may register on the waiting list even after its closure on the digital platform (the main draw can no longer be modified at this time, even if it is incomplete). The application for registration will be made directly to the coordinator of the Sportya platform no later than the start of the competition. The waiting list thus completed will be updated online on the tournament page.
      
      A team registered on the waiting list in a tournament or the players part of the team may sign up on the main draw or on a waiting list for another tournament starting in the same week only if the tournaments are eligible to be played during the same period.
      
      In case of withdrawal or no-show at the tournament of teams registered on the main draw of a competition (regardless of whether only one or none of the players do not show up), their place will be taken, in order, by the teams on the waiting list for the respective competition.
      
      Filling in positions on the main draw with teams from the waiting list is done automatically by the digital platform, in case a team is withdrawing from the main draw, until Monday evening, at 20:00, in the week of the event. In this situation, the members of the team from the waiting list that has been distributed on the main draw will be notified via the system's electronic messaging. From the moment of the draw, the changes caused by withdrawals will be made by the coordinator of the digital platform or by the tournament director, after contacting and confirming with the teams on the waiting list. In case of no-show at the match of round 1, the tournament director may make substitutions with players on the waiting list who have confirmed their presence by showing-up or may try to contact them and schedule the match without disrupting the tournament schedule.
      
      In the latter case, it is generally recommended that only teams on top positions in the waiting list show up at the venue of the tournament, as there is no guarantee that they will be able to enter the main draw.
      `,
      p15: `Withdrawal of a team occurs when at least one of the team's players withdraws. The player left without a partner, due to a withdrawal, may re-register for that competition, if he/she has identified another partner, in case the registrations have not closed yet. The position of the team thus withdrawn is occupied by the first team on the waiting list.
      
      If the withdrawal of the team takes place after the draw and the generation of the main draw, the team from the waiting list will occupy the same position on the main draw of the team it is replacing, regardless of the ranking of one or the other. If there is no team on the waiting list, a player whose team is on the main draw may replace his partner after his/her withdrawal, without losing the position on the main draw, before the first match of the team in the tournament. The replacement can only be made after verifying that the new team partner has no participation restrictions (e.g., appropriate level of play, penalties, specific restrictions imposed by the organizers, etc.).
      
      In case of no-show at the tournament of teams from the main draw and if a waiting list exists, the competition director may decide to fill in the empty positions only with teams from the waiting list, if they showed up and are ready to play. If several teams from the waiting list are present at the venue of the competition, the order in which they will fill in the positions on the main draw left vacant will be strictly the order in which they are listed in the waiting list.
      `,
      p16: `Players who have been notified through the electronic messaging on the Circuit website that they are registered on the main draw of any competition in a tournament will report to the tournament organizer or competition director, as appropriate, to register their presence in the tournament and to pay the participation fee (Club Entry Fee).
      
      An identity card could be requested to identify the players.`,
      subtitle1: 'Tournament categories',
      subtitle2: 'Competitions',
      subtitle3: 'Participation in tournaments',
      subtitle4: 'Tournament registration',
      subtitle5: 'Registration for singles competitions',
      subtitle6: 'Registration for Local League, 1st Serve, Special and Silver tournaments (singles)',
      subtitle7: 'Gold and Platinum Tournament Registration (Singles)',
      subtitle8: 'Waiting list (singles)',
      subtitle9: 'Replacing a player',
      subtitle10: 'Registration for doubles competitions',
      subtitle11: 'Registration for the Local League, 1st Serve, Special and Silver tournaments (doubles)',
      subtitle12: 'Registration for Gold and Platinum Tournaments (doubles)',
      subtitle13: 'Waiting list (doubles)',
      subtitle14: 'Replacement of a doubles team',
      subtitle15: 'Registration at the tournament venue',
    },
    section4: {
      p1: `For all competitions within the tournaments listed on the Sportya Circuit, the main draws are automatically generated by the digital platform, by the draw algorithm.
      
      The draw is made two or three calendar days prior to the start of the tournament and is based on the general ranking valid at that time. Exceptions to this rule may appear and will be indicated on the event page.
      
      Before executing the draw, the seeds shall be established. In the event of a tie, the number of tournaments played, the number of friendly matches played and the seniority in the Sportya system will be taken into account, in descending order. In the case of doubles teams, the seeds are established similarly, based on the equivalent level of play of the team (calculated as the arithmetic mean of the levels of the two players) and the average score in the doubles ranking (the arithmetic mean of the scores in the doubles of the two players), using for the tie, in case of equal level and score, the average of the same parameters as for the singles main draw.
      
      The number of seeded players, in both singles and doubles competitions, is given by the following table:
      `,
      p2: 'The placement of the seeded players will be done by draw, according to the table below: ',
      p3: `In situations where a main draw of:
      
      - 16 positions is not filled with at least 12 players / teams, or
      
      - 32 positions is not filled with at least 24 players / teams, or
      
      - 64 positions is not filled with at least 48 players / teams, or
      
      - 128 positions is not filled with at least 96 players / teams,
      
      the first round is considered as qualifiers, and the main draw is reduced in size by half. The scoring is done according to the size of the main draw resulting from the registration and used for the draw.
      
      Exception: if an 8-position main draw is filled with a minimum of 5 players, the main draw will not change.
      
      If a main draw is not filled with a minimum of 5 players / teams, a game format other than the knockout (single elimination) draw may be established for the respective competition or the competition is canceled. In the case of a special gameplay format, the details of its play format and scoring will be established according to the number of participants and announced for each competition individually.
      
      In the case of tournaments using groups format, the number of seeded players is equal to the number of groups formed. In this situation, the draw involves dividing the players into value categories, based on the ranking and in equal numbers with the number of players in the groups (e.g., in a competition with 15 players divided into 5 groups of 3, the first 5 players will be seeded, the next 5 players will occupy the second position in the group randomly, the last 5 players will occupy the third position in the group randomly).
      
      In the case of a competition using a format which includes a consolation tournament, the main draw of the consolation tournament is mirrored from that of the main competition, so that a separate draw is no longer necessary. More specifically, the winners of the first round evolve to the right on the main draw, while the losers of the first round evolve to the left on the consolation draw.
      `,
      p4: `For each competition in a tournament, the main draw completed after the draw is executed plus the corresponding waiting list will be published via the digital platform, at least 2 calendar days before the start of the tournament. In exceptional cases, delays may occur.
      
      Example: For a tournament that starts on a Friday, the main draws are published no later than Wednesday of the same week.
      
      The main draw will contain information regarding the scheduling of the matches for the first rounds. The scheduling of the next matches will be made by the tournament umpire.
      
      Once the main draw has been published on the digital platform, changes can be made following the announced withdrawal of some players / teams after applying the procedure regarding their replacement with players / teams from the waiting list.
      
      In exceptional cases, the draw may be executed again after the publication of the main draw, by the decision of the coordinator of the Sportya platform, with the presentation on the tournament webpage of the reasons underlying such decision.
      
      Once the competition has started, the players / teams that have qualified for the next round and are to play the next day have the obligation to inform themselves at the end of the competition day, to the tournament umpire, about the schedule of the match/matches they are going to play.
      
      In his turn, the umpire must take care to inform the players / teams as promptly as possible, regarding the play schedule, having the obligation to inform correctly and in time.
      
      The main draws including the final results will be published on the digital platform. Any errors observed by players in connection with the results of the matches must be reported to the coordinator of the Sportya platform within a maximum of 7 calendar days from the publication of the results, after which they are considered final.`,
      subtitle1: 'Publication of main draws and results',
    },
    section5: {
      p1: `The waiver of a player or team from participating in a tournament, after the player / team has entered one of its competitions, is not considered, from a technical point of view, a withdrawal, if it occurs during the period in which registrations for that tournament, on the main draw, are still open, thus having no consequences on the player / team. Waiver of participation must be made by using the withdrawal function from the competition, accessible from the personal page hosted on the digital platform, of the person announcing the withdrawal.
      
      Withdrawal means the waiver of a player or team from participating in a tournament they have entered, held after the closing of registrations, and if the player / team has been announced to be on the main draw. The player / team on the waiting list is not required to be present at the tournament.
      
      Withdrawal is considered being announced if it is made by the player / team using the withdrawal function from the competition, found on the competition page, after entries on the main draw close and up to 24 hours before the start of the competition.
      
      Example: The Sportya Cup tournament starts on Friday night, at 18:00, with the level 5 matches. The level 6 competition starts on Saturday morning, at 8:00. Withdrawal for the level 6 competition is considered to be announced if it is made at least 24 hours before the start of the level 6 matches, respectively on Friday, at 8:00. Withdrawal from the level 6 competition made after Friday, at 8:00, is considered a late withdrawal.
      
      Withdrawal in any other form (e.g., by telephone, non-attendance / no-show at the tournament, etc.), including by notifying the tournament director, is not considered being an announced withdrawal.
      
      If the withdrawal takes place less than 24 hours before the start of the competition, but not less than 12 hours before the start of the competition, the player has the obligation to withdraw online and inform the competition director about the situation, allowing him to make the necessary changes and thus disrupting the rest of the competition as little as possible. From a technical point of view, the withdrawal is considered late, and the fee paid online will not be refunded. In cases where a player announces the withdrawal less than 12 hours before the start of the competition or does not announce his absence from the tournament in any form, he is subject to additional penalties.
      
      Once the withdrawal from a tournament is announced, by using the withdrawal function, accessible on the Circuit website, the player / team can reconsider the decision, by requesting to re-register for the same tournament, on the waiting list, no later than the start of the competition.
      
      Any appeals regarding the penalties received may be addressed in writing to the Circuit Disciplinary Commission (corporate@sportya.net) which has the obligation to give an answer within a maximum of 20 days, the decision being irrevocable.
      `,
      p2: `Withdrawals from singles or doubles competitions are counted for each player. If a player makes 4 withdrawals, regardless of their character (announced or unannounced), he risks being suspended and denied the right to participate in events until the end of the season.
      A doubles team is considered withdrawn if at least one of the players withdraws.
      
      The suspension of the right to participate in tournaments operates starting with the first day of Monday after the moment of registering the penalty in the Circuit system.
      
      In the case of doubles competitions:
      
      • The announced withdrawal affects only the player on the team announcing the withdrawal. If only one of the players announces the withdrawal, the latter will not be counted for that non-participation. In the event of a withdrawal announced by one of the players, the other has no obligation to attend the tournament.
      
      • The player thus left without a partner can re-register for the competition, if he has found another partner, if registrations have not closed yet, or after their closure, in compliance with the conditions of registration on the waiting list.
      
      • The unannounced (no-show) or late withdrawal of a player will not affect, from the point of view of penalties, his teammate, if he is present at the venue of the competition. The tournament umpire will have to mention the presence of this player on the competition main draw. Otherwise, both players will incur the corresponding penalty for no-show withdrawal.`,
      subtitle1:
        'Penalties related to withdrawal from singles or doubles competitions',
    },
    section6: {
      p1: `In the Sportya system the following competition formats are allowed:
      
      1. Single-Elimination format (knockout);
      
      2. Round-Robin format (groups);
      
      3. Special formats, which will be presented in detail on the tournament online page. Examples of special formats: with consolation draw, double elimination system, and so on.
      `,
      p2: `The minimum number of days in which a competition of a tournament can take place is given by the size of the main draw and the need to comply with the maximum number of matches allowed per day of competition for a player. Thus, it is recommended that a player not be scheduled to have more than 2 singles and one doubles in one single day. However, it is possible to reach 3 singles matches in one day (if the game schedule requires it; the situations will be announced in advance), respectively 2 singles matches and 2 doubles matches, if players are registered for two competitions of the tournament.
      
      The game format for the matches is the following:
      
      • Two sets (tie-break at 6-6) + match tie-break (up to 10), in case of a tie: 
      
      ∘ all matches in a level 4 and level 5 singles competition;
      
      ∘ all matches in a level 6, 7, 8 or 9 singles competition, except the finals;
      
      ∘ all matches in a doubles competition.
      
      • Two sets of three, with match tie-break at 6-6: the finals of the singles competitions for levels 6, 7, 8 and 9, except the cases when a different play format is announced prior to entering this phase of the competition.
      
      The No-ad system should be used in all the doubles competitions (according to ITF rules).
      
      In exceptional cases (e.g., delays due to weather, extreme weather conditions), the competition director may decide to play matches, including singles semifinals and finals, using a short format, provided that his decision is announced before the match. This format involves “short” sets of four games, with decisive super-tiebreak, in case of a tie in sets, according to the ITF: the player or the team of doubles who wins the first four games wins the set, but only if he/she has an advantage of two games. In case of a tie (4-4) a tiebreak will be played.
      
      Players who do not comply with the match format established by the tournament umpire may be eliminated from the tournament and may be penalized by suspending their right to enter competitions.
      
      It is recommended that matches start not earlier than 8:00 A.M. and that the last match of the day is not scheduled later than 10:30 P.M.      `,
      p3: `The scheduling of matches after the first round will be made by the competition director or tournament umpire.
      Players must start the match after the end of the warm-up period, which, however, must not exceed 5 minutes. After that, the play must be continuous, a player not being able to unjustifiably delay the development of a match.
      
      After the first game of each set and during the tiebreak, the game will be continuous and the players will change the side of the court without a break. No more than 20 seconds must elapse from the moment the ball left the game at the end of the point and until it is put back into play through the first serve of the next point. If the first service is missed, the second must be performed immediately by the serving player. When the side of the court changes, from the moment the ball left the game at the end of the game until it is put back into play for the first point of the next game, no more than 90 seconds must elapse. At the end of each set, regardless of the score, there will be a break of 120 seconds, from the moment the ball left the game at the end of the set and until it was put back into play for the next set. If the set ends after an even number of games, the side of the court will not change until after the first game of the next set. The receiver will play at the reasonable pace of the opponent who serves and must be ready to receive when he/she is ready to serve.
      
      The tournament umpire may temporarily suspend a match due to darkness or unfavorable court or weather conditions. If the interruption is caused by the fall of the darkness, it must be done after an even number of games, in the ongoing set, or at the end of the set.
      
      Before the start of a match, there will be a warm-up period of five minutes. If the match has been suspended or postponed, the new warm-up period will be as follows:
      
      - 0-15 minutes break: no new warm-up;
      
      - 15-30 minutes break: 3 minutes warm-up;
      
      - more than 30 minutes break: 5 minutes warm-up.
      
      When it is necessary to schedule more than one match for the same player during the same day, the following minimum rest periods are recommended:
      
      - if he/she played less than an hour: 30 minutes of rest;
      
      - if he/she played between an hour and an hour and a half: one hour of rest;
      
      - if he/she played for more than an hour and a half: an hour and a half of rest.
      
      In the event that players participate in a singles and doubles competition in a tournament, a doubles match may be scheduled 15 minutes after the end of the singles match in which the same player is involved, if the schedule of matches in the tournament does not allow a longer pause interval.
      
      If the match was interrupted for 30 minutes or more due to rain or other unfavorable conditions, the period during which the match was not played will not be taken into account for the total duration of the match. If the interruption is less than 30 minutes, the match is considered to be continuous from the moment the ball was put into play for the first point.
      
      Matches must follow one after the other, without a break, according to the announced gameplay schedule. The main draws and gameplay schedule must be displayed by the tournament umpire in a highly visible place, in an area commonly frequented by players.
      
      Any player who is not ready to play within 15 minutes from the start time announced for his/her match is considered eliminated, unless the tournament umpire decides, taking into account all relevant circumstances, not to declare his/her elimination.
      `,
      p4: 'All matches of a competition (part of a tournament event) will take place on the same type of surface, for the entire duration of the competition. In case of bad weather, the matches can be moved to another surface, which may or may not be indoor.',
      p5: 'The matches in Sportya will be played without an umpire, as the players are umpiring themselves, and possible misunderstandings being referred to and resolved by the tournament umpire (see also the special provisions regarding umpiring, provided as an Annex at the end of this Rulebook).',
      p6: 'In case of extreme temperatures, the competition director may decide to interrupt the matches and / or postpone them, being obliged to do so if the outside air temperature exceeds 37 degrees Celsius.',
      p7: '',
      subtitle1: 'Tennis competition format',
      subtitle2: 'The system of conducting matches',
      subtitle3: 'Continuous play, interruption, postponement, punctuality',
      subtitle4: 'Tennis courts',
      subtitle5: 'Match umpiring',
      subtitle6: 'Extreme weather conditions',
    },
    section7: {
      p1: `During the matches and while inside the venue where the tournament is organized, each player must behave appropriately and athletically.
      
      All players must dress and present themselves for the match in a proper way. Clean and usually accepted tennis clothing must be worn.
      
      During a match (including during the warm-up), you must not play without a polo or T-shirt, or wear something different, gym aprons, shirts or any other inappropriate clothing. On clay courts, players must wear generally accepted footwear for granular surfaces. The tournament umpire has the authority to determine whether the sole of the shoe complies with the accepted standards for the respective playing surface.
      
      Players must not speak obscenely, make obscene gestures inside the venue of the tournament, verbally or physically assault any official, opponent, spectator or any other person inside the venue of the tournament.
      
      Players must not hit, kick or violently or angrily throw a tennis ball inside a tournament venue, except for the purpose of obtaining a point during a match (including warm-up), nor kicking or violently or angrily throwing a tennis racket or other equipment at the venue of the tournament.
      
      Players must not receive instructions during a match (or during the warm-up). Communication of any kind, verbal or nonverbal, between a player and another person, during the match or during the warm-up, can be interpreted as guidance.
      
      In the event of the above violations, supplemented by those in the ITF Code of Conduct, the tournament umpire will apply the following penalties during the competition:
      
      - In case of the first violation, the sanction will be a Warning 
      
      - In case of the second violation, the sanction will be Lost Point;
      
      - Starting with the third violation, all violations are sanctioned with Lost Game.
      
      Depending on the seriousness of the violation and its impact on the smooth running of the competition, the tournament supervisor (representative of the administrative management of the System) or, in his absence, the tournament umpire may remove from the competition, without warning, any player, including in the event of an obvious under-evaluation of the level of play or if the player speaks obscenely, makes obscene gestures or manifests himself aggressively, verbally or physically, to any official, opponent, spectator or any other person inside the venue of the tournament.
      
      In all cases of elimination, the decision of the Chief Umpire shall be final and without the possibility to appeal. Any player eliminated in accordance with the above provisions will be prohibited from participating in all other competitive events in that tournament, if any, unless the incident is strictly limited to a violation of punctuality or of the rules of conduct referring to clothing and equipment described hereinabove, or when his doubles partner is the one who commits an act that leads to elimination.
      
      Any player penalized for an offense on the spot may appeal, within ten days after the last day of the tournament, by addressing himself/herself to the Circuit Discipline Commission, to review the decision on guilt and penalty. A statement from the player regarding the facts and circumstances that gave rise to the incident, together with the evidence that he/she wishes to be examined, should be included in the Request for Appeal.
      
      Upon receipt of such a request, the Circuit Discipline Commission will make a reasonable investigation of the facts and circumstances of the incident and will confirm or change in whole or in part the decision of the Chief Umpire. In the event of a total or partial change, the Circuit Discipline Commission will cancel the player's penalty, in accordance with the provisions of the appeal.
      
      Upon notification by an organizer, competition director or tournament umpire, the System Managing Committee may temporarily suspend the right to activate or remove a player from the Circuit, if he/she blatantly violates the rules of conduct and by his/her behavior is harming the safety of other players and their property or affects the good image of the Circuit. The decision of the System Managing Committee shall be final and without appeal.`,
    },
    section8: {
      p1: `In order to encourage players registered on the Sportya platform to increase the frequency of playing by participating in tournaments and friendly matches, the Sportya system generates rankings. These do not have the nature of official rankings, being internal rankings and strictly valid for the Sportya community. They are used to reward the most active players and are used in the tournament registration module.
      
      For each level of play, two rankings are automatically generated, one for singles and one for doubles, for which the individual activity of each player is taken into account, carried out within the Circuit in a standard reference period of 52 consecutive weeks, determined from the date when the rankings are calculated.
      
      Players accumulate points by participating in singles or doubles competitions and friendly singles matches and lose points through administrative penalties or by losing a friendly match.
      
      In any of the rankings, only the players appear for whom there has been a change in the personal score during the standard reference period of 52 weeks, resulting from participating in tournaments, playing friendly matches, receiving penalties, or changing the level of play.
      
      After the start of the competition season, the rankings are automatically updated weekly, being valid for the entire calendar week and being calculated at the beginning of it. Due to the periods required to report the results of the tournaments and the validation of friendly matches, the rankings take into account the results obtained two weeks ago.
      
      Example: The results of a tournament ending on Sunday 22/04 are entered into the system during the following week, respectively between Monday 23/04 and Sunday 29/04. In this way, the tournament will be included in the ranking calculated on Monday 30/04 and valid for the week 30/04 - 03/05.
      
      At the beginning of, or during, a competitive season, the System Managing Committee may decide to rebalance the levels of play, which results in the automatic change of the players’ level of play by at most one level, based on a well-defined algorithm. The players ranking system is based on the ELO system, customized for Sportya.`,
      p2: `Basic level. All the rankings of the six levels of play (4.0, 5.0, 6.0, 7.0, 8.0 and 9.0), both singles and doubles, start from a single basic level of 200 points. The personal score in the ranking reflects the player's activity, while the level of play indicates the level of performance in playing tennis.
      
      Score upon entry. Any player entering the Sportya system receives a "welcome" entry score equal to the basic level, respectively 200 points, in both the singles and doubles rankings.
      
      Following a change in level, the player's score on his/her new level changes as follows:
      
      • in case of moving to the lower level, singles or doubles, under the conditions of having a score of more than 200 points at the time of this level change, his/her new score, in the singles or doubles ranking, depending on the modified level, is 250 points, equivalent to the basic score plus a 50-point virtual tournament;
      
      • in case of (a) moving to the upper level or (b) moving to the lower level when his/her score is below 200 points, his/her new score is 200 points, equal to the basic level.
      
      Total score. The total score taken into account for the determination of each ranking is given by the sum of the following two components, minus any points penalties received during the standard reference period:
      
      - basic level, of 200 points;
      
      - an activity score, calculated according to the type of ranking, as follows:
      
      ∘ Singles ranking: the sum of the scores obtained in the best 7 singles competitions played in the tournaments registered on the Sportya platform, to which is added the score achieved in all friendly matches.
      
      ∘ Doubles ranking: the sum of the scores obtained in the best 7 doubles competitions played in the tournaments registered on the Sportya platform.
      
      The minimum score in any of the rankings is zero. In the case of a player who loses all his/her points received upon entry due to the friendly matches played or the accumulated penalties (these being the only situations when the score decreases), his/her score will remain zero, regardless of whether he/she still loses other points through activity.
      
      In the case of equal personal scores, the following will be considered with the aim of ordering the players in the ranking:
      
      - For the singles ranking: number of singles tournaments played, number of friendly matches played and seniority in the system;
      
      - For the doubles ranking: number of doubles tournaments played and seniority in the system.
      
      Moving to a higher or lower level of play is not possible during the year by accumulating or losing points, respectively.`,
      p3: 'Scoring a friendly singles match is done based on both the result (victory / defeat) and the level of play of the opponent at the time of the match.',
      p4: 'The friendly match in which there is a level difference of at least 3.0 levels between the two players is not taken into account.',
      p5: 'Scoring of singles competitions is done according to the table below, depending on the number of rounds played in the competition and the type of tournament. If a qualifying round is required, it will not count as a round when determining the number of rounds played.',
      p6: ` (*) does not cumulate with the score obtained by participating in round 1 of the main tournament
      
      The score for Special Tournaments and Champions Tournaments will be set for each competition, taking into account the number of participating players / teams and the proposed gameplay format. The scoring grid will be announced on the tournament page.
      
      Participation in matches that have been considered as qualifying is scored identically with elimination from the 1st round on the competition main draw.
      
      In the case of 1st Serve tournaments, participation is scored with 4 points, plus 4 points for each match won in the competition.
 
      In the case of the Local Leagues, the participation is scored with 10 points, to which are added 10 points for each match won in the League.
      
      In the case of both the 1st Serve and Local Leagues, the W.O. (walkover) are not taken into account for the calculation of the final score.
      
      The score obtained is the same, regardless of the opponent's level. The winner of a tournament is scored as if he/she passed in the next round after the finals.
      
      In the case of round-robin events (groups), the competition will be included in the scoring grid corresponding to the number of participants. The allocation of points will be made starting from the champion / finalist to the players without any victory, respecting the thresholds in the table.`,
      p7: 'If the final ranking is made at the group stage, without the players being promoted to a knockout round, the following point allocation structure applies:',
      p8: 'The scoring of the doubles competitions is identical to the singles ones, respectively depending on the number of rounds played in the competition and the type of tournament. The scoring grid is identical to that of the singles competitions, respectively each of the two players of a team receives a score that counts in the doubles ranking, according to the allocation rules mentioned for the singles competitions.',
      p9: 'The group stage matches with W.O. (walkover) are not considered victories and will not count towards the final points allocation.',
      subtitle1: 'Personal Score - basic level, score upon entry, total score',
      subtitle2: 'Scoring friendly matches',
      subtitle3: 'Scoring singles competitions',
      subtitle4: 'Scoring doubles competitions',
    },
    section9: {
      p1: `The Sportya system is built on the principle of levels of play, which involves maintaining a certain degree of uniformity of performance in the game of tennis, within each individual level of play.
      
      In order to achieve the best possible uniformity within a level of play, the system provides different mechanisms, through which a player can change his level of play during a competitive season:
      
      1. the procedure for rebalancing the levels o play, based on a standard formula; 
      
      2. administrative decision.
      
      In the Sportya system, changing the level of play of a player is not possible by accumulating or losing points, respectively. The scoring mechanism is used exclusively to generate the place in the ranking of the corresponding level of play.
      
      Following a change of level, the player's score on his new level of play changes as follows:
      
      • in case of moving to the lower level, under the conditions of having a score of more than 200 points at the time of this level change, his/her new score will be 250 points, equivalent to the basic score plus a 50-point virtual tournament;
      
      • in case of (a) moving to the upper level or (b) moving to the lower level when his/her score is below 200 points, his/her new score will be 200 points, equal to the basic level.`,
      p2: `Moving a player one level higher can be done by the decision of the System Managing Committee and / or the tournament supervisor, if the assessment of a player's level is considered to be clearly erroneous.
      
      Moving a player one level lower will only be possible by the decision of the System Managing Committee or the tournament supervisor, if it is considered that there is an obvious motivation to justify such a decision. Moving to the lower level can also be done by the System Managing Committee based on the request of the player. The player’s application will be analyzed only if the requesting player has participated in at least one tournament.
      
      In case of changing the level of play of a player, the selection of the level of a competition in which he/she can participate will be made with reference to his new level, once the level change is validated. If the player is already registered for a tournament using his/her old level, the System Managing Committee has the right to correct this by modifying the registration with reference to his/her new level, if possible.
      `,
      subtitle1: 'Changing the level of play by administrative decision',
    },
    section10: {
      p1: `The Managing Committee of the Sportya system and Media Production Group (MPG) does not assume any responsibility for the organization and supervision of matches and tournaments listed on the Sportya digital platform (Sportya.net), which is entirely the responsibility of the players and / or organizers of the respective tournaments, as appropriate. The consequences of violating the legal obligations regarding the organization of matches or tournaments will be borne exclusively and entirely by the players, respectively the organizers of each tournament.
      
      By choosing to play friendly matches and participate in tournaments listed on the Sportya digital platform, players take full responsibility for their health. Through this Rulebook, the System Managing Committee and MPG inform the players registered on the Sportya digital platform of the need for them to perform a regular medical check-up and one before participating in a tournament, the risk of not performing these medical checks being entirely assumed by the players.
      
      By registering on the Sportya digital platform and on any tournament listed on this online platform, players implicitly agree to waive any claims of any kind (including those related to any harm to bodily integrity or health, incurred on the way to / from or during the participation in any tennis match or tournament listed on the Sportya platform) against the tournament organizers, MPG and the Sportya system.
      
      By registering on the Sportya digital platform and on any tournament presented on this platform, it is considered that players agree not to associate under any circumstances the name of the Sportya system and / or MPG with illegal activities or that violate good morals. Sportya and / or MPG will not assume any obligation in connection with actions / inactions committed by a participant during or in connection with a friendly match or in a tournament listed on the Sportya platform, which would cause damage to material or moral rights of third parties, natural and / or legal persons. In connection with participation in a tournament or friendly match, the responsibility for the safety of personal property lies entirely with the players, the System Managing Committee and MPG not offering in this respect any assurance of whatever kind, implicit or explicit.
      
      Players participating in a tournament are advised to search for information regarding the facilities offered by the sports base / clubs where it takes place, before participating in the event. The System Managing Committee and MPG do not in any way guarantee the presence of facilities or their quality.
      
      No data, information and / or facilities offered through the Sportya digital platform will be used for personal purposes. The Sportya system and / or MPG will be exonerated of any liability in connection with their use without the express consent of the System Managing Committee and / or MPG or for any unlawful purpose by any person, including registered participants.
      
      By registering in the Sportya system and participating in friendly matches and / or tournaments listed on the Sportya digital platform, in connection with the players' participation in the friendly matches and / or tournaments and / or events presented on the Sportya digital platform, it is considered that the players implicitly agree:
      
      - to give MPG and the Sportya system all rights to the image (alone and / or in a group), static or moving, during or outside the friendly matches and / or tournaments and / or other events presented on the Sportya digital platform;
      
      - to recognize the right of MPG and the Sportya system to record and use personal data (e.g., name, biographical data, appearance, voice and match play performance), using any media (including audio, photo and video), for presentation and processing results, the promotion of the Sportya system and any related events;
      
      - not to have any material or other claim against MPG and / or the Sportya system in connection with their exercise of their rights indicated hereinabove;
      
      - to participate in promotional activities of the Sportya system, if there is a request from the System Managing Committee and / or MPG, provided that he/she has the necessary availability.
      
      (*) The Sportya Managing Committee reserves the right to amend this Rulebook without prior notice to players. 
        `,
      p2: '',
      subtitle1: '',
    },
    section11: {
      p1: `The matches in Sportya will be played without an umpire, as the players are umpiring themselves, and possible misunderstandings being referred to and resolved by the tournament referee. In addition to the trust and fair play that are desirable, beyond the rules of tennis, it is necessary to respect some additional principles:
      
      1. Each player (or doubles team) has the obligation to announce the errors (ball is out) on his half of the field. The "out" announcement must be made as soon as the ball has touched the court or immediately after the execution of the return shot, unambiguously and loud enough to be heard by the opponent, but without disturbing the matches played on other courts. The announcement may be accompanied by the following signs: the index finger pointing outwards - the shot was out; outstretched palm facing the ground - the shot was in.
      
      2. As soon as the shot is announced as being "out", the exchange of balls stops.
      
      3. The player who serves announces the score before starting each new point, so that he can be heard by the opponent.
      
      4. The start of the point means that both players agree with all decisions made before the score is announced. 
      
      5. Continuing to play the point means that all balls played so far have been considered good. All balls that are difficult to judge “instantly” must be played and eventually announced “out” immediately afterwards (after hitting the ball). For example, a reflex return on a ball that is difficult to judge at the time of impact will not be considered an intention to continue the game if the player announces "out" immediately after executing the shot.
      
      6. On clay, the trace left by the ball can be checked by the players. In the case of a "doubtful" ball, the player must encircle the impression in the ground left by the ball, without touching it. Touching or deleting it means awarding the point to the opponent. If the two players (or teams) agree on the impression, but not on the interpretation (shot in or out), they must address the tournament referee (or supervisor), who will decide even if he has not seen the action. The decision is not open to discussion and cannot be challenged.
      
      7. In case he has seen the action / shot and is sure of his appreciation, the tournament referee (or supervisor) can intervene to correct a mistake, whether or not there is a dispute between the players. The decision is not open to discussion and cannot be challenged.
      
      8. Players should not ask for the opinion of the spectators or take it into account.
      
      9. If a player shouted "out" incorrectly, he loses the point (the point is not re-played).
        `,
      p2: `● The players who are seeds (according to the Sportya general ranking valid at the time of the draw) will be highlighted as such on the main draw;
      
      ● “BYE” means free round for that player (occurs as a result of the smaller number of players compared to the number of positions on the main draw - the first beneficiaries are the seeds);
      
      ● "Friday, M4, C3, but not earlier than 20:00" means that the match will take place on Friday, being the fourth match of the day on course no. 3, but not earlier than 20:00, even if the course is free after the end of previous matches. In other words, the players who will play that match must be present at the venue of the competition, ready to play, at 20:00 at the latest.
      
      ● The maximum time allowed for entry on the tennis court is 15 minutes from the time the match is announced by the competition director. If a player does not appear on the court during this time period, the opponent will be recorded as qualified for the next round.
      
      All appointments may be delayed, either due to the length of previous matches played on the respective court or the weather conditions.
        `,
      p3: '',
      subtitle1: 'Match Umpiring',
      subtitle2: 'Other specifications',
    },
  },
  liga_locala: {
    mainTitle: 'SPORTYA LOCAL LEAGUE (SLL)',
    mainDescription: `Sign up FOR FREE in a Sportya Local League and play tennis WHEN YOU WANT, WHERE YOU WANT with opponents of the same level. 

        FOR FREE?! - booking fee is 0; moreover, you only pay as much as you play; 

        WHEN  I WANT?! - matches are scheduled by mutual agreement between players; 

        WHERE  I WANT?! - we recommend partner clubs; however, you play where you want, in agreement with the opponent. 

        Participation in the SLL is subject to the Sportya Rulebook, unless other special provisions have been made for this game concept. 

      `,
    section1: {
      p1: `SLL are of two types: 
        a) OPEN SLL; 

        b) SLL coordinated by a host club (usually, a club organizing Sportya tournaments). 

        The difference between the two types of leagues consists in the degree of support received from the clubs in terms of the coordination of the matches schedule and the progress of the Leagues. OPEN type leagues depend exclusively on the involvement of players in programming the matches with opponents and monitoring the progress of the League. 

        SLL proposes, as a play format, the play in a group with several players, in a round robin format (each competes against all other members of the group), which takes place over several weeks. A league can have one or more groups, for one or more levels of play. 

        The duration of a SLL, respectively of the matches in a group, is given by the number of stages of each (a group of N players will be played in maximum N weeks if N is odd, or (N1) weeks if N is even) . The stages are predefined and presented to the players, on the official competition page on the Sportya website, after the registrations for a League are ended. 

        Group matches can be played at any time. However, there is a limit to the timing of each match: the end of the week allocated to the respective stage, which the match is part of. 

        Registrations are made online, exclusively through the League's presentation page, in the first came, first served system. Registrations begin one week before the League's opening week at 22:00 on Monday and close on the day before the automatic draw procedure, at 20:00.


        Communication 

        The contacts of players from a component group of a SLL will be available to all opponents enrolled in that group, as well as the host club (where applicable). The representative of the host club can mediate the communication between players. Registration in a SLL implies the default consent for the distribution of the telephone number, without which the organization of the SLL would not be possible. 


        Tennis courts / location 

        If the SLL is coordinated by a host club, the location of the club's tennis courts will be deemed as the official location, and all matches in the SLL will be associated with that location. However, players have the option to decide by mutual agreement to play the match in another location. In the event that misunderstandings arise between players regarding the determination of the location, the official location will always take precedence. 

        In the case of OPEN type SLL, the location of a match will be determined by the players, by mutual agreement. If players fail to finalize the match scheduling details, the match will be marked with WO for both players. 


        Costs/Courts rental 

        Online registration in a SLL is free. 

        The carrying out of the matches in SLL may involve renting a tennis court, which cost is not directly covered by SLL registration. The cost of the courts will be supported equally by the two players. We recommend renting an interval of 90-120 minutes per match or ensuring, as far as possible, that the period can be extended if the reserved time does not allow the completion of the match. 


        Match scheduling 

        All scheduling, regardless of the type of SLL, is determined by the mutual agreement of the opponents, each match having a deadline at the end of the week allocated to the stage which the match is part of. 

        Example: a match in the 3rd stage of a SLL can be played at any time in the first three weeks of the league, but not later than the Sunday of the third week. 

        If the SLL is coordinated by a host club, the club’s representative may mediate communication between the players for the programming of the match, but the ultimate responsibility rests with the players. If the players fail to finalize the match scheduling details, the match will be marked with WO for both players.


        Play balls 

        The play balls are provided by the players for each match, the choice of the brand and the number of balls to be used being also determined by mutual agreement by the players. We recommend that the match be played with a minimum of 2 new balls, or 3 balls played, but in good condition and the costs involved will be shared by mutual agreement. 


        Match Format 

        We recommend that all matches be played with super tiebreak (10 points) at 1 set all. However, given the flexible nature of SLL, players can only choose to play with decisive set if both agree. 

        To level the scores, the decisive set will be considered with the score 1-0 (+1 at the game score), regardless of the super tiebreak or the decisive set score. 


        Results 

        The results will be updated online by one of the players, from the personal page on the website. We recommend that players determine who will take responsibility, namely the score reporting into the system to take place by the end of the day on which the match was played. Any changes will be requested, in due course, from the representative of the host club or by e-mail at contact@sportya.net.


        League results / scores 

        The League results are determined after the finalization of the last stage of a League. Participation in SLL counts in the Sportya general ranking. Participation is scored with 5 points; each victory will be scored with 10 points. 


        Awards 

        The top two players in the League will be rewarded with diplomas. Any additional prizes will be indicated on the official League page. 


        Withdrawals / penalties 

        A player may give up from participating until the end of the registration period, such situation being not deemed as a withdrawal from the technical point of view.

        The withdrawal after the closing of the registrations and until the time of the first match will be announced to the representative of the host club or by e-mail at contact@sportya.net and it will be penalized with the suspension of the right to enroll in a new League for the next 5 weeks. 

        Non-attendance (unannounced withdrawal) at a match scheduled and confirmed by the opponent may result in the elimination from the League. The decision will be made by the representative of the host club or by the Coordinator of the Sportya system, depending on the circumstances. 

        `,
      subtitle1: 'CONCEPT',
    },
    section2: {
      p1: `In compliance with the Sportya Rulebook, the participation in the SLL play system is made on each person’s own responsibility, both from the point of view of interaction with other players, and considering the medical/physical condition necessary for physical activities. 

        Moreover, the smooth running of a SLL depends on the degree of availability and understanding of each player, in all aspects of the League: location / schedule / play balls. 

        We recommend flexibility and availability against the unforeseen situations (last minute rescheduling, postponements, etc.). All players will need understanding, from opponents, at some point. 

        Both the players and the representatives of the host club (where appropriate) can provide the necessary feedback to maintain an atmosphere favorable for evolution within Sportya. We are looking forward to receiving your impressions at contact@sportya.net.

        `,
      subtitle1: 'RESPONSIBILITY – GOOD UNDERSTANDING ',
    },
    section3: {
      p1: `1. What is the size of the groups? 
        The standard size will be 5 players (5 stages in 5 weeks, with 4 matches for each player), but may vary depending on several aspects. No groups smaller than 4 players will be made. 

        2. What is the structure of a League? 

        The structure is flexible. A league may consist of a single group for a single level of play, or it can reach multiple groups for multiple levels of play. 

        3. How do I communicate with the other players in my group? 

        The players' phone numbers will be available to all members of a group, as well as to the host clubs / organizers. Players or club representatives can form a group on social networks (e.g., WhatsApp, Viber) for quick communication and supervision of the program.

        4. Is it mandatory to offer the phone number to opponents? 

        Yes, as the players mutually agree on their matches; the club representative has no obligation to determine the scheduling of the matches. 

        5. What happens if the players do not agree on the location? 

        If the League is coordinated by a host club, that match will be played in that club. In the case of an OPEN type League, WO will be noted for both players. 

        6. What happens if players don't agree on the date/time? 

        If the League is coordinated by a host club, players can resort to the club representative to mediate the situation. However, there is no obligation for the representative to schedule the match (as in classic Sportya tournaments). Thus, if a common denominator is not reached, the game can also be marked with WO for both players. We recommend avoiding these problems and approaching each situation as balanced as possible. Following the feedback received, consistent with the number of WO registered, players may risk suspension for participating in other events within the Local Leagues. 

        7. What if a player asks to reschedule the match?

        If this happens more than 24 hours before the match, the opponent must offer the chance of a rescheduling. If it happens less than 24 hours before the match, the opponent may agree to a reschedule or request to win the match via WO. We recommend players to approach each situation with maximum flexibility and understanding towards the playing partner. 

        8. How are the League final results calculated if some matches are played with a super tiebreak and others with a decisive set? 

        The official format for making the calculation is the one with super tiebreak up to 10 points (two points difference at 9-9). In this case, the super tiebreak is considered a set won with 1-0 (one game to zero; +1 for the game scoring). If a decisive set is played, it will be considered 1-0, regardless of the final score. 

        9. What happens in case of a tie in the League final results? 

        The differentiation is made following the result of the direct match. If there are three or more players with the same number of victories, the ranking is determined by taking into account in the following order: the set scoring, the game scoring, the highest number of sets / games won, considering all the matches in the group. If one of the above criteria generates a better or weaker player than the other two, a direct victory will be considered when differentiating between the two remaining players.
        
        10. Are groups followed by other phases of the gameplay? 

        In the first period of the development of the Leagues, the group closes with the League final results being determined after the last stage related to group matches. Different variations of the gameplay will be considered for the future. `,

      subtitle1: 'FREQUENTLY ASKED QUESTIONS',
    },
  },
  terms: {
    mainTitle: 'TERMS OF USE',
    section1: {
      p1: `These terms and conditions of use (hereinafter referred to as the "Terms of Use") govern the use of (including access to) the website Sportya.net (hereinafter referred to as the "Website"), operated globally by Media Production Group S.R.L. (hereinafter referred to as "MPG" or "us"), a limited liability company, legally incorporated and operating in compliance with the laws of Romania, having its registered office in Câmpina, 15 Mărăsesti Street, Prahova County, ROMANIA, post code 105600, registered with Prahova Trade Register under no. J29/1130/1997, having Unique Registration Code 9815911, Tax Registration Code / VAT code RO9815911, including the content made available through this Website. 
        Any person accessing the Website ("you") accepts these Terms of Use in effect at the time of your visit to the Website and agrees to comply with these Terms of Use. These Terms of Use are subject to change from time to time, and your use of this Website is subject to the Terms of Use valid at the time of your use of the Website. Please check these Terms of Use regularly to ensure that you agree to them. If you do not agree, please do not use the Website.

        Visiting, using, or purchasing services / products on the Website imply the acceptance of the Terms of Use. 

        The Website provides information support regarding the matches and tournaments / events organized under the auspices of Sportya, offering a mechanism for enrolling amateur players in Sportya, a system for self-assessment of the level of play, a database containing players registered in the system, their personal pages, an information system on the tournaments listed in the competitions calendar of the current year, as announced and approved by the organizing tennis clubs, a system for reporting of the results of the matches played between registered players and those recorded in tournaments within Sportya, an algorithm for calculating the individual score of each registered player and an overall ranking based on these scores, but also others (collectively referred to as "Services"). The Use of the Services is governed by a series of rules that must be observed by all users of the Website. 

        Once you use the Services, you agree with the terms of the Terms of Use and you are responsible for complying with them, whether you are a mere "Visitor" (you have entered the Website without registering) or a "Member" / "User" (you have registered in the system and you have an account and a personal page). Registration in the system is necessary to have access to all the functionalities of the Website and all services. The registration procedure may be terminated by a prospective Member only after he / she has explicitly consented to the compliance with the Terms of Use. 

        Hereinafter, the term "User" includes both Members and Visitors. You have the right to use the services of the Website (whether your access to or use of the Website is accidental or intentional), only if you agree to comply with the relevant legislation and Terms of Use. If you do not agree with the Terms of Use, you must leave the Website and stop using the Services. Any subsequent access to the Website will be deemed as your acceptance of the terms and conditions set forth herein. 

        Any violation of the Terms of Use allows the MPG administrator to give warnings and ultimately restrict (at his sole discretion) the access of the user concerned, indefinitely. No user can invoke as an excuse for violation of the Terms of Use the fact that he/she did not know of their existence. MPG undertakes the obligation to post a link to the Terms of Use on the main page of the Website (Home Page) at all times, so that it can be easily identified and read.

        The content we make available through the Website ("Content") may be governed by special terms ("Special Conditions") which you accept each time you access that Content. In the event of a conflict or inconsistency between the Terms of Use and any Special Conditions, the Special Conditions shall prevail over these Terms of Use. Each time you access the Website and its Content, you accept these Terms of Use and any Special Conditions applicable to that Content.

        The access to the Website is temporarily permitted and we reserve the right to withdraw or modify the Content we provide on the Website without notice. From time to time, we may restrict the access to certain parts of the Website or the entire Website.

        We have no obligation to make the Website available and will not be liable if, for any reason, the Website is not available, in whole or in part, at any time or for any period.

        `,
      subtitle1: 'SUBJECT OF THE TERMS AND CONDITIONS AND ACCEPTANCE THEREOF',
    },
    section2: {
      p1: `You acknowledge and agree voluntarily and expressly that your use of the Website is exclusively on your sole responsibility.
        You are responsible for all arrangements necessary to access the Website. You are also responsible for ensuring that all persons accessing the Website through your internet connection are aware of and comply with these Terms of Use and any applicable Special Conditions.

        If you choose or are provided with a user identification code, password or any other information, as part of our security procedures, you must treat this information as confidential and must not disclose it to any third party.

        We have the right to deactivate at any time any user identification code or password, whether chosen by you or assigned by us, if, in our opinion, you have not complied with any of the provisions of these Terms of Use.

        By accessing the Website, you agree that you will not take any action that could harm the image, interests or rights of us or those of any of our affiliates or that could harm the Website, render it unusable or overload it, or in any way impede the normal use of the Website by other visitors.

        We implement reasonable security measures which are appropriate for detecting the existence of viruses. However, you should bear in mind that the existing security measures for computer systems on the internet are not entirely reliable and that, therefore, we cannot guarantee the absence of viruses or any other elements that could cause changes to your computer systems (hardware and software) or the data and files contained in your systems.`,

      subtitle1: 'USE OF THE WEBSITE',
    },
    section3: {
      p1: `We will have no obligation to verify the accuracy of the Content and do not guarantee the usefulness, accuracy, completeness or relevance of the Content and/or that such Content is up to date. We expressly exclude any liability for errors or omissions with respect to the Content and the Website, unless such liability is caused by fraud or fraudulent misrepresentation on our part.
        The inclusion of the Content in the Website does not in any case constitute the provision of staffing services, consultancy or any other type of services. MPG and our affiliates expressly exclude any liability for decisions made by you, based on the Content.`,

      subtitle1: 'CONTENT',
    },
    section4: {
      p1: 'Prices presented on the Website include all applicable taxes. The payment of services is made by various means - debit/credit card or bank transfer, as they are presented on the Website for each service.',
      subtitle1: 'PRICES AND PAYMENT METHODS',
    },
    section5: {
      p1: 'The Benefits related to the purchase of a service (e.g., Premium Membership Account) are activated when the payment is confirmed. The right to participate in an event is activated after the online payment of the Sportya Service Fee, which represents the value of the digital services offered by the Website in connection with the event. Any deposit related to the crediting of the Virtual Wallet is carried out upon the receipt of the payment. The provision of all services will start during the 14-day withdrawal period. The users of the Website are informed during the procedure of service purchasing that the right of withdrawal will be lost after the complete execution of the purchased services.',
      subtitle1: 'PROVISION OF SERVICES',
    },
    section6: {
      p1: `A. On the Sportya.net website, you can pay the membership fee by purchasing one of the available accounts (e.g., Premium Account), you can register for a tennis tournament by paying an online fee representing the value of the digital services offered by the Website in connection with the progress of the event (Sportya Service Fee), you can credit your Virtual Wallet with an amount to be used later, and you can buy products associated with the registration in a competition, as part of the participation kit that you will receive when you present yourself at the tennis club / sports center to register your presence in the respective competition.
        B. If after purchasing on Sportya.net one of the mentioned services / products, you want to give up the purchase and implicitly to receive your money back, you can apply for the refund of the products within 14 days, according to the Return Policy regulations, available on the Website, including during the event registration procedure. `,

      subtitle1: 'RETURN POLICY',
    },
    section7: {
      p1: `All Content on the Website is either owned by us or licensed by us for use. Everything you see or read on this Website (such as images, photographs, illustrations, texts, videos, results, tables, figures, together with the method for the recording and processing of the information, including the algorithm for the calculation of the personal score, and the generation of the ranking and other materials) is protected worldwide by copyright, design, trademark and other laws relating to intellectual property rights.
        You must always comply with all intellectual property rights relating to the Website and the Content, whether owned by us, any of our affiliates or third parties. You must not obtain or attempt to obtain any part of the Content by any means or procedures other than those made available to you by the Website.

        These Terms of Use or your use of the Website do not in any way grant you any other intellectual property rights to the Website or Content, other than those specified herein or under any Special Conditions.

        Therefore, you are expressly prohibited from making any reproduction, transformation, distribution or public communication, or from making available, extracting, reusing, resubmitting or otherwise using, by any means or procedure, any part of the Website or the Content, except as permitted by these Terms of Use or any relevant Special Conditions, or unless the applicable law allows you to do so, or when you have been provided with an explicit authorization by the relevant copyright holder.

      `,
      subtitle1: 'INTELLECTUAL PROPERTY RIGHTS',
    },
    section8: {
      p1: `Links to other websites operated by third parties that are not affiliated with our Website may be indicated on the Website. The inclusion of any link to these third-party sites does not imply endorsement by us of these sites, and we decline any responsibility for any third-party sites, to which or from which there are links on this Website.
        We have not reviewed all websites to which links appear on the Website and we are not responsible for the content or accuracy of any pages outside the Website or any other sites to which there are links on the Website. Tracking any link to any other page outside of the Website or to any third-party site is carried out at your own risk.

        Any link to our Website from a third-party website requires our prior written permission.

      `,
      subtitle1: 'LINKS AND USE OF LINKS',
    },
    section9: {
      p1: 'The collection and use of your personal information are carried out in compliance with the provisions of Regulation 2016/679 applicable in all Member States of the European Union, being governed by the USER/MEMBER DATA PRIVACY STATEMENT, which is provided as a separate document and is available on the Website.',
      subtitle1: 'PERSONAL INFORMATION',
    },
    section10: {
      p1: 'For proper operation, the Website involves communication with users who sign up for tennis events / tournaments, in connection with calendar tournaments, reservation of participation, match programming, withdrawals, penalties, and many more. To be effective, this communication uses several techniques of remote communication: electronic mail (e-mail), telephone with human intervention, SMS on mobile phone. By using the Services and the Website, all users agree to use and receive messages on the aforementioned communication channels, as long as they facilitate the use of the services and improve the experience.',
      subtitle1: 'USE OF REMOTE COMMUNICATION',
    },
    section11: {
      p1: `The Website allows Members to post pictures and transmit to other members through its functionalities various information in text format, including links or other materials (collectively referred to as "User’s Content"). The photos must be relevant and comply with certain technical characteristics (described on the Website), and the User’s Content must not be offensive, illegal or infringe, harm or pose a threat to other users, otherwise MPG may delete such pictures or User’s Content at its discretion. However, MPG undertakes no responsibility for monitoring the services with regard to inappropriate photos, content or behavior of users. By sending messages to other users and attaching pictures to the Website, you agree that you are solely responsible and will indemnify the Website and / or MPG for any damages, losses or costs incurred as a result of the attachment, transmission or your reference to a User’s Content that breaches the above provisions. If you consider that you have been harmed by messages received through the internal messaging system or by displaying pictures on users' personal profiles: 
        - If a person considers that his / her copyright has been infringed or that damage has been caused by sending messages on the Website, we cannot disclose the personal data of the author of the message, as the communication of such data to third parties is not allowed without the express agreement of the data subject, in compliance with the provisions of art. 5(1) of the Romanian Law 677/2001. 

        - If you consider yourself wronged in any way due to a message received through the internal messaging system, you can proceed to notify the judiciary or other body empowered by law. We will comply and respond to requests from such a public authority, as long as they comply with the legal requirements, and we will disclose the data of its author to the competent authority and comply with its provisions, if applicable. 

        The Website offers exclusive functionalities to its users, through which they can report their tennis matches played with other users, which have an effect on the ranking automatically generated by the algorithm for calculating the individual score of each registered user. The responsibility for the correct registration of personal data and those related to the results of matches held between users lies entirely with them, as MPG makes no warranty, express or implied, regarding the correctness and accuracy of such information.

      `,
      subtitle1: 'CONTENT POSTED BY USERS ON THE WEBSITE',
    },
    section12: {
      p1: `The Chat feature on the Website is a private and encrypted application intended for users to discuss and share their thoughts and experiences related to sports, and provide support to players seeking match or training opportunities. 
      
      The following behaviors are strictly prohibited on the Chat feature and may result in a permanent ban from the platform:

      1. Harassment or Bullying: Any behavior that is intended to harass, intimidate, or cause distress to another user is strictly forbidden. This includes, but is not limited to, sending threatening or aggressive messages, spreading rumors or false information about another user, or engaging in cyberbullying.

      2. Bad Words: Any use of profanity or offensive language is strictly forbidden. This includes, but is not limited to, using racial slurs, homophobic language, or any other language that is intended to offend or hurt another person.

      3. Hate speech of any kind: Any expression of hatred or bigotry towards a particular group of people is strictly forbidden. This includes, but is not limited to, expressions of anti-Semitism, racism, sexism, or homophobia.

      4. Sharing of Sexual, Explicit, Pornography, or Child abuse comments or content: Any sharing of sexually explicit, pornographic, or child abuse related content is strictly forbidden. This includes, but is not limited to, sharing images, videos, or text that is sexual in nature or that depicts violence or exploitation.

      5. Sharing of links or URLs containing phishing, viruses, malware or spyware: Any sharing of links or URLs that are intended to phish for personal information, distribute viruses, malware, or spyware is strictly forbidden.

      6. Spam: Any behavior that is intended to send repetitive, unsolicited messages or comments that are not related to sports or the conversation on the platform is strictly forbidden.      

      We reserve the right to permanently ban any user who engages in prohibited behavior on the Chat feature.
      By using the Chat feature on Sportya, you agree to abide by these terms and conditions and to refrain from engaging in any of the prohibited behaviors outlined above. We reserve the right to modify these terms and conditions at any time. If you have any questions or concerns about these terms and conditions, please contact us at contact@sportya.net
      `,
      subtitle1: 'CHAT & MESSAGING',
    },
    section13: {
      p1: `Participation in the Member-get-Member feature implies the user's agreement to comply with these terms, as well as any additional guidelines or rules provided by Sportya. It is important to carefully read and understand these terms before participating.
      
      Eligibility:
      \xA0\xA0\u2022 Users who create their Sportya accounts using the referral codes of existing Sportya users will be eligible for rewards.
      \xA0\xA0\u2022 Existing Sportya users who invite new players using their unique referral code will also be eligible for rewards.

      Reward Criteria:
      \xA0\xA0\u2022 New Users:
      \xA0\xA0\xA0\xA0\u25E6 New users who sign up on Sportya using a referral code will receive a reward of 5 Euro or the equivalent in the currency of their Virtual Wallets on Sportya.
      \xA0\xA0\xA0\xA0\u25E6 The reward will be granted to new users once they complete one of the following eligible actions for the first time. 
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Obtaining their First Validated Result in a Sportya tournament
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Topping up their Virtual Wallet
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Purchasing a Premium account with Virtual Wallet or Credit Card
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Participating in a Friendly Match with a validated score for the first time
      \xA0\xA0\xA0\xA0\u25E6 Each user will receive the reward only once.
      \xA0\xA0\u2022 Existing Users:
      \xA0\xA0\xA0\xA0\u25E6 Existing users who successfully refer new players using their unique referral code will receive a reward of 5 Euro, or the equivalent in the currency of their Virtual Wallets, for each of these players who successfully creates an account on Sportya and completes one of the same eligible actions mentioned for new users for the first time:
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Obtaining their First Validated Result in a Sportya tournament
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Topping up their Virtual Wallet
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Purchasing a Premium account with Virtual Wallet or Credit Card
      \xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\u25AB Participating in a Friendly Match with a validated score for the first time
      \xA0\xA0\xA0\xA0\u25E6 The maximum number of invited players for which an existing user can be rewarded is 10.

      Reward Distribution:
      \xA0\xA0\u2022 Rewards will be distributed to eligible users within a reasonable time after the completion of the eligible action by the referred user.
      \xA0\xA0\u2022 The rewards will be credited to the Virtual Wallet of the users in the currency equivalent mentioned in the reward criteria section.

      General Conditions:
      \xA0\xA0\u2022 Users are prohibited from engaging in fraudulent, misleading, or deceptive activities to obtain referrals or rewards. Any such behavior will result in disqualification from the Member-get-Member feature and may lead to the suspension or termination of the user's Sportya account.
      \xA0\xA0\u2022 Sportya reserves the right to verify the eligibility of users and the validity of referral actions. Violation of these terms or suspected abuse of the Member-get-Member feature will result in the forfeiture of rewards.
      \xA0\xA0\u2022 Sportya reserves the right to modify, suspend, or terminate the Member-get-Member feature or these terms at any time without prior notice. Reasonable efforts will be made to notify users in the event of such changes.
      \xA0\xA0\u2022 Users are responsible for any applicable taxes, fees, or other liabilities arising from participating in the Member-get-Member feature and receiving rewards.`,
      subtitle1: 'SPORTYA REWARDS & MEMBER-GET-MEMBER',
    },
    section14: {
      p1: `On the Website, the following user activities are strictly prohibited: 
        a. the creation and use of "clones" (multiple accounts of the same user). The discovery by the Website administrator of these accounts will result in the permanent suspension of clone accounts and the suspension of the main account for an indefinite period. 

        b. through the internal mail system of the Website, harassment, insulting in any form of the users of the Website, the use of obscene, threatening, abusive or indecent words, abusive, offensive, threatening or defamatory behavior, the transmission of information that is known to be false, misleading or promoting illegal activities. 

        c. the transmission of information, files, etc. to users in violation of copyright and related rights provided by Romanian Law  8/1996, as well as any other rights of third parties. All copyright on the photos attached to the Website belong to the authors. Their reproduction (on any media) without the permission of the authors is a violation of the laws in force. If you wish to use them, please contact the authors of the works. MPG assumes no obligation to protect the photos displayed on the Website, to compensate the authors or to help the authors to be compensated for the damage they have suffered. 

        d. sending the same message repeatedly and abusively or sending similar messages to multiple users ("flood"). 

        e. sending unsolicited commercial messages to other users, even if done through the internal messaging system, sending "unsolicited mail", "chain letters" or mass mail, etc.

        f. the collection of any personally identifiable information from users, the request from other users of personally identifiable information or password for commercial or illegal purposes, the use of another user's account, password or personal page. MPG reserves the right to investigate and take appropriate legal action against any person who, at its sole discretion, violates these provisions, including, without limitation, removing inappropriate content from the Website or stopping access to the Website services for such users. In addition, in case of suspicion on the legality of the messages posted, MPG may report the situation to the authorities able to apply the law, this fact including the disclosure to these authorities of the personal data of those concerned.

        `,
      subtitle1: 'PROHIBITED ACTIVITIES',
    },
    section15: {
      p1: `The information on the Website, including the documents underlying the operation of Sportya (e.g., Sportya Rulebook regarding the participation in events, the Return Policy, etc.) are translated / offered in several language versions. All language versions are deemed equally authentic. In the event of any discrepancy between the versions, the English version will prevail in determining the spirit, intention and meaning of an aspect.
        For all purposes, the English language version of the Content, regardless of its nature (descriptive text used in the Website, document, etc.) will be the original, governing tool. 

        `,
      subtitle1: 'GOVERNING LANGUAGE',
    },
    section16: {
      p1: 'You agree to indemnify and exonerate MPG, together with its employees, management and shareholders, in connection with any claim or request (including reasonable attorneys\' fees) from a third party arising out of or in connection with your use of the Website\'s internal messaging system, your breach of the Terms of Use, or infringing any intellectual property rights made from your computer. MPG reserves the right, at its own expense, to ensure its exclusive defense and control over any matter that would otherwise be the subject of compensation on your part.',
      subtitle1: 'COMPENSATION',
    },
    section17: {
      p1: `We do not warrant or represent that the Website and/or Content are accurate, complete, error-free or reliable or that the use of the Website and/or Content will not infringe the rights of third parties. We do not warrant or represent that the functional aspects of the Website and/or the Content will be error-free or that the servers that make it available do not contain viruses or other harmful components.
        The use of the Website and/or the Content is carried out at your own risk and all items on the Website are made available to you "AS SUCH" and "AS AVAILABLE" without any express or implied warranty.
        `,
      subtitle1: 'WARRANTIES, LIMITATION OF LIABILITY',
    },
    section18: {
      p1: 'The Terms of Use, the rights and obligations imposed by them, and all legal effects produced by the Terms of Use will be construed based on and governed by the Romanian law. Romanian law will govern all aspects that were not provided for by the Terms of Use. Any dispute regarding the Terms of Use will be referred to the competent courts in Romania.',
      subtitle1: 'APPLICABLE LAW AND JURISDICTION',
    },
    section19: {
      p1: 'By using, viewing or purchasing the services on the Website, the user is aware that the Romanian laws will govern the above Terms of Use and any dispute of any kind that may arise between the user and MPG. In the event of any conflicts between MPG and the users of the Website, we will first try to settle them amicably within a minimum of 30 business days. If amicable settlement will not be possible, an attempt will be made to find a solution without resorting to the courts, through the use of the online platform https://ec.europa.eu/consumers/odr/ provided by the European Commission. If none of the previous ways have led to a solution, the conflict will be settled in the competent court in compliance with the Romanian laws in force.',
      subtitle1: 'DISPUTES',
    },
    section20: {
      p1: 'If any of the above clauses is found to be void or invalid, for whatever reason, it will not affect the validity of the other clauses. With the launch of an order on the Website, the customer accepts without objection the Terms of Use, their value being the same as a valid contract concluded. The withdrawal from the contract is regulated in the Return Policy. By using the services of the Website, the customer agrees to these Terms of Use and fully undertakes the rights and obligations arising from the purchase of services on the Website.',
      subtitle1: 'INDEPENDENCE',
    },
    section21: {
      p1: 'These Terms of Use may vary from time to time, and your use of the Website is subject to the current terms at the time you access the Website and its Content. Please check these Terms of Use regularly to ensure that you are familiar with them.',
      subtitle1: 'AMENDMENTS',
    },
    section22: {
      p1: 'Questions, comments and requests regarding these Terms of Use may be directed to us via corporate@sportya.net.',
      subtitle1: 'CONTACT',
    },
  },
  confidentiality: {
    mainTitle:
      'USER / MEMBER DATA PRIVACY STATEMENT',
    mainDescription: `Media Production Group S.R.L. (hereinafter referred to as "MPG" or "we"), the global operator of the Sportya.net Website, undertakes to protect your right to the privacy of your personal data when using the services (hereinafter referred to as "Services") of the Sportya.net website (hereinafter referred to as "Website"), in compliance with the provisions of Regulation 2016/679 applicable in all Member States of the European Union.
      Through the Website, we collect and use information about you, in order to provide you with our Services related to the operation of Sportya (hereinafter referred to as "Sportya") and to your activity within Sportya.

      MPG undertakes to protect and respect your privacy. This Data Privacy Statement describes your privacy rights in connection with the data about you that we collect and process, and the measures we take to protect your privacy. `,

    section1: {
      p1: `In this Statement, we will refer to you by using the terms ‘You’ or ‘User’.
      When we talk about ‘us’, or ‘MPG’, we refer to Media Production Group S.R.L., a limited liability company, legally incorporated and operating in compliance with the laws of Romania, having its registered office in Câmpina, 15B Mărăsesti Str., Prahova County, ROMANIA, post code 105600, registered with Prahova Trade Register under no. J29/1130/1997, having Unique Registration Code 9815911, Tax Registration Code / VAT code RO9815911. MPG is one of the largest service providers for amateur athletes in Romania, through hundreds of sports events under its coordination, together with its affiliates, the MPG Sports Club Association and the ProAm Events Foundation ("Affiliates"). Through its various projects dedicated to sport for everyone, MPG and its Affiliates provide various services dedicated to active leisure, from amateurs’ access to sports competitions, to the management of registrations in these competitions and the presentation of information related to competitions, such as lists of participants, results, the recording of the history of sports activity, live watching solutions in sports events, etc. ("Our activities").

      To carry out our Activities, MPG uses several IT systems. For Sportya, the Website is at the same time a Self-Service Portal (the "Portal") for competition organizers and potential participants. The portal will allow you not only to search and register for events that organizers publish or that are previously approved and published by MPG, but also to keep a record of the entire activity carried out in the events registered on the Portal.

      Ultimately, this is a statement on the information about people – like you. It includes facts about you (e.g., results following your participation in events/tournaments), and also features about your sporting activity (e.g., your level of play). It is not about information related to MPG (although sometimes the two overlap). This information is sometimes called "Personal Data", "Identifiable Personal Data" or "PII". In this statement, we will use the term "Personal Data".
      `,
      subtitle1: 'Explanation of certain terms',
    },
    section2: {
      p1: `The personal data collected by MPG typically includes, but is not limited to:
       • your name, date of birth, contact details (e-mail, telephone, address), level of play according to the Sportya regulation, documents proving your identity;

       • if you log in to the Portal using your Facebook or Google account, or other social networks: your profile data;

       • if you contact us, in some cases we will register that correspondence;

       • feedback about you from our staff and third parties: if you provide feedback about others, we usually keep this information as well, and it represents personal data about you (being your opinion);

       • we may collect information about your visits to our Website/Portal, including but not limited to your IP address, browser, date and time registration, location, country traffic data, location information, Weblogs and other communication information and resources that you access. This information will make your future visit to our website/portal easier. For example, we may suggest sporting events held in the location from which you contacted / visited us;

       • we may also collect information for purposes of marketing and analytics, including information about how you respond to email, SMS, telephone and other marketing campaigns;

       • if applicable, details of any disabilities and any adjustments we will have to make for you.
      `,
      subtitle1:
        'What personal data does MPG collect and use?',
    },
    section3: {
      p1: `MPG collects and processes personal data:
       1. to provide you with the services you expect from us, when you accept by explicit consent to become a member of Sportya, namely a user of the Website/Portal, such as finding sports events / tournaments in the area where you reside, in order to help you with recommendations or to facilitate the process of participating in the scheduled sports events. This includes sending your name and relevant personal information (e.g., telephone number, level of play) to the organizers of the sports events you have signed up for, so that they can inform you and keep you up to date on the organization details or manage your participation, and by default be able to carry out the event organization activity. The organizers of the sports events registered on the Website / Portal can thus contact you by e-mail, telephone and / or other communication methods;

       2. with your explicit consent, we may use your contact details for direct marketing, for example via e-mail, messages or telephone;

       3. to develop, test and improve our Website/Portal or other new or existing systems / processes, in order to better serve you; this takes place in the context of new IT systems and processes, so that data about you can be used to test these new IT systems and processes, where bogus data cannot fully replicate the operation of that new IT system;

       4. to carry out studies, statistics and analytical research, for example to compare the running of sports events between different geographical areas, respectively the interest shown by participants in these events;

       5. for data transfer to third parties (see below);

       6. if necessary, to comply with any legal obligations; and

       7. we may also process your data:

       a. to promote the security and protection of persons, premises, systems and assets;

       b. to monitor the compliance with the Sportya and/or MPG internal policies and procedures; 

       c. to manage communications and other systems used by MPG and its affiliates (including internal contact databases);

       d. to investigate or respond to incidents and complaints;

       e. in the case of photos or videos resulting from your participation in events, to promote similar events internally and externally to third parties / potential new participants, sponsors, partners; or

       f. to comply with the obligations and rights and to cooperate in investigations carried out by the police, government or regulators; and

       g. to participate in any potential or actual acquisition or sale, or in a joint venture, in a business or company, in whole or in part, in which MPG or its Affiliates wish to participate.
      `,
      subtitle1: 'Why do we use personal information about you?',
    },
    section4: {
      p1: `According to the law, we must have a legal basis for processing the data we keep about you.

      The processing of personal data by us for all the purposes listed above is based on the reason for processing, such as the performance of the Services to which you are entitled, by registering as a member in Sportya (objectives 1 and 6-7), processing necessary to comply with the legal or regulatory obligations (objectives 6-7) and / or the necessary processing in the legitimate interests of MPG in the exercise of its fundamental rights and of its personnel to carry out an activity in a way that does not affect your interests or your fundamental rights and freedoms (objectives 1-5 and 7). When data processing is necessary for the legitimate interests of the MPG, we ensure that the processing is performed in such a way that our legitimate interests outweigh any individual interests. Except for the above-mentioned reasons, we will process your personal data only with your consent (an additional processing basis).
      `,
      subtitle1: 'What are the provisions of the law in this regard?',
    },
    section5: {
      p1: 'You do not have the obligation to provide us with your personal data, but it is almost impossible for us to work with you and offer our services to you otherwise.',
      subtitle1:
        'Are you bound to provide us with the personal data we request?',
    },
    section6: {
      p1: 'Yes, we do this sometimes. MPG may use automated systems/processes and make automated decisions (such as creating analytics profiles), to provide you and the organizers of sports events/tournaments, as customers, with the services you request from us. For example, when our customers want to promote an event / tournament, we may search our list of Service users by using automated criteria that take into account the level of play, past sporting activity and, in certain circumstances, previous feedback we have received from customers, to make a short list that includes users who will most likely be interested in our client\'s event / tournament. ',
      subtitle1:
        'Do we process personal data about you without human intervention?  ',
    },
    section7: {
      p1: 'MPG may keep your personal data indefinitely, as it contributes to the functionality of the Services, and to the correct display of information related to the conduct of an event/tournament (e.g., final results of the event/tournament).',
      subtitle1: 'How long do you keep your personal data?',
    },
    section8: {
      p1: `As mentioned above, we usually disclose your data to third parties. The reason is to complete the objectives set out above. We carry this out under the following circumstances:
         • To our customers / organizers of events / sports tournaments: we will share your data with our customers who implement the events/tournaments you have signed up for. They have contact and other privacy obligations in connection with your data, towards us and towards you;

         • To our suppliers. We may, for example, contract a supplier to perform administrative and operational work in support of our relations with you. The provider(s) will be bound by the contractual obligations and other legal obligations to observe the confidentiality of your data and to respect your privacy, and will only have access to the data necessary to perform their functions; relevant providers are primarily IT providers (who host or support our IT systems, including information about you), insurance or security companies (who offer you specific services, such as accident insurance policies or live watching of events; as a result, they need to know about you in order to provide you with these services), and financial and accounting management service providers (who may need to process your details in order to comply with legal accounting processes). We can also contract providers that offer IT technology services and solutions, which may include the generation of customizable photo/video content, content that can be offered to you for free or for a fee, digital timing for competitive events (e.g., cycling), based on which the final rankings are generated, without which it would be impossible to reward the participants or implement certain Services provided by the Portal and/or the Website.

         • To our affiliates, the MPG Sports Club Association and the ProAm Events Foundation. They are located in Romania; our affiliates perform different functions, and as a result, your data may be shared with them for different reasons. 

         • Subject to legal provisions, we will share your data with the government, police, regulatory bodies or regulatory agencies if, at our discretion, we consider that we are legally required or authorized to do so, or that it would be prudent to do so; and

         • As part of prior verification of (or implementation of) a merger, acquisition or other business transaction, we may need to disclose your information to the potential seller or buyer and its consultants.

        `,
      subtitle1: 'Do we transfer your data to third parties?',
    },
    section9: {
      p1: 'Your personal information shall not be transferred outside the European Union. If applicable, we will only transfer your data outside the EU to countries that the European Commission deems to provide you with an adequate level of protection, or where MPG has implemented appropriate safeguards to seek to preserve the confidentiality of your information.  We will notify you of the countries to which we transfer your data, when and if we identify the need to transfer it.',
      subtitle1: 'Do we transfer your data outside the European Union?',
    },
    section10: {
      p1: ` • The right to access and obtain a copy of your personal data

      You have the right to request confirmation if we process any of your personal information. If applicable, you may have access to your personal information and certain information regarding how it is processed. In some cases, you may ask us to provide you with an electronic copy of your information.

       • The right to rectify your personal data

      If you can prove that the personal data we hold about you is not correct, you can request that this information be updated or corrected. We encourage you to access the self-service system offered by the Website/Portal, if possible, and to update your personal data directly.

       • The right to be forgotten / to delete data

      In certain cases, you have the right to ask us to restrict the processing of your personal data and/or to delete your personal data. You may submit such a request at any time, and MPG will assess whether your request will be positively solved, however, if this right is subject to any legal rights or obligations, we may retain the data. For cases where, in compliance with the legal provisions, we determine that the request to delete your personal data must be positively solved, MPG will do so without delay. Please note that once your data is deleted, MPG can no longer help you after this data has been deleted. If you want to register again and access the Services provided, you will need to re-enter your data.

       • The right to object

      If the processing of your data by MPG is based on the legitimate interest of MPG (and no other processing basis), or is linked to direct marketing, you have the right to object to the processing of your data by MPG, referring to your particular situation.

      If you wish to exercise any of your rights, please log in to the self-service Portal (which allows you to exercise your rights autonomously) or send us an email at corporate@sportya.net. 

      When you send us an email to exercise your rights, MPG may ask you to identify yourself before solving your request.

      Finally, you have the right to file a complaint with the Data Protection Authority located at your place of residence or where you work, or in the place where you believe that the problem has arisen in connection with your data.`,

      subtitle1: 'What are your rights? ',
    },
    section11: {
      p1: 'You are responsible for keeping the login details on the Website/Portal secure, in particular the password that we have given you or that you have chosen. This login identification data will only be used by you. You are not permitted to share your identification data or any other account details with any other person(s).',
      subtitle1: 'Data security when using MPG systems',
    },
    section12: {
      p1: 'Questions, comments and requests regarding this document can be directed to us at corporate@sportya.net.',
      subtitle1: 'How can you contact us?',
    },
    section13: {
      p1: 'The terms of this Statement may be amended from time to time. We will post any material changes to this Statement through appropriate notices, either on the Website/Portal or by contacting you using other communication channels.',
      subtitle1: 'How do we process the amendments to this Statement?',
    },
  },
  cookiePolicy: {
    mainTitle: 'COOKIE POLICY',
    mainDescription: `MPG undertakes to provide you the best online experience when you visit the Website.
      For this purpose, we use our own cookies and standard third-party cookies to obtain data about your behavior while visiting the Website, and we also track how often you visit the Website. The information we collect is anonymous and does not identify you personally, unless you provide us with your information by filling out a form or by another particularity provided on the Website.`,

    section1: {
      p1: `We do not use intrusive cookies on the Website to collect your personal information. We use industry-standard third-party cookies, such as Google Analytics, to track your online behavior and obtain aggregated statistical information in the following ways:
       • We track the number of visits on the Website, as well as from where did each visitor come from (i.e., the website visited before you arrived on the Website), and where does each visitor go after visiting the Website (i.e., the website you visit after leaving us).

       • A cookie is stored on your device for no more than one (1) year and is linked to your IP address. Cookies are small text files that your browser stores on your device. This allows us to track if you return to the Website.
      `,
      subtitle1: 'What is our purpose',
    },
    section2: {
      p1: `Unless you have set your browser settings to refuse cookies, our systems will issue cookies every time you access the Website.
      You can refuse to accept cookies by activating the setting in your browser that allows you to refuse the storage of cookies. This will delete all the details in the cookie. However, if you select this setting, you may not be able to access portions of the Website or use certain features.`,

      subtitle1: 'How to manage cookies',
    },
    section3: {
      p1: `While browsing on the Website, you may also notice content from third parties. We may also offer you the opportunity to make additional contact with us and share information with others by using social networks such as Facebook, LinkedIn, Instagram or Twitter. This content is added to keep you up-to-date with carefully selected information that you might consider relevant or of interest, but this may allow third party vendors to store additional cookies on your device, over which we have no control.
      Therefore, we suggest that you check the websites of these third parties and their cookie policies, for more information regarding their cookies and how you can manage them.`,

      subtitle1: 'Third-party cookies',
    },
    section4: {
      p1: `The Website contains links to third-party websites. If you follow a link to any of these third party Websites, please note that these websites have their own privacy and cookie policies and that we accept no responsibility or liability whatsoever for these policies. Please check these policies before submitting any personal data to other third-party websites.
        Below is a conclusive example of a cookie used by Sportya.net including details of its purpose and other information that you may find useful. Please note, however, that if you choose to access third-party sites through Sportya.net, in addition to the one mentioned below, other cookies may be added.

        Third-party cookie from Google Inc. // Purpose 

        Google Analytics sets cookies to allow us to track the use of pages and services on our sites.

        // What kind of data is kept?Duration of the visit, page views, place, operating system, browser, input path, visit number.

        // Link to personal data? No.
        
        // Type of cookie? Permanent.

        // Third-party cookies? Yes, Google Inc.

        // Third-party policy http://www.google.co.uk/intl/en/policies/privacy/ 
        `,
      subtitle1: 'Cookies used on Sportya.net',
    },
    section5: {
      p1: 'The terms of this Policy may change periodically. We will post any material changes to this Policy through appropriate notices, either on this website or by contacting you through other communication channels.',
      subtitle1: 'Policy changes',
    },
    section6: {
      p1: 'Questions, comments and requests regarding this policy may be directed to us, via corporate@sportya.net.',
      subtitle1: 'CONTACT',
    },
  },
  returnPolicy: {
    mainTitle: 'RETURN POLICY',
    mainDescription: `1. On the Sportya.net website you can pay the membership fee by purchasing one of the available accounts (ex: Premium Account), you can sign up for a tennis tournament by paying an online fee which represents the value of the digital services offered by the site in connection with the event (Sportya Services Fee), you can top up your Virtual Wallet with an amount that you can use later on Sportya.net and you can buy products during the online entry process for an event, as part of the participation kit that you will receive during the registration process, at the tennis club / sports base where the event takes place.
    2. If after the purchase on Sportya.net of one of the services / products mentioned in art. (1) you want to cancel your purchase and implicitly, to get a refund, you can apply for the standard return procedure of products within 14 days, according to the provisions of “Emergency Ordinance no. 34/2014” (hereinafter referred to as “Ordinance”).

    
    According to the Ordinance, a distance contract means the contract for the supply of goods or services concluded between a trader and a consumer, within a sales system organized by the trader, which uses exclusively, before and at the conclusion of this contract, one or more distance communication techniques.
    3. As a consumer who has purchased services and / or products online from the Sportya.net website, you have the right to unilaterally withdraw from the contract without invoking any reason, within 14 days from the date of entering into the contract, respectively making the purchase of services / products, by sending a written request, to the e-mail address corporate@sportya.net, under the following conditions:

    a. Member Account / Membership. In case of purchasing a membership account (ex: Premium Account), if during the 14 days:

    i. you have not used the online entry for an event and / or have not participated in any event in the Sportya calendar in which you benefit from the conditions offered by the Account (reduced fee and / or access to Platinum Tournaments, Champions Tournaments, Masters Tournaments, International Tournaments and Special Tournaments), the amount received as payment will be refunded in full and your Member Account will be changed to a Free Account. All benefits offered by the Account for which you requested the refund are withdrawn, including the right to participate in events conditioned by the existence of a specific active Account, even if the entries were made before requesting the cancelation of the Account, respectively the refund of the amount paid for the Account;

    ii. you have made a reservation for an event and / or participated in an event in the Sportya calendar where you have benefited from the conditions offered by the Account (reduced fee and / or access to Platinum Tournaments, Champions Tournaments, Masters Tournaments, International Tournaments and Special Tournaments), the amount paid is not refunded and your Member Account / Membership remains unchanged.

    b. Sportya Services Fee. In case of payment of the online fee which represents the value of the digital services offered by the site in connection with the event (Sportya Services Fee) during the online registration process for an event, the services are fully provided at the time of payment and the amount received as payment cannot be refunded. However, if you have used the online withdrawal feature for an event and the withdrawal is validated by the withdrawal system, the Sportya Services Fee is transferred to your personal Virtual Wallet and can be used to enter other Sportya events.

    
    c. Virtual Wallet Top Up. In case of topping up the Virtual Wallet, if during the 14 days after topping up:

    i. you have not used the amount from your Virtual Wallet, respectively you have not made an entry for a Sportya event, the amount received as payment is refunded in full;

    
    ii. you used the amount from your Virtual Wallet, partially or totally, respectively you made an entry for a Sportya event, the amount received as payment cannot be refunded.

    
    d. Specific products. If during the 14 days elapsed from the moment you purchased specific products offered during the online entry for an event, respectively as part of your participation kit:

    i. in case the entries for the event have not yet closed - if you use the online withdrawal function and the withdrawal is validated by the withdrawal system, the amount corresponding to the purchased products is withheld, in order to process the order, the procedure for preparing it for expedition being already initiated. However, the amount can be either transferred to the personal Virtual Wallet or fully refunded, if you make a formal request to withdraw from the contract;

    ii. in case the event entries have closed and the participation kits have been prepared for shipment to the organizer - the amount related to the payment of the products is refunded in full only after the products have been returned to Sportya and the return has been processed and validated. The costs associated with the return of the products by courier service are borne by you, as a buyer (fixed fee of 15 RON for standard deliveries). The return period of the purchased products expires in this case within 14 days from the moment you took possession of these products, delivered to the tennis club organizing the event / competition for which you registered. The following general conditions regarding the condition of the returned products apply:

    1. Returned products must be in the same condition in which they were shipped. The return will be made with all the original labels of the products intact, together with any accompanying documents with which the products were received, including, if applicable, the original guarantee certificate. If the product has been accompanied by accessories or gifts, they must also be returned, provided they have been shipped.

    2. Unsealed can of balls (the metal seal has been removed and the box has been depressurized) cannot be returned.

    3. If you have bought several products of the same kind and want to return them all, make sure that only one of them has been unsealed. All other products must be returned sealed for the return to be accepted.

    4. You can return the consumables (grips, bandanas / cuffs, etc.) only if they are sealed and have no traces of use, respectively the packaging is in the same condition in which it was shipped.

    5. Products that have been modified, those that show signs of wear / use, bumps, shocks, scratches, or products that lack accessories are not accepted. In the case of clothing or footwear products, including fashion accessories, they must be returned in the same condition in which they were delivered, not worn, without stains or signs of wear.

    6. The return of nutrition products (ex: sports drinks, energy bars, etc.), cosmetics or personal care products is not accepted if they have been unsealed or tested. If a package of several products has been purchased, all products must be returned.

    7. If the return is requested for a product accompanied by a service (ex: activation of the Premium Account following the purchase of a product), validation of the return implies the cancellation of the service and all rights or benefits resulting from the use of the service.

    8. The products for which the return is requested must be delivered to the courier exactly as communicated in the return request message, together with the Return Form completed and signed by you. If the returned package sent by you contains different products than those mentioned in the return request message, respectively those that appear indicated in the Return Form, validation for the return will not take place, respectively the return will be refused.

    
    e. In any case, the withdrawal from the contract requires that you send us a request by e-mail, at corporate@sportya.net, in which you express your decision to withdraw from the contract, respectively giving up the services and / or products purchased on Sportya.net. We will send you a confirmation of receipt by e-mail and, in case you apply for the return of physical products, a Return Form including the list of products indicated in your message. The Return Form must be completed by you with the missing information, signed and then sent to Sportya together with the returned products. The return will be checked based on this Form. The return will be refused in case the Return Form signed by you is missing from the returned package.

    
    f. The right of unilateral withdrawal from the contract does not apply in the case of legal persons.

    
    4. Due to the particularities of the Sportya system, the purchase of a Member Account / Membership (ex: Premium Account), the payment of a Sportya Services Fee and the top up of the Virtual Wallet on Sportya.net is a direct confirmation by the consumer that he agrees the delivery of services to begin in the 14 days of withdrawal from the contract and acknowledges that the right of withdrawal will be lost after the full execution of the contract, respectively the delivery of purchased services.

    
    5. Due to the particularities of the Sportya system, regarding the option offered to participants to add products to their participation kits, due to be received during the registration process, at the tennis club / sports base where the event takes place, the ordered products will be returned by the competition organizer to Sportya, if the player did not show up for the event, respectively did not take possession of the participation kit and implicitly of the purchased products. Subsequently, it remains the responsibility of the buyer / player to contact Sportya and establish another method of direct shipping of purchased products, and will bear both the courier cost of the return made by the organizer of the competition as well as the courier cost for the new delivery. If the buyer requests to cancel the products and withdraw from the contract, Sportya is entitled to deduct from the price of the products the return cost incurred by the competition organizer and only refund the difference.

    6. If the return has been accepted in accordance with all the conditions described in this document, the amount to which you are entitled will be refunded no later than 14 days from the date the return was validated (the products have been returned and the return has been processed), in accordance with the provisions of the law.

    The amount will be refunded using the same payment methods as those used for the initial purchase, unless you have agreed to another payment method and provided that you do not incur the payment of commissions for the reimbursement.

    For any details or questions regarding the return of the products, please contact us by email at corporate@sportya.net or by phone, using one of the numbers displayed on Sportya.net.`,

  },
  gameFormatInfo: {
    mainTitle: 'HOW TO PLAY IN GROUPS OF 3',
    mainDescription: `One of the most sought after gameplay formats is the <1>Group-of-3 System</1>, with two matches in classic format, scheduled for each player. The format provides a richer experience for participants, and the technical implementation standard, adapted to Sportya events, is described below:

      <1>Group rankings / Separation in a Group</1>
      • In the event of a tie between two players, to determine the ranking of a group, the tiebreaker is made according to the result of the direct match.
      • In the event of a tie between three or more players, the tiebreaker is made taking into account, in order: set average, game average (difference between lost and won sets / games), the highest number of sets / games won.
      • In case of a perfect tie, the order in the group will be determined by drawing lots.

      <3>Group qualification</3>
      Within the Sportya events, 2 qualification alternatives from a Group-of-3 can be applied:
      • <5>Only the first place qualifies.</5> 
      • <7>The first two ranked players qualify.</7>

      In the situation where the format of an event includes the rule of qualifying one or more of the 2nd places from different groups, the distinction between the 2nd places is made according to: the victory-defeat ratio, the set score, the games score, number of sets won and number of games won. In case of a perfect tie, the 2nd place in the group of the best seeded player will qualify.

      If there are only two players in a group (according to the number of entries or following a withdrawal from both matches), in order to make the comparison between the 2nd place in a 2-player group and a 3-player group, the results of the matches with the last ranked player are eliminated and the same criteria are considered (practically, only the results between the 2nd place against the 1st place in each group will count).

      <9>Withdrawals</9> 
      If a player shows up for a match and no longer plays the second one (for whatever reason), the score in the second match will be 6/0 6/0 for the group ranking.
      In the case of the super tiebreak, it will be considered a set won 1-0 (one game to zero).

      <11>Adjustment of the participation fee / Partial return as a result of withdrawals from the group</11>
      Within Sportya events, the following situations may arise as a result of withdrawals from a group:
      • <13>The play of a single match in a group, caused by a withdrawal</13>, will mean a decrease in the tournament fee to the value of a classic knockout tournament fee, unless both players in the group qualify, in which case the fee charged will not be adjusted. The refund will be requested as follows: from the organizer, for the Club Entry Fee, and by email (contact@sportya.net) to Sportya, for the Sportya Service Fee paid online. After entering the results, the money will be credited to the Sportya Virtual Wallet. 
      • <15>In the event of two withdrawals from a group</15>, the third player is promoted directly to the next stage and the participation fee is adjusted to the level of a knockout tournament fee. 

      <17>Technical characteristics for the upper phase / Knockout draw. Scoring</17> 
      The knockout draw that follows the play in the groups will have predetermined positions or a draw will be announced for some of them. The scoring grids remain the usual ones, defined in the Sportya Rulebook, the points being awarded according to the number of players on the main draw, from the moment of the draw.
      `,
  },
  //  •

};
