export default {
  seoTitle: 'Najít událost | Sportya',
  leaderboardSeoTitle: 'Žebříčky hráčů | Sportya',
  leaderboards: 'Hodnocení',
  results: 'Výsledky',
  newEventsNearYou: 'Vybrané události',
  seeAll: 'Zobrazit vše',
  seeEvent: 'Zobrazit událost',
  seeMyEvents: 'Zobrazit mé události',
  goToEvent: 'Přejít na událost ⟶',
  goToEvent2: 'Přejít na událost',
  newEvent: 'Nová událost',
  undisputed: 'Nesporný',
  pickLocation: 'Místo',
  recommendedEvents: 'Vybrané události',
  announcedWithdrawal: 'Ohlášené odstoupení',
  penalty: 'Sankce',
  pickCategory: 'Kategorie',
  pickYear: 'Rok',
  pickMonth: 'Měsíc',
  all: 'Vše',
  filter_all: 'Filtry {{amount}}',
  clearAll: 'Vymazat vše',
  wildcard: 'Zástupný znak',
  filters: 'Filtry',
  prizes: 'Ceny',
  gameType: 'Typ hry: {{ gameType }}',
  gameTypeText: 'Typ hry',
  eventLevel: 'Úroveň události',
  eventStatus: 'Stav události',
  age: 'Věk',
  playMode: 'Herní režim',
  singles: 'Dvouhry',
  doubles: 'Čtyřhry',
  Singles: 'Dvouhry',
  Doubles: 'Čtyřhry',
  viewLocation: 'Zobrazit místo',
  eventCategory: 'Kategorie: ',
  pendingPlayer: 'Nesporný',
  eventPeriod: 'Období události: ',
  surfaceType: 'Typ povrchu: ',
  noOfCourts: 'Počet kurtů: ',
  allActive: 'Aktivní události',
  availableEntries: 'Volná místa',
  organizerName: 'Pořadatel: ',
  organizerPhone: 'Telefon: ',
  organizerEmail: 'E-mail: ',
  tournamentDirector: 'Ředitel turnaje: ',
  refereeName: 'Jméno rozhodčího: ',
  supervisor: 'Představitel Sportya: ',
  followEvent: 'Sledovat',
  unfollowEvent: 'Přestat sledovat',
  viewAllUpdates: 'Zobrazit všechny aktualizace ({{ total }})',
  viewLessUpdates: 'Zobrazit méně aktualizací',
  officialUpdates: 'Oficiální aktualizace',
  viewCompetition: 'Přejít na soutěž',
  competitions: 'Soutěže',
  priceNotAvailable: 'Cena není k dispozici',
  topUpNow: 'Nabít nyní',
  allWinners: 'Všichni vítězové',
  joinNowLink: '<0>Připojit se</0>',
  level: 'Úroveň',
  champion: 'Šampion',
  champions: 'Mistři',
  firstServe: 'První podání',
  league: 'Liga',
  finalist: 'Finalista',
  finalists: 'Finalisté',
  readMore: 'Přečtěte si více',
  officialSponsors: 'Oficiální sponzoři',
  waitingList: 'Pořadník',
  mainList: 'Hlavní listina',
  acceptanceList: 'Seznam pro přijetí',
  preRegistrationList: 'Rozdělení do seznamu',
  sortingList: 'Rozdělení do seznamu',
  sortList: 'Rozdělení do seznamu',
  avgLevel: 'Průměrná úroveň',
  avgRank: 'Průměrné hodnocení',
  avgPoints: 'Průměrné body',
  matchStart: 'Odhadovaný začátek utkání: <1>ne dříve než {{ date }}</1>',
  restrictions: 'Omezení',
  restrictionsDoubleLevel: 'Max. úroveň hráče čtyřhry. {{ level }}',
  restrictionsTeamLevel: 'Max. úroveň týmu - {{ level }}',
  confirmPayment: 'Potvrďte svou platbu',
  restrictionsGender: 'Pohlaví {{ gender }}',
  summerAndPremium: 'Pouze prémiové účty',
  gameLevel: 'Úroveň <1>{{ level }}</1>',
  playerGameLevel: 'Úroveň hry',
  playerRank: 'Hodnocení',
  closed: 'Uzavřeno',
  tablePlayers: 'Losovat:<1>{{ players }} Hráči</1>',
  tableGroups: 'Losovat:<1>{{ players }} Týmy</1>',
  eliminatory: 'Vyřazení',
  groups: 'Skupiny',
  group: 'Skupina {{type}}',
  eventFound: '<0>{{ totalResults }} událost</0> nalezeno v <2>{{ location }}</2>',
  eventFound_tennis: '<0>{{ totalResults }} událost</0> nalezeno v <2>{{ location }}</2>',
  eventFound_padel: '<0>{{ totalResults }} událost</0> nalezeno v <2>{{ location }}</2>',
  eventsFound: '<0>{{ totalResults }} události</0> nalezeno v <2>{{ location }}</2>',
  eventsFound_tennis: '<0>{{ totalResults }} události</0> nalezeno v <2>{{ location }}</2>',
  eventsFound_padel: '<0>{{ totalResults }} události</0> nalezeno v <2>{{ location }}</2>',
  eventFoundNc: '<0>{{ totalResults }} událost</0> nalezeno',
  eventFoundNc_tennis: '<0>{{ totalResults }} událost</0> nalezeno',
  eventFoundNc_padel: '<0>{{ totalResults }} událost</0> nalezeno',
  eventsFoundNc: '<0>{{ totalResults }} události</0> nalezeno',
  eventsFoundNc_tennis: '<0>{{ totalResults }} události</0> nalezeno',
  eventsFoundNc_padel: '<0>{{ totalResults }} události</0> nalezeno',
  in: 'v',
  noResults: 'Nebyly nalezeny žádné události.',
  joinAndPay: 'Připojit se a zaplatit',
  ballToBeAnnounced: 'Brzy bude oznámeno',
  proximityRadius: 'Rádius',
  qualifying: 'Kvalifikace',
  phaseStatuses: {
    waitingList: 'Pořadník',
    mainList: 'Hlavní listina',
    sortingList: 'Rozdělení do seznamu',
    undefined: 'Není k dispozici',
    upcomingCompetitions: {
      upcoming: 'NADCHÁZEJÍCÍ',
      playing: 'HRAJÍCÍ',
    },
    openingSoon: 'Brzy se otevírá',
    openingSoonStartDate: 'Registrace na {{ listType }} se otevře dne {{ date }}',
    openingSoonEndDate: '',

    preRegistrationsOpen: 'Před otevřením',
    preRegistrationsOpenStartDate: 'Registrace do seznamu rozdělení budou uzavřeny dne {{ date }}',
    preRegistrationsOpenEndDate: 'Rozdělení proběhne ve dnech {{ date }}',

    sortPending: 'Čeká se na seřazení',
    sortPendingStartDate: 'Registrace do hlavního seznamu se otevře dne {{ date }}',
    sortPendingEndDate: '',

    registrationsOpen: 'Otevřít',
    registrationsOpenStartDate: '',
    registrationsOpenEndDate: 'Registrace do hlavního seznamu budou uzavřeny dne {{ date }}',

    drawPending: 'Čeká se na losování',
    drawPendingStartDate: '',
    drawPendingEndDate: 'Registrace do pořadníku jsou stále otevřené do dne {{ date }}',

    readyToPlay: 'Připraveno ke hře',
    readyToPlayStartDate: '',
    readyToPlayEndDate: 'Registrace do pořadníku jsou stále otevřené do dne {{ date }}',

    playing: 'Hrající',
    playingStartDate: '',
    playingEndDate: 'Registrace do pořadníku jsou stále otevřené do dne {{ date }}',

    resultsPending: 'Čeká se na výsledky',
    resultsPendingStartDate: '',
    resultsPendingEndDate: '',

    ended: 'Ukončeno',
    endedStartDate: '',
    endedEndDate: '',
  },
  registrationsOpenMainList: '<0>Hlavní listina</0>: {{ available }} dostupné volné místo — Pospěšte si!',
  registrationsOpenMainList_plural: '<0>Hlavní listina</0>: {{ available }} Dostupná volná místa',
  registrationsOpenMainListFull: '<0>Hlavní listina</0>: Zaplněna',
  registrationsOpenMainListFull_plural: '<0>Hlavní listina</0>: Zaplněna',
  registrationClosedMainList: '<0>Hlavní listina</0>: Uzavřeno',
  registrationClosedMainList_plural: '<0>Hlavní listina</0>: Uzavřeno',
  registrationsOpenWaitingList: '<0>Pořadník</0>: Otevřeno ({{ occupied }} Volné místo obsazeno)',
  registrationsOpenWaitingList_plural: '<0>Pořadník</0>: Otevřeno ({{ occupied }} Volná místa obsazena)',
  registrationsOpenWaitingListAll: '<0>Pořadník</0>: Otevřeno ({{ occupied }} Volná místa obsazena)',
  registrationClosedWaitingListAll: '<0>Pořadník</0>: Uzavřeno',
  registrationClosedWaitingList: '<0>Pořadník</0>: Uzavřeno',
  preRegistrationsOpenSortList: '<0>Rozdělení do seznamu</0>',
  preRegistrationClosedSortList: '<0>Rozdělení do seznamu</0>: Uzavřeno',

  slotsAvailable_space: ' Dostupná volná místa',
  slotAvailable_space: ' Dostupné volné místo',

  slotsAvailable: ' Dostupná volná místa',
  slotAvailable: ' Dostupné volné místo',
  availableSlot: 'Dostupné volné místo',
  availableSlots: 'Dostupná volná místa',
  slotsOccupied: '{{occupiedSlots}} Obsazená místa',
  slotOccupied: '{{occupiedSlots}} Obsazené místo',

  joinCompetition: {
    seoTitle: 'Zapojit se do soutěže | Sportya',
  },

  eventDetails: {
    seoTitle: '{{eventName}} - Události | Sportya',
  },

  competitionDetails: {
    seoTitle: 'Úroveň {{competitionLevel}}, {{gameType}}, {{eventName}} - Události | Sportya',
    title: 'Technická příručka a nejčastější dotazy pro místní ligy',
    text: 'Zaregistrujte se <1>FOR FREE</1> do místní ligy Sportya a hrajte tenis <1>WHEN YOU WANT, WHERE YOU WANT</1> se soupeři na stejné úrovni. Místní liga navrhuje (jako hrací formát) hru ve skupině s několika hráči ve formátu round robin (každý soupeří se všemi ostatními členy skupiny), která se koná v průběhu několika týdnů.',
  },

  openingSoon: 'Brzy se otevírá',
  preRegistrationsOpen: 'Otevřena předběžná registrace',
  sortPending: 'Čeká se na seřazení',
  registrationsOpen: 'Otevřít',
  drawPending: 'Čeká se na losování',
  readyToPlay: 'Připraveno ke hře',
  playing: 'Hrající',
  resultsPending: 'Čeká se na výsledky',
  ended: 'Ukončeno',
  endedStartDate: '',
  endedEndDate: '',

  gender: 'Pohlaví',
  male: 'Muž',
  female: 'Žena',
  mixt: 'Smíšené',
  weekdays: 'Pracovní dny',
  weekend: 'Víkendy',
  resetToDefault: 'Obnovit výchozí',
  apply: 'Použít',
  officialBall: 'Oficiální míč',
  playerPackageTitle: 'Balíček pro hráče',
  playerPackage: `Standardní účastnický balíček obsahuje:
  - dva nové míče pro každé utkání ve dvouhře (po skončení utkání budou vráceny pořadateli)
  - láhev vody pro každého hráče.`,
  winners: {
    title: 'Hlavní losování',
    subtitle: 'Vítězové',
    downloadPdf: 'Stáhnout PDF',
    quarterfinals: 'Čtvrtfinále',
    semifinals: 'Semifinále',
    final: 'Finále',
  },
  join: {
    matchStart: 'Odhadovaný začátek utkání',
    package: {
      title: 'Balíček pro hráče (zdarma)',
      subtitle: 'Standardní účastnický balíček obsahuje:',
      1: 'dva nové míče pro každé utkání ve dvouhře',
      2: 'láhev vody pro každého hráče',
      return: 'Po skončení utkání budou míče vráceny pořadateli. Balíček pro hráče (zdarma) a všechny další produkty zakoupené v obchodě s událostmi Event Shop budou doručeny a lze je vyzvednout na recepci klubu, který akci pořádá.',
    },
    sprinten: {
      title: 'Proveďte změnu!',
      1: 'Zapojte se do iniciativy a podpořte stipendia dětské fyzioterapie, která pomáhají desítkám dětí s postižením užívat si pohybového cvičení a mít šanci na normální život.',
      2: 'Program realizovaný nadací ProAm Events Foundation prostřednictvím projektu SPRINTEN.',
      donate: 'Zaslat příspěvek!',
    },
    duoJoin: 'Přidat svého partnera ze čtyřhry',
    searchPartner: 'Vyhledat partnera a připojit se k této události',
    playerAdded: 'Hráč byl přidán do vašeho týmu!',
    changePlayer: 'Změnit hráče',
    payment: {
      title: 'Způsob platby',
      selectMethod: 'Vybrat způsob platby',
      virtualWallet: 'Virtuální peněženka',
      creditCard: 'Kreditní karta',
      availableBalance: 'Dostupný zůstatek',
      insufficientFunds: 'Nedostatečné finanční prostředky',
      negativeFunds: 'Nedostatečné finanční prostředky',
      topUpFirst: 'Nejprve proveďte <0>Dobití virtuální peněženky</0>, abyste mohli zaplatit touto platební metodou nebo použijte kreditní / debetní kartu.',
      confirmPay: 'Potvrdit a zaplatit',
      redirect: 'Budete přesměrováni na',
    },
  },
  amountToBePaid: 'Částka k úhradě',
  remainingBalance: 'Zbývající zůstatek',
  pleaseConfirmPaymentForEvent: 'Potvrďte prosím konečnou platbu prostřednictvím virtuální peněženky za tuto vybranou událost.',
  summerPremiumAccountPrice: '<0>Prémiový účet: {{ cena }} {{ měna }}</0>',
  clubEntryFee: 'Vstupní poplatek klubu: <1><2>{{ primaryClubFee }}</2></1>',
  clubEntryFee2: 'Vstupní poplatek klubu:',
  getPremium: 'Přejděte na Prémiový účet',
  withdraw: 'Odstoupit',
  joined: 'Přihlásil',
  summary: 'Shrnutí',
  bookingFee: 'Servisní poplatky Sportya',
  partnerBookingFee: 'Servisní poplatky partnera Sportya',
  bookingFeeDiscounted: 'Servisní poplatky Sportya (se slevou)',
  partnerBookingFeeDiscounted: 'Servisní poplatky partnera Sportya (se slevou)',
  premiumDiscount: 'Sleva na Premium',
  premiumDiscountIncluded: '(vč. -{{ amount }} {{ currency }}  Prémiová sleva)',
  secondGameDiscountIncluded: '(včetně slevy -{{ amount }} {{ currency }} na 2. soutěž)',
  getPremiumDiscount: 'Přejděte na Prémiový účet a plaťte pouze <1>{{ amount }} {{ currency }}</1>',
  learnMore: 'Zjistit víc',
  participationFee: 'Vstupní poplatek klubu',
  participationFeeInfo: 'Platí se přímo pořadateli, v místě, na akci. Pořádající klub: <1>{{ clubName }}</1>.',
  yourTShirtSize: 'Velikost vašeho trička',
  sprintenDonation: 'Finanční příspěvek SPRINTEN',
  sprintenDonationProduct: 'Finanční příspěvek projektu SPRINTEN: {{ částka }} {{ měna }}',
  tShirtProduct: 'Tričko Premium Sportya Play On {{ gender }} {{ size }}',
  shoppingBasket: 'Nákupní košík',
  couponDiscount: 'Kupón <1>Odebrat kupón</1>',
  totalToBePaid: 'Celkem k úhradě',
  inclVAT: 'včetně DPH',
  addCoupon: 'Přidat kupón',
  joinLevelMissing: 'Před zapojením do soutěže si nastavte úroveň hry!',

  payRule: `Registrací na akci potvrzuji, že jsem si přečetl(a), porozuměl(a) a souhlasím se zněním podmínek <1>Sportya - Pravidla a předpisy</1>. Potvrzuji, že jsem v dobrém fyzickém stavu a přebírám plnou odpovědnost za svou účast. Souhlasím, aby mé telefonní číslo bylo předáno pořadateli této události a/nebo v případě místních lig hráčům skupiny, do které patřím.

  Potvrzuji také, že jsem si přečetl(a) <3>Zásady vracení </3>, porozuměl(a) jim a souhlasím s nimi. Souhlasím s tím, že poskytování služeb bude zahájeno během 14denní lhůty pro odstoupení od smlouvy. Beru na vědomí, že mé právo na odstoupení od smlouvy po úplném provedení zakoupených služeb zaniká.`,

  learnMoreDiscount: 'Získat slevu <1>Learn More</1>',
  discountModalTitle: 'Sleva na registraci do 2 soutěží',
  discountModalDescription: 'Hráči s účty <1>FREE</1> mají nárok na slevu <3>15 RON / 6 BGN</3> při registraci do 2 soutěží, které se konají v rámci stejného turnaje Stříbro nebo Speciální turnaj z Rumunska, respektive <5>25 RON / 10 BGN</5> při registraci do 2 soutěží v rámci turnaje Zlato z Rumunska nebo jakékoli akce z Bulharska.\n\n Sleva bude uplatněna automaticky v okamžiku registrace. Podmínkou je, že registrace do první soutěže, bez ohledu na to, zda se jedná o dvouhru, čtyřhru nebo tým, bude v systému ověřena (provedení platby) před provedením rezervace ve druhé soutěži.',
  change: 'Změnit',
  players: 'Hráči',
  cancel: 'Storno',
  withdrawText1: 'Opravdu chcete z této události odstoupit? \n Odstoupení z turnajů může být penalizováno body nebo omezením práva na registraci do turnajů podle období, v němž se odehrává, a podle jejich počtu. Odstoupení z pořadníku čekatelů není penalizováno.',
  withdrawText2: 'Další informace o odstoupení od smlouvy a souvisejících důsledcích jsou k dispozici v dokumentu <1> Obecná nařízení </1> (Kapitola 5). Částka související s online objednávkou bude vrácena v souladu se zněním sekce <3> Zásady vracení.</3>',
  filtersList: {
    champions: 'Mistři',
    firstServe: 'První podání',
    silver: 'Stříbro',
    gold: 'Zlato',
    special: 'Speciální',
    platinum: 'Platina',
    league: 'Místní liga',
  },
  sortby: 'Seřadit podle',
  startDate: 'Datum zahájení',
  newest: 'Nejnovější',
  showEndedEvents: 'Ukončené události',
  joinedEvents: 'Pouze mé události',
  reservedSlot: 'Rezervované volné místo /',
  underMaintenance: 'Probíhá údržba této události. Registrace jsou momentálně pozastaveny.',
  underMaintenanceButton: 'Probíhá údržba',
  competitionsUnavailable: 'Soutěže pro tuto akci budou brzy vyhlášeny.',
  min1event: 'Minimálně jedna odehraná událost',
  min3event: 'Minimálně 3 odehrané události',
  joinNowButton: {
    ended: 'Soutěž ukončena',
    playing: 'Hrající',
    resultsPending: 'Čeká se na výsledky',
    drawPending: 'Čeká se na losování',
    readyToPlay: 'Připraveno ke hře',
  },
  withdrawal: {
    early: {
      title: 'PŘEDČASNÉ odstoupení',
      subtitle: 'Opravdu chcete z této soutěže odstoupit?',
      desc: 'Pokud odstoupení od smlouvy potvrdíte, přijdete o své místo na seznamu účastníků soutěže, a poplatek za služby Sportya bude vrácen do virtuální peněženky hráče, který registraci odeslal.',
    },
    announced: {
      title: 'OHLÁŠENÉ odstoupení z {{list}}',
      subtitle: 'Opravdu chcete z této soutěže odstoupit?',
      desc: 'Pokud odstoupení od smlouvy potvrdíte, přijdete o své místo na seznamu účastníků soutěže, a poplatek za služby Sportya bude vrácen do virtuální peněženky hráče, který registraci odeslal.',
    },
    late: {
      title: 'OPOŽDĚNÉ odstoupení od {{list}}',
      subtitle: 'Opravdu chcete z této soutěže odstoupit?',
      desc: 'Pokud odstoupení od smlouvy potvrdíte, přijdete o své místo na seznamu účastníků soutěže, a poplatek za služby Sportya již NEBUDE vrácen.',
    },
    denied: {
      title: 'Odstoupení online již nejsou povoleny',
      subtitle: '',
      desc: 'V tuto chvíli již nejsou odstoupení povoleny. Ze soutěže však můžete stále odstoupit, pokud kontaktujete ředitele turnaje. To mu umožňuje zmírnit důsledky vašeho odstoupení. Mějte prosím na paměti, že poplatek za služby Sportya NEBUDE vrácen.',
    },
    disclaimer: 'Další informace týkající se odstoupení a náhrad naleznete v sekci <1>\'Pravidla a předpisy Sportya</1> a <3>Zásady vracení</3>.',
  },
  frozenLeaderboard: 'Oficiální pořadí na žebříčcích se bude přepočítávat každý týden počínaje 28. prosincem 2021. Děkujeme za vaši trpělivost.',
  sortListOpen: 'Registrace do pořadníku čekatelů otevřeny',
  pending: 'Čeká se na registraci',
  stages: {
    1: 'Finále',
    2: 'Semifinále',
    3: 'Čtvrtfinále',
    4: 'Čtvrtfinále',
  },
  nationalCalendar: 'Zvolte si svůj národní kalendář',
  quickFind: 'Rychle vyhledejte tu nejlepší událost pro vás',
  specificMonth: 'Nastavit vlastní data',
  showFeaturedEvents: 'Zobrazit vybrané události',
  hideFeaturedEvents: 'Skrýt vybrané události',
  newEvents: 'Nové události',
  teamGameLevel: 'Úroveň týmu',
  joinNow: 'Připojit se',
  restrictionsLevel: 'Max. úroveň {{ level }}',
  noEventsFound: 'Žádná událost nebyla nalezena.',
  onlymale: 'Pouze muži',
  onlyfemale: 'Pouze ženy',
  onlymixt: 'Smíšené',
  allAges: 'Všechny věkové skupiny',
  customAge: 'Vybrat interval',
  shirtSize: 'Velikost trička',
  lfp: {
    noPartner: 'Ještě nemáte parťáka do čtyřhry?',
    whosInterested: 'Podívejte se, kdo má zájem',
    findAPartner: 'Najděte partnera pro tým čtyřhry',
    eligible: `Než se přidáte na soupisku nebo kontaktujete hráče, ujistěte se, že splňujete kritéria způsobilosti pro turnaj u spoluhráče, kterého chcete kontaktovat.
    
    Pokud se přidáte na seznam nebo oslovíte potenciálního partnera ve čtyřhře, nejste povinni soutěžit. <1>Zjistit více</1>`,
    registrationNotRequired: 'Vaše přidání na seznam hráčů, kteří hledají partnera pro tým čtyřhry, nezakládá žádnou povinnost zúčastnit se této soutěže.',
    findMore: 'Zjistit více',
    imLooking: 'Hledám partnera',
    optOut: 'vzdávám se',
    contact: 'Zpráva',
    playersLFP: '{{ total }} hráči hledají partnery',
    join: 'Připojte se k seznamu!',
    beFirst: 'Ukažte ostatním hráčům, kteří hledají partnery pro čtyřhru pro tento turnaj, že vás zajímá.',
    eligibilityCriteria: `Než začnete hledat potenciálního partnera, zkontrolujte si kritéria způsobilosti pro tuto soutěž.
    
    Vaše přidání na seznam hráčů, kteří hledají partnera pro tým čtyřhry, nezakládá žádnou povinnost zúčastnit se této soutěže. <1>Zjistit více</1>`,
    iAmLFP: 'Hledám partnera',
  },
  fmBanner: {
    desc: `Zatímco čekáte na oznámení nových událostí ve vaší zemi, můžete si zahrát přátelský zápas s přáteli nebo jinými hráči na Sportya`,
  },
};
