import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

const AlertStyle = styled.div`
position: relative;
background: ${props => ({ theme }) => (props.warn
  ? theme.colors?.warn
  : (props.info ? theme.colors?.darkTurquoise : theme.colors?.success)
)};
border-radius: ${({ theme }) => theme.functions?.toEm(10)};
padding: ${({ theme }) => theme.functions?.toEm(20)};
display: block;

> .dismiss-btn {
  cursor: pointer;
  font-weight: 700;
  display: block;
  text-decoration: none;   
}`;

const Alert = ({ children, dismissible, ...props }) => {
  const [dismissAlert, setDismissAlert] = useState(false);

  const handleDismiss = async () => {
    if (dismissAlert) return;
    setDismissAlert(true);
  };

  return !dismissAlert && (
    <AlertStyle {...props}>
      {children}
      {(dismissible === true || dismissible === undefined) && (
        <div className="dismiss-btn float-right" onClick={handleDismiss} alt="Dismiss Alert">
          <Trans ns="common" i18nKey="dismiss">Dismiss</Trans>
        </div>
      )}
    </AlertStyle>
  );
};

export default Alert;
