/** @jsxImportSource @emotion/react */
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccount } from './actions';

import {
  H2, Button, P, H4,
} from '../../../components/Collection';

import FileUpload from '../../../components/Form/FileUpload';
import { store as socketStore } from '../../../components/Socket/store';
import { Container, DropzoneContainer, FormContainerPicture } from './styles';
import { updateAccountInfoState } from '../../../components/Layout/reducers/session';
import Logout from '../../../components/Logout';
import LoadImage from '../../../components/common/LoadImage';
import mixpanel from '../../../mixpanel';

const SetProfilePicture = () => {
  const { editCurrentPhoto } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion } = useSelector(state => state.session);
  const dropzoneRef = useRef();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const [profilePicture, setProfilePicture] = useState(accountInfo?.profilePicture);
  const [removed, setRemoved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);

  const formSchema = Yup.object().shape({
    profilePicture: Yup.string().required(commonT('required')),
  });

  const nameInitials = `${(accountInfo.firstName.charAt(0) + accountInfo.lastName.charAt(0)).toUpperCase()}`;

  const getNextStep = () => {
    let list = [];
    profileCompletion?.forEach(step => {
      if (!step.completed) {
        list = [...list, step.path];
      }
    });

    const index = list.findIndex(path => path === '/my-account/profile-picture') + 1;

    return list.length && list[index]
      ? list[index] : '/dashboard?congrats';
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        await dispatch(updateAccountInfoState({
          ...accountInfo,
          ...data,
        }));

        if (editCurrentPhoto) {
          history.push('/my-account');
        } else {
          history.push(getNextStep());
        }
      } else {
        setIsSubmitting(false);
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'accounts-response',
      );
      socket.on(
        'accounts-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'accounts-response',
        );
      };
    }
  }, [socketClientId]);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const removeFile = async () => {
    if (editCurrentPhoto) {
      await updateAccount({
        id: accountInfo.id,
        payload: {
          profilePicture: null,
        },
        addToast,
        socketClientId,
        dispatch,
        history,
        t: commonT,
      });
      setRemoved(true);
    }
    setProfilePicture(null);
  };

  const logProfilePicture = (completed) => {
    mixpanel.track('Upload profile photo', {
      sp_uploaded_profile_photo: completed ? 'completed' : 'skipped',
    });
    mixpanel.identify(accountInfo.mixpanelId);
  };

  return (
    <Container picture>
      <Logout />
      <H2>
        <Trans ns="accounts" i18nKey="completeProfile.profilePicture">Profile Picture</Trans>
      </H2>
      <P textAlign="center">
        {t('completeProfile.profilePictureSubtitle', { siteName: process.env.REACT_APP_SITE_NAME })}
      </P>
      <FormContainerPicture hasPicture={profilePicture}>
        <Formik
          enableReinitialize
          initialValues={{
            profilePicture,
          }}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            setIsSubmitting(true);

            logProfilePicture(true);

            const payload = values;
            await updateAccount({
              id: accountInfo.id,
              payload,
              addToast,
              socketClientId,
              dispatch,
              history,
              t: commonT,
            });
          }}
        >
          {({
            isValid,
          }) => (
            <Form className="w100">
              {profilePicture && !editCurrentPhoto
                && (
                  <div>
                    <P margin="47px 0 10px 0" xSmall textAlign="center">
                      {t('completeProfile.looksGreat')}
                      ,
                      {' '}
                    </P>
                    <H4 textAlign="center">
                      {accountInfo.firstName}
                      {' '}
                      {accountInfo.lastName}
                    </H4>
                  </div>
                )}

              <DropzoneContainer>
                <FileUpload
                  dropzoneRef={dropzoneRef}
                  accept=".png, .jpeg, .jpg"
                  accountInfo={accountInfo}
                  onChange={async filename => {
                    setProfilePicture(filename);
                    setError(null);
                  }}
                  thumbnail
                  className="profile-picture"
                  nameInitials={nameInitials}
                  photo={profilePicture ?? accountInfo?.profilePicture}
                  setPhoto={setProfilePicture}
                  bucketName="accounts"
                  setter={setIsLoading}
                  bucketId={accountInfo?.userId}
                  onDropRejected={(fileRejections) => setError(fileRejections[0].errors[0].message)}
                />
                {!isLoading && profilePicture && !isLoading && (
                  <img className="uploaded" src={LoadImage('onboarding/uploaded.svg')} alt="Succesfully Uploaded" />)}
              </DropzoneContainer>
              <Field
                name="profilePicture"
                type="hidden"
                value={profilePicture}
              />

              {profilePicture
                && (
                  <div className="mb30">
                    <P xSmall bold textAlign="center" margin="0 0 17px 0" className="action" onClick={openDialog}>
                      <Trans ns="accounts" i18nKey="completeProfile.uploadNewPhoto">Upload new photo</Trans>
                    </P>
                    <P xSmall bold textAlign="center" margin="0 0 32px 0" className="action" onClick={removeFile}>
                      <Trans ns="accounts" i18nKey="completeProfile.removePhoto">Remove photo</Trans>
                    </P>
                  </div>
                )}

              {error && (
                <P xSmall textAlign="center" margin="0 0 15px 0" color="red">
                  {error}
                </P>
              )}

              {removed || profilePicture !== accountInfo?.profilePicture
                ? (
                  <Button
                    black
                    width="100%"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {commonT('continue')}
                  </Button>
                )
                : ''}

              {(!profilePicture || (profilePicture === accountInfo?.profilePicture)) && (
                <>
                  <P xSmall textAlign="center" margin="0 0 15px 0">
                    <Trans ns="accounts" i18nKey="completeProfile.pictureMaxSize">
                      The profile picture must be less than 1MB.
                    </Trans>
                  </P>
                  <Button
                    NoBorder
                    margin="0 0 20px 0"
                    width="100%"
                    type="button"
                    onClick={openDialog}
                  >
                    <img src={LoadImage('onboarding/camera.svg')} alt="Upload" />
                    {t('completeProfile.uploadYourPhoto')}
                  </Button>

                  {editCurrentPhoto
                    ? (
                      <Button
                        outline
                        width="100%"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        {t('logout.btnCancel')}
                      </Button>
                    )
                    : (
                      <Button
                        outline
                        width="100%"
                        type="button"
                        onClick={() => { history.push(getNextStep(true)); logProfilePicture(false); }}
                      >
                        {t('completeProfile.skipForNow')}
                      </Button>
                    )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </FormContainerPicture>
    </Container>
  );
};

export default SetProfilePicture;
