export default {
  chats: 'Beszélgetések',
  startChat: 'Kezdj beszélgetést',
  findPlayersAndStartConversation: 'Keressen játékosokat, és kezdjen beszélgetést',
  seeFriendsList: 'Lásd a barátok listáját',
  disconnected: 'Szétkapcsolt. Újracsatlakozási kísérlet...',
  isTyping: '{{ user }} gépelés...',
  online: 'Online',
  offline: 'Offline',
  viewProfile: 'Profil megtekintése',
  startGroupChat: 'Indítson csoportos csevegést {{ user }}',
  muteConversation: 'Értesítések némítása',
  unmuteConversation: 'Értesítések engedélyezése',
  leaveConversation: 'Hagyd a beszélgetést',
  deleteConversation: 'Beszélgetés törlése',
  allMessagesLoaded: 'Minden üzenet betöltve.',
  messageDeleted: 'Ezt az üzenetet törölték.',
  system: 'Rendszer',
  deleteMessage: 'Üzenet törlése',
  typeMessage: 'Írjon be egy üzenetet...',
  groupChat: 'Csoportos csevegés',
  groupMembers: 'Résztvevő',
  noOfParticipants: '{{ participants }} résztvevő',
  groupName: 'Ön, {{ groupNamesList }}',
  addParticipants: 'Résztvevők hozzáadása',
  seeGroupMembers: 'A résztvevők megtekintése',
  muteGroup: 'Értesítések némítása',
  unmuteGroup: 'Értesítések engedélyezése',
  leaveGroup: 'Hagyd a beszélgetést',
  deleteGroup: 'Beszélgetés törlése',
  cancel: 'Visszavonás',
  createGroup: 'Teremt',
  yourFriends: 'A barataid',
  add: 'Hozzáadás',
  you: 'Ön',
  leave: 'Kilépsz',
  delete: 'Töröl',
  playerBlocked: 'You’ve blocked this player',
  left: 'Left',
  openOrStartChat: 'Nyissa meg az egyik meglévő beszélgetést, <br/> vagy indítson újat.',
  leaveConfirmationGroup: 'Biztosan kilép ebből a <br/> csoportos csevegésből?',
  deleteConfirmationGroup: 'Biztosan törli ezt a csoportos csevegést?',
  deleteConfirmationConversation: 'Biztosan törli ezt a beszélgetést?',
  USER_HAS_LEFT_CONVERSATION: '<i><strong>{{ user }}</strong> kilépett a beszélgetésből.</i>',
  PLAYER_ADDED_TO_CONVERSATION: '<i><strong>{{ user }}</strong> <strong>{{ addedBy }}</strong> tette hozzá a beszélgetéshez.</i>',
  SUCCESSFULLY_INITIATED_CHAT: 'A beszélgetést sikeresen elindították.',
  COULD_NOT_INITIATE_CHAT: 'A beszélgetést nem sikerült elindítani. Próbáld újra később.',
  SUCCESSFULLY_MUTED_CONVERSATION: 'A beszélgetés értesítései ki vannak kapcsolva.',
  SUCCESSFULLY_UNMUTED_CONVERSATION: 'A beszélgetés értesítéseit újra engedélyeztük.',
  SUCCESSFULLY_LEFT_CONVERSATION: 'Sikeresen kilépett a beszélgetésből.',
  SUCCESSFULLY_DELETED_CONVERSATION: 'A beszélgetést törölték.',
  INTERNAL_ERROR: 'Belső szolgáltatási hiba. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.',
  tryAgain: 'Próbáld újra',
  failedToLoadConversations: 'Nem sikerült betölteni a beszélgetéseket',
  leftGroupChat: 'Többé nem küldhet üzeneteket ebben a csoportos beszélgetésben, mert már nem vesz részt benne.',
  unreadMessages: '{{ messages }} olvasatlan üzenet',
};
