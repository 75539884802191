import styled from '@emotion/styled';
import ProfileVisibilityBackground from '../../../assets/images/bg-set-profile-visibility.png';
import ProfileAvailabilityBackground from '../../../assets/images/bg-set-availability.png';
import FavoriteSportBackground from '../../../assets/images/bg-set-favorite-sport.png';
import LoadImage from '../../../components/common/LoadImage';

export const Container = styled.div(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  paddingBottom: '200px',
  '@media (min-width: 800px)': {
    paddingTop: '110px',
    background:
      props.details ? `#fff url('${LoadImage('onboarding/bg-set-profile-details.png')}') no-repeat center`
        : props.level ? `#fff url('${LoadImage('onboarding/bg-set-game-level.png')}') no-repeat center`
          : props.visibility ? `#fff url('${LoadImage('onboarding/bg-set-profile-visibility.png')}') no-repeat center`
            : props.picture ? `#fff url('${LoadImage('onboarding/bg-set-profile-picture.png')}') no-repeat center`
              : props.availability
                ? `#fff url('${LoadImage('onboarding/bg-set-profile-availability.png')}') no-repeat center` : '#fff',
    backgroundSize: 'contain',
  },
  ...props.level && {
    '@media (max-width: 950px)': {
      padding: '40px 20px 120px 20px',
      '.parent': {
        flexDirection: 'column',
      },
      '.first': {
        order: 2,
        margin: 'unset',
        h2: {
          textAlign: 'center',
        },
      },
      '.second': {
        order: 1,
      },
    },
  },
}));

export const FormContainerDetails = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '350px',
  width: '100%',
  '.form-group': {
    width: '100%',
  },
  '.birthdate > div:nth-of-type(2)': {
    margin: '0 10px',
  },
  '.custom-radio': {
    display: 'flex',
    justifyContent: 'space-around',
    margin: `0 -5px ${props.theme.functions?.toEm(40)} -5px`,
    label: {
      display: 'flex',
      width: '170px',
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 12px 8px 13px',
      borderRadius: '4px',
      position: 'relative',
      transition: 'all .3s ease 0s',
      boxShadow: `inset 0 0 0 2px ${props.theme.colors?.black}`,
      color: props.theme.colors?.black,
      margin: '0 5px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 700,
      img: {
        visibility: 'hidden',
        position: 'absolute',
      },
    },
  },
  '[type="radio"]': {
    position: 'absolute',
    opacity: 0,
    width: 0,
    cursor: 'pointer',
  },
  'input[type="radio"]:checked + label': {
    background: props.theme.colors.primary,
    color: '#fff',
    img: {
      visibility: 'visible',
      position: 'relative',
      marginRight: '10px',
    },
  },
}));

export const SetVisibilityWrapper = styled.div`
  .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
  }

  .custom-radio {
    fieldset {
      padding: 0;
      border: none;
      position: relative;
    }

    [type="radio"] {
      position: absolute;
      pointer-events: all;
      top: 25px;
      left: 20px;
    }

    label {
      width: 100%;
      position: relative;
      display: block;
      padding: ${({ theme }) => theme.functions?.toEm(20)};
      cursor: pointer;

      margin-bottom: 10px;
      border: 1px solid #EAF6F6;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(234, 246, 246, 0.58);
      border-radius: 4px;     
      position: relative;  
      
      span {
        font-weight: bold;
        font-size: ${({ theme }) => theme.functions?.toEm(18)};
        color: ${({ theme }) => theme.colors?.black};
        margin-left: 30px;
        display: block;
      }
      
      .description {
        margin-top: 20px;
        visibility: hidden;
        position: absolute;
        
        p {
          line-height: ${({ theme }) => theme.functions?.toEm(18)};
        }
        
        .subtitle {
          font-weight: bold;
          font-size: 12px;
          color: ${({ theme }) => theme.colors?.turquoise};
        }
      }
    }
  
    input[type="radio"]:checked + label {
        border: 2px solid #10242C;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(234, 246, 246, 0.58);
        border-radius: 4px;
        
        .description {
          visibility: visible;
          position: relative;
        }
    }
  }

  ${props => props.theme.functions?.minTablet(`
    background: url(${ProfileVisibilityBackground}) center center;
    background-repeat: no-repeat;
    background-size: 80vw;
  `)}  
`;

export const SetFavoriteSportWrapper = styled.div`
  .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
  }
    
  .field-item {
    border-radius: 4px;
    margin-bottom: 10px;
    border: ${({ theme }) => `1px solid ${theme?.colors?.disabledBlue}`};

    &.checked {
        border: 2px solid ${({ theme }) => theme?.colors?.primary};
    }

    label {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;

        span {
            font-weight: bold;
            font-size: ${({ theme }) => theme.functions?.toEm(18)};
            color: ${({ theme }) => theme.colors?.black};
            display: flex;
            gap: 15px;
            align-items: center;
        }
    }
  }

  ${props => props.theme.functions?.minTablet(`
    background: url(${FavoriteSportBackground}) center center;
    background-repeat: no-repeat;
    background-size: 80vw;
  `)}  
`;

export const List = styled.ul({});

export const Item = styled.li({
  marginTop: '30px',
});

export const FormContainerLevel = styled.div(props => ({
  position: 'relative',
  background: props.theme.colors?.primary,
  boxShadow: '4px 4px 0px rgba(124, 201, 198, 0.2)',
  borderRadius: '4px',
  padding: props.theme.functions?.toEm(40),
  width: '350px',
  height: '100%',
  marginBottom: '20px',

  '.disabled': {
    filter: 'invert(28%) sepia(5%) saturate(1766%) hue-rotate(151deg) brightness(28%) contrast(90%)',
    cursor: 'not-allowed',
  },

  'p,h2': {
    color: '#fff !important',
  },

  ...props.levelSet && {
    background: '#EAF6F6',
    boxShadow: 'unset',
    height: 'auto',
    'p,h2,a': {
      color: `${props.theme.colors.primary} !important`,
    },
  },

  '.watch-video': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 10px',

    width: '100%',
    height: '40px',

    border: `1px solid ${props.levelSet ? props.theme.colors.primary : '#fff'}`,
    borderRadius: '4px',

    textDecoration: 'none',
    color: '#fff',

    margin: '20px 0 0 0',

    '> svg': {
      marginRight: '10px',
    },
  },

  '.selected-level': {
    color: props.theme.colors?.primary,
    fontWeight: 700,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-5px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '50px 0 0 200px',
    borderColor: '#fff0 #fff0 #fff0 #fff',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '-5px',
    bottom: '-5px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 50px 200px',
    borderColor: '#fff0 #fff0 #fff #fff0',
  },
}));

export const FormContainerPicture = styled.div(props => ({
  display: 'flex',
  maxWidth: '348px',
  width: '100%',
  margin: '0 auto',
  '.profile-picture': {
    margin: '0 auto',
  },
  '.picture-thumbnail': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: props.hasPicture ? '22px auto 36px' : '90px auto 90px',
    width: '130px',
    height: '130px',
    border: '2px solid #EAF6F6',
    borderRadius: '50%',
    cursor: 'pointer',
    overflow: 'hidden',
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
  },
  '.action': {
    color: '#78ADAB',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export const HiddenUpload = styled.input({
  display: 'none',
});

export const CropBackground = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 10,
});

export const CropContainer = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: 'max-content',
  maxWidth: '620px',
  transform: 'translate(-50%, -50%)',
  borderRadius: '20px',
  zIndex: 11,
  '> div': {
    minHeight: '160px',
    minWidth: '160px',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  img: {
    width: '100%',
  },
});

export const UserID = styled.span({
  fontWeight: 400,
  color: 'rgb(120, 173, 171)',
  margin: '0 10px',
});

export const DropzoneContainer = styled.div({
  position: 'relative',
  '.uploaded': {
    position: 'absolute',
    bottom: '-9px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export const ProfileAvailabilityWrapper = styled.div`
  .container {
    width: 100%;
    max-width: 350px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.functions?.toEm(15)};
    ${props => props.theme.functions?.mediaQueryMax('1024px', `
    position: relative;
    padding-bottom: 100px;
    `)}
  }

  .react-datepicker-popper {
    z-index: 1;
  }
  
  .react-datepicker__header--time--only {
    display: none;
  }

  .react-datepicker__time-list {
    list-style: none;
    background: #fff;
    width: 113px;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 5px 10px;
    max-height: 300px;
    overflow: auto;
    li {
      cursor: pointer;
      margin: 10px 0;
      &:first-of-type {
        margin: 0 0 10px 0;
      }
      &:last-of-type {
        margin: 10px 0 0 0;
      }
    }
  }

  .choose-day {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .day {
      min-width: ${({ theme }) => theme.functions?.toEm(80)};
      margin-right: ${({ theme }) => theme.functions?.toEm(20)};
      user-select: none;
  
      [type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 0;
        cursor: pointer;
      }
      
      img {
        position: absolute;
        visibility: hidden;
      }

      label {
        display: block;
        border-radius: ${({ theme }) => theme.functions?.toEm(4)};
        padding: ${({ theme }) => theme.functions?.toEm(10)};
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors?.black};
        cursor: pointer;
        text-align: center;
        background: ${props => ({ theme }) => (props.black ? theme.colors?.black : theme.colors?.white)};
        font-weight: bold;
      }
    
      input[type="checkbox"]:checked + label {
          background-color: ${({ theme }) => theme.colors?.black};
          color: ${({ theme }) => theme.colors?.white};
  
          img {
            visibility: visible;
            position: relative;
            margin-right: 10px;
          }
      }
      
      ${props => props.theme.functions?.minDesktopSmall(`
        margin-right: ${props.theme.functions?.toEm(35)};
      `)}
    }
    
    .interval {
      width: 100%;
      display: flex;
      position: relative;
      
      p {
        width: 50%;
      }
      
      > div {
        width: inherit;

        &:nth-of-type(1) {
          margin-right: 10px;
        }
        
        input {
          display: block;
          border-radius: ${({ theme }) => theme.functions?.toEm(4)};
          padding: ${({ theme }) => theme.functions?.toEm(10)};
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors?.black};
          cursor: pointer;
          text-align: left;
          background: ${props => ({ theme }) => (props.black ? theme.colors?.black : theme.colors?.white)};
          font-weight: bold;
          width: 100%;
        }
      }
    }
  }
  
  ${props => props.theme.functions?.minDesktopSmall(`
    background: url(${ProfileAvailabilityBackground}) center center;
    background-repeat: no-repeat;
    background-size: 80vw;
  `)}  
`;
