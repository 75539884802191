import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';
import {
  AddScoreInput,
  AddScoreModalTeam,
  AddScoreTeamContainer,
  AddTieInput,
  ScoreInputTitle,
  ScoresContainer,
  TieInputTitle,
  TrophyIcon,
  VS,
} from '../../../../../../components/Modals/AddScore/styles';

import { PlayerAvatarContainer } from '../../../../../../StartMatch/components/WantToPlay/styles';
import Checkbox from '../../../../../../../../components/Form/VanillaCheckbox';
import LoadImage from '../../../../../../../../components/common/LoadImage';
import { Contain, P } from '../../../../../../../../components/Collection';
import { PlayerName } from '../../../../../../Matches/styles';

import {
  DoublesPlayerName,
  PlayerScoreContainer,
  PlayerScoreHeader,
  SetTitlesContainer,
} from './styles';

import { AvatarAbsoluteContainer, AvatarRelativeContainer, PlayerAvatar } from '../../styles';
import { validateScore, handleScore, getWinner } from '../../../../../../helpers';
import deleteFromScoreForm from '../../../../../../helpers/deleteFromScoreForm';

const PlayerScores = ({
  handleCheckboxes,
  setScoreForm,
  scoreForm,
  player,
  opponent,
  playerPartner,
  opponentPartner,
  isDoubles,
  winner,
  winnerError,
  alreadyHasScore,
  setWinnerError,
  declareWinner,
  setScoreError,
  scoreError,
}) => {
  const { t } = useTranslation('friendlyMatches');
  const { accountInfo } = useSelector(state => state.session);
  const {
    userId, profilePicture, firstName, lastName,
  } = accountInfo || {};

  const [isModalDisabled, disableModal] = useState(true);

  const winnerIsPlayer = winner === 'player';
  const winnerIsOpponent = winner === 'opponent';

  const hasPlayerScoreValues = !!(scoreForm?.player?.score).filter(obj => obj.points !== null).length;
  const hasOpponentScoreValues = !!(scoreForm?.opponent?.score).filter(obj => obj.points !== null).length;

  const scoreFormHasPoints = hasPlayerScoreValues || hasOpponentScoreValues;
  const bothPlayersHavePoints = hasPlayerScoreValues && hasOpponentScoreValues;

  const getSet = (number, type) => scoreForm?.[type]?.score.find(item => item?.set === number);

  const [isSecondSetDisabled, setIsSecondSetDisabled] = useState(true);
  const [isThirdSetDisabled, setIsThirdSetDisabled] = useState(true);

  useEffect(() => {
    setIsSecondSetDisabled(
      !getSet(1, 'player')
      || !getSet(1, 'opponent'),
    );

    setIsThirdSetDisabled(
      !getSet(2, 'player')
      || !getSet(2, 'opponent'),
    );

    if (bothPlayersHavePoints) {
      declareWinner(getWinner(scoreForm));
    } else {
      declareWinner(null);
    }

    if (scoreFormHasPoints) {
      let isScoreError = false;
      ['player', 'opponent'].every(team => {
        scoreForm[team].score.every(set => {
          if (scoreForm[team]?.type) {
            return true;
          }

          if (set.points !== null && (set.points < 0 || set.points > 24)) {
            isScoreError = true;
            return false;
          }

          if (set.tie !== null && (set.tie < 0 || set.tie > 24)) {
            isScoreError = true;
            return false;
          }
          return true;
        });
        if (isScoreError) {
          return false;
        }
        return true;
      });

      setWinnerError((getWinner(scoreForm) === 'error'));
      setScoreError(isScoreError);
      disableModal(isScoreError);
    } else {
      setWinnerError(false);
      setScoreError(scoreError || false);
      disableModal(true);
    }
  }, [scoreForm]);

  const nameInitials = {
    get player() {
      const first = (player || areYouThePlayer ? accountInfo : {})?.firstName?.charAt(0)
        ? firstName?.charAt(0).toUpperCase() : '';
      const last = (player || areYouThePlayer ? accountInfo : {})?.lastName?.charAt(0)
        ? lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get opponent() {
      const first = (opponent || areYouTheOpponent ? accountInfo : {})?.firstName?.charAt(0)
        ? opponent?.firstName?.charAt(0).toUpperCase() : '';
      const last = (opponent || areYouTheOpponent ? accountInfo : {})?.lastName?.charAt(0)
        ? opponent?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get playerPartner() {
      const first = (playerPartner || areYouThePlayerPartner ? accountInfo : {})?.firstName?.charAt(0)
        ? playerPartner?.firstName?.charAt(0).toUpperCase() : '';
      const last = (playerPartner || areYouThePlayerPartner ? accountInfo : {})?.lastName?.charAt(0)
        ? playerPartner?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
    get opponentPartner() {
      const first = (opponentPartner || areYouTheOpponentPartner ? accountInfo : {})?.firstName?.charAt(0)
        ? opponentPartner?.firstName?.charAt(0).toUpperCase() : '';
      const last = (opponentPartner || areYouTheOpponentPartner ? accountInfo : {})?.lastName?.charAt(0)
        ? opponentPartner?.lastName?.charAt(0).toUpperCase() : '';
      return `${first} ${last}`;
    },
  };

  const opponentProfilePicture = LoadImage(
    opponent?.profilePicture
      ? `accounts/${opponent?.userId}/${opponent?.profilePicture}`
      : 'friendly-matches/match-form/avatar-placeholder.svg',
    opponent?.profilePicture,
  );

  const opponentTranslation = { ns: 'friendlyMatches', i18nKey: isDoubles ? 'opponentTeam' : 'scoresData.opponent' };

  const areYouTheOpponent = userId === opponent?.userId;
  const areYouTheOpponentPartner = userId === opponentPartner?.userId;
  const areYouThePlayerPartner = userId === playerPartner?.userId;
  const areYouThePlayer = !areYouTheOpponent && !areYouTheOpponentPartner && !areYouThePlayerPartner;

  const firstScoreColumnTabOrder = {
    0: 1,
    1: 3,
    2: 5,
  };

  const secondScoreColumnTabOrder = {
    0: 2,
    1: 4,
    2: 6,
  };

  return (
    <PlayerScoreContainer>
      <PlayerScoreHeader>
        <AddScoreModalTeam marginOnMobile>
          <AvatarRelativeContainer>
            <AvatarAbsoluteContainer isDoubles={isDoubles && playerPartner}>
              <PlayerAvatarContainer disableMargin>
                <PlayerAvatar
                  isDoubles={isDoubles && playerPartner}
                  winner={winnerIsPlayer}
                  profilePicture={profilePicture}
                  hasInitials={areYouThePlayer ? !profilePicture : !player?.profilePicture}
                  bg={profilePicture ? LoadImage(areYouThePlayer
                    ? `accounts/${userId}/${profilePicture}`
                    : `accounts/${player?.userId}/${player?.profilePicture}`, true) : null}
                >
                  {!profilePicture && (<P bold>{nameInitials?.player}</P>)}
                </PlayerAvatar>
                {!isDoubles && (
                  <PlayerName small noWidth>
                    {winnerIsPlayer && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                    {areYouThePlayer ? (
                      <Trans ns="conversations" i18nKey="you">
                        You
                      </Trans>
                    ) : (player?.firstName)}
                  </PlayerName>
                )}
              </PlayerAvatarContainer>
            </AvatarAbsoluteContainer>

            {isDoubles && playerPartner && (
              <AvatarAbsoluteContainer isPartner isDoubles={isDoubles && playerPartner}>
                <PlayerAvatarContainer disableMargin isPartner>
                  <PlayerAvatar
                    isPartner
                    isDoubles={isDoubles}
                    winner={winnerIsPlayer}
                    profilePicture={playerPartner?.profilePicture}
                    hasInitials={playerPartner && !playerPartner?.profilePicture}
                    bg={playerPartner?.profilePicture
                      ? LoadImage(`accounts/${playerPartner?.userId}/${playerPartner?.profilePicture}`, true)
                      : null}
                  >
                    {!playerPartner?.profilePicture && (<P bold>{nameInitials?.playerPartner}</P>)}
                  </PlayerAvatar>
                </PlayerAvatarContainer>
              </AvatarAbsoluteContainer>
            )}

            {isDoubles && (
              <DoublesPlayerName>
                <PlayerName
                  small
                  maxWidth={playerPartner}
                  winner={winnerIsPlayer}
                  noWidth
                  disalbeAdditionalMargins
                  padding="unset"
                >
                  {winnerIsPlayer && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                  {areYouThePlayer ? (
                    <Trans ns="conversations" i18nKey="you">
                      You
                    </Trans>
                  ) : (playerPartner?.firstName)}
                  {playerPartner && (<>&nbsp; &amp;</>)}
                </PlayerName>
                {playerPartner && (
                  <PlayerName small maxWidth winner={winnerIsPlayer}>
                    {areYouThePlayerPartner ? (
                      <Trans ns="conversations" i18nKey="you">
                        You
                      </Trans>
                    ) : playerPartner?.firstName || (
                      <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                        Player 2
                      </Trans>
                    )}
                  </PlayerName>
                )}
              </DoublesPlayerName>
            )}
          </AvatarRelativeContainer>
        </AddScoreModalTeam>
        <VS>VS</VS>
        <AddScoreModalTeam>
          <AvatarRelativeContainer>
            <AvatarAbsoluteContainer isDoubles={isDoubles && opponentPartner}>
              <PlayerAvatarContainer disableMargin>
                <PlayerAvatar
                  isDoubles={isDoubles}
                  winner={winnerIsOpponent}
                  hasInitials={opponent && !opponent?.profilePicture}
                  bg={opponentProfilePicture}
                >
                  {!opponent?.profilePicture && (<P bold>{nameInitials?.opponent}</P>)}
                </PlayerAvatar>
                {!isDoubles && (
                  <PlayerName small noWidth>
                    {winnerIsOpponent && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                    {areYouTheOpponent ? (
                      <Trans ns="conversations" i18nKey="you">
                        You
                      </Trans>
                    ) : opponent?.firstName
                    || (
                      <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                        Player 2
                      </Trans>
                    )}
                  </PlayerName>
                )}
              </PlayerAvatarContainer>
            </AvatarAbsoluteContainer>

            {isDoubles && opponentPartner && (
              <AvatarAbsoluteContainer isPartner isDoubles={isDoubles && opponentPartner}>
                <PlayerAvatarContainer disableMargin isPartner>
                  <PlayerAvatar
                    isPartner
                    isDoubles={isDoubles}
                    winner={winnerIsOpponent}
                    hasInitials={opponentPartner && !opponentPartner?.profilePicture}
                    profilePicture={opponentPartner?.profilePicture}
                    bg={opponentPartner?.profilePicture
                      ? LoadImage(`accounts/${opponentPartner?.userId}/${opponentPartner?.profilePicture}`, true)
                      : null}
                  >
                    {!opponentPartner?.profilePicture && (<P bold>{nameInitials?.opponentPartner}</P>)}
                  </PlayerAvatar>
                </PlayerAvatarContainer>
              </AvatarAbsoluteContainer>
            )}

            {isDoubles && (
              <DoublesPlayerName>
                {opponent && (
                  <PlayerName
                    small
                    maxWidth={opponentPartner}
                    winner={winnerIsOpponent}
                    noWidth
                    disalbeAdditionalMargins
                  >
                    {winnerIsOpponent && (<TrophyIcon src={LoadImage('friendly-matches/winner.svg')} alt="Winner" />)}
                    {areYouTheOpponent ? (
                      <Trans ns="conversations" i18nKey="you">
                        You
                      </Trans>
                    ) : opponent?.firstName}
                    {opponentPartner && (<>&nbsp; &amp;</>)}
                  </PlayerName>
                )}
                {opponentPartner && (
                  <PlayerName small maxWidth winner={winnerIsOpponent} noWidth>
                    {areYouTheOpponentPartner ? (
                      <Trans ns="friendlyMatches" i18nKey="you">
                        You
                      </Trans>
                    ) : opponentPartner?.firstName || (
                      <Trans ns={opponentTranslation.ns} i18nKey={opponentTranslation.i18nKey}>
                        Player 2
                      </Trans>
                    )}
                  </PlayerName>
                )}
              </DoublesPlayerName>
            )}
          </AvatarRelativeContainer>
        </AddScoreModalTeam>
      </PlayerScoreHeader>

      <Contain direction="column" align="center">
        <ScoresContainer>
          <AddScoreTeamContainer marginOnMobile>
            <Contain>
              <ScoreInputTitle>
                <Trans ns="friendlyMatches" i18nKey="score">
                  Score
                </Trans>
              </ScoreInputTitle>
              <TieInputTitle>
                <Trans ns="friendlyMatches" i18nKey="tie">
                  Tie
                </Trans>
              </TieInputTitle>
            </Contain>
            {[...Array(3)]?.map((_, key) => (
              <AddScoreModalTeam row key={key}>
                <AddScoreInput
                  tabIndex={firstScoreColumnTabOrder[key]}
                  disabled={alreadyHasScore}
                  defaultValue={scoreForm.player?.score?.[key]?.points}
                  onKeyDown={(e) => (
                    e.key === 'Backspace'
                    && e.target.value < 10 && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), true))
                  )}
                  onInput={(e) => validateScore(e)}
                  onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, true, false))}
                  {...key === 1 && (isSecondSetDisabled) && { disabled: true }}
                  {...key === 2 && (isThirdSetDisabled) && { disabled: true }}
                />

                <AddTieInput
                  disabled={alreadyHasScore}
                  defaultValue={scoreForm.player?.score?.[key]?.tie}
                  onKeyDown={(e) => (
                    e.key === 'Backspace'
                    && e.target.value < 10 && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), true, true))
                  )}
                  onInput={(e) => validateScore(e)}
                  onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, true, true))}
                  {...key === 1 && (isSecondSetDisabled) && { disabled: true }}
                  {...key === 2 && (isThirdSetDisabled) && { disabled: true }}
                />
              </AddScoreModalTeam>
            ))}
            <Contain width="100%" margin="5px 0 0 0">
              <Checkbox
                onChange={() => handleCheckboxes(true)}
                checked={!!scoreForm.player?.type}
                label={t('abandoned')}
                isDisabled={alreadyHasScore}
                disabled={alreadyHasScore}
              />
            </Contain>
          </AddScoreTeamContainer>

          <SetTitlesContainer>
            {[...Array(3)]?.map((_, key) => (
              <AddScoreModalTeam middle key={key}>
                Set
                {' '}
                {key + 1}
              </AddScoreModalTeam>
            ))}
          </SetTitlesContainer>

          <AddScoreTeamContainer>
            <Contain width="100%">
              <ScoreInputTitle>
                <Trans ns="friendlyMatches" i18nKey="score">
                  Score
                </Trans>
              </ScoreInputTitle>
              <TieInputTitle>
                <Trans ns="friendlyMatches" i18nKey="tie">
                  Tie
                </Trans>
              </TieInputTitle>
            </Contain>
            {[...Array(3)]?.map((_, key) => (
              <AddScoreModalTeam row key={key}>
                <AddScoreInput
                  tabIndex={secondScoreColumnTabOrder[key]}
                  disabled={alreadyHasScore}
                  defaultValue={scoreForm.opponent?.score?.[key]?.points}
                  onKeyDown={(e) => (
                    e.key === 'Backspace' && e.target.value < 10
                    && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), false))
                  )}
                  onInput={(e) => validateScore(e)}
                  onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, false, false))}
                  {...key === 1 && (isSecondSetDisabled) && { disabled: true }}
                  {...key === 2 && (isThirdSetDisabled) && { disabled: true }}
                />

                <AddTieInput
                  disabled={alreadyHasScore}
                  defaultValue={scoreForm.opponent?.score?.[key]?.tie}
                  onKeyDown={(e) => (
                    e.key === 'Backspace' && e.target.value < 10
                    && setScoreForm(deleteFromScoreForm(scoreForm, (key + 1), false, true)))}
                  onInput={(e) => validateScore(e)}
                  onChange={(e) => setScoreForm(handleScore(scoreForm, (key + 1), e.target.value, false, true))}
                  {...key === 1 && (isSecondSetDisabled) && { disabled: true }}
                  {...key === 2 && (isThirdSetDisabled) && { disabled: true }}
                />
              </AddScoreModalTeam>
            ))}
            <Contain width="100%" margin="5px 0 0 0">
              <Checkbox
                onChange={() => handleCheckboxes(false)}
                checked={!!scoreForm.opponent?.type}
                label={t('abandoned')}
                isDisabled={alreadyHasScore}
                disabled={alreadyHasScore}
              />
            </Contain>
          </AddScoreTeamContainer>
        </ScoresContainer>
      </Contain>

      {isModalDisabled && scoreError && (
        <P xSmall color="error" textAlign="center">
          <Trans ns="friendlyMatches" i18nKey="scoreValidationMaxValue">Accepted values: 0-24</Trans>
        </P>
      )}

      {winnerError && (
        <P xSmall color="error" textAlign="center">
          <Trans ns="friendlyMatches" i18nKey="scoreValidationInvalidWinner">
            Please double-check your score and fill in the correct and
            complete score so that a winner/winning team can be determined.
          </Trans>
        </P>
      )}
    </PlayerScoreContainer>
  );
};

export default PlayerScores;
