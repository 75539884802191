import styled from '@emotion/styled';

export const Row = styled.div(props => ({
  display: 'flex',
  maxWidth: '1200px',
  margin: '80px auto',
  justifyContent: 'space-around',
  border: '2px solid #F2F2F2',
  borderRadius: '10px',
  padding: props.theme.functions?.toEm(40),
  '@media (max-width: 1220px)': {
    margin: '80px 20px',
  },
  '@media (max-width: 950px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 767px)': {
    padding: props.theme.functions?.toEm(15),
  },
}));

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 950px)': {
    width: 'max-content',
    margin: '40px auto',
    h2: {
      borderBottom: '1px solid #0000001a',
    },
  },
});
