import React from 'react';
import { Trans } from 'react-i18next';
import LoadImage from '../../../../../components/common/LoadImage';
import { Paragraph } from '../../../../../components';
import buttonsActions from '../../helpers/buttonsActions';

const MatchWarnings = (
  {
    match,
    myPosition,
    timeUntilScoreValidation,
    displayMessage = false,
  },
) => {
  const { playerPartner, opponent, opponentPartner } = match || {};
  const playersArray = [playerPartner?.invitation, opponent.invitation, opponentPartner?.invitation];
  const playerDeclined = !!playersArray.filter(status => status === 'declined').length && myPosition === 'player';
  const playerWithdrawn = !!playersArray.filter(status => status === 'withdrawn').length && myPosition === 'player';
  const playerNoMatch = !!playersArray.filter(status => status === 'noMatch').length && myPosition === 'player';
  const inactiveScoreUpdate = (
    match.gameType === 'doubles'
    && (
      !!playersArray.filter(status => status === 'declined').length
      || !!playersArray.filter(status => status === 'withdrawn').length
      || !!playersArray.filter(status => status === 'noMatch').length
    )
  );

  const buttonsToShow = buttonsActions({ match, myPosition });

  const dueTime = timeUntilScoreValidation.replace(/^\D+/g, '');
  return (
    <>
      {buttonsToShow?.confirmScore && !buttonsToShow?.status && timeUntilScoreValidation && (
        <div className={`info-icons ${displayMessage && 'info-icons__is-visible'}`}>
          <img src={LoadImage('friendly-matches/pending-icon.svg')} alt="" />
          <div className="popup popup__on-hold">
            <Paragraph smaller>
              <Trans ns="friendlyMatches" i18nKey="pendingScorePopup">
                Score Pending Confirmation - Please confirm the score or submit any corrections in
                {' '}
                {{ dueTime }}
              </Trans>
            </Paragraph>
          </div>
        </div>
      )}
      {(playerDeclined || playerWithdrawn || playerNoMatch || inactiveScoreUpdate) && !buttonsToShow?.status && (
        <div className={`info-icons ${displayMessage && 'info-icons__is-visible'}`}>
          <img src={LoadImage('friendly-matches/alert-icon.svg')} alt="" />
          <div className="popup popup__warning">
            <Paragraph smaller>
              {playerDeclined && (
                <Trans ns="friendlyMatches" i18nKey="invitationDeclined">
                  Invitation Declined - Please review match details and take any necessary actions
                </Trans>
              )}

              {playerWithdrawn && (
                <Trans ns="friendlyMatches" i18nKey="playerWithdrawn">
                  Player Withdrawal - Please review match details and take any necessary actions
                </Trans>
              )}

              {playerNoMatch && (
                <Trans ns="friendlyMatches" i18nKey="noMatchReported">
                  Player reported No-Match - Please review match details and take any necessary actions
                </Trans>
              )}

              {inactiveScoreUpdate && !(playerDeclined || playerWithdrawn || playerNoMatch) && (
                <Trans ns="friendlyMatches" i18nKey="inactiveScoreUpdate">
                  Score cannot be added/confirmed/changed at the moment due to player withdrawals/unconfirmed players.
                </Trans>
              )}
            </Paragraph>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchWarnings;
