/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */
import React from 'react';

import { css, useTheme } from '@emotion/react';

const LevelBadge = ({ children, ...props }) => {
  const theme = useTheme();
  const { functions } = theme;

  return (
    <div
      css={css`
        width: ${props.small ? '20px' : '50px'};
        position: relative;
        margin-top: 5px;
        margin: ${props.margin ? props.marign : '5px 15px 15px 15px'};
        ${props.centered ? 'margin:  auto;' : ''}

        strong {
          background: ${props.background ? props.background : props.isDouble ? theme.colors?.white : theme.colors?.turquoise};
          border: 2px solid ${props.border ? props.border : theme.colors?.turquoise};
          ${props.small ? 'border-bottom-width: 5px;' : ''};
          color: ${props.color ? props.color : props.isDouble ? theme.colors?.turquoise : theme.colors?.white};
          border-radius: 4px;
          text-align: center;
          font-size: ${ props.small ? functions?.toEm(12) : functions?.toEm(32)};
          padding: ${props.small ? '1px 2px 1px' : '2px 5px 10px'};
          z-index: 1;
          position: relative;
          width: 100%;
          display: block;
          clip-path: ${props.small ? 'polygon(0 0, 100% 0, 100% calc(100% - 5px), 50% 101%, 0 calc(100% - 5px))' : 'polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% 101%, 0 calc(100% - 12px))'};
        }
      `}
      className={props.className}
    >
      <strong>{children}</strong>
    </div>
  );
};

export default LevelBadge;
