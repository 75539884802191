import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import mixpanel from '../../../mixpanel';
import { TabContent, PageContainer } from '../styles';
import StartMatchBanner from './components/Banner';
import InvitePlayersInYourArea from './components/InvitePlayers';
import SearchForAPlayer from './components/SearchForPlayer';
import TopPicks from './components/TopPicks';
import WantToPlay from './components/WantToPlay';
import Header from '../components/Header';
import RaiseHand from '../../../components/Layout/components/Header/components/RaiseHand';
import PartnerClubs from './components/PartnerClubs';
import Modal from '../../../components/Modal';
import { Button, Link } from '../../../components/Collection';
import { Paragraph } from '../../../components';

const StartMatch = ({ name, masterSport }) => {
  const { accountInfo } = useSelector(state => state.session);
  const [hasGameLevel, setHasGameLevel] = useState(true);

  useEffect(() => {
    mixpanel.track('Visited Start Match tab');
  }, []);

  return (
    <PageContainer>
      <Header page={name} masterSport={masterSport} />
      <TabContent>
        <RaiseHand
          mobileOnly
          isFriendlyMatches
          handRaisedData={accountInfo?.handRaised}
        />
        <StartMatchBanner setHasGameLevel={setHasGameLevel} />
        <WantToPlay masterSport={masterSport} setHasGameLevel={setHasGameLevel} />
        <InvitePlayersInYourArea masterSport={masterSport} />
        <PartnerClubs masterSport={masterSport} />
        <TopPicks masterSport={masterSport} />
        <SearchForAPlayer />
      </TabContent>

      <Modal isOpen={!hasGameLevel} onRequestClose={() => setHasGameLevel(!hasGameLevel)}>
        <div className="modal-content text-center">
          <Paragraph className="mb40 mt25">
            <Trans ns="friendlyMatches" i18nKey="setYourGameLevel">
              Please set your game level before joining a friendly match!
            </Trans>
          </Paragraph>

          <Button padding="0 10px" margin="0 auto">
            <Link to="/my-account/game-level">
              <Trans ns="player" i18nKey="setYourGameLevel">
                Set Your Game Level
              </Trans>
            </Link>
          </Button>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default StartMatch;
