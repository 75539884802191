/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router';

import { isEmpty } from 'lodash';
import P from '../../../../components/Paragraph';
import Select from '../../../../components/Form/Select';
import {
  Calendar,
  Container,
  Months,
  OnDesktop,
  OnMobile,
  Option,
  Options,
} from './styles';

const RangeDatePicker = ({
  handleChange,
  hasChanges,
  deleteFilters,
  calendarRef,
  pinnedFilters,
  firstSelect,
  setFirstSelect,
  secondSelect,
  setSecondSelect,
  handler,
  displaySingleCalendar,
  doNotDisplayOptions,
  isOnFriendlyMatchesCreateMatches,
  onlyFutureDates,
  onlyPastDates,
  isRankedMatch,
}) => {
  const { t: commonT } = useTranslation('common');
  const history = useHistory();
  const [currentOption, setCurrentOption] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearsChanged, setYearsChanged] = useState(null);

  const start = new Date(firstSelect?.year, firstSelect?.month, firstSelect?.day);
  const end = new Date(secondSelect?.year, secondSelect?.month, secondSelect?.day);

  const years = [];
  for (let i = 2009; i <= new Date().getFullYear(); i++) {
    years.push({
      label: i,
      value: i,
    });
  }
  years.reverse();

  const months = [
    { label: commonT('january') },
    { label: commonT('february') },
    { label: commonT('march') },
    { label: commonT('april') },
    { label: commonT('may') },
    { label: commonT('june') },
    { label: commonT('july') },
    { label: commonT('august') },
    { label: commonT('september') },
    { label: commonT('october') },
    { label: commonT('november') },
    { label: commonT('december') },
  ];

  const handleView = force => {
    if (force) {
      deleteFilters();
      handleChange({ ...hasChanges, month: false });
      handler();
    }

    if (!isEmpty(firstSelect) && isEmpty(secondSelect)) {
      handler();
    }
  };

  const code = history.location.pathname.split('/')[1];
  const languageCode = code && code.length === 2 ? code : null;
  const weekDaysInitials = languageCode !== 'ro'
    ? ['M', 'T', 'W', 'T', 'F', 'S', 'S'] : ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  const getDays = (year, month) => {
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1);
    const dateString = firstDayOfMonth.toLocaleDateString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    const padding = weekDays.indexOf(dateString.split(', ')[0]);

    const currentDate = new Date();
    const actualMonth = currentDate.getMonth();
    const actualYear = currentDate.getFullYear();

    const remainingDaysInMonth = () => {
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const remainingDays = lastDayOfMonth.getDate() - currentDate.getDate() + 1;
      return remainingDays;
    };

    const isFutureOrPastActive = (year === actualYear)
      && (onlyFutureDates || onlyPastDates)
      && (month === actualMonth);

    if (isFutureOrPastActive) {
      if (onlyFutureDates) {
        return {
          daysFromPreviousMonth: padding,
          padding: new Date().getDate() - 1,
          days: remainingDaysInMonth(),
        };
      }

      if (onlyPastDates) {
        return {
          padding,
          days: new Date().getDate(),
          remainingDaysInMonth: remainingDaysInMonth() - 1,
        };
      }
    }

    return {
      padding,
      days: daysInMonth,
    };
  };

  const handleTime = (month, future) => {
    const currentDate = new Date();
    const actualMonth = currentDate.getMonth() + 1;
    const actualYear = currentDate.getFullYear();
    const actualDay = currentDate.getDate();

    const isFutureDate = onlyFutureDates;
    const isPastDate = onlyPastDates && !future;

    let monthToSet;

    if (isFutureDate) {
      if (currentYear === actualYear) {
        monthToSet = month >= actualMonth ? month : month + 1;
      } else {
        monthToSet = month;
      }
    } else if (isPastDate) {
      if (currentYear === actualYear) {
        if (isRankedMatch) {
          monthToSet = isRankedMatch && actualDay - 6 < 0 ? actualMonth - 1 : actualMonth;
        } else {
          monthToSet = month <= actualMonth ? month : actualMonth;
        }
      } else {
        monthToSet = month;
      }
    } else {
      monthToSet = !onlyPastDates && !onlyFutureDates ? month : actualMonth;

      if (onlyPastDates && future) {
        monthToSet = (month < actualMonth || currentYear !== actualYear) ? month : actualMonth;
      }
    }

    switch (monthToSet) {
    case 0:
      setCurrentMonth(12);
      return setCurrentYear(currentYear - 1);

    case 13:
      setCurrentMonth(1);
      return setCurrentYear(currentYear + 1);

    default:
      return monthToSet && setCurrentMonth(monthToSet);
    }
  };

  const displayMonth = type => {
    switch (type) {
    case 0:
      return months[currentMonth - 1].label;
    case 1:
      switch (currentMonth) {
      case 12:
        return months[0].label;
      default:
        return months[currentMonth].label;
      }
    default:
    }
  };

  const displayYear = type => {
    switch (type) {
    case 0:
      return currentYear;
    case 1:
      switch (currentMonth) {
      case 12:
        return currentYear + 1;
      default:
        return currentYear;
      }
    default:
    }
  };

  const month1 = getDays(currentYear, currentMonth - 1);
  const month2 = getDays(currentYear, currentMonth === 13 ? 1 : currentMonth);

  const isBetween = ({ day, month, year }) => {
    const current = new Date(year, month, day);

    if (start > end) {
      return current > end && current < start;
    }
    return current > start && current < end;
  };

  const isSelected = ({ day, month, year }) => {
    const currentDate = new Date();
    const actualMonth = currentDate.getMonth() + 1;
    const actualYear = currentDate.getFullYear();

    const ourDay = firstSelect?.day || currentDate?.getDate();
    const ourMonth = firstSelect?.month || actualMonth;
    const ourYear = firstSelect?.year || actualYear;

    const first = ourDay === day && ourMonth === month && ourYear === year;
    const second = secondSelect?.day === day && secondSelect?.month === month && secondSelect?.year === year;

    if (!firstSelect && !secondSelect && first) {
      return {
        default: true,
        selected: true,
        type: 'selected__placeholder',
      };
    }

    if (firstSelect && first) {
      return {
        selected: true,
        type: start < end ? 'selected__right' : start > end ? 'selected__left' : '',
      };
    } if (firstSelect && second) {
      return {
        selected: true,
        type: start < end ? 'selected__left' : start > end ? 'selected__right' : '',
      };
    }

    return null;
  };

  const select = ({ day, month, year }) => {
    if (!displaySingleCalendar) {
      const isSameSelect = (firstSelect || secondSelect)
        && firstSelect.day === day
        && firstSelect.month === month
        && firstSelect.year === year;
      if (!isSameSelect) {
        if (!firstSelect && !secondSelect) {
          setFirstSelect({ day, month, year });
        } else if (firstSelect && !secondSelect) {
          setSecondSelect({ day, month, year });
        } else if (firstSelect && secondSelect) {
          setFirstSelect({ day, month, year });
          setSecondSelect(null);
        }
      } else {
        setFirstSelect(null);
        setSecondSelect(null);
      }
    } else {
      setFirstSelect({ day, month, year });
      handler();
    }
  };

  const handleYearSelection = data => {
    setCurrentYear(data.value);
    setYearsChanged(true);
  };

  return (
    <Container
      ref={calendarRef}
      pinned={pinnedFilters}
      displaySingleCalendar={displaySingleCalendar}
      isOnFriendlyMatchesCreateMatches={isOnFriendlyMatchesCreateMatches}
    >

      {!doNotDisplayOptions && (
        <Options>
          <Select
            name="year"
            placeholder={commonT('year')}
            onChange={handleYearSelection}
            options={years}
            label={commonT('year')}
            isSearchable={false}
            width="120px"
            lowInput
            {...yearsChanged && { labelOn: true }}
            className="yearSelector"
          />
          <Option onClick={() => setCurrentOption(0)} className={`o${!currentOption ? '__active' : ''}`}>
            <Trans ns="common" i18nKey="calendar" />
          </Option>
          <Option
            onClick={() => {
              setCurrentOption(1); setFirstSelect(false);
              setSecondSelect(false);
              handleView(true);
            }}
            className={`o${currentOption ? '__active' : ''}`}
          >
            <Trans ns="common" i18nKey="month" />
          </Option>
        </Options>
      )}

      <Calendar>
        <Months>
          <div className="calendar">
            <div className="calendar__header">
              <div className="calendar__navigate back" onClick={() => handleTime(currentMonth - 1)} />
              <div className="calendar__month">
                <P bold>
                  {`${displayMonth(0)} ${displayYear(0)}`}
                </P>
              </div>
              {displaySingleCalendar
                ? (<div className="calendar__navigate forth" onClick={() => handleTime(currentMonth + 1, true)} />) : (
                  <OnMobile>
                    <div className="calendar__navigate forth" onClick={() => handleTime(currentMonth + 1, true)} />
                  </OnMobile>
                )}
            </div>

            <div className="week">
              {weekDaysInitials.map((day, key) => (
                <span key={key}>
                  {day.slice(0, 1)}
                </span>
              ))}
            </div>

            <div className="month">
              {month1?.daysFromPreviousMonth && (
                [...Array(month1.daysFromPreviousMonth)].map((_, key) => (
                  <span key={key} className="padded" />
                ))
              )}

              {[...Array(month1.padding)].map((_, key) => {
                const currentDate = new Date();
                const actualMonth = currentDate.getMonth() + 1;
                const actualYear = currentDate.getFullYear();

                const isSameMonthAndYear = (actualMonth === currentMonth) && actualYear === currentYear;
                const requiresSpecialPadding = (isSameMonthAndYear && onlyFutureDates);
                const currentKey = key + 1;

                return (
                  <span key={currentKey} className={requiresSpecialPadding ? 'special padded' : 'padded'}>
                    {requiresSpecialPadding && currentKey}
                  </span>
                );
              })}

              {[...Array(month1.days)].map((_, key) => {
                const currentDate = new Date();
                const actualMonth = currentDate.getMonth() + 1;
                const actualYear = currentDate.getFullYear();
                const actualDay = currentDate.getDate();

                const isSameMonthAndYear = (actualMonth === currentMonth) && (actualYear === currentYear);
                const currentKey = (isSameMonthAndYear && onlyFutureDates)
                  ? ((isSameMonthAndYear ? month1.padding : 0) + (key + 1))
                  : key + 1;

                const selectedDate = { day: currentKey, month: currentMonth, year: displayYear(0) };

                const dateIteratedInLoop = new Date(currentYear, currentMonth, key);
                const actualDate = new Date(actualYear, actualMonth, actualDay);
                const isDateGreaterThanPresent = dateIteratedInLoop > actualDate;

                const sixDaysInMillis = 6 * 24 * 60 * 60 * 1000; // Duration of 6 days in milliseconds
                const isLessThanSixDays = actualDate.getTime() - dateIteratedInLoop.getTime() > sixDaysInMillis;

                const requiresSpecialPadding = (onlyPastDates && isRankedMatch
                  && ((isLessThanSixDays) || isDateGreaterThanPresent));

                return (
                  <span
                    key={key}
                    {...(isBetween(selectedDate) && { className: 'gap gap__left' })}
                    {...(isSelected(selectedDate)?.selected && !requiresSpecialPadding && {
                      className: `selected ${isSelected(selectedDate)?.type}`,
                    })}
                    {...requiresSpecialPadding && {
                      className: 'special padded',
                    }}
                    {...!requiresSpecialPadding && {
                      onClick: () => { select(selectedDate); handleView(); },
                    }}
                  >
                    {currentKey}
                  </span>
                );
              })}

              {month1?.remainingDaysInMonth && (
                [...Array(month1.remainingDaysInMonth)].map((_, key) => {
                  const currentKey = month1.days + (key + 1);
                  return (
                    <span key={key} className="special padded">
                      {currentKey}
                    </span>
                  );
                })
              )}
            </div>
          </div>
          {!displaySingleCalendar && (
            <div className="calendar">
              <div className="calendar__header">
                <div className="calendar__month">
                  <P bold>
                    {`${displayMonth(1)} ${displayYear(1)}`}
                  </P>
                </div>
                <OnDesktop>
                  <div className="calendar__navigate forth" onClick={() => handleTime(currentMonth + 1)} />
                </OnDesktop>
              </div>
              <div className="week">
                {weekDaysInitials.map((day, key) => (
                  <span key={key}>
                    {day.slice(0, 1)}
                  </span>
                ))}
              </div>
              <div className="month">
                {[...Array(month2.padding)].map((_, key) => (
                  <span key={key} className="padded" />
                ))}
                {[...Array(month2.days)].map((_, key) => (
                  <span
                    key={key}
                    onClick={() => {
                      select({
                        day: key + 1,
                        month: currentMonth + 1 === 13 ? 1 : currentMonth + 1,
                        year: displayYear(1),
                      });
                      handleView();
                    }}
                    {...(isSelected({
                      day: key + 1,
                      month: currentMonth + 1 === 13 ? 1 : currentMonth + 1 === 13 ? 1 : currentMonth + 1,
                      year: displayYear(1),
                    })?.selected && {
                      className: `selected ${isSelected({
                        day: key + 1,
                        month: currentMonth + 1 === 13 ? 1 : currentMonth + 1,
                        year: displayYear(1),
                      })?.type}`,
                    })}
                    {...(isBetween({
                      day: key + 1,
                      month: currentMonth + 1 === 13 ? 1 : currentMonth + 1,
                      year: displayYear(1),
                    }) && { className: 'gap gap__right' })}
                  >
                    {key + 1}
                  </span>
                ))}
              </div>
            </div>
          )}
        </Months>
      </Calendar>
    </Container>
  );
};

export default RangeDatePicker;
