import { Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { external } from '../../components/common/http';
import LoadImage from '../../components/common/LoadImage';
import { fetchTotalEventsInCountry } from '../Events/reducers';
import {
  Button,
  H1,
  H4,
  Link,
  P,
} from '../../components/Collection';
import LangSelect from '../../components/Layout/components/LangSelect';
import mixpanel from '../../mixpanel';
import {
  Container,
  Contain,
  CallToAction,
  Totals,
  Icon,
  ListsContainer,
  List,
  Item,
  RedText,
  Logo,
  Header,
} from './styles';

const Index = ({ isMobile }) => {
  const dispatch = useDispatch();
  // const { eventsInYourCountry } = useSelector(state => state.events.list);

  useEffect(() => {
    const fetchAPI = async () => {
      const { data } = await external.get('https://ipapi.co/json/');
      if (data?.country_name) {
        dispatch(fetchTotalEventsInCountry(data?.country_name));
      }
    };

    fetchAPI();
  }, []);

  const trackClicks = () => {
    mixpanel.track('Join Sportya - Bulgaria');
  };

  return (
    <Container>
      <Header>
        <Link to="/">
          <Logo src={LoadImage('logo-white.svg')} alt="Sportya logo" />
        </Link>
        <LangSelect
          color="white"
          margin="0 0 0 auto"
          onlyLangs={[{ label: 'en', value: 'en' }, { label: 'bg', value: 'bg' }]}
        />
      </Header>
      <Contain>
        <H1 color="white" fontSize="48px" lineHeight="68px">
          <Trans ns="campaignBG" i18nKey="unregistered.title">
            Join the Sportya tennis community. Play your best match!
          </Trans>
        </H1>
        <H4
          color="white"
          fontSize="24px"
          lineHeight="31px"
          {...isMobile && { margin: '20px 0 0 0' }}
        >
          <Trans ns="campaignBG" i18nKey="unregistered.subtitle">
            Create a FREE account and enter Silver events* with
            {' '}
            <RedText>100% DISCOUNTED</RedText>
            {' '}
            Sportya Service Fee** all summer long
          </Trans>
        </H4>
        <CallToAction>
          <Button
            {...isMobile && { margin: '0 auto 10px auto' }}
            color="white"
            background="#F42B03"
            padding="0 20px"
            maxWidth="290px"
            width="100%"
            noBorder="true"
            onClick={() => trackClicks()}
          >
            <Link to="/register">
              <Trans ns="campaignBG" i18nKey="unregistered.joinSportya">
                Join Sportya
              </Trans>
            </Link>
          </Button>
          <Totals>
            <Icon src={LoadImage('campaign/calendar.png')} />
            <P bold color="secondary">
              <Trans ns="campaignBG" i18nKey="unregistered.eventsTotal">
                {{ amount: 25 }}
                {' '}
                Events in your country
              </Trans>
            </P>
          </Totals>
          <Totals>
            <Icon src={LoadImage('campaign/player.png')} />
            <P bold color="secondary">
              <Trans ns="campaignBG" i18nKey="unregistered.playersTotal">
                Over 26.000 Players in your region
              </Trans>
            </P>
          </Totals>
        </CallToAction>
        <ListsContainer>
          <List>
            <Item>
              <Trans ns="campaignBG" i18nKey="unregistered.list.item1">
                National calendar of tennis events for amateurs
              </Trans>
            </Item>
            <Item>
              <Trans ns="campaignBG" i18nKey="unregistered.list.item2">
                Level-based play from beginners to experienced
              </Trans>
            </Item>
          </List>
          <List>
            <Item>
              <Trans ns="campaignBG" i18nKey="unregistered.list.item3">
                Personal player profiles and more
              </Trans>
            </Item>
            <Item>
              <Trans ns="campaignBG" i18nKey="unregistered.list.item4">
                Match format: 2 Tie-Break sets with Match Tie-Break at 1 set all
              </Trans>
            </Item>
          </List>
        </ListsContainer>
        {isMobile
          && (
            <Button
              color="white"
              background="#F42B03"
              height="60px"
              padding="0 20px"
              width="100%"
              margin="0 0 40px 0"
              noBorder="true"
              onClick={() => trackClicks()}
            >
              <Link to="/register">
                <Trans ns="campaignBG" i18nKey="unregistered.joinSportya">
                  Join Sportya
                </Trans>
              </Link>
            </Button>
          )}
        <P
          xSmall
          opacity={0.3}
          color="white"
        >
          <Trans ns="campaignBG" i18nKey="readme">
            *1st Serve, Silver, Gold, Platinum, Special, and Champions Tournament. Each event category offers different
            rewards in points and prizes, and provides a different level of challenge. For each event, we organize one
            or more competitions based on Game Levels, either for Singles or Doubles.

            **Sportya Service Fee is an online fee that represents the value of the services offered by the site in
            connection with the event. This helps us run our platform and offer services like customer support.
          </Trans>
        </P>
      </Contain>
    </Container>
  );
};

export default Index;
