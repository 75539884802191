import React from 'react';
import { Paragraph } from '../../../../components';
import { Contain } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

const SmallProfilePicture = ({ player }) => (
  <Contain>
    { player.profilePicture ? (
      <div className="player__avatar d-flex">
        <div className="player__avatar--wrapp position-relative">
          <div className="player__avatar--default d-flex">
            <img
              src={LoadImage(`accounts/${player.userId}/${player.profilePicture}`, true)}
              alt={`${player.firstName.charAt(0)}${player.lastName.charAt(0)}`}
              width="38px"
              height="38px"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="player__avatar d-flex">
        <div className="player__avatar--wrapp position-relative">
          <div className="player__avatar--default d-flex">
            <Paragraph medium bold className="text-uppercase">
              {player.firstName
                    && player.lastName
                    && player.firstName.charAt(0) + player.lastName.charAt(0)}
            </Paragraph>
          </div>
        </div>
      </div>
    )}
  </Contain>
);

export default SmallProfilePicture;
