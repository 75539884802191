export default {
  seoTitle: 'Înregistrează-te | Sportya',
  tosAgree: 'Sunt de acord cu <3>Termenii și Condițiile</3> <1>{{ process.env.REACT_APP_SITE_NAME }}</1> și <5>Politica de Confidențialitate</5>, care include consimțământul meu de a fi contactat cu privire la utilizarea serviciilor <1>{{ process.env.REACT_APP_SITE_NAME }}</1>, inclusiv, dar fără a se limita la înregistrarea și participarea la evenimente, gestionarea meciurilor amicale și orice altă comunicare internă.',
  ageAgree: 'Prin înregistrare, confirm că am vârsta minimă de <1>15 ani</1>',
  createAccount: 'Creează Cont',
  alreadyHaveAnAccount: 'Ești deja înregistrat?',
  signupWith: 'Sau înregistrează-te cu',
  newsletterSubscribe: 'Mă abonez la newsletter-ul <1>{{ process.env.REACT_APP_SITE_NAME }}</1>, care conține informații despre evenimentele <1>{{ process.env.REACT_APP_SITE_NAME }}</1> și alte oportunități de joc din regiunea mea, detalii cu privire la funcționalități noi și promoții / discount-uri selectate de la partenerii / sponsorii noștri apropiați',
  countriesComingSoon: 'Noi locații vor fi adăugate în curând!',
  title: 'Înregistrează-te',
  subTitle: 'Sportya este deschisă pentru jucătorii din <1>România, Republica Moldova, Bulgaria, Bahrain, Hungary, și Cehia.</1>',
  oldUserInfo: `<0>Ai deja un cont Tenis Partener?</0> 
  Nu este necesar să îți creezi un nou cont Sportya. Datele tale au fost deja migrate și te poți <2>Autentifica</2> cu email-ul și parola contului Tenis Partener.`,
};
