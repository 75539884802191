import styled from '@emotion/styled';
import { P } from '../../../../components/Collection';

export const Container = styled.div(({ hasLoader }) => ({
  ...hasLoader && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  minHeight: '350px',
  padding: '0 0 20px 0',
}));

export const RanksContainer = styled.div({
  padding: '20px 30px',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
});

export const RanksHeader = styled.div({
  display: 'flex',
  margin: '0 0 20px 0',
  padding: '0 10px',
});

export const RankContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #EAF6F6',
  padding: '10px',
  '&:last-of-type': {
    borderBottom: 'unset',
  },
});

export const TrendIcon = styled(P)(({ trendColor }) => ({
  margin: 'unset',
  color: trendColor || '#fff',
}));

export const Column = styled.div(({
  maxWidth, flex, justify, column, xMargin, center,
}) => ({
  ...flex && {
    display: 'flex',
    ...justify && {
      justifyContent: justify,
    },

    ...center && {
      alignItems: 'center',
    },

    ...column && {
      flexDirection: 'column',
    },
  },

  ...xMargin && {
    margin: xMargin,
  },

  width: '100%',
  ...maxWidth && {
    maxWidth: `${maxWidth}px`,
  },
}));
