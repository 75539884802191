export default {
  seoTitle: 'Esemény keresése | Sportya',
  leaderboardSeoTitle: 'Játékosok ranglistahelyezése | Sportya',
  leaderboards: 'Ranglistahelyezések',
  results: 'Eredmények',
  newEventsNearYou: 'Kiemelt események',
  seeAll: 'Összes megtekintése',
  seeEvent: 'Esemény megtekintése',
  seeMyEvents: 'Eseményeim megtekintése',
  goToEvent: 'Ugrás az Eseményhez ⟶',
  newEvent: 'Új esemény',
  pickLocation: 'Helyszín',
  recommendedEvents: 'Kiemelt események',
  pickCategory: 'Kategória',
  pickYear: 'Év',
  pickMonth: 'Hónap',
  all: 'Összes',
  filter_all: '{{amount}} szűrése',
  clearAll: 'Mindent töröl',
  wildcard: 'Helyettesítő jel',
  filters: 'Szűrők',
  allActive: 'Aktív események',
  availableEntries: 'Szabad ülések',
  prizes: 'Nyeremények',
  gameType: 'Mérkőzés típusa: {{ gameType }}',
  gameTypeText: 'Mérkőzés típusa',
  eventLevel: 'Esemény szintje',
  eventStatus: 'Esemény státusa',
  age: 'Életkor',
  playMode: 'Játék változata',
  singles: 'Egyéni',
  doubles: 'Páros',
  Singles: 'Egyéni',
  Doubles: 'Páros',
  viewLocation: 'Helyszín megtekintése',
  eventCategory: 'Kategória: ',
  eventPeriod: 'Esemény időszaka: ',
  surfaceType: 'Felület típusa: ',
  noOfCourts: 'Pályák száma: ',
  organizerName: 'Szervező: ',
  organizerPhone: 'Telefonszám: ',
  organizerEmail: 'E-mail: ',
  tournamentDirector: 'Tornaigazgató: ',
  refereeName: 'Játékvezető neve: ',
  supervisor: 'Sportya képviselője: ',
  followEvent: 'Kövesse',
  unfollowEvent: 'Követés megszüntetése',
  viewAllUpdates: 'Összes frissítés megtekintése ({{ total }})',
  viewLessUpdates: 'Kevesebb frissítés megtekintése',
  officialUpdates: 'Hivatalos frissítések',
  viewCompetition: 'Tovább a versenyhez',
  competitions: 'Versenyek',
  priceNotAvailable: 'Ár nem elérhető',
  topUpNow: 'Feltöltés most',
  allWinners: 'Összes győztes',
  joinNowLink: '<0>Csatlakozz most</0>',
  level: 'Szint',
  champion: 'Bajnok',
  champions: 'Bajnokok',
  firstServe: 'Első adogatás',
  league: 'Liga',
  finalist: 'Döntős',
  finalists: 'Döntősök',
  readMore: 'Olvass tovább',
  officialSponsors: 'Hivatalos szponzorok',
  waitingList: 'Várólista',
  mainList: 'Főlista',
  acceptanceList: 'Elfogadólista',
  preRegistrationList: 'Rendezőlista',
  sortingList: 'Rendezőlista',
  sortList: 'Rendezőlista',
  avgLevel: 'Átl. szint',
  avgRank: 'Átl. ranglistahelyezés',
  avgPoints: 'Átl. pontok',
  matchStart: 'Mérkőzés becsült kezdete: <1>legkorábban {{ date }}</1>',
  restrictions: 'Korlátozások',
  restrictionsDoubleLevel: 'Maximális páros játékszint {{ level }}',
  restrictionsTeamLevel: 'Csapat maximális szintje - {{ level }}',
  confirmPayment: 'Fizetés megerősítése',
  restrictionsGender: 'Nem {{ gender }}',
  summerAndPremium: 'Csak Prémium fiókok',
  gameLevel: '<1>{{ level }}</1> szint',
  playerGameLevel: 'Játékszint',
  playerRank: 'Ranglistahelyezés',
  closed: 'Lezárult',
  tablePlayers: 'Sorsolás:<1>{{ players }} játékosok</1>',
  tableGroups: 'Sorsolás:<1>{{ players }} csapatok</1>',
  eliminatory: 'Kieséses',
  groups: 'Csoportok',
  eventsFound: '<0>{{ totalResults }} eseményt</0> találtunk a következő helyszínen: <2>{{ location }}</2>',
  eventsFound_plural: '<0>{{ totalResults }} eseményt</0> találtunk a következő helyszínen: <2>{{ location }}</2>',
  in: '-ban/-ben',
  noResults: 'Nem találhatók események.',
  joinAndPay: 'Csatlakozz és fizess',
  ballToBeAnnounced: 'Hamarosan bejelentésre kerül',
  phaseStatuses: {
    waitingList: 'Várólista',
    mainList: 'Főlista',
    sortingList: 'Rendezőlista',

    openingSoon: 'Nyitás hamarosan',
    openingSoonStartDate: 'A {{ listType }}-ra való regisztráció {{ date }}-án nyílik meg',
    openingSoonEndDate: '',

    preRegistrationsOpen: 'Előregisztráció',
    preRegistrationsOpenStartDate: 'A rendezőlistára való regisztráció  {{ date }}-án zárul le',
    preRegistrationsOpenEndDate: 'A rendezésre {{ date }}-án kerül sor',

    sortPending: 'Rendezés függőben',
    sortPendingStartDate: 'A főlistára való regisztráció {{ date }}-án nyílik meg',
    sortPendingEndDate: '',

    registrationsOpen: 'Nyitva',
    registrationsOpenStartDate: '',
    registrationsOpenEndDate: 'A főlistára való regisztráció {{ date }}-án zárul le',

    drawPending: 'Sorsolás függőben',
    drawPendingStartDate: '',
    drawPendingEndDate: 'A várólistára való regisztráció {{ date }}-ig még nyitva van',

    readyToPlay: 'Lejátszásra kész',
    readyToPlayStartDate: '',
    readyToPlayEndDate: 'A várólistára való regisztráció {{ date }}-ig még nyitva van',

    playing: 'Lejátszás folyamatban',
    playingStartDate: '',
    playingEndDate: 'A várólistára való regisztráció {{ date }}-ig még nyitva van',

    resultsPending: 'Eredmények függőben',
    resultsPendingStartDate: '',
    resultsPendingEndDate: '',

    ended: 'Vége',
    endedStartDate: '',
    endedEndDate: '',
  },
  registrationsOpenMainList: '<0>Főlista</0>: {{ available }} hely szabad – Siess!',
  registrationsOpenMainList_plural: '<0>Főlista</0>: {{ available }} hely szabad',
  registrationsOpenMainListFull: '<0>Főlista</0>: betelt',
  registrationsOpenMainListFull_plural: '<0>Főlista</0>: betelt',
  registrationClosedMainList: '<0>Főlista</0>: lezárva',
  registrationClosedMainList_plural: '<0>Főlista</0>: lezárva',
  registrationsOpenWaitingList: '<0>Várólista</0>: nyitva ({{ occupied }} hely foglalt)',
  registrationsOpenWaitingList_plural: '<0>Várólista</0>: nyitva ({{ occupied }} hely foglalt)',
  registrationsOpenWaitingListAll: '<0>Várólista</0>: nyitva ({{ occupied }} hely foglalt)',
  registrationClosedWaitingListAll: '<0>Várólista</0>: lezárva',
  registrationClosedWaitingList: '<0>Várólista</0>: lezárva',
  preRegistrationsOpenSortList: '<0>Rendezőlista</0>',
  preRegistrationClosedSortList: '<0>Rendezőlista</0>: lezárva',

  slotsAvailable_space: ' Hely szabad',
  slotAvailable_space: ' Hely szabad',

  slotsAvailable: ' Hely szabad',
  slotAvailable: ' Hely szabad',
  availableSlot: 'Szabad hely',
  availableSlots: 'Szabad helyek',
  slotsOccupied: '{{occupiedSlots}} hely foglalt',
  slotOccupied: '{{occupiedSlots}} hely foglalt',

  joinCompetition: {
    seoTitle: 'Csatlakozz a versenyhez | Sportya',
  },

  eventDetails: {
    seoTitle: '{{eventName}} - Esemény | Sportya',
  },

  competitionDetails: {
    seoTitle: 'Szint {{competitionLevel}}, {{gameType}}, {{eventName}} - Események | Sportya',
    title: 'Helyi Liga technikai útmutató és GYIK',
    text: 'Regisztrálj <1>INGYENESEN</1> egy Sportya Helyi Ligába, és teniszezz <1>AHOL AKARSZ, AMIKOR AKARSZ</1> azonos szintű ellenfelekkel. A Helyi Liga játékformátumként a több játékosból álló csoportban zajló játékot javasolja, körmérkőzéses formátumban (mindenki a csoport minden tagjával versenyez), amely több héten keresztül zajlik.',
  },

  openingSoon: 'Nyitás hamarosan',
  preRegistrationsOpen: 'Előzetes regisztráció nyitva',
  sortPending: 'Rendezés függőben',
  registrationsOpen: 'Nyitva',
  drawPending: 'Sorsolás függőben',
  readyToPlay: 'Lejátszásra kész',
  playing: 'Lejátszás folyamatban',
  resultsPending: 'Eredmények függőben',
  ended: 'Vége',
  endedStartDate: '',
  endedEndDate: '',

  gender: 'Nem',
  male: 'Férfi',
  female: 'Nő',
  mixt: 'Vegyes',
  weekdays: 'Hétköznapok',
  weekend: 'Hétvégék',
  resetToDefault: 'Visszaállítás alapértelmezettre',
  apply: 'Alkalmaz',
  officialBall: 'Hivatalos labda',
  playerPackageTitle: 'Játékos csomag',
  playerPackage: `A standard részvételi csomag a következőkből áll:
  - két új labda minden egyes mérkőzéshez (a mérkőzés végén vissza kell juttatni a szervezőhöz)
  - egy üveg viz minden játékosnak.`,
  winners: {
    title: 'Főtábla',
    subtitle: 'Győztesek',
    downloadPdf: 'PDF letőltése',
    quarterfinals: 'Negyeddöntők',
    semifinals: 'Elődöntők',
    final: 'Döntő',
  },
  join: {
    matchStart: 'Mérkőzés becsült kezdete',
    package: {
      title: 'Játékos csomag (ingyenes)',
      subtitle: 'A standard részvételi csomag a következőkből áll:',
      1: 'két új labda minden egyes mérkőzéshez',
      2: 'egy üveg viz minden játékosnak',
      return: 'A mérkőzés végén a labdákat vissza kell adni a szervezőnek. A mellékelt Játékoscsomag (Ingyenes) és az Eseményáruházból vásárolt további termékek a rendezvénynek otthont adó Klub recepciójára kerülnek kiszállításra és ott vehetők át.',
    },
    sprinten: {
      title: 'Valósíts meg változást!',
      1: 'Kapcsolódj be és támogasd a gyermekgyógyászati ​​fizioterápiás ösztöndíjakat, amelyek több tucat fogyatékkal élő gyermeket segítenek abban, hogy élvezzék a mozgást és esélyt kapjanak a normális életre.',
      2: 'A ProAm Events Alapítvány által a SPRINTEN projekten keresztül megvalósított program.',
      donate: 'Adományozz most!',
    },
    duoJoin: 'Add hozzá Páros partneredet',
    searchPartner: 'Keress partnert, és csatlakozz ehhez az eseményhez',
    playerAdded: 'Felvetted a játékost a csapatodba!',
    changePlayer: 'Játékos változtatása',
    payment: {
      title: 'Fizetési mód',
      selectMethod: 'Fizetési mód kiválasztása',
      virtualWallet: 'Virtuális pénztárca',
      creditCard: 'Hitelkártya',
      availableBalance: 'Rendelkezésre álló egyenleg',
      insufficientFunds: 'Fedezethiány',
      negativeFunds: 'Negatív keret',
      topUpFirst: 'Kérjük, először <0> töltsd fel a Virtuális pénztárcádat</0>, hogy ezzel a fizetési móddal tudj fizetni, vagy fizess hitel-/bankkártyáddal',
      confirmPay: 'Megerősítés és fizetés',
      redirect: 'Átirányítás ide:',
    },
  },
  amountToBePaid: 'Fizetendő összeg',
  remainingBalance: 'Fennmaradó egyenleg',
  pleaseConfirmPaymentForEvent: 'Kérjük, erősítsd meg virtuális pénztárcáddal a végső fizetést a kiválasztott eseményért.',
  summerPremiumAccountPrice: '<0>Prémium fiók: {{ price }} {{ currency }}</0>',
  clubEntryFee: 'Klub belépési díj: <1><2>{{ primaryClubFee }}</2></1>',
  clubEntryFee2: 'Klub belépési díj',
  premiumDiscount: 'Prémium kedvezmény',
  getPremium: 'Igényelj Prémium fiókot',
  withdraw: 'Visszalépés',
  joined: 'Feliratkozott',
  summary: 'Összegzés',
  bookingFee: 'Sportya szolgáltatási díj',
  partnerBookingFee: 'Sportya Partner szolgáltatási díj',
  premiumDiscountIncluded: '(inkl. -{{ amount }} {{ currency }} Prémium kedvezmény)',
  secondGameDiscountIncluded: '(inkl. -{{ amount }} {{ currency }} kedvezmény a 2. versenyre)',
  getPremiumDiscount: 'Igényelj Prémium fiókot, és fizess csak <1>{{ amount }} {{ currency }}</1> összeget',
  learnMore: 'Tudj meg többet',
  participationFee: 'Klub belépési díj',
  participationFeeInfo: 'Közvetlenül a szervezőnek kell fizetni, amikor csatlakozol a tornához. Szervező klub: <1>{{ clubName }}</1>.',
  yourTShirtSize: 'Pólóméreted',
  sprintenDonation: 'SPRINTEN adomány',
  sprintenDonationProduct: 'SPRINTEN projekt adomány: {{ amount }} {{ currency }}',
  tShirtProduct: 'Prémium Sportya Play On póló {{ gender }} {{ size }}',
  shoppingBasket: 'Kosár',
  couponDiscount: 'Kupon <1>Kupon eltávolítása</1>',
  totalToBePaid: 'Összesen fizetendő',
  inclVAT: 'ÁFA-val',
  addCoupon: 'Kupon hozzáadása',
  joinLevelMissing: 'Kérjük, állítsd be a játékszintedet, mielőtt csatlakozol egy versenyhez!',

  payRule: `Az eseményre való regisztrációval kijelentem, hogy elolvastam, megértettem és elfogadom a <1>Sportya - Szabályok és előírások</1> című dokumentumot. Kijelentem, hogy fizikailag egészséges vagyok és teljes felelősséget vállalok a részvételemért. Beleegyezem, hogy telefonszámomat a rendezvény szervezője és/vagy Helyi ligák esetén a csoportomban levő játékosok megkapják.

  Kijelentem továbbá, hogy elolvastam, megértettem és elfogadom a <3>Visszatérítési szabályzat</3> című dokumentumot. Beleegyezem, hogy a szolgáltatások nyújtása a 14 napos visszalépési időszak alatt kezdődjön meg. Tudomásul veszem, hogy a megvásárolt szolgáltatások teljes körű teljesítését követően a visszalépési jogom elvész.`,

  learnMoreDiscount: 'Szerezz kedvezményt <1>További információk</1>',
  discountModalTitle: 'Regisztrációs kedvezmény 2 versenyre',
  discountModalDescription: 'Az <1>INGYENES</1> fiókkal rendelkező játékosok <3>15 RON / 6 BGN </3> kedvezményben részesülnek, ha 2 versenyre regisztrálnak ugyanazon a Ezüst vagy Speciális eseményen Romániából, illetve <5>25 RON / 10 BGN</5> kedvezményben, ha 2 versenyre jelentkeznek egy Arany eseményen belül Romániából vagy bármely eseményre Bulgáriából.\n\n A kedvezmény a regisztrációkor automatikusan érvényesül, azzal a feltétellel, hogy az első versenyre való regisztrációt – függetlenül attól, hogy egyéni, páros vagy csapatversenyről van-e szó – a 2. versenyre történő foglalás előtt a rendszerben érvényesíteni kell (fizetett).',
  players: 'Játékosok',
  cancel: 'Visszavonás',
  withdrawText1: 'Biztos, hogy visszalép erről az eseményről? \n A tornákról való visszalépést pontokkal vagy a tornákra való regisztráció jogának korlátozásával lehet büntetni az időszaktól függően, amikor zajlanak, illetve a számuktól. A várólistáról való visszalépés nem von büntetést maga után.',
  withdrawText2: 'A visszalépésekről és azok következményeiről további információ az <1> Általános előírások </1> című dokumentumban (5. fejezet) található. Az online megrendeléshez kapcsolódó összeg visszatérítése a <3>Visszatérítési szabályzat</3> szerint történik.',
  filtersList: {
    champions: 'Győztesek',
    firstServe: 'Első adogatás',
    silver: 'Ezüst',
    gold: 'Arany',
    special: 'Speciális',
    platinum: 'Platina',
    league: 'Helyi Liga',
  },
  sortby: 'Rendezés',
  startDate: 'Kezdő dátum',
  newest: 'Legújabb',
  showEndedEvents: 'Befejezett események',
  joinedEvents: 'Csak az én eseményeim',
  reservedSlot: 'Fenntartott hely /',
  underMaintenance: 'Ez az esemény karbantartás alatt áll. A regisztráció pillanatnyilag szünetel.',
  underMaintenanceButton: 'Karbantartás alatt',
  competitionsUnavailable: 'Hamarosan meghirdetik a versenyeket erre az eseményre.',
  min1event: 'Legalább egy lejátszott esemény ',
  min3event: 'Legalább 3 lejátszott esemény',
  joinNowButton: {
    ended: 'A verseny véget ért',
    playing: 'Lejátszás folyamatban',
    resultsPending: 'Eredmények függőben',
    drawPending: 'Sorsolás függőben',
    readyToPlay: 'Lejátszásra kész',
  },
  withdrawal: {
    early: {
      title: 'KORAI visszalépés',
      subtitle: 'Biztos, hogy vissza akarsz lépni a versenyről?',
      desc: 'Ha megerősíted a visszalépést, elveszíted a versenylistás helyedet, és a Sportya szolgáltatási díját visszautaljuk a regisztrációt benyújtó játékos Virtuális pénztárcájába.',
    },
    announced: {
      title: 'BEJELENTI Kilépést a {{list}}',
      subtitle: 'Biztos, hogy vissza akarsz lépni a versenyről?',
      desc: 'Ha megerősíted a visszalépést, elveszíted a versenylistás helyedet, és a Sportya szolgáltatási díját visszautaljuk a regisztrációt benyújtó játékos Virtuális pénztárcájába.',
    },
    late: {
      title: 'KÉSŐI Kilépés a {{list}}',
      subtitle: 'Biztos, hogy vissza akarsz lépni a versenyről?',
      desc: 'Ha megerősíted a visszalépést, elveszíted a versenylistás helyedet, és a Sportya szolgáltatási díját NEM térítjük vissza.',
    },
    denied: {
      title: 'Az online visszalépés már nem engedélyezett',
      subtitle: '',
      desc: 'Jelenleg az online visszalépés már nem engedélyezett. Erről a versenyről továbbra is visszaléphetsz, ha kapcsolatba lépsz a Tornaigazgatóval. Ez lehetővé teszi számára, hogy enyhítse a visszalépésed hatását. Felhívjuk figyelmedet, hogy a Sportya szolgáltatási díját NEM térítjük vissza.',
    },
  },
  disclaimer: 'Kérjük, tekintsd meg a <1>Sportya Szabályok és előírások</1> és a <3>Visszatérítési szabályzat</3> című dokumentumokat, ha további információra van szükséged a visszalépéssel és a visszatérítéssel kapcsolatban',

  frozenLeaderboard: 'A hivatalos ranglistát 2021. december 28-tól kezdődően hetente újraszámítjuk. Köszönjük a türelmedet.',
  sortListOpen: 'A rendezőlistára való regisztráció megnyílt',
  pending: 'Regisztráció függőben',

  goToEvent2: 'Ugrás az Eseményhez',
  undisputed: 'Vitathatatlan',
  announcedWithdrawal: 'Bejelentett visszavonás',
  penalty: 'Büntetés',
  pendingPlayer: 'Vitathatatlan',
  group: 'Csoport {{type}}',
  eventFound: '<0>{{ totalResults }} esemény</0> találtunk a következő helyszínen: <2>{{ location }}</2>',

  eventFoundNc: '<0>{{ totalResults }} esemény</0> megtalált',
  eventsFoundNc: '<0>{{ totalResults }} eseményeket</0> megtalált',
  proximityRadius: 'Sugár',
  qualifying: 'Minősítés',
  undefined: 'Nem elérhető',
  upcomingCompetitions: {
    upcoming: 'KÖTELEZŐ',
    playing: 'JÁTSZÁS',
  },

  bookingFeeDiscounted: 'Sportya szolgáltatási díj (Kedvezményes)',
  partnerBookingFeeDiscounted: 'Sportya Partner szolgáltatási díj (Kedvezményes)',

  stages: {
    1: 'Végső',
    2: 'Elődöntők',
    3: 'Negyeddöntők',
    4: 'Negyeddöntők',
  },
  nationalCalendar: 'Válassza ki nemzeti naptárát',
  quickFind: 'Gyorsan megtalálja az Ön számára legmegfelelőbb eseményt',
  specificMonth: 'Állítson be egyéni dátumokat',
  showFeaturedEvents: 'Kiemelt események megjelenítése',
  hideFeaturedEvents: 'Kiemelt események elrejtése',
  newEvents: 'Új események',
  teamGameLevel: 'Csapatszint',
  joinNow: 'Csatlakozz most',
  restrictionsLevel: 'Max szint {{ level }}',
  noEventsFound: 'Nem található esemény.',
  onlymale: 'Csak Férfiak',
  onlyfemale: 'Csak Nők',
  onlymixt: 'Vegyes',
  allAges: 'Minden korosztály',
  customAge: 'Válassza ki az intervallumot',
  shirtSize: 'Póló mérete',
  lfp: {
    noPartner: 'Még nincs páros csapattársa?',
    whosInterested: 'Nézze meg, ki keres még',
    findAPartner: 'Keressen páros partnert',
    eligible: `Mielőtt felveszi magát a névsorba, vagy felveszi a kapcsolatot egy játékossal, győződjön meg arról, hogy megfelel a versenyre való alkalmassági feltételeknek azzal a csapattárssal, akivel kapcsolatba szeretne lépni.
    
    Ha felveszi magát a listára, vagy felveszi a kapcsolatot egy lehetséges páros csapattársával, nem köteles versenyezni. <1>Tudjon meg többet</1>`,
    registrationNotRequired: 'A páros csapatba partnert kereső játékosok listájára való felvétele nem jelent semmilyen kötelezettséget a versenyen való részvételre.',
    findMore: 'Tudjon meg többet',
    imLooking: 'Társat keresek',
    optOut: 'Feladom',
    contact: 'Üzenet',
    playersLFP: '{{ total }} játékos társat keres',
    join: 'Csatlakozz a listához!',
    beFirst: 'Mutasd meg más játékosoknak, akik páros partnereket keresnek erre a versenyre, hogy Önt is érdekli.',
    eligibilityCriteria: `Kérjük, ellenőrizze a verseny alkalmassági feltételeit, mielőtt potenciális partnert keres.
    
    A páros csapatba partnert kereső játékosok listájára való felvétele nem jelent semmilyen kötelezettséget a versenyen való részvételre. <1>Tudjon meg többet</1>`,
    iAmLFP: 'Társat keresek',
  },
  fmBanner: {
    desc: `Amíg arra vár, hogy új eseményeket jelentsenek be országában, barátságos mérkőzést játszhat barátaival vagy más játékosokkal a Sportya`,
  },
};
