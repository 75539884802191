export default {
  friendsSeoTitle: 'приятели | Sportya',
  title: 'приятели',
  tabs: {
    allFriends: 'Всички приятели ({{friendsCount}})',
    friendRequests: 'Молби за приятелство ({{friendsCount}})',
    sentRequests: 'Изпратени заявки ({{friendsCount}})',
  },
  unfriendText: 'Изтриване на приятел {{name}}',
  unfriend: 'Изтриване на приятел',
  unfriendConfirmationText: 'Сигурен ли си, че премахваш приятелството на {{name}}?',
  noRequestsSent: 'Не си получил нови покани за приятелство',
  noRequestsReceived: 'Не си изпратил нови покани за приятелство',
  wantToAddFirstFriend: 'Искате ли да добавите своя първи приятел в Sportya?',
  confirm: 'Приеми',
  decline: 'Откажи',
  cancelRequest: 'Откажи заявката',
};
