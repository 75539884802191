import React, { useEffect, useState } from 'react';
import LoadImage from '../common/LoadImage';
import { Container } from '../index';

const banners = [
  {
    name: 'PRINGLES',
    path: 'campaign/banners/pringles_150x1225px.jpg',
    path2: 'campaign/banners/pringles_220x1225px.jpg',
    url: 'https://popandplay.ro/promotie_nationala.php',
  },
  {
    name: 'DURACELL',
    path: 'campaign/banners/duracell_1225x150px.png',
    path2: 'campaign/banners/duracell_1225x220px.png',
    url: 'https://www.duracell.ro/',
  },
  {
    name: 'ORGANIC INDIA',
    path: 'campaign/banners/organicindia_1225x150px.png',
    path2: 'campaign/banners/organicindia_1225x220px.jpg',
    url: 'https://organicindia.ro/',
  },
  {
    name: 'Tenis10',
    path: 'campaign/banners/tenis10_1225x150px.jpg',
    path2: 'campaign/banners/tenis10_1225x220px.jpg',
    url: 'https://www.tenis10.ro/ro/',
  },
  {
    name: 'Lindab',
    path: 'campaign/banners/lindab_1225x150px.jpg',
    path2: 'campaign/banners/lindab_1225x220px.png',
    url: 'https://www.lindabacoperisuri.ro/',
  },
];

const DuracellAndPringles = ({ section = 'homepage' }) => {
  const [banner, setBanner] = useState(null);
  let classNames;

  useEffect(() => {
    setBanner(banners[Math.floor(Math.random() * banners.length)]);
  }, [section]);

  if (!banner) return null;

  switch (section) {
  case 'homepage':
    classNames = "mb40";
    break;
  case 'events':
    classNames = "p0 mb20";
    break;
  case 'eventInfo':
    classNames = "p0 mt20";
    break;
  default:
    classNames = "";
  }

  return (
    <Container className={classNames}>
      <a href={banner.url} target="_blank" rel="noopener noreferrer">
        <img
          src={LoadImage(section === "events" ? banner.path2 : banner.path)}
          alt={banner.name}
          className="img-responsive w100"
        />
      </a>
    </Container>
  );
};

export default DuracellAndPringles;
