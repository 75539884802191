import React, { useEffect, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';

import { fetchLanguages, setCurrentLanguage } from '../../../components/Layout/reducers/languages';
import { getAccountInfo } from '../../../components/Layout/reducers/session';

import { store as socketStore } from '../../../components/Socket/store';

import { Container } from './styles';
import ContentLoader from '../../../components/ContentLoader';
import LoadImage from '../../../components/common/LoadImage';

import {
  P,
  H2,
  Button,
  Contain,
} from '../../../components/Collection';
import Logout from '../../../components/Logout';
import Select from '../../../components/Form/Select';
import Input from '../../../components/Form/Input';

import { updateAccount } from '../../MyAccount/CompleteProfile/actions';
import mixpanel from '../../../mixpanel';

const Welcome = () => {
  const history = useHistory();
  const { accountInfo, currentStep, status } = useSelector(state => state.session);
  const availableLanguages = useSelector(state => state.languages);
  const [languages, setLanguages] = useState(availableLanguages.currentLanguage);
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const globalState = useContext(socketStore);

  const {
    state: {
      socket: { socketClientId, socket },
    },
  } = globalState;

  const formSchema = Yup.object().shape({
    subscribed: Yup.boolean(),
  });

  const handleChangeLanguage = (selectedOption) => {
    let url = history.location.pathname + history.location.search;

    if (selectedOption?.value !== i18next.language) {
      if (i18next.options.fallbackLng.includes(selectedOption?.value)) {
        if (
          history.location.pathname.includes(
            `/${i18next.options.fallbackLng.toString()}`,
          )
        ) {
          url = history.location.pathname.replace(
            `/${i18next.options.fallbackLng.toString()}`,
            '',
          );
        } else {
          url = history.location.pathname.replace(`/${i18next.language}`, '');
        }
      } else {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : null;

        if (!languageCode) {
          url = `/${selectedOption?.value}${history.location.pathname}`; // add language in url
        } else {
          url = history.location.pathname.replace(
            `/${languageCode}`,
            `/${selectedOption?.value}`,
          ); // update language
        }
      }
    }

    history.replace(url, { ...history.location.state });

    i18next.changeLanguage(selectedOption?.value).then(() => {
      dispatch(setCurrentLanguage(selectedOption));
    });
  };

  useEffect(() => {
    if (accountInfo.role !== 'player' || accountInfo.status !== 'activated' || currentStep === undefined) {
      return history.push('/dashboard');
    }

    if (currentStep) {
      if (currentStep.step !== 'profileDetails') {
        return history.push(currentStep.path);
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (availableLanguages.status === 'idle') {
      dispatch(fetchLanguages());
    }

    if (availableLanguages.status === 'succeeded') {
      setLanguages(
        (availableLanguages.data || []).map((language) => ({
          value: language.code,
          label: language.name,
        })),
      );

      if (isEmpty(availableLanguages?.currentLanguage?.value) && !isEmpty(availableLanguages.data)) {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : 'en';
        const [lang] = availableLanguages.data
          .filter((language) => language.code === languageCode)
          .map((language) => ({ value: language.code, label: language.name }));
        handleChangeLanguage(lang);
      }
    }
  }, [availableLanguages, dispatch]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        dispatch(getAccountInfo());
        history.push(currentStep.path);
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => {
        socket.removeAllListeners('accounts-response');
      };
    }
  }, [socketClientId, currentStep]);

  const logLanguageSelection = (values) => {
    switch (accountInfo?.registrationMethod) {
    case 'google_account':
      mixpanel.people.set({
        sp_membership: 'free',
        sp_registration_method: 'Google Account',
      });
      mixpanel.track('FE Created account', {
        sp_created_account_method: 'Google Account',
        sp_created_account_newsletter: values?.communicationPreferences?.newsletter ? 'on' : 'off',
      });
      mixpanel.track('FE Activated account');
      break;
    case 'facebook':
      mixpanel.people.set({
        sp_membership: 'free',
        sp_registration_method: 'Facebook Account',
      });
      mixpanel.track('FE Created account', {
        sp_created_account_method: 'Facebook Account',
        sp_created_account_newsletter: values?.communicationPreferences?.newsletter ? 'on' : 'off',
      });
      mixpanel.track('FE Activated account');
      break;
    default:
      mixpanel.identify(accountInfo?.mixpanelId);
      if (localStorage.getItem('mixpanelIntegration') === 'Account activated') {
        mixpanel.track('FE Activated account');
        localStorage.removeItem('mixpanelIntegration');
      }
    }
    mixpanel.people.set({
      sp_language: availableLanguages?.currentLanguage?.label,
    });
    mixpanel.track('Select language', {
      sp_selected_language: availableLanguages?.currentLanguage?.label,
    });
  };

  if (status === 'loading' || isEmpty(currentStep)) {
    return (<ContentLoader title={false} />);
  }

  return (
    <Container>
      <Contain
        align="center"
        direction="column"
        justify="center"
        margin="0 0 30px 0"
        maxWidth="350px"
      >
        <img src={LoadImage('welcome/icon-star.svg')} alt="" />

        <P bold>
          <Trans
            ns="dashboard"
            i18nKey="welcome.hello"
            values={{ username: accountInfo.firstName }}
          />
        </P>

        <H2 className="text-center mb20">
          <Trans
            ns="dashboard"
            i18nKey="welcome.welcomeToPlatform"
            values={{ site: process.env.REACT_APP_SITE_NAME }}
          />
        </H2>
        <P maxWidth="315px">
          <Trans ns="dashboard" i18nKey="welcome.welcomeText">
            Before starting, help us complete your profile for the
            best experience. It only takes a minute!
          </Trans>
        </P>

        <P className="mt40">
          <Trans ns="dashboard" i18nKey="choseLanguage">Select your preferred language to continue</Trans>
        </P>
        <Formik
          initialValues={{
            communicationPreferences: {
              newsletter: accountInfo?.communicationPreferences?.newsletter,
            },
            language: accountInfo?.language ?? 'en',
          }}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const payload = {
              communicationPreferences: {
                ...accountInfo?.communicationPreferences,
                newsletter: values.communicationPreferences.newsletter,
              },
              language: availableLanguages.currentLanguage.value,
            };

            if (['google_account', 'facebook'].includes(accountInfo?.registrationMethod)) {
              payload.mixpanelId = mixpanel.get_distinct_id();
            }

            logLanguageSelection(values);

            await updateAccount({
              id: accountInfo.id,
              payload,
              addToast,
              setSubmitting,
              socketClientId,
              dispatch,
              history,
              t: commonT,
            });
          }}
        >
          {({ values, isSubmitting, handleChange }) => (
            <Form>
              <Select
                name="language"
                placeholder={commonT(`languages.${availableLanguages.currentLanguage.value}`)}
                value={commonT(availableLanguages.currentLanguage)}
                onChange={handleChangeLanguage}
                options={languages}
                lowPlaceholder
                isDisabled={status === 'loading' || isEmpty(languages)}
                isSearchable={false}
                label={t('profileSettings.languageCommunication.language')}
              />

              {['google_account', 'facebook'].includes(accountInfo?.registrationMethod) && (
                <Input
                  name="communicationPreferences[newsletter]"
                  onChange={handleChange}
                  checked={values.communicationPreferences.newsletter}
                  type="checkbox"
                  label={(
                    <Trans ns="register" i18nKey="newsletterSubscribe">
                      I subscribe to
                      {' '}
                      {process.env.REACT_APP_SITE_NAME}
                      &apos;s newsletter
                    </Trans>
                  )}
                  id="subscribeToNewsletter"
                />
              )}

              <Button width="100%" black type="submit" disabled={isSubmitting}>
                <Trans ns="dashboard" i18nKey="welcome.startBtn">Let&apos;s Start</Trans>
              </Button>
            </Form>
          )}
        </Formik>
        <Logout bottom="20px" />
      </Contain>
    </Container>
  );
};

export default Welcome;
