export default {
  home: 'Начална страница',
  login: 'Вход',
  register: 'Регистрация',
  dashboard: 'Dashboard',
  findEvent: 'събития',
  friendlyMatches: 'Приятелски Мачове',
  bookCourt: 'съдилища',
  forPlayers: 'За Играчи',
  forClubs: 'За Тенис клубове',
  blog: 'Блог',
  help: 'Помощ',
  findAPlayer: 'Играчи',
  leaderboards: 'Ранглисти',
  lookForAnything: 'Търсете каквото и да било...',
  notifications: 'Известия',
  membershipType: "<0>{{t(accountInfo?.membership?.plan) || 'Free'}}</0> Account",
  membership: 'Абонаменти',
  comingSoon: 'Скоро',
  gameLevel: 'Ниво на игра',
  myProfile: 'Моя профил',
  free: 'Безплатен акаунт',
  summer: 'Летен акаунт',
  premium: 'Премиум акаунт',

  menu: {
    friends: 'приятели',
    myEvents: 'Събития',
    bookings: 'Резервации',
    mySettings: 'Настройки',
    myOrders: 'Поръчки',
    virtualWallet: 'Виртуален Портфейл',
    plans: 'Абонамент Sportya',
    logout: 'Излизане',
  },
};
