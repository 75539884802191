const getCountryIsoCode = (country) => {
  let isoCodes;
  switch (country) {
  case 'Romania':
    isoCodes = {
      currency: 'RON',
      countryCode: 'RO',
      language: 'ro',
      timezone: 'Europe/Bucharest',
      vat: 0,
    };
    break;
  case 'Bulgaria':
    isoCodes = {
      currency: 'BGN',
      countryCode: 'BG',
      language: 'bg',
      timezone: 'Europe/Sofia',
      vat: 20,
    };
    break;
  case 'Moldova':
    isoCodes = {
      currency: 'RON',
      countryCode: 'MD',
      language: 'ro',
      timezone: 'Europe/Chisinau',
      vat: 0,
    };
    break;
  case 'Hungary':
    isoCodes = {
      currency: 'HUF',
      countryCode: 'HU',
      language: 'hu',
      timezone: 'Europe/Budapest',
      vat: 27,
    };
    break;
  case 'Bahrain':
    isoCodes = {
      currency: 'BHD',
      countryCode: 'BH',
      language: 'en',
      timezone: 'Asia/Bahrain',
      vat: 10,
    };
    break;
  case 'Armenia':
    isoCodes = {
      currency: 'AMD',
      countryCode: 'AM',
      language: 'am',
      timezone: 'Asia/Yerevan',
      vat: 20,
    };
    break;
  case 'Czechia':
    isoCodes = {
      currency: 'CZK',
      countryCode: 'CZ',
      language: 'cz',
      timezone: 'Europe/Prague',
      vat: 21,
    };
    break;
  default:
    isoCodes = {
      currency: 'RON',
      countryCode: 'RO',
      language: 'ro',
      vat: 0,
    };
  }

  return isoCodes;
};

export default getCountryIsoCode;
