import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import H1 from '../../components/H1';
import H4 from '../../components/H4';
import P from '../../components/Paragraph';

import LoadImage from '../../components/common/LoadImage';

import Menu from './components/menu';
import { Container } from './styles';

const RulesRegulations = () => {
  const { t } = useTranslation('rules');
  const history = useHistory();

  const code = history.location.pathname.split('/')[1];
  const languageCode = code && code.length === 2 ? code : null;

  const menuItems = [];
  const sections = [];
  const sectionsLengths = [10, 1, 15, 3, 1, 6, 0, 1, 1, 1, 2, 0];

  for (let j = 0; j < sectionsLengths.length; j++) {
    const sectionNr = j + 1;
    sections[j] = [];

    for (let i = 1; i <= sectionsLengths[j]; i++) {
      if (j === 3) {
        sections[j].push(
          <div key={i}>
            <P large className="mb40">
              {t(`regulament_general.section${sectionNr}.p${i}`)}
            </P>
            {i !== sectionsLengths[j] && (
              <img
                src={LoadImage(`rules/${languageCode}/section${sectionNr}-img${i}.png`)}
                className="img-responsive"
                alt=""
              />
            )}
          </div>,
        );
      } else if ([5, 10].includes(j)) {
        sections[j].push(
          <div key={i}>
            <P large bold className="mb40">
              {t(`regulament_general.section${sectionNr}.subtitle${i}`)}
            </P>
            <P large className="mb40">
              {t(`regulament_general.section${sectionNr}.p${i}`)}
            </P>
          </div>,
        );
      } else {
        sections[j].push(
          <div key={i}>
            <P large className="mb40">
              {t(`regulament_general.section${sectionNr}.p${i}`)}
            </P>
            <P large bold className="mb40">
              {t(`regulament_general.section${sectionNr}.subtitle${i}`)}
            </P>
          </div>,
        );
      }
    }

    if (j === 3) {
      sections[j].push(
        <div key={sectionsLengths[j] + 1}>
          <P large bold className="mb40">
            {t(`regulament_general.section${sectionNr}.subtitle1`)}
          </P>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 1
              }`,
            )}
          </P>
        </div>,
      );
    } else if (j === 7) {
      sections[j].push(
        <div key={sectionsLengths[j] + 1}>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 1
              }`,
            )}
          </P>
          {languageCode
            && (
              <img
                src={LoadImage(`rules/${languageCode}/section${sectionNr}-img1.png`)}
                className="img-responsive"
                alt=""
              />
            )}
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 2
              }`,
            )}
          </P>
          <P large bold className="mb40">
            {t(
              `regulament_general.section${sectionNr}.subtitle${sectionsLengths[j] + 1
              }`,
            )}
          </P>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 3
              }`,
            )}
          </P>
          {languageCode
            && (
              <img
                src={LoadImage(`rules/${languageCode}/section${sectionNr}-img2.png`)}
                className="img-responsive"
                alt=""
              />
            )}
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 4
              }`,
            )}
          </P>
          <P large bold className="mb40">
            {t(
              `regulament_general.section${sectionNr}.subtitle${sectionsLengths[j] + 2
              }`,
            )}
          </P>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 5
              }`,
            )}
          </P>
          {languageCode
            && (
              <>
                <img
                  src={LoadImage(`rules/${languageCode}/section${sectionNr}-img3.png`)}
                  className="img-responsive"
                  alt=""
                />
                <img
                  src={LoadImage(`rules/${languageCode}/section${sectionNr}-img4.png`)}
                  className="img-responsive"
                  alt=""
                />
                <img
                  src={LoadImage(`rules/${languageCode}/section${sectionNr}-img5.png`)}
                  className="img-responsive"
                  alt=""
                />
              </>
            )}
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 6
              }`,
            )}
          </P>
          {languageCode
            && (
              <img
                src={LoadImage(`rules/${languageCode}/section${sectionNr}-img6.png`)}
                className="img-responsive"
                alt=""
              />
            )}
          <P large className="mb40">
            {t('regulament_general.section8.p9')}
          </P>
          <P large bold className="mb40">
            {t(
              `regulament_general.section${sectionNr}.subtitle${sectionsLengths[j] + 3
              }`,
            )}
          </P>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 7
              }`,
            )}
          </P>
        </div>,
      );
    } else {
      sections[j].push(
        <div key={sectionsLengths[j] + 1}>
          <P large className="mb40">
            {t(
              `regulament_general.section${sectionNr}.p${sectionsLengths[j] + 1
              }`,
            )}
          </P>
        </div>,
      );
    }

    menuItems.push({
      id: `section${sectionNr}`,
      title: t(`regulament_general.title${sectionNr}`),
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle5')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-3 position-relative">
          <Menu menuItems={menuItems} />
        </div>
        <div className="col-sm-9">
          <H1 className="mb30">{t('regulament_general.mainTitle')}</H1>
          {sections.map((item, key) => (
            <div key={key} id={`section${key + 1}`}>
              <H4 className="mb40">
                {t(`regulament_general.title${ key + 1}`)}
              </H4>
              {item}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default RulesRegulations;
