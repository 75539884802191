import {
  get,
} from '../../components/common/http';

export const getFriendlyMatchByBookingId = async (bookingId) => {
  try {
    const response = await get(`/friendly-matches/${bookingId}/booking-id`);
    return response?.data?.data;
  } catch (error) {
    return null;
  }
};
