import React from 'react';
import styled from '@emotion/styled';

import { Trans, useTranslation } from 'react-i18next';
import {
  Column,
  Event,
  EventBody,
  EventFooter,
  EventHeader,
  EventTotalWonPoints,
  FriendlyMatchEventName,
  TableHeader,
} from '../styles';

import rankBeforeEvent from '../../../../../components/common/rankBeforeEvent';

import { Contain, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import { formatMatchId } from '../../../../FriendlyMatches/helpers';
import FriendlyEventMetaComponent from './FriendlyEventMeta';
import FriendlyMatchesComponent from './FriendlyMatches';

export const MatchType = styled.img({
  width: '48px',
  height: '48px',
  margin: '0 5px 0 0',
  '@media (max-width: 1270px)': {
    display: 'none',
  },
});

export const MatchId = styled.small({
  color: '#10242C',
  opacity: '0.4',
});

const ActivityFriendlyMatch = ({ event, user }) => {
  const { userId } = user;
  const { t: commonT } = useTranslation('common');
  const { t: friendlyMatchesT } = useTranslation('friendlyMatches');

  const {
    sportType,
    gameType,
    date,
    level,
    category,
  } = event;

  const {
    ranked,
    format,
    location,
    matchId,
  } = [event.matches];

  return (
    <Event>
      <EventHeader doubles={gameType === 'doubles'} friendlyMatch>
        <Contain align="center">
          <MatchType src={LoadImage(`friendly-matches/matches/${gameType}-match.svg`)} alt="" />
          <Contain direction="column">
            <P xSmall bold>
              <Trans ns="common" i18nKey={`sports.${sportType}`}>{sportType}</Trans>
            </P>
            <Contain>
              <FriendlyMatchEventName>
                {friendlyMatchesT(`${gameType}FriendlyMatch`)}
              </FriendlyMatchEventName>
              <Contain margin="10px 0 0 5px">
                <MatchId>{formatMatchId(matchId)}</MatchId>
              </Contain>
            </Contain>
            <FriendlyEventMetaComponent {...{
              category,
              date,
              level,
              format,
              location,
              ranked,
            }}
            />
          </Contain>
        </Contain>

      </EventHeader>
      <EventBody>
        <TableHeader>
          <Column maxWidth={800}>
            <P xSmall>
              <Trans ns="player" i18nKey="opponent">
                Opponent
              </Trans>
            </P>
          </Column>

          <Column maxWidth={300} flex justify="center">
            <P xSmall>
              <Trans ns="player" i18nKey="score">
                Score
              </Trans>
            </P>
          </Column>

          <Column maxWidth={20} flex justify="center" xMargin="0 0 0 auto">
            <P xSmall>
              <Trans ns="player" i18nKey="wL">
                W/L
              </Trans>
            </P>
          </Column>
        </TableHeader>
        <FriendlyMatchesComponent match={event.matches[0]} gameType={gameType} />
      </EventBody>
      <EventFooter>
        {rankBeforeEvent(event, userId, user, commonT)}
        {event?.totalWonPoints > 0 && (
          <EventTotalWonPoints xSmall bold>+ {event.totalWonPoints} pts.</EventTotalWonPoints>
        )}
      </EventFooter>
    </Event>
  );
};

export default ActivityFriendlyMatch;
