import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { get } from '../../components/common/http';

// Actions
export const fetchConversations = createAsyncThunk('fetchConversations', async () => {
  const { data: { data } } = await get('/chat/conversations');
  return data;
});

export const fetchConversation = createAsyncThunk('fetchConversation', async ({ conversationId, options }) => {
  if (!options) {
    Object.assign(options, {
      page: 1,
      limit: 50,
    });
  }

  const filterParams = new URLSearchParams({ ...options }).toString();
  const { data: { data } } = await get(`/chat/conversations/${conversationId}?${filterParams}`);
  if (options.page > 1) {
    return {
      data,
      pushContent: true,
      options,
    };
  }

  return data;
});

// Actions
export const fetchNewMessages = createAsyncThunk('settings/fetchNewMessages', async () => {
  const { data: { data } } = await get('/chat/new-messages');
  return data;
});

// Slice
const conversations = createSlice({
  name: 'conversations',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setNewMessages: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].newMessages += 1;
      }
    },
    readAllMessages: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].newMessages = 0;
      }
    },
    removeConversationFromList: (state, action) => {
      state.data = state.data.filter(({ id }) => id !== action.payload);
    },
    addConversationToList: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.id);
      if (conversationIndex === -1) {
        state.data = [action.payload, ...state.data];
      }
    },
    setMuteConversation: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.conversationId);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].mutedBy = [...state.data[conversationIndex].mutedBy, action.payload.userId];
      }
    },
    setUnmuteConversation: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.conversationId);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].mutedBy = state.data[conversationIndex].mutedBy.filter(
          (userId) => userId !== Number(action.payload.userId),
        );
      }
    },
    updateLastMessage: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.conversationId);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].lastMessage = action.payload.lastMessage;
      }

      state.data = [...state.data].sort(
        (a, b) => (b.lastMessage?.createdAt || b.createdAt)
          .localeCompare(a.lastMessage?.createdAt || a.createdAt),
      );
    },
    updateMembersList: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.conversationId);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].members = [...state.data[conversationIndex].members, ...action.payload.members];
      }
    },
    setLeftConversation: (state, action) => {
      const conversationIndex = state.data.findIndex(({ id }) => id === action.payload.conversationId);
      if (conversationIndex !== -1) {
        state.data[conversationIndex].leftBy = [...state.data[conversationIndex].leftBy, action.payload.userId];
      }
    },
  },
  extraReducers: {
    [fetchConversations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchConversations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchConversations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const messages = createSlice({
  name: 'messages',
  initialState: {
    data: [],
    allMessagesLoaded: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    pushMessageToConversation: (state, action) => {
      if (!state.data.length || state.data?.[0]?.conversationId === action.payload.conversationId) {
        state.data = [...state.data, action.payload];
      }
    },
    updateMessageInfo: (state, action) => {
      const messageId = action.payload.tempId ?? action.payload.id;
      const messageIndex = state.data.findIndex(({ id }) => id === messageId);
      if (messageIndex !== -1) {
        state.data[messageIndex] = { ...state.data[messageIndex], ...action.payload };
      }
    },

    setAllMessagesLoaded: (state, action) => {
      state.allMessagesLoaded = Boolean(action.payload);
    },
  },
  extraReducers: {
    [fetchConversation.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchConversation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action.payload.pushContent) {
        state.data = [...action.payload.data.reverse(), ...state.data];
        if (action.payload?.data.length === 0 || action.payload?.data.length < action.payload.options.limit) {
          state.allMessagesLoaded = true;
        }
      } else {
        state.data = action.payload;
      }
    },
    [fetchConversation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const newMessages = createSlice({
  name: 'newMessages',
  initialState: {
    data: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    seenMessages: (state, action) => {
      state.data -= Number(action.payload);
      if (state.data < 0) {
        state.data = 0;
      }
    },
    setNewMessage: (state) => {
      state.data += 1;
    },
  },
  extraReducers: {
    [fetchNewMessages.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchNewMessages.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchNewMessages.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  setNewMessages,
  readAllMessages,
  removeConversationFromList,
  addConversationToList,
  setMuteConversation,
  setUnmuteConversation,
  updateLastMessage,
  updateMembersList,
  setLeftConversation,
} = conversations.actions;

export const {
  pushMessageToConversation,
  updateMessageInfo,
  setAllMessagesLoaded,
} = messages.actions;

export const {
  seenMessages,
  setNewMessage,
} = newMessages.actions;

const reducer = combineReducers({
  list: conversations.reducer,
  info: messages.reducer,
  newMessages: newMessages.reducer,
});

export default reducer;
