import React from 'react';
import { Trans } from 'react-i18next';

import mixpanel from '../../../../../mixpanel';

import { ButtonRightArrowIcon, SearchForAPlayerBanner } from '../../styles';

import { Button, H3 } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import LinkWrapper from '../../../../../components/Link';

const SearchForAPlayer = () => (
  <SearchForAPlayerBanner>
    <H3 margin="0 0 30px 0">
      <Trans ns="friendlyMatches" i18nKey="searchSpecificPlayer">Want to search for a specific player?</Trans>
    </H3>
    <Button width="max-content" padding="0 14px 0 34px">
      <LinkWrapper to="/find-player" onClick={() => mixpanel.track('Click FIND A PLAYER from FRIENDLY MATCHES')}>
        <Trans ns="friendlyMatches" i18nKey="findAPlayer">Find a Player</Trans>
        <ButtonRightArrowIcon
          src={LoadImage('friendly-matches/start-match/button-right-arrow.svg')}
          alt="Find a Player"
        />
      </LinkWrapper>
    </Button>
  </SearchForAPlayerBanner>
);

export default SearchForAPlayer;
