import React from 'react';

import { useSelector } from 'react-redux';
import { ResultsStyle } from './styles';

import ResultsPhase from '../resultsPhase';
import ResultsCard from '../ResultsCard';
import ResultsHeader from '../ResultsHeader';

const index = () => {
  let key;
  const { data } = useSelector(state => state.events?.matches);

  const league = !!(data?.length && data[0]?.competition?.drawType === 'groups');
  const duo = data[0]?.competition?.gameType === 'doubles';

  // format data for groups case
  const formattedGroups = [];
  // format data for elims case
  const formattedElims = [];
  if (league) {
    const groupsData = data?.reduce(
      (
        ac,
        {
          competition,
          player,
          opponent,
          playerPartner,
          opponentPartner,
          date,
          courtDetails,
          winner,
        },
      ) => {
        // eslint-disable-next-line no-param-reassign
        ac[competition.group] = [
          ...(ac[competition.group] || []),
          {
            player,
            opponent,
            playerPartner,
            opponentPartner,
            date,
            courtDetails,
            winner,
          },
        ];
        return ac;
      },
      {},
    );
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (key in groupsData) {
      formattedGroups.push(groupsData[key]);
    }
  } else {
    const groupMatchesByStages = data.reduce(
      (
        ac,
        {
          competition,
          player,
          opponent,
          playerPartner,
          opponentPartner,
          date,
          courtDetails,
          winner,

        },
      ) => {
        // eslint-disable-next-line no-param-reassign
        ac[competition.stage] = [
          ...(ac[competition.stage] || []),
          {
            competition,
            player,
            opponent,
            playerPartner,
            opponentPartner,
            date,
            courtDetails,
            winner,
          },
        ];
        return ac;
      },
      {},
    );

    // eslint-disable-next-line no-restricted-syntax
    for (key in groupMatchesByStages) {
      if (key !== '0') {
        formattedElims.push(groupMatchesByStages[key]);
      }
    }
  }

  const mapData = formattedGroups?.length > 0 ? formattedGroups : formattedElims;

  if (formattedElims?.length > 0) {
    formattedElims.reverse();
  }

  return (
    <ResultsStyle league={league}>
      <ResultsHeader league={league} />
      {mapData?.map((value, idx) => (
        <div key={idx} className="resultsWrapp">
          <ResultsPhase index={idx} league={league} phase={value.phase} date={value.date} court={value.court} />
          <div className="results">
            <ResultsCard
              final={idx === 0}
              duo={duo}
              league={league}
              groups={formattedGroups}
              elimns={formattedElims}
              data={value}
            />
            {idx + 1 === formattedGroups.length ? '' : <hr />}
          </div>
        </div>
      ))}
    </ResultsStyle>
  );
};

export default index;
