import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import LoadImage from '../common/LoadImage';
import { H3, P } from '../Collection';

import {
  Container, Logo, Image, Contain, ImageContainer,
} from './styles';

const Maintenance = () => {
  const history = useHistory();

  if (process.env.REACT_APP_UNDER_MAINTENANCE === 'false') {
    history.push('/');
  }

  return (
    <Container>
      {/* <Back>
        <img src={LoadImage('white-arrow-right.svg')} />
            <P color={'white'} medium>
                Back
            </P>
        </Back> */}
      <Helmet>
        <title>Maintenance | Sportya</title>
      </Helmet>
      <Logo src={LoadImage('logo-white.svg')} />
      <Contain>
        <ImageContainer>
          <Image src={LoadImage('maintenance/Under Maintenance.gif')} />
        </ImageContainer>
        <H3 color="white" margin="-40px 0 13px 0">
          Under Maintenance
        </H3>
        <P color="white" margin="0 0 13px 0" maxWidth="800px" textAlign="center" medium>
          Sportya is currently under maintenance.
          Please bear with us while we tweak everything so that things look good and work as intended.
          The website will be back online shortly.
        </P>
        <P color="white" textAlign="center" medium>
          Thank you for your patience,
          {' '}
          <br />
          Team Sportya
        </P>
      </Contain>
    </Container>
  );
};

export default Maintenance;
