import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { groupBy, isEmpty } from 'lodash';

import {
  Container,
  Tab,
  TabContainer,
  TabContent,
  TabsContainer,
} from './styles';
import Competition from '../../Events/EventDetails/components/competition';
import {
  Button,
  Contain,
  H1,
  H4,
} from '../../../components/Collection';
import Spinner from '../../../components/LoadingSpinner';
import Event from '../../../components/Event';
import Pagination from '../../../components/Pagination';
import { PaginationWrapper } from '../../Events/styles';
import { fetchMyEvents } from './reducers';
import mixpanel from '../../../mixpanel';
import { Paragraph } from '../../../components';
import LoadImage from '../../../components/common/LoadImage';

const tabList = [
  { label: 'Joined', value: 'joined', fallback: 'noJoined' },
  { label: 'Looking for a Partner', value: 'lookingForPartners', fallback: 'noLfp' },
  { label: 'Followed', value: 'followed', fallback: 'noFollowed' },
  { label: 'Closed', value: 'closed', fallback: 'noClosed' },
  { label: 'Withdrawals', value: 'withdrawals', fallback: 'noWithdrawals' },
];

const initialOptions = {
  page: 1,
  limit: 10,
};

const MyEvents = () => {
  const history = useHistory();
  const fetchedData = useSelector((state) => state.dashboard.myEvents);
  const { accountInfo: { id } = {} } = useSelector((state) => state.session);

  const listRef = useRef(null);
  const [options, setOptions] = useState(initialOptions);
  const [activeTab, setActiveTab] = useState({ name: 'joined', fallback: 'noJoined' });
  const [currentPage, setCurrentPage] = useState(0);

  const active = data => activeTab?.name === data;
  const dispatch = useDispatch();

  const { status, data: { results, totalPages } = [] } = fetchedData[activeTab?.name] || {};

  const isFollowingTabActive = activeTab?.name === 'followed';

  useEffect(() => {
    if (isEmpty(results) && status === 'idle') {
      return dispatch(fetchMyEvents({ id, activeTab: activeTab?.name, options }));
    }
    return null;
  }, [status, dispatch]);

  const triggerScroll = () => {
    if (listRef?.current) {
      return listRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    return null;
  };

  const handlePagination = page => {
    const { selected } = page || {};
    if (currentPage !== selected) {
      const newOptions = {
        ...options,
        page: selected + 1,
      };

      setOptions(newOptions);
      setCurrentPage(selected);

      dispatch(fetchMyEvents({ id, activeTab: activeTab?.name, options: newOptions }));
      return window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    return null;
  };

  const groupedCompetitions = groupBy(results, 'sportType');
  const sportsByCompetitions = Object.keys(groupedCompetitions);

  return (
    <Container innerRef={listRef}>
      <H1 margin="20px 0 40px 0">
        <Trans ns="myEvents" i18nKey="title">
          My Events
        </Trans>
      </H1>

      <TabsContainer>
        {tabList?.map((tab, key) => (
          <TabContainer
            onClick={() => {
              setActiveTab({ name: tab.value, fallback: tab.fallback });
              if (tab.value === 'lookingForPartners') {
                mixpanel.track('Click on [Looking for Partners] tab in My Events');
              }
            }}
            active={active(tab.value)}
            key={key}
          >
            <Tab bold>
              <Trans ns="myEvents" i18nKey={tab.value}>
                {tab.value}
              </Trans>
            </Tab>
          </TabContainer>
        ))}
      </TabsContainer>
      <TabContent>
        {fetchedData.generalStatus === 'loading' && <Spinner height="350px" />}

        {sportsByCompetitions.map((sport) => (
          <div key={sport} className="mb60">
            <H4 className="mb20">
              <Trans ns="common" i18nKey={`sports.${sport}`}>{sport}</Trans>
            </H4>
            {groupedCompetitions[sport]?.map((event, key) => (
              <React.Fragment key={key}>
                {isFollowingTabActive && (<Event event={event} key={key} fullwidth followedEvent />)}
                {!isFollowingTabActive
                  && event?.competitions
                    ?.slice()
                    .sort((a, b) => a.level - b.level)
                    .sort((a, b) => a.gameType < b.gameType)
                    .map((competitionInfo, k) => (
                      <React.Fragment key={key}>
                        <Paragraph large bold className="d-flex space-between">
                          {event?.name}

                          <Paragraph
                            bold
                            className="cursor-pointer d-flex gap-10 hidden-mobile"
                            onClick={() => history.push(`/events/${event._id}`)}
                          >
                            <Trans ns="myEvents" i18nKey="seeEvent">See Event</Trans>
                            <img src={LoadImage("arrow-right.svg")} alt="See events" />
                          </Paragraph>
                        </Paragraph>
                        <Competition
                          key={k}
                          myAccount
                          {...{
                            competitionInfo,
                            eventInfo: event,
                            eventStatus: event?.currentPhase?.status,
                          }}
                          {...(activeTab.name === 'withdrawals' && {
                            noShow: true,
                          })}
                        />
                      </React.Fragment>
                    ))}
              </React.Fragment>
            ))}
          </div>
        ))}

        {isEmpty(groupedCompetitions) && status === 'succeeded' && (
          <Contain
            width="100%"
            height="350px"
            justify="center"
            direction="column"
            align="center"
          >
            <H4 margin="0 0 20px 0" textAlign="center">
              <Trans ns="myEvents" i18nKey={activeTab.fallback}>
                No Joined Competitions
              </Trans>
            </H4>
            <Button
              minWidth="200px"
              padding="15px 48px"
              small
              outline
              onClick={() => history.push('/events')}
            >
              <Link className="slider-link" to="/events">
                <Trans ns="myEvents" i18nKey="findEvent">
                  Find an Event
                </Trans>
              </Link>
            </Button>
          </Contain>
        )}

        <PaginationWrapper onClick={() => triggerScroll()}>
          <Pagination
            pageCount={totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={currentPage}
          />
        </PaginationWrapper>
      </TabContent>
    </Container>
  );
};

export default MyEvents;
