import React from 'react';
import { CardStyle } from './styles';

const index = ({ children }) => (
  <CardStyle>
    {children}
  </CardStyle>
);

export default index;
