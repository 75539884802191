import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Trans } from 'react-i18next';
import ContentLoader from '../../../../components/ContentLoader';
import { Button, H1, Link } from '../../../../components/Collection';
import { SliderWrapper, InnerSlider, SliderContent } from './styles';

import { fetchSlides } from './reducers';
import LoadImage from '../../../../components/common/LoadImage';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  fade: true,
  autoplay: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SliderComponent = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.homepage?.slides);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchSlides());
    }
  }, [status, dispatch]);

  if (status === 'loading') {
    return <ContentLoader />;
  }

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {data?.map((item, key) => (
          <InnerSlider key={key} background={LoadImage(item.image)}>
            <SliderContent>
              <H1 color="white">
                <Trans ns="homepage" i18nKey={`slider.${item.key}`} />
              </H1>
              <Button noBorder width="200px">
                <Link to={`/${item.link}`}>
                  <Trans ns="homepage" i18nKey={`slider.${item.buttonText}`}>Join now</Trans>
                  <img className="ml10" src={LoadImage('right-arrow.svg')} alt="Join Now!" />
                </Link>
              </Button>
            </SliderContent>
          </InnerSlider>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default SliderComponent;
