import styled from '@emotion/styled';
import { H4 } from '../../../../components/Collection';

export const StatsContainer = styled.div(({ extend, isOnDashboard }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: extend ? 'unset' : '394px',
  width: '100%',
  height: 'fit-content',
  padding: 20,
  background: '#EAF6F6',
  borderRadius: '10px',

  ...isOnDashboard ? {
    margin: '0 0 20px 0',
    maxWidth: 'unset',
  } : {
    '@media (max-width: 1100px)': {
      maxWidth: 'unset',
      '&:not(last-of-type)': {
        margin: '0 0 20px 0',
      },
    },
  },

}));

export const StatsH4 = styled(H4)({
  display: 'flex',
  alignItems: 'center',
  '> img': {
    margin: '0 0 0 5px',
  },
});

export const Statistic = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '20px 0',
  justifyContent: 'center',
  borderRadius: '4px',
  background: '#fff',
  width: '100%',
});

export const StatisticContent = styled.div(({ noBorder }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  ...!noBorder && {
    '&:after': {
      position: 'absolute',
      content: '""',
      height: '100%',
      border: '1px solid #EAF6F6',
      right: 0,
    },
  },
}));

export const Container = styled.div(({ hasLoader }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...hasLoader && {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  minHeight: '350px',
  padding: '0 0 50px 0',
  '@media (max-width: 1100px)': {
    ".stats-boxes": {
      flexDirection: 'column',
    },
  },
}));

export const InnerStats = styled.div(({ isOnDashboard }) => ({
  display: 'flex',
  textAlign: 'center',
  width: '100%',
  gap: 20,

  '> div': {
    flexGrow: 1,
  },
  ...isOnDashboard && {
    flexDirection: 'column',
    gap: 0,
  },
}));
