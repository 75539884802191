export default {
  seoTitle: 'Regisztráció | Sportya',
  tosAgree: 'Elfogadom a <1>{{ process.env.REACT_APP_SITE_NAME }}</1> <3>Általános Szerződési Feltételek</3> és <5>Adatvédelmi irányelvek</5> című dokumentumait, amely elfogadás tartalmazza a hozzájárulásomat ahhoz, hogy a <1>{{ process.env.REACT_APP_SITE_NAME }}</1> szolgáltatásainak használatával kapcsolatban kapcsolatba lépjenek velem, beleértve, de nem kizárólagosan az eseményekre való regisztrációt és az azokon való részvételt, a barátságos mérkőzések kezelését és bármely más belső kommunikációt.',
  ageAgree: 'A regisztrációval megerősítem, hogy legalább <1>15 éves vagyok</1>',
  createAccount: 'Fiók létrehozása',
  alreadyHaveAnAccount: 'Már van fiókod?',
  signupWith: 'Vagy regisztrálj a következővel:',
  newsletterSubscribe: 'Feliratkozom a <1>{{ process.env.REACT_APP_SITE_NAME }}</1> hírlevelére, amely információkat tartalmaz a <1>{{ process.env.REACT_APP_SITE_NAME }}</1> eseményeiről és az egyéb játéklehetőségekről a régiómban, az új funkciókról, valamint a kiválasztott harmdik felek által szervezett promóciókról és a közeli partnereink / támogatóink által biztosított kedvezményekről',
  title: 'Regisztráció',
  subTitle: 'A Sportya elérhető Románia, Moldova, Bulgária, Bahrein, Magyarország és Csehország játékosai számára.',
  oldUserInfo: `<0>Van már Tenisz Partener fiókod?</0> 
  Nem kell új Sportya-fiókot létrehoznod. Az adataid már átvitelre kerültek, és használhatod a <2>Belépés</2> funkciót a Tenisz Partener fiókod e-mail címével és jelszavával.`,
  countriesComingSoon: 'Új országok hamarosan!',
};
