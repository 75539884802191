import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dateFormat, { i18n } from 'dateformat';

import LoadImage from '../../../../../components/common/LoadImage';
import { EventMetaList } from '../styles';
import { Contain } from '../../../../../components/Collection';

export const RankedText = styled.span({
  fontWeight: 'normal',
});

export const RankedIcon = styled.img({
  width: '16px',
  height: '16px',
  margin: '0 0 0 5px',
});

export const Bullet = styled.span({
  margin: '0 10px',
});

export const EventMetaListItem = styled.li({
  // '&:first-of-type': {
  //   margin: '0 10px 0 0',
  // },
  '&:not(:last-of-type):not(:first-of-type):after': {
    content: "'•'",
    margin: '0 10px',
  },

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  alignItems: 'center',

  '> a': {
    color: '#78ADAB',
  },
});

const FriendlyEventMetaComponent = ({
  date,
  location,
  ranked,
}) => {
  const { t: friendlyMatchesT } = useTranslation('friendlyMatches');
  const { t: commonT } = useTranslation('common');

  i18n.dayNames = [
    commonT('Sun'),
    commonT('Mon'),
    commonT('Tue'),
    commonT('Wed'),
    commonT('Thu'),
    commonT('Fri'),
    commonT('Sat'),
  ];

  i18n.monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    commonT('January'),
    commonT('February'),
    commonT('March'),
    commonT('April'),
    commonT('May'),
    commonT('June'),
    commonT('July'),
    commonT('August'),
    commonT('September'),
    commonT('October'),
    commonT('November'),
    commonT('December'),
  ];

  const formattedDate = dateFormat(date, 'd mmmm (ddd), HH:MM');

  return (
    <EventMetaList>
      <EventMetaListItem>
        { ranked && (
          <Contain align="center">
            <RankedText> {friendlyMatchesT('ranked')} </RankedText>
            <RankedIcon src={LoadImage('ranked.svg')} alt="" />
            <Bullet>•</Bullet>
          </Contain>
        )}
        { !ranked && (
          <Contain>
            <RankedText> {friendlyMatchesT('unranked')} </RankedText>
            <Bullet>•</Bullet>
          </Contain>
        )}
      </EventMetaListItem>

      <EventMetaListItem>
        {location?.clubName ?? location?.name}
      </EventMetaListItem>
      <EventMetaListItem>
        {formattedDate}
      </EventMetaListItem>
    </EventMetaList>
  );
};

export default FriendlyEventMetaComponent;
