export default {
  minutesUntil: 'timp limită {{time}} min',
  hoursUntil: 'timp limită {{time}} h',
  daysUntil: 'timp limită {{time}} zile',
  monthsUntil: 'timp limită {{time}} luni',
  yearsUntil: 'timp limită {{time}} ani',
  minutesAgo: 'cu {{time}} minute în urmă',
  hoursAgo: 'cu {{time}} ore în urmă',
  daysAgo: 'cu {{time}} zile în urmă',
  monthsAgo: 'cu {{time}} luni în urmă',
  yearsAgo: 'cu {{time}} ani în urmă',
  justNow: 'chiar acum',
};
