export default {
  completeProfile: {
    profileDetails: 'Подробности за профила',
    fillProfileDetails: 'Попълнете данните за профила',
    sportyaAvailability: 'Sportya е отворена за играчи от Румъния, Молдова и България. Скоро ще бъдат добавени нови локации',
    year: 'Година',
    month: 'Месец',
    day: 'Ден',
    gender: 'Пол',
    location: 'Местоположение',
    locationNotSet: '<0>Местоположение:</0> Задайте местоположението си',
    userLocationNotSet: '<0>Местоположение:</0> Все още не е зададено',
    age: 'Възраст',
    ageNotSet: '<0>Възраст:</0> Задайте възрастта си',
    userAgeNotSet: '<0>Възраст:</0> Все още не е зададена',
    handNotSet: '<0>Доминираща ръка:</0> Задайте доминиращата си ръка',
    userHandNotSet: '<0>Доминираща ръка:</0> Все още не е зададена',
    searchLocation: 'Търсете местоположение...',
    chooseDominantHand: 'Изберете доминиращата ръка',
    left: 'Лява',
    right: 'Дясна',
    continue: 'Продължи',
    saveAndContinue: 'Запазете и продължете',
    uploadNewPhoto: 'Качете нова снимка',
    removePhoto: 'Изтрийте снимката',
    profileVisibility: 'Видимост на профила',
    fillProfileVisibility: 'Изберете как искате играчите да ви виждат',
    allPlayers: 'Всички играчи',
    allPlayersDescription: 'Профилът ви ще бъде видим за всички играчи и те ще имат възможност да ви изпращат съобщения, да ви добавят като приятел и да ви канят на приятелски мачове.',
    allPlayersSubtitle: 'Препоръчваме тази опция, за да се насладите напълно на изживяването {{siteName}}!',
    onlyFriendsAndTheirFriends: 'Само вашите приятели & техните приятели',
    onlyFriendsAndTheirFriendsDescription: 'Профилът ви ще бъде видим само за играчи, добавени като Приятели и техните приятели. \n \n Всички останали играчи ще виждат ограничена версия на вашия профил (име, идентификационен номер на играча, местоположение, снимка на профила) и ще могат да ви изпращат само съобщения и да ви добавят като приятел.',
    onlyFriends: 'Само вашите приятели',
    onlyFriendsDescription: 'Профилът ви ще бъде напълно видим само за играчи, добавени като Приятели.\n \n Всички останали играчи ще виждат ограничена версия на вашия профил.(име, идентификационен номер на играча, местоположение, снимка на профила) и ще може да ви изпраща само съобщения и да ви добавя като приятел.',
    gameLevel_tennis: 'Ниво на тенис игра',
    gameLevel_padel: 'Ниво на игра с падел',
    gameLevelDescription: 'Когато сме на тенис, искаме да играем възможно най-равностойни мачове. Затова, самооценката на игровия потенциал и организацията на мачове / събития на нива на игра се превърнаха в основата за удовлетворяване на тенис преживяванията, в Sportya.',
    gameLevelList: {
      1: 'Първоначално ще зададете ниво на игра, показващо потенциалът Ви на Сингъл. Това ниво ще бъде зададено като начално ниво и на двойки, след това нивата на игра за Сингъл и Двойки ще се коригират според резултатите от мачовете.',
      2: 'Определянето на началното ниво на игра е важна стъпка и може да окаже голямо влияние върху първите ви мачове и турнири от календара на Sportya. Изберете внимателно нивото на играта и се опитайте да бъдете възможно най-обективни.',
      3: 'Ниво 4 отговаря на начинаещи, докато ниво 9 е най-близко до професионалния тенис.',
      4: 'Нивото на игра се актуализира и променя динамично, в зависимост от резултатите на всеки играч.',
    },
    chooseGameLevel_tennis: 'Изберете нивото на игра',
    chooseGameLevel_padel: 'Изберете нивото на игра',
    seeVideoDemonstration: 'Вижте примерните видеа',
    selectThisLevel: 'Изберете това ниво',
    skipForNow: 'Върни се по-късно',
    level: 'НИВО',
    levelDescription: {
      4: 'Играчът има ентусиазъм, желание да се учи, познания за играта на тенис и има способността да поддържа размяна на няколко топки. Недостатъците му са скоростта на играта, прекъснато/непълно движение при удряне на топката и движение по терена по принцип не му е силната страна.',
      5: 'Играчът разбира и може да се движи по терена, за да създаде предимство в играта, има контрол върху посоката с форхенд и може да поддържа последователна размяна на топки. Недостатъците му са все още ниската скорост на игра, обща несигурност в движенията, които все още често са прекъсвани/непълни. Често се наблюдава липса на решителни удари.',
      6: 'Играчът контролира посоката както с форхенда, така и с бекхенд, може да развие последователно темпо на игра и в много ситуации се наблюдава намерението да изгради точката. Бекхендът, дори и да е контролируем, не е последователен удар, вторият сервис обикновено е с ниска скорост и е лошо пласиран и въпреки създадените игрови възможности липсата на удари все пак е решаваща.',
      7: 'Играчът може да ускорява ударите от форхенд, да има различни удари, да редува ефектите и може да изгради точка върху слабостите на противника. Липсва обаче решимост в ситуации на превъзходство, непредизвикани грешки в ключови точки и липса на агресия.',
      8: 'Играчът владее топспина, има агресивна игра, използва цялата гама от удари и не прави подаръци на противника. Въпреки това, играчът рискува прекомерно в някои случаи и като цяло има неефективен бекхенд в ситуации на натиск.',
      9: 'Играчът използва сервиса като оръжие, от което да се страхуват, има различни удари, редува ефектите, които често са непредвидими, ефективни, решаващи както с форхенда, така и с бекхенд.',
    },
    profilePicture: 'Снимка на профила',
    looksGreat: 'Изглежда добре',
    profilePictureSubtitle: 'Снимките помагат на приятелите ви да ви разпознаят в {{siteName}}',
    uploadYourPhoto: 'Качи снимка',
    availability: 'Възможност за игра',
    availabilityDescription: 'Намерете по-лесно партньори за игра, като определите възможността си за игра',
    completeProfile: 'Пълен профил',
    setAvailability: 'Задайте възможност за игра',
    Mon: 'Понеделник',
    Tue: 'Вторник',
    Wed: 'Сряда',
    Thu: 'Четвъртък',
    Fri: 'Петък',
    Sat: 'Събота',
    Sun: 'Неделя',
    chooseFavoriteSport: "Изберете любимия си спорт",
    chooseFavoriteSportSubtitle: "Това трябва да бъде основният спорт, който играете. Други спортове могат да бъдат добавени по-късно от настройките на акаунта ви.",
  },
  logout: {
    wantToLogout: 'Излизане?',
    logoutParagraph: 'Сигурни ли сте, че искате да излезете?',
    btnCancel: 'Отказ',
    btnLogout: 'Излизане',
  },
  wallet: {
    seoTitle: 'Виртуален портфейл | Sportya',
    virtualWallet: 'Виртуален Портфейл',
    walletInfo: 'Виртуален портфейл Sportya е платежна система, която ви позволява бързо да се регистрирате за турнири и да заплатите за други Sportya услуги. Можете да заредите своя виртуален портфейл и да го използвате по-късно като опция за плащане. Плащанията се валидират без забавяне. Ние не съхраняваме данни за плащане/карта.',
    chooseAmountToTopUp: 'Изберете сумата, с която искате да заредите портфейла си',
    noTransactions: 'Няма транзакции',
    availableFunds: 'Налични средства',
    latestTransactions: 'Последни транзакции',
    transactionId: 'Номер на транзакцията',
    date: 'Дата',
    product: 'Продукти',
    amount: 'Сума',
    topUp: 'Зареди портфейл',
    transactionTypes: {
      topUp: 'Заредете Виртуалния портфейл',
      topUpByAdmin: 'Зареждане на Виртуален портфейл (Административно)',
      subtractByAdmin: 'Актуализиране на Виртуален портфейл (Административно)',
      eventRegistration: 'Регистрация за събитие: <1> {{eventName}} </1>',
      partnerEventRegistration: 'Партньор – Регистриране за събитие: <1> {{eventName}} </1>',
      eventWithdrawal: 'Оттегляне от Събитие: <1>{{ eventName }}</1>',
      planUpdate: 'Надграждане на членство: <1>{{ membershipName }}</1>',
      currencyNotSet: 'Трябва да настроите местоположението си, за да започнете да използвате своя виртуален портфейл и функцията за допълване.',
      balanceAdjustmentByAdmin: 'Промяна на Баланса (Административно)',
      waitingListRefund: 'Автоматично връщане на Списък с изчакващи',
    },
    mobile: {
      id: 'ID',
      date: 'Дата',
      type: 'Вид',
      amount: 'Сума',
    },
  },
  orders: {
    title: 'Моите поръчки',
    subtitle: 'История на Поръчките',
    seoTitle: 'Моите поръчки | Sportya',
    orderList: {
      orderID: 'ID Поръчка',
      dateTime: 'Ден & Час',
      orderType: 'Вид Поръчка',
      amount: 'Сума',
      paymentMethod: 'Метод на Плащане',
      orderDetails: 'Подробности за Поръчката',
      viewDetails: 'Виж подробности',
      invoiceInfo: 'Информация за фактура',
      viewInvoice: 'Вижте фактура',
      invoiceNotAvailable: "Not Available",
      orderSummary: {
        title: 'Подробности за Поръчката',
        seeEvents: 'Вижте Събитието',
        about: 'Записването за турнира е валидирано. Оттук нататък тази поръчка не може да бъде променяна. За допълнителни въпроси, моля свържете се с нас на',
        about2: 'Допълнителна информация и нашата Политика за връщане са достъпни на',
        returnPolicy: 'Условия за връщане',
        summary: 'Резюме',
        bookingFee: 'Такса услуги Sportya',
        sprintenDonation: 'Дарение на Sprinten',
        coupon: 'Купон',
        total: 'Тотално',
        inclTVA: 'С ДДС',
        clubEntryFees: ' Такса за регистрация към клуба',
        howToPayEntryFee: 'Заплаща се директно на организатора, в деня на събитието. Клуб организатор: ',
        data: {
          id: 'ID заявка',
          type: 'Вид Заявка',
          date: 'Дата & Време',
          method: 'Метод на Плащане',
        },
      },
      mobile: {
        ID: 'ID',
        Date: 'Дата: ',
        Type: '<0>Вид:</0>',
        Price: '<0>Сума:</0>',
        Payment: 'Метод на Плащане',
      },
    },
    noOrders: 'Няма предишни заявки',
  },
  notifications: {
    title: 'Известия',
    subtitle: 'Последни Известия',
    seoTitle: 'Известия | Sportya',
    seeDetails: 'Виж подробности',
    noNotifications: 'Няма намерени известия.',
    dropdown: {
      title: 'Известия',
      allNotifications: 'Вижте всички известия',
    },
    topUp: "Балансът на вашия виртуален портфейл е актуализиран <0>Детайли</0>.",
    gameLevelChangedByAdmin: "Нивото на игра е променено от администратор. Новото Ви ниво е {{ gameLevelForSingles }} за Сингъл, {{ gameLevelForDoubles }} за Двойки. <0> Вижте подробности </0>.",
    eventRegistrationBan: "Поради наказания вече няма да можете да се регистрирате за събития. <0> Вижте подробности </0>.",
    penaltyAdministrative: "Точките са премахнати от класирането Ви от администратор. <0> Вижте подробности </0>",
    penaltyLateWithdrawal: "Вашето късно оттегляне от събитието <0>{{ eventName }}</0> е обработено. Вашата такса за регистрация е задържана.",
    penaltyAnnouncedWithdrawal: "Your withdrawal from event <0>{{ eventName }}</0> has been processed and your registration fee was added to your <0>Virtual Wallet</0>.",
    teamWithdrawalByAdmin: "Вашата двойка беше оттеглена от турнира <0>{{ eventName }}</0>. <0>Вижте подробности</0>.",
    playerWithdrawalByAdmin: "Оттеглен сте от турнира <0>{{ eventName }}</0>. <0>Вижте подробности</0>",
    teamWithdrawalFromDoubles: "Твоят партньор <0>{{ partnerName }}</0> оттегли двойката от <0>{{ eventName }}</0>.",
    registrationByAdminOnSLForDoubles: "Записване на двойката Ви в <0>{{ eventName }}</0> в списъка със записалите е успешно. <0>Вижте подробности</0>.",
    registrationByAdminOnSLForSingles: "Записването Ви в <0>{{ eventName }}</0> в списъка със записалите е успешно. <0>Вижте подробности</0>.",
    registrationByAdminOnWLForDoubles: "Записване на двойката Ви в <0>{{ eventName }}</0> в списъка на чакащите е успешно. <0>Вижте подробности</0>.",
    registrationByAdminOnWLForSingles: "Записването Ви в <0>{{ eventName }}</0>  списъка на чакащите е успешно. <0>Вижте подробности</0>.",
    registrationByAdminOnMLForDoubles: "Записване на двойката Ви в<0>{{ eventName }}</0> в основната схема е успешно. <0>Вижте подробности</0>.",
    registrationByAdminOnMLForSingles: "Записването Ви в <0>{{ eventName }}</0> в основната схема е успешно. <0>Вижте подробности</0>.",
    promotionToMLForDoubles: "В резултат на оттеглянето от турнира на двойки, организиран в <0>{{ eventName }}</0>, вашата двойка премина в основната схема. <0>Вижте подробности</0>.",
    promotionToMLForSingles: "В резултат на оттеглянето от турнира на сингъл, организирано в <0>{{ eventName }}</0>, премина в основната схема. <0>Вижте подробности</0>.",
    eventRegistrationSLOpen: "Регистрации в списъка на чакащите за <0>{{ eventName }}</0> са отворени. <0>присъедини се сега</0>.",
    eventRegistrationSLClosed: "Регистрации в списъка на чакащите за <0>{{ eventName }}</0> са били затворени. Сортирането за основната схема и списъка с чакащи, въз основа на позиция в ранглиста, е в ход. <0>Вижте подробности</0>.",
    eventRegistrationMLOpen: "Регистрации в Основната схема за <0>{{ eventName }}</0> са отворени. <0>присъедини се сега</0>.",
    eventRegistrationMLClosed: "Регистрации в Основната схема за <0>{{ eventName }}</0> са били затворени. Регистрация в списъка на чакащите е отворена до деня преди началото на състезанието. <0>Вижте подробности</0>.",
    eventMainDrawPublished: "Регистрации в Основната схема за <0>{{ eventName }}</0> са били затворени. Регистрация в списъка на чакащите е отворена до деня преди началото на състезанието. <0>Вижте подробности</0>.",
    eventStarted: "Мачовете в <0>{{ eventName }}</0> започват днес. <0>Вижте подробности</0>.",
    eventEnded: "Мачовете в <0>{{ eventName }}</0> завършиха. Резултатите и победителите се актуализират. <0>Вижте подробности</0>.",
    eventClosed: "Резултатите за <0>{{ eventName }}</0> са актуализирани. <0>Вижте подробности</0>.",
    eventAnnouncementUpdates: "Актуализация на турнира <0>{{ eventName }}</0>: \n\n {{ announcement }}",
    friendRequestReceived: "Имате покана за приятелство от {{ partnerName }}. <0>Виж подробности</0>.",
    friendRequestAccepted: "Имате нов приятел в Sportya - {{ partnerName }}. <0>Виж профил</0>.",
    friendJoined: "{{ partnerName }} се присъедини към Sportya по ваша покана. <0>Виж профил</0>.",
    friendRaisedHand: "{{ partnerName }} търси приятелски мач в Sportya. <0>Покани за мач</0>.",
    invitationReceived: "{{ partnerName }} те покани на мач от {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Вижте поканата</0>.",
    pastMatchInvite: "Моля, потвърдете участието си и резултата за {{ matchGameType }} мача с {{ partnerName }} - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    matchAccepted: "{{ partnerName }} прие поканата ти за {{ matchGameType }} мач - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    matchDeclined: "{{ partnerName }} отказа поканата ти за игра на {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    matchDenied: "{{ partnerName }} отказа участие в твоя мач от {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    playerWithdrew: "{{ partnerName }} се оттегли от {{ matchGameType }} вашия мач - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    addScoreReminder: "Добавете резултата от вашия мач {{ matchGameType }}  - <strong>{{ matchFormatedId }}</strong>. <0>Добавете резултата</0>.",
    upcomingMatchReminder: "Вашият мач {{ matchGameType }} започва след 30 минути - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    pastMatchConfirmed: "{{ partnerName }} потвърди мача на {{ matchGameType }} и резултата - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    scoreAdded: "{{ partnerName }} добави резултата от твоя {{ matchGameType }} мач - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    scoreChanged: "{{ partnerName }} промени резултата от твоя {{ matchGameType }} мач - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    scoreValidated: "Крайният резултат от вашия мач {{ matchGameType }} е потвърден - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    canceledMatch: "Твоят {{ matchGameType }} мач е анулиран  - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    updatedMatch: "Вашият мач от {{ matchGameType }} е актуализиран  - <strong>{{ matchFormatedId }}</strong>. <0>Виж съвпадението</0>.",
    playerRatingReceived: "Току-що получихте нова оценка от приятелски мач. <0>Вижте средната оценка</0>.",
    newRewardReceived: "Честито! Получихте нова награда. <0>Проверете Виртуалния Портфейл</0>",
    bookingCancellation: "Вашата резервация в <strong>{{ clubName }}</strong> е анулирана от Клуба - <strong>{{ bookingId }}</strong>. <0>Преглед на подробностите за резервация</0>.",
    bookingRescheduled: "Вашата резервация в <strong>{{ clubName }}</strong> е пренасрочена от Клуба - <strong>{{ bookingId }}</strong>. <0>Преглед на подробностите за резервация</0>.",
  },
  creditCard: 'Кредитна карта',
  virtualWallet: 'Виртуален портфейл',
  summerMembership: 'Летен акаунт',
  premiumMembership: 'Премиум акаунт',
  transactionTypes: {
    topUp: 'Зареждане на Виртуален портфейл',
    topUpByAdmin: ' Зареждане на Виртуален портфейл (Административно)',
    subtractByAdmin: ' Актуализиране на Виртуален портфейл (Административно)',
    eventRegistration: 'Записване на Събитие: <0>{{ eventName }}</0>',
    eventWithdrawal: 'Оттегляне от Събитие: <0>{{ eventName }}</0>',
    upgradeMembership: 'Upgrade Абонамент: <1>{{ membershipName }}</1>',
    upgradeMembershipByAdmin: 'Upgrade Абонамент (от Admin): <1>{{ membershipName }}</1>',
    balanceAdjustmentByAdmin: 'Променен баланс (от Admin)',
    waitingListRefund: 'Автомятично връщане Списък с резерви',
    currencyNotSet: 'Трябва да настроите местоположението си, за да започнете да използвате своя виртуален портфейл и функцията за допълване.',
    date: '<0>Дата:</0> {{ createdAt }}',
    firstTaskCompletion: "Награда: Собствено действие",
    referralReward: "Награда: действие на гост играч",
  },
  notificationButton: 'Бутон за известия',
  pictureMaxSize: 'Профилната снимка трябва да е по-малка от 1MB.',
  premium: 'Премиум {{period}} акаунт',
  undefinedMembership: 'Премиум',
  referral: {
    title: "Поканете приятелите си и ще бъдете възнаградени",
    title2: "Създайте своя акаунт и печелете награди!",
    subtitle: "Поканете приятелите си в Sportya и спечелете награди за първите {{ maxRewards }} играчи*, които се присъединят към Sportya с вашия код за покана! И двамата ще получите <strong>{{ amount } }{{ currency }}</strong> във вашите виртуални портфейли** веднага щом <strong>едно от следните действия бъде извършено успешно от първия поканен играч</strong>:",
    subtitle2: "Спечелете награда веднага щом изпълните успешно ЕДНО от следните действия за първи път:",
    item1: "Получаване на първи валидиран резултат в турнир Sportya",
    item11: "Получаване на първи валидиран резултат в турнир Sportya",
    item2: "3ареждане На Виртуален Портфейл",
    item22: "Зареждане На Виртуален Портфейл",
    item3: "Закупуване на Premium акаунт с виртуален портфейл или банкова карта",
    item4: "Първо участие в приятелска среща с валидиран резултат",
    amountEarned: "{{ amount }} {{ currency }} спечелени",
    rewardsCompleted: "Пълни награди {{ receivedRewards }}/{{ maxRewards }}",
    yourFriend: "Покани",
    referVia: "Покана чрез",
    shareTitle: "Споделете",
    smallText1: "*Играч ще бъде награден само за първите {{ maxRewards }} играчи, които се присъединят към Sportya с рефералния код и изпълнят успешно едно от необходимите действия за първи път.",
    smallText2: "**Виртуалният портфейл на наградения играч автоматично ще бъде кредитиран с 5 евро или еквивалента в неговата валута на виртуалния портфейл, веднага щом някое от отговарящите на условията действия бъде изпълнено успешно. Всеки потребител ще получи наградата само веднъж, само за едно от отговарящите на условията действия.",
    smallText3: "*Съответната сума от стойността на наградата ще бъде кредитирана във валутния баланс на вашия виртуален портфейл Sportya.",
    smallText4: "**Само едно от отговарящите на условията действия ще бъде възнаградено, когато бъде изпълнено за първи път.",
    smallText5: "Моля, проверете <1>Правила и условия</1>, за да научите повече за наградите Sportya",
    inviteFriends: "Поканете приятелите си и ще бъдете възнаградени",
    inviteFriendsDescription: "Спечелете <strong>{{ amount }} {{ currency }}</strong> за първите {{ maxRewards }} играчи, които се присъединят към Sportya с вашия код за покана!",
    inviteYourFriends: "Покани приятелите си",
    invite: {
      playTogether: 'Поканете приятелите си и играйте заедно',
      friends: 'Покани приятелите си',
    },
    shareToMedia: {
      email: {
        title: 'Да играем тенис в Спортя',
        body: `Здравейте! %0D%0A
Използвам Sportya, платформата за състезателни спортове за аматьори тенисисти, и ви каня да се присъедините! %0D%0A
В Sportya можете да се срещате с други играчи, да играете приятелски мачове заедно или да участвате в турнири и да се състезавате за първите места в националната и международната ранглиста! %0D%0A
Това е чудесен начин да останете активни и да се забавлявате заедно. %0D%0A
%0D%0A
Присъединете се към Sportya сега, като използвате моята уникална връзка за покана и спечелете награди! %0D%0A
{{inviteLink}}`,
      },
      facebook: `Join me on Sportya, the competitive sports platform for amateur tennis players!\r\n
We can connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings!\r\n
Use my unique invitation link: {{inviteLink}}`,
      twitter: 'Присъединете се към мен в Sportya, състезателната спортна платформа за аматьори тенисисти!%0aСъздайте акаунт, за да се срещате с други играчи в приятелски мачове, национални и международни турнири!%0a',
    },
    invitedBy: 'Вие сте поканени от <1>{{referee}}</1>.',
    joinSportya: 'Създайте своя акаунт и печелете награди! <1>Открийте повече</1>.',
    share: 'Споделете любовта си към тениса! Поканете вашите приятели в Sportya и играйте приятелски мачове заедно или се състезавайте за първите места в национални и международни класации!',
    copyAndShare: 'Копирайте и споделете вашата връзка за покана',
    willBeFriend: 'Приятелите, които поканите и се регистрирате в Sportya, ще бъдат автоматично добавени към вашия списък с приятели.',
    copy: 'Копиране',
    dashboardBannerTitle: "Вие сте една крачка по-близо до наградата",
    dashboardBannerSubtitle: "Спечелете <1>{{ amount }} {{ currency }}</1>, веднага щом завършите успешно ЕДНО от следните действия за първи път:",
    congrats: 'Честито! Току-що получихте <1>{{ amount }} {{ currency }}</1> във вашия <3>Виртуален Портфейл</3> за успешното завършване на първото ви действие в Sportya.',
    findOutMore: 'Научете повече за <1>наградите Sportya</1> или проверете <3>Правила и условия</3>',
  },
  ratePlayer: 'Дайте бележка',
  changeRating: 'Промяна на присъдената оценка',
  receivedRating: '{{ total }} получена бележка',
  receivedRatings: '{{ total }} получени бележки',
  ratePlayerInfo: 'Можете да оценявате други играчи само след като сте участвали в поне един приятелски мач с тях.<br/>Средната оценка на даден играч се изчислява въз основа на всички оценки, получени от други играчи,<br/> и ще бъде публично показана в профила на този играч след получаване на минимум 3 оценки.',
  noRatings: 'Няма оценки',
  rate: 'Запазване на оценката',
  removeRating: 'Оттегляне на оценката',
  ownRatingInfo: 'Вашата средна оценка ще стане видима за всички играчи само след като сте получили поне 3 оценки от играчи, с които сте взаимодействали.',
  skipRating: 'Ще оценя по-късно',
  howWouldRatePlayer: 'Дайте бележка',
  howWouldRateTitlePartner: 'Оценете Партньора',
  howWouldRateTitleOpponent: 'Оценете Противник 1',
  howWouldRateTitleOpponentPartner: 'Оценете Противник 2',
  thankYouForRating: 'Благодаря ви за оценката!',
};
