import aes from 'crypto-js/aes';
import { get, patch } from '../../../components/common/http';

export const setPassword = async (props) => {
  const {
    id,
    resetCode,
    values,
    addToast,
    setSubmitting,
    socketClientId,
    t,
  } = props;

  try {
    const { rawPassword } = values;
    const KEY = process.env.REACT_APP_ENC_KEY;
    const password = aes.encrypt(rawPassword, KEY).toString();
    await patch(`/accounts/${id}/set-password`, { socketClientId, resetCode, password });

    setSubmitting(false);
  } catch (error) {
    setSubmitting(false);
    return (
      addToast
      && addToast(t(error.message || 'CHANGE_PASSWORD_FAILED'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const getAccount = async (id, resetCode) => {
  try {
    const { data } = await get(`/accounts/${id}/reset-code/${resetCode}`);

    return data;
  } catch (error) {
    return error.response?.data;
  }
};
