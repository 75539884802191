import React from 'react';

import LoadImage from "../../../../components/common/LoadImage";

import { Container } from './styles';

const initials = (data) => `${data.firstName.charAt(0)} ${data.lastName.charAt(0)}.`;

const Avatar = ({ conversation, accountInfo }) => {
  // return first 2 members from conversation, except you
  const members = (conversation?.members || [])
    .filter((member) => member.userId !== accountInfo.userId)
    .slice(0, 2);
  const avatarUrl = `accounts/${conversation?.userInfo?.userId}/${conversation?.userInfo?.profilePicture}`;

  return (
    <Container className={conversation?.type === 'group' ? 'group-conversation' : 'individual-conversation'}>
      {conversation?.type === 'individual' && (
        conversation?.userInfo?.profilePicture ? (
          <div className="image">
            <img
              src={LoadImage(avatarUrl, true)}
              alt={`${conversation?.userInfo?.firstName} ${conversation?.userInfo?.lastName}`}
            />
          </div>
        ) : (
          <div className="initials">
            <span>{initials(conversation?.userInfo)}</span>
          </div>
        )
      )}

      {conversation?.type === 'group' && conversation?.picture && (
        <div className="image">
          <img src={LoadImage(`chats/${conversation?.picture}`, true)} alt="" />
        </div>
      )}

      {conversation?.type === 'group' && !conversation?.picture && (
        <>
          {members.map((member, key) => {
            if (member.profilePicture) {
              return (
                <div key={key} className="image">
                  <img
                    src={LoadImage(`accounts/${member.userId}/${member.profilePicture}`, true)}
                    alt={`${member.firstName} ${member.lastName}`}
                  />
                </div>
              );
            }

            return (
              <div key={key} className="initials">
                <span>{initials(member)}</span>
              </div>
            );
          })}

          {(conversation?.members || []).length > 2 && (
            <div className="plus-members">
              <span>{`+${conversation.members.length - 2}`}</span>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Avatar;
