import styled from '@emotion/styled';
import ComponentContainer from '../../components/Container';

export const SubscriptionsHeader = styled.div`
    max-width:640px;
    width:100%;
    margin:40px auto 0px auto;
    
    h1,p{
        text-align:center
    }
    p{
        margin: 20px 0 50px;
    }
  `;

export const ActivePlan = styled.div`
  display: flex;
  width: 100%;
  max-width: max-content;
  padding: 10px 20px;
  border-radius: 75px;
  background: #EAF6F6;
  margin: 0 auto 30px;
  > img { 
    margin-right: 5px;
  }
`;

export const BenefitsStyle = styled.div`
    max-width:918px;
    width:100%;
    margin:0 auto;
    h4{
        font-size: 24px;
        line-height: 31px;
        text-align:center;  
        margin-bottom:32px;
    }

    .benefitsWrapp{
        display:flex;
        justify-content:space-between;

        @media screen and (max-width:1024px){
      flex-direction:column;
      align-items: center;
    }
        .benefits{
          max-width:435px;
          width:100%;
        }
    }
    .subscriptionsInfo{
      margin: 40px 0 134px;
    p{
      margin:0 0 20px;
      span{
        color: ${({ theme }) => theme?.colors?.secondary};
      }
    }
}
`;

export const PlansStyle = styled.div`
  max-width:918px;
  width:100%;
  display:flex;
  margin: 0 auto;
  padding-bottom:58px;
  justify-content:space-between;

  @media screen and (max-width: 768px){
    flex-direction:column;
    align-items: center;
  }

`;

export const Container = styled(ComponentContainer)`
  .subscriptionsWrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;
