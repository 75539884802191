export default {
  friendsSeoTitle: 'Friends | Sportya',
  title: 'Friends',
  tabs: {
    allFriends: 'All Friends ({{friendsCount}})',
    friendRequests: 'Friend Requests ({{friendsCount}})',
    sentRequests: 'My Sent Requests ({{friendsCount}})',
  },
  unfriendText: 'Unfriend {{name}}',
  unfriend: 'Unfriend',
  unfriendConfirmationText: 'Are you sure you want to remove {{name}} as your friend?',
  noRequestsSent: 'You didn\'t receive any new friend requests',
  noRequestsReceived: 'You didn\'t send any new friend requests',
  wantToAddFirstFriend: 'Want to add your first friend on Sportya?',
  confirm: 'Confirm',
  decline: 'Decline',
  cancelRequest: 'Cancel Request',
};
