import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../../../components/common/http';

// Actions
export const fetchStats = createAsyncThunk('stats/fetchStats', async () => {
  const { data: { data } } = await get('/stats');
  return data;
});

// Slice
const slice = createSlice({
  name: 'stats',
  initialState: {
    data: {
      players: 0,
      events: 0,
      clubs: 0,
      courts: 0,
      cities: 0,
    },
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchStats.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});
export default slice.reducer;
