import React, { useEffect } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';

import Link from '../../../../components/Link';
import LoadImage from '../../../../components/common/LoadImage';
import ContentLoader from '../../../../components/ContentLoader';
import { Paragraph, H3 } from '../../../../components';
import { Content, Competition } from './styles';
import { OnDesktop, OnMobile } from '../../styles';
import { fetchCompetitions } from './reducers';
import getCompetitionListTypeEntry from '../../../../components/common/getCompetitionListTypeEntry';

const UpcomingCompetitions = () => {
  const dispatch = useDispatch();
  const { data: competitions, status } = useSelector(state => state.dashboard?.competitions);
  const { accountInfo } = useSelector(state => state.session);
  const { t } = useTranslation('events');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCompetitions(accountInfo?.id));
    }
  }, [status, dispatch]);

  if (status === 'loading') {
    return <ContentLoader title type="listCompetitions" items={2} />;
  }

  const getCurrentPhase = phase => (phase === 'playing'
    ? t('phaseStatuses.upcomingCompetitions.playing')
    : t('phaseStatuses.upcomingCompetitions.upcoming')
  );

  const groupedCompetitions = groupBy(competitions?.results, 'sportType');
  const sportsByCompetitions = Object.keys(groupedCompetitions);

  return (
    <>
      {!isEmpty(groupedCompetitions) && (
        <Content>
          <H3 className="mb20 title">
            <span><Trans ns="player" i18nKey="myEvents">Upcoming Competitions</Trans></span>
            <Link to="/my-account/events">
              <Trans ns="events" i18nKey="seeAll">See My Events</Trans>
              <img src={LoadImage('black-right-arrow-small.svg')} alt="" />
            </Link>
          </H3>
          {sportsByCompetitions.map((sport) => (
            <>
              <Paragraph large bold key={sport} className="text-capitalize">
                {sport}
              </Paragraph>
              {groupedCompetitions[sport].map((event, key) => (
                <Competition key={key}>
                  <div className="date text-center">
                    <H3>{dateFormat(event?.competitions?.startDate, 'd')}</H3>
                    <Paragraph bold>{dateFormat(event?.competitions?.startDate, 'mmm')}</Paragraph>
                  </div>
                  <OnDesktop className="w100">
                    <div className="description">
                      <ul className="location">
                        <li>{event.club?.clubName}</li>
                        {event?.club?.location && (
                          <li>{`${event.club?.location?.city}, ${event.club?.location?.country}`}</li>
                        )}
                      </ul>
                      <Link to={`/events/${event._id}/competitions/${event.competitions?._id}`}>
                        <Paragraph large bold>{event.name}</Paragraph>
                        <div className={`status ${event?.currentPhase?.status}`}>
                          <Paragraph>{getCurrentPhase(event?.currentPhase?.status)}</Paragraph>
                        </div>
                      </Link>

                      <ul className="event-details">
                        <li>
                          <Trans ns="events" i18nKey="level">Level</Trans>
                          {' '}
                          {event.competitions?.level}
                        </li>
                        <li>{event.category}</li>
                        <li><Trans ns="events" i18nKey={event.competitions?.gameType} /></li>
                        <li>
                          {event.competitions?.mlMaxNumber}
                          {' '}
                          <Trans ns="common" i18nKey={event.competitions?.gameType === 'singles' ? 'players' : 'teams'}>
                            Players
                          </Trans>
                          {' '}
                          (
                          <Trans ns="events" i18nKey={event.competitions?.drawModel}>
                            {event.competitions?.drawModel}
                          </Trans>
                          {event?.competitions?.phases?.length > 0 && (
                            <>
                              {' '}
                              +
                              <Trans i18nKey={event?.competitions?.phases[0]?.drawModel} ns="events">
                                {` + ${event?.competitions?.phases[0]?.drawModel}`}
                              </Trans>
                            </>
                          )}
                          )
                        </li>
                      </ul>
                      <span className="listType">
                        <img src={LoadImage('checkmark-black.svg')} alt="" />
                        <Paragraph small bold>
                          <Trans
                            ns="events"
                            i18nKey={getCompetitionListTypeEntry(accountInfo?.userId, event?.competitions)}
                          />
                        </Paragraph>
                      </span>
                    </div>
                    <div className="go-to-event">
                      <Link to={`/events/${event._id}/competitions/${event.competitions?._id}`}>
                        <img src={LoadImage('right-arrow.svg')} alt="" />
                      </Link>
                    </div>
                  </OnDesktop>

                  <OnMobile width="100%">
                    <Link to={`/events/${event._id}/competitions/${event.competitions?._id}`}>
                      <div className="description">
                        <ul className="location">
                          <li>{event.club?.clubName}</li>
                          {event?.club?.location && (
                            <li>{`${event.club?.location?.city}, ${event.club?.location?.country}`}</li>
                          )}
                        </ul>

                        <Paragraph large bold>{event.name}</Paragraph>
                        <div className={`status ${event?.currentPhase?.status}`}>
                          <Paragraph>{getCurrentPhase(event?.currentPhase?.status)}</Paragraph>
                        </div>

                        <ul className="event-details">
                          <li>
                            <Trans ns="events" i18nKey="level">Level</Trans>
                            {' '}
                            {event.competitions?.level}
                          </li>
                          <li>{event.category}</li>
                          <li><Trans ns="events" i18nKey={event.competitions?.gameType} /></li>
                          <li>
                            {event.competitions?.mlMaxNumber}
                            {' '}
                            <Trans
                              ns="common"
                              i18nKey={event.competitions?.gameType === 'singles' ? 'players' : 'teams'}
                            >
                              Players
                            </Trans>
                            {' '}
                            (
                            <Trans ns="events" i18nKey={event.competitions?.drawModel}>
                              {event.competitions?.drawModel}
                            </Trans>
                            {event?.competitions?.phases?.length > 0 && (
                              <>
                                {' '}
                                +
                                <Trans i18nKey={event?.competitions?.phases[0]?.drawModel} ns="events">
                                  {` + ${event?.competitions?.phases[0]?.drawModel}`}
                                </Trans>
                              </>
                            )}
                            )
                          </li>
                        </ul>
                        <span className="listType">
                          <img src={LoadImage('checkmark-black.svg')} alt="" />
                          <Paragraph small bold>
                            <Trans
                              ns="events"
                              i18nKey={getCompetitionListTypeEntry(accountInfo?.userId, event?.competitions)}
                            />
                          </Paragraph>
                        </span>
                      </div>
                      <div className="go-to-event">
                        <img src={LoadImage('right-arrow.svg')} alt="" />
                      </div>
                    </Link>
                  </OnMobile>
                </Competition>
              ))}
            </>
          ))}
        </Content>
      )}
    </>
  );
};

export default UpcomingCompetitions;
