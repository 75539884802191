import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Trans } from 'react-i18next';
import { post } from '../common/http';
import Loader from '../ContentLoader';
import LoadImage from '../common/LoadImage';
import { Contain } from '../Collection';

const FileUpload = ({
  dropzoneRef,
  onChange,
  photo,
  setPhoto,
  accept,
  multiple,
  clubsPage,
  bucketName = 'accounts',
  bucketId = null,
  setter,
  onDropRejected,
  ...props
}) => {
  const [loading, setLoading] = useState(null);

  const uploadPhoto = async (files, setPhotoFunction, onChangeFunction) => {
    if (!files.length) {
      return;
    }
    setLoading(true);
    setter(true);
    const formData = new FormData();
    [...files].forEach(file => formData.append('file', file));
    const { data: { data: { filename } } } = await post(`/assets/${bucketName}/${bucketId}`, formData);
    setPhotoFunction(filename);

    // eslint-disable-next-line no-unused-expressions
    onChangeFunction && onChange(filename);
    setLoading(false);
    setter(false);
  };

  const imageUrl = LoadImage(`${bucketName}/${bucketId}/${photo}`, true);

  return (
    <div className={`FileUpload ${props.className || ''}`}>
      <div className="FileUpload__content">
        <Dropzone
          ref={dropzoneRef}
          accept={accept || null}
          multiple={multiple || false}
          onDrop={acceptedFiles => uploadPhoto(acceptedFiles, setPhoto, onChange)}
          onDropRejected={(fileRejections) => (onDropRejected ? onDropRejected(fileRejections) : null)}
        >

          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message" {...getRootProps()}>
                <input {...getInputProps()} />
                {props.thumbnail && props.nameInitials
                  ? (
                    <>
                      {!loading ? (
                        <div className="picture-thumbnail">
                          {photo
                            ? <img src={imageUrl} alt="" />
                            : <h2>{props.nameInitials}</h2>}
                        </div>
                      )
                        : (
                          <Contain margin="60px 50% 100px 50%">
                            <Loader absolute />
                          </Contain>
                        )}
                    </>
                  )
                  : (
                    <>
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      {!clubsPage
                        && (
                          <h4>
                            <Trans ns="common" i18nKey="uploadPlaceholder">
                              Drop files here or click to upload.
                            </Trans>
                          </h4>
                        )}

                      {photo && <img src={imageUrl} alt="" />}
                    </>
                  )}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

export default FileUpload;
