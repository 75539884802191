import styled from '@emotion/styled';
import { Button, H2 } from '../../../../../components/Collection';

export const AddScoreModalContainer = styled.div({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '600px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  background: '#fff',
  padding: '40px',
  zIndex: 99,
  maxHeight: 'calc(100vh - 20px)',

  '@media (max-width: 520px)': {
    width: 'calc(100% - 20px)',
  },

  '@media (max-width: 400px)': {
    padding: 15,

    "> div": {
      zoom: 0.8,
    },

    h4: {
      fontSize: '16px',
    },
  },
});

export const AddScoreModalTeams = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 0 0 0',
});

export const AddScoreTeamContainer = styled.div(({ marginOnMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 520px)': {
    ...marginOnMobile && {
      margin: '0 40px 0 0',
    },
  },
}));

export const TrophyIcon = styled.img({
  height: '14px',
  width: '14px',
  margin: '0 5px 0 0',
});

export const AddScoreModalTeam = styled.div(({
  row, middle, autoLeftMargin, marginOnMobile,
}) => ({
  display: 'flex',
  flexDirection: row ? 'row' : 'column',
  width: '150px',
  height: '50px',
  alignItems: 'center',
  ...middle && {
    justifyContent: 'center',
  },
  ...autoLeftMargin && {
    margin: '0 0 0 auto',
  },

  '@media (max-width: 520px)': {
    width: '120px',
    ...marginOnMobile && {
      margin: '0 40px 0 0',
    },
  },

  '.error': {
    border: '1px solid red !important',
  },
}));

export const VS = styled(H2)({
  color: '#C1E6E5',
  width: '150px',
  textAlign: 'center',
  '@media (max-width: 520px)': {
    display: 'none',
  },
});

export const AddScoreContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const AddScoreInput = styled.input({
  height: '40px',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  width: '84px',
  margin: '0 10px 0 0',
  textAlign: 'center',

  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',

  '&:focus': {
    outline: 'none',
  },

  '@media (max-width: 520px)': {
    width: '55px',
  },

  '&:disabled': {
    border: '1px solid #EAF6F6',
    background: '#EAF6F6',
    opacity: 1,
    color: '#AFAFAF',
  },
});

export const ScoreInputTitle = styled.div({
  textAlign: 'center',
  width: '84px',
  margin: '0 10px 0 0',
  fontSize: '10px',
  '@media (max-width: 520px)': {
    width: '55px',
  },
});

export const AddTieInput = styled.input({
  height: '40px',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  width: '42px',
  textAlign: 'center',

  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    border: '1px solid #EAF6F6',
    background: '#EAF6F6',
    color: 'linen',
    opacity: 1,
  },
});

export const TieInputTitle = styled.div({
  fontSize: '10px',
  textAlign: 'center',
  width: '42px',
});

export const AddScoreButton = styled(Button)({
  width: '100%',
});

export const CancelButton = styled(Button)({
  margin: '10px 0 0 0',
  width: '100%',
});

export const ScoresContainer = styled.div({
  display: 'flex',
  '&:not(:last-of-type)': {
    margin: '0 0 6px 0',
  },
});
