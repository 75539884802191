import dateFormat from 'dateformat';

const getTimeIntervals = ({ date }) => {
  const dateTime = new Date(date);
  const intervals = [];

  for (let i = 6; i < 24; i++) {
    dateTime.setHours(i);
    dateTime.setMinutes(0);
    intervals.push(dateFormat(dateTime, "HH:MM"));

    if (i < 23) {
      dateTime.setHours(i);
      dateTime.setMinutes(30);
      intervals.push(dateFormat(dateTime, "HH:MM"));
    }
  }
  // set time to the next Half of the hour
  const now = new Date();
  now.setMinutes((Math.ceil(now.getMinutes() / 30) * 30) % 60);
  if (now.getMinutes() === 0) {
    now.setHours(now.getHours() + 1);
  }
  const time = dateFormat(now, "HH:MM");

  return intervals
    .filter(value => (
      (dateFormat(dateTime, "DDDD") === 'Today' && time && value >= time)
      || dateFormat(dateTime, "DDDD") !== 'Today'))
    .map((value) => ({
      label: value,
      value,
    }));
};

export default getTimeIntervals;
