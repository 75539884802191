import styled from '@emotion/styled';

export const Container = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  padding: '30px',
  zIndex: 6,
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '600px',
    height: '100%',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    background: '#fff',
    filter: 'blur(54px)',
    zIndex: 3,
  },
});

export const Protect = styled.div({
  zIndex: 5,
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const NonBlurContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 4,
  '> *': {
    position: 'relative',
    zIndex: 4,
  },
});

export const EmailIcon = styled.img({
  height: '46.75px',
  width: '51px',
});

export const Alert = styled.div({
  display: 'flex',
  height: '60px',
  padding: '20px 15px',
  background: '#FFE071',
  borderRadius: '4px',
  margin: '100px 20px 0',
  width: '51px',
});
