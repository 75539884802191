import styled from '@emotion/styled';
import { H3, P } from '../../../../components/Collection';

export const Container = styled.div(({ hasLoader }) => ({
  ...hasLoader && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  minHeight: '350px',
  padding: '0 0 20px 0',
}));

export const StatsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  margin: '20px 0 40px 0',
  width: '100%',
  minHeight: '130px',
  '@media (max-width: 920px)': {
    flexDirection: 'column',
  },
});

export const H3Title = styled(H3)({
  margin: '0 0 20px 0',
});

export const Stats = styled.div(({ noBorder }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '90px',
  ...!noBorder && {
    borderRight: '1px solid #EAF6F6',
    '@media (max-width: 920px)': {
      borderRight: 'unset',
      borderBottom: '1px solid #EAF6F6',
      padding: '20px 0',
      height: 'unset',
    },
  },
}));

export const CalculationDateContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 920px)': {
    flexDirection: 'column',
  },
});

export const CalculationDate = styled(P)({
  margin: '0 0 0 auto',
  '@media (max-width: 920px)': {
    margin: '20px 0 0 0',
  },
});

export const PenaltyEventContainer = styled.div({
  display: 'flex',
  height: '102px',
  borderRadius: '10px',
  background: '#FFF1F4',
  padding: '20px 30px',
  '&:not(last-of-type)': {
    margin: '0 0 20px 0',
  },
  '@media (max-width: 920px)': {
    flexDirection: 'column',
    height: 'unset',
  },
});

export const PenaltyReason = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 0 0 auto',
  '@media (max-width: 920px)': {
    margin: 'unset',
  },
});

export const PenaltyPoints = styled(P)({
  margin: 'auto 0 auto 200px',
  '@media (max-width: 920px)': {
    margin: '20px 0 0 0',
  },
});
