import styled from '@emotion/styled';

// results header for groups
export const ResultsStyleHeaderGroups = styled.div`
  display:flex;
  justify-content:space-between;
  margin-top:20px;

  p{margin:0;}

  .players{
    max-width:400px;
    width:100%;
    margin-left:169px;
  }

  .winsLosses{
    max-width:71px;
    width:100%;
  }

  .setsWon{
    max-width:82px;
    width:100%;
  }

  .gamesWon{
    max-width:97px;
    width:100%;
  }

  .rank{
    max-width:30px;
    width:100%;
  }

  @media screen and (max-width: 1024px){
  display:none;
 }
`;

// results header for normal comp
export const ResultsStyleHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.lightBlue};
  display:flex;
  justify-content:space-between;
  margin-top:20px;

 @media screen and (max-width: 1024px){
  display:none;
 }

  p{color: ${({ theme }) => theme?.colors?.darkTurquoise};}

  .headerPhase{
    max-width:169px;
    width:100%;
  }

  .headerInfo{
    max-width:989px;
    width:100%;
    display:flex;
    justify-content:space-between;
    
    .headerInfoLeft{
      max-width: 437px;
      width:100%;
      display:flex;
      justify-content:space-between;
      padding-left:25px;
  

      .scores{
        max-width:107px;
        width:100%;
        text-align:center;
      }
    }
  .headerInfoRight{
    max-width: 460px;
    width:100%;
    display:flex;
    justify-content:space-between;

    .winsLeft{
    max-width:200px;
    width:100%;

    display:flex;
    justify-content:space-between;
    }

    .winsRight{
      max-width:212px;
      width:100%; 
      display:flex;
      justify-content:space-between;
    }

  }
}
`;
