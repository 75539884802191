import styled from '@emotion/styled';
import ComponentContainer from '../../../components/Container';

export const Container = styled(ComponentContainer)`
  margin-top:40px;
  
  h3
  { 
    line-height: 36px;
    margin-bottom: 30px;
  }
`;

export const NotificationPanel = styled.div`
  margin-top:40px;

  .unseen {
    border-radius: 10px;
    background: #eaf6f6;
  }

  .seeDetails {
    display: inline-flex;
    color: #7cc9c6;
    font-weight: bold;
  }

  h3
  { 
    line-height: 36px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.lightBlue};
  }

  border:1px solid ${({ theme }) => theme?.colors?.lightBlue};
  padding: 30px 40px 30px 40px;
  border-radius: 10px;
`;

export const NoNotificationsAvailable = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '40px auto',
  height: '50vh',
});
