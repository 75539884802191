const datePickerInterval = () => {
  const today = new Date();
  const date = new Date();
  date.setDate(date.getDate() + 14);

  return {
    fromDate: today,
    toDate: new Date(date),
  };
};

export default datePickerInterval;
