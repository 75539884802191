export default {
  title: 'Evenimentele Mele',
  seoTitle: 'Evenimentele Mele | Sportya',
  joined: 'Înscrieri',
  noJoined: 'Nu te-ai înscris în competiții până acum.',
  followed: 'Urmărite',
  noFollowed: 'Nu ai ales să urmărești competiții până acum.',
  closed: 'Închise',
  noClosed: 'Nu ai finalizat competiții până acum.',
  withdrawals: 'Retrageri',
  noWithdrawals: 'Nu te-ai retras de la evenimente până acum.',
  findEvent: 'Găsește un Eveniment',
  seeEvent: 'Vezi Evenimentul',
  seeCompetition: 'Vezi Competiția',
  registeredOnMainBoard: 'Înscris pe Tabloul Principal',
  registeredInQueueList: 'Înscris pe Lista de Așteptare',
  lookingForPartners: 'Caut un partener',
};
