import PubSub from 'pubsub-js';
import {
  get,
  post,
} from '../../components/common/http';

const catchError = ({
  error,
  addToast,
  t,
  fallbackMessage = 'API_ERROR',
}) => {
  const message = error?.response?.data?.message ?? error?.message ?? fallbackMessage;

  addToast(t(message), {
    appearance: 'error',
    autoDismiss: true,
  });

  PubSub.publish('api-error-handler', {
    message,
    hasNotification: true,
  });
};

export const bookCourt = async ({
  payload,
  addToast,
  t,
  setIsSubmitting,
}) => {
  try {
    await post("/bookings", payload);
  } catch (error) {
    setIsSubmitting(false);
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'FAILED_TO_BOOK_COURT',
    });
  }
};

export const getClubCourts = async (filters) => {
  try {
    const filterParams = new URLSearchParams({ ...filters }).toString();
    const { data } = await get(`/clubs/courts?${filterParams}`);
    const [result] = data?.data?.results;
    return result;
  } catch (error) {
    return false;
  }
};

export const getEventsLocations = async (filters) => {
  try {
    const filterParams = new URLSearchParams({ ...filters }).toString();
    const { data: { data } } = await get(`/events/locations?${filterParams}`);
    return data;
  } catch (error) {
    return false;
  }
};
