import styled from '@emotion/styled';

export const ResultsPlayersCard = styled.div`
  height:159px;
  background-color:${
  (props => (
    props.final
      ? ({ theme }) => theme?.colors?.primary
      : ({ theme }) => theme?.colors?.white)
  )};
  padding: 21px 29px 21px 25px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  border-radius: 4px;
  
  width:100%;
  `;

export const ResultsPlayersGroupCard = styled.div`
    min-height:100px;
    height:auto;
  `;

export const ElimsLeftInfo = styled.div`
  @media screen and (max-width: 1024px){
        margin: 21px 0 15px 25px;
  }
      max-width: 169px;
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      margin-top: 21px;
      p{
        margin:0;
      }
      p:first-of-type{
        margin-bottom:5px;
      }
      p:not(:first-of-type){
        color: ${({ theme }) => theme?.colors?.darkTurquoise};
      }
  `;
