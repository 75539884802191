import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import dateFormat from "dateformat";
import { isEmpty } from "lodash";

import Button from "../../../components/Button";
import Link from "../../../components/Link";
import { Paragraph } from "../../../components";
import UserAvatar from "../../../components/UserAvatar";
import timeToString from "../../Clubs/Info/components/Calendar/helpers/timeToString";
import { getFriendlyMatchByBookingId } from "../actions";
import LoadImage from "../../../components/common/LoadImage";
import { formatMatchId } from "../../FriendlyMatches/helpers";

const CourtBookingInfo = ({ paymentInfo }) => {
  const [friendlyMatch, setFriendlyMatch] = useState({});
  const {
    amount,
    currency,
    minCancellationTime,
  } = paymentInfo;
  const bookingInfo = JSON.parse(paymentInfo.bookingInfo);
  const clubInfo = JSON.parse(paymentInfo.clubInfo);
  const courtInfo = JSON.parse(paymentInfo.courtInfo);

  useEffect(() => {
    if (bookingInfo.bookingId) {
      getFriendlyMatchByBookingId(bookingInfo.bookingId).then(r => {
        if (!isEmpty(r)) {
          setFriendlyMatch(r);
        }
      });
    }
  }, [bookingInfo.bookingId]);

  const {
    matchId,
    player,
    playerPartner,
    opponent,
    opponentPartner,
  } = friendlyMatch;

  const startDate = new Date(bookingInfo?.startDate);
  const [hour, minute] = timeToString(bookingInfo?.startHour).split(':');
  startDate.setHours(hour, minute);

  return (
    <div className="booking-info">
      <Paragraph small>
        <Trans ns="clubs" i18nKey="bookingId">
          Booking ID: <span>{{ bookingId: bookingInfo.bookingId }}</span>
        </Trans>
      </Paragraph>
      <Paragraph medium bold>{clubInfo.clubName} - {clubInfo?.location?.name}</Paragraph>
      <hr />
      <ul className="overview">
        <li>
          <Paragraph><Trans ns="events" i18nKey="startDate">Start Date</Trans></Paragraph>
          <Paragraph>{dateFormat(startDate, 'dd mmmm (ddd)')}</Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="startTime">Start Time</Trans></Paragraph>
          <Paragraph>{dateFormat(startDate, 'HH:MM')}</Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="duration">Duration</Trans></Paragraph>
          <Paragraph>
            <Trans ns="clubs" i18nKey="durationMin">{{ duration: bookingInfo?.duration }} min</Trans>
          </Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="court">Court</Trans></Paragraph>
          <Paragraph>{courtInfo?.name}</Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="sportType">Sport Type</Trans></Paragraph>
          <Paragraph>
            <Trans ns="common" i18nKey={`sports.${clubInfo.sportType}`}>{clubInfo.sportType}</Trans>
          </Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="surface">Surface</Trans></Paragraph>
          <Paragraph>
            <Trans ns="clubs" i18nKey={`surfaceType.${courtInfo?.surface}`}>{courtInfo?.surface}</Trans>
          </Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="courtType">Court Type</Trans></Paragraph>
          <Paragraph>
            <Trans ns="clubs" i18nKey={`courtTypes.${courtInfo?.type}`}>{courtInfo?.type}</Trans>
          </Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="gameType">Game Type</Trans></Paragraph>
          <Paragraph>
            <Trans ns="clubs" i18nKey={`courtSizes.${courtInfo?.size}`}>{courtInfo?.size}</Trans>
            {!isEmpty(friendlyMatch) && (
              <span>, <Trans ns="clubs" i18nKey="friendlyMatch">Friendly Match</Trans></span>
            )}
          </Paragraph>
        </li>
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="selfService">Self-Service</Trans></Paragraph>
          <Paragraph>
            <Trans ns="common" i18nKey={courtInfo?.selfService ? 'yes' : 'no'}>
              {courtInfo?.selfService ? 'Yes' : 'No'}
            </Trans>
          </Paragraph>
        </li>
        {courtInfo?.hasLighting && (
          <li>
            <Paragraph><Trans ns="clubs" i18nKey="additionalInfo">Additional Info</Trans></Paragraph>
            <Paragraph className="text-capitalize">
              <Trans ns="common" i18nKey="withLighting">With Lighting</Trans>
            </Paragraph>
          </li>
        )}
        <li>
          <Paragraph><Trans ns="clubs" i18nKey="totalWithVat">Total (incl. VAT)</Trans></Paragraph>
          <Paragraph bold className="text-capitalize">
            {amount} {currency}
          </Paragraph>
        </li>
        {!isEmpty(friendlyMatch) && (
          <li className="friendly-match">
            <Link to="/friendly-matches/available">
              <Paragraph bold className="friendly-match__title">
                <Trans ns="clubs" i18nKey="friendlyMatch">Friendly Match</Trans>
                <img src={LoadImage('pagination-right-arrow.svg')} alt="" width="8" />
              </Paragraph>
              <Paragraph smaller color="grey">{formatMatchId(matchId)}</Paragraph>
            </Link>
            <ul>
              <li><UserAvatar user={player} size={38} /></li>
              {!isEmpty(playerPartner) && (<li><UserAvatar user={playerPartner} size={38} /></li>)}
              <li><UserAvatar user={opponent} size={38} /></li>
              {!isEmpty(opponentPartner) && (<li><UserAvatar user={opponentPartner} size={38} /></li>)}
            </ul>
          </li>
        )}
      </ul>

      <div className="d-flex row justify-content-center">
        <Button outline fullwidth>
          <Link to="/my-account/bookings">
            <Trans ns="paymentConfirmation" i18nKey="goToMyBookings">Go to My Bookings</Trans>
          </Link>
        </Button>
        <Paragraph smaller className="mt10 text-center ml10 mr10">
          <Trans ns="paymentConfirmation" i18nKey="cancellationWithRefundInfo">
            This Booking can be canceled with Full Refund up to
            <strong>{{ hours: minCancellationTime }}h</strong> before the Start Time.
          </Trans>
        </Paragraph>
      </div>
    </div>
  );
};

export default CourtBookingInfo;
