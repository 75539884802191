import styled from '@emotion/styled';

export const GameTypeTab = styled.div(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '50px',
  width: '95px',
  borderRadius: '4px',
  cursor: 'pointer',
  userSelect: 'none',

  '&:first-of-type': {
    margin: '0 10px 0 0',
  },

  ...active && {
    background: '#EAF6F6',
  },
}));

export const TabPlaceholder = styled.img({
  width: '150px',
});

export const RefPosition = styled.div({
  position: 'absolute',
  top: '-150px',
});
