export default {
  unregistered: {
    title: 'Стани приятел на Sportya. Играй най-добрите си тенис мачове!',
    subtitle: 'Създай безплатно своя профил и играй в Silver турнирите* със 100% отстъпка от Sportya таксата** за цяла година',
    eventsTotal: '25 Турнири във Вашата страна',
    playersTotal: 'Повече от 700 тенисиста във Вашия регион',
    joinSportya: 'Присъедини Sportya',
    list: {
      item1: 'Национален тенис календар за любители',
      item2: '6 нива на игра, от начинаещи до напреднали',
      item3: 'Персонален съзтезателен профил и още',
      item4: 'Формат на мачовете: 2 нормални сета с шампионски тайбрек при сет на сет',
    },
  },
  free: {
    title: 'Вземи Премиум акаунт и участвай в SIlver и Gold* турнирите със 100% отстъпка от Sportya таксата**',
    subtitle: 'Включи се в топ-събитията като Platinium турнир и Турнир на шампионите',
    eventsTotal: '{{amount}} Турнири във Вашата страна',
    list: {
      item1: {
        title: 'Топ турнири',
        desc: 'Присъедини се към събитията от големия шлем с повече от 200 играча, в 6 страни. Подобрявай ранкинга си и се състезавай с най-добрите на Турнира на шампионите',
      },
      item2: {
        title: 'Страхотна оферта',
        desc: 'Колкото повече играеш, толкова повече спестяваш. Вземи отстъпка до 12$ с всяко записване.',
      },
    },
  },
  readme: `*1st Serve, Silver, Gold, Platinum, Специален, и Турнир на шампионите. Всяка категория турнири носи различни награди и точков актив, и предлага различно предизвикателство. За всеки турнир ние организираме едно или повече състезания според нивата на игра, както за сингъл така и за двойки. 
**Sportya таксата се заплаща онлайн и покрива разходите за организиране на събитието. Това ни помага да поддържаме платформата и да предлагаме качествени услуги за нашите клиенти.`,
};
