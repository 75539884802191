/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import Menu from './components/menu';
import { Container } from './styles';

const TermsConditions = () => {
  const { t } = useTranslation('rules');

  const menuItems = [];
  const sections = [];

  for (let j = 0; j < 21; j++) {
    const sectionNr = j + 1;

    menuItems.push({
      id: `section${sectionNr}`,
      title: t(`terms.section${sectionNr}.subtitle1`),
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle2')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-3 position-relative">
          <Menu menuItems={menuItems} />
        </div>
        <div className="col-sm-9">
          <H1 className="mb30">{t('terms.mainTitle')}</H1>
          {sections.map((item, key) => (
            <div key={key} id={`section${key + 1}`}>
              {item}
            </div>
          ))}
          <div id="section1">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section1.subtitle">
                  SUBJECT OF THE TERMS AND CONDITIONS AND ACCEPTANCE THEREOF
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section1.p1">
                  These terms and conditions of use (hereinafter referred to as the "Terms of Use") govern the use of (including access to) the website Sportya.net (hereinafter referred to as the "Website"), operated globally by Media Production Group S.R.L. (hereinafter referred to as "MPG" or "us"), a limited liability company, legally incorporated and operating in compliance with the laws of Romania, having its registered office in Câmpina, 15 Mărăsesti Street, Prahova County, ROMANIA, post code 105600, registered with Prahova Trade Register under no. J29/1130/1997, having Unique Registration Code 9815911, Tax Registration Code / VAT code RO9815911, including the content made available through this Website.
                  Any person accessing the Website ("you") accepts these Terms of Use in effect at the time of your visit to the Website and agrees to comply with these Terms of Use. These Terms of Use are subject to change from time to time, and your use of this Website is subject to the Terms of Use valid at the time of your use of the Website. Please check these Terms of Use regularly to ensure that you agree to them. If you do not agree, please do not use the Website.

                  Visiting, using, or purchasing services / products on the Website imply the acceptance of the Terms of Use.

                  The Website provides information support regarding the matches and tournaments / events organized under the auspices of Sportya, offering a mechanism for enrolling amateur players in Sportya, a system for self-assessment of the level of play, a database containing players registered in the system, their personal pages, an information system on the tournaments listed in the competitions calendar of the current year, as announced and approved by the organizing tennis clubs, a system for reporting of the results of the matches played between registered players and those recorded in tournaments within Sportya, an algorithm for calculating the individual score of each registered player and an overall ranking based on these scores, but also others (collectively referred to as "Services"). The Use of the Services is governed by a series of rules that must be observed by all users of the Website.

                  Once you use the Services, you agree with the terms of the Terms of Use and you are responsible for complying with them, whether you are a mere "Visitor" (you have entered the Website without registering) or a "Member" / "User" (you have registered in the system and you have an account and a personal page). Registration in the system is necessary to have access to all the functionalities of the Website and all services. The registration procedure may be terminated by a prospective Member only after he / she has explicitly consented to the compliance with the Terms of Use.

                  Hereinafter, the term "User" includes both Members and Visitors. You have the right to use the services of the Website (whether your access to or use of the Website is accidental or intentional), only if you agree to comply with the relevant legislation and Terms of Use. If you do not agree with the Terms of Use, you must leave the Website and stop using the Services. Any subsequent access to the Website will be deemed as your acceptance of the terms and conditions set forth herein.

                  Any violation of the Terms of Use allows the MPG administrator to give warnings and ultimately restrict (at his sole discretion) the access of the user concerned, indefinitely. No user can invoke as an excuse for violation of the Terms of Use the fact that he/she did not know of their existence. MPG undertakes the obligation to post a link to the Terms of Use on the main page of the Website (Home Page) at all times, so that it can be easily identified and read.

                  The content we make available through the Website ("Content") may be governed by special terms ("Special Conditions") which you accept each time you access that Content. In the event of a conflict or inconsistency between the Terms of Use and any Special Conditions, the Special Conditions shall prevail over these Terms of Use. Each time you access the Website and its Content, you accept these Terms of Use and any Special Conditions applicable to that Content.

                  The access to the Website is temporarily permitted and we reserve the right to withdraw or modify the Content we provide on the Website without notice. From time to time, we may restrict the access to certain parts of the Website or the entire Website.

                  We have no obligation to make the Website available and will not be liable if, for any reason, the Website is not available, in whole or in part, at any time or for any period.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section2">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section2.subtitle">
                  USE OF THE WEBSITE
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section2.p1">
                  You acknowledge and agree voluntarily and expressly that your use of the Website is exclusively on your sole responsibility.
                  You are responsible for all arrangements necessary to access the Website. You are also responsible for ensuring that all persons accessing the Website through your internet connection are aware of and comply with these Terms of Use and any applicable Special Conditions.

                  If you choose or are provided with a user identification code, password or any other information, as part of our security procedures, you must treat this information as confidential and must not disclose it to any third party.

                  We have the right to deactivate at any time any user identification code or password, whether chosen by you or assigned by us, if, in our opinion, you have not complied with any of the provisions of these Terms of Use.

                  By accessing the Website, you agree that you will not take any action that could harm the image, interests or rights of us or those of any of our affiliates or that could harm the Website, render it unusable or overload it, or in any way impede the normal use of the Website by other visitors.

                  We implement reasonable security measures which are appropriate for detecting the existence of viruses. However, you should bear in mind that the existing security measures for computer systems on the internet are not entirely reliable and that, therefore, we cannot guarantee the absence of viruses or any other elements that could cause changes to your computer systems (hardware and software) or the data and files contained in your systems.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section3">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section3.subtitle">
                  CONTENT
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section3.p1">
                  We will have no obligation to verify the accuracy of the Content and do not guarantee the usefulness, accuracy, completeness or relevance of the Content and/or that such Content is up to date. We expressly exclude any liability for errors or omissions with respect to the Content and the Website, unless such liability is caused by fraud or fraudulent misrepresentation on our part.
                  The inclusion of the Content in the Website does not in any case constitute the provision of staffing services, consultancy or any other type of services. MPG and our affiliates expressly exclude any liability for decisions made by you, based on the Content.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section4">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section4.subtitle">
                  PRICES AND PAYMENT METHODS
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section4.p1">
                  Prices presented on the Website include all applicable taxes. The payment of services is made by various means - debit/credit card or bank transfer, as they are presented on the Website for each service.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section5">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section5.subtitle">
                  PROVISION OF SERVICES
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section5.p1">
                  The Benefits related to the purchase of a service (e.g., Premium Membership Account) are activated when the payment is confirmed. The right to participate in an event is activated after the online payment of the Sportya Service Fee, which represents the value of the digital services offered by the Website in connection with the event. Any deposit related to the crediting of the Virtual Wallet is carried out upon the receipt of the payment. The provision of all services will start during the 14-day withdrawal period. The users of the Website are informed during the procedure of service purchasing that the right of withdrawal will be lost after the complete execution of the purchased services.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section6">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section6.subtitle">
                  RETURN POLICY
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section6.p1">
                  A. On the Sportya.net website, you can pay the membership fee by purchasing one of the available accounts (e.g., Premium Account), you can register for a tennis tournament by paying an online fee representing the value of the digital services offered by the Website in connection with the progress of the event (Sportya Service Fee), you can credit your Virtual Wallet with an amount to be used later, and you can buy products associated with the registration in a competition, as part of the participation kit that you will receive when you present yourself at the tennis club / sports center to register your presence in the respective competition.
                  B. If after purchasing on Sportya.net one of the mentioned services / products, you want to give up the purchase and implicitly to receive your money back, you can apply for the refund of the products within 14 days, according to the Return Policy regulations, available on the Website, including during the event registration procedure.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section7">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section7.subtitle">
                  INTELLECTUAL PROPERTY RIGHTS
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section7.p1">
                  All Content on the Website is either owned by us or licensed by us for use. Everything you see or read on this Website (such as images, photographs, illustrations, texts, videos, results, tables, figures, together with the method for the recording and processing of the information, including the algorithm for the calculation of the personal score, and the generation of the ranking and other materials) is protected worldwide by copyright, design, trademark and other laws relating to intellectual property rights.
                  You must always comply with all intellectual property rights relating to the Website and the Content, whether owned by us, any of our affiliates or third parties. You must not obtain or attempt to obtain any part of the Content by any means or procedures other than those made available to you by the Website.

                  These Terms of Use or your use of the Website do not in any way grant you any other intellectual property rights to the Website or Content, other than those specified herein or under any Special Conditions.

                  Therefore, you are expressly prohibited from making any reproduction, transformation, distribution or public communication, or from making available, extracting, reusing, resubmitting or otherwise using, by any means or procedure, any part of the Website or the Content, except as permitted by these Terms of Use or any relevant Special Conditions, or unless the applicable law allows you to do so, or when you have been provided with an explicit authorization by the relevant copyright holder.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section8">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section8.subtitle">
                  LINKS AND USE OF LINKS
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section8.p1">
                  Links to other websites operated by third parties that are not affiliated with our Website may be indicated on the Website. The inclusion of any link to these third-party sites does not imply endorsement by us of these sites, and we decline any responsibility for any third-party sites, to which or from which there are links on this Website.
                  We have not reviewed all websites to which links appear on the Website and we are not responsible for the content or accuracy of any pages outside the Website or any other sites to which there are links on the Website. Tracking any link to any other page outside of the Website or to any third-party site is carried out at your own risk.

                  Any link to our Website from a third-party website requires our prior written permission.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section9">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section9.subtitle">
                  PERSONAL INFORMATION
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section9.p1">
                  The collection and use of your personal information are carried out in compliance with the provisions of Regulation 2016/679 applicable in all Member States of the European Union, being governed by the USER/MEMBER DATA PRIVACY STATEMENT, which is provided as a separate document and is available on the Website.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section10">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section10.subtitle">
                  USE OF REMOTE COMMUNICATION
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section10.p1">
                  For proper operation, the Website involves communication with users who sign up for tennis events / tournaments, in connection with calendar tournaments, reservation of participation, match programming, withdrawals, penalties, and many more. To be effective, this communication uses several techniques of remote communication: electronic mail (e-mail), telephone with human intervention, SMS on mobile phone. By using the Services and the Website, all users agree to use and receive messages on the aforementioned communication channels, as long as they facilitate the use of the services and improve the experience.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section11">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section11.subtitle">
                  CONTENT POSTED BY USERS ON THE WEBSITE
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section11.p1">
                  The Website allows Members to post pictures and transmit to other members through its functionalities various information in text format, including links or other materials (collectively referred to as "User’s Content"). The photos must be relevant and comply with certain technical characteristics (described on the Website), and the User’s Content must not be offensive, illegal or infringe, harm or pose a threat to other users, otherwise MPG may delete such pictures or User’s Content at its discretion. However, MPG undertakes no responsibility for monitoring the services with regard to inappropriate photos, content or behavior of users. By sending messages to other users and attaching pictures to the Website, you agree that you are solely responsible and will indemnify the Website and / or MPG for any damages, losses or costs incurred as a result of the attachment, transmission or your reference to a User’s Content that breaches the above provisions. If you consider that you have been harmed by messages received through the internal messaging system or by displaying pictures on users' personal profiles:
                  - If a person considers that his / her copyright has been infringed or that damage has been caused by sending messages on the Website, we cannot disclose the personal data of the author of the message, as the communication of such data to third parties is not allowed without the express agreement of the data subject, in compliance with the provisions of art. 5(1) of the Romanian Law 677/2001.

                  - If you consider yourself wronged in any way due to a message received through the internal messaging system, you can proceed to notify the judiciary or other body empowered by law. We will comply and respond to requests from such a public authority, as long as they comply with the legal requirements, and we will disclose the data of its author to the competent authority and comply with its provisions, if applicable.

                  The Website offers exclusive functionalities to its users, through which they can report their tennis matches played with other users, which have an effect on the ranking automatically generated by the algorithm for calculating the individual score of each registered user. The responsibility for the correct registration of personal data and those related to the results of matches held between users lies entirely with them, as MPG makes no warranty, express or implied, regarding the correctness and accuracy of such information.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section12">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section12.subtitle">
                  CHAT & MESSAGING
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section12.p1">
                  The Chat feature on the Website is a private and encrypted application intended for users to discuss and share their thoughts and experiences related to sports, and provide support to players seeking match or training opportunities.

                  The following behaviors are strictly prohibited on the Chat feature and may result in a permanent ban from the platform:

                  1. Harassment or Bullying: Any behavior that is intended to harass, intimidate, or cause distress to another user is strictly forbidden. This includes, but is not limited to, sending threatening or aggressive messages, spreading rumors or false information about another user, or engaging in cyberbullying.

                  2. Bad Words: Any use of profanity or offensive language is strictly forbidden. This includes, but is not limited to, using racial slurs, homophobic language, or any other language that is intended to offend or hurt another person.

                  3. Hate speech of any kind: Any expression of hatred or bigotry towards a particular group of people is strictly forbidden. This includes, but is not limited to, expressions of anti-Semitism, racism, sexism, or homophobia.

                  4. Sharing of Sexual, Explicit, Pornography, or Child abuse comments or content: Any sharing of sexually explicit, pornographic, or child abuse related content is strictly forbidden. This includes, but is not limited to, sharing images, videos, or text that is sexual in nature or that depicts violence or exploitation.

                  5. Sharing of links or URLs containing phishing, viruses, malware or spyware: Any sharing of links or URLs that are intended to phish for personal information, distribute viruses, malware, or spyware is strictly forbidden.

                  6. Spam: Any behavior that is intended to send repetitive, unsolicited messages or comments that are not related to sports or the conversation on the platform is strictly forbidden.

                  We reserve the right to permanently ban any user who engages in prohibited behavior on the Chat feature.
                  By using the Chat feature on Sportya, you agree to abide by these terms and conditions and to refrain from engaging in any of the prohibited behaviors outlined above. We reserve the right to modify these terms and conditions at any time. If you have any questions or concerns about these terms and conditions, please contact us at contact@sportya.net
                </Trans>
              </P>
            </div>
          </div>

          <div id="section13">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section13.subtitle">
                  SPORTYA REWARDS & MEMBER-GET-MEMBER
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section13.p1">
                  Participation in the Member-get-Member feature implies the user's agreement to comply with these terms, as well as any additional guidelines or rules provided by Sportya. It is important to carefully read and understand these terms before participating.

                  Eligibility:
                  - Users who create their Sportya accounts using the referral codes of existing Sportya users will be eligible for rewards.
                  - Existing Sportya users who invite new players using their unique referral code will also be eligible for rewards.

                  Reward Criteria:
                  - New Users:
                  -- New users who sign up on Sportya using a referral code will receive a reward of 5 Euro or the equivalent in the currency of their Virtual Wallets on Sportya.
                  -- The reward will be granted to new users once they complete one of the following eligible actions for the first time.
                  -- Obtaining their First Validated Result in a Sportya tournament
                  -- Topping up their Virtual Wallet
                  -- Purchasing a Premium account with Virtual Wallet or Credit Card
                  -- Participating in a Friendly Match with a validated score for the first time
                  -- Each user will receive the reward only once.

                  - Existing Users:
                  -- Existing users who successfully refer new players using their unique referral code will receive a reward of 5 Euro, or the equivalent in the currency of their Virtual Wallets, for each of these players who successfully creates an account on Sportya and completes one of the same eligible actions mentioned for new users for the first time:
                  -- Obtaining their First Validated Result in a Sportya tournament
                  -- Topping up their Virtual Wallet
                  -- Purchasing a Premium account with Virtual Wallet or Credit Card
                  -- Participating in a Friendly Match with a validated score for the first time
                  -- The maximum number of invited players for which an existing user can be rewarded is 10.

                  Reward Distribution:
                  - Rewards will be distributed to eligible users within a reasonable time after the completion of the eligible action by the referred user.
                  - The rewards will be credited to the Virtual Wallet of the users in the currency equivalent mentioned in the reward criteria section.

                  General Conditions:
                  - Users are prohibited from engaging in fraudulent, misleading, or deceptive activities to obtain referrals or rewards. Any such behavior will result in disqualification from the Member-get-Member feature and may lead to the suspension or termination of the user's Sportya account.
                  - Sportya reserves the right to verify the eligibility of users and the validity of referral actions. Violation of these terms or suspected abuse of the Member-get-Member feature will result in the forfeiture of rewards.
                  - Sportya reserves the right to modify, suspend, or terminate the Member-get-Member feature or these terms at any time without prior notice. Reasonable efforts will be made to notify users in the event of such changes.
                  - Users are responsible for any applicable taxes, fees, or other liabilities arising from participating in the Member-get-Member feature and receiving rewards.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section14">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section14.subtitle">
                  PROHIBITED ACTIVITIES
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section14.p1">
                  On the Website, the following user activities are strictly prohibited:
                  a. the creation and use of "clones" (multiple accounts of the same user). The discovery by the Website administrator of these accounts will result in the permanent suspension of clone accounts and the suspension of the main account for an indefinite period.

                  b. through the internal mail system of the Website, harassment, insulting in any form of the users of the Website, the use of obscene, threatening, abusive or indecent words, abusive, offensive, threatening or defamatory behavior, the transmission of information that is known to be false, misleading or promoting illegal activities.

                  c. the transmission of information, files, etc. to users in violation of copyright and related rights provided by Romanian Law  8/1996, as well as any other rights of third parties. All copyright on the photos attached to the Website belong to the authors. Their reproduction (on any media) without the permission of the authors is a violation of the laws in force. If you wish to use them, please contact the authors of the works. MPG assumes no obligation to protect the photos displayed on the Website, to compensate the authors or to help the authors to be compensated for the damage they have suffered.

                  d. sending the same message repeatedly and abusively or sending similar messages to multiple users ("flood").

                  e. sending unsolicited commercial messages to other users, even if done through the internal messaging system, sending "unsolicited mail", "chain letters" or mass mail, etc.

                  f. the collection of any personally identifiable information from users, the request from other users of personally identifiable information or password for commercial or illegal purposes, the use of another user's account, password or personal page. MPG reserves the right to investigate and take appropriate legal action against any person who, at its sole discretion, violates these provisions, including, without limitation, removing inappropriate content from the Website or stopping access to the Website services for such users. In addition, in case of suspicion on the legality of the messages posted, MPG may report the situation to the authorities able to apply the law, this fact including the disclosure to these authorities of the personal data of those concerned.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section15">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section15.subtitle">
                  GOVERNING LANGUAGE
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section15.p1">
                  The information on the Website, including the documents underlying the operation of Sportya (e.g., Sportya Rulebook regarding the participation in events, the Return Policy, etc.) are translated / offered in several language versions. All language versions are deemed equally authentic. In the event of any discrepancy between the versions, the English version will prevail in determining the spirit, intention and meaning of an aspect.
                  For all purposes, the English language version of the Content, regardless of its nature (descriptive text used in the Website, document, etc.) will be the original, governing tool.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section16">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section16.subtitle">
                  COMPENSATION
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section16.p1">
                  You agree to indemnify and exonerate MPG, together with its employees, management and shareholders, in connection with any claim or request (including reasonable attorneys\' fees) from a third party arising out of or in connection with your use of the Website\'s internal messaging system, your breach of the Terms of Use, or infringing any intellectual property rights made from your computer. MPG reserves the right, at its own expense, to ensure its exclusive defense and control over any matter that would otherwise be the subject of compensation on your part.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section17">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section17.subtitle">
                  WARRANTIES, LIMITATION OF LIABILITY
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section17.p1">
                  We do not warrant or represent that the Website and/or Content are accurate, complete, error-free or reliable or that the use of the Website and/or Content will not infringe the rights of third parties. We do not warrant or represent that the functional aspects of the Website and/or the Content will be error-free or that the servers that make it available do not contain viruses or other harmful components.
                  The use of the Website and/or the Content is carried out at your own risk and all items on the Website are made available to you "AS SUCH" and "AS AVAILABLE" without any express or implied warranty.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section18">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section18.subtitle">
                  APPLICABLE LAW AND JURISDICTION
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section18.p1">
                  The Terms of Use, the rights and obligations imposed by them, and all legal effects produced by the Terms of Use will be construed based on and governed by the Romanian law. Romanian law will govern all aspects that were not provided for by the Terms of Use. Any dispute regarding the Terms of Use will be referred to the competent courts in Romania.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section19">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section19.subtitle">
                  DISPUTES
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section19.p1">
                  By using, viewing or purchasing the services on the Website, the user is aware that the Romanian laws will govern the above Terms of Use and any dispute of any kind that may arise between the user and MPG. In the event of any conflicts between MPG and the users of the Website, we will first try to settle them amicably within a minimum of 30 business days. If amicable settlement will not be possible, an attempt will be made to find a solution without resorting to the courts, through the use of the online platform https://ec.europa.eu/consumers/odr/ provided by the European Commission. If none of the previous ways have led to a solution, the conflict will be settled in the competent court in compliance with the Romanian laws in force.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section20">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section20.subtitle">
                  INDEPENDENCE
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section20.p1">
                  If any of the above clauses is found to be void or invalid, for whatever reason, it will not affect the validity of the other clauses. With the launch of an order on the Website, the customer accepts without objection the Terms of Use, their value being the same as a valid contract concluded. The withdrawal from the contract is regulated in the Return Policy. By using the services of the Website, the customer agrees to these Terms of Use and fully undertakes the rights and obligations arising from the purchase of services on the Website.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section21">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section21.subtitle">
                  AMENDMENTS
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section21.p1">
                  These Terms of Use may vary from time to time, and your use of the Website is subject to the current terms at the time you access the Website and its Content. Please check these Terms of Use regularly to ensure that you are familiar with them.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section22">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section22.subtitle">
                  CONTACT
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section22.p1">
                  Questions, comments and requests regarding these Terms of Use may be directed to us via corporate@sportya.net.
                </Trans>
              </P>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TermsConditions;
