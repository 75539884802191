import styled from '@emotion/styled';
import LoadImage from '../common/LoadImage';

export const Badge = styled.div(props => ({
  ...props.position && { position: props.position },
  ...props.top && { top: props.top },
  ...props.left && { left: props.left },
  ...props.right && { right: props.right },

  display: props.display ?? 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: props.small ? '16px' : props.medium ? '21px' : '50px',
  height: props.small ? '18px' : props.medium ? '21px' : '56px',
  background:
    props.gold ? `url(${LoadImage('shield-gold.svg')})`
      : props.silver ? `url(${LoadImage('shield-silver.svg')})`
        : props.double ? `url(${LoadImage('shield-double.svg')})`
          : `url(${LoadImage('shield.svg')})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  margin: props.large ? '5px 15px 15px 15px' : props.margin ?? '0 2px',
  p: {
    color: `${props.gold ? props.theme.colors.gold : props.double ? '#10242C' : props.theme.colors.white}`,
    padding: '0 0 2px 0 !important',
  },
  h3: {
    color: `${props.gold ? props.theme.colors.gold : props.double ? '#10242C' : props.theme.colors.white}`,
    padding: `${props.padding ? props.padding : '0 0 6px 0px'}`,
    margin: 0,
    ...props.fontSize && { fontSize: props.fontSize },
  },
}));
