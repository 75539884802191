import styled from '@emotion/styled';
import { Link } from '../../../../components/Collection';

export const TablePlayerContainer = styled.div({
  display: 'flex',
  padding: '10px 0 20px 0',
  borderBottom: '1px solid #EAF6F6',
  alignItems: 'center',
  '@media (max-width: 850px)': {
    flexDirection: 'column',
    alignItems: 'unset',
  },

  '&:last-of-type': {
    borderBottom: 'unset',
  },
});

export const PlayerInformationContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '505px',
  width: '100%',
});

export const PlayerInformationResponsiveContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 850px)': {
    flexDirection: 'column',
    alignItems: 'unset',
  },
});

export const PlayerInformation = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 0 0 10px',
  width: 'max-content',

  '> a:hover': {
    textDecoration: 'none',
  },
});

export const PlayerUsername = styled.div({
  display: 'flex',
});

export const RelativeContainer = styled.div({
  position: 'relative',
  '> a:hover': {
    textDecoration: 'none',
  },
});

export const LevelBadgesContainer = styled.div({
  position: 'absolute',
  bottom: '-12px',
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
});

export const RaisedHandContainer = styled.div({
  margin: '0 0 0 35px',
  '@media (max-width: 850px)': {
    margin: '20px 0 0 0',
  },
});

export const OnlyMobileText = styled.div({
  display: 'none',
  '@media (max-width: 850px)': {
    display: 'inline-block',
    fontWeight: 400,
  },
});

export const PlayerStats = styled.div({
  display: 'flex',
});

export const PlayerStatsColumn = styled.div(({ customMinWidthOnDesktop }) => ({
  display: 'flex',
  minWidth: customMinWidthOnDesktop,
  '@media (max-width: 850px)': {
    justifyContent: 'space-between',
    margin: '20px 0 0 0',
    minWidth: 'unset',
    maxWidth: '320px',
    width: '100%',
  },
}));

export const PlayerInfoLink = styled(Link)({
  '@media (max-width: 380px)': {
    display: 'inline-block',
    maxWidth: '220px',
  },
});
