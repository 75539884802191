import React, { useContext, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import aes from 'crypto-js/aes';

import { store } from './store';
import { socket as setSocket } from './actions';

const Socket = ({ connectionUrl }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { accountInfo } = useSelector(state => state?.session);

  useEffect(() => {
    if (accountInfo !== null) {
      const socket = io(connectionUrl, {
        transports: ['websocket'],
        // upgrade: false,
      });

      if (accountInfo?.userId) {
        socket.auth = {
          id: accountInfo?.id,
          userId: accountInfo?.userId,
          token: aes.encrypt(accountInfo?.id, process.env.REACT_APP_ENC_KEY).toString(),
        };
      }
      socket.on('connect', () => {
        setSocket({ socketClientId: accountInfo?.id ?? socket.id, socket })(dispatch);
      });

      // socket.io.on("ping", () => {
      //   console.log('ping', new Date());
      // });
    }
  }, [accountInfo, connectionUrl, dispatch]);

  return <></>;
};

export default Socket;
