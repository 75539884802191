export default {
  title: 'Najít hráèe',
  seoTitle: 'Hráèi | Sportya',
  playerProfileSeoTitle: '{{firstName}} {{lastName}} - Hráèi | Sportya',
  allPlayers_tennis: 'All Tennis Players',
  allPlayers_padel: 'All Padel Players',
  singleLevel: 'Úroveò pro dvouhry',
  singleLevel_tennis: 'Úroveò pro dvouhry',
  singleLevel_padel: 'Úroveò pro dvouhry',
  doubleLevel: 'Úroveò pro ètyøhru',
  doubleLevel_tennis: 'Úroveò pro ètyøhru',
  doubleLevel_padel: 'Úroveò pro ètyøhru',
  playerHand: 'Hrající ruka',
  availabilityFriendly: 'Dostupnost pro pøátelská utkání',
  availabilityFriendlyNotSet: 'Dostupnost pro pøátelská utkání nebyla dosud nastavena',
  activity: 'Èinnost',
  titles: 'Názvy',
  leaderboards: 'Hodnocení',
  levelNotSet: 'Úroveò nebyla nastavena',
  singles: 'Dvouhry',
  doubles: 'Ètyøhry',
  points: 'Body',
  stats: 'Statistiky',
  pickYear: 'Rok',
  allActivities: 'Všechny aktivity',
  level: 'Úroveò',
  round: 'Soutìžní kolo',
  oponent: 'Soupeø',
  eloRating: 'Hodnocení ELO',
  leaderboard: 'Hodnocení',
  score: 'Stav hry',
  wL: 'W/L',
  winStatusShorthand: 'W',
  loseStatusShorthand: 'L',
  woStatusShorthand: 'W.O.',
  eloDescription: 'Hodnocení pøed turnajem: ',
  champion: 'Šampion',
  finalist: 'Finalista',
  week: 'Týden',
  calculationDate: 'Datum výpoètu',
  rank: 'Hodnocení',
  move: 'Pøesun',
  bestResults: 'Nejlepší výsledky',
  pointsSummary: 'Souhrn bodù',
  playerRank: 'Hodnocení hráèe',
  totalPoints: 'Celkový poèet bodù',
  competitionPoints: 'Soutìžní body',
  friendlyMatchPoints: 'Body v pøátelském utkání',
  basePoints: 'Základní body',
  penaltyPoints: 'Trestné body',
  playerFound: '<0>{{ totalResults }} hráè</0> nalezen v <2>{{ location }}</2>',
  playersFound: '<0>{{ totalResults }} hráèi</0> nalezeni v <2>{{ location }}</2>',
  playerFoundNc: '<0>{{ totalResults }} hráè</0> nalezen',
  playersFoundNc: '<0>{{ totalResults }} hráèi</0> nalezeni',
  changePhoto: 'Zmìnit fotografii',
  setYourGameLevel: 'Zvolit úroveò hry',
  gameLevel: 'Úroveò hry',
  setYourAvailability: 'Nastavit dostupnost',
  availabilityForFriendlyMatches: 'Dostupnost pro pøátelská utkání',
  sendMessage: 'Send Message',
  myEvents: 'Mé události',
  mySettings: 'Moje nastavení',
  newestPlayers_tennis: 'Nejnovìjší hráèi',
  newestPlayers_padel: 'Nejnovìjší hráèi',
  player: 'Hráè',
  friendlyMatches: 'Pøátelská utkání',
  registrationDate: 'Datum registrace',
  findAPlayer_tennis: 'Najít hráèe',
  findAPlayer_padel: 'Najít hráèe',
  joined: 'Pøipojil(a) se',
  upgradedAt: 'Úroveò byla upravena na',
  best7results: 'Nejlepších 7 výsledkù',
};
