import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get } from '../../../components/common/http';
import { setFriends, startLoading } from '../../../components/Friends/reducers';

function friendsHook(filters, page, friends) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();
  const batchElements = 25;

  useEffect(() => {
    dispatch(setFriends([]));
  }, [filters]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(startLoading());
    setError(false);

    let filtersString = `?status=${filters.status}`;
    filtersString += filters.sortBy ? `&sortBy=${filters.sortBy}` : '&sortBy=firstName';
    filtersString += `&limit=${batchElements}`;
    filtersString += page ? `&page=${page}` : '&page=1';

    get(`/friends${filtersString}`).then((res) => {
      const { data } = res.data;
      if (friends.results && data.results && page > 1) {
        data.results = [...new Set([...friends.results, ...data.results])];
      }
      dispatch(setFriends(data));
      setHasMore(data?.results?.length < res?.data?.data?.totalResults);
    });

    setIsLoading(false);
  }, [filters, page]);

  return { isLoading, error, hasMore };
}

export default friendsHook;
