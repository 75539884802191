import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { Global, css, ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import Socket from './components/Socket';
import { StateProvider as SocketProvider } from './components/Socket/store';
import store from './store';
import globalStyle from './assets/styles/index.scss';
import './translations';
import routes from './routes';
import theme from './theme';
import LoadImage from './components/common/LoadImage';

const App = () => {
  const { functions } = theme;
  const { t } = useTranslation('common');
  const baseRouteUrl = '/:locale(en|ro|bg|hu|cz)?';

  ReactGA.initialize(process.env.REACT_APP_GOGLE_ANALYTICS_ID);

  return (
    <ThemeProvider theme={{ ...{ functions, ...theme?.default } }}>
      <Provider store={store}>
        <SocketProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_SITE_NAME}</title>
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={`${window.location.origin}/`} />
            <link rel="alternate" href={`${window.location.origin}/`} hrefLang="x-default" />
            <link rel="alternate" href={`${window.location.origin}/ro`} hrefLang="ro" />
            <link rel="alternate" href={`${window.location.origin}/bg`} hrefLang="bg" />
            <link rel="alternate" href={`${window.location.origin}/hu`} hrefLang="hu" />
            <meta httpEquiv="Content-Language" content="en" />
            <meta name="description" content={t('metaDescription')} />
            <meta name="keywords" content={t('metaKeywords')} />
          </Helmet>
          <meta property="og:title" content={t('ogTitle')} />
          <meta property="og:description" content={t('ogDescription')} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={process.env.REACT_APP_WEB_URL} />
          <meta property="og:site_name" content={process.env.REACT_APP_SITE_NAME} />
          <meta property="og:image" content={process.env.REACT_APP_WEB_URL + LoadImage('sportya_og.jpg')} />
          <meta property="twitter:card" content={process.env.REACT_APP_WEB_URL + LoadImage('sportya_og.jpg')} />
          <meta property="twitter:url" content={process.env.REACT_APP_WEB_URL} />
          <meta property="twitter:title" content={t('ogTitle')} />
          <meta property="twitter:description" content={t('ogDescription')} />

          <Global styles={css`${globalStyle}`} />
          <ToastProvider autoDismiss autoDismissTimeout={6000}>
            <Socket connectionUrl={process.env.REACT_APP_SOCKET_URL} />
            <Router>
              <Switch>
                {routes.map(
                  ({ Layout, ...props }) => (
                    <Route key={props.name} exact path={baseRouteUrl + props.route}>
                      <Layout {...props} />
                    </Route>
                  ),
                )}
              </Switch>
            </Router>
          </ToastProvider>
        </SocketProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
