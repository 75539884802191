import React, {
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { Container } from './styles';

import { store as socketStore } from '../../components/Socket/store';
import {
  AddMessage,
  ConversationContent,
  ConversationsList,
} from './components';
import {
  Button, H2, H3, Paragraph,
} from '../../components';
import LoadImage from '../../components/common/LoadImage';
import Link from '../../components/Link';
import ConversationHeader from './components/ConversationHeader';
import SearchBar from './components/SearchBar';
import ContentLoader from '../../components/ContentLoader';

import {
  fetchConversations,
  pushMessageToConversation,
  removeConversationFromList,
  addConversationToList,
  setMuteConversation,
  setUnmuteConversation,
  updateLastMessage,
  updateMessageInfo,
  setNewMessages,
  updateMembersList, setLeftConversation,
} from './reducers';

import { addConversation } from './actions';
import mixpanel from '../../mixpanel';

const systemMessages = [
  'USER_HAS_LEFT_CONVERSATION',
  'PLAYER_ADDED_TO_CONVERSATION',
];

const shortName = (data) => {
  if (!data?.firstName || !data?.lastName) return;

  return `${data.firstName} ${data.lastName?.charAt(0)}.`;
};

const initiateConversation = async ({ player, addToast, t }) => {
  const payload = {
    members: [player],
  };

  await addConversation({
    payload,
    addToast,
    t,
  });
};

const Chat = () => {
  const { conversationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { t } = useTranslation('conversations');
  const globalState = useContext(socketStore);
  const { state: { socket: { socket } = {} } = {} } = globalState;
  const lastMessageRef = useRef(null);

  const { accountInfo } = useSelector(state => state?.session);
  const { list: { data: conversations, status } } = useSelector(state => state?.conversations);

  const [typingMessages, setTypingMessages] = useState({});
  const [disconnected, setDisconnected] = useState(false);

  const [player, setPlayer] = useState(null);
  const [findPlayerSearchbar, setFindPlayerSearchbar] = useState(false);

  const [mobileToggleContent, setMobileToggleContent] = useState(!!conversationId);

  useEffect(() => {
    dispatch(fetchConversations());
  }, [accountInfo]);

  // initiate new conversation
  useEffect(() => {
    if (!isEmpty(player) && !isEmpty(accountInfo)) {
      initiateConversation({
        player,
        addToast,
        t,
      });
    }
  }, [player]);

  useEffect(() => {
    if (conversationId && conversations.length) {
      setFindPlayerSearchbar(false);
    }

    if (!conversationId && conversations.length) {
      setMobileToggleContent(false);
    }
  }, [conversationId, conversations]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (!success) {
        addToast(t(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
          maxOpened: 1,
        });
      }

      switch (message) {
      case 'SUCCESSFULLY_ADDED_MESSAGE':
        // send to all participants except sender
        if (data?.messageInfo?.userId !== accountInfo?.userId) {
          // add conversation to list if was previous deleted and new message was received
          const conversationExist = conversations.findIndex(({ id }) => id === data?.messageInfo?.conversationId);
          if (conversationExist === -1 && !isEmpty(data?.conversationInfo)) {
            dispatch(addConversationToList({
              ...data?.conversationInfo,
              newMessages: 0,
            }));
          }

          // increment conversation new messages received && user doesn't have conversation muted
          if (!(data?.conversationInfo?.mutedBy || [])?.includes(accountInfo?.userId)) {
            dispatch(setNewMessages(data?.messageInfo?.conversationId));
          }

          // append message to conversation
          dispatch(pushMessageToConversation({
            ...data?.messageInfo,
          }));

          // update conversation list last message
          dispatch(updateLastMessage({
            conversationId: data?.messageInfo?.conversationId,
            lastMessage: data?.messageInfo,
          }));
        }

        // if sender is owner, update message state with database id and update status
        if (data?.messageInfo?.userId === accountInfo?.userId) {
          dispatch(updateLastMessage({
            conversationId: data?.messageInfo?.conversationId,
            lastMessage: {
              message: data?.messageInfo?.message,
              createdAt: data?.messageInfo?.createdAt,
              status: 'sent',
            },
          }));
          dispatch(updateMessageInfo({
            tempId: data.tempId,
            id: data.messageInfo?.id,
            status: 'sent',
          }));
        }
        break;
      case 'SUCCESSFULLY_DELETED_MESSAGE':
        await dispatch(updateMessageInfo({
          id: data.messageId,
          status: 'deleted',
        }));
        break;
      case 'TYPING_MESSAGE':
        setTypingMessages({
          ...typingMessages,
          [data?.conversationId]: {
            isTyping: data.isTyping,
            users: data.conversationType === 'group'
              ? data.users.filter((value) => value !== shortName(accountInfo)).join(' & ')
              : [],
            conversationId: data?.conversationId,
          },
        });
        break;
      case 'SUCCESSFULLY_LEFT_CONVERSATION':
        addToast(t(message), {
          appearance: 'success',
          autoDismiss: true,
          maxOpened: 1,
        });

        await dispatch(setLeftConversation({
          conversationId,
          ...data,
        }));
        break;
      case 'SUCCESSFULLY_DELETED_CONVERSATION':
        addToast(t(message), {
          appearance: 'success',
          autoDismiss: true,
          maxOpened: 1,
        });

        await dispatch(removeConversationFromList(conversationId));

        history.push('/conversations');
        break;
      case 'SUCCESSFULLY_MUTED_CONVERSATION':
        addToast(t(message), {
          appearance: 'success',
          autoDismiss: true,
          maxOpened: 1,
        });

        await dispatch(setMuteConversation({ conversationId, userId: accountInfo?.userId }));
        break;
      case 'SUCCESSFULLY_UNMUTED_CONVERSATION':
        addToast(t(message), {
          appearance: 'success',
          autoDismiss: true,
          maxOpened: 1,
        });

        await dispatch(setUnmuteConversation({ conversationId, userId: accountInfo?.userId }));
        break;
      case 'SUCCESSFULLY_INITIATED_CHAT':
        // add message only if conversation is new
        if (data?.isNew) {
          await addToast(t(message), {
            appearance: success ? 'success' : 'error',
            autoDismiss: true,
          });
        }

        if (success) {
          await dispatch(addConversationToList(data));
          return history.push(`/conversations/${data.id}`);
        }
        break;
      case 'SUCCESSFULLY_ADDED_PLAYERS':
        await dispatch(updateMembersList({
          conversationId: data?.conversationId,
          members: data?.members,
          membersIds: data?.membersIds,
        }));
        break;
      default:
      }
    };

    if (socket) {
      socket.removeAllListeners('chat-response');

      socket.on('connect', () => setDisconnected(false));
      socket.on('disconnect', () => setDisconnected(true));
      socket.on('chat-response', listenForResponse);

      return () => socket.removeAllListeners('chat-response');
    }
  }, [conversationId, typingMessages, socket]);

  const isBlocked = (conversation) => {
    if (!conversation) return;
    const userIds = (accountInfo?.blockedPlayers || []).map(user => user?.blockedUser?.userId);

    return conversation?.type === 'individual' && (userIds || []).includes(conversation?.userInfo?.userId);
  };

  if (status === 'loading') {
    return (
      <Container className="loading-content">
        <ContentLoader title={false} />
      </Container>
    );
  }

  if (
    !conversationId && ['succeeded', 'loading'].includes(status)
    && isEmpty((conversations || []).filter(
      ({ lastMessage, initiatorId }) => !(isEmpty(lastMessage) && initiatorId !== Number(accountInfo?.userId)),
    ))
  ) {
    return (
      <>
        <Helmet>
          <title>{t('startChat')}</title>
        </Helmet>
        <Container className="no-conversations">
          <img src={LoadImage('conversations-big-icon.svg')} alt="" width={50} height={50} />
          <H3><Trans ns="conversations" i18nKey="startChat">Start a Chat</Trans></H3>
          <SearchBar
            setPlayer={setPlayer}
            userId={accountInfo?.userId}
            mixpanelEvent="Initiate Chat from Blank (Select Player for Chat from Blank)"
          />
        </Container>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <Helmet>
          <title>{t('chats')}</title>
        </Helmet>
        <Container className="no-conversations">
          <img src={LoadImage('close-icon.svg')} alt="" width={30} height={30} />
          <H3 className="mt25">
            <Trans ns="conversations" i18nKey="failedToLoadConversations">Failed to Load Conversations</Trans>
          </H3>
          <Button onClick={() => dispatch(fetchConversations())}>
            <span><Trans ns="conversations" i18nKey="tryAgain">Try Again</Trans></span>
          </Button>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('chats')}</title>
      </Helmet>
      <Container className={mobileToggleContent ? 'conversation-selected' : ''}>
        <aside>
          <div className="heading d-flex space-between">
            <H2><Trans ns="conversations" i18nKey="chats">Chats</Trans></H2>
            <div
              onClick={() => {
                setFindPlayerSearchbar(!findPlayerSearchbar);
                mixpanel.track('Initiate Chat from Conversations List (Click New Chat Button)');
              }}
            >
              <img
                className="cursor-pointer"
                src={LoadImage(!findPlayerSearchbar ? 'new-chat-icon.svg' : 'cancel-new-chat-icon.svg')}
                alt="New Chat"
              />
            </div>
          </div>

          {findPlayerSearchbar && (
            <div className="new-conversation">
              <SearchBar setPlayer={setPlayer} userId={accountInfo?.userId} />
            </div>
          )}

          <ConversationsList
            conversations={conversations}
            conversationId={conversationId}
            typingMessages={typingMessages}
            systemMessages={systemMessages}
            shortName={shortName}
            findPlayerSearchbar={findPlayerSearchbar}
            isBlocked={isBlocked}
          />

          <div className="text-center">
            <Link
              to="/friends"
              className="friends-list"
              onClick={() => {
                mixpanel.track('Access Friends from Chat (Click on See Friends List from conversations list)');
              }}
            >
              <img src={LoadImage('friends-icon.svg')} alt="" />
              <Paragraph bold>
                <Trans ns="conversations" i18nKey="seeFriendsList">See Friends List</Trans>
              </Paragraph>
            </Link>
          </div>
        </aside>

        <section className={!conversationId && !findPlayerSearchbar ? 'no-selected-conversation' : ''}>
          {findPlayerSearchbar && (
            <div className="new-conversation">
              <SearchBar
                setPlayer={setPlayer}
                userId={accountInfo?.userId}
                mixpanelEvent="Select Player for Initiated Chat (after selecting New Chat Button)"
              />
            </div>
          )}

          {!findPlayerSearchbar && conversationId && (
            <>
              <ConversationHeader
                conversationId={conversationId}
                setMobileToggleContent={setMobileToggleContent}
                isBlocked={isBlocked}
                typingMessages={typingMessages}
              />

              <ConversationContent
                conversationId={conversationId}
                userId={accountInfo?.userId}
                systemMessages={systemMessages}
                shortName={shortName}
                setMobileToggleContent={setMobileToggleContent}
                lastMessageRef={lastMessageRef}
              />

              <AddMessage isBlocked={isBlocked} lastMessageRef={lastMessageRef} />

              {disconnected
                && (
                  <div className="disconnected">
                    <Trans ns="conversations" i18nKey="disconnected">Disconnected. Trying to reconnect...</Trans>
                  </div>
                )}
            </>
          )}

          {!findPlayerSearchbar && !conversationId && (
            <div className="flex text-center center-middle align-items-center justify-content-center">
              <img src={LoadImage('placeholder-conversation.svg')} alt="" />
              <Paragraph medium bold className="color-secondary mt40">
                <Trans ns="conversations" i18nKey="openOrStartChat">
                  Open an existing conversation or <br /> start a new one to get things moving
                </Trans>
              </Paragraph>
            </div>
          )}
        </section>
      </Container>
    </>
  );
};

export default Chat;
