import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import ToggleVisible from '../../../../components/common/ToggleVisible';
import { get, post, remove } from '../../../../components/common/http';
import { H3, P } from '../../../../components/Collection';
import { clearMyEvents } from '../../../MyAccount/Events/reducers';
import { Button } from './player/styles';
import Player from './player';
import LoadImage from '../../../../components/common/LoadImage';
import { store as socketStore } from '../../../../components/Socket/store';
import { addConversation } from '../../../Chat/actions';
import { addConversationToList } from '../../../Chat/reducers';
import mixpanel from '../../../../mixpanel';

import {
  BannerContainer,
  ButtonIcon,
  CloseIcon,
  Container,
  FindOutMore,
  Modal,
  ModalBackground,
  PlayersList,
  TennisRacket,
} from './styles';
import { setEventInfo, setLFP, sortLFP } from '../../reducers';

const Index = ({ withPadding, noPadding }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { competitionId, eventId } = useParams();
  const { t: commonT } = useTranslation('common');
  const { accountInfo } = useSelector(state => state.session);
  const { data: eventInfo } = useSelector(state => state.events?.info);
  const { isComponentVisible, setIsComponentVisible, ref } = ToggleVisible(false, false);
  const [competitionInfo] = (eventInfo?.competitions || []).filter((v) => v._id === competitionId);
  const { lookingForPartners } = competitionInfo || {};
  const isOnLFP = lookingForPartners?.filter(player => player?.userId === accountInfo?.userId)[0];

  const handleLFPModal = async () => {
    mixpanel.track('Click on [See who\'s interested button] displayed on Doubles competitions');
    const { data: { data } } = await get(`/events/${eventInfo?._id}`);
    const idsOfUsersBlocked = (accountInfo?.blockedPlayers || []).map(user => user?.blockedUser?.userId);
    const idsOfUsersWhoBlocked = (accountInfo?.blockedPlayers || []).map(user => user?.user?.userId);
    const userIds = [...idsOfUsersBlocked, ...idsOfUsersWhoBlocked];

    dispatch(setEventInfo(data));
    dispatch(sortLFP({ playerUserId: accountInfo?.userId, blockedPlayers: userIds }));
    return setIsComponentVisible(!isComponentVisible);
  };

  const lfpNotEmpty = !!lookingForPartners?.length;
  const LFPTotal = lookingForPartners?.length;

  const globalState = useContext(socketStore);
  const {
    state: {
      socket: { socketClientId, socket },
    },
  } = globalState;

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (message === 'SUCCESSFULLY_INITIATED_CHAT') {
        const { isNew, id } = data || {};
        if (isNew) {
          addToast(commonT(message), {
            appearance: success ? 'success' : 'error',
            autoDismiss: true,
          });
        }

        if (success) {
          dispatch(addConversationToList(data));
          return history.push(`/conversations/${id}`);
        }
      }
    };

    if (socket) {
      socket.removeAllListeners('chat-response');
      socket.on('chat-response', listenForResponse);

      return () => {
        socket.removeAllListeners('chat-response');
      };
    }
  }, [socketClientId]);

  const contactPlayer = async player => addConversation({
    payload: {
      members: [player],
    },
    addToast,
    t: commonT,
  });

  const joinLFPList = async user => {
    dispatch(clearMyEvents({ type: 'lookingForPartners' }));
    if (isOnLFP) {
      const newLFPList = lookingForPartners?.filter(player => player?._id !== user?._id);
      const { data: { success } } = await remove(`/events/${eventId}/competitions/${competitionId}/lookingForPartner`);
      if (success) {
        return dispatch(setLFP({ id: competitionInfo?._id, list: newLFPList }));
      }
    }

    const { data: { success } } = await post(`/events/${eventId}/competitions/${competitionId}/lookingForPartner`);
    if (success) {
      return dispatch(setLFP({ id: competitionInfo?._id, list: [user, ...lookingForPartners] }));
    }
    return null;
  };

  return (
    <>
      <BannerContainer noPadding={noPadding}>
        <Container withPadding={withPadding}>
          <P bold textAlign="center">
            <Trans ns="events" i18nKey="lfp.noPartner">{'Don\'t have a partner for doubles yet?'}</Trans>
          </P>
          <Button lfpBanner onClick={() => handleLFPModal()}>
            <ButtonIcon src={LoadImage('events/joinCompetition/lfp-button.svg')} alt={'See who\'s interested'} />
            <Trans ns="events" i18nKey="lfp.whosInterested">
              {'See who\'s interested'}
            </Trans>
          </Button>
        </Container>
      </BannerContainer>
      {isComponentVisible && (
        lfpNotEmpty ? (
          <>
            <ModalBackground />
            <Modal ref={ref}>
              <CloseIcon
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                src={LoadImage('events/joinCompetition/close.svg')}
                alt="Close Modal"
              />
              <H3>
                <Trans ns="events" i18nKey="lfp.findAPartner">
                  Find a partner for Doubles
                </Trans>
              </H3>
              <P small>
                <Trans ns="events" i18nKey="lfp.eligible">
                  Before looking for a new partner please make sure you are eligible for joining this competition and
                  check additional eligibility criteria for the team before contacting any of the players on the list.
                  <FindOutMore href="/rules-regulations" target="_blank">
                    Find out more
                  </FindOutMore>
                </Trans>
              </P>
              {!isOnLFP && (
                <Player player={accountInfo} isMe handler={joinLFPList} />)}
              {lfpNotEmpty && (
                <>
                  <P small bold margin="30px 0 5px 0">
                    <Trans ns="events" i18nKey="lfp.playersLFP">
                      {{ total: LFPTotal }} Players looking for a partner
                    </Trans>
                  </P>
                  <PlayersList>
                    {lookingForPartners?.map((player, key) => (
                      <Player
                        key={key}
                        player={player}
                        isOnLFP={isOnLFP}
                        handler={joinLFPList}
                        contact={contactPlayer}
                        isMe={accountInfo?.userId === player?.userId}
                      />
                    ))}
                  </PlayersList>
                </>
              )}
            </Modal>
          </>
        ) : (
          <>
            <ModalBackground />
            <Modal ref={ref} center>
              <CloseIcon
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                src={LoadImage('events/joinCompetition/close.svg')}
                alt="Close Modal"
              />
              <TennisRacket src={LoadImage('events/joinCompetition/tennis-racket.svg')} alt="Looking for Partner" />
              <H3>
                <Trans ns="events" i18nKey="lfp.join">
                  Join the list!
                </Trans>
              </H3>
              <P margin="10px 0 20px 0" textAlign="center" large bold>
                <Trans ns="events" i18nKey="lfp.beFirst">
                  {'Be the 1st one to show that you\'re looking for a parter for this Doubles competition.'}
                </Trans>
              </P>
              <P textAlign="center" small>
                <Trans ns="events" i18nKey="lfp.eligibilityCriteria">
                  Please check the eligibility criteria for this competition before looking for a potential partner.
                  <FindOutMore href="/rules-regulations" target="_blank">
                    Find out more
                  </FindOutMore>
                </Trans>
              </P>
              <Button
                emptyLFP
                onClick={() => {
                  joinLFPList(accountInfo);
                  mixpanel.track('Click on [I\'m looking for a partner] button displayed when no players on the list');
                }}
              >
                <Trans ns="events" i18nKey="lfp.iAmLFP">
                  {'I\'m looking for a Partner'}
                </Trans>
              </Button>
            </Modal>
          </>
        ))}
    </>
  );
};

export default Index;
