export default {
  thankYouForRegistration: 'Dìkujeme za vaši registraci!',
  congratulations: 'Gratulujeme!',
  topUpSuccessfully: 'Do své virtuální penìženky jste úspìšnì pøidali {{ èástka }} {{ mìna }}.',
  undefinedMembershipSuccessfully: 'Úspìšnì jste zakoupili nový úèet',
  premiumMembershipSuccessfully: 'Úspìšnì jste zakoupili nový Prémiový {{period}}úèet',
  addedTo: {
    mainList: 'Pøidali jsme vás do hlavního seznamu.',
    waitingList: 'Pøidali jsme vás do poøadníku èekatelù.',
    preRegistrationList: 'Pøidali jsme vás do seznamu rozdìlení.',
  },
  level: 'Úroveò',
  walletStatus: 'Zaplatili jste <1>{{ paid }} {{ currency }}</1> ze své virtuální penìženky. \nZbývající zùstatek: <3>{{ walletBalance }} {{ currency }}</3>.',
  goToEvent: 'Pøejít nazpìt na událost',
  goToDashboard: 'Pøejít na Øídicí panel',
  goToVirtualWallet: 'Pøejít do Virtuální penìženky',
  goToMyProfile: 'Pøejít na Mùj profil',
  failed: 'Platbu nelze dokonèit!',
  'Neplatný podpis': 'Dùvod: <1>Neplatný podpis</1>',
  invalidSignature: 'Dùvod: <1>Neplatný podpis</1>',
  paymentProcessing: 'Vaše platba byla ovìøena a právì se zpracovává.',
  playFriendlyMatch: 'Zatímco čekáte na zahájení soutěže, můžete si zahrát <strong>přátelský zápas</strong> se svými přáteli nebo jinými hráči na Sportya.',
  yourCourtIsBooked: 'Pozemek je rezervován!',
  goToMyBookings: 'Moje rezervace',
  cancellationWithRefundInfo: 'Tuto rezervaci lze zrušit za účelem vrácení celé částky do <1>{{ hours }}h</1> před časem zahájení.',
};
