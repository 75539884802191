export default {
  seoTitle: 'Регистрирай се | Sportya',
  tosAgree: 'Съгласен съм с <3>Правилата и условията</3> <1>{{ process.env.REACT_APP_SITE_NAME }}</1> și <5>Политика за поверителност</5>, което включва моето съгласие за връзка с мен относно използването на услугите <1>{{ process.env.REACT_APP_SITE_NAME }}</1>, включително, но не само, записване и участие на събития, управление на приятелски мачове и всякаква друга вътрешна комуникация.',
  ageAgree: 'С регистрацията потвърждавам, че съм поне<1>15 години</1>',
  createAccount: 'Създай акаунт',
  alreadyHaveAnAccount: 'Вече сте регистриран?',
  signupWith: 'Или се регистрирайте с',
  newsletterSubscribe: 'Абонирам се за newsletter <1>{{ process.env.REACT_APP_SITE_NAME }}</1>, която съдържа информация за събития <1>{{ process.env.REACT_APP_SITE_NAME }}</1> и други възможности за игри в моя регион, подробности за новите функции и промоции / избрани отстъпки от партньори/ нашите спонсори',
  title: 'Регистрирай се',
  subTitle: 'Sportya е достъпна за играчи в <1>Румъния, Република Молдова, България, Бахрейн, Унгария и Чехия.</1>',
  oldUserInfo: `<0>Вече имате акаунт Tenis Partener?</0> 
  Не е необходимо да създавате нов Sportya акаунт. Вашите данни вече са мигрирани и можете <2>Autentifica</2> с имейл и парола за акаунт Tenis Partener.`,
  countriesComingSoon: 'Очаквайте скоро нови държави!',
};
