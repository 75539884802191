export default {
  events: 'Najít událost',
  courts: 'Kurty',
  membership: 'Èlenství',
  comingSoon: 'Již brzy',
  findEvent: 'Najít událost',
  book_court: 'Rezervovat kurt',
  findPlayers: 'Najít hráèe',
  forPlayers: 'Pro hráèe',
  rankings: 'Hodnocení',
  forClubs: 'Pro kluby',
  blog: 'Blog',
  help: 'Nápovìda',
  aboutUs: 'O nás',
  motto: 'Vybojujte své nejlepší utkání!',
  joinNow: 'Pøipojit se',
  privacy: 'Zásady ochrany soukromí',
  termsAndConditions: 'Smluvní vztahy a podmínky',
  returnPolicy: 'Zásady vracení',
  language: 'Jazyk',
  english: 'Angliètina',
  romanian: 'Rumunština',
  English: 'Angliètina',
  Romanian: 'Rumunština',
  copyrightFooter: 'Všechna práva vyhrazena.',
  rules: 'Sportya - Pravidla a pøedpisy',
  groupsPlaying: 'Formáty pro skupinovou hru',
  localLeaguesRules: 'Místní ligy - Pravidla a pøedpisy',
  sportyaRomania: 'Sportya Rumunsko',
  sportyaBulgaria: 'Sportya Bulharsko',
  sportya: 'Sportya',
  sportyaDesc: 'Rozhodli jsme se propojit a inspirovat sportovce celého svìta. Vytvoøili jsme soutìžní platformy, události a specifické nástroje, které jim pomohou vytìžit jejich sportovní život na maximum.',
};
