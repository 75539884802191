import React from "react";
import { Trans } from "react-i18next";
import H1 from "../../../components/H1";
import Paragraph from "../../../components/Paragraph";

const PaymentError = ({ error }) => (
  <>
    <div className="logoplaceholder color-white">X</div>
    <H1><Trans ns="paymentConfirmation" i18nKey="failed">Could not process payment!</Trans></H1>
    <Paragraph large className="text-center mt20">
      <Trans ns="paymentConfirmation" i18nKey={error}>
        Reason:
        <strong>{error}</strong>
      </Trans>
    </Paragraph>
  </>
);

export default PaymentError;
