/*
  Return integer hour format (1200) as string (12:00)
 */
const timeToString = (value) => {
  if (!value || typeof value !== 'number') return false;

  // add zero to hour
  const time = (value.toString().length < 4 ? "0" : "") + value;

  // get hour and minute
  const hour = time.slice(0, 2);
  const minute = time.slice(-2);

  // return time as string
  return `${hour}:${minute}`;
};

export default timeToString;
