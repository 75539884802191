import React from 'react';
import AvailabilityInfo from './components/availability';
import GameLevelInfo from './components/gameLevel';
import MetaInfo from './components/meta';
import { Container } from './styles';

const Info = () => (
  <Container>
    <GameLevelInfo hideOnMobile />
    <MetaInfo />
    <AvailabilityInfo />
  </Container>
);

export default Info;
