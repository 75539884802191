import styled from '@emotion/styled';
import { Link } from '../../../components/Collection';
import LoadImage from '../../../components/common/LoadImage';

export const Container = styled(Link)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100px',
  width: '100%',
  background: `url('${LoadImage('getting-started-guide/guide-banner.png')}') no-repeat 90% center/cover`,
  backgroundPosition: 'unset',
  borderRadius: '10px',
  margin: '20px 0',
  '@media (max-width: 650px)': {
    height: 'unset',
    padding: '20px',
    p: {
      width: '65vw',
      textAlign: 'center',
    },
  },
});

export const Arrow = styled.img({
  position: 'absolute',
  right: '30px',
  top: '50%',
  transform: 'translateY(-50%)',

  '@media (max-width: 500px)': {
    right: 15,
  },
});

export const Logo = styled.img({
  width: '155px',
  height: '21px',
});
