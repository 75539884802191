import styled from '@emotion/styled';

// import UserLevel from '../../../../assets/images/userLevel.svg'
// import LevelBadge from '../../../../assets/images/level-badge.svg'

export const StyledWinners = styled.div`
  width:100%;
  min-height:147px;
  padding:34px 0 34px 0;
  background-color:${({ theme }) => theme?.colors?.primary};
  border-radius:10px;
  display:flex;
  justify-content:space-between;
  margin-top:37px;
  p{
    margin:0;
  }

.left,.right{
max-width:340px;
width:100%;

.firstPlayer{
    margin-bottom:${props => () => (props.duo ? '30px' : '0')};
}
}

.left{
  margin-left:30px;
}

.right{
  margin-right:10px;
}

.player{
  display:flex;

  .playerImgWrapp{
    position:relative;
    width:80px;
    height:80px;
    border: 1px solid ${({ theme }) => theme.colors?.lightBlue2};
    background-color: ${({ theme }) => theme.colors?.white};
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;

    img{
     width:80px;
    height:80px;
    }

    .playerLevel{
      p{
        color:${({ theme }) => theme?.colors?.white};
      }
      position:absolute;
      bottom:-10px;
      left:0;
      right:0;
      margin:auto;
      width:18px;
      height:18px;
    } 
  h4{
    color: ${({ theme }) => theme.colors?.mediumTurquoise};
  }
  }

  .playerInfo{
    display:flex;
    flex-direction:column;
    margin-left:20px;
    justify-content:center;

    .playerName{
      margin-top:6px;
      display:flex;
      align-items:center;
      img{
        margin-left:11px;
      }
    }

    p{color:${({ theme }) => theme?.colors?.white}}
  }
}

.champion{
    .playerLevel{
      p{
        color: ${({ theme }) => theme?.colors?.gold} !important;
      }
    }
  .playerInfo{
    p{
      color:${({ theme }) => theme?.colors?.gold};
    }
  }
  img:first-of-type{
    border: 2px solid ${({ theme }) => theme?.colors?.gold};
  }
}

.scoreWrapp{
  max-width:377px;
  width:100%;
  border-left:1px solid rgba(255, 255, 255, .2);
  border-right:1px solid rgba(255, 255, 255, .2);;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-left:30px;
  margin-right:30px;

  .scorePoints{
    display:flex;
  }

  p{
    color: ${({ theme }) => theme?.colors?.white}};

  }

  .score{
    font-size: ${({ theme }) => theme.functions?.toEm(28)};;
    margin-top:10px;
    margin-left:10px;
    margin-right:10px;
    line-height:36px;
  }

   @media screen and (max-width:1024px){
  flex-direction:column !important;
  justify-content:center !important;
  align-items:center !important;

  .left,.right,.playerInfo{margin:0 !important;}

  .playerInfo{
    margin-top:10px !important;
    p{
      text-align:center;
    }
  }

  .player{
    flex-direction:column;
    align-items:center ;
  }

  .scoreWrapp{
    margin:20px 0 20px 0 !important;
    border-left:none !important;
    border-right:none !important;
    border-top:1px solid rgba(255, 255, 255, .2);
    border-bottom:1px solid rgba(255, 255, 255, .2);
    padding:10px;
  }
}
}

`;
