import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LoadImage from '../../../../../components/common/LoadImage';

import {
  ButtonsContainer,
  ButtonsFirstPart,
  ButtonsSecondPart,
} from '../../../styles';

import { DueToP } from '../../styles';
import { OptionIcon, ReasonText } from '../../../StartMatch/components/WantToPlay/styles';
import { ExpandedMatchesButton } from '../../../components/Modals/MatchesDropdown/styles';
import { Button } from '../../../../../components';

export const InvitationButtons = ({
  isOnHold,
  buttonsToShow,
  modalHandler,
  isExpanded,
  setIsExpanded,
  timeUntilScoreValidation,
  initiateConversation,
}) => {
  const { t } = useTranslation('friendlyMatches');
  const {
    accept, decline, confirmMatch, noMatch, chat, status,
  } = buttonsToShow || {};
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <ButtonsContainer status={status}>
        {!status && (
          <ButtonsFirstPart>
            {accept && (
              <ExpandedMatchesButton small onClick={() => modalHandler('accept')} autoLeftMargin>
                <Trans ns="friendlyMatches" i18nKey="acceptMatch">Accept Match</Trans>
              </ExpandedMatchesButton>
            )}

            {decline && (
              <ExpandedMatchesButton onClick={() => modalHandler('decline')} small declineButton outline noBorder>
                <Trans ns="friendlyMatches" i18nKey="decline">Decline</Trans>
              </ExpandedMatchesButton>
            )}

            {confirmMatch && (
              <div className="position-relative">
                <ExpandedMatchesButton
                  small
                  disabled={isOnHold}
                  onClick={() => modalHandler('confirmMatchScore')}
                  autoLeftMargin
                >
                  <Trans ns="friendlyMatches" i18nKey="confirmMatch">Confirm Match</Trans>
                </ExpandedMatchesButton>
                <DueToP small>{timeUntilScoreValidation}</DueToP>
              </div>
            )}

            {noMatch && (
              <ExpandedMatchesButton
                small
                declineButton
                outline
                noBorder
                onClick={() => modalHandler('noMatchFromRequestsTab')}
              >
                <Trans ns="friendlyMatches" i18nKey="noMatch">No Match</Trans>
              </ExpandedMatchesButton>
            )}

            {chat && isExpanded && (
              <div className="only-mobile">
                <Button fullwidth outline small onClick={initiateConversation}>
                  <span><Trans ns="friendlyMatches" i18nKey="chat">Chat</Trans></span>
                </Button>
              </div>
            )}
          </ButtonsFirstPart>
        )}

        <ButtonsSecondPart>
          {chat && (
            <OptionIcon
              src={LoadImage('friendly-matches/matches/button-chat.svg')}
              alt="Open chat"
              onClick={initiateConversation}
              className="initiate-conversation"
              hideTablet
            />
          )}
          {status
          && (
            <ReasonText>
              <OptionIcon
                src={LoadImage('friendly-matches/matches/x-icon.svg')}
                alt={capitalize(t(status))}
                isReason
                noBorder
              />
              {capitalize(t(status))}
            </ReasonText>
          )}
          <OptionIcon
            onClick={() => setIsExpanded(!isExpanded)}
            src={LoadImage('friendly-matches/matches/button-dropdown.svg')}
            alt=""
            hideMobile
          />
        </ButtonsSecondPart>
      </ButtonsContainer>
      <OptionIcon
        onClick={() => setIsExpanded(!isExpanded)}
        src={LoadImage('friendly-matches/matches/button-dropdown.svg')}
        alt=""
        hideDesktop
        className="mt10"
      />
    </>
  );
};
