/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import { isEmpty, pick } from 'lodash';

import { RankedIcon } from '../../../../Matches/styles';
import SearchBar from '../../../../components/MatchFormSearchBar';
import { P } from '../../../../../../components/Collection';
import Select from '../../../../../../components/Form/Select';
import Radio from '../../../../../../components/Form/VanillaRadio';
import LoadImage from '../../../../../../components/common/LoadImage';
import YoureTheCaptain from '../../../../components/MatchFormSearchBar/components/PlayerOption';

import {
  MatchFormButtonsContainer,
  MatchInvitationContainer,
  MatchRankTypeContainer,
  MatchOptionsContainer,
  MatchFormTypesTitle,
  CheckboxContainer,
  MatchOptionTitle,
  MatchFormButtons,
  MatchOptionIcon,
  TeamsContainer,
  MatchRankType,
  MatchOptions,
  MatchOption,
  TeamTitle,
  Team,
  StartDateContainer,
  SetDateAndTimeContainer,
  ChangeCountry,
  DateAndTimeContainer,
  DateLabel,
  SelectedDateValue,
  AverageLevel,
} from './styles';

import { store as socketStore } from '../../../../../../components/Socket/store';
import ToggleVisible from '../../../../../../components/common/ToggleVisible';
import RangeDatePicker from '../../../../../Events/components/EventFilters/RangeDatePicker';
import SelectGoogleAutocomplete from '../../../../../../components/Form/SelectGoogleAutocomplete';
import { MatchFormClearResults } from '../../../../components/MatchFormSearchBar/styles';
import { Country, Flag } from '../../../../../Auth/Register/styles';
import getCountryIsoCode from '../../../../../../components/common/getCountryIsoCode';
import {
  addMatchToList, changeSummaryTotals, createFriendlyMatch, editFriendlyMatch, replaceMatchInList,
} from '../../../../reducers';
import mixpanel from '../../../../../../mixpanel';
import { formatMatchId, getLocalDate, getWinner } from '../../../../helpers';
import ConfirmModal from '../components/ConfirmModal';
import PlayerScores from '../PlayedMatch/components/PlayerScore';
import TooltipRankedMatches from './components/rankedTooltip';
import CancelModal from '../components/CancelModal';
import CourtInfoCard from "../../../../../Clubs/Listing/components/CourtInfoCard";
import { fetchClubs } from "../../../../../Clubs/reducers";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";
import { Paragraph } from "../../../../../../components";
import BookCourtModal from "../../../../../Clubs/Listing/components/bookCourtModal";

const formatHour = hour => (hour < 10 ? `0${hour}` : `${hour}`);
const formatMinute = hour => (hour < 10 ? `0${hour}` : `${hour}`);

const getTimeList = (amount, type, onlyFuture) => {
  const result = [];
  const currentHour = new Date().getHours() + 1;
  const format = {
    minutes: formatMinute,
    hours: formatHour,
  };

  for (let i = onlyFuture ? currentHour : 0; i < amount; i++) {
    result.push({ label: format[type](i), value: format[type](i) });
  }

  return result;
};

const isCurrentDate = ({ day, month, year }) => {
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  return day === currentDay && month === currentMonth && year === currentYear;
};

const userFields = [
  'id',
  'userId',
  'firstName',
  'lastName',
  'email',
  'phone',
  'profilePicture',
  'location',
  'gameLevel',
  'rank',
];

const MatchInvitationForm = ({ match, edit, cancelModalState }) => {
  const routerHistory = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('friendlyMatches');
  const { t: playerT } = useTranslation('player');

  const { data: { results: courts } = {}, status: statusCourts } = useSelector(state => state.clubs?.listing);
  const [bookingInfo, setBookingInfo] = useState({});

  const {
    visible: isCancelModalVisible,
    handler: setIsCancelModalVisible,
  } = cancelModalState || {};

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = ToggleVisible(false);

  const {
    isComponentVisible: isConfirmModalVisible,
    setIsComponentVisible: setIsConfirmModalVisible,
  } = ToggleVisible(false);

  const matchFormat = [
    { label: t('singleSet'), value: 'singleSet' },
    { label: t('2setsWithSuperTieBreak'), value: '2setsWithSuperTieBreak' },
    { label: t('2setsOutOf3'), value: '2setsOutOf3' },
  ];

  const getMatchFormat = valueToFind => matchFormat.find(({ value }) => value === valueToFind);

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const { accountInfo } = useSelector(state => state.session);
  const { eligibleCountries } = useSelector(state => state.settings.data);

  const [gameType, setGameType] = useState(match?.gameType || false);

  const [partner, setPartner] = useState(match?.playerPartner);
  const [opponent, setOpponent] = useState(match?.opponent);
  const [opponentPartner, setOpponentPartner] = useState(match?.opponentPartner);
  const [selectedMatchFormat, selectMatchFormat] = useState(getMatchFormat(match?.format || '2setsOutOf3'));

  const [isChangeCountryEnabled, enableChangeCountry] = useState(false);
  const [selectedCountry, selectCountry] = useState(null);
  const [locationType, setLocationType] = useState(null);

  const dateFromMatch = match?.startDate && new Date(match?.startDate);
  const dayFromMatch = dateFromMatch?.getUTCDate();
  const yearFromMatch = dateFromMatch?.getUTCFullYear();
  const monthFromMatch = dateFromMatch?.getUTCMonth() + 1;
  const hourFromMatch = Number(dateFormat(match?.startDate, 'HH'));
  const minuteFromMatch = formatMinute(dateFromMatch?.getUTCMinutes());

  const preCompleteSelectedDate = dateFromMatch ? {
    day: dayFromMatch,
    month: monthFromMatch,
    year: yearFromMatch,
  } : {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  const [selectedDate, selectDate] = useState(preCompleteSelectedDate);
  const [hour, setHour] = useState(dateFromMatch && hourFromMatch);
  const [minute, setMinute] = useState(dateFromMatch && minuteFromMatch);

  const [isFormDisabled, disableForm] = useState(true);
  const [location, setLocation] = useState(match?.location && { ...match?.location });

  const isRankedMatch = match?.ranked === false ? 'unranked' : 'ranked';
  const [rankedMatch, setRankedMatch] = useState(isRankedMatch);

  useEffect(() => {
    if (!isEmpty(location) && locationType === 'bookACourt' && !isEmpty(selectedDate) && hour) {
      const date = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;
      const filters = {
        timestamp: dateFormat(`${date} ${hour}:${minute}`, 'yyyy-mm-dd HH:MM'),
        location: location.name,
        radius: 50,
        coordinates: [location.coords.lat, location.coords.lng],
        availableOnly: true,
      };
      dispatch(fetchClubs({ filters }));
    }
  }, [location, locationType, selectedDate, hour, minute]);

  const isTypeSelected = type => type === rankedMatch;
  const isRanked = rankedMatch === 'ranked';

  const isDoubles = gameType === 'doubles';
  const isSingles = gameType === 'singles';

  const myLevel = accountInfo?.gameLevel?.[gameType];

  const requiredLevel = {
    singles: {
      min: myLevel - 1,
      max: myLevel + 1,
    },
    doubles: {
      average: (myLevel + partner?.gameLevel?.doubles) / 2,
      opponentAverage: (opponent?.gameLevel?.doubles + opponentPartner?.gameLevel?.doubles) / 2,
      get min() { return this.average - 1; },
      get max() { return this.average + 1; },
    },
    get min() {
      return isDoubles ? this.doubles.min : this.singles.min;
    },
    get max() {
      return isDoubles ? this.doubles.max : this.singles.max;
    },
    get forFriendlyMatches() {
      return isDoubles ? () => {
        const minRequired = this.doubles.min;
        const maxRequired = this.doubles.max;
        const opponentTeam = this.doubles.opponentAverage;
        return (partner && opponent && opponentPartner && isRanked)
          ? (opponentTeam >= minRequired && opponentTeam <= maxRequired) : true;
      } : () => {
        const opponentLevel = opponent?.gameLevel?.singles;
        const minRequired = this.singles.min;
        const maxRequired = this.singles.max;
        return (opponent && isRanked) ? (opponentLevel >= minRequired && opponentLevel <= maxRequired) : true;
      };
    },
  };

  const [winner, declareWinner] = useState(null);
  const [winnerError, setWinnerError] = useState(null);

  const [scoreForm, setScoreForm] = useState({
    player: {
      score: [
        ...match?.player?.score ? [...match?.player?.score] : [],
      ],
      ...match?.player?.type && { type: match?.player?.type },
    },
    opponent: {
      score: [
        ...match?.opponent?.score ? [...match?.opponent?.score] : [],
      ],
      ...match?.opponent?.type && { type: match?.opponent?.type },
    },
  });

  const [scoreError, setScoreError] = useState(false);

  useEffect(() => {
    if (!requiredLevel.forFriendlyMatches() && rankedMatch === 'ranked') {
      return disableForm(true);
    }

    if (hour && minute && selectedDate && location) {
      if (gameType === 'doubles' && partner && opponentPartner && opponent) {
        return disableForm(false);
      }

      if (gameType === 'singles' && opponent) {
        return disableForm(false);
      }
    } else {
      return disableForm(true);
    }
  }, [
    gameType,
    partner,
    opponent,
    opponentPartner,
    selectedMatchFormat,
    hour,
    minute,
    rankedMatch,
    location,
    selectedDate,
  ]);

  const dispatchForm = () => {
    const payload = {
      player: accountInfo,
      playerPartner: partner,
      opponent,
      opponentPartner,
      ranked: isRanked,
      gameType,
      startDate: new Date(selectedDate?.year, (selectedDate?.month - 1), selectedDate?.day, hour, minute),
      timezoneOffset:
        new Date(selectedDate?.year, (selectedDate?.month - 1), selectedDate?.day, hour, minute).getTimezoneOffset(),
      format: selectedMatchFormat?.value || false,
      location: { ...location?.value || location },
      ...match?._id && {
        id: match?._id,
      },
    };

    disableForm(true);
    dispatch(edit ? editFriendlyMatch({ payload, id: match?._id }) : createFriendlyMatch({ payload }))
      .then(() => dispatch(changeSummaryTotals({ matches: 1, requests: 0 })));
  };

  const [matchOptions, setMatchOptions] = useState({
    clubOrAddress: !match?.location?.isOther,
    format: true,
  });

  const handleMatchOption = (option, value) => setMatchOptions({
    ...matchOptions,
    [option]: value,
  });

  const formTypes = [
    { title: t('rankedMatch'), desc: t('generatesPoints'), key: 'ranked' },
    { title: t('unrankedMatch'), desc: t('doesntGeneratePoints'), key: 'unranked' },
  ];

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(country => ({
    value: (country.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${country.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${country.key}`)}</P>
      </Country>
    ),
  }));

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success) {
        const { match: responseMatch } = data || {};
        const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const matchStartDate = getLocalDate(responseMatch.startDate, responseMatch.timezone);
        const mixpanelEventProperties = {
          sp_asset_type: 'MATCH INVITE',
          sp_match_type: responseMatch.ranked ? 'Ranked' : 'Unranked',
          sp_game_type: responseMatch.gameType,
          sp_owner_level_single: responseMatch.player?.gameLevel?.singles,
          sp_owner_level_double: responseMatch.player?.gameLevel?.doubles,
          sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
          sp_start_date: matchStartDate.toString(),
          sp_start_hours: matchStartDate.getHours(),
          sp_game_location_city: responseMatch.location?.city,
          sp_game_location_country: responseMatch.location?.country,
          sp_game_format: responseMatch.format,
          sp_match_id: formatMatchId(responseMatch.matchId),
        };
        switch (message) {
        case 'SUCCESSFULLY_CREATED_FRIENDLY_MATCH':
          dispatch(addMatchToList({ match: responseMatch, list: 'matches' }));
          setIsConfirmModalVisible(true);
          mixpanel.track('Friendly Matches MATCH CREATED', {
            ...mixpanelEventProperties,
          });
          break;
        case 'SUCCESSFULLY_UPDATED_FRIENDLY_MATCH':
          dispatch(replaceMatchInList({ match: responseMatch, list: 'matches' }));
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          mixpanel.track('Friendly Matches EDIT MATCH', {
            ...mixpanelEventProperties,
          });
          routerHistory.push('/friendly-matches/available');
          break;
        default:
        }
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'friendly-matches-response',
      );
      socket.on(
        'friendly-matches-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'friendly-matches-response',
        );
      };
    }
  }, [socketClientId]);

  const gameTypeOptions = [
    { label: playerT('singles'), value: 'singles' },
    { label: playerT('doubles'), value: 'doubles' },
  ];

  const handleHour = (value) => {
    if (!minute) {
      setHour(value);
      return setMinute('00');
    }

    return setHour(value);
  };

  const handleCheckboxes = (isPlayer) => {
    const newScoreForm = { ...scoreForm };
    const playerType = isPlayer ? 'player' : 'opponent';
    const hasBye = newScoreForm[playerType]?.type;

    if (isPlayer) {
      delete newScoreForm.opponent.type;
    }

    if (!isPlayer) {
      delete newScoreForm.player.type;
    }

    if (hasBye) {
      delete newScoreForm[playerType].type;
    } else {
      newScoreForm[playerType].type = 'BYE';
    }

    declareWinner(getWinner(scoreForm));
    return setScoreForm(newScoreForm);
  };

  const matchHasScore = match?.player?.score?.length || match?.player?.type || match?.opponent?.type;

  const handleCourts = () => {
    setLocation(accountInfo?.location);
    setLocationType('bookACourt');
  };

  return (
    <>
      <MatchInvitationContainer>
        <P small margin="20px 0 0 0" textAlign="center">
          <Trans ns="friendlyMatches" i18nKey="invitationPendingConfirmation">
            Match invitations will be pending acceptance from the invited players
          </Trans>
        </P>
        <MatchFormTypesTitle bold>
          <Trans ns="friendlyMatches" i18nKey="selectMatchType">
            Select the Match Type
          </Trans>
        </MatchFormTypesTitle>
        <MatchRankTypeContainer>
          {formTypes.map((formType, key) => (
            <MatchRankType
              key={key}
              isSelected={isTypeSelected(formType.key)}
              onClick={() => {
                selectMatchFormat(getMatchFormat('2setsOutOf3'));
                setRankedMatch(formType.key);
              }}
            >
              <CheckboxContainer>
                <Radio
                  onChange={() => { }}
                  checked={isTypeSelected(formType.key)}
                  label={formType.title}
                />
                {formType.key === 'ranked' && (
                  <RankedIcon src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="Ranked Match" />)}
              </CheckboxContainer>
              <P xSmall display="flex" align="center">
                {formType.desc}
                {formType.key === 'ranked' && (
                  <TooltipRankedMatches />
                )}
              </P>
            </MatchRankType>
          ))}
        </MatchRankTypeContainer>

        <MatchOptions>
          <MatchOptionTitle>
            <P bold>
              <Trans ns="friendlyMatches" i18nKey="matchDetails">
                Match Details
              </Trans>
            </P>
          </MatchOptionTitle>

          <Select
            height="50px"
            name="gameType"
            isClearable
            label={t('gameType')}
            isSearchable={false}
            placeholder={t('gameType')}
            onChange={(selectedGameType) => { setGameType(selectedGameType?.value); }}
            options={gameTypeOptions}
            labelOn={gameType}
            alignLabel
            higher
            lowInput
            {...match && {
              defaultValue: {
                ...match?.gameType === 'singles'
                  ? { label: playerT('singles'), value: 'singles' }
                  : { label: playerT('doubles'), value: 'doubles' },
              },
            }}
          />

          {isDoubles && (
            <TeamsContainer>
              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="yourTeam">
                    Your Team
                  </Trans>
                  <AverageLevel type={gameType} hasTenth={requiredLevel.doubles.average % 1 === 0.5}>
                    {requiredLevel.doubles.average || '?'}
                  </AverageLevel>
                </TeamTitle>
                <YoureTheCaptain
                  isDoubles={isDoubles}
                  player={accountInfo}
                  playerInputPlaceholder
                  isMe
                  handler={() => null}
                />
                <SearchBar
                  isDoubles={isDoubles}
                  isPartner
                  handler={setPartner}
                  selectedOption={partner}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />
              </Team>

              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="opponentTeam">
                    Opponent Team
                  </Trans>
                  <AverageLevel type={gameType} hasTenth={requiredLevel.doubles.opponentAverage % 1 === 0.5}>
                    {requiredLevel.doubles.opponentAverage || '?'}
                  </AverageLevel>
                </TeamTitle>
                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={1}
                  handler={setOpponent}
                  selectedOption={opponent}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />
                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={2}
                  handler={setOpponentPartner}
                  selectedOption={opponentPartner}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />
              </Team>
            </TeamsContainer>
          )}

          {isSingles && (
            <>
              <YoureTheCaptain
                isDoubles={isDoubles}
                player={accountInfo}
                playerInputPlaceholder
                isMe
                handler={() => null}
              />
              <SearchBar
                isDoubles={isDoubles}
                handler={setOpponent}
                selectedOption={opponent}
                hasError={!requiredLevel.forFriendlyMatches()}
                match={match}
              />
            </>
          )}

          {!requiredLevel.forFriendlyMatches() && (
            <P xSmall color="error">
              <Trans ns="friendlyMatches" i18nKey="playerMinMaxLevelError">
                Opponent level not compatible for your Ranked Match. Please select a player within the Level Range
                {' '}
                {{ min: requiredLevel.min }}
                -
                {{ max: requiredLevel.max }}
                , or switch to Unranked Match to continue playing with the selected player.
              </Trans>
            </P>
          )}
        </MatchOptions>

        <MatchOptions>
          <MatchOptionTitle>
            <P bold>
              <Trans ns="friendlyMatches" i18nKey="proposedMatchFormat">
                Proposed Match Format
              </Trans>
            </P>
          </MatchOptionTitle>
          <MatchOptionsContainer>
            <MatchOption
              onClick={() => handleMatchOption('format', true)}
              isSelected={matchOptions.format}
            >
              <Trans ns="friendlyMatches" i18nKey="format">
                Format
              </Trans>
            </MatchOption>
            {!isRanked && (
              <MatchOption
                onClick={() => handleMatchOption('format', false)}
                isSelected={!matchOptions.format}
              >
                <Trans ns="friendlyMatches" i18nKey="decideLater">
                  Decide Later
                </Trans>
              </MatchOption>
            )}
          </MatchOptionsContainer>
          {!matchOptions.format ? (
            <P>
              <Trans ns="friendlyMatches" i18nKey="decideGameFormatLater">
                Players can decide the game format later or complete it after the match
              </Trans>
            </P>
          ) : (
            <Select
              name="matchFormat"
              height="50px"
              placeholder={t('matchFormat')}
              onChange={(e) => selectMatchFormat(getMatchFormat(e?.value))}
              options={matchFormat.filter(({ value }) => (isRanked && value === '2setsOutOf3') || !isRanked)}
              label={t('matchFormat')}
              isClearable
              isSearchable={false}
              alignLabel
              higher
              lowInput
              {...selectedMatchFormat && {
                value: selectedMatchFormat,
              }}
            />
          )}

          {matchHasScore && (
            <PlayerScores
              setScoreForm={setScoreForm}
              handleCheckboxes={handleCheckboxes}
              scoreForm={scoreForm}
              isDoubles={isDoubles}
              playerPartner={partner}
              player={match?.player}
              opponent={opponent}
              opponentPartner={opponentPartner}
              winner={winner}
              winnerError={winnerError}
              declareWinner={declareWinner}
              setWinnerError={setWinnerError}
              alreadyHasScore={!!match}
              scoreError={scoreError}
              setScoreError={setScoreError}
            />
          )}
        </MatchOptions>

        {!match?.bookingId && (
          <MatchOptions>
            <MatchOptionTitle>
              <P bold>
                <Trans ns="friendlyMatches" i18nKey="dateAndTime">
                  Date & Time
                </Trans>
              </P>
            </MatchOptionTitle>

            <DateAndTimeContainer>
              <StartDateContainer
                {...!matchHasScore && { onClick: () => setIsComponentVisible(!isComponentVisible) }}
                hasMargin
                isDisabled={matchHasScore}
              >
                {selectedDate
                  && (
                    <DateLabel>
                      <Trans ns="events" i18nKey="startDate">
                        Start Date
                      </Trans>
                    </DateLabel>
                  )}

                {selectedDate
                  && (
                    <SelectedDateValue>
                      {dateFormat(
                        new Date(selectedDate?.year, (selectedDate?.month - 1), selectedDate?.day),
                        'd mmm yyyy',
                      )}
                    </SelectedDateValue>
                  )}

                {!selectedDate
                  && (
                    <P bold small>
                      <Trans ns="events" i18nKey="startDate">
                        Start Date
                      </Trans>
                    </P>
                  )}

                {selectedDate && !matchHasScore && (
                  <MatchFormClearResults
                    {...!matchHasScore && { onClick: () => selectDate(null) }}
                    isOnFriendlyMatchesCreateMatches
                  >
                    {/* eslint-disable-next-line max-len */}
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" /></svg>
                  </MatchFormClearResults>
                )}
              </StartDateContainer>

              {selectedDate && (
                <SetDateAndTimeContainer>
                  <Select
                    isDisabled={!!matchHasScore}
                    name="startTimeHour"
                    placeholder={t('hour')}
                    onChange={(time) => handleHour(time.value)}
                    options={getTimeList(24, 'hours', isCurrentDate(selectedDate))}
                    height="50px"
                    label={t('hour')}
                    isSearchable={false}
                    {...!!hour && { defaultValue: { label: hour, value: hour } }}
                    labelOn={!!hour}
                    alignLabel
                    higher
                    lowInput
                  />

                  <Select
                    isDisabled={!!matchHasScore}
                    name="startTimeMinute"
                    placeholder={t('minute')}
                    onChange={(time) => setMinute(time.value)}
                    options={getTimeList(60, 'minutes')}
                    height="50px"
                    label={t('minute')}
                    isSearchable={false}
                    {...minute && { value: { label: minute, value: minute } }}
                    labelOn={minute}
                    alignLabel
                    higher
                    lowInput
                  />
                </SetDateAndTimeContainer>
              )}
              {isComponentVisible
                && (
                  <RangeDatePicker
                    handler={() => setIsComponentVisible(!isComponentVisible)}
                    calendarRef={ref}
                    handleChange={() => { }}
                    firstSelect={selectedDate}
                    setFirstSelect={selectDate}
                    displaySingleCalendar
                    doNotDisplayOptions
                    isOnFriendlyMatchesCreateMatches
                    onlyFutureDates
                  />
                )}
            </DateAndTimeContainer>
          </MatchOptions>
        )}

        {!match?.bookingId && !isEmpty(selectedDate) && hour && (
          <>
            <MatchFormTypesTitle bold>
              <Trans ns="friendlyMatches" i18nKey="selectLocation">
                Select Location
              </Trans>
            </MatchFormTypesTitle>
            <MatchRankTypeContainer>
              <MatchRankType
                isSelected={locationType === 'bookACourt'}
                onClick={handleCourts}
              >
                <CheckboxContainer>
                  <MatchOptionIcon src={LoadImage('clubs/court-type.svg')} alt="Option" />
                  <P bold display="flex" align="center">
                    <Trans ns="clubs" i18nKey="bookACourt">Book a Court</Trans>
                  </P>
                </CheckboxContainer>
                <P xSmall display="flex" align="center">Choose a club with available courts for your match</P>
              </MatchRankType>
              <MatchRankType
                isSelected={locationType === 'otherLocation'}
                onClick={() => setLocationType('otherLocation')}
              >
                <CheckboxContainer>
                  <MatchOptionIcon src={LoadImage('friendly-matches/match-form/location-icon.svg')} alt="Option" />
                  <P bold display="flex" align="center">Other Location</P>
                </CheckboxContainer>
                <P xSmall display="flex" align="center">Search for another address or venue</P>
              </MatchRankType>
            </MatchRankTypeContainer>
          </>
        )}

        {locationType && (
          <>
            <MatchOptions locationType={locationType}>
              <P small margin="0 0 10px 0">
                <Trans ns="friendlyMatches" i18nKey="countrySelect">
                  {{ country: accountInfo?.location?.country }}
                  {' '}
                  <ChangeCountry onClick={() => enableChangeCountry(true)}>Change Country</ChangeCountry>
                </Trans>
              </P>

              {isChangeCountryEnabled && (
                <Select
                  name="country"
                  placeholder={commonT('country')}
                  label={commonT('country')}
                  options={countries}
                  isSearchable={false}
                  onChange={(country) => selectCountry(country.value)}
                  height="50px"
                  labelOn={selectedCountry}
                  alignLabel
                  higher
                  lowInput
                />
              )}

              <SelectGoogleAutocomplete
                name="location"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [selectedCountry || getCountryIsoCode(accountInfo?.location?.country)?.countryCode],
                  },
                }}
                minLengthAutocomplete={3}
                hook={(e) => setLocation(e?.value)}
                placeholder={t('clubOrAddress')}
                label={commonT('location')}
                isClearable
                {...location && {
                  defaultValue: { label: location?.name, value: location },
                }}
              />
            </MatchOptions>

            {locationType === 'bookACourt' && (
              <>
                {!isEmpty(courts) && (
                  courts.map((court, key) => (
                    <CourtInfoCard
                      key={key}
                      clubInfo={court}
                      setBookingInfo={setBookingInfo}
                      startDate={`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`}
                      startHour={`${hour}:${minute}`}
                    />
                  ))
                )}
                {statusCourts === 'loading' && <LoadingSpinner height={100} />}
                {(statusCourts === 'succeeded' && isEmpty(courts)) && (
                  <div className="d-flex flex-column align-items-center mt50">
                    <img src={LoadImage('clubs/court-type.svg')} alt="" width={35} />
                    <Paragraph bold className="mt10">
                      <Trans ns="clubs" i18nKey="noAvailableCourts">
                        There are no courts available for the selected date.
                      </Trans>
                    </Paragraph>
                  </div>
                )}
              </>
            )}

            {locationType === 'otherLocation' && (
              <MatchFormButtonsContainer>
                <MatchFormButtons second outline onClick={() => setIsCancelModalVisible(!isCancelModalVisible)}>
                  <Trans ns="friendlyMatches" i18nKey="cancel">
                    Cancel
                  </Trans>
                </MatchFormButtons>
                <MatchFormButtons
                  first
                  disabled={isFormDisabled}
                  onClick={() => dispatchForm()}
                >
                  <Trans ns="friendlyMatches" i18nKey={edit ? 'updateMatch' : 'sendInvite'}>
                    {edit ? 'updateMatch' : 'saveMatch'}
                  </Trans>
                </MatchFormButtons>
              </MatchFormButtonsContainer>
            )}
          </>
        )}

        {match?.bookingId && edit && (
          <MatchFormButtonsContainer>
            <MatchFormButtons second outline onClick={() => setIsCancelModalVisible(!isCancelModalVisible)}>
              <Trans ns="friendlyMatches" i18nKey="cancel">
                Cancel
              </Trans>
            </MatchFormButtons>
            <MatchFormButtons
              first
              disabled={isFormDisabled}
              onClick={() => dispatchForm()}
            >
              <Trans ns="friendlyMatches" i18nKey={edit ? 'updateMatch' : 'sendInvite'}>
                {edit ? 'updateMatch' : 'saveMatch'}
              </Trans>
            </MatchFormButtons>
          </MatchFormButtonsContainer>
        )}
      </MatchInvitationContainer>

      {isConfirmModalVisible && (
        <ConfirmModal
          handler={() => routerHistory.push('/friendly-matches/available')}
          match={{
            opponent, opponentPartner, playerPartner: partner, gameType,
          }}
        />
      )}

      {isCancelModalVisible && (
        <CancelModal
          cancelHandler={() => setIsCancelModalVisible(!isCancelModalVisible)}
        />
      )}

      {/*  Book a Court Modal */}
      {!isEmpty(bookingInfo) && (
        <BookCourtModal
          isOpen={!isEmpty(bookingInfo)}
          bookingInfo={bookingInfo}
          friendlyMatchInfo={{
            matchType: rankedMatch,
            format: selectedMatchFormat?.value,
            gameType,
            player: pick(accountInfo, userFields),
            opponent: pick(opponent, userFields),
            playerPartner: pick(partner, userFields),
            opponentPartner: pick(opponentPartner, userFields),
          }}
          onRequestClose={() => setBookingInfo({})}
        />
      )}
    </>
  );
};

export default MatchInvitationForm;
