export default {
  forgotPassword: 'Forgot password',
  subTitle: 'Enter your e-mail below',
  reset: 'Reset',
  placeholders: {
    email: 'E-mail address',
    password: 'Password',
    passwordConfirmation: 'Confirm password',
  },
  passwordResetSuccessfully: 'Please check your inbox for reset password instructions.',
  setPassword: 'Set password',
  setNewPassword: 'Type below your new password',
  invalidResetPasswordLink: 'Reset password link expired or is invalid.',
};
