import styled from '@emotion/styled';

export const SoonToBeAnnounced = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -20px;
  background-color: #EAF6F6;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  position: relative;
  h1{
  position: absolute;
  font-size: ${({ theme }) => theme.functions?.toEm(60)};
  color: ${({ theme }) => theme.colors?.secondary};
  width:368px;
  top:130px;
  left:40px;
  padding:0 !important;
  margin: 0 !important;
  }
  img{
    width:100%;
    object-fit: contain;
    display:block;
  }

   ${props => props.theme.functions?.mediaQueryMax('800px', `
  h1{
    width:170px;
    font-size:30px;
    top:10px;
    left:35px;
  }
  `)}
}
`;
