import React from 'react';
import { Trans } from 'react-i18next';
import { ConfirmModalContainer } from '../ConfirmModal/styles';
import { ModalBackground } from '../../../../../../../components/Layout/components/Header/components/RaiseHandStyles';
import { Button, P } from '../../../../../../../components/Collection';
import { ButtonsContainer } from './styles';

const { history } = window;

const MatchFormCancelModal = ({ cancelHandler }) => (
  <>
    <ModalBackground />
    <ConfirmModalContainer isCancelModal>
      <P bold textAlign="center">
        <Trans ns="friendlyMatches" i18nKey="cancelPopup.title">
          Are you sure you want to quit creating
          this match invitation?
        </Trans>
      </P>

      <ButtonsContainer>
        <Button outline onClick={() => cancelHandler()} width="100%" margin="23px 20px 0 0">
          <Trans ns="friendlyMatches" i18nKey="cancelPopup.stay">
            Cancel
          </Trans>
        </Button>
        <Button onClick={() => history.back()} width="100%" margin="23px 0 0 0">
          <Trans ns="friendlyMatches" i18nKey="cancelPopup.quit">
            Quit
          </Trans>
        </Button>
      </ButtonsContainer>
    </ConfirmModalContainer>
  </>
);

export default MatchFormCancelModal;
