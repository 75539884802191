export default {
  title: 'Joacă local. Accede internațional.',
  subtitle: 'Alătură-te unei comunități globale de jucători amatori',
  seoTitle: 'Sportya este pasul următor în evoluția comunității Tenis Partener | Sportya',
  subheaderText: {
    1: '12 ani în devenire, Sportya este următorul pas în evoluția comunității Tenis Partener.',
    2: 'Sportya este un fenomen dedicat unei comunități guvernate de pasiunea pentru sport, energizată de spiritul competiției, nedivizată de vârstă, sex, culoare sau alte credințe.',
    3: 'Sportya nu are frontiere geografice, nu sunt necesare pașapoarte. Aici suntem ghidați doar de spiritul de fair-play și ne leagă un singur limbaj, limbajul SPORTULUI.',
    learnMore: 'Află mai multe',
  },
  infoItem1: {
    title: 'Nivelul de Joc',
    1: 'Toți membrii Sportya trebuie să își evalueze nivelul de joc atunci când se alătură comunității, pe baza unor standarde și reprezentări clare ale jocului.',
    2: 'Există 6 niveluri de joc disponibile, de la începători la experți. Nivelul de joc determină potrivirea corectă atât în evenimente competitive, cât și în meciuri amicale, și este ajustat dinamic în funcție de rezultate.',
    3: 'Toți jucătorii de tenis sunt bineveniți. Ne străduim să oferim experiențe de meci echilibrate, în funcție de abilități tehnice și formă fizică, în concordanță cu dificultatea proiectată a meciului.',
    4: 'Rezultatul final - încurajăm competiția distractivă și antrenantă, fundamentul sportului, care duce în cele din urmă la progres în jocul tău și în viața de zi cu zi.',
  },
  infoItem2: {
    title: 'Turnee de Tenis pentru Amatori',
    1: 'Cu fiecare eveniment, sărbătorim competiția și dezvoltăm legături sociale.',
    2: 'Membrii Sportya au acces la un calendar centralizat de evenimente, organizate în strâns parteneriat cu cluburi din întreaga lume. JOACĂ CEL MAI BUN MECI, descoperă noi locații și stabilește legături cu oameni din culturi diferite, uniți de limbajul SPORTULUI.',
    3: 'Alege cum vrei să joci, de la evenimente clasice, knockout, organizate în weekend, la evenimente cu format de grupe, evenimente organizate in timpul serii și multe altele. Crează-ți propriul profil de jucător, conectează-te cu alți jucători și profită la maxim de experiența jocului de tenis.',
  },
  infoItem3: {
    title: 'Ligi Locale de Tenis',
    1: 'Știm că timpul este o problemă pentru mulți membri Sportya și că structura fixă a programării meciurilor pentru evenimentele clasice de tenis poate fi prohibitivă. Așa au apărut Ligile Locale în Sportya.',
    2: 'Ligile Locale vă permit să jucați un meci pe săptămână, urmând un orar flexibil, menținând în același timp spiritul competiției în viață.',
  },
  infoItem4: {
    title: 'Meciuri Amicale',
    subtitle: 'Re-lansare, în curând',
    1: 'Meciurile amicale sunt cel mai flexibil mod de a experimenta tenisul în Sportya. Odată ce ai devenit membru Sportya, întreaga comunitate devine locul tău de joacă. Meciurile amicale pot fi jucate oricând, oriunde, cu alți membri Sportya.',
    2: 'Îmbrățișează diversitatea de joc pe care o oferă fiecare nou meci, conectează-te cu alți membri oriunde te-ai afla, distrează-te și bucură-te de meciuri echilibrate, totul sincronizat cu viața personală și preferințele tale.',
  },
  readyToPlay: 'Ready? Play!',
  findAnEvent: 'Caută un Eveniment ⟶',
  buttonText: 'Alătură-te acum ⟶',

  learnMore: {
    title: '12 ani în devenire, Sportya este următorul pas în evoluția comunității Tenis Partener.',
    subtitle: 'Sportya este mai mult decât un proiect sau o serie de evenimente. Sportya este un fenomen dedicat unei comunități guvernate de pasiunea pentru sport, energizată de spiritul competiției, nedivizată de vârstă, sex, culoare sau alte credințe. Sportya nu are frontiere geografice, nu sunt necesare pașapoarte. Aici suntem ghidați doar de spiritul de fair-play și ne leagă un singur limbaj, limbajul SPORTULUI.',
    content: {
      first: {
        title: 'De ce am făcut-o?',
        1: `Iubim Tenis Partener și toate experiențele și amintirile care au făcut parte din lunga noastră călătorie de 12 ani.
        Această schimbare marchează sfârșitul unei perioade din viața noastră și face loc unor noi dezvoltări care nu puteau fi realizate pe actuala platformă Tenis Partener.
        Cu toate acestea, multe dintre lucrurile pe care le știți și le iubiți la Tenis Partener vor rămâne neschimbate și se vor regasi în Sportya.`,
        2: 'În timp ce Tenis Partener a fost dezvoltat având în prim plan comunitatea românească a jucătorilor de tenis amator, Sportya se adresează jucătorilor la nivel global. Veți vedea această schimbare începând din iulie 2021, odată cu lansarea calendarului evenimentelor de tenis Sportya în Bulgaria. În următoarele luni vă puteți aștepta să ne vedeți în locuri noi din întreaga lume.',
        3: 'Cu Sportya, ne întoarcem la rădăcinile noastre și ne reafirmăm poziția de inovatori în tehnologie, cu impact experiențial, la fel cum am făcut acum 12 ani, odată cu lansarea primei platforme Tenis Partener. Noua platformă Sportya este construită de la zero și constituie un punct de plecare solid pentru evoluțiile viitoare. De asemenea, adoptăm un program de lansare iterativ mai activ și mai consecvent – vă puteți aștepta la noi funcționalități, lansate bilunar.',
        4: 'Nu în ultimul rând, în următorii ani vom extinde comunitatea în noi sporturi. Contribuția noastră pentru o lume mai bună și mai unită începe cu Sportya. Gândiți-vă la evenimente internaționale multi-sport, care reunesc oameni din diverse culturi. Imaginați-vă oameni mai activi, peste tot în jurul vostru, care își construiesc obiceiuri mai sănătoase, de la o vârstă fragedă.',
        5: `Pentru noi, tot ceea ce facem este condus de dorința de a conecta sportivii lumii și de a-i inspira să rămână activi, pe tot parcursul vieții.
        Acest lucru nu a fost niciodată mai important decât este acum.`,
      },
      second: {
        title: 'Cum afectează acest lucru jucătorii actuali Tenis Partener?',
        1: `Toate conturile Tenis Partener au fost schimbate automat în conturi Sportya (sportya.net). Toate beneficiile de membru sunt intacte. Datele de conectare rămân neschimbate.
        Informațiile dvs. private legate de contul dvs. nu au fost transferate către nicio entitate terță parte. Atât Sportya, cât și Tenis Partener sunt mărci deținute și administrate de MPG România.`,
        2: 'Îți mulțumim că ne-ai lăsat să facem parte din viața ta. Suntem nerăbdători să descoperim împreună viitorul.',
        3: `Meciuri frumoase,
        Echipa Sportya / Tenis Partener`,
      },
    },
    button: 'Înapoi',
  },
};
