import React from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import FormInput from '../../../../components/Form/Input';
import Button from '../../../../components/Form/Button';
import { Paragraph } from '../../../../components';
import { Container } from './styles';
import { updateAccount } from './actions';
import { H3 } from '../../../../components/Collection';

const ChangePassword = ({ accountInfo }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(commonT('requiredField'))
      .min(8, commonT('minChars', { chars: 8 }))
      .matches(/[^A-Za-z 0-9]/g, commonT('mustContainerSpecialChars'))
      .max(30, commonT('maxChars', { chars: 30 })),

    confirmNewPassword: Yup.string().required(commonT('requiredField'))
      .oneOf(
        [Yup.ref('newPassword'), null],
        t('profileSettings.changePassword.passwordsDoNotMatch'),
      ),
  });

  return (
    <Container>
      <H3 margin="0 0 20px 0">
        <Trans ns="settings" i18nKey="profileSettings.changePassword.title">
          Change Password
        </Trans>
      </H3>
      <Formik
        enableReinitialize
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={formSchema}
        validateOnChange
        onSubmit={async (values, { setSubmitting }) => {
          const payload = {
            oldPassword: values.oldPassword,
            password: values.newPassword,
          };

          await updateAccount({
            id: accountInfo.id,
            payload,
            addToast,
            setSubmitting,
            dispatch,
            t: commonT,
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
          values,
          // setFieldValue,
          // setFieldTouched,
        }) => (
          <Form>
            <FormInput
              name="oldPassword"
              type="password"
              value={values.oldPassword}
              placeholder={t('profileSettings.changePassword.oldPassword')}
              label={t('profileSettings.changePassword.oldPassword')}
              // onChange={setFieldValue}
              // onBlur={setFieldTouched}
              autoComplete="on"
              {...{ errors, touched }}
            />

            <Paragraph>
              {t('profileSettings.changePassword.passwordRequirement')}
            </Paragraph>

            <FormInput
              name="newPassword"
              type="password"
              value={values.newPassword}
              placeholder={t('profileSettings.changePassword.newPassword')}
              label={t('profileSettings.changePassword.newPassword')}
              // onChange={setFieldValue}
              // onBlur={setFieldTouched}
              autoComplete="on"
              {...{ errors, touched }}
            />

            <FormInput
              name="confirmNewPassword"
              type="password"
              value={values.confirmNewPassword}
              placeholder={t(
                'profileSettings.changePassword.confirmNewPassword',
              )}
              label={t('profileSettings.changePassword.confirmNewPassword')}
              // onChange={setFieldValue}
              // onBlur={setFieldTouched}
              autoComplete="on"
              {...{ errors, touched }}
            />

            <Button
              type="submit"
              black
              disabled={isSubmitting || !(isValid && dirty)}
            >
              {t('profileSettings.changePassword.savePassword')}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ChangePassword;
