import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import { activateAccount } from './actions';
import { store as socketStore } from '../../../components/Socket/store';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { updateAccountInfoState } from '../../../components/Layout/reducers/session';
import mixpanel from '../../../mixpanel';

const ActivateAccount = () => {
  const { id, activationCode } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const { accountInfo, isLoggedIn } = useSelector(state => state?.session);
  const { t: commonT } = useTranslation('common');

  useEffect(async () => {
    if (
      (socketClientId && isEmpty(accountInfo))
      || (!isEmpty(accountInfo) && accountInfo.id === socketClientId)
    ) {
      await activateAccount(id, activationCode, socketClientId);
      localStorage.setItem('mixpanelIntegration', 'Account activated');
    }
  }, [accountInfo, socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        mixpanel.track('FE Activated account', {
          distinct_id: accountInfo?.mixpanelId,
        });

        if (isLoggedIn) {
          if (!isEmpty(accountInfo)) {
            dispatch(updateAccountInfoState({ status: 'activated' }));
          }
        }

        history.push('/dashboard/welcome');
      } else {
        history.push('/login');
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socket, accountInfo]);

  return <LoadingSpinner />;
};

export default ActivateAccount;
