import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from 'react-router-dom';

// Components
import LoadImage from "../../../../components/common/LoadImage";
import ToggleVisible from "../../../../components/common/ToggleVisible";
import Avatar from "../Avatar";
import GroupChatModal from './components/GroupChatModal';
import SeeMembersListModal from './components/SeeMembersListModal';
import LeaveChatConfirmationModal from './components/LeaveChatConfirmationModal';
import { Button, Paragraph } from '../../../../components';
import Modal from '../../../../components/Modal';
import mixpanel from "../../../../mixpanel";

// Actions
import {
  deleteConversation,
  leaveConversation,
  muteConversation,
  unmuteConversation,
} from "../../actions";
import {
  removeConversationFromList,
  setLeftConversation,
  setMuteConversation,
  setUnmuteConversation,
} from '../../reducers';

const ConversationHeader = ({
  conversationId,
  typingMessages,
  setMobileToggleContent,
  isBlocked,
}) => {
  const { t } = useTranslation('conversations');
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const { accountInfo } = useSelector(state => state?.session);
  const { list: { data: conversations } } = useSelector(state => state?.conversations);
  const { data: onlineUsers } = useSelector(state => state?.dashboard?.onlineUsers);

  const [isOnline, setIsOnline] = useState(false);
  const [addParticipantsFlag, setAddParticipantsFlag] = useState(false);
  const [groupChatModal, setGroupChatModal] = useState(false);
  const [seeGroupMembersModal, setSeeGroupMembersModal] = useState(false);

  const [confirmLeaveConversation, setConfirmLeaveConversation] = useState(false);
  const [confirmDeleteConversation, setConfirmDeleteConversation] = useState(false);

  const conversationInfo = conversations.filter(({ id }) => id?.toString() === conversationId).shift();

  const typingMessage = typingMessages[conversationId];

  useEffect(() => {
    if (!isEmpty(conversationInfo) && !isEmpty(onlineUsers)) {
      const user = onlineUsers.filter(
        ({ userId, online }) => userId === Number(conversationInfo?.userInfo?.userId) && online,
      ).shift();

      setIsOnline(user?.online);
    }
  }, [conversationInfo, onlineUsers]);

  const handleLeaveConversation = async () => {
    await leaveConversation({ conversationId, addToast, t });

    await dispatch(setLeftConversation({
      conversationId,
      userId: accountInfo?.userId,
    }));

    setConfirmLeaveConversation(false);
  };

  const handleMuteConversation = async () => {
    await muteConversation({ conversationId, addToast, t });
    await dispatch(setMuteConversation({ conversationId, userId: accountInfo?.userId }));
    mixpanel.track('Mute Conversation (from Conversation Menu)');
  };

  const handleUnmuteConversation = async () => {
    await unmuteConversation({ conversationId, addToast, t });
    await dispatch(setUnmuteConversation({ conversationId, userId: accountInfo?.userId }));
    mixpanel.track('Unmute Conversation (from Conversation Menu)');
  };

  const handleDeleteConversation = async () => {
    await deleteConversation({ conversationId, addToast, t });
    await dispatch(removeConversationFromList(conversationId));
    mixpanel.track('Delete Conversation (from Conversation Menu)');
    history.push(`/conversations`);
  };

  const handleGroupChatModal = (action = 'open') => {
    // reset group members if modal closed
    if (action === 'close') {
      setGroupChatModal(false);
      setAddParticipantsFlag(false);
    }

    if (action === 'open') {
      mixpanel.track('Start Group Chat with Partner from Conversation Menu');
      setGroupChatModal(true);
    }
  };

  const handleAddParticipants = () => {
    setAddParticipantsFlag(true);
    setGroupChatModal(true);
  };

  const handleSeeGroupMembers = async () => {
    setSeeGroupMembersModal(!seeGroupMembersModal);
  };

  const viewProfile = () => {
    mixpanel.track('View Player Profile from Conversation Menu');
    history.push(`/accounts/${conversationInfo?.userInfo?.id}`);
  };

  const groupNamesList = (conversationInfo?.members || [])
    .reduce((acc, { firstName }) => {
      acc.push(firstName);
      return acc;
    }, [])
    .filter((v) => v !== accountInfo.firstName)
    .join(", ");

  const participants = conversationInfo?.members.length;

  const hasLeftGroup = conversationInfo?.type === 'group' && (conversationInfo?.leftBy || [])
    .includes(accountInfo?.userId);

  const handleInvite = () => {
    mixpanel.track('Initiate CREATE MATCH', { sp_action_source: 'Chat - Invite to Match' });
    history.push('/friendly-matches/create/invite', {
      fetch: true,
      match: {
        gameType: 'singles',
        opponent: conversationInfo?.userInfo,
      },
    });
  };

  const backHandler = () => {
    setMobileToggleContent(false);
    history.push('/conversations');
  };

  const fullName = `${conversationInfo?.userInfo.firstName} ${conversationInfo?.userInfo.lastName}`;

  return (
    <div className="conversation-header space-between d-flex">
      <div className="back-arrow" onClick={backHandler}>
        <img src={LoadImage('back-arrow.svg')} alt="Back" />
      </div>

      {conversationInfo?.type === 'individual' && (
        <div className="conversation-name" onClick={() => history.push(`/accounts/${conversationInfo?.userInfo?.id}`)}>
          <Avatar conversation={conversationInfo} accountInfo={accountInfo} />

          <div>
            <h3>{conversationInfo?.name ?? fullName}</h3>
            {!isBlocked(conversationInfo) && typingMessage?.isTyping && (
              <p className="is-typing">
                <Trans ns="conversations" i18nKey="isTyping">
                  {{ user: typingMessage?.users }} typing...
                </Trans>
              </p>
            )}

            {!isBlocked(conversationInfo) && !typingMessage?.isTyping && (
              <p className="status">
                {isOnline ? (
                  <>
                    <span className="is-online" />
                    <Trans ns="conversations" i18nKey="online">Online</Trans>
                  </>
                ) : (
                  <>
                    <span className="is-offline" />
                    <Trans ns="conversations" i18nKey="offline">Offline</Trans>
                  </>
                )}
              </p>
            )}

            {isBlocked(conversationInfo) && (
              <p className="is-blocked">
                <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.youBlockedThisPlayer">
                  You’ve blocked this player
                </Trans>
              </p>
            )}
          </div>
        </div>
      )}

      {conversationInfo?.type === 'group' && (
        <div className="conversation-name" onClick={handleSeeGroupMembers}>
          <Avatar conversation={conversationInfo} accountInfo={accountInfo} />

          <div>
            <h3>
              {conversationInfo?.name ?? (
                <Trans ns="conversations" i18nKey="groupName">
                  You, {{ groupNamesList }}
                </Trans>
              )}
            </h3>
            {typingMessage?.isTyping && (
              <p className="is-typing">
                <Trans ns="conversations" i18nKey="isTyping">
                  {{ user: typingMessage?.users }} typing...
                </Trans>
              </p>
            )}

            {!typingMessage?.isTyping && (
              <p className="status">
                <Trans ns="conversations" i18nKey="noOfParticipants">
                  {{ participants }} participants
                </Trans>
              </p>
            )}
          </div>
        </div>
      )}

      <div className="actions-wrapper">
        {conversationInfo?.type === 'individual' && (
          <Button wide outline smaller className="create-match" onClick={() => handleInvite()}>
            <img src={LoadImage(`friendly-matches/start-match/button-invite.svg`)} alt="Create Match" />
            <Paragraph smaller bold><Trans ns="friendlyMatches" i18nKey="createMatch">Create Match</Trans></Paragraph>
          </Button>
        )}

        <div ref={ref} className="actions" onClick={() => setIsComponentVisible(!isComponentVisible)}>
          <img src={LoadImage('three-dots-icon.png')} alt="Actions" className="p10" />
          <ul className={`options ${isComponentVisible && !seeGroupMembersModal ? '' : 'hidden'}`}>
            {conversationInfo?.type === 'individual' && (
              <>
                <li onClick={viewProfile}>
                  <img src={LoadImage('view-profile-outline-icon.svg')} alt="" />
                  <Trans ns="conversations" i18nKey="viewProfile">View Profile</Trans>
                </li>

                {!isBlocked(conversationInfo) && (
                  <li onClick={() => handleGroupChatModal('open')}>
                    <img src={LoadImage('start-group-chat-icon.svg')} alt="" />
                    <Trans ns="conversations" i18nKey="startGroupChat">
                      Start Group Chat with
                      {{ user: conversationInfo?.userInfo?.firstName }}
                    </Trans>
                  </li>
                )}
              </>
            )}

            {conversationInfo?.type === 'group' && (
              <>
                {!hasLeftGroup && (
                  <li onClick={handleAddParticipants}>
                    <img src={LoadImage('add-participants-icon.svg')} alt="" />
                    <Trans ns="conversations" i18nKey="addParticipants">Add Participants</Trans>
                  </li>
                )}

                <li onClick={handleSeeGroupMembers}>
                  <img src={LoadImage('see-group-members-icon.svg')} alt="" />
                  <Trans ns="conversations" i18nKey="seeGroupMembers">See Group Members</Trans>
                </li>
              </>
            )}

            {!hasLeftGroup && !(conversationInfo?.mutedBy || []).includes(accountInfo?.userId) ? (
              <li onClick={handleMuteConversation}>
                <img src={LoadImage('silent-bell-icon.svg')} alt="" />
                <Trans
                  ns="conversations"
                  i18nKey={`mute${conversationInfo?.type === 'group' ? 'Group' : 'Conversation'}`}
                >
                  Mute Conversation
                </Trans>
              </li>
            ) : !hasLeftGroup && (
              <li onClick={handleUnmuteConversation}>
                <img src={LoadImage('silent-bell-icon.svg')} alt="" />
                <Trans
                  ns="conversations"
                  i18nKey={`unmute${conversationInfo?.type === 'group' ? 'Group' : 'Conversation'}`}
                >
                  Unmute Conversation
                </Trans>
              </li>
            )}

            {conversationInfo?.type === 'group' && !hasLeftGroup && (
              <li onClick={() => setConfirmLeaveConversation(true)}>
                <img src={LoadImage('leave-group-chat-icon.svg')} alt="" />
                <Trans ns="conversations" i18nKey="leaveGroup">Leave Group Chat</Trans>
              </li>
            )}
            <li className="delete" onClick={() => setConfirmDeleteConversation(true)}>
              <img src={LoadImage('red-delete-icon.svg')} alt="" />
              <Trans
                ns="conversations"
                i18nKey={`delete${conversationInfo?.type === 'group' ? 'Group' : 'Conversation'}`}
              >
                Delete Conversation
              </Trans>
            </li>
          </ul>
        </div>
      </div>

      <GroupChatModal
        conversationId={conversationId}
        groupChatModal={groupChatModal}
        handleGroupChatModal={handleGroupChatModal}
        addParticipantsFlag={addParticipantsFlag}
        accountInfo={accountInfo}
        conversationInfo={conversationInfo}
      />

      <SeeMembersListModal
        seeGroupMembersModal={seeGroupMembersModal}
        setSeeGroupMembersModal={setSeeGroupMembersModal}
        handleSeeGroupMembers={handleSeeGroupMembers}
        conversationInfo={conversationInfo}
        handleLeaveConversation={handleLeaveConversation}
        accountInfo={accountInfo}
      />

      <LeaveChatConfirmationModal
        confirmLeaveConversation={confirmLeaveConversation}
        setConfirmLeaveConversation={setConfirmLeaveConversation}
        handleLeaveConversation={handleLeaveConversation}
      />

      <Modal
        isOpen={confirmDeleteConversation}
        onRequestClose={() => setConfirmDeleteConversation(false)}
      >
        <div className="modal-header">
          <Paragraph large bold className="text-center">
            <Trans
              ns="conversations"
              i18nKey={`deleteConfirmation${conversationInfo?.type === 'group' ? 'Group' : 'Conversation'}`}
            >
              Are you sure you want to delete this <br /> conversation ?
            </Trans>
          </Paragraph>
        </div>
        <div className="modal-footer justify-content-center">
          <Button outline onClick={() => setConfirmDeleteConversation(false)} className="mr10">
            <span><Trans ns="conversations" i18nKey="cancel">Cancel</Trans></span>
          </Button>
          <Button onClick={handleDeleteConversation} red>
            <span><Trans ns="conversations" i18nKey="delete">Delete</Trans></span>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConversationHeader;
