import React from 'react';

import { IndividualPlayer, IndividualPlayerGroup } from './styles';
import Paragraph from '../../../../../components/Paragraph';
import Level from '../Level';

import PlayerBadge from '../PlayerBadge';

const pointsSum = (score) => score.reduce((acc, { points }) => acc + points, 0);

const calculateSetWinner = (player, opponent) => {
  let winner;
  if (player?.points === opponent?.points) {
    return winner = player?.tie > opponent?.tie ? 'player' : 'opponent';
  }
  winner = player?.points > opponent?.points ? 'player' : 'opponent';

  return winner;
};

const setsManagement = (score, opponentScore) => score.reduce((acc, curr) => {
  const [opponent] = opponentScore.filter(({ set }) => set === curr.set);
  const calculateWinner = calculateSetWinner(curr, opponent);
  Object.assign(acc, {
    loses: (acc.loses || 0) + (calculateWinner === 'opponent' ? 1 : 0),
    wins: (acc.wins || 0) + (calculateWinner === 'player' ? 1 : 0),
  });
  return acc;
}, {});

const index = ({
  groupData, final, league, duo, elimData, playerType,
}) => {
  const groupStats = groupData?.reduce((acc, curr) => {
    // eslint-disable-next-line no-param-reassign
    acc[curr.player.userId] = {
      ...curr.player,
      partner: curr.playerPartner,
      wins: ((acc[curr.player.userId] || {}).wins || 0) + (curr.winner === 'player' ? 1 : 0),
      loses: ((acc[curr.player.userId] || {}).loses || 0) + (curr.winner === 'opponent' ? 1 : 0),
      gamesLost: ((acc[curr.player.userId] || {}).gamesLost || 0) + pointsSum(curr.opponent.score),
      gamesWon: ((acc[curr.player.userId] || {}).gameswon || 0) + pointsSum(curr.player.score),
      // eslint-disable-next-line max-len
      setsWon: ((acc[curr.player.userId] || {}).setsWon || 0) + (setsManagement(curr.player.score, curr.opponent.score)).wins,
      // eslint-disable-next-line max-len
      setsLost: ((acc[curr.player.userId] || {}).setsLost || 0) + (setsManagement(curr.player.score, curr.opponent.score)).loses,
    };

    // eslint-disable-next-line no-param-reassign
    acc[curr.opponent.userId] = {
      ...curr.opponent,
      partner: curr.opponentPartner,
      wins: ((acc[curr.opponent.userId] || {}).wins || 0) + (curr.winner === 'opponent' ? 1 : 0),
      loses: ((acc[curr.opponent.userId] || {}).loses || 0) + (curr.winner === 'opponent' ? 1 : 0),
      gamesLost: ((acc[curr.opponent.userId] || {}).gamesLost || 0) + pointsSum(curr.player.score),
      gamesWon: ((acc[curr.opponent.userId] || {}).gameswon || 0) + pointsSum(curr.opponent.score),
      // eslint-disable-next-line max-len
      setsWon: ((acc[curr.opponent.userId] || {}).setsWon || 0) + (setsManagement(curr.opponent.score, curr.player.score)).wins,
      // eslint-disable-next-line max-len
      setsLost: ((acc[curr.opponent.userId] || {}).setsLost || 0) + (setsManagement(curr.opponent.score, curr.player.score)).loses,
    };

    return acc;
  }, {});

  const sortByWins = obj => {
    const sortedGroupValues = [];
    Object.keys(obj)?.map((group) => sortedGroupValues.push(obj[group]));

    return sortedGroupValues?.sort((a, b) => a?.wins + b?.wins);
  };

  return (
    league ? (
      <IndividualPlayerGroup>
        {duo ? (
          <>
            {/* duo group case */}
            {sortByWins(groupStats)?.map((element, idx) => (
              <div className="individualPlayerGroup" key={idx}>
                {/* -  player info */}
                <div className="levelWrapp duoGroup">

                  <div className="groupPlayer">
                    <div className="level">
                      <Level small level={element?.level} />
                    </div>
                    <Paragraph small>
                      {!element?.userId ? 'BYE' : `${element?.firstName } ${ element?.lastName}`}
                    </Paragraph>
                  </div>

                  <div className="groupPlayer">
                    <div className="level">
                      <Level small level={element?.partner?.level} />
                    </div>
                    <Paragraph small>
                      {!element?.partner?.userId
                        ? 'BYE'
                        : `${element?.partner?.firstName } ${ element?.partner?.lastName}`}
                    </Paragraph>
                  </div>
                </div>

                {/* -  score */}
                <div className="winsLosses">
                  <Paragraph small>
                    {`${element?.wins > 0 ? element?.wins : 0}-${element?.loses > 0 ? element?.loses : 0}`}
                  </Paragraph>
                </div>
                <div className="setsWon">
                  <Paragraph small>
                    {`${element?.setsWon > 0 ? element?.setsWon : 0}-${element?.setsLost > 0 ? element?.setsLost : 0}`}
                  </Paragraph>
                </div>
                <div className="gamesWon">
                  <Paragraph small>
                    {/* eslint-disable-next-line max-len */}
                    {`${element?.gamesWon > 0 ? element?.gamesWon : 0} - ${element?.gamesLost > 0 ? element?.gamesLost : 0} `}
                  </Paragraph>
                </div>
                <div className="rank">
                  <Paragraph small>{idx + 1}</Paragraph>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {/* solo group case */}
            {sortByWins(groupStats)?.map((element, idx) => (
              <div className="individualPlayerGroup" key={idx}>
                <div className="levelWrapp">
                  <div className="level">
                    <Level small level={element?.level} />
                  </div>
                  <Paragraph small>
                    {!element?.userId ? 'BYE' : `${element?.firstName } ${ element?.lastName}`}
                  </Paragraph>
                </div>

                <div className="winsLosses">
                  <Paragraph small>
                    {`${element?.wins > 0 ? element?.wins : 0}-${element?.loses > 0 ? element?.loses : 0}`}
                  </Paragraph>
                </div>
                <div className="setsWon">
                  <Paragraph small>
                    {`${element?.setsWon > 0 ? element?.setsWon : 0}-${element?.setsLost > 0 ? element?.setsLost : 0}`}
                  </Paragraph>
                </div>
                <div className="gamesWon">
                  <Paragraph small>
                    {/* eslint-disable-next-line max-len */}
                    {`${element?.gamesWon > 0 ? element?.gamesWon : 0} - ${element?.gamesLost > 0 ? element?.gamesLost : 0} `}
                  </Paragraph>
                </div>
                <div className="rank">
                  <Paragraph small>{idx + 1}</Paragraph>
                </div>
              </div>
            ))}
          </>
        )}
      </IndividualPlayerGroup>
    ) : (
      <IndividualPlayer duo={duo}>
        {/* elims case */}
        <>
          <div className="individualPlayer">
            <div className={`leftWrapper ${final ? '' : 'paragraphDefault'
              }`}
            >
              <div className="flex nameLevel">
                <div className="level">
                  <Level
                    gold={!!final}
                    level={playerType === 'opponent' ? elimData?.opponent?.level : elimData?.player?.level}
                    small
                  />
                </div>
                <Paragraph bold small>
                  {playerType === 'opponent'
                    ? `${elimData?.opponent?.firstName } ${ elimData?.opponent?.lastName}`
                    : `${elimData?.player?.firstName } ${ elimData?.player?.lastName}`}
                </Paragraph>

                <div className="badge">
                  {/* - player badge */}
                  {elimData?.winner === 'player' && playerType !== 'opponent' && <PlayerBadge winner={!!final} />}

                  {/* - cover the second place case */}
                  {playerType === 'opponent'
                    && elimData?.winner === 'player'
                    && elimData?.competition?.stage === elimData?.competition?.finalStage
                    && (
                      <PlayerBadge second={!!final} />
                    )}

                  {playerType !== 'opponent'
                    && elimData?.winner === 'opponent'
                    && elimData?.competition?.stage === elimData?.competition?.finalStage
                    && (
                      <PlayerBadge second={!!final} />
                    )}

                  {/* - opponent badge */}
                  {elimData?.winner === 'opponent' && playerType === 'opponent' && <PlayerBadge winner={!!final} />}
                </div>

              </div>

              <div className="leftScore">
                <Paragraph bold small>4</Paragraph>
                <Paragraph small>2</Paragraph>
                <Paragraph bold small>6</Paragraph>
              </div>
            </div>

            <div className={`rightWrapper ${final ? '' : 'paragraphDefault'} `}>
              <div className="winsLeft">
                <div>
                  {' '}
                  <Paragraph small>3-0</Paragraph>
                </div>
                <div>
                  {' '}
                  <Paragraph small>13-4</Paragraph>
                </div>
              </div>
              <div className="winsRight">
                <div><Paragraph small>3-0</Paragraph></div>
                <div><Paragraph small>1</Paragraph></div>
              </div>
            </div>
          </div>

          {/* duo partener */}
          {duo ? (
            <div className="individualPlayer">
              <div className={`leftWrapper ${final ? '' : 'paragraphDefault'} `}>
                <div className="flex nameLevel">

                  <div className="level">
                    <Level
                      gold={!!final}
                      level={playerType === 'opponent'
                        ? elimData?.opponentPartner?.level
                        : elimData?.playerPartner?.level}
                      small
                    />
                  </div>

                  <Paragraph bold small>
                    {playerType === 'opponent'
                      ? `${elimData?.opponentPartner?.firstName } ${ elimData?.opponentPartner?.lastName}`
                      : `${elimData?.playerPartner?.firstName } ${ elimData?.playerPartner?.lastName}`}
                  </Paragraph>
                </div>
              </div>
            </div>
          ) : ''}
        </>
      </IndividualPlayer>
    )
  );
};

export default index;
