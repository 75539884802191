import { post, remove } from '../common/http';

export const addRating = async ({
  userId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await post(`/accounts/${userId}/rating`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};

export const removeRating = async ({
  userId,
  payload = {},
  setSubmitting,
  addToast,
  t,
}) => {
  try {
    await remove(`/accounts/${userId}/rating`, payload);
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};
