import styled from '@emotion/styled';

export const Container = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '147px',
  borderRadius: '10px',
  background: '#FFE071',
  padding: '30px',
  margin: '20px 0 0 0',
  ...props.isRo ? {
    '@media (max-width: 1260px)': {
      '> p': {
        textAlign: 'center',
        margin: '0 0 20px 0',
      },
    },
  } : {
    '@media (max-width: 1075px)': {
      '> p': {
        textAlign: 'center',
        margin: '0 0 20px 0',
      },
    },
  },
  '@media (max-width: 450px)': {
    padding: '20px 10px',
  },
}));

export const Checkmark = styled.img({
  height: '15px',
  width: '15px',
  margin: '0 10px 0 0',
});

export const Steps = styled.ol({
  display: 'flex',
  listStyle: 'none',
  margin: 'auto 0 0 0',
  '@media (max-width: 1260px)': {
    justifyContent: 'center',
    margin: '10px 0 20px 0',
  },
});

export const Step = styled.li(props => ({
  position: 'relative',
  // maxWidth: '280px',
  width: '100%',
  height: '6px',
  background: props.completed
    ? props.theme.colors.black : props.theme.colors.white,

  '&:first-of-type': {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },

  '&:last-of-type': {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    '&:after': {
      display: 'none',
    },
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    right: '0',
    bottom: '-2px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    background: props.completed
      ? props.theme.colors.black : props.theme.colors.white,
  },
}));

export const Stage = styled.div(props => ({
  display: 'flex',
  width: 'max-content',
  alignItems: 'center',
  position: 'absolute',
  top: '-30px',
  left: '50%',
  transform: 'translateX(-50%)',
  '@media (max-width: 1260px)': {
    top: '-25px',
  },
  ...props.isRo ? {
    '@media (max-width: 790px)': {
      display: 'none',
    },
  } : {
    '@media (max-width: 650px)': {
      display: 'none',
    },
  },
}));

export const Progress = styled.div({
  display: 'flex',
  width: '100%',
  '@media (max-width: 1260px)': {
    flexDirection: 'column',
  },
});
