import styled from '@emotion/styled';
import { Button } from '../../../components/Collection';

export const DeclineButton = styled(Button)(({ theme: { colors } }) => ({
  height: '40px',
  padding: '0 15px',
  marginLeft: '10px',
  color: colors.error,
  transition: 'all 0.3s ease-out',
  boxShadow: `inset 0 0 0 1px ${colors.error}`,
  '&:hover': {
    boxShadow: `inset 0 0 0 3px ${colors.error}`,
  },
}));
