import React from 'react';
import { Trans } from 'react-i18next';

import { Avatar } from '../../../../../Events/JoinCompetition/components/Search/styles';
import {
  FriendIcon,
  Initials,
  PlayerID,
  Username,
} from '../../WantToPlay/styles';
import {
  ContainAvatar,
  Distance,
  DistanceRadiusIcon,
  Location,
  PlayerContainer,
  FullName,
} from '../styles';

import { Paragraph } from '../../../../../../components';
import { P } from '../../../../../../components/Collection';
import LoadImage from '../../../../../../components/common/LoadImage';
import { getAge } from '../../../../helpers';
import { Badge } from '../../../../../../components/LevelsBadges/styles';
import LinkWrapper from '../../../../../../components/Link';
import mixpanel from '../../../../../../mixpanel';
import PlayerRating from '../../../../../../components/PlayerRating';

const nameInitials = ({ firstName, lastName }) => {
  const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
  const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
  return `${first} ${last}`;
};

const PlayerInYourArea = ({ data }) => {
  const {
    userInfo,
    distance,
    isFriend,
    ratingInfo,
  } = data || {};

  const name = `${userInfo?.firstName} ${userInfo?.lastName}`;
  const kmDistance = (distance / 1000).toFixed(2);

  return (
    <PlayerContainer>
      <ContainAvatar>
        <LinkWrapper
          to={`/accounts/${userInfo?.id}`}
          className="text-decoration-none"
          onClick={() => mixpanel.track('View Player from Players in Your Area')}
        >
          {userInfo?.profilePicture
            ? <Avatar src={LoadImage(`accounts/${userInfo?.userId}/${userInfo?.profilePicture}`, true)} alt={name} />
            : <Initials large><Paragraph bold>{nameInitials(userInfo)}</Paragraph></Initials>}
        </LinkWrapper>
        <div className="game-level">
          <Badge small><P xSmall bold>{userInfo?.gameLevel?.singles ?? '-'}</P></Badge>
          <Badge small double><P xSmall bold>{userInfo?.gameLevel?.doubles ?? '-'}</P></Badge>
        </div>
      </ContainAvatar>
      <LinkWrapper
        to={`/accounts/${userInfo?.id}`}
        className="text-decoration-none"
        onClick={() => mixpanel.track('View Player from Players in Your Area')}
      >
        <Username className="text-center">
          <PlayerRating
            ratingInfo={ratingInfo}
          />
          {isFriend && (
            <FriendIcon
              src={LoadImage('friendly-matches/start-match/friends.svg')}
              alt="One of your friends"
            />
          )}
          <FullName>{name}</FullName>
          <PlayerID>#{userInfo?.userId}</PlayerID>
        </Username>
      </LinkWrapper>
      <Location small>
        {getAge(userInfo?.birthDate)}
        {' '}
        -
        {' '}
        {userInfo?.location?.name}
      </Location>
      <Distance>
        <DistanceRadiusIcon
          src={LoadImage('friendly-matches/start-match/distance-radius.svg')}
          alt="How further away is the player from your location"
        />
        <P xSmall>
          {kmDistance < 1
            ? <Trans ns="friendlyMatches" i18nKey="nearby">Nearby</Trans>
            : (
              <Trans ns="friendlyMatches" i18nKey="distanceAway">
                {{ kmDistance }}
                {' '}
                km away
              </Trans>
            )}
        </P>
      </Distance>
    </PlayerContainer>
  );
};

export default PlayerInYourArea;
