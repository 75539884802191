import aes from 'crypto-js/aes';
import { post, get } from '../../../components/common/http';

export const registerHandler = async ({
  values,
  addToast,
  setSubmitting,
  commonT,
  socketClientId,
}) => {
  let registerResponse;
  const KEY = process.env.REACT_APP_ENC_KEY;
  try {
    const payload = {
      ...values,
      socketClientId,
      password: aes.encrypt(values.password, KEY).toString(),
    };
    registerResponse = await post('/accounts', payload);
  } catch ({ response: error }) {
    registerResponse = { error };
  }

  // enable submit button
  setSubmitting(false);

  if (registerResponse && registerResponse.error) {
    const { data: { message = '' } = {} } = registerResponse.error;
    return addToast(commonT(message), {
      appearance: 'error',
      autoDismiss: true,
    });
  }
};

export const getReferredAccount = async ({ referralCode, addToast, commonT }) => {
  try {
    const { data } = await get(`/referred-account/${referralCode}`);
    return data?.data;
  } catch (error) {
    if (error.response?.data?.message) {
      addToast(commonT(error.response?.data?.message), {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    return false;
  }
};
