import styled from '@emotion/styled';

export const BenefitsListItem = styled.div`
  .benefits-list{
    display:flex;
    justify-content:space-between;
    align-items: center;
    margin-bottom:20px;
    @media screen and (max-width:360px){
      flex-direction:column;
      p{
        margin-top:10px !important;
        text-align: center;
      }
      img{
        margin:0 !important;
      }
    }  
    img{
      width:13.26px;
      height:10.56px;
      margin-left:7px;
      ${props => props.theme.functions?.mediaQueryMax('460px', `
        margin-right: 10px;
      `)}
    }
    p{
      max-width:399px;
      margin:0;
      width:100%;
    }
  }

`;
