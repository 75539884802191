export default {
  title: 'Eseményeim',
  seoTitle: 'Eseményeim | Sportya',
  joined: 'Csatlakoztam',
  noJoined: 'Még nem adott meg eseményt.',
  followed: 'Követett',
  noFollowed: 'Még nem követett eseményeket.',
  closed: 'Lezárt',
  noClosed: 'Még nem zártál le egyetlen versenyt sem (az eredmények érvényesek).',
  withdrawals: 'Visszalépések',
  noWithdrawals: 'Még nincs pénzkivételed.',
  findEvent: 'Esemény keresése',
  seeEvent: 'Esemény megtekintése',
  seeCompetition: 'Továbblépés versenyre',
  registeredOnMainBoard: 'Csatlakozott Főlistán',
  registeredInQueueList: 'Csatlakozott Várólistán',
  lookingForPartners: 'Társat keresek',
};
