export default {
  title: 'Bejelentkezés',
  subTitle: 'Üdvözöljük újra',
  seoTitle: 'Bejelentkezés | Sportya',
  placeholders: {
    email: 'Email',
    password: 'Jelszó',
  },
  forgotPassword: 'Elfelejtetted a jelszavad?',
  remember: 'Emlékezz rám',
  button: 'Bejelentkezés',
  socialSignTitle: 'Vagy jelentkezz be a következővel:',
  noAccount: 'Nincs fiókod?',
  termsAndConditions: 'A bejelentkezéssel megerősítem, hogy elolvastam és elfogadom az <1>Általános Szerződési Feltételek</1> és az <1>Adatvédelmi irányelvek</1> című dokumentumokat',
  form: {
    AUTH_FAILED: 'Érvénytelen hitelesítő adatok.',
    something_wrong_happened: 'Valami baj történt.',
  },
};
