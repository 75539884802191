import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { debounce, isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import Option from './Option';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import { get } from '../../../../components/common/http';
import LoadImage from '../../../../components/common/LoadImage';
import { P } from '../../../../components/Collection';
import { setHasSearched } from '../../reducer';

const Container = styled.div({
  margin: '20px 0 40px 0',
  position: 'relative',
});

const SearchBarStyle = styled.input({
  width: '100%',
  height: '60px',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  padding: '20px 70px',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '23px',
  ':focus': {
    outline: 'none',
    outlineWidth: 0,
  },
});

const SearchResults = styled.div({
  position: 'absolute',
  top: '65px',
  left: 0,
  maxHeight: '319px',
  height: 'max-content',
  width: '100%',
  padding: '23px 15px',
  background: '#fff',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  zIndex: 1,
  overflow: 'auto',
});

export const ClearResults = styled.span({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  right: '15px',
  top: '28px',
  transform: 'translate(0, -50%)',
  cursor: 'pointer',
  img: {
    marginRight: '5px',
  },
  '@media (max-width: 875px)': {
    span: {
      display: 'none',
    },
  },
});

export const SearchIcon = styled.img({
  position: 'absolute',
  left: '30px',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
});

const SearchBar = ({
  isLoading,
  totalPlayers,
  handleEnter,
  filters,
  mark,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t: commonT } = useTranslation('common');
  const { t: leaderboardT } = useTranslation('leaderboards');

  const [debounceInterval, setDebounceInterval] = useState(150);
  const [suggestions, setSuggestions] = useState(null);
  const hasOptions = !isEmpty(suggestions);
  const { hasSearched } = useSelector(state => state.leaderboard.list);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { masterSport } = useSelector(state => state.session);

  const fetch = debounce(async string => {
    const startTime = (new Date()).getTime();
    const params = new URLSearchParams({ sportType: masterSport, ...filters, string }).toString();
    const { data: { data } } = await get(`/leaderboards/search?${params}`);
    setSuggestions(data);
    if (((new Date()).getTime() - startTime) > 100) {
      setDebounceInterval(300);
    } else {
      setDebounceInterval(150);
    }
  }, debounceInterval);

  const handleSearching = async string => {
    if (string.length <= 60) {
      if (string.length >= 3) {
        await fetch(string);
      } else if (suggestions?.results.length) {
        setSuggestions(null);
      }
      setIsComponentVisible(true);
    }
  };

  const clearResults = () => {
    setSuggestions(null);
    setFilters({
      ...filters,
      string: null,
    });
    dispatch(setHasSearched(false));
    handleEnter.reset();
    inputRef.current.value = '';
  };

  const showResults = () => {
    if (hasOptions) {
      return setIsComponentVisible(true);
    }
  };

  const handleKeyDown = async key => {
    if (hasSearched && key !== 'Enter') {
      dispatch(setHasSearched(false));
    }

    if (key === 'Enter' && !hasSearched) {
      const string = inputRef.current.value;
      const params = new URLSearchParams({ ...filters, string }).toString();

      const { data: { data } } = await get(`/leaderboards?${params}`);
      dispatch(handleEnter.setter(data));
      setFilters({
        ...filters,
        string,
      });
      setIsComponentVisible(false);
      return mark?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    return null;
  };

  return (
    <Container>
      <SearchBarStyle
        maxLength={60}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onClick={() => showResults()}
        onInput={e => handleSearching(e.target.value)}
        placeholder={isLoading ? commonT('isLoading') : leaderboardT('searchBar', { results: totalPlayers })}
        ref={inputRef}
        defaultValue={filters.string || null}
      />
      <SearchIcon src={LoadImage('search-icon_black.svg')} />
      {inputRef.current?.value.length > 0 && (
        <ClearResults onClick={() => { clearResults(); }}>
          <img src={LoadImage('close-icon.svg')} alt="clear" />
          {' '}
          <span>{commonT('clearResults')}</span>
        </ClearResults>
      )}
      {isComponentVisible && (
        <SearchResults ref={ref}>
          {(suggestions?.results || []).map((player, key) => (<Option player={player} key={key} />))}
          {inputRef.current?.value.length < 3 && (
            <P textAlign="center">
              <Trans ns="common" i18nKey="typeAtLeast3Chars">Type at least 3 characters...</Trans>
            </P>
          )}
          {!suggestions?.results.length && inputRef.current?.value.length >= 3 && (
            <P textAlign="center">
              <Trans ns="common" i18nKey="noResultsFound">No results found</Trans>
            </P>
          )}
        </SearchResults>
      )}
    </Container>
  );
};

export default SearchBar;
