export default {
  home: 'Home',
  login: 'Log In',
  register: 'Sign Up',
  dashboard: 'Dashboard',
  findEvent: 'Events',
  friendlyMatches: 'Friendly Matches',
  bookCourt: 'Book a Court',
  forPlayers: 'For Players',
  forClubs: 'For Clubs',
  blog: 'Blog',
  help: 'Help',
  findAPlayer: 'Players',
  leaderboards: 'Rankings',
  lookForAnything: 'Look for anything...',
  notifications: 'Notifications',
  membershipType: "<0>{{t(prop.membership) || 'Free'}}</0> Account",
  gameLevel: 'Game Level',
  myProfile: 'Profile',
  membership: 'Membership',
  comingSoon: 'Coming Soon',
  free: 'Free Account',
  summer: 'Summer Account',
  premium: 'Premium Account',

  menu: {
    friends: 'Friends',
    inviteFriends: 'Invite Your Friends',
    myEvents: 'My Events',
    bookings: 'Bookings',
    mySettings: 'Settings',
    myOrders: 'Orders',
    virtualWallet: 'Virtual Wallet',
    plans: 'Sportya Plan',
    logout: 'Log Out',
  },
};
