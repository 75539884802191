import React from 'react';
import styled from '@emotion/styled';

const NotificationBubbleStyle = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background-color: #F42B02;
  color:white;
  font-size: 12px;
  font-weight: normal;
  text-align:center;
  display: inline-block;
  ${({ addLeftMargin }) => !!addLeftMargin && 'margin: 0 0 0 5px;'}
`;

const NotificationBubble = ({ children, ...props }) => (
  <NotificationBubbleStyle {...props}>
    {children}
  </NotificationBubbleStyle>
);

export default NotificationBubble;
