import styled from '@emotion/styled';

export const ProductStyle = styled.div`
overflow: auto;

  p{
    margin:0;
  }

  .transaction{
    margin-top:20px;
    display:flex;
    justify-content:space-between;
    
    .transactionId{
    max-width:90px;
    width:100%;
      >p
      {
        color: ${({ theme }) => theme?.colors?.darkTurquoise};
      }
    }

    .transactionDate{
      max-width:141px;
      width:100%;
    }

    .transactionProduct{
      max-width:487px;
      width:100%;
    }
    
    .transactionAmount{
      max-width:100px;
      width:100%;
    }
  }

    span
    {
      display: none;

      ${props => props.theme.functions?.mediaQueryMax('810px', `
        display: block;
      `)}
    }

    ${props => props.theme.functions?.mediaQueryMax('810px', `
    .transaction
    {
      display: grid;
      padding-bottom: 10px;
      border-bottom: 1px solid #EAF6F6;
      width: max-content;

      .transactionId
      {
        margin-bottom: 5px;
        >p
        {
          color: black;
        }
      }
      .transactionDate
      {
        margin-bottom: 5px;
      }
      .transactionProduct
      {
        margin-bottom: 5px;
      }
      .transactionAmount
      {
        margin-bottom: 5px;
      }
    }`)}

    p{span{color:${({ theme }) => theme?.colors?.darkTurquoise}}}
`;
