import styled from '@emotion/styled';

export const ConfirmModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  position: 'fixed',
  left: '50%',
  top: '50%',

  maxWidth: '600px',
  width: '100%',

  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  background: '#fff',
  padding: '40px',
  zIndex: 99,

  '@media (max-width: 768px)': {
    maxWidth: 'calc(100vw - 40px)',
  },
});

export const CheckmarkEllipse = styled.img({
  width: '50px',
  margin: '0 0 10px 0',
});

export const InfoIcon = styled.img({
  width: '15px',
  margin: '8px  5px auto 0',
});
