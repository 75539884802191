export default {
  SOMETHING_WENT_WRONG: "And ... it’s OUT! Something went wrong. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  CHANGE_PASSWORD_FAILED: "Password could not be updated.",
  CHANGE_PASSWORD_SUCCESS: "Your password was successfully updated.",
  ACCOUNT_ACTIVATED: "Your account was successfully validated.",
  ACCOUNT_ALREADY_ACTIVATED: "Your account is already validated.",
  COULD_NOT_ACTIVATE_ACCOUNT: "We encountered an error and cannot validate your account. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  ACCOUNT_ALREADY_EXIST: "The account already exists. Please log in or reset your password.",
  AUTH_FAILED: "We encountered an error and could not authenticate you. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  INVALID_CREDENTIALS: "Invalid credentials. Please check spelling and try again.",
  INTERNAL_ERROR: "Internal service error. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  SERVICE_UNAVAILABLE: "Oops! Something went wrong! Help us improve your experience by sending an error report at contact@sportya.net.",
  UPDATE_ACCOUNT_SUCCESS: "Account was successfully updated.",
  UPDATE_ACCOUNT_FAILED: "Account could not be updated. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  UPDATE_CLUB_SUCCESS: 'Club account was successfully updated.',
  UPDATE_CLUB_FAILED: 'Club account could not be updated.',
  SESSION_EXPIRED: "Session expired. Please authenticate again.",
  SUCCESSFULLY_FOLLOW_EVENT: "You are now following the event.",
  SUCCESSFULLY_UNFOLLOW_EVENT: "You have unfollowed the event.",
  FAILED_FOLLOW_EVENT: "Failed to follow / unfollow event.",
  ADD_FRIEND_SUCCESS: "You have successfully sent the friend request",
  RAISE_HAND_SUCCESS: "You raised your hand.",
  LOWER_HAND_SUCCESS: "You lowered your hand.",
  ACCEPT_FRIEND_SUCCESS: "Congrats! You have a new friend on Sportya.",
  REMOVE_FRIEND_SUCCESS: "The player was successfully removed from your Friends list.",
  DECLINE_FRIEND_SUCCESS: "You have successfully declined the friend request.",
  CANCEL_FRIEND_SUCCESS: "You have successfully canceled the friend request.",
  SUBSCRIPTION_ALREADY_ACTIVATED: "Your membership was already activated.",
  COULD_NOT_AUTHORIZE_PAYMENT: "We could not authorize your payment. Please try again.",
  MEMBERSHIP_NOT_FOUND: "Chosen membership was not found. Please try again.",
  INSUFFICIENT_BALANCE: "Membership upgrade failed. Insufficient balance.",
  AMOUNT_NOT_VALID: "Membership upgrade failed. Invalid amount.",
  SUMMER_SUBSCRIPTION_ACTIVATED: "C'mon! Your Summer Account was successfully activated.",
  PREMIUM_SUBSCRIPTION_ACTIVATED: "C'mon! Your Premium Account was successfully activated.",
  PREMIUM_MEMBERSHIP_UPGRADE: "C'mon! Your Premium {{period}} Account was successfully activated.",
  ALREADY_REGISTERED_TO_COMPETITION: "You are already registered to the max. nr. of competitions of the same type, for this event.",
  MAX_ALLOWED_SAME_COMPETITION_ERROR: "You are already registered to the max. nr. of competitions of the same type, for this event.",
  EVENT_REGISTRATION_SUCCESSFULLY: "You have successfully registered to the competition.",
  EVENT_WITHDRAWAL_SUCCESSFULLY: "You have successfully withdrawn from the competition.",
  FEE_TAX_NOT_AVAILABLE: "Fees for this competition are not yet set. Please try again later.",
  NOT_REGISTERED_TO_COMPETITION: "You are not registered to this competition.",
  PAYMENT_NOT_FOUND: "Could not calculate fee tax for this competition. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  INVALID_FEE_TAX: "Could not calculate fee tax for this competition. We should work on this, so please help us out and let us know what happened at hq@sportya.net.",
  USER_INVALID_FEE_TAX: "Could not get competition fee tax for your user account. Please try again later.",
  PARTNER_USER_INVALID_FEE_TAX: "Could not get competition fee tax for your partner user account. Please try again later.",
  CURRENT_PASSWORD_NOT_MATCH: "Your Old Password is incorrect. Please try again.",
  USER_NOT_ALLOWED_CASE_LEVEL: "Registration cannot be processed. Your Game Level doesn't match with registration criteria of the competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_LEVEL: "Registration cannot be processed. Your team partner's Game Level doesn't match with registration criteria of the competition.",
  ACCESS_FORBIDDEN: 'Access forbidden.',
  ACCOUNT_BLOCKED: 'Your account has been blocked. Please contact us.',
  ERROR_TIMEFRAME: "Registrations are not opened yet for this competition.",
  USER_NOT_ALLOWED_CASE_GENDER: "Registration cannot be processed. Your Gender doesn't match with registration criteria of the competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_GENDER: "Registration cannot be processed. Your team partner's gender doesn't match with registration criteria of the competition.",
  WITHDRAW_NOT_ALLOWED: "Withdraw from this event is not allowed anymore. Please contact us at hq@sportya.net.",
  ERROR_BANNED_USER: "You cannot join this competition because you are currently banned. Please wait for your ban period to expire before joining a new competition.",
  ERROR_BANNED_PARTNER: "Player {{ partnerName }} - {{ partnerId }} is currently banned from event registrations. Please select another player as your teammate.",
  ERROR_USER_REGISTERED_SAME_WEEK: 'You are already registered in a competition that takes place at the same time as this event. Check the events you are already registered for in the My Events section, part of your account management.',
  USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Registration cannot be processed. Your Account Type (FREE) doesn't match with the registration criteria of the competition (PREMIUM accounts only).",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Registration cannot be processed. Your team partner's account type (FREE) doesn't match with the registration criteria of the competition (PREMIUM accounts only).",
  USER_NOT_ALLOWED_CASE_MAXAGE: "Registration cannot be processed. Your age doesn't match with registration criteria of the competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_MAXAGE: "Registration cannot be processed. Your team partner's age doesn't match with registration criteria of the competition.",
  USER_NOT_ALLOWED_CASE_MINAGE: "Registration cannot be processed. Your age doesn't match with registration criteria of the competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_MINAGE: "Registration cannot be processed. Your team partner's age doesn't match with registration criteria of the competition.",
  USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "You do not have the min. nr. of played (validated) events required to join this competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Your team partner does not have the min. nr. of played (validated) events required to join this competition.",
  USER_NOT_ALLOWED_CASE_RANK: "You do not meet the registration criteria. Your current ranking does not fall within the limits set for this competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_RANK: "Your team partner does not meet the registration criteria. His current ranking does not fall within the limits set for this competition.",
  TEAM_NOT_ALLOWED_AVG_LEVEL: "The registration cannot be processed. Your team level does not correspond to the level restriction applied to this competition.",
  TEAM_LEVEL_FORBIDDEN_TO_TEAMS: "The registration cannot be processed. Levels in your team do not correspond to the level restriction applied to this competition.",
  TEAM_MUST_BE_MIXT: "The registration cannot be processed. Your team must be mixt to match the registration criteria of the competition.",
  PARTNER_USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Registration cannot be processed. Players have to be at least 15 years old to join Sportya competitions.',
  USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Registration cannot be processed. Players have to be at least 15 years old to join Sportya competitions.',
  UNBLOCK_PLAYER_SUCCESS: 'The player was successfully unblocked.',
  BLOCK_PLAYER_SUCCESS: 'The player was successfully blocked.',
  SUCCESSFULLY_CREATED_REPORT: 'The player was successfully reported.',
  COULD_NOT_CREATE_FRIENDLY_MATCH: 'Your match cannot be created at the moment. Please try again later!',
  SUCCESSFULLY_UPDATED_FRIENDLY_MATCH: 'Your match was successfully updated.',
  COULD_NOT_UPDATE_FRIENDLY_MATCH: 'Your match cannot be updated at the moment. Please try again later!',
  SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH: 'You successfully accepted the match invitation.',
  COULD_NOT_ACCEPT_FRIENLDY_MATCH: 'The match invitation cannot be accepted at the moment. Please try again later!',
  SUCCESSFULLY_DECLINED_FRIENDLY_MATCH: 'You successfully declined the match invitation.',
  COULD_NOT_DECLINE_FRIENLDY_MATCH: 'The match invitation cannot be declined at the moment. Please try again later!',
  SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN: 'You successfully withdrew from the match.',
  COULD_NOT_WITHDRAW_FRIENLDY_MATCH: 'Your withdrawal from the match cannot be processed at the moment. Please try again later!',
  SUCCESSFULLY_CANCELED_FRIENDLY_MATCH: 'Your match was successfully canceled.',
  COULD_NOT_CANCEL_FRIENLDY_MATCH: 'Your match cannot be canceled at the moment. Please try again later!',
  SUCCESSFULLY_REFUSED_FRIENDLY_MATCH: 'You successfully reported the match as undisputed.',
  COULD_NOT_REFUSE_FRIENLDY_MATCH: 'The match cannot be reported as undisputed at the moment. Please try again later!',
  SUCCESSFULLY_SAVED_SCORE: 'Your score was successfully saved.',
  COULD_NOT_SAVE_SCORE: 'Your score cannot be saved at the moment. Please try again later!',
  passwordMustMatch: 'Passwords do not match. Please try again.',
  banned: `Your player account is banned for <0>{{time}}</0>. You will be unable to register for new competitions until <1>{{until}}.</1>
  If you need more details, you can contact the Sportya Team at <2>contact@sportya.net</2>`,
  missingLocation: 'Location for this event has not yet been established.',
  notAvailable: 'Not Available',
  weeks: '{{time}} weeks',
  singleWeek: '1 week',
  free: 'Free',
  rank: 'Rank',
  age: 'Age',
  minage: 'Min. Age',
  maxage: 'Max. Age',
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  isLoading: 'Is Loading...',
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  male: "Male",
  female: "Female",
  men: "Men",
  women: "Women",
  mixt: "Mixt",
  select: "Select",
  level: "Level",
  maintenance: 'Maintenance',
  inMaintenance: 'In maintenance',
  regBackSoon: 'Registrations will reopen soon',
  continue: "Continue",
  requiredField: "Required field",
  nrNotValid: 'Phone Number not valid',
  invalidEmail: "Invalid email",
  minChars: "Minimum {{ chars }} characters",
  maxChars: "Maximum {{ chars }} characters",
  mustContainerSpecialChars: "Include at least one special character (@,!,#, etc)",
  logout: "Log Out",
  login: "Log In",
  signup: "Sign Up",
  password: "Password",
  email: "Email",
  grass: 'Grass',
  clay: 'Clay',
  artificial: 'Artificial',
  hard: 'Hard',
  close: 'Close',
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  phone: 'Phone',
  imClubManager: "I'm a Club Manager",
  clubOwner: 'Club Owner',
  moreDetails: 'More Details',
  birthdate: 'Birthdate',
  days: 'Days',
  day: 'Day',
  from: 'From',
  to: 'To',
  back: 'Back',
  openHours: 'Open Hours',
  dismiss: 'Dismiss',
  metaDescription: 'Sportya is the place that we all long to be part of, a nation governed by our passion for sport. Energized by the spirit of competition. Undivided by age, gender, color or other beliefs. The land of Sportya has no geographical borders, no passports are needed and we are guided only by fair-play. We all speak the same language, and that’s the language of SPORT.',
  metaKeywords: 'sportya, tennis, tennis events, tennis competitions, tennis tournaments, tennis matches, tennis community, tennis leagues, local leagues, , tennis partner, tennis players, amateur players, tennis clubs, friendly matches, play tennis, find players, find events, tennis courts, best match, sports',
  ogTitle: 'Join me on Sportya and get rewarded!',
  ogDescription: 'Join me on Sportya to connect with other amateur tennis players and play friendly matches or compete in local and international tournaments! \n\r Sign up using my unique invitation link and get rewarded:\n\r {{ link }}',
  saveChanges: 'Save Changes',
  languages: {
    en: "English",
    bg: "Български",
    ro: "Română",
    hu: "Magyar",
    cz: 'Čeština',
  },
  gold: "Gold",
  silver: "Silver",
  platinum: "Platinum",
  champions: "Champions",
  winners: 'Winners',
  courtNr: 'Court {{courtNr}}',
  premium: "Premium",
  special: "Special",
  firstServe: '1st Serve',
  league: 'League',
  allAges: "All ages",
  type: 'Type',
  customAge: "Select custom age",
  custom: 'Custom',
  location: "Location",
  LocationGmaps: 'Location',
  category: "Category",
  month: "Month",
  year: "Year",
  premiumAccountMembershipUpgrade: 'SPORTYA Premium {{ period }} Membership Upgrade',
  virtualWalletTopUp: 'SPORTYA Virtual Wallet Top-Up {{ amount }} {{ currency }}',
  eventRegistration: 'SPORTYA Event Registration to {{ eventName }}',
  partnerEventRegistration: 'SPORTYA Event Registration Partner to {{ eventName }}',
  cookieConsent: 'This website uses cookies to provide you with the best experience. By using Sportya, you consent to the use of cookies in accordance with our <1>Privacy Policy<1>',
  cookieConsentButton: 'Allow Cookies',
  all: 'All',
  romania: 'Romania',
  bulgaria: 'Bulgaria',
  moldova: 'Republic of Moldova',
  bahrain: 'Bahrain',
  armenia: 'Armenia',
  hungary: 'Hungary',
  czechRepublic: 'Czech Republic',
  city: 'City',
  countriesByKey: {
    ro: 'Romania',
    md: 'Republic of Moldova',
    bg: 'Bulgaria',
    cz: 'Czechia',
    am: 'Armenia',
    hu: 'Hungary',
    bh: 'Bahrain',
  },
  country: 'Country',
  soonToBeAnnounced: 'Soon to be announced',

  migration: {
    1: `The migration of data from Tenis Partener to Sportya is under way. 
    During this time, part of your Player Profile will be unavailable. 
    Thank you for your patience.`,
  },

  players: "Players",
  teams: 'Teams',
  areYouSure: 'Are you sure?',
  setLevel: 'Set Level',

  allLocations: 'All Locations',
  allCategories: 'All Categories',
  allMonths: 'All Months',
  allYears: 'All Years',
  allActivity: 'All Activity',
  allLevels: 'All Levels',

  competitions: 'Competitions',
  activity: 'Activity',
  friendlyMatches: 'Friendly Matches',

  onlyfemale: 'Women-only',
  onlymale: 'Men-only',
  onlymixt: 'Only Mixt',

  right: 'Right',
  left: 'Left',
  searchPlayer: 'Search a player',
  'Romania-All': 'Romania (All)',
  'Bulgaria-All': 'Bulgaria (All)',
  'Moldova-All': 'Moldova (All)',
  'Hungary-All': 'Hungary (All)',
  'Bahrain-All': 'Bahrain (All)',
  'Armenia-All': 'Armenia (All)',
  'Czechia-All': 'Czechia (All)',
  officialBall: 'Official Ball',
  eventPrizes: 'Event Prizes',
  eventPrizesDescription: 'The standard prizes for a classic tournament (Silver, Gold, Platinum, Special Tournament), for each competition event, consist of a medal and a diploma. Any additional prizes will be updated in this section. \n\n In the case of Local Leagues, please send us a message at contact@sportya.net, in order to generate your digital diploma for the 1st place.',
  gameLevel: 'Game Level',
  uploadPlaceholder: 'Drop files here or click to upload.',
  download: "Download",
  viewPDF: "View the Main Draw in PDF",
  clearResults: 'Clear Search',
  onlyLatin: `Please use only letters from the Latin alphabet. 
  This restriction is necessary to allow other players to find you more easily in Sportya 
  (ex: when registering a doubles team for an event).`,
  noResultsFound: 'No results found',
  typeAtLeast3Chars: 'Type at least 3 characters...',
  allActivities: 'All Activities',
  eventSponsors: 'Event Sponsors',
  sponsors: 'Sponsors',
  coordinators: 'Coordinators',
  officialStore: 'Official Shop',
  officialSponsors: 'Official Sponsors',
  proximityRadius: 'Proximity',
  currentLocation: 'My Location',
  calendar: 'Calendar',
  radius: 'Radius',
  page_not_found: 'Page not found.',
  go_back: 'Go back',
  show: 'Show',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversation was successfully initiated.',
  COULD_NOT_INITIATE_CHAT: 'Conversation could not be initiated. Try again later.',
  cancelPopup: {
    title: 'Are you sure you want to leave this page?',
    stay: 'Stay on Page',
    quit: 'Leave Page',
  },
  COULD_NOT_RATE_PLAYER: 'Your rating cannot be saved at the moment. Please try again later!',
  SUCCESSFULLY_RATED_PLAYER: 'You successfully rated the player.',
  SUCCESSFULLY_DELETED_RATING: 'You successfully deleted your rating.',
  PaidParking: "Paid Parking",
  FreeParking: "Free Parking",
  EquipmentRental: "Equipment Rental",
  Store: "Store",
  Lockers: "Lockers",
  Showers: "Showers",
  Restaurant: "Restaurant",
  Cafeteria: "Cafeteria",
  Bar: "Bar",
  VendingMachine: "Vending Machine",
  SpecialAccess: "Special Access",
  KidsPlayground: "Kids Playground",
  referralCode: "Referral code (optional)",
  INVALID_REFERRAL_CODE: 'Invalid referral code',
  REFERRAL_CODE_APPLIED_SUCCESSFULLY: 'Referral code applied successfully!',
  date: "Date",
  time: "Time",
  no: "No",
  yes: "Yes",
  FAILED_TO_BOOK_COURT: "Failed to book this court! Try again!",
  BOOKING_COULD_NOT_BE_CREATED: "Failed to book this court! Try again!",
  COULD_NOT_CALCULATE_BOOKING_PRICE: "Failed to book this court! Try again!",
  COURT_NO_LONGER_AVAILABLE: "This court is not available anymore! Try another one.",
  START_DATE_OUTSIDE_MAX_BOOKING_INTERVAL: "Failed to book this court! Try again!",
  BOOKING_CONFIRMED_SUCCESSFULLY: "Congrats! The chosen court has been successfully booked!",
  COURT_IS_NOT_AVAILABLE: "This court is not available anymore! Try another one.",
  CLUB_BOOKING_DISABLED: "This court has just been disabled by the Club. Try booking a different one.",
  FAILED_TO_CONFIRM_BOOKING: "The booking could not be confirmed. Please try again or contact us.",
  ALREADY_WITHDRAWN: "You have already withdrawn from this competition",
  RESOURCE_NOT_FOUND: "Could not find route.",
  BOOKING_CANCELED_SUCCESSFULLY: "The booking has been successfully canceled.",
  FAILED_TO_CANCEL_BOOKING: "The booking could not be canceled.",
  BOOKING_ALREADY_CANCELED: "The booking has already been canceled.",
  INVALID_FRIENDLY_MATCH_SCORE_FORMAT: 'Invalid score based on selected match format!',
  INVOICE_UNAVAILABLE: 'Invoice is unavailable.',
  bookNow: "Book Now",
  searchAndBookCourts: "<0>BIG NEWS!</0> You can now SEARCH and BOOK a court in Sportya Partner Clubs",
  sports: {
    all: "All Sports",
    tennis: "Tennis",
    padel: "Padel",
  }
};
