const getStage = (match, t, raw) => {
  const hasGroup = match.group && t(
    'group',
    { type: String.fromCharCode(97 + (match.group - 1)).toUpperCase() },
  );
  return raw && match.stage === 0 && match.opponent.type === 'BYE' ? false : !hasGroup && match.stage
    ? (match.finalStage - match.stage > 2)
      ? `R - ${2 ** (match.finalStage - match.stage + 1)}`
      : t(`stages.${match.finalStage - match.stage + 1}`)
    : match.stage === 0 ? t('qualifying')
      : hasGroup;
};

export default getStage;
