import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PubSub from 'pubsub-js';

import { get } from '../../../components/common/http';

// Actions
export const fetchMyBookings = createAsyncThunk('accounts/fetchMyBookings', async ({ id, filters }) => {
  try {
    const filterParams = new URLSearchParams(Object.assign(filters)).toString();
    const { data: { data } } = await get(`/accounts/${id}/bookings?${filterParams}`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// Slice
const slice = createSlice({
  name: 'myBookings',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchMyBookings.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchMyBookings.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
    },
    [fetchMyBookings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default slice.reducer;
