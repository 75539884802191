export default {
  friendsSeoTitle: 'Barátok | Sportya',
  title: 'Barátok',
  tabs: {
    allFriends: 'Minden barát ({{friendsCount}})',
    friendRequests: 'Baráti kérések ({{friendsCount}})',
    sentRequests: 'Elküldött kérések ({{friendsCount}})',
  },
  unfriendText: 'Ismerős törlése {{name}}',
  unfriend: 'Ismerős törlése',
  unfriendConfirmationText: 'Biztosan eltávolítja {{name}} a barátja közül?',
  noRequestsSent: 'Nem érkezett új baráti felkérés',
  noRequestsReceived: 'Nem küldött új baráti kérést',
  wantToAddFirstFriend: 'Szeretné felvenni első barátját a Sportya?',
  confirm: 'Elfogad',
  decline: 'Elutasítás',
  cancelRequest: 'Kérés visszavonása',
};
