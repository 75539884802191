import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { blockPlayer, unblockPlayer } from '../../reducers';
import mixpanel from '../../../../../../../../../../mixpanel';
import { Button, P, H3 } from '../../../../../../../../../../components/Collection';
import { ButtonsContainer, BlockModal as Modal, ModalBackground } from '../styles';
import { UserContext } from '../../../../../..';
import { FriendContext } from '../../../../../../../../../Friends/components/FriendCard';
import { BlockedContext } from '../../../../../../../../../MyAccount/Settings/components/components/BlockedPlayerRow';

const BlockModal = ({ modalHandler }) => {
  let uContext = null;

  if (useContext(UserContext)) {
    uContext = useContext(UserContext);
  } else if (useContext(FriendContext)) {
    uContext = useContext(FriendContext);
  } else {
    uContext = useContext(BlockedContext);
  }

  const { user, isBlocked, socketClientId } = uContext;

  const { t } = useTranslation('settings');
  const dispatch = useDispatch();

  const { firstName, lastName, userId } = user || {};

  const unblock = () => {
    mixpanel.track('Confirm [Unblock] in confirmation dialog');
    dispatch(unblockPlayer({ blockedUserId: userId, socketClientId }));
    return modalHandler(true);
  };

  const block = () => {
    mixpanel.track('Confirm [Block] in confirmation dialog');
    dispatch(blockPlayer({ blockedUserId: userId, socketClientId }));
    return modalHandler(true);
  };

  const handleBlock = () => (isBlocked ? unblock() : block());
  const unblockName = `${firstName} ${lastName}`;

  return (
    <>
      <ModalBackground />
      <Modal>
        <H3>
          {t(`profileSettings.blockedPlayers.${isBlocked ? 'unblock' : 'block'}User`, { unblockName })}
        </H3>

        <P>
          <Trans
            ns="settings"
            i18nKey={`profileSettings.blockedPlayers.areYouSure${isBlocked ? 'Unblock' : 'Block'}`}
            values={{ unblockName: `${firstName} ${lastName}` }}
          >
            Are you sure you want to unblock
            {' '}
            <strong>{`${firstName} ${lastName}`}</strong>
          </Trans>
        </P>

        <P margin="20px 0 0 0">
          {t(`profileSettings.blockedPlayers.${isBlocked ? 'unblock' : 'block'}Info`)}
        </P>

        {!isBlocked && (
          <P>
            {t('profileSettings.blockedPlayers.blockNote')}
          </P>
        )}

        <ButtonsContainer>
          <Button padding="14px 70px" onClick={() => modalHandler()} outline>
            <Trans ns="events" i18nKey="cancel">Cancel</Trans>
          </Button>

          <Button padding="14px 70px" onClick={() => handleBlock()} color="#fff">
            {t(`profileSettings.blockedPlayers.${isBlocked ? 'unblock' : 'block'}`)}
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  );
};

export default BlockModal;
