import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LoadImage from '../../../../../components/common/LoadImage';
import { Contain } from '../../../../../components/Collection';
import ToggleVisible from '../../../../../components/common/ToggleVisible';

import {
  DueToP,
  Dropdown,
} from '../../styles';

import {
  ButtonsContainer,
  ButtonsFirstPart,
  ButtonsSecondPart,
} from '../../../styles';

import { ReasonText, OptionIcon, OptionIconPlaceholder } from '../../../StartMatch/components/WantToPlay/styles';
// eslint-disable-next-line max-len
import { DropdownItemContainer } from '../../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/styles';
import { ExpandedMatchesButton, PositionRelative } from '../../../components/Modals/MatchesDropdown/styles';

export const MatchButtons = ({
  isOnHold,
  buttonsToShow,
  isExpanded,
  setIsExpanded,
  timeUntilScoreValidation,
  modalHandler,
  match,
  initiateConversation,
}) => {
  const history = useHistory();
  const { t } = useTranslation('friendlyMatches');
  const {
    ref,
    isComponentVisible: isDropdownVisible,
    setIsComponentVisible: setIsDropdownVisible,
  } = ToggleVisible(false);
  const {
    edit, addScore, confirmScore, changeScore, noMatch, cancel, withdraw, chat, status,
  } = buttonsToShow || {};
  const hasDropdown = noMatch
  || (edit && (addScore || confirmScore)) || cancel
  || (withdraw && (edit || addScore || confirmScore));

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleEditMatch = () => {
    history.push(`/friendly-matches/edit${match.pastMatch ? '/played' : ''}`, { match });
  };

  return (
    <>
      <ButtonsContainer>
        <ButtonsFirstPart>
          {edit && (!(addScore || confirmScore || changeScore) || isOnHold)
            && (
              <ExpandedMatchesButton small onClick={() => handleEditMatch()} autoLeftMargin>
                <Trans ns="friendlyMatches" i18nKey="editMatch">Edit Match</Trans>
              </ExpandedMatchesButton>
            )}

          {addScore && !(edit && isOnHold)
            && (
              <ExpandedMatchesButton small disabled={isOnHold} onClick={() => modalHandler('addScore')} autoLeftMargin>
                <Trans ns="friendlyMatches" i18nKey="addScore">Add Score</Trans>
              </ExpandedMatchesButton>
            )}

          {confirmScore && !(edit && isOnHold) && (
            <PositionRelative>
              <ExpandedMatchesButton
                margin="0 0 20px 0"
                small
                disabled={isOnHold}
                onClick={() => modalHandler('confirmScore')}
                autoLeftMargin
              >
                <Trans ns="friendlyMatches" i18nKey="confirmScore">Confirm Score</Trans>
              </ExpandedMatchesButton>
              <DueToP small>{timeUntilScoreValidation}</DueToP>
            </PositionRelative>
          )}

          {changeScore && !(edit && isOnHold) && (
            <PositionRelative addMarginBottom>
              <ExpandedMatchesButton
                small
                disabled={isOnHold}
                onClick={() => modalHandler('changeScore')}
                autoLeftMargin
              >
                <Trans ns="friendlyMatches" i18nKey="changeScore">Change Score</Trans>
              </ExpandedMatchesButton>
              <DueToP small>{timeUntilScoreValidation}</DueToP>
            </PositionRelative>
          )}

          {withdraw && !(edit || addScore || confirmScore)
            && (
              <ExpandedMatchesButton
                whiteBoxShadow
                declineButton
                outline
                noBorder
                small
                onClick={() => modalHandler('withdraw')}
                autoLeftMargin
                disableDeclineButtonMinWidth
              >
                <Trans ns="friendlyMatches" i18nKey="withdraw">Withdraw</Trans>
              </ExpandedMatchesButton>
            )}
        </ButtonsFirstPart>
        <ButtonsSecondPart>
          {chat && (
            <OptionIcon
              hideTablet
              src={LoadImage('friendly-matches/matches/button-chat.svg')}
              alt="Open chat"
              onClick={initiateConversation}
              className="initiate-conversation"
            />
          )}
          {status
            && (
              <ReasonText>
                <OptionIcon
                  src={LoadImage('friendly-matches/matches/x-icon.svg')}
                  alt={capitalize(t(status))}
                  isReason
                  noBorder
                />
                {capitalize(t(status))}
              </ReasonText>
            )}

          {hasDropdown ? (
            <Contain position="relative">
              <OptionIcon
                dropdownIcon
                hideTablet
                src={LoadImage('friendly-matches/matches/button-more.svg')}
                alt="More Options"
                onClick={() => { handleDropdown(); }}
                ref={ref}
              />
              {isDropdownVisible && (
                <Dropdown ref={ref}>
                  {noMatch && (
                    <DropdownItemContainer onClick={() => modalHandler('noMatchFromMatchesTab')} red>
                      <Contain margin="0 10px 0 0">
                        <img src={LoadImage('close-red-icon.svg')} width="10px" height="10px" alt="no match" />
                      </Contain>
                      <Trans ns="friendlyMatches" i18nKey="noMatch">No Match</Trans>
                    </DropdownItemContainer>
                  )}

                  {withdraw && (edit || addScore || confirmScore) && (
                    <DropdownItemContainer onClick={() => modalHandler('withdraw')} red>
                      <Contain margin="0 10px 0 0">
                        <img src={LoadImage('close-red-icon.svg')} width="10px" height="10px" alt="withdraw" />
                      </Contain>
                      <Trans ns="friendlyMatches" i18nKey="withdraw">Withdraw</Trans>
                    </DropdownItemContainer>
                  )}

                  {edit && (addScore || confirmScore || changeScore) && (
                    <DropdownItemContainer onClick={() => handleEditMatch()}>
                      <Contain margin="0 10px 0 0">
                        <img src={LoadImage('pencil.svg')} width="14px" height="14px" alt="edit match" />
                      </Contain>
                      <Trans ns="friendlyMatches" i18nKey="editMatch">Edit Match</Trans>
                    </DropdownItemContainer>
                  )}

                  {cancel && (
                    <DropdownItemContainer onClick={() => modalHandler('cancel')} red>
                      <Contain margin="0 10px 0 0">
                        <img src={LoadImage('close-red-icon.svg')} width="10px" height="10px" alt="cancel match" />
                      </Contain>
                      <Trans ns="friendlyMatches" i18nKey="cancelMatch">Cancel Match</Trans>
                    </DropdownItemContainer>
                  )}

                </Dropdown>
              )}
            </Contain>
          ) : !status && <OptionIconPlaceholder />}
          <OptionIcon
            hideMobile
            onClick={() => setIsExpanded(!isExpanded)}
            src={LoadImage('friendly-matches/matches/button-dropdown.svg')}
            alt=""
          />
        </ButtonsSecondPart>
      </ButtonsContainer>
      <OptionIcon
        hideDesktop
        onClick={() => setIsExpanded(!isExpanded)}
        src={LoadImage('friendly-matches/matches/button-dropdown.svg')}
        alt=""
        className="mt10"
      />
    </>
  );
};
