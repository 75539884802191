import React from 'react';
import { Avatar } from './styles';
import InitialsComponent from '../Initials';
import LoadImage from '../../common/LoadImage';

const AvatarComponent = ({
  profilePicture,
  userId,
  firstName,
  lastName,
}) => (
  profilePicture ? (
    <Avatar
      src={LoadImage(`accounts/${userId}/${profilePicture}`, true)}
      alt={`${firstName.charAt(0)}${lastName.charAt(0)}`}
    />
  ) : (
    <InitialsComponent {...{ firstName, lastName }} />
  )
);

export default AvatarComponent;
