import styled from '@emotion/styled';
import { Link } from '../../../../components';

export const Container = styled.div(({ rewardsBar, receivedReward, firstBox }) => ({
  position: "relative",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: receivedReward ? "center" : "start",
  background: "#FFF9E1",
  margin: "20px 0",
  marginTop: firstBox ? 20 : 0,
  borderRadius: 10,

  ...rewardsBar && {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  '@media (max-width: 478px)': {
    ".coins": {
      display: "none",
    },
  },
}));

export const Content = styled.div({
  padding: 30,
  paddingTop: 20,

  ".actions": {
    listStyleType: "none",
    display: "grid",
    gridGap: 14,

    li: {
      display: "flex",
      fontSize: 16,

      a: {
        display: "flex",
        alignItems: "center",
      },

      img: {
        marginRight: 10,
      },
    },
  },

  '@media (max-width: 478px)': {
    padding: 15,
  },
});

export const CongratsBar = styled.div({
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  background: "#FFCD1A",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,

  a: {
    color: "#000000",
    textDecoration: "underline",
  },

  p: {
    margin: 0,
  },

  img: {
    padding: 8,
    cursor: "pointer",
  },

  '@media (max-width: 478px)': {
    padding: 15,
  },
});

export const TermsLink = styled(Link)({
  color: "#10242C",
  textDecoration: "underline",
});
