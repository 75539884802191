import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-scroll';

import P from '../../../components/Paragraph';
import BackButton from '../../../components/BackButton';

const ListMenu = styled.div`
  
  ${(props) => props.theme.functions?.minTablet(`
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    width: 100%;
    max-width: 300px;
    padding-right: ${props.theme.functions?.toEm(15)};
  `)}

  ul {
    list-style-type: none;
    margin-top: 30px;

    ${(props) => props.theme.functions?.minTablet(`
      margin-top: 0;
      overflow-y: scroll;
      max-height: 75vh;
    `)}
  }

  .listMenu__item {
    display: block;
    cursor: pointer;
    margin-bottom: ${(props) => props.theme.functions?.toEm(25)};

    &:hover {
      text-decoration: none;
    }
    
    p {
      color: ${({ theme }) => theme.colors?.primary};
    }

    &.active {
      p {
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  .wrapp_backButton {
    display: none;
    margin-bottom: 60px;    

    ${(props) => props.theme.functions?.minTablet(`
      display: block;
    `)}
  }
`;

const Menu = (props) => {
  const { menuItems } = props || {};
  return (
    <ListMenu>
      <div className="wrapp_backButton">
        <BackButton noAbsolute />
      </div>
      <ul>
        {menuItems.map((item, key) => (
          <li key={key}>
            <Link
              activeClass="active"
              to={item.id}
              spy
              smooth
              offset={-250}
              duration={500}
              className="listMenu__item"
            >
              <P smaller bold>{item.title}</P>
            </Link>
          </li>
        ))}
      </ul>
    </ListMenu>
  );
};

export default Menu;
