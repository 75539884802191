const validateScore = (e) => {
  const { value } = e.target;
  const isInteger = /^\d+$/.test(String(value));
  const hasDecimal = /\./.test(String(value));

  if (Number.isNaN(value) || !isInteger || hasDecimal) {
    Object.assign(e.target, { value: null });
  }

  if (value < 0 || value > 24) {
    return e.target.classList.add('error');
  }

  e.target.classList.remove('error');
};

export default validateScore;
