import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title, description, url, image, facebook, twitter,
}) => {
  const has = {
    title: !!title,
    description: !!description,
    url: !!url,
    image: !!image,
    facebook: {
      title: !!facebook?.title,
      type: !!facebook?.type,
      url: !!facebook?.url,
      image: !!facebook?.image,
      description: !!facebook?.description,
      site_name: !!facebook?.site_name,
      locale: !!facebook?.locale,
    },
    twitter: {
      card: !!twitter?.card,
      site: !!twitter?.site,
      title: !!twitter?.title,
      description: !!twitter?.description,
      image: !!twitter?.image,
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${window.location.origin}/`} />
        <link rel="alternate" href={`${window.location.origin}/`} hrefLang="x-default" />
        <link rel="alternate" href={`${window.location.origin}/ro`} hrefLang="ro" />
        <link rel="alternate" href={`${window.location.origin}/bg`} hrefLang="bg" />
        <link rel="alternate" href={`${window.location.origin}/hu`} hrefLang="hu" />
        <meta httpEquiv="Content-Language" content="en" />

        {has.title && (<meta name="title" content={title} />)}
        {has.description && (<meta name="description" content={description} />)}

        {has.facebook.title && (<meta property="og:title" content={facebook.title} />)}
        {has.facebook.type && (<meta property="og:type" content={facebook.type} />)}
        {has.facebook.url && (<meta property="og:url" content={facebook.url} />)}
        {has.facebook.image && (<meta property="og:image" content={process.env.REACT_APP_WEB_URL + facebook.image} />)}
        {has.facebook.image
        && (<meta property="og:image:secure_url" content={process.env.REACT_APP_WEB_URL + facebook.image} />)}
        {has.facebook.image && (<meta property="og:image:type" content="image/jpeg" />)}
        {has.facebook.description && (<meta property="og:description" content={facebook.description} />)}
        {has.facebook.site_name && (<meta property="og:site_name" content={facebook.site_name} />)}
        {has.facebook.locale && (<meta property="og:locale" content={facebook.locale} />)}

        {has.twitter.card && (<meta property="twitter:card" content={process.env.REACT_APP_WEB_URL + twitter.card} />)}
        {has.twitter.image
        && (<meta property="twitter:image" content={process.env.REACT_APP_WEB_URL + twitter.image} />)}
        {has.twitter.site && (<meta property="twitter:site" content={twitter.site} />)}
        {has.twitter.title && (<meta property="twitter:title" content={twitter.title} />)}
        {has.twitter.description && (<meta property="twitter:description" content={twitter.description} />)}
      </Helmet>

      <meta property="og:url" content="https://sportya.net" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="When Great Minds Don’t Think Alike" />
      <meta property="og:description" content="How much does culture influence creative thinking?" />
      <meta property="og:image" content={process.env.REACT_APP_WEB_URL + facebook.image} />
    </>
  );
};

export default SEO;
