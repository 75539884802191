import styled from '@emotion/styled';

export const ListModal = styled.div`
padding: 0px 20px;
overflow-y: auto;
margin-bottom: 40px;
  h3{
    line-height:36px; 
  }
h3,span{
  margin-left:20px;
}
  p{
    margin: 8px 0 0 0 ;
    span{
    font-weight: 700;
  }
  margin-bottom:20px;
  }
`;

export const ShowListStyle = styled.div`
  width: 100%;

  .mainList{
    background-color:${({ theme: { colors } }) => colors?.lightGreen};
  }

  .waitingList{
    background-color: ${({ theme: { colors } }) => colors?.orange};
  }

  .mainListFull{
    background-color: ${({ theme: { colors } }) => colors?.silver};
  }

  .closedList{
    background-color: ${({ theme: { colors } }) => colors?.secondaryRed};
  }

.showList {
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 15px 19.84px 15px 19.84px;
  border-radius: 4px;
  background: ${({ theme, bg }) => theme?.colors[bg]};
  color: ${({ theme, color }) => theme?.colors[color]};
  margin: unset !important;

   @media screen and (max-width: 768px){
    flex-direction:column;
    div{
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
        p{
          margin:0 0 5px 0 !important; 
        }
      }
   }
  

  div {
    display:flex;
    align-items:center;
    p:first-of-type{margin-left:9px;}
    p:last-child{margin-left:10px;}
  }
}
`;
