import styled from '@emotion/styled';

export const Content = styled.section`
  padding: 0;
  margin-bottom: 50px;
`;

export const ListArticles = styled.div`
  display: flex;
  grid-gap: 20px;

  ${props => props.theme.functions?.mediaQueryMax('768px', `
    display: grid;
    margin: 0;
  `)}
`;

export const Article = styled.article`
  display: flex;
  background: #FFFFFF;
  border: 1px solid ${ ({ theme }) => theme.colors?.lightBlue };
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(16, 36, 44, 0.1);
  border-radius: 10px;
  padding: 30px;
  justify-content: space-between;
  flex-direction: column;
  // align-items: center;
  //margin: 0 15px;
  width: 100%;
  //max-width: 285px;

  ${props => props.theme.functions?.mediaQueryMax('768px', `
    margin: 0 auto;
    max-width: none;
    min-height: 250px;
  `)}

    > div:last-of-type
    {
      margin-top: auto;
    }
  
  .description {
    width: 100%;
    margin-bottom: 20px;

    ul {
      list-style: none;
      padding-left: 0;
      margin: 10px 0;
  
      li {
        font-size: ${ ({ theme }) => theme?.functions?.toEm(12) };
        display: inline-block;
        margin-right: 15px;
        
        &:after {
          content: "•";
          position: absolute;
          padding: 0 5px;
        }
        
        &:last-child:after {
          display: none;
        }
      }
      
      &.event-details li {
        font-size: ${ ({ theme }) => theme?.functions?.toEm(16) };
      }
    }
  }
  
  .go-to-event {
    a {
      padding: 20px;
    }
  }
`;
