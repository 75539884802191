import styled from '@emotion/styled';

export const Container = styled.div(() => ({
  maxWidth: '100%',
  margin: '0 auto',
  padding: '0 30px',

  '@media (max-width: 768px)': {
    padding: '0 15px',
  },

  ".heading-title": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 90px)",
  },
}));

export const CourtsContainer = styled.div(() => ({
  maxWidth: "100%",
  height: "auto",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, calc(20% - 25px))",
  gap: 30,
  marginBottom: 20,

  '@media (max-width: 1500px)': {
    gridTemplateColumns: "repeat(auto-fit, calc(25% - 25px))",
  },

  '@media (max-width: 1199px)': {
    gridTemplateColumns: "repeat(auto-fit, calc(33% - 25px))",
    marginBottom: 30,
  },

  '@media (max-width: 767px)': {
    gridTemplateColumns: "repeat(auto-fit, calc(50% - 15px))",
  },

  '@media (max-width: 550px)': {
    gridTemplateColumns: "repeat(auto-fit, calc(100%))",
  },
}));
