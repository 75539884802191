import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import mixpanel from '../../../mixpanel';

import { store as socketStore } from '../../../components/Socket/store';

import LoadImage from '../../../components/common/LoadImage';
import { P } from '../../../components/Collection';
import ToggleVisible from '../../../components/common/ToggleVisible';
import Match from './components/Match';
import Header from '../components/Header';
import MatchMovedPlaceholder from '../components/MatchMovedPlaceholder';
import AcceptDeclineCancelModal from '../components/Modals/AcceptDeclineMatch';

import {
  fetchFriendlyMatches,
  fetchInactiveMatches,
  changeSummaryTotals,
  replaceMatchInList,
  addMatchToList,
  removePlaceholder,
} from '../reducers';

import {
  PageContainer, TabContent, ViewMore, ViewMoreArrow,
} from '../styles';
import { NoDataContainer } from '../StartMatch/components/WantToPlay/styles';
import { Button, H3 } from '../../../components';
import LinkWrapper from '../../../components/Link';
import AddScoreModal from '../components/Modals/AddScore';
import { addConversationToList } from '../../Chat/reducers';
import { formatMatchId, getLocalDate } from '../helpers';
import SetRatingModal from '../../../components/PlayerRating/components/SetRatingModal';
import handleNextPlayerRating from '../../../components/PlayerRating/helpers/handleNextPlayerRating';

const Matches = ({ name }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const { t: chatT } = useTranslation('conversations');
  const globalState = useContext(socketStore);
  const {
    data: matches,
    status,
    sportType,
  } = useSelector(state => state?.friendlyMatches?.friendlyMatches?.matches);
  const [page, setPage] = useState(1);
  const [modalType, setModalType] = useState(null);
  const { isComponentVisible: isModalVisible, setIsComponentVisible: setIsModalVisible } = ToggleVisible(false);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const { accountInfo, masterSport } = useSelector(state => state?.session);

  const [ratePlayerData, setRatePlayerData] = useState({
    playerToRate: null,
    userPositionIndex: 0,
    thankYouModal: false,
    userId: accountInfo?.userId,
    matchInfo: null,
  });

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalType(null);
  };

  const isScoreModal = modalType?.type === 'addScore'
  || modalType?.type === 'changeScore'
  || modalType?.type === 'confirmScore'
  || modalType?.type === 'confirmMatch';

  useEffect(() => {
    if (status === 'idle' || (sportType && sportType !== masterSport && status === 'succeeded')) {
      dispatch(fetchFriendlyMatches({ tab: 'matches' }));
    }
  }, [dispatch, status, sportType, masterSport]);

  useEffect(() => {
    if (page > 1) {
      dispatch(fetchInactiveMatches({ page }));
    }
  }, [page]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success) {
        const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const matchStartDate = data?.match?.ended
          ? new Date() : getLocalDate(data?.match?.startDate, data?.match?.timezone);
        switch (message) {
        case 'SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN':
          mixpanel.track('Friendly matches WITHDRAW', {
            sp_match_type: data?.match.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: data?.match.gameType,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: data?.match.location?.city,
            sp_game_location_country: data?.match.location?.country,
            sp_game_format: data?.match.format,
            sp_match_id: formatMatchId(data?.match.matchId),
          });
          dispatch(replaceMatchInList({ match: data?.match, list: 'matches' }));
          break;
        case 'SUCCESSFULLY_REFUSED_FRIENDLY_MATCH':
          mixpanel.track('Friendly matches NO MATCH', {
            sp_asset_type: 'MATCH INVITE',
            sp_match_type: data?.match.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: data?.match.gameType,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: data?.match.location?.city,
            sp_game_location_country: data?.match.location?.country,
            sp_game_format: data?.match.format,
            sp_match_id: formatMatchId(data?.match.matchId),
          });
          dispatch(replaceMatchInList({ match: data?.match, list: 'matches' }));
          break;
        case 'SUCCESSFULLY_CANCELED_FRIENDLY_MATCH':
          mixpanel.track('Friendly matches CANCEL MATCH', {
            sp_asset_type: data?.match.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
            sp_match_type: data?.match.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: data?.match.gameType,
            sp_owner_level_single: data?.match.player.gameLevel?.singles,
            sp_owner_level_double: data?.match.player.gameLevel?.doubles,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: data?.match.location?.city,
            sp_game_location_country: data?.match.location?.country,
            sp_game_format: data?.match.format,
            sp_match_id: formatMatchId(data?.match.matchId),
          });
          dispatch(replaceMatchInList({ match: data?.match, list: 'matches' }));
          break;
        case 'SUCCESSFULLY_SAVED_SCORE':
          // rating user
          handleNextPlayerRating({
            ratePlayerData: {
              ...ratePlayerData,
              matchInfo: data?.match,
            },
            setRatePlayerData,
          });

          if (data?.match?.ended) {
            dispatch(replaceMatchInList({
              match: {
                matchId: data?.match?.matchId,
                isPlaceholder: true,
                placeholderTranslation: 'matchMovedToScores',
                placeholderDestination: 'scores',
              },
              list: 'matches',
            }));
            dispatch(changeSummaryTotals({ matches: -1, requests: 0 }));
            dispatch(addMatchToList({ match: data?.match, list: 'scores' }));
          } else {
            dispatch(replaceMatchInList({ match: data?.match, list: 'matches' }));
          }
          break;
        default:
        }

        setIsModalVisible(false);
        addToast(commonT(message), {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'friendly-matches-response',
      );

      socket.on(
        'friendly-matches-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'friendly-matches-response',
        );
      };
    }
  }, [socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (message === 'SUCCESSFULLY_INITIATED_CHAT') {
        addToast(chatT(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
          maxOpened: 1,
        });

        if (success) {
          await dispatch(addConversationToList(data));
          return history.push(`/conversations/${data.id}`);
        }
      }
    };

    if (socket) {
      socket.removeAllListeners('chat-response');
      socket.on('chat-response', listenForResponse);

      return () => socket.removeAllListeners('chat-response');
    }
  }, [socket]);

  useEffect(() => {
    mixpanel.track('Visited Matches tab');
    dispatch(removePlaceholder({ list: 'matches' }));
  }, []);

  const loadInactiveRequests = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <PageContainer>
      <Header page={name} />
      {matches?.results && matches?.results.length > 0 && (
        <TabContent>
          {matches?.results.map((match, key) => (
            match.isPlaceholder
              ? <MatchMovedPlaceholder match={match} list="matches" />
              : (
                <Match
                  key={key}
                  match={match}
                  toggleModal={{ isModalVisible, setIsModalVisible }}
                  setModalType={setModalType}
                  modalType={modalType}
                />
              )
          ))}
        </TabContent>
      )}

      {matches?.results && !matches?.results.length && status === 'succeeded' && (
        <NoDataContainer className="text-center">
          <img src={LoadImage('friendly-matches/black-palette.svg')} alt="" width={60} height={60} />
          <H3><Trans ns="friendlyMatches" i18nKey="noActiveMatches">No Active Matches</Trans></H3>
          <P className="mb30">
            <Trans ns="friendlyMatches" i18nKey="noMatchesSubtitle">
              You have no match scheduled at the moment. Invite your friends or other players and start a new match now!
            </Trans>
          </P>
          <Button wide>
            <LinkWrapper
              to="/friendly-matches"
              onClick={() => mixpanel.track('Click START MATCH placeholder from Matches')}
            >
              <Trans ns="friendlyMatches" i18nKey="startMatch">Start Match</Trans>
            </LinkWrapper>
          </Button>
        </NoDataContainer>
      )}

      {matches?.loadMore && (
        <ViewMore onClick={loadInactiveRequests}>
          <P bold>
            <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
          </P>
          <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View Inactive Requests" />
        </ViewMore>
      )}

      {isModalVisible && !isScoreModal && (
        <AcceptDeclineCancelModal modalData={modalType} />
      )}

      {isModalVisible && isScoreModal && (
        <AddScoreModal
          modalData={modalType}
          closeModalHandler={() => closeModalHandler()}
        />
      )}

      {ratePlayerData?.playerToRate && (
        <SetRatingModal
          skippable
          ratingInfo={ratePlayerData?.playerToRate?.ratingInfo}
          user={ratePlayerData?.playerToRate}
          matchId={ratePlayerData?.matchInfo?.friendlyMatchId ?? ratePlayerData?.matchInfo?._id}
          gameType={ratePlayerData?.matchInfo?.gameType}
          userPositionIndex={ratePlayerData?.userPositionIndex}
          onRequestClose={() => setRatePlayerData({
            ...ratePlayerData,
            playerToRate: null,
          })}
          onSubmit={(v) => handleNextPlayerRating({
            ratePlayerData,
            setRatePlayerData,
            skipThankYouModal: !!v?.skipThankYouModal,
          })}
          thankYouModal={ratePlayerData?.thankYouModal}
          mixpanelData={{
            source: 'Post-Match',
            matchType: ratePlayerData?.matchInfo?.ranked ? 'Ranked' : 'Unranked',
            assetType: ratePlayerData?.matchInfo?.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
          }}
        />
      )}
    </PageContainer>
  );
};

export default Matches;
