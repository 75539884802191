import styled from '@emotion/styled';

export const PlayerScoreContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 0 0 0',

  '@media (max-width: 400px)': {
    marginTop: '10px',
  },
});

export const PlayerScoreHeader = styled.div({
  display: 'flex',
  margin: '0 0 62px 0',
  justifyContent: 'center',
});

export const SetTitlesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '15px 0 0 0',

  '@media (max-width: 520px)': {
    display: 'none',
  },
});

export const DoublesPlayerName = styled.div({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: '-95px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'max-content',
});
