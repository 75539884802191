import styled from '@emotion/styled';
import { Link } from '../../../../components/Collection';

export const Player = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  height: '239px',
  width: '286px',
  padding: '30px 0',
  a: {
    textDecoration: 'none !important',
  },
  p: {
    margin: 'unset !important',
  },
  '.ellipsis': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '250px',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
});

export const ID = styled.span(props => ({
  color: props.theme.colors.darkTurquoise,
  fontWeight: 400,
  margin: '0 0 0 5px',
}));

export const JoinedDate = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
  borderRadius: '80px',
  height: '36px',
}));

export const JoinedDateText = styled.p(() => ({
  color: '#7CC9C6',
}));

export const ContainAvatar = styled(Link)({
  position: 'relative',
  margin: '10px 0 15px',
  '.badge': {
    position: 'absolute',
    left: '50%',
    bottom: '-8px',
    transform: 'translateX(-50%)',
  },
});

export const Avatar = styled.img((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  borderRadius: '50%',
  height: props.small ? '38px' : '80px',
  width: props.small ? '38px' : '80px',
}));

export const Initials = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: props.small ? '38px' : '80px',
  width: props.small ? '38px' : '80px',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
}));
