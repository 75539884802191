/* eslint-disable max-len */
import styled from '@emotion/styled';

export const PerkStyle = styled.div`
margin-bottom:20px;
display:flex;
align-items: center;
margin: 0 0 20px auto;
img{
    width:13px;
    height:11px;
    margin-right:16px;
}
.perkText{
    margin:0 !important;
    text-align:left;
    line-height:21px;
    max-width:223px;
    width: 100%;
    color:${props => ({ theme }) => (props.type === 'red' ? `${theme?.colors?.darkRed}` : (props.planType === 'premium' ? `${theme?.colors?.white}` : `${theme?.colors?.primary}`))};
    }
`;
