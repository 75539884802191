/** @jsxImportSource @emotion/react */
import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, convertFromRaw } from 'draft-js';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Link from '../../../../components/Link';
import Button from '../../../../components/Button';
import H4 from '../../../../components/H4';
import P from '../../../../components/Paragraph';

const ContentWrapper = styled.section`
  p {
    white-space: pre-line;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 7;
   -webkit-box-orient: vertical;
  }
`;

const EventDescription = ({ content, ...props }) => {
  const { t } = useTranslation('events');
  let editorContent;
  if (isEmpty(content)) return false;

  if (!props.staticContent) {
    const initState = convertFromRaw(JSON.parse(content));
    editorContent = EditorState.createWithContent(initState);
  }

  return (
    <ContentWrapper {...props}>
      {props.staticContent ? (
        <>
          <H4 className="mb20">{content.title}</H4>
          <P medium className="mb20">{content.description}</P>
        </>
      ) : (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: stateToHTML(editorContent.getCurrentContent()) }} />
      )}

      {props.readMore && !props.link ? (
        <Button small outline onClick={props.readMore}>
          <Link to="/" onClick={(event) => event.preventDefault()}>
            {t('readMore')}
          </Link>
        </Button>
      ) : props.readMore && props.link && (
        <Button small outline onClick={props.readMore}>
          <Link to={props.link}>
            {t('readMore')}
          </Link>
        </Button>
      ) }
    </ContentWrapper>
  );
};

export default EventDescription;
