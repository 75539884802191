export default {
  title: 'Моите събития',
  seoTitle: 'Моите събития | Sportya',
  joined: 'Регистрации',
  noJoined: 'Все още не участвате в никакви събития.',
  followed: 'Последвани',
  noFollowed: 'Все още не следите никакви събития.',
  closed: 'Затворени',
  noClosed: 'Все още не сте финализирали нито едно състезание (с потвърдени резултати).',
  withdrawals: 'Оттегляния',
  noWithdrawals: 'Все още нямате отказвания.',
  findEvent: 'Намери Събитие',
  seeEvent: 'Посети Събитието',
  seeCompetition: 'Посети Турнира',
  registeredOnMainBoard: 'Записан в Основната схема',
  registeredInQueueList: 'Записан в Списъка с изчакващи',
  lookingForPartners: 'Търся партньор',
};
