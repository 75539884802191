import styled from '@emotion/styled';
import LoadImage from '../../../../components/common/LoadImage';

export const Container = styled.div(({ isDashboard }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '118px',
  background: `url(${LoadImage('invite-friends/bg-banner.svg')})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',

  ...isDashboard && {
    borderRadius: '10px',
    margin: '0 0 20px 0',
  },

  '@media (max-width: 940px)': {
    minHeight: '200px',
    height: 'unset',
    padding: '20px',
  },
}));

export const URLContainer = styled.div({
  display: 'flex',
  '@media (max-width: 940px)': {
    flexDirection: 'column',
  },
});

export const SocialMediaContainer = styled.div({
  display: 'flex',
  '@media (max-width: 940px)': {
    margin: '10px auto 0 auto',
  },
});

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  maxWidth: '750px',
  width: '100%',
  height: '100%',
});

export const Icon = styled.img({
  margin: '0 10px 0 0',
});

export const InviteURL = styled.input(({ playerProfile }) => ({
  maxWidth: '475px',
  width: '100%',
  height: '50px',
  cursor: 'text',

  borderRadius: '4px 0 0 4px',
  border: 'unset',
  borderTop: '1px solid #C1E6E5',
  borderBottom: '1px solid #C1E6E5',
  borderLeft: '1px solid #C1E6E5',
  padding: '0 0 0 17px',

  background: '#fff',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#10242C',
  ...playerProfile && {
    '@media (max-width: 940px)': {
      border: '1px solid #C1E6E5',
      borderRadius: '4px',
      maxWidth: 'unset',
      width: '100%',
    },
  },

  '@media (max-width: 480px)': {
    border: '1px solid #C1E6E5',
    borderRadius: '4px',
  },
}));

export const CopyButton = styled.div(({ playerProfile }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  borderRadius: '0 4px 4px 0',
  padding: '15px 18px',
  background: '#F42B03',
  cursor: 'pointer',
  transition: '.2s all ease-out',
  userSelect: 'none',
  margin: '0 20px 0 0',

  borderTop: '1px solid #C1E6E5',
  borderBottom: '1px solid #C1E6E5',
  borderRight: '1px solid #C1E6E5',

  '&:hover': {
    background: '#F42B03DB',
  },

  '&:active': {
    background: '#10242C',
  },

  ...playerProfile && {
    '@media (max-width: 940px)': {
      justifyContent: 'center',
      border: 'unset',
      borderRadius: '4px',
      margin: '10px 0 0 0',
      width: '100%',
    },
  },

  '@media (max-width: 480px)': {
    justifyContent: 'center',
    border: 'unset',
    borderRadius: '4px',
    width: '100%',
    margin: '10px 0 0 0',
  },
}));

export const SocialMedia = styled.a({
  height: '44px',
  transition: '.2s all ease-out',
  margin: '2px 5px 0 0',
  cursor: 'pointer',
  '&:hover': {
    marginTop: '0',
  },
});
