import styled from '@emotion/styled';
import { Button } from '../../../../../components/Collection';

export const ExitIcon = styled.img({
  position: 'absolute',
  width: '40px',
  height: '40px',
  right: '0px',
  top: '14px',
  cursor: 'pointer',
  objectFit: 'none',
  '&:hover': {
    border: '2px solid #7cc9c666',
    borderRadius: '5px',
  },

  '@media (max-width: 991px)': {
    display: 'none',
    top: -15,
    right: -15,
  },
});

export const ExpandedMatchesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  maxWidth: '1234px',
  background: '#fff',

  '.location': {
    whiteSpace: 'normal',
    width: '100%',
  },
});

export const ExpandedMatchesContent = styled.div(() => ({
  display: 'flex',
  paddingBottom: 20,

  '.event-data': {
    margin: '20px 0 0 58px',
  },

  '.player-data': {
    justifyContent: "space-between",
  },

  '@media (max-width: 991px)': {
    flexDirection: 'column',
  },

  '@media (max-width: 767px)': {
    '.event-data': {
      marginLeft: 0,
    },
  },

  '@media (max-width: 479px)': {
    '.player-data': {
      flexDirection: 'column',
      alignItems: 'baseline',
      marginBottom: 20,

      '> div': {
        width: 'auto',
      },
    },
  },
}));

export const ExpandedMatchesFooter = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '20px 0 0 0',
  borderTop: '1px solid #EAF6F6',

  '@media (max-width: 991px)': {
    flexDirection: 'column',
  },
});

export const ExpandedMatchesButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 0 auto',
  gap: 10,

  '.position-relative': {
    width: 'inherit',
  },

  '@media (max-width: 991px)': {
    margin: 'auto',
    gap: 10,
    flexDirection: 'column',
    width: '100%',

    '.position-relative': {
      // marginBottom: 15,
    },

    button: {
      width: '100%',
    },
  },
});

export const PositionRelative = styled.div(({ addMarginBottom }) => ({
  ...addMarginBottom && {
    // margin: '0 0 20px 0',
  },
  position: 'relative',
  width: '100%',

  '@media (max-width: 1200px)': {
    margin: 0,
  },
}));

export const ExpandedMatchesButton = styled(Button)(({
  autoLeftMargin, declineButton, theme: { colors }, fullRedBg, disableDeclineButtonMinWidth,
}) => ({
  minWidth: '135px',
  height: '40px',
  padding: '0 15px',

  ...autoLeftMargin && {
    margin: '0 0 0 auto',
  },

  ...fullRedBg && {
    background: colors.error,
    border: 'unset',
    boxShadow: 'unset',
  },

  ...autoLeftMargin && {
    '@media (max-width: 1200px)': {
      margin: 'unset',
    },
  },

  ...declineButton && {
    ...!disableDeclineButtonMinWidth && {
      minWidth: '100px',
    },
    color: colors.error,
    transition: 'all 0.3s ease-out',
    border: `2px solid ${colors.error}`,
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${colors.error}`,
    },
  },

  '@media (max-width: 991px)': {
    width: '100%',
  },
}));

export const ExpandedMatchesTeams = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const ExpandedMatchesTeam = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '&:first-of-type': {
    margin: '20px 0 0 0',
  },
  '&:last-of-type': {
    margin: '40px 0 0 0',
  },
});

export const Bold = styled.span({
  fontWeight: 700,
});
