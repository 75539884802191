export const isHandRaised = (expiry) => {
  if (!expiry) {
    return false;
  }

  const dateNow = new Date();
  const expiryDate = new Date(expiry);

  return dateNow.getTime() <= expiryDate.getTime();
};

export const calculateDaysLeft = (expiry, requestedAt) => {
  const expiryDate = new Date(expiry);
  const dateNow = new Date();
  const requestedAtDate = new Date(requestedAt);

  const difference = expiryDate.getTime() - dateNow.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  if (requestedAtDate.toISOString().split('T')[0] === dateNow.toISOString().split('T')[0]) {
    return totalDays - 1;
  }

  return totalDays;
};

export const getDetailsText = (matchType, opponentLevel, t, eventsT) => {
  let finalText = '';

  if (matchType === 'both') {
    finalText += t('raiseHand.singlesAndDoubles');
  } else if (matchType === 'singles') {
    finalText += eventsT('singles');
  } else if (matchType === 'doubles') {
    finalText += eventsT('doubles');
  }

  if (opponentLevel === 'sameLevel') {
    finalText += ` (${t('raiseHand.sameLevelDescription')})`;
  } else if (opponentLevel === 'aboveLevel') {
    finalText += ` (${t('raiseHand.aboveLevelDescription')})`;
  }

  return finalText;
};
