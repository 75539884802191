import styled from '@emotion/styled';
import { Link } from '../../../../components/Collection';
import Container from '../../../../components/Container';
import LoadImage from '../../../../components/common/LoadImage';

export const SeeAll = styled(Link)({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  margin: '0 0 0 auto',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const ExtendedContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  marginBottom: 30,
  marginTop: 30,

  h3: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,

    '@media only screen and (max-width: 768px)': {
      flexDirection: 'column',
      gap: 10,

      a: {
        margin: 'auto',
      },
    },
  },

  'a:hover': {
    textDecoration: 'none',
  },

  '.friendly-matches': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 30,

    '&__box': {
      background: 'white',
      width: '100%',
      padding: 20,
      border: `1px solid ${theme?.colors?.lightBlue}`,
      boxSizing: 'border-box',
      boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
      borderRadius: 10,
    },

    '.total': {
      borderBottom: `1px solid ${theme?.colors?.lightBlue}`,
      paddingBottom: 20,
      marginBottom: 20,
    },

    '.match-info': {
      display: 'flex',
      alignItems: 'center',

      img: {
        marginRight: 10,
      },

      '.location': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 320,

        '@media only screen and (max-width: 1366px)': {
          maxWidth: 300,
        },

        '@media only screen and (max-width: 1200px)': {
          maxWidth: 'calc(50vw - 140px)',
        },

        '@media only screen and (max-width: 768px)': {
          maxWidth: 'calc(100vw - 140px)',
        },

        '&__fullwidth': {
          maxWidth: 'initial',
          whiteSpace: 'break-spaces',
        },
      },

      '.match-type': {
        display: 'flex',
        alignItems: 'center',
      },

      '.ranked': {
        paddingTop: 4,
        marginLeft: 10,
      },

      '.your-match': {
        backgroundColor: theme?.colors?.secondary,
        padding: '0px 4px',
        borderRadius: 2,
        fontSize: 12,
        marginLeft: 8,
        color: 'white',
        lineHeight: '16px',
      },
    },

    '@media only screen and (max-width: 768px)': {
      flexDirection: 'column',
    },
  },

  '@media only screen and (max-width: 768px)': {
    marginTop: 15,
    marginBottom: 15,
  },

  '.no-friendly-matches': {
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    borderRadius: 10,
    background: `url('${LoadImage('friendly-matches/no-friendly-matches.png')}') no-repeat 90% center/cover`,
    padding: '40px 45px',

    ".palette": {
      marginRight: 60,
    },

    h4: {
      color: '#5FFDF7',
      marginBottom: 30,
    },

    ".add-played-match": {
      marginLeft: 35,
      color: 'white',
      fontSize: 16,
      fontWeight: 700,
      display: "inline-flex",
    },

    '@media only screen and (max-width: 768px)': {
      padding: '20px 30px',

      ".buttons": {
        textAlign: "center",
      },

      ".add-played-match": {
        display: "flex",
        justifyContent: "center",
        marginLeft: 0,
        marginTop: 20,
      },
    },
  },
}));
