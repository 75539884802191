export default {
  seoTitle: 'Setările Mele | Sportya',
  cancelRequest: 'Anulează Cererea',
  addFriend: 'Adaugă Prieten',
  confirmRequest: 'Confirmă Cererea',
  friends: 'Prieteni',
  unfriend: 'Șterge Prieten',

  profileSettings: {
    title: 'Setările Mele',

    playerDetails: {
      title: 'Detalii Cont',
      passionatePlayer: 'Joci cu Pasiune',
      accountType: 'Tipul Contului',
      upgradeToPremium: 'Achiziționează Contul Premium',
      subscriptionValidity: 'Valabilitate Abonament',
      subscriptionState: 'Fără dată de expirare',
      since: 'Din {{createdAt}}',
      free: 'Gratuit',
      summer: 'Cont de Vară',
      premium: 'Cont Premium {{period}}',
      accountMembership: {
        free: 'Gratuit',
        summer: 'Cont de Vară',
        premium: 'Cont Premium',
      },
    },

    playerProfile: {
      accountType: {
        free: 'Cont Gratuit',
        summer: 'Cont de Vară',
        premium: 'Cont Premium',
      },
    },

    changePassword: {
      title: 'Schimbă Parola',
      oldPassword: 'Parola Veche',
      newPassword: 'Noua Parolă',
      confirmNewPassword: 'Confirmă Noua Parolă',
      passwordRequirement: 'Noua parolă trebuie să aibă între 8-30 caractere alfanumerice și să includă cel puțin un caracter special.',
      pleaseEnterNewPassword: 'Introdu noua parolă',
      passwordsDoNotMatch: 'Parolele nu se potrivesc.',
      savePassword: 'Salvează Parola',
    },

    personalInformation: {
      title: 'Informații Personale',
      firstName: 'Prenume',
      lastName: 'Nume',
      year: 'An',
      month: 'Lună',
      day: 'Zi',
      location: 'Reședință',
      phoneNumber: 'Număr Telefon',
      invalidPhoneNumber: 'Număr telefon invalid ',
      clubTitle: 'Ocupație',
    },

    profileDetails: {
      title: 'Detalii Profil & Sporturi',
      generalDetails: 'Detalii Generale',
      gameLevels: 'Niveluri de Joc',
      singleGameLevel: ' Nivel de Joc Simplu',
      doublesGameLevel: 'Nivel de Joc Dublu',
      noGameLevel: 'Nu ți-ai setat încă nivelul de joc.',
      buttonSetLevel: 'Setează-ți Nivelul de Joc',
      needOfChange: 'Dacă vrei să schimbi nivelul te rugăm să ne contactezi pe adresa <1>contact@sportya.net</1>.',
      dominantHand: 'Mâna Dominantă',
      left: 'Stânga',
      right: 'Dreapta',
      shirtSize: 'Mărime Tricou',
      shirtNone: 'Niciuna',
    },

    languageCommunication: {
      title: 'Limbă & Comunicare',
      language: 'Limbă',
      ro: 'Română',
      en: 'Engleză',
      email: 'Email',
      newsletter: 'Newsletter',
      emailChatConversations: 'Notificări pe email pentru conversațiile prin Chat',
      emailFriendRequests: 'Notificări pe email pentru solicitările de Prietenie',
      emailFriendlyMatches: 'Notificări pe email pentru Meciuri Amicale',
      subscribe: 'Abonat la newsletter-ul Sportya (oportunități de joc din regiunea mea, detalii cu privire la funcționalități noi și altele)',
    },

    availabilitySchedule: {
      title: 'Program Disponibilitate',
      Mon: 'Lu',
      Tue: 'Ma',
      Wed: 'Mi',
      Thu: 'Jo',
      Fri: 'Vi',
      Sat: 'Sâ',
      Sun: 'Du',
    },

    blockedPlayers: {
      title: 'Jucători Blocați',
      blockImplications: 'Odată ce blochezi pe cineva, acel jucător nu va mai putea să te invite la meciuri partener, să inițieze o conversație cu tine, sau să îți trimită o cerere de prietenie.',
      howToBlock: 'Poți bloca un jucător folosind opțiunea Blochează Jucător din profilul său de jucător.',
      unblock: 'Deblochează',
      block: 'Blochează',
      unblockUser: 'Deblochează jucătorul {{unblockName}}',
      blockUser: 'Blochează jucătorul {{unblockName}}',
      areYouSureUnblock: 'Sigur vrei să deblochezi jucătorul <1>{{unblockName}}</1> ?',
      areYouSureBlock: 'Sigur vrei să blochezi jucătorul <1>{{unblockName}}</1> ?',
      unblockInfo: 'Jucătorul va putea să te invite la meciuri, să inițieze o conversație cu tine, sau să îți trimită o cerere de prietenie.',
      blockInfo: 'Jucătorul nu va mai putea să te invite la meciuri, să inițieze o conversație cu tine sau să îți trimită o cerere de prietenie prin intermediul Sportya.',
      blockNote: 'Notă: Nu sunt incluse meciurile în competițiile la care participați amândoi.',
      blockPlayer: 'Blochează Jucător',
      unblockPlayer: 'Deblochează Jucător',
      youBlockedThisPlayer: 'Ai blocat acest jucător',
      manageBlockedPlayers: 'Administrează Jucătorii Blocați',
      reportPlayer: 'Raportează Jucător',
      reportUser: 'Raportează jucătorul {{reportName}}',
      reportInfo: 'Te rugăm să alegi motivul raportării și să furnizezi orice informații suplimentare care vor ajuta echipa noastră să înțeleagă mai bine situația. Jucătorul raportat nu va fi notificat.',
      reportButton: 'Raportează',
      reportAndBlockButton: 'Raportează și Blochează',
      successReportAndBlockTitle: 'Ai raportat și blocat acest jucător',
      successReportText: 'Vom analiza raportarea și vom reveni către tine dacă vom avea nevoie de mai multe informații.',
      gotIt: 'Am înțeles',
      successReportTitle: 'Ai raportat acest jucător',
    },

    billingData: {
      title: 'Date Facturare',
      companyName: 'Numele companiei',
      cui: 'C.I.F',
      regNumber: 'Număr de înregistrare',
      fullName: 'Numele complet',
      address: 'Stradă, număr, etaj, etc',
      city: 'Oraș',
      country: 'Țară',
    },

    reportReasons: {
      reason: 'Motivul',
      selectTheReason: 'Selecteaza Motivul',
      harassment: 'Hărțuire sau Intimidare',
      spam: 'Spam',
      fakeProfile: 'Identitate falsă',
      playerUnderFifteen: 'Jucător sub 15 ani',
      other: 'Alt motiv',
    },

    reportTextAreaInfo: 'Te rugăm să ne oferi mai multe informații (opțional)',
  },
};
