export default {
  title: 'Начална страница',
  seoTitle: 'Играйте на местно ниво, достъп и на международно ниво. Присъединете се към глобална общност от тенис любители| Sportya',
  stats: {
    failedToLoad: '<0>Статистиката не е налична:</0> {{error}}',
    activePlayers: '<0>Играчи</0> <2>{{players}}</2>',
    activeEvents: '<0>Планирани събития {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Клубове</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Кортове</0> <2>{{courts}}</2>',
    activeCities: '<0>Градове</0> <2>{{cities}}</2>',
    activeCountries: `<0>Държави</0> <2>Румъния, Република
     Молдова, България,
     Бахрейн</2>`,
  },

  newEvents: {
    title: 'Препоръчани Събития',
    tennisTitle: 'Представени тенис събития',
    padelTitle: 'Представени събития за гребене',
  },

  clubArea: {
    title: 'Вие сте управител на клуб или администратор на корт?',

    first: {
      title: 'Управлявайте местни и глобални събития',
      desc: 'Персонализирани според нуждите на вашия клуб, включително наличности на кортове или съоръжения, за максимално въздействие.',
    },
    second: {
      title: 'Станете хъб за аматьорски спортисти',
      desc: 'Подкрепете организираната игра по нива на умения. Увеличете ангажираността и лоялността на играчите в клуба.',
    },
    third: {
      title: 'Свържете се с нови играчи',
      desc: 'Изградете и разширете базата си от играчи.',
    },
    fourth: {
      title: 'Наемайте вашите кортове и съоръжения',
      desc: 'Управлявайте и предлагайте кортове под наем на цялата общност Sportya. Подкрепете ангажираността на играчите и увеличете честотата на игра.',
    },
    button: 'Разбери повече',
  },

  centralPanel: {
    first: {
      title: 'Играй повече. Играй по твой начин.',
      desc: 'Оцени нивото си в тенис, падел и други спортове и избери как искаш да играеш – от класически събития, турнири с елиминации организирани през уикендите, до събития с групов формат, вечерни събития, гъвкави местни лиги, приятелски мачове и много други.',
    },
    second: {
      title: 'Запишете се за местни и международни турнири',
      desc: 'Достъпете календар с хиляди събития на Sportya, играйте в местни и международни аматьорски турнири, всички организирани по нива на играта в различни спортове.',
    },
    third: {
      title: 'Намерете и резервирайте кортове или игрища',
      desc: 'Търсете и резервирайте онлайн налични кортове или игрища в партньорски клубове за тенис, падел и други спортове.',
    },
    buttons: {
      first: 'Разбери повече',
      second: 'Участвай',
    },
  },

  slider: {
    '1stSlideText': 'Присъединете се към местни и международни аматьорски спортни събития',
    '1stSlideBtn': 'Намерете събитие',
    '2ndSlideText': 'Намерете и резервирайте кортове и съоръжения в партньорски спортни клубове',
    '2ndSlideBtn': 'Търсете сега',
    '3rdSlideText': 'Намерете партньор за приятелски мачове в тенис, падел и други спортове',
    '3rdSlideBtn': 'Разберете повече',
  },
};
