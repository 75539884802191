export default {
  hero: {
    title: 'Köszöntjük a \nSportya oldalán',
    desc: 'Sok tanulnivaló van a Sportya-ról, ezért kezdjük a legfontosabbakkal. Fedezd fel az alábbi útmutatót, hogy megtudd, hogyan működnek a mérkőzések, események és ranglisták.',
  },
  firstPhase: {
    title: 'Mi a Sportya?',
    desc: `A Sportya egy versenysport ökoszisztéma, ahol amatőr játékosok
               tízezrei barátságos mérkőzéseken, bajnokságokon és eseményeken
               vesznek részt, hogy élvezhessék a kiegyensúlyozott játékot
               vagy versenyezhessenek a legjobb helyekért a nemzeti és
               nemzetközi ranglistákon.`,
    articles: {
      1: {
        title: 'Fedezd fel',
        desc: 'Ha csatlakozol a Sportyához, az amatőr játékosok nagy és szenvedélyes közösségének részesévé válsz, és hozzáférést kapsz a több száz teniszklubban szervezett eseményekhez. Új régiókra és országokra terjesztjük ki a tevékenységünket, így nagyobb választási lehetőséget kínálunk neked, bármilyen utat is választasz - akár helyben játszol a partner klubokban, akár felfedező útra indulsz, és új élményeket keresel a világ más tájain.',
      },
      2: {
        title: 'Hívd ki',
        desc: 'Kezdd el néhány barátságos mérkőzéssel, csatlakozz egy helyi ligához, ha fontos számodra a rugalmas időbeosztás, vagy vesd bele magad azonnal a versenyszerű eseményekbe, és küzdd fel magad az amatőr játékosok nemzeti és nemzetközi ranglistájának élére.',
      },
      3: {
        title: 'Építs kapcsolatokat',
        desc: 'Tartsd közel a barátaidat, ismerkedj meg újakkal, oszd meg a tapasztalataidat. Építsd fel és mutasd be játékosprofilodat, és hozd ki a legtöbbet a teniszéletedből.',
      },
      4: {
        title: 'Játékszint',
        subtitle: 'Játssz kiegyensúlyozott mérkőzéseket',
        desc: 'Minden Sportya-tag egy adott játékszinten játszik. A szint határozza meg az eseményeket, amelyekhez csatlakozhatsz, és megkönnyíti a megfelelő párosítást mind a versenyszerű, mind a baráti játékban.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 jétékszint',
      desc: 'Minden játékost szeretettel várunk, a kezdőtől a tapasztalt játékosokig. Értékeld ki a Játékszintedet a fiók létrehozásakor, menj el játszani és élvezd a kiegyensúlyozott mérkőzéseket. 6 játékszintet kínálunk, amelyek mindegyikét a képességek és a tapasztalat keveréke határozza meg.',
    },
    2: {
      title: 'Egyéni és Páros játékszintek',
      desc: 'A Sportya külön kezeli őket. Kezdetben egy olyan játékszintet fogsz beállítani, amely az Egyéni játéklehetőségedet jelzi. Ez lesz automatikusan a kezdő játékszinted a páros mérkőzéseken. Ahogy fejlődsz, mindkét szinted automatikusan veled együtt fejlődik.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Helyi liga',
      subtitle: 'Kezeld rugalmasan a játékodat',
      desc: 'A helyi ligák lehetővé teszik, hogy hetente egy mérkőzést játszhass, rugalmas időbeosztást követve, miközben a versenyszellem megmarad. A helyi ligákra való regisztráció jelenleg INGYENES a Sportya felületén.',
    },
    2: {
      title: 'Barátságos mérkőzések',
      subtitle: 'Találj új játékpartnereket',
      desc: 'A barátságos mérkőzések a legrugalmasabb módot jelentik a tenisznek a Sportya felületén keresztül törénő megismerésére. Barátságos mérkőzéseket bárhol, bármikor játszhatsz más Sportya-tagokkal. Párkereső rendszerünk segít megtalálni a megfelelő játékpartnert, megtalálni a megfelelő helyszínt, és online jelenteni az eredményt, ami hozzájárul a ranglistán való helyezésedhez és a játékosprofilodhoz. Néhány funkció jelenleg még fejlesztés alatt áll, de megéri a várakozás.',
    },
    comingSoon: '2023-ben érkezik',
    comingSoonFM: 'Q1 2023-ben érkezik',
    inDevelopment: 'In Development',
  },
  fifthPhase: {
    title: 'Rangsorolt események',
    subtitle: 'Legjobb mérkőzésed még előtted van',
    desc: 'A Sportya legfontosabb funkciója, hogy a ranglistás események egy összefüggő éves naptárba szerveződnek, lehetővé téve, hogy helyi és globális amatőr ranglistákon versenyezz, és a csúcsra törj. A Sportya az amatőr játékosoknak az, ami az ATP/WTA a profi játékosoknak.',
    articles: {
      1: {
        title: 'Ranglistás eseménykategóriák',
        desc: 'Első Adogatás, Ezüst, Arany, Platina, Speciális és Bajnokok Tornája. Mindegyiknek meghatározott szerepe van a naptárban, és a nevezési követelmények kategóriánként eltérőek. Minden egyes eseményhez egy vagy több versenyt szervezünk a játékszintek alapján, akár Egyéniben, akár Párosban.',
      },
      2: {
        title: 'Eseményformátumok',
        desc: 'Élvezd a formátumok sokaságát, a klasszikus, hétvégén szervezett kieséses eseményektől kezdve a csoportos formátumú eseményeken át az éjszakai eseményekig.',
      },
      3: {
        title: 'Mérkőzésformátumok',
        desc: 'A Sportya Rangsorolt eseményein a legtöbb mérkőzést 2 tie-break szettben játsszák, a meccs tie-break (10 pont) 1 szettben.',
      },
      4: {
        title: 'Részvételi csomag',
        desc: 'Az eseményhez való hozzáférésen és annak minden kapcsolódó következményén kívül, hogy az esemény Sportya-ban zajlik (eseménykoordináció, eredmények, ranglisták, tevékenységfrissítések, ügyfélszolgálat stb.) a Sportya-ban a részvételi díjak általában tartalmaznak mérkőzésenként 2 új prémium teniszlabdát és mérkőzésenként egy üveg vizet is.',
      },
    },
  },
  sixthPhase: {
    title: 'Kezdj el játszani',
    desc: 'Végeredményben mindezzel a következő a célunk: ösztönözni szeretnénk a sport alapját képező szórakoztató és magával ragadó versenyzést, ami végső soron a játékban és a mindennapi életben való fejlődéshez vezet.',
  },
  playVideo: 'Videó lejátszása',
};
