import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

import {
  Container, LevelContainer, Data, Item, Value, DataContainer, Info, Lists, Badge, List, Checkmark,
} from './styles';
import Restrictions from './components/restrictions';
import { Badge as ShieldBadge } from '../../../../components/LevelsBadges/styles';

import getCountryIsoCode from '../../../../components/common/getCountryIsoCode';
import isRegisteredToCompetition from '../../../../components/common/isRegisteredToCompetition';
import hasRestrictions from '../../../../components/common/competitionHasRestrictions';

import GoToCompetition from './components/button';
import { H3, P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import getCompetitionListTypeEntry from '../../../../components/common/getCompetitionListTypeEntry';

const Index = props => {
  const {
    eventInfo,
    competitionInfo,
    eventStatus,
    displayType,
    myAccount,
  } = props;

  const { accountInfo, isLoggedIn } = useSelector(state => state.session);
  const [isRegistered, setIsRegistered] = useState();
  const [fees, setFees] = useState();

  if (!competitionInfo) {
    return false;
  }

  const currentPhase = eventInfo?.currentPhase?.status;
  const listType = getCompetitionListTypeEntry(accountInfo?.userId, competitionInfo);
  const isBeforeClosingRegistrations = new Date() < new Date(eventInfo?.phases?.playing?.endDate);
  const {
    mlMaxNumber,
    wildCards,
    mainList,
    preRegistrationList,
  } = competitionInfo;

  const wildCardsOnML = mainList?.filter(player => player?.slotType === 'WILD_CARD')?.length;
  const wildCardsOnSL = preRegistrationList?.filter(player => player?.slotType === 'WILD_CARD')?.length;

  const availableSlots = Math.max(
    0,
    Number(mlMaxNumber)
    - Number(wildCards - wildCardsOnML)
    - Number(mainList?.length),
  );

  const availableSlotsSl = Math.max(
    0,
    Number(mlMaxNumber)
    - Number(wildCards - wildCardsOnSL)
    - Number(preRegistrationList?.length),
  );

  const registrationMLStatus = eventStatus === 'registrationsOpen' ? 'registrationsOpen' : 'registrationClosed';
  const preRegistrationSLStatus = (eventStatus === 'preRegistrationsOpen'
    ? 'preRegistrationsOpen' : 'preRegistrationClosed'
  );

  const registrationMLListStatus = (eventStatus === 'registrationsOpen' && availableSlots === 0) ? 'Full' : '';
  const preRegistrationSLListStatus = (eventStatus === 'preRegistrationsOpen' && availableSlots === 0) ? 'Full' : '';

  const registrationWLStatus = isBeforeClosingRegistrations ? 'registrationsOpen' : 'registrationClosed';
  const registrationWLListStatus = competitionInfo?.waitingList.length === 0 ? 'All' : '';

  const badgeStatus = {
    sl: {
      bg: eventStatus === 'preRegistrationsOpen' ? 'lightGreen' : 'secondaryRed',
      color: 'black',
      availableSlotsSl,
      translationKey: `${preRegistrationSLStatus}SortList${preRegistrationSLListStatus}`,
    },
    ml: {
      bg: eventStatus === 'registrationsOpen' ? (availableSlots > 0 ? 'lightGreen' : 'grey') : 'secondaryRed',
      color: 'black',
      availableSlots,
      // eslint-disable-next-line max-len
      translationKey: `${registrationMLStatus}MainList${registrationMLListStatus}${availableSlots === 0 || availableSlots > 1 ? '_plural' : ''}`,
    },
    wl: {
      bg: ['resultsPending', 'ended'].includes(eventStatus) ? 'secondaryRed' : 'yellow',
      color: 'black',
      occupiedSlots: competitionInfo?.waitingList?.length,
      translationKey: `${registrationWLStatus}WaitingList${registrationWLListStatus}`,
    },
  };

  const restrictions = hasRestrictions(competitionInfo);
  // check competition fee tax
  if (competitionInfo) {
    const { currency } = getCountryIsoCode(eventInfo?.club?.location?.country);
    const [eventMainFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'RON' : accountInfo?.currency));
    const [eventSecondaryFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'BGN' : currency));

    if (isRegistered === undefined) {
      setIsRegistered(isRegisteredToCompetition(accountInfo?.userId, competitionInfo));
    }

    if (!fees) {
      setFees({
        primary: eventMainFees,
        secondary: eventSecondaryFees,
      });
    }
  }

  const hasGroups = competitionInfo.numberOfGroups > 0;
  const hasPhases = competitionInfo?.phases?.length > 0;

  return (
    <Container>
      <LevelContainer>
        <Trans ns="common" i18nKey="level">Level</Trans>
        <ShieldBadge large double={competitionInfo.gameType === 'doubles'}>
          <H3>{competitionInfo?.level}</H3>
        </ShieldBadge>
        {(myAccount && listType) && (
          <List>
            <Checkmark src={LoadImage('checkmark-black.svg')} />
            <P small bold><Trans ns="events" i18nKey={listType} /></P>
          </List>
        )}
      </LevelContainer>
      <DataContainer>
        <Data>
          <Info>
            <Item bold>
              <Trans ns="events" i18nKey="gameTypeText">Game Type</Trans>
              :
              <Value><Trans ns="events" i18nKey={competitionInfo?.gameType} /></Value>
            </Item>
            <Item bold>
              <Trans ns="events" i18nKey={competitionInfo.gameType === 'singles' ? 'tablePlayers' : 'tableGroups'}>
                Draw:
                <Value>
                  {{ players: competitionInfo?.mlMaxNumber }}
                  {' '}
                  Players
                </Value>
              </Trans>
              <Value>
                (
                {hasGroups && `${competitionInfo.numberOfGroups} `}
                <Trans ns="events" i18nKey={competitionInfo?.drawModel}>{competitionInfo?.drawModel}</Trans>
                {hasPhases && (
                  <>
                    {' '}
                    +
                    <Trans i18nKey={competitionInfo?.phases[0]?.drawModel} ns="events">
                      {` + ${competitionInfo?.phases[0]?.drawModel}`}
                    </Trans>
                  </>
                )}
                )
              </Value>
            </Item>
          </Info>
          <Info>
            <Restrictions
              competitionInfo={competitionInfo}
              restrictions={restrictions}
            />
            <Item bold>
              <Trans ns="events" i18nKey="matchStart">
                Match Start:
                <Value>{{ date: dateFormat(competitionInfo?.startDate, 'd mmm (ddd), HH:MM') }}</Value>
              </Trans>
            </Item>
          </Info>
        </Data>
        <Lists>
          {!['competitionInfo'].includes(displayType) && !['openingSoon'].includes(eventStatus) && (
            <>
              {!['preRegistrationsOpen', 'sortPending'].includes(eventStatus) ? (
                <Badge
                  bg={badgeStatus.ml.bg}
                  textColor={badgeStatus.ml.color}
                >
                  <P>
                    <Trans
                      ns="events"
                      i18nKey={badgeStatus.ml.translationKey}
                      count={badgeStatus.ml.availableSlots}
                    >
                      <strong>Main List:</strong>
                      {{ available: badgeStatus.ml.availableSlots }}
                    </Trans>
                  </P>
                </Badge>
              ) : (
                <>
                  {!['competitionInfo'].includes(displayType) ? (
                    <Badge
                      bg={badgeStatus.sl.bg}
                      textcolor={badgeStatus.sl.color}
                    >
                      <P>
                        <Trans
                          ns="events"
                          i18nKey={badgeStatus.sl.translationKey}
                          count={badgeStatus.sl.availableSlots}
                        >
                          <strong>List:</strong>
                          {{ available: badgeStatus.sl.availableSlots }}
                        </Trans>
                      </P>
                    </Badge>
                  ) : ''}
                </>
              )}
              {(availableSlots === 0 || ['readyToPlay', 'playing', 'drawPending'].includes(currentPhase)) ? (
                <Badge
                  bg={badgeStatus.wl.bg}
                  textcolor={badgeStatus.wl.color}
                  textweight="300"
                >
                  <P>
                    <Trans ns="events" i18nKey={badgeStatus.wl.translationKey} count={badgeStatus.wl.occupiedSlots}>
                      <strong>Waiting List:</strong>
                      {{ occupied: badgeStatus.wl.occupiedSlots }}
                    </Trans>
                  </P>
                </Badge>
              ) : ''}
            </>
          )}
        </Lists>
      </DataContainer>
      <GoToCompetition
        isLoggedIn={isLoggedIn}
        myAccount={myAccount}
        displayType={displayType}
        eventInfo={eventInfo}
        accountInfo={accountInfo}
        competitionInfo={competitionInfo}
      />
    </Container>
  );
};

export default Index;
