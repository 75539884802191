import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { P } from '../../../../../components/Collection';
import compareScores from '../../../../../components/common/compareScores';
import getStage from '../../../../../components/common/getStage';
import isUndisputed from '../../../../../components/common/isUndisputed';
import LoadImage from '../../../../../components/common/LoadImage';
import { Badge } from '../../../../../components/LevelsBadges/styles';
import {
  Column, MatchContainer, MatchContainerMobile, MatchData, MatchMeta, MatchMetaContainer, WLBox,
} from '../styles';

const MatchesComponent = ({ match, gameType }) => {
  const isDoubles = gameType === 'doubles';
  const {
    opponent, player, playerPartner, opponentPartner, winner,
  } = match || {};
  const { t } = useTranslation('events');
  const { t: commonT } = useTranslation('common');

  const getName = (type) => {
    const user = {
      firstName: match?.[type]?.firstName,
      lastName: match?.[type]?.lastName,
    };
    return user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}` : commonT('notAvailable');
  };

  return (
    <>
      <MatchContainer>
        <Column maxWidth={140} flex center>
          <P small bold>
            {getStage(match, t)}
          </P>
        </Column>
        <Column maxWidth={300} flex center {...isDoubles && { column: true, center: false, align: 'center' }}>
          <span className="d-flex align-items-center">
            <Badge small className="mr5" double={isDoubles}>
              <P xSmall>
                {opponent?.level || '?'}
              </P>
            </Badge>
            {opponent?.type === 'BYE' && !opponent?.reason ? <P small>BYE</P>
              : opponent?.id
                ? (
                  <a href={`/accounts/${opponent?.id}`} target="_blank" rel="noreferrer">
                    <P small bold={winner === 'opponent'} className="d-flex">
                      {getName('opponent')}
                      {winner === 'opponent' && !isUndisputed(player, opponent)
                        ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                        : ''}
                    </P>
                  </a>
                )
                : (
                  <P small bold={winner === 'opponent'} className="d-flex">
                    {getName('opponent')}
                    {winner === 'opponent' && !isUndisputed(player, opponent)
                      ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                      : ''}
                  </P>
                )}
          </span>
          {isDoubles
            ? (
              <span className="d-flex align-items-center">
                {opponentPartner?.level && (
                  <Badge small className="mr5" double={isDoubles}>
                    <P xSmall>
                      {opponentPartner?.level}
                    </P>
                  </Badge>
                )}
                {opponentPartner?.type === 'BYE' && !opponentPartner?.reason ? <P small>BYE</P>
                  : opponentPartner?.id
                    ? (
                      <a href={`/accounts/${opponentPartner?.id}`} target="_blank" rel="noreferrer">
                        <P small bold={winner === 'opponent'} className="d-flex">
                          {getName('opponentPartner')}
                          {' '}
                          {winner === 'opponent' && isDoubles
                            ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                            : ''}
                        </P>
                      </a>
                    )
                    : (
                      <P small bold={winner === 'opponent'} className="d-flex">
                        {getName('opponentPartner')}
                        {' '}
                        {winner === 'opponent' && isDoubles
                          ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                          : ''}
                      </P>
                    )}
              </span>
            )
            : ''}
        </Column>
        {/* <Column maxWidth={160}>ELO Rating</Column> */}
        <Column maxWidth={250} flex column justify="center">
          <P small bold className="d-flex justify-content-center">
            {opponent?.rank ?? '-'}
          </P>
          {isDoubles && (
            <P small bold className="d-flex justify-content-center">
              {opponentPartner?.rank ?? '-'}
            </P>
          )}
        </Column>
        <Column maxWidth={300} flex column justify="center">
          <P small className="d-flex justify-content-center">
            {isUndisputed(player, opponent, isDoubles, playerPartner, opponentPartner) ? t('undisputed')
              : opponent?.reason === 'NO_SHOW' || player.reason === 'NO_SHOW'
                ? 'W.O.' : opponent?.type === 'BYE' && !opponent?.reason ? '-'
                  : opponent?.score?.length && player?.score?.length
                    ? opponent?.score.slice()
                      .sort((a, b) => (a.set > b.set ? 1 : -1)).map((score, keyScore) => (
                        <React.Fragment key={keyScore}>
                          {compareScores(match, score, keyScore)}
                        </React.Fragment>
                      )) : opponentPartner?.score?.length && playerPartner?.score?.length
                  && opponentPartner?.score.slice()
                    .sort((a, b) => (a.set > b.set ? 1 : -1)).map((score, keyScore) => (
                      <React.Fragment key={keyScore}>
                        {compareScores(match, score, keyScore, true)}
                      </React.Fragment>
                    ))}
            {opponent?.reason === 'ABANDONED' && (<>&nbsp;(Ab.)</>)}
            {player.reason === 'ABANDONED' && (<>&nbsp;(Ab.)</>)}
          </P>
        </Column>
        <Column maxWidth={20} xMargin="0 0 0 auto">
          <P smaller>
            {isUndisputed(player, opponent, isDoubles, playerPartner, opponentPartner)
              ? (
                <span>
                  -
                </span>
              )
              : !opponent?.score.length && !opponent?.type && opponent?.reason === 'NO_SHOW' ? (
                <WLBox>
                  <Trans ns="player" i18nKey="woStatusShorthand">
                    W.O.
                  </Trans>
                </WLBox>
              ) : winner === 'player'
              || opponent?.reason === 'NO_SHOW'
              || opponent?.type === 'BYE'
              || opponent?.reason === 'ABANDONED'
                ? (
                  <WLBox win>
                    <Trans ns="player" i18nKey="winStatusShorthand">
                      W
                    </Trans>
                  </WLBox>
                ) : winner === 'opponent'
                || player.reason === 'NO_SHOW'
                || player.type === 'BYE'
                || player.reason === 'ABANDONED'
                  ? (
                    <WLBox lose>
                      <Trans ns="player" i18nKey="loseStatusShorthand">
                        L
                      </Trans>
                    </WLBox>
                  ) : ''}
          </P>
        </Column>
      </MatchContainer>
      <MatchContainerMobile>
        <MatchMetaContainer>
          <MatchMeta>Round</MatchMeta>
          <MatchData>{getStage(match, t)}</MatchData>
        </MatchMetaContainer>
        <MatchMetaContainer>
          <MatchMeta>Opponent</MatchMeta>
          <MatchData>
            <div className="d-flex align-items-center">
              <Badge small className="mr5" double={isDoubles}>
                <P xSmall>
                  {opponent?.level || '?'}
                </P>
              </Badge>
              {opponent?.type === 'BYE' && !opponent?.reason ? <P small>BYE</P>
                : opponent?.id
                  ? (
                    <a href={`/accounts/${opponent?.id}`} target="_blank" rel="noreferrer">
                      <P small bold={winner === 'opponent'} className="d-flex">
                        {getName('opponent')}
                        {winner === 'opponent' && !isUndisputed(player, opponent)
                          ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                          : ''}
                      </P>
                    </a>
                  )
                  : (
                    <P small bold={winner === 'opponent'} className="d-flex">
                      {getName('opponent')}
                      {winner === 'opponent' && !isUndisputed(player, opponent)
                        ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                        : ''}
                    </P>
                  )}
            </div>
            {isDoubles
              ? (
                <span className="d-flex align-items-center">
                  {opponentPartner?.level && (
                    <Badge small className="mr5" double={isDoubles}>
                      <P xSmall>
                        {opponentPartner?.level || '?'}
                      </P>
                    </Badge>
                  )}
                  {opponentPartner?.type === 'BYE' && !opponentPartner?.reason ? <P small>BYE</P>
                    : opponentPartner?.id
                      ? (
                        <a href={`/accounts/${opponentPartner?.id}`} target="_blank" rel="noreferrer">
                          <P small bold={winner === 'opponent'} className="d-flex">
                            {getName('opponentPartner')}
                            {' '}
                            {winner === 'opponent' && isDoubles
                              ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                              : ''}
                          </P>
                        </a>
                      )
                      : (
                        <P small bold={winner === 'opponent'} className="d-flex">
                          {getName('opponentPartner')}
                          {' '}
                          {winner === 'opponent' && isDoubles
                            ? <img src={LoadImage('checkmark-draw.svg')} className="ml5" alt="" />
                            : ''}
                        </P>
                      )}
                </span>
              )
              : ''}
          </MatchData>
        </MatchMetaContainer>
        <MatchMetaContainer>
          <MatchMeta>Ranking</MatchMeta>
          <MatchData>
            {opponent?.rank ?? '-'}
            {isDoubles && (` / ${opponentPartner?.rank ?? '-'}`)}
          </MatchData>
        </MatchMetaContainer>
        <MatchMetaContainer>
          <MatchMeta>Score</MatchMeta>
          <MatchData>
            {isUndisputed(player, opponent, isDoubles, playerPartner, opponentPartner) ? t('undisputed')
              : opponent?.reason === 'NO_SHOW' || player.reason === 'NO_SHOW'
                ? 'W.O.' : opponent?.type === 'BYE' && !opponent?.reason ? '-'
                  : opponent?.score?.length && player?.score?.length
                    ? opponent?.score.slice()
                      .sort((a, b) => (a.set > b.set ? 1 : -1)).map((score, keyScore) => (
                        <React.Fragment key={keyScore}>
                          {compareScores(match, score, keyScore)}
                        </React.Fragment>
                      )) : opponentPartner?.score?.length && playerPartner?.score?.length
                  && opponentPartner?.score.slice()
                    .sort((a, b) => (a.set > b.set ? 1 : -1)).map((score, keyScore) => (
                      <React.Fragment key={keyScore}>
                        {compareScores(match, score, keyScore, true)}
                      </React.Fragment>
                    ))}
            {opponent?.reason === 'ABANDONED' && (<>&nbsp;(Ab.)</>)}
            {player.reason === 'ABANDONED' && (<>&nbsp;(Ab.)</>)}
          </MatchData>
        </MatchMetaContainer>
        <MatchMetaContainer>
          <MatchMeta>W/L</MatchMeta>
          <MatchData>
            {isUndisputed(player, opponent, isDoubles, playerPartner, opponentPartner)
              ? (
                <span>
                  -
                </span>
              )
              : !opponent?.score.length && !opponent?.type && opponent?.reason === 'NO_SHOW' ? (
                <WLBox>
                  <Trans ns="player" i18nKey="woStatusShorthand">
                    W.O.
                  </Trans>
                </WLBox>
              ) : winner === 'player'
              || opponent?.reason === 'NO_SHOW'
              || opponent?.type === 'BYE'
              || opponent?.reason === 'ABANDONED'
                ? (
                  <WLBox win>
                    <Trans ns="player" i18nKey="winStatusShorthand">
                      W
                    </Trans>
                  </WLBox>
                ) : winner === 'opponent'
              || player.reason === 'NO_SHOW'
              || player.type === 'BYE'
              || player.reason === 'ABANDONED'
                  ? (
                    <WLBox lose>
                      <Trans ns="player" i18nKey="loseStatusShorthand">
                        L
                      </Trans>
                    </WLBox>
                  ) : ''}
          </MatchData>
        </MatchMetaContainer>
      </MatchContainerMobile>
    </>
  );
};

export default MatchesComponent;
