import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import {
  LeftSide, MarginLeftAuto, SectionContainer, SectionTitle,
} from '../../styles';
import { Contain, P } from '../../../../../components/Collection';
import GoogleMapsComponent from '../../../../../components/GoogleMapsComponent';
import SelectComponent from '../../../../../components/Form/Select';
import Event from '../../../../../components/Event';
import Pagination from '../../../../../components/Pagination';
import handlePhaseChange from '../../helpers/handlePhaseChange';
import filtersList from '../../constants/filtersList';
import handlePagination from '../../helpers/handlePagination';
import Calendar from '../Calendar';
import { fetchClubEvents } from '../../../reducers';

const LeftSideComponent = ({
  clubInfo,
  clubId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('clubs');
  const { data: events, status } = useSelector(state => state.clubs?.info?.events);
  const { t: eventsT } = useTranslation('events');
  const [selectedPhase, setSelectedPhase] = useState({ value: 'all', label: 'All' });
  const [currentPage, setCurrentPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 5,
    sortBy: 'createdAt:desc',
  });

  useEffect(() => {
    if (clubId !== clubInfo?.id || status === 'idle') {
      dispatch(fetchClubEvents({ clubId }));
    }
  }, [clubId, status]);

  const toolkit = {
    setSelectedPhase(phase) {
      return setSelectedPhase(phase);
    },
    setCurrentPage(page) {
      return setCurrentPage(page);
    },
    setParams(param) {
      return setParams(param);
    },
    fetchClubEvents(newParams) {
      return dispatch(fetchClubEvents(newParams));
    },
  };

  return (
    <LeftSide>
      {clubInfo?.bookingAccess === 'enabled'
        && clubInfo?.bookingSettings?.bookingEnabled === true
        && (<Calendar clubInfo={clubInfo} />)}

      {clubInfo?.location?.coords && (
        <SectionContainer noMargin>
          <SectionTitle>
            <Trans ns="clubs" i18nKey="location">Location</Trans>
          </SectionTitle>
          <GoogleMapsComponent coords={clubInfo?.location?.coords} />
        </SectionContainer>
      )}

      {clubInfo?.description && (
        <SectionContainer>
          <SectionTitle>
            <Trans ns="clubs" i18nKey="about">About</Trans>
          </SectionTitle>

          <P medium>{clubInfo?.description}</P>
        </SectionContainer>
      )}

      {(selectedPhase.value !== 'all' || events?.totalResults > 0) && (
        <Contain align="center" id="top">
          <SectionTitle>
            <Trans ns="clubs" i18nKey="events">Events</Trans>
          </SectionTitle>
          <MarginLeftAuto>
            <SelectComponent
              defaultValue={selectedPhase}
              labelOn={selectedPhase}
              placeholder={t('filterEvents')}
              onChange={(e) => handlePhaseChange({
                selectedValue: e,
                toolkit,
                params,
                clubId,
                t: eventsT,
              })}
              options={filtersList(eventsT)}
              label={t('filterEvents')}
              lowInput
              width="160px"
            />
          </MarginLeftAuto>
        </Contain>
      )}

      {(events?.results || [])?.map((event, key) => (
        <Event event={event} grid={false} key={key} />
      ))}

      <Pagination
        pageCount={events?.totalPages || 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(e) => handlePagination({
          clubId,
          page: e,
          toolkit,
          params,
        })}
        forcePage={currentPage}
      />
    </LeftSide>
  );
};

export default LeftSideComponent;
