import React from 'react';
import DatePicker from 'react-datepicker';

const DatePickerComponent = ({
  name, value, onChange, ...props
}) => (
  <DatePicker
    selected={(value && new Date(value)) || null}
    onChange={val => {
      onChange(name, val);
    }}
    {...props}
    className={props.showTimeSelect ? 'only-time' : 'date-time'}
  />
);

export default DatePickerComponent;
