/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import Menu from './components/menu';
import { Container } from './styles';

const Confidentiality = () => {
  const { t } = useTranslation('rules');
  const menuItems = [];

  for (let j = 0; j < 13; j++) {
    const sectionNr = j + 1;

    menuItems.push({
      id: `section${sectionNr}`,
      title: t(`confidentiality.section${sectionNr}.subtitle1`),
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle1')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-3 position-relative">
          <Menu menuItems={menuItems} />
        </div>
        <div className="col-sm-9">
          <H1 className="mb30">{t('confidentiality.mainTitle')}</H1>
          <P large className="mb40">{t('confidentiality.mainDescription')}</P>

          <div id="section1">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section1.subtitle1">
                  Explanation of certain terms
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section1.p1">
                  In this Statement, we will refer to you by using the terms ‘You’ or ‘User’.
                  When we talk about ‘us’, or ‘MPG’, we refer to Media Production Group S.R.L., a limited liability company, legally incorporated and operating in compliance with the laws of Romania, having its registered office in Câmpina, 15B Mărăsesti Str., Prahova County, ROMANIA, post code 105600, registered with Prahova Trade Register under no. J29/1130/1997, having Unique Registration Code 9815911, Tax Registration Code / VAT code RO9815911. MPG is one of the largest service providers for amateur athletes in Romania, through hundreds of sports events under its coordination, together with its affiliates, the MPG Sports Club Association and the ProAm Events Foundation (&quot;Affiliates&quot;). Through its various projects dedicated to sport for everyone, MPG and its Affiliates provide various services dedicated to active leisure, from amateurs’ access to sports competitions, to the management of registrations in these competitions and the presentation of information related to competitions, such as lists of participants, results, the recording of the history of sports activity, live watching solutions in sports events, etc. (&quot;Our activities&quot;).

                  To carry out our Activities, MPG uses several IT systems. For Sportya, the Website is at the same time a Self-Service Portal (the &quot;Portal&quot;) for competition organizers and potential participants. The portal will allow you not only to search and register for events that organizers publish or that are previously approved and published by MPG, but also to keep a record of the entire activity carried out in the events registered on the Portal.

                  Ultimately, this is a statement on the information about people – like you. It includes facts about you (e.g., results following your participation in events/tournaments), and also features about your sporting activity (e.g., your level of play). It is not about information related to MPG (although sometimes the two overlap). This information is sometimes called &quot;Personal Data&quot;, &quot;Identifiable Personal Data&quot; or &quot;PII&quot;. In this statement, we will use the term &quot;Personal Data&quot;.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section2">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section2.subtitle1">
                  What personal data does MPG collect and use?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section2.p1">
                  The personal data collected by MPG typically includes, but is not limited to:
                  • your name, date of birth, contact details (e-mail, telephone, address), level of play according to the Sportya regulation, documents proving your identity;

                  • if you log in to the Portal using your Facebook or Google account, or other social networks: your profile data;

                  • if you contact us, in some cases we will register that correspondence;

                  • feedback about you from our staff and third parties: if you provide feedback about others, we usually keep this information as well, and it represents personal data about you (being your opinion);

                  • we may collect information about your visits to our Website/Portal, including but not limited to your IP address, browser, date and time registration, location, country traffic data, location information, Weblogs and other communication information and resources that you access. This information will make your future visit to our website/portal easier. For example, we may suggest sporting events held in the location from which you contacted / visited us;

                  • we may also collect information for purposes of marketing and analytics, including information about how you respond to email, SMS, telephone and other marketing campaigns;

                  • if applicable, details of any disabilities and any adjustments we will have to make for you.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section3">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section3.subtitle1">
                  Why do we use personal information about you?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section3.p1">
                  MPG collects and processes personal data:
                  1. to provide you with the services you expect from us, when you accept by explicit consent to become a member of Sportya, namely a user of the Website/Portal, such as finding sports events / tournaments in the area where you reside, in order to help you with recommendations or to facilitate the process of participating in the scheduled sports events. This includes sending your name and relevant personal information (e.g., telephone number, level of play) to the organizers of the sports events you have signed up for, so that they can inform you and keep you up to date on the organization details or manage your participation, and by default be able to carry out the event organization activity. The organizers of the sports events registered on the Website / Portal can thus contact you by e-mail, telephone and / or other communication methods;

                  2. with your explicit consent, we may use your contact details for direct marketing, for example via e-mail, messages or telephone;

                  3. to develop, test and improve our Website/Portal or other new or existing systems / processes, in order to better serve you; this takes place in the context of new IT systems and processes, so that data about you can be used to test these new IT systems and processes, where bogus data cannot fully replicate the operation of that new IT system;

                  4. to carry out studies, statistics and analytical research, for example to compare the running of sports events between different geographical areas, respectively the interest shown by participants in these events;

                  5. for data transfer to third parties (see below);

                  6. if necessary, to comply with any legal obligations; and

                  7. we may also process your data:

                  a. to promote the security and protection of persons, premises, systems and assets;

                  b. to monitor the compliance with the Sportya and/or MPG internal policies and procedures;

                  c. to manage communications and other systems used by MPG and its affiliates (including internal contact databases);

                  d. to investigate or respond to incidents and complaints;

                  e. in the case of photos or videos resulting from your participation in events, to promote similar events internally and externally to third parties / potential new participants, sponsors, partners; or

                  f. to comply with the obligations and rights and to cooperate in investigations carried out by the police, government or regulators; and

                  g. to participate in any potential or actual acquisition or sale, or in a joint venture, in a business or company, in whole or in part, in which MPG or its Affiliates wish to participate.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section4">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section4.subtitle1">
                  What are the provisions of the law in this regard?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section4.p1">
                  According to the law, we must have a legal basis for processing the data we keep about you.

                  The processing of personal data by us for all the purposes listed above is based on the reason for processing, such as the performance of the Services to which you are entitled, by registering as a member in Sportya (objectives 1 and 6-7), processing necessary to comply with the legal or regulatory obligations (objectives 6-7) and / or the necessary processing in the legitimate interests of MPG in the exercise of its fundamental rights and of its personnel to carry out an activity in a way that does not affect your interests or your fundamental rights and freedoms (objectives 1-5 and 7). When data processing is necessary for the legitimate interests of the MPG, we ensure that the processing is performed in such a way that our legitimate interests outweigh any individual interests. Except for the above-mentioned reasons, we will process your personal data only with your consent (an additional processing basis).
                </Trans>
              </P>
            </div>
          </div>

          <div id="section5">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section5.subtitle1">
                  Are you bound to provide us with the personal data we request?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section5.p1">
                  You do not have the obligation to provide us with your personal data, but it is almost impossible for us to work with you and offer our services to you otherwise.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section6">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section6.subtitle1">
                  Do we process personal data about you without human intervention?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section6.p1">
                  Yes, we do this sometimes. MPG may use automated systems/processes and make automated decisions (such as creating analytics profiles), to provide you and the organizers of sports events/tournaments, as customers, with the services you request from us. For example, when our customers want to promote an event / tournament, we may search our list of Service users by using automated criteria that take into account the level of play, past sporting activity and, in certain circumstances, previous feedback we have received from customers, to make a short list that includes users who will most likely be interested in our client\&apos;s event / tournament.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section7">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section7.subtitle1">
                  How long do you keep your personal data?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section7.p1">
                  MPG may keep your personal data indefinitely, as it contributes to the functionality of the Services, and to the correct display of information related to the conduct of an event/tournament (e.g., final results of the event/tournament).
                </Trans>
              </P>
            </div>
          </div>

          <div id="section8">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section8.subtitle1">
                  Do we transfer your data to third parties?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section8.p1">
                  As mentioned above, we usually disclose your data to third parties. The reason is to complete the objectives set out above. We carry this out under the following circumstances:
                  • To our customers / organizers of events / sports tournaments: we will share your data with our customers who implement the events/tournaments you have signed up for. They have contact and other privacy obligations in connection with your data, towards us and towards you;

                  • To our suppliers. We may, for example, contract a supplier to perform administrative and operational work in support of our relations with you. The provider(s) will be bound by the contractual obligations and other legal obligations to observe the confidentiality of your data and to respect your privacy, and will only have access to the data necessary to perform their functions; relevant providers are primarily IT providers (who host or support our IT systems, including information about you), insurance or security companies (who offer you specific services, such as accident insurance policies or live watching of events; as a result, they need to know about you in order to provide you with these services), and financial and accounting management service providers (who may need to process your details in order to comply with legal accounting processes). We can also contract providers that offer IT technology services and solutions, which may include the generation of customizable photo/video content, content that can be offered to you for free or for a fee, digital timing for competitive events (e.g., cycling), based on which the final rankings are generated, without which it would be impossible to reward the participants or implement certain Services provided by the Portal and/or the Website.

                  • To our affiliates, the MPG Sports Club Association and the ProAm Events Foundation. They are located in Romania; our affiliates perform different functions, and as a result, your data may be shared with them for different reasons.

                  • Subject to legal provisions, we will share your data with the government, police, regulatory bodies or regulatory agencies if, at our discretion, we consider that we are legally required or authorized to do so, or that it would be prudent to do so; and

                  • As part of prior verification of (or implementation of) a merger, acquisition or other business transaction, we may need to disclose your information to the potential seller or buyer and its consultants.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section9">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section9.subtitle1">
                  Do we transfer your data outside the European Union?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section9.p1">
                  Your personal information shall not be transferred outside the European Union. If applicable, we will only transfer your data outside the EU to countries that the European Commission deems to provide you with an adequate level of protection, or where MPG has implemented appropriate safeguards to seek to preserve the confidentiality of your information.  We will notify you of the countries to which we transfer your data, when and if we identify the need to transfer it.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section10">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section10.subtitle1">
                  What are your rights?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section10.p1">
                  • The right to access and obtain a copy of your personal data

                  You have the right to request confirmation if we process any of your personal information. If applicable, you may have access to your personal information and certain information regarding how it is processed. In some cases, you may ask us to provide you with an electronic copy of your information.

                  • The right to rectify your personal data

                  If you can prove that the personal data we hold about you is not correct, you can request that this information be updated or corrected. We encourage you to access the self-service system offered by the Website/Portal, if possible, and to update your personal data directly.

                  • The right to be forgotten / to delete data

                  In certain cases, you have the right to ask us to restrict the processing of your personal data and/or to delete your personal data. You may submit such a request at any time, and MPG will assess whether your request will be positively solved, however, if this right is subject to any legal rights or obligations, we may retain the data. For cases where, in compliance with the legal provisions, we determine that the request to delete your personal data must be positively solved, MPG will do so without delay. Please note that once your data is deleted, MPG can no longer help you after this data has been deleted. If you want to register again and access the Services provided, you will need to re-enter your data.

                  • The right to object

                  If the processing of your data by MPG is based on the legitimate interest of MPG (and no other processing basis), or is linked to direct marketing, you have the right to object to the processing of your data by MPG, referring to your particular situation.

                  If you wish to exercise any of your rights, please log in to the self-service Portal (which allows you to exercise your rights autonomously) or send us an email at corporate@sportya.net.

                  When you send us an email to exercise your rights, MPG may ask you to identify yourself before solving your request.

                  Finally, you have the right to file a complaint with the Data Protection Authority located at your place of residence or where you work, or in the place where you believe that the problem has arisen in connection with your data.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section11">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section11.subtitle1">
                  Data security when using MPG systems
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section11.p1">
                  You are responsible for keeping the login details on the Website/Portal secure, in particular the password that we have given you or that you have chosen. This login identification data will only be used by you. You are not permitted to share your identification data or any other account details with any other person(s).
                </Trans>
              </P>
            </div>
          </div>

          <div id="section12">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section12.subtitle1">
                  How can you contact us?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section12.p1">
                  Questions, comments and requests regarding this document can be directed to us at corporate@sportya.net.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section13">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section13.subtitle1">
                  How do we process the amendments to this Statement?
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="confidentiality.section13.p1">
                  The terms of this Statement may be amended from time to time. We will post any material changes to this Statement through appropriate notices, either on the Website/Portal or by contacting you using other communication channels.
                </Trans>
              </P>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Confidentiality;
