import styled from '@emotion/styled';

export const PenaltyFee = styled.div`
  .penaltyWrap2{
    display:flex;
    flex-direction:column;
    color: ${({ theme }) => theme?.colors?.error};
    padding-bottom:20px;
    width:100%;

    .penaltyTitle{
      display:flex;
      align-items:center;
      p:first-of-type{
        font-weight: bold;
        font-size: ${({ theme }) => theme.functions?.toEm(14)};;
        line-height: 18px;
      }
      .penaltySum{
        font-weight: bold;
        font-size: ${({ theme }) => theme.functions?.toEm(16)};;
        line-height: 21px;
        margin-top:0px;
      }
      margin-top:0px;
    }
.penaltySum{
  font-weight: normal;
  font-size: ${({ theme }) => theme.functions?.toEm(14)};;
  line-height: 18px;
  margin-top:10px;
}
  }
`;
