export default {
  forgotPassword: 'Elfelejtett jelszó',
  subTitle: 'Írd be az e-mail címedet',
  reset: 'Visszaállítás',
  placeholders: {
    email: 'E-mail cím',
    password: 'Jelszó',
    passwordConfirmation: 'Jelszó megerősítése',
  },
  passwordResetSuccessfully: 'Kérjük, ellenőrizd beérkezett üzeneteidet a jelszó-visszaállítási utasításokért.',
  setPassword: 'Állítsd be a jelszót',
  setNewPassword: 'Írd be az új jelszavadat',
  invalidResetPasswordLink: 'A jelszó visszaállítása link lejárt vagy érvénytelen.',
};
