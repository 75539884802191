import React from 'react';
import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import { Trans } from 'react-i18next';
import { P } from '../../../../components/Collection';

const Container = styled.div({
  display: 'flex',
  width: '260px',
  height: '48px',
  padding: '3px',
  borderRadius: '4px',
  margin: '2px 0 0 0',
  background: '#EAF6F6',
  '@media (max-width: 880px)': {
    padding: 'unset',
    background: 'unset',
    position: 'relative',
    margin: '0 0 20px 0 !important',
    width: '100%',
  },
  '&:after': {
    display: 'none !important',
  },
  cursor: 'pointer',
});

const DateContainer = styled.div({
  height: '50px',
  width: '115px',
  margin: 'unset !important',
  '@media (max-width: 880px)': {
    border: '1px solid #78ADAB',
    borderRadius: '4px',
  },
  padding: '2px 10px',
  p: {
    margin: 'unset',
    width: 'max-content',
  },
  '&:first-of-type': {
    margin: '0 10px 0 0 !important',
  },
});

const Label = styled(P)({
  fontSize: '10px',
});

const Close = styled.div({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  cursor: 'pointer',
  svg: {
    display: 'inline-block',
    fill: '#000',
    lineHeight: 1,
    stroke: 'currentColor',
    strokeWidth: 0,
  },
  '@media (max-width: 880px)': {
    display: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: '50px !important',
    top: '3px',
  },
});

const RangeDateSelects = ({
  deleteFilters, handleChange, hasChanges, setFirstSelect, setSecondSelect, firstSelect, secondSelect, handler,
}) => {
  const get = {
    get first() {
      const formatted = {
        day: firstSelect.day.toLocaleString('en-GB', {
          minimumIntegerDigits: 2,
        }),
        month: firstSelect.month.toLocaleString('en-GB', {
          minimumIntegerDigits: 2,
        }),
      };
      return new Date(firstSelect.year, formatted.month - 1, formatted.day);
    },
    get second() {
      const formatted = {
        day: secondSelect.day.toLocaleString('en-GB', {
          minimumIntegerDigits: 2,
        }),
        month: secondSelect.month.toLocaleString('en-GB', {
          minimumIntegerDigits: 2,
        }),
      };
      return new Date(secondSelect.year, formatted.month - 1, formatted.day);
    },
    // eslint-disable-next-line react/no-this-in-sfc
    get firstDate() { return this.first > this.second ? this.second : this.first; },
    // eslint-disable-next-line react/no-this-in-sfc
    get secondDate() { return this.first > this.second ? this.first : this.second; },
  };

  const clearDates = () => {
    setFirstSelect(false);
    setSecondSelect(false);
    deleteFilters();
    handleChange({ ...hasChanges, month: false });
  };

  return (
    <Container className="form-group form-group--select">
      <DateContainer {...handler && { onClick: handler }}>
        <Label>
          <Trans ns="events" i18nKey="startDate">
            Start Date
          </Trans>
        </Label>
        <P bold small>
          {dateFormat(get.firstDate, 'dd mmm yyyy')}
        </P>
      </DateContainer>
      <DateContainer {...handler && { onClick: handler }}>
        <Label>
          <Trans ns="events" i18nKey="endDate">
            End Date
          </Trans>
        </Label>
        <P bold small>
          {dateFormat(get.secondDate, 'dd mmm yyyy')}
        </P>
      </DateContainer>
      <Close onClick={clearDates}>
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
          {/* eslint-disable-next-line max-len */}
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </Close>
    </Container>
  );
};

export default RangeDateSelects;
