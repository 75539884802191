import React from 'react';
import { Trans } from 'react-i18next';
import { CheckmarkEllipse, ConfirmModalContainer, InfoIcon } from './styles';
import LoadImage from '../../../../../../../components/common/LoadImage';
import { ModalBackground } from '../../../../../../../components/Layout/components/Header/components/RaiseHandStyles';
import { Button, Contain, P } from '../../../../../../../components/Collection';
import Match from '../../../../../components/MatchFormSearchBar/components/PlayerOption';

const ConfirmModal = ({
  match,
  handler,
  played,
}) => {
  const {
    opponent, opponentPartner, playerPartner, gameType,
  } = match || {};
  const isPlayed = played ? 'playedMatchForm' : 'matchInviteForm';
  const isDoubles = gameType === 'doubles';

  return (
    <>
      <ModalBackground />
      <ConfirmModalContainer>
        <CheckmarkEllipse src={LoadImage('friendly-matches/match-form/checkmark-ellipse.svg')} alt="Confirm Save" />
        <P bold textAlign="center">
          <Trans ns="friendlyMatches" i18nKey={`${isPlayed}.title`}>
            You successfully saved the Singles match with:
          </Trans>
        </P>

        {playerPartner && (
          <Match
            isDoubles={isDoubles}
            player={playerPartner}
            playerInputPlaceholder
            handler={() => null}
            isPlaceholder
          />
        )}
        <Match isDoubles={isDoubles} player={opponent} playerInputPlaceholder handler={() => null} isPlaceholder />
        {opponentPartner && (
          <Match
            isDoubles={isDoubles}
            player={opponentPartner}
            playerInputPlaceholder
            handler={() => null}
            isPlaceholder
          />
        )}

        <Contain>
          <InfoIcon src={LoadImage('info-icon.svg')} alt="" />
          <P xSmall>
            <Trans ns="friendlyMatches" i18nKey={`${isPlayed}.desc`}>
              The submitted played match and score are pending confirmation
              from the participant(s) in order to be validated
            </Trans>
          </P>
        </Contain>

        <Button onClick={() => handler()} width="100%" margin="23px 0 0 0">
          <Trans ns="friendlyMatches" i18nKey={`${isPlayed}.button`}>
            Got it
          </Trans>
        </Button>
      </ConfirmModalContainer>
    </>
  );
};

export default ConfirmModal;
