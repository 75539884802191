export default {
  seoTitle: 'Nové funkce již brzy | Sportya',

  item1: {
    title: 'Bulharština',
    subtitle: '(Nový jazyk)',
    text: `Usilovně pracujeme na přípravě spuštění verze Sportya v bulharštině. Víme, jak je to důležité. 
        Pracujeme na tom.`,
  },

  item2: {
    title: 'Zprávy pro hráče',
    text: 'Rozhodli jsme se spuštění nového systému zasílání zpráv odložit, protože plánujeme rozšířit jeho propojení s ostatními službami platformy Sportya. Je to nezbytné pro obnovení funkce Přátelská utkání, spolu s novým systémem správy spojení na platformě Sportya.',
  },

  item3: {
    title: 'Přátelská utkání',
    subtitle: '(znovu spustit)',
    text: 'Není to úplně nová funkce a zkušení hráči ji dobře znají. Upgrady, na nichž se pracuje, vám umožní najít partnera pro soutěžní zápas rychleji než dříve a s vyšší mírou relevance pokud se jedná o soutěživost a předpokládané zkušenosti ze zápasů.',
  },

  item4: {
    title: 'Seznam přátel',
    text: 'Bude to nový způsob, jak komunikovat s přáteli na platformě Sportya, rozšiřovat svou síť a udržovat kontakty po celý rok. Jak bylo uvedeno výše, další integrace se systémem zpráv a přátelskými zápasy vám umožní ještě lepší využití tenisového života.',
  },

  item5: {
    title: 'Blog pro Novinky a Aktualizace',
    text: 'Na platformě Sportya věříme, že byste měli být součástí konverzace. Proto bude naše sekce pro Novinky a Aktualizace spuštěna znovu ve formě Blogu. Chystáme se vám přiblížit dění na platformě Sportya a dát vám možnost podílet se na její budoucnosti.',
  },

  item6: {
    title: 'Seznamte se se svým soupeřem',
    subtitle: '(pro čtyřhry)',
    text: 'Snaha najít spoluhráče pro zápasy ve čtyřhrách brzy skončí. Představujeme funkci, na kterou se často ptáte: možnost najít další hráče, kteří mají zájem připojit se k týmu na konkrétní událost. Již brzy na akci nedaleko vás.',
  },

  item7: {
    title: 'Rezervace',
    text: 'Zmenšujeme odstup mezi vámi a kurtem. Sportya Booking je mnohem víc než pouhá rezervace. Očekávejte širokou nabídku kurtů a úzkou integraci s dalšími službami platformy Sportya. Konečný výsledek — utkání připravíte mnohem rychleji než kdykoli předtím, ať už se jedná o přátelské nebo soutěžní klání.',
  },

  item8: {
    title: 'Živé skóre',
    text: 'V prvním cyklu systému obnovíme možnost zadávat výsledky zápasů, a to jak pro ředitele akcí, tak pro hráče.  Rychleji a snadněji než dříve. Nový systém nám v budoucnu umožní vyvíjet softwarová i hardwarová řešení, která změní váš zážitek ze zápasu a umožní vám soustředit se a užívat si hru.',
  },

  item9: {
    title: 'Správa událostí',
    subtitle: '(Kluby a pořadatelé)',
    text: 'Zahájíme proces hluboké integrace se všemi partnerskými kluby platformy Sportya a naše služby propojíme s jejich provozními systémy. To nám postupně umožní vyvíjet nové služby pro hráče a klubům umožní s vámi komunikovat na hlubší úrovni, poskytovat rychlejší a preciznější služby na míru a celkově lepší zážitek pro své členy a zákazníky.',
  },

  comingSoon: 'Již brzy',
  worthTheWait: 'Stojí za to si počkat',

  stayTuned: 'Zůstaňte naladěni na další informace!',
};
