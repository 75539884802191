import React from 'react';

import { ResultsPhase } from './styles';

import Paragraph from '../../../../../components/Paragraph';

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const index = ({ league, key }) => (
  <ResultsPhase league={league}>
    <Paragraph medium bold>
      Group {alphabet[key]}
    </Paragraph>
  </ResultsPhase>
);

export default index;
