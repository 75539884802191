import styled from '@emotion/styled';

export const Container = styled.div`
    text-align: center;
`;

export const ComingSoon = styled.div`
    display: inline-block;
    text-align: center;
    max-width: 480px;

    .worth-the-wait
    {
        font-size: 12px;
        border: 2px solid #eaf6f6;
        border-radius: 15px;
        width: max-content;
        padding: 5px 10px;
        margin: 10px auto;
    }

    h2
    {
        margin-bottom: -10px;
    }
`;

export const Elipse = styled.div`
display: flex;
justify-content: center;
align-items: center;

height: 100px;
width: 100px;

box-shadow: 0px 6px 10px rgba(193, 230, 229, 0.4);
border-radius: 50%;

margin: 20px auto;
`;

export const FeatureBlock = styled.div`
    display: inline-block;
    text-align: center;
    padding: 15px;
    max-width: 329px;

    .worth-the-wait-1
    {
        font-size: 12px;
        border: 2px solid #eaf6f6;
        border-radius: 15px;
        width: max-content;
        padding: 5px 10px;
        margin: 10px auto;
    }

    h4
    {
        margin-bottom: -10px;
    }

    h4+p
    {
        margin-bottom: 20px;
    }
`;
