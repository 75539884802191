export default {
  seoTitle: 'Моите настройки | Sportya',
  cancelRequest: 'Откажи заявката',
  addFriend: 'Добави приятел',
  confirmRequest: 'Приемете заявката',
  friends: 'приятели',
  unfriend: 'Изтриване на приятел',
  profileSettings: {
    title: 'SМоите настройки ',

    playerDetails: {
      title: 'Информация за акаунта',
      passionatePlayer: 'Играеш със Страст',
      accountType: 'Вид Акаунт',
      upgradeToPremium: 'Купете Премиум акаунт',
      subscriptionValidity: 'Валидност на абонамента',
      subscriptionState: 'Без срок на годност',
      since: 'От {{createdAt}}',
      free: 'Безплатен',
      summer: 'Летен абонамент',
      premium: 'Премиум {{period}} акаунт',
      accountMembership: {
        free: 'Безплатен',
        summer: 'Летен абонамент',
        premium: 'Премиум акаунт',
      },
    },

    playerProfile: {
      accountType: {
        free: 'Безплатен акаунт',
        summer: 'Летен абонамент',
        premium: 'Премиум акаунт',
      },
    },

    changePassword: {
      title: 'Промени паролата',
      oldPassword: 'Стара парола',
      newPassword: 'Нова парола',
      confirmNewPassword: 'Потвърждение на новата парола',
      passwordRequirement: 'Вашата нова парола трябва да бъде между 8-30 букви и цифри знака и да включва поне един специален знак.',
      pleaseEnterNewPassword: 'Въведете новата парола',
      passwordsDoNotMatch: 'Паролите не съвпадат.',
      savePassword: 'Запази паролата',
    },

    personalInformation: {
      title: 'Лична информация',
      firstName: 'Име',
      lastName: 'Фамилия',
      year: 'Година',
      month: 'Месец',
      day: 'Ден',
      location: 'Местоположение',
      phoneNumber: 'Телефонен номер',
      invalidPhoneNumber: 'Невалиден телефонен номер',
      clubTitle: 'Професия',
    },

    profileDetails: {
      title: 'Информация за профила',
      generalDetails: 'General Details',
      gameLevels: 'Game Levels',
      singleGameLevel: ' Ниво на игра на Сингъл',
      doublesGameLevel: 'Ниво на игра на Двойки',
      noGameLevel: 'Все още не сте задали своето ниво на игра.',
      buttonSetLevel: 'Задайте своето ниво на игра',
      needOfChange: 'Ако желаете да промените нивото, моля свържете се с нас на <1>contact@sportya.net</1>.',
      dominantHand: 'Дясна ръка',
      left: 'Лява',
      right: 'Дясна',
      shirtSize: 'Размер тениска',
      shirtNone: 'Нито един',
    },

    languageCommunication: {
      title: 'Език & Комуникация',
      language: 'Език',
      ro: 'Румънски',
      en: 'Английски',
      email: 'Имейл',
      newsletter: 'Бюлетин',
      emailChatConversations: 'Имейл известия за чат разговори',
      emailFriendRequests: 'Имейл известия за покани за приятелство',
      emailFriendlyMatches: 'Имейл известия за приятелски мачове',
      subscribe: 'Абонирай се за бюлетина Sportya (възможности за мачове в моя регион, подробности за новите функции и други)',
    },

    availabilitySchedule: {
      title: 'Възможност за игра',
      Mon: 'Пон',
      Tue: 'Вто',
      Wed: 'Сря',
      Thu: 'Чет',
      Fri: 'Пет',
      Sat: 'Съб',
      Sun: 'Нед',
    },

    blockedPlayers: {
      title: 'Блокирани играчи',
      blockImplications: 'След като блокирате някого, този играч вече не може да ви кани на мачове, да започва разговор с вас или да ви добавя като приятел.',
      howToBlock: 'Можете да блокирате играчи, като щракнете върху Блокиране на играч от опциите на техния профил.',
      unblock: 'Разблокирай',
      block: 'Блокирай',
      unblockUser: 'Деблокирай {{unblockName}}',
      blockUser: 'Блокирай {{unblockName}}',
      areYouSureUnblock: 'Сигурни ли сте, че искате да отблокирате <1>{{unblockName}}</1> ?',
      areYouSureBlock: 'Сигурни ли сте, че искате да блокирате <1>{{unblockName}}</1> ?',
      unblockInfo: 'Играчът ще може да ви покани на мачове, да започне разговор с вас или да ви добави като приятел.',
      blockInfo: 'Играчът вече няма да може да ви кани на мачове, да започва разговор с вас или да ви добавя като приятел.',
      blockNote: 'Забележка: Не включва мачове в състезания, в които и двамата участвате.',
      blockPlayer: 'Блокиране на играч',
      unblockPlayer: 'Деблокирайте играча',
      reportPlayer: 'Докладвай играч',
      reportUser: 'Докладвай {{reportName}}',
      reportInfo: 'Моля, изберете причината за сигнала и предоставете допълнителна информация, която ще помогне на нашия екип да разбере по-добре ситуацията. Докладваният играч няма да бъде уведомен.',
      reportButton: 'Докладвай',
      reportAndBlockButton: 'Докладвай и блокирай',
      successReportAndBlockTitle: 'Вие докладвахте и блокирахте този играч',
      successReportText: 'Ще прегледаме вашия доклад и ще се свържем с вас, ако е необходима информация.',
      gotIt: 'Разбрах',
      successReportTitle: 'Вие докладвахте за този играч',
      youBlockedThisPlayer: 'Вие сте блокирали този играч',
      manageBlockedPlayers: 'Управление на блокирани играчи',
    },

    billingData: {
      title: 'Фактурни данни',
      companyName: 'Име на фирмата',
      cui: 'Данъчен регистрационен код',
      regNumber: 'Регистрационен номер',
      fullName: 'Пълно име',
      address: 'Име на улица, номер, етаж и т.н.',
      city: 'Град',
      country: 'Държава',
    },

    reportReasons: {
      reason: 'Причината',
      selectTheReason: 'Изберете Причината',
      harassment: 'Тормоз или малтретиране',
      spam: 'Спам',
      fakeProfile: 'Фалшив профил',
      playerUnderFifteen: 'Играч под 15 години',
      other: 'Друга причина',
    },

    reportTextAreaInfo: 'Моля, предоставете допълнителна информация (по избор)',
  },
};
