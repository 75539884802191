import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { Link } from '../../../Collection';
import mixpanel from '../../../../mixpanel';
import { Item, Nav, List } from './styles';
import SportSelector from "../SportSelector";

const NavComponent = ({
  isLoggedIn,
  mobile,
  whiteNavText,
  className,
  activeSports,
}) => {
  const router = useLocation();
  const { pathname } = router;
  const { t } = useTranslation('header');

  const menuLinks = [
    {
      url: '/events',
      name: t('findEvent'),
      loggedIn: [true, false],
    },
    {
      url: '/friendly-matches',
      name: t('friendlyMatches'),
      loggedIn: [true, false],
    },
    {
      url: '/clubs',
      name: t('bookCourt'),
      loggedIn: [true, false],
    },
    {
      url: '/find-player',
      name: t('findAPlayer'),
      loggedIn: [true],
    },
    {
      url: '/leaderboards',
      name: t('leaderboards'),
      loggedIn: [true],
    },
    {
      url: '/for-players',
      name: t('forPlayers'),
      loggedIn: [false],
    },
    {
      url: '/for-clubs',
      name: t('forClubs'),
      loggedIn: [false],
    },
    {
      url: '/plans',
      name: t('membership'),
      loggedIn: [false],
    },
  ].filter(({ loggedIn }) => loggedIn?.includes(isLoggedIn));

  const trackClick = (item) => {
    if (item.url === 'friendly-matches') {
      mixpanel.track('Click on Friendly Matches from header');
    }
  };

  const baseUrl = i18next.language === 'en' ? '' : `/${i18next.language}`;

  const isActive = (itemUrl) => {
    if (itemUrl === '/friendly-matches') {
      return (pathname.split('/')).includes('friendly-matches');
    }

    if (baseUrl) {
      return `${baseUrl}${itemUrl}` === pathname;
    }
    return itemUrl === pathname;
  };

  return (
    <Nav {...className && { className }}>
      <List mobile={mobile}>
        <SportSelector activeSports={activeSports} />

        {menuLinks.map((item, key) => (
          <Item key={key} active={isActive(item.url)} {...{ whiteNavText, mobile }}>
            <Link to={item.url} onClick={() => trackClick(item)}>
              {item.name}
            </Link>
          </Item>
        ))}
      </List>
    </Nav>
  );
};

export default NavComponent;
