export default {
  thankYouForRegistration: 'Thank you for your registration!',
  congratulations: 'Congratulations!',
  topUpSuccessfully: 'You have successfully added {{ amount }} {{ currency }} to your Virtual Wallet.',
  undefinedMembershipSuccessfully: 'You successfully purchased a new account',
  premiumMembershipSuccessfully: 'You successfully purchased a new Premium {{period}} account',
  addedTo: {
    mainList: "You've been added to the Main List.",
    waitingList: "You've been added to the Waiting List.",
    preRegistrationList: "You've been added to the Sorting List.",
  },
  level: 'Level',
  walletStatus: 'You paid <1>{{ paid }} {{ currency }}</1> from your Virtual Wallet. \n Remaining Balance: <3>{{ walletBalance }} {{ currency }}</3>.',
  goToEvent: 'Go back to Event',
  goToDashboard: 'Go to my Dashboard',
  goToVirtualWallet: 'Go to Virtual Wallet',
  goToMyProfile: 'Go to My Profile',
  failed: 'Could not process payment!',
  'Invalid signature': 'Reason: <1>Invalid Signature</1>',
  invalidSignature: 'Reason: <1>Invalid Signature</1>',
  paymentProcessing: 'Your payment has been authorised and is currently being processed.',
  playFriendlyMatch: 'While you\'re waiting for your competition to start, you can play a <strong>friendly match</strong> with your friends and fellow players on Sportya.',
  yourCourtIsBooked: 'Your court is booked!',
  goToMyBookings: 'Go to My Bookings',
  cancellationWithRefundInfo: 'This Booking can be canceled with a Full Refund up to <1>{{ hours }}h</1> before the Start Time.',
};
