import styled from '@emotion/styled';
import { Button } from '../../../../../../components/Collection';

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 0 60px 0',

  '@media (max-width: 1125px)': {
    padding: '0 0 20px 0',
  },
});

export const MyProfileOptionsContainer = styled.div({
  display: 'flex',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    maxWidth: '350px',
    width: '100%',
  },
});

export const MyProfileButton = styled(Button)({
  padding: '0 20px 0 10px !important',
  '@media (max-width: 800px)': {
    width: '100%',
    '&:last-of-type': {
      margin: '10px 0 0 0',
    },
  },
});

export const VisitedProfileOptionsContainer = styled.div({
  display: 'flex',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    width: '100%',
    '&:last-of-type': {
      margin: '10px 0 0 0',
    },
  },
});

export const VisitedProfileButton = styled(Button)(({ minWidth }) => ({
  padding: '0 20px 0 10px !important',
  ...minWidth && {
    minWidth,
  },
  '@media (max-width: 800px)': {
    width: '100%',
    margin: '0 10px 10px 0 !important',
    '&:last-of-type': {
      margin: 'unset',
    },
  },
}));
