export default {
  title: 'My Events',
  seoTitle: 'My Events | Sportya',
  joined: 'Joined',
  noJoined: 'You have not entered any events yet.',
  noLfp: 'No Matches',
  followed: 'Followed',
  noFollowed: 'You have not followed any events yet.',
  closed: 'Closed',
  noClosed: 'You have not finalized any competition (results validated) yet.',
  withdrawals: 'Withdrawals',
  noWithdrawals: 'You have no withdrawals yet.',
  findEvent: 'Find an Event',
  seeEvent: 'See Event',
  seeCompetition: 'Go to Competition',
  registeredOnMainBoard: 'Joined on Main List',
  registeredInQueueList: 'Joined on Waiting List',
  lookingForPartners: 'Looking for Partners',
};
