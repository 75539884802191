import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BannerContainer } from './styles';
import { Button, H4, P } from '../../../../components/Collection';

const NoResultsBanner = () => {
  const history = useHistory();
  return (
    <BannerContainer>
      <H4>
        <Trans ns="friendlyMatches" i18nKey="playFriendlyMatch">
          Play a Friendly Match
        </Trans>
      </H4>
      <P maxWidth="540px">
        <Trans ns="events" i18nKey="fmBanner.desc">
          While you&apos;re waiting for new events to be announced in your country,
          you can play a friendly match with your friends and fellow players on Sportya
        </Trans>
      </P>
      <Button
        background="#fff"
        color="#10242C"
        padding="20px"
        noBorder
        margin="20px 0 0 0"
        onClick={() => history.push('/friendly-matches')}
      >
        <P bold>
          <Trans ns="friendlyMatches" i18nKey="goToFriendlyMatches">
            Go to Friendly Matches
          </Trans>
        </P>
      </Button>
    </BannerContainer>
  );
};
export default NoResultsBanner;
