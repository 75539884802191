export default {
  forgotPassword: 'Забравих паролата си',
  subTitle: 'Въведете своя имейл по-долу',
  reset: 'Въведете нова парола',
  placeholders: {
    email: 'Имайл адрес',
    password: 'Парола',
    passwordConfirmation: 'Потвърди паролата',
  },
  passwordResetSuccessfully: 'Моля да проверите имайла си относно инструцтии за задаване на нова парола',
  setPassword: 'Задаване на парола',
  setNewPassword: 'Добавете новата парола в полето по-долу.',
  invalidResetPasswordLink: 'Линка за задаване на паролата е изтекъл или невалиден.',
};
