import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  text-align: center;  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  
  .container {
    width: 600px;
    max-width: 100%;
    border: 2px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
    padding: ${ ({ theme }) => theme.functions?.toEm(80) } 15px;
  }
  
  .buttons {
    > div {
      margin: ${ ({ theme }) => theme.functions?.toEm(10) } ${ ({ theme }) => theme.functions?.toEm(5) };
    }
  }

  ${props => props.theme.functions?.minTablet(`
   
  `)}
`;
