export default {
  hero: {
    title: 'Vítá vás Sportya',
    desc: 'O platformě Sportya se dozvíte spoustu věcí a proto začneme tím nejdůležitějším. Projděte si níže uvedeného průvodce, kde se dozvíte, jak fungují zápasy, události a žebříčky.',
  },
  firstPhase: {
    title: 'Co znamená Sportya?',
    desc: `Sportya představuje sportovní soutěžní ekosystém, v němž se desítky
               tisíc amatérských hráčů zapojují do přátelských zápasů,
               lig a akcí, kde si užívají vyrovnanou hru, nebo soutěží
               o nejlepší místa v národních a mezinárodních žebříčcích.`,
    articles: {
      1: {
        title: 'Objevujte',
        desc: 'Jakmile se připojíte k platformě Sportya, stanete se součástí velké a nadšené komunity amatérských hráčů a získáte přístup k akcím pořádaným ve stovkách tenisových klubů. Svou působnost rozšiřujeme na nové regiony a země, čímž vám přinášíme větší rozmanitost pro každou cestu, jakou si vyberete. Můžete hrát v místním partnerském klubu nebo poznávat a hledat nové zkušenosti po celém světě.',
      },
      2: {
        title: 'Výzva',
        desc: 'Začněte s několika přátelskými zápasy, pokud je pro vás důležitá časová flexibilita, přidejte se k místní lize nebo se rovnou vrhněte do soutěžních utkání a probojujte se na vrchol národního a mezinárodního žebříčku amatérských hráčů.',
      },
      3: {
        title: 'Komunikace',
        desc: 'Udržujte kontakty se svými přáteli, seznamte se s novými a sdílejte své zážitky. Vytvořte si a předveďte svůj hráčský profil a prožívejte svůj tenisový život naplno.',
      },
      4: {
        title: 'Úroveň hry',
        subtitle: 'Hrajte vyrovnané zápasy',
        desc: 'Každý člen platformy Sportya hraje na určité herní úrovni. Tato úroveň určuje akce, do nichž se můžete zapojit, a usnadňuje správné zařazení nejen v soutěžních turnajích, ale i ve společenských přátelských zápasech.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 herních úrovní',
      desc: 'Vítáme všechny hráče, od začátečníků až po ostřílené borce. Při vytváření účtu zvažte svou herní úroveň, vyrazte ven a užijte si vyrovnané zápasy. Nabízíme 6 herních úrovní na základě kombinace schopností a zkušeností.',
    },
    2: {
      title: 'Úrovně pro dvouhry a čtyřhry',
      desc: 'Na platformě Sportya je řešíme odděleně. Na počátku si nastavíte úroveň hry, která odpovídá vašemu hernímu potenciálu ve dvouhře. Tato úroveň bude automaticky vaší počáteční i pro zápasy ve čtyřhře. Obě tyto úrovně se budou automaticky vyvíjejí spolu s vámi.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Místní liga',
      subtitle: 'Zachovejte flexibilitu hry',
      desc: 'Místní ligy vám umožní odehrát jeden zápas týdně podle flexibilního časového rozvrhu a zároveň zachovat ducha soutěže. Registrace do místních lig je na platformě Sportya v současné době ZDARMA.',
    },
    2: {
      title: 'Přátelská utkání',
      subtitle: 'Vyhledejte nové herní partnery',
      desc: 'Přátelská utkání jsou nejflexibilnější cestou, jak si na platformě Sportya vyzkoušet tenis. Přátelské zápasy s ostatními členy Sportya můžete hrát kdykoli a kdekoli. Náš systém párování vám pomůže vyhledat vhodného herního partnera, najít vyhovující místo a nahlásit výsledek online, což přispěje k vašemu hodnocení a hráčskému profilu. Některé funkce jsou v současné době ve vývoji, ale stojí za to na ně počkat.',
    },
    comingSoon: 'Co je nového v r. 2023',
    comingSoonFM: 'Co je nového v r. Q1 2023',
    inDevelopment: 'In Development',
  },
  fifthPhase: {
    title: 'Žebříčkové soutěže',
    subtitle: 'Váš nejlepší zápas čeká',
    desc: 'Hlavní akce platformy Sportya, Žebříčkové soutěže, jsou organizovány v souvislém ročním kalendáři, který vám umožní soutěžit v místních a globálních amatérských žebříčcích a usilovat o nejvyšší příčky. Pro amatérské hráče je Sportya tím, čím je ATP/WTA pro profesionály.',
    articles: {
      1: {
        title: 'Kategorie hodnocených událostí',
        desc: 'První podání, Stříbro, Zlato, Platina, Speciální turnaj a Turnaj mistrů. Každá z nich má v kalendáři svou specifickou úlohu a požadavky na vstup se u jednotlivých kategoriích liší. Pro každou událost pořádáme jednu nebo více soutěží podle herních úrovní, buď pro dvouhry nebo pro čtyřhry.',
      },
      2: {
        title: 'Formáty událostí',
        desc: 'Můžete se těšit na celou řadu formátů, počínaje klasickými vyřazovacími turnaji pořádanými během víkendu až po akce skupinového formátu, noční akce a další.',
      },
      3: {
        title: 'Formáty zápasů',
        desc: 'Většina zápasů v žebříčkových soutěžích Sportya se hraje na 2 tiebreakové sety s tiebreakem (10 bodů) na 1 set.',
      },
      4: {
        title: 'Balíček pro účast',
        desc: 'Kromě přístupu na událost a všechny s ní související důsledky, která se koná v rámci platformy Sportya (koordinace události, výsledky, žebříčky, aktualizace aktivit, péče o zákazníky atd.), vstupní poplatky ve Sportya obvykle zahrnují 2 nové prémiové tenisové míče přidělené na zápas a láhev vody na zápas.',
      },
    },
  },
  sixthPhase: {
    title: 'Pusťte se do hry',
    desc: 'Koneckonců vždyť se jedná o to, že podporujeme zábavné a přitažlivé soutěžení, které je základem sportu, a které nakonec vede k postupu ve hře i v každodenním životě.',
  },
  playVideo: 'Přehrát video',
};
