import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';
import { DayPicker } from 'react-day-picker';
import { isEmpty } from 'lodash';
import {
  bg,
  cs,
  enUS,
  hu,
  ro,
} from 'date-fns/locale';

import {
  CalendarInfo,
  DatePickerContainer,
  Container,
  CalendarWrapper,
} from './styles';
import { PopUp, PopUpContainer } from '../../../../../components/PlayerRating/styles';

import { fetchClubBookings } from '../../../reducers';

import { H3, Paragraph } from '../../../../../components';
import LoadImage from '../../../../../components/common/LoadImage';
import ToggleVisible from '../../../../../components/common/ToggleVisible';
import getCountryIsoCode from '../../../../../components/common/getCountryIsoCode';
import HandleCourt from './components/handleCourt';
import BookCourtModal from '../../../Listing/components/bookCourtModal';
// import getCourtsWorkingHours from './helpers/getCourtsWorkingHours';
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import CalendarOnMobile from './components/CalendarOnMobile';
import Select from '../../../../../components/Form/Select';

const Calendar = ({ clubInfo }) => {
  const { id: clubId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountInfo, isLoggedIn, masterSport } = useSelector((state) => state.session);
  const { t } = useTranslation(['common', 'clubs']);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { data: bookings, status } = useSelector((state) => state.clubs?.info?.bookings);
  const { data: { activeSports } = {} } = useSelector(state => state.settings);
  const [bookingInfo, setBookingInfo] = useState({});
  const [sportType, setSportType] = useState(masterSport);

  let { date } = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  if (date) date = dateFormat(date, 'yyyy-mm-dd');

  useEffect(() => {
    if (!date) {
      history.push({
        pathname: history.location.pathname,
        search: `?date=${dateFormat(new Date(), 'yyyy-mm-dd')}`,
      });
    }
  }, [date]);

  useEffect(() => {
    if (date) {
      dispatch(
        fetchClubBookings({
          clubId,
          date,
          status: 'confirmed',
        }),
      );
    }
  }, [date]);

  // redirect to login if not authenticated
  useEffect(() => {
    if (!isEmpty(bookingInfo) && !isLoggedIn) {
      const pathName = (history.location.pathname || '')
        .split('/')
        .filter((v) => v)
        .join('~');
      return history.push(`/login/${pathName}`);
    }
  }, [bookingInfo]);

  useEffect(() => {
    if (masterSport) {
      setSportType(masterSport);
    }
  }, [masterSport]);

  const { courts = [], bookingSettings } = clubInfo;
  const activeCourts = courts.filter((court) => court.sportType === sportType);

  /*
  const day = new Date(date).getDay() || 7;
  const activeCourts = courts.filter((court) => (
    court?.status === 'active' && (court.availability || []).find((av) => av.day === day && av.enabled === true)
  ));
  const workingHours = getCourtsWorkingHours(courts, date);

  if (isEmpty(workingHours)) return (<></>);
  */

  const workingHours = {
    startHour: 6,
    endHour: 24,
  };
  const courtsHours = hours(workingHours.startHour, workingHours.endHour);
  const bookingHours = hours(
    workingHours.startHour,
    workingHours.endHour,
    true,
  );

  const maxDateScheduleExceeded = bookingSettings?.maxDateSchedule
    && new Date(bookingSettings?.maxDateSchedule) < new Date(date);

  return (
    <CalendarWrapper>
      <div className="desktop-only">
        <div className="heading d-flex align-middle justify-content-between">
          <H3>
            <Trans ns="clubs" i18nKey="bookACourt" context={masterSport}>
              Book a Court
            </Trans>
          </H3>
          <div className="select-wrapper">
            <Select
              name="sportType"
              placeholder={t('sportType', { ns: 'clubs' })}
              onChange={({ value }) => setSportType(value)}
              options={activeSports.map((sport) => ({
                label: t(`sports.${sport}`),
                value: sport,
              }))}
              width="150px"
              height="50px"
              label={t('sportType', { ns: 'clubs' })}
              isSearchable
              value={sportType && {
                label: t(`sports.${sportType}`),
                value: sportType,
              }}
              alignLabel
              lowInput
              className="select-time"
            />
          </div>
        </div>
        <Container ref={ref}>
          {isComponentVisible && (
            <DatePickerContainer>
              <DayPicker
                mode="single"
                required
                showOutsideDays
                locale={locale(accountInfo?.location?.country)}
                selected={new Date(date)}
                onSelect={(value) => {
                  const formattedDate = dateFormat(value, 'yyyy-mm-dd');

                  history.push({
                    pathname: history.location.pathname,
                    search: `?date=${formattedDate}`,
                  });

                  setIsComponentVisible(!isComponentVisible);
                }}
                {...datePickerInterval(bookingSettings)}
              />
            </DatePickerContainer>
          )}
          <table className="table-responsive">
            <thead>
              <tr>
                <th
                  colSpan={4}
                  align="center"
                  onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                  <Paragraph bold>
                    {dateFormat(date, 'dd mmm')}
                    <img
                      src={LoadImage('arrow-down.svg')}
                      alt=""
                      className="ml5"
                    />
                  </Paragraph>
                </th>
                {courtsHours.map((hour, key) => (
                  <th align="center" colSpan={2} key={key}>
                    {hour}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {status === 'succeeded'
                && activeCourts.map((court) => (
                  <HandleCourt
                    key={court._id}
                    court={court}
                    bookings={bookings}
                    lastBookingHour={Number(workingHours.endHour)}
                    bookingHours={bookingHours}
                    setBookingInfo={setBookingInfo}
                    maxDateScheduleExceeded={maxDateScheduleExceeded}
                  />
                ))}
            </tbody>
          </table>
          {status === 'loading' && (
            <div className="text-center p10">
              <LoadingSpinner height="40px" />
            </div>
          )}
          {status === 'succeeded' && isEmpty(activeCourts) && (
            <Paragraph className="text-center p10">
              <Trans ns="clubs" i18nKey="noAvailableCourts">
                No available courts for this interval.
              </Trans>
            </Paragraph>
          )}
          {status === 'error' && (
            <Paragraph className="text-center p10">
              <Trans ns="clubs" i18nKey="couldNotFetchCourtsBookings">
                Courts bookings couldn&apos;t be fetched. Try again.
              </Trans>
            </Paragraph>
          )}
        </Container>
        <CalendarInfo>
          <div>
            <Paragraph smaller>
              <span className="available" />
              <Trans ns="clubs" i18nKey="available">
                Available
              </Trans>
            </Paragraph>
            <Paragraph smaller>
              <span className="not-available" />
              <Trans ns="clubs" i18nKey="notAvailable">
                Not available
              </Trans>
            </Paragraph>
            <Paragraph smaller>
              <span className="your-booking" />
              <Trans ns="clubs" i18nKey="yourBooking">
                Your booking
              </Trans>
            </Paragraph>
          </div>
          <div className="d-flex gap-10">
            {bookingSettings?.selfService && (
              <PopUpContainer inline>
                <PopUp>
                  <Trans ns="clubs" i18nKey="selfServiceCourtsPopupDescription">
                    Players must make their courts at the end of the matches to
                    leave them in good condition for the next matches.
                  </Trans>
                </PopUp>
                <Paragraph smaller className="d-flex align-middle">
                  <img
                    src={LoadImage('clubs/self-service-icon.svg')}
                    alt=""
                    className="mr5"
                  />
                  <Trans ns="clubs" i18nKey="selfServiceCourts">
                    Self-Service Courts
                  </Trans>
                </Paragraph>
              </PopUpContainer>
            )}
            {bookingSettings?.minCancellationTime && (
              <PopUpContainer inline>
                <PopUp>
                  <Trans
                    ns="clubs"
                    i18nKey="cancellationWithRefundPopupDescription"
                  >
                    Bookings at this Club can be canceled up to
                    {{ hours: bookingSettings?.minCancellationTime }} hours
                    before the Start Time.
                  </Trans>
                </PopUp>
                <Paragraph smaller className="d-flex align-items-center">
                  <img
                    src={LoadImage('clubs/refund-icon.svg')}
                    alt=""
                    className="mr5"
                  />
                  <Trans ns="clubs" i18nKey="cancellationWithRefund">
                    Cancellation with Refund - up to
                    <strong className="ml5">
                      {{ hours: bookingSettings?.minCancellationTime }} hours
                    </strong>
                  </Trans>
                </Paragraph>
              </PopUpContainer>
            )}
          </div>
        </CalendarInfo>
      </div>

      <div className="mobile-only">
        <CalendarOnMobile
          date={date}
          idClub={clubInfo?._id || clubInfo?.id}
          clubInfo={clubInfo}
          bookings={bookings}
          bookingHours={bookingHours}
          setBookingInfo={setBookingInfo}
        />
      </div>

      {!isEmpty(bookingInfo) && isLoggedIn && (
        <BookCourtModal
          isOpen={!isEmpty(bookingInfo)}
          bookingInfo={bookingInfo}
          onRequestClose={() => setBookingInfo({})}
        />
      )}
    </CalendarWrapper>
  );
};

const locale = (country) => {
  const { language = 'en' } = getCountryIsoCode(country);
  const locales = {
    ro,
    bg,
    hu,
    cz: cs,
    en: enUS,
  };
  return locales[language] || enUS;
};

const hours = (startHour = 6, endHour = 24, half = false) => {
  const dateTime = new Date();
  const intervals = [];

  for (let i = startHour; i < endHour; i++) {
    dateTime.setHours(i);
    dateTime.setMinutes(0);
    if (!half) {
      intervals.push(dateFormat(dateTime, 'HH'));
    }

    if (i < endHour && half) {
      intervals.push(dateFormat(dateTime, 'HH:00'));

      dateTime.setHours(i);
      dateTime.setMinutes(30);
      intervals.push(dateFormat(dateTime, 'HH:MM'));
    }
  }

  return intervals;
};

const datePickerInterval = ({ bookInAdvance, maxDateSchedule }) => {
  const today = new Date();
  const now = new Date();
  now.setDate(now.getDate() + bookInAdvance);
  let toDate = new Date(now);

  if (maxDateSchedule) {
    const maxDate = new Date(maxDateSchedule);
    if (maxDate < toDate) {
      toDate = new Date(maxDateSchedule);
    }
  }

  return {
    fromDate: today,
    toDate,
  };
};

export default Calendar;
