const formatFacilities = (court, t) => {
  const arr = [];
  arr.push(...[
    t(`courtSizes.${court.size}`),
    t(`surfaceType.${court.surface}`),
    t(`courtTypes.${court.type}`),
  ]);

  let str = arr.join(", ");
  if (court?.hasLighting) {
    str += ` (${t('withLighting')})`;
  }

  return str;
};

export default formatFacilities;
