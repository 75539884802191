import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import mixpanel from '../../mixpanel';

import {
  Container,
  SubscriptionsHeader,
  BenefitsStyle,
  PlansStyle,
  ActivePlan,
} from './styles';

import H1 from '../../components/H1';
import H4 from '../../components/H4';
import Paragraph from '../../components/Paragraph';
import BenefitsList from './Components/BenefitsList';
import Plan from './Components/Plan';
import LoadImage from '../../components/common/LoadImage';
import ContentLoader from '../../components/ContentLoader';
import { store as socketStore } from '../../components/Socket/store';

const Plans = () => {
  const { accountInfo } = useSelector(state => state.session);
  const membership = accountInfo?.membership?.plan;
  const memberships = useSelector(state => state.settings?.data?.memberships);
  const expDate = dateFormat(accountInfo?.membership?.endDate, 'd mmmm yyyy', true);
  const currency = accountInfo?.currency || 'EUR';
  const { t } = useTranslation('subscriptions');
  const activePlans = memberships?.filter(plan => plan?.status === 'active');
  const { addToast } = useToasts();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const { t: commonT } = useTranslation('common');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const getAvailability = days => {
    const hasPlan = accountInfo?.membership?.plan !== 'free';
    const start = hasPlan ? new Date(accountInfo?.membership?.endDate) : new Date();
    const end = hasPlan ? new Date(accountInfo?.membership?.endDate) : new Date();

    const plan = {
      start: start.setDate(start.getDate() + (hasPlan ? 1 : 0)),
      end: end.setDate(end.getDate() + days + (hasPlan ? 1 : 0)),
    };

    return {
      start: dateFormat(plan.start, 'd mmm yyyy'),
      end: dateFormat(plan.end, 'd mmm yyyy'),
    };
  };

  useEffect(() => {
    mixpanel.track('Visit Membership', {
      sp_visit_membership_logged_in: !!accountInfo,
      sp_visit_membership_city: accountInfo?.location?.city,
      sp_visit_membership_country: accountInfo?.location?.country,
    });
  }, []);

  useEffect(() => {
    const listenForResponse = async ({ success, data, message }) => {
      if (success) {
        if (data.paymentType === 'virtualWallet') {
          addToast(commonT('PREMIUM_MEMBERSHIP_UPGRADE', { period: message.replace(/[^0-9]/g, '') }), {
            appearance: 'success',
            autoDismiss: true,
          });
          return history.push(
            `/${accountInfo?.language}/payment/confirmation/${data.membership.id}/${data.method}/virtual-wallet`,
          );
        }
        window.location.replace(data.url);
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      setIsLoading(false);
    };

    if (socket) {
      socket.removeAllListeners('payments-response');
      socket.on('payments-response', listenForResponse);
      return () => socket.removeAllListeners('payments-response');
    }
  }, [socketClientId]);

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>

      <SubscriptionsHeader>
        <H1><Trans ns="subscriptions" i18nKey="title">Get a Premium Account</Trans></H1>
        <Paragraph medium>
          <Trans ns="subscriptions" i18nKey="subtitle">
            To benefit from all the functionalities Sportya has to offer
            and save on tournament entry fees, choose one of the
            <strong>premium</strong>
            {' '}
            accounts available.
          </Trans>
        </Paragraph>
      </SubscriptionsHeader>
      {membership === 'premium' && (
        <ActivePlan>
          <img src={LoadImage('checkmark-black.svg')} alt="check" />
          <Paragraph small>
            <Trans ns="subscriptions" i18nKey="activePlan" values={{ expDate }}>
              Your <strong>Premium</strong> plan is active until <strong>{expDate}</strong>
            </Trans>
          </Paragraph>
        </ActivePlan>
      )}

      <PlansStyle>
        <Plan
          image={LoadImage('free-plan-icon.svg')}
          period={0}
          perks={[
            {
              text: t('plans.free.1'),
              type: t('premium'),
            },
            {
              text: t('plans.free.2'),
              type: t('blue'),
            },
            {
              text: t('plans.free.3'),
              type: t('red'),
            },
          ]}
          price={t('plans.free.price')}
          title={t('plans.free.title')}
          included
          type={t('free')}
          isFree
          currentPlan={accountInfo?.membership?.plan === 'free'}
          accountInfo={accountInfo}
        />

        {activePlans ? activePlans.map((plan, key) => (
          <Plan
            key={key}
            image={LoadImage('premium-time-plan.svg')}
            period={plan?.name?.split('-')[1]}
            perks={[
              { text: t('plans.premium.1') },
              { text: t('plans.premium.2') },
              { text: t('plans.premium.3') },
            ]}
            id={plan?.id}
            type="premium"
            price={Number(plan?.discountedPrice?.[currency] ?? plan?.price?.[currency]).toFixed(2)}
            oldPrice={plan?.discountedPrice?.[currency] && Number(plan?.price?.[currency]).toFixed(2)}
            currency={currency}
            expDate={t('plans.premium.expDate')}
            timePeriod={`${getAvailability().start} - ${getAvailability(plan?.activeDays).end}`}
            title={t('plans.premium.title')}
            info={t('plans.premium.info')}
            extend={accountInfo?.membership?.plan !== 'free'}
            accountInfo={accountInfo}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ))
          : (
            <>
              <ContentLoader />
              <ContentLoader />
            </>
          )}
      </PlansStyle>

      <BenefitsStyle>
        <H4><Trans ns="subscriptions" i18nKey="benefits.title">Benefits included in all accounts</Trans></H4>
        <div className="benefitsWrapp">
          <div className="benefits">
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.1">Personal page (profile) detailing the activity</Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.2">National ranking for amateurs</Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.3">
                10% discount TENNIS WAREHOUSE EUROPE, the largest specialized retailer in Europe, for all purchases
              </Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.4">
                Game package, insured for participation in each tournament (new balls, products offered by sponsors)
              </Trans>
            </BenefitsList>
          </div>

          <div className="benefits">
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.5">Partner matches with any member of the site</Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.6">Personal messaging, notification system</Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.7">
                View the activity of all players and comparison system between players
              </Trans>
            </BenefitsList>
            <BenefitsList>
              <Trans ns="subscriptions" i18nKey="benefits.8">Technical support (by e-mail)</Trans>
            </BenefitsList>
          </div>
        </div>

        <div className="subscriptionsInfo">
          <Paragraph smaller>
            <Trans ns="subscriptions" i18nKey="info.feeReduction">
              1. Discounted entries for Summer / Premium Accounts are event dependent. A typical discount
              for a GOLD tournament organized in Romania is 25 LEI off the full price. For SILVER tournaments
              organized in Romania, the discount is 15 LEI. All events in Bulgaria will receive a
              discount of 10 BGN for Premium accounts, in 2021. Additional discounts may apply for certain
              calendar events, such as special format events, Platinum category tournaments, international
              tournaments, etc
            </Trans>
          </Paragraph>
          {/* <Paragraph smaller>
          <Trans ns='subscriptions' i18nKey='info.seniorMember'>2. After participating in 10 tournaments
          during a year (January 1 - December 31) players with free account benefit from free activation
          of the Premium account.</Trans></Paragraph> */}
        </div>

      </BenefitsStyle>
    </Container>
  );
};

export default Plans;
