import styled from '@emotion/styled';

export const TableStyle = styled.div`
    border:1px solid ${({ theme }) => theme.colors?.lightBlue2};
    border-radius:10px;
    padding:20px 30px 24px 30px;

      @media screen and (max-width:1024px){
    .headerCard{
      .player{
        max-width:300px !important;
      }
    }

    .headerCardFriend{
      .playerFriend{
        max-width:300px !important;
      }
    }
  }

  

 @media screen and (max-width:768px){
  overflow:scroll;
  ::-webkit-scrollbar {
    height:2px !important;
  }

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme?.colors?.lightBlue};;
  outline: none;
}
  .headerCard{
    width:716px;

    .player{
      width:280px !important; 
    }
  }

  .headerCardFriend{
    width:916px;

    .playerFriend{
      width: 280px !important;
    }
  }

}

  .headerCard{
    display:flex;
    justify-content:space-between;
    margin-bottom:20px;

    p{
      margin:0;
    }
  }

  .headerCardFriend{
    display:flex;
    justify-content:space-between;
    margin-bottom:20px;

    p{
      margin:0;
    }
  }

    .rank{
      max-width: 60px;
      width: 100%;
      margin-right: 20px;
    }

    .rankFriend{
      max-width: 47px;
      width: 100%;
    }

    .player{
      max-width: 547px;
      width: 100%;
    }

    .playerFriend{
      max-width: 450px;
      width: 100%;
    }
    
    .points{
      max-width: 50px;
      width: 100%;
      text-align: center;
    }

    .pointsFriend{
      max-width: 50px;
      width: 100%;
      text-align: center;
    }

    .generalRankFriend{
      max-width: 75px;
      width: 100%;
      text-align: center;
    }

    .participations{
      max-width: 90px;
      width: 100%;
      text-align: center;
    }

    .participationsFriend{
      max-width: 50px;
      width: 100%;
      text-align: center;
    }

    .elo{
      max-width: 80px;
      width:100%;
      text-align: center;
    }

    .friendlyMatches{
      max-width: 150px;
      width: 100%;
      text-align: center;
    }

    .friendlyMatchesFriend{
      max-width: 110px;
      width: 100%;
      text-align: center;
    }
`;
