import React from 'react';
import styled from '@emotion/styled';
import { P, Contain } from '../Collection';

const SwitchContainer = styled.div(props => ({
  display: 'flex',
  padding: '3px',
  alignItems: 'center',
  height: '22px',
  minWidth: '38px',
  marginRight: '10px',
  background:
        props.active ? props.theme.colors.primary
          : '#C1E6E5',
  borderRadius: '20px',
  cursor: 'pointer',
  transition: 'all 1s ease',
}));

const SwitchButton = styled.div(props => ({
  width: '17px',
  height: '16px',
  borderRadius: '50%',
  background: '#fff',
  transition: 'all .3s ease',
  ...props.active && {
    transform: 'translateX(90%)',
  },
}));

const ToggleButton = ({
  children, active, handler, className,
}) => (
  <Contain
    {...className && { className }}
    align="center"
  >
    <Contain {...handler && { onClick: handler }}>
      <SwitchContainer active={active}>
        <SwitchButton active={active} />
      </SwitchContainer>
    </Contain>
    <P>{children}</P>
  </Contain>
);

export default ToggleButton;
