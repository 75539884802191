import styled from '@emotion/styled';
import { Button, P } from '../../../../../../components/Collection';
import LoadImage from '../../../../../../components/common/LoadImage';

export const MatchInvitationContainer = styled.div({});

export const MatchRankTypeContainer = styled.div({
  display: 'flex',
  '@media (max-width: 450px)': {
    flexDirection: 'column',
  },
});

export const MatchRankType = styled.div(({ isSelected }) => ({
  borderRadius: '4px',
  border: '2px solid #EAF6F6',
  padding: '20px',
  width: '100%',

  userSelect: 'none',
  cursor: 'pointer',

  '&:first-of-type': {
    margin: '0 12px 0 0',

    '@media (max-width: 450px)': {
      margin: '0 0 15px 0',
    },
  },

  '&:hover': {
    span: {
      '&:after': {
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        background: '#000',
        display: 'block',
      },
    },
  },

  ...isSelected && {
    border: '2px solid #10242C',
  },
}));

export const MatchFormTypesTitle = styled(P)({
  textAlign: 'center',
  margin: '20px auto 10px auto',
});

export const CheckboxContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 15px 0',

  '> div': {
    margin: 'unset',
  },
});

export const MatchOptionIcon = styled.img({
  width: '22px',
  height: '22px',
  objectFit: 'none',
  margin: '0 8px 0 0',
});

export const MatchOptionTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 10px 0',
});

export const MatchOptionsContainer = styled.div({
  display: 'flex',
  margin: '0 0 10px 0',
});

export const MatchOption = styled.div(({ isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',

  borderRadius: '4px',
  background: isSelected ? '#EAF6F6' : '#fff',
  color: isSelected ? '#10242C' : '#78ADAB',
  padding: '0 20px',
  height: '40px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',

  '&:first-of-type': {
    margin: '0 10px 0 0',
  },
}));

export const MatchOptions = styled.div(({ locationType }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '50px 0',
  ...locationType && {
    marginTop: 20,
  },
  ...locationType === 'bookACourt' && {
    marginBottom: 10,
  },

  '.form-group': {
    margin: 'unset',
    width: '100%',
  },
}));

export const MatchFormButtons = styled(Button)(({ first, second }) => ({
  padding: '0 45px',
  minWidth: '150px',
  '@media (max-width: 450px)': {
    order: first ? 1 : second ? 2 : 'unset',
    ...first && {
      margin: '0 0 15px 0',
    },
    ...!first && !second && {
      '&:first-of-type': {
        margin: '0 0 15px 0',
      },
    },
  },
}));

export const MatchFormButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 450px)': {
    flexDirection: 'column',
  },
});

export const MatchFormSearchBar = styled.input({
  width: '100%',
  height: '40px',
  border: '1px solid #78ADAB',
  borderRadius: '4px',
  padding: '20px 10px',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',

  color: '#10242C',
  '::placeholder': {
    textOverflow: 'ellipsis',
    opacity: '100%',
    color: '#10242C',
  },
  ':focus': {
    outline: 'none',
    outlineWidth: 0,
    '::placeholder': {
      color: 'transparent',
    },
  },
  '&:hover': {
    borderColor: 'hsl(0, 0%, 70%)',
  },
});

export const MatchFormSearchBarContainer = styled.div({
  position: 'relative',
  '&:last-of-type': {
    margin: '10px 0 0 0',
  },
});

export const SearchBarContainer = styled.div({
  position: 'relative',
});

export const TeamsContainer = styled.div(({ margin }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: margin || '20px 0 0 0',
}));

export const TeamTitle = styled(P)({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 10px 0',
});

export const Team = styled.div({
  '&:first-of-type': {
    margin: '0 0 20px 0',
  },
});

export const SetDateAndTimeContainer = styled.div({
  display: 'flex',
  width: '100%',

  '.form-group': {
    '&:first-of-type': {
      margin: '0 10px 0 0',
    },
  },

  '@media (max-width: 480px)': {
    margin: '10px 0 0 0',
  },
});

export const DateAndTimeContainer = styled.div({
  position: 'relative',
  display: 'flex',

  '@media (max-width: 480px)': {
    flexDirection: 'column',
  },
});

export const AverageLevel = styled.div(({ hasTenth }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0 0 5px',
  width: hasTenth ? '25px' : '19px',
  height: '20px',
  background: `url(${LoadImage(`friendly-matches/match-form/average-level-${hasTenth ? 'doubles' : 'singles'}.svg`)})`,
  backgroundRepeat: 'no-repeat',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',
  padding: '0 1px 0 0',
}));

export const DateLabel = styled.label({
  position: 'absolute',
  left: '10px',
  top: '5px',
  fontSize: '10px',
  lineHeight: '13px',
  fontWeight: 300,
});

export const StartDateContainer = styled.div(({ hasMargin, hasError, isDisabled }) => ({
  position: 'relative',

  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  width: '100%',
  height: '50px',
  border: '1px solid #78ADAB',
  borderRadius: '4px',
  padding: '0 15px',
  color: '#10242C',

  ...hasMargin && {
    margin: '0 10px 0 0',
  },

  ':focus': {
    outline: 'none',
    outlineWidth: 0,
  },
  '::placeholder': {
    textOverflow: 'ellipsis',
    opacity: '100%',
    color: '#10242C',
  },
  '&:hover': {
    border: '1px solid hsl(0, 0%, 70%)',
  },

  ...hasError && {
    border: '1px solid red',
  },

  ...isDisabled && {
    background: 'hsl(0, 0%, 95%)',
    color: 'hsl(0, 0%, 60%)',
    cursor: 'default',
    userSelect: 'none',
    touchAction: 'none',
  },
}));

export const SelectedDateValue = styled.span({
  position: 'absolute',
  bottom: '10px',
  left: '10px',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
});

export const ChangeCountry = styled.span({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#78ADAB',
});
