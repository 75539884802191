export default {
  unregistered: {
    title: 'Join the Sportya tennis community. Play your best match!',
    subtitle: 'Create a FREE account and enter Silver events* with 100% DISCOUNTED Sportya Service Fee** all summer long',
    eventsTotal: 'Events in your country',
    playersTotal: 'Over 26.000 Players in your region',
    list: {
      item1: 'National calendar of tennis events for amateurs',
      item2: 'Level-based play from beginners to experienced',
      item3: 'Personal player profiles and more',
      item4: 'Match format: 2 Tie-Break sets with Match Tie-Break at 1 set all',
    },
  },
  free: {
    title: 'Get Premium and join Silver & Gold* events with 100% discounted Sportya Service Fee**',
    subtitle: 'Access top-tier events like Platinum & Champions Tournament.',
    eventsTotal: '{{amount}} Events in your country',
    list: {
      item1: {
        title: 'Top tier events',
        desc: 'Join us at Grand Slam-like events for amateurs, with up to 200 players, in 6 countries. Rise in the rankings and compete with the best, at the  Champions Tournament.',
      },
      item2: {
        title: 'Great savings',
        desc: 'The more you play, the more you save. Get discounts up to 12$ with each entry.',
      },
    },
  },
  readme: `*1st Serve, Silver, Gold, Platinum, Special, and Champions Tournament. Each event category offers different rewards in points and prizes, and provides a different level of challenge. For each event, we organize one or more competitions based on Game Levels, either for Singles or Doubles. 
**Sportya Service Fee is an online fee that represents the value of the services offered by the site in connection with the event. This helps us run our platform and offer services like customer support.`,
  rjt: {
    title: 'România Joacă Tenis la Dublu',
    date: '10 - 30 Noiembrie 2022',
    desc: 'Taxa de rezervare gratuită pentru conturile PREMIUM <1>(Discount 45 RON)</1>',
    active: 'Activează-ți contul PREMIUM și ai <1>45 RON discount</1> la înscrierea online (taxa de rezervare gratuită)',
    getPremium: 'Ia-ți Contul Premium',
  },
};
