/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-len */
/* eslint-disable no-tabs */

import React from 'react';
import styled from '@emotion/styled';

const ButtonStyle = styled.div`
position: relative;
text-align: center;
background: ${props => ({ theme }) => (props.outline ? theme.colors?.white // outline bg
  : (props.red ? theme.colors?.third // red bg
  	: (props.transparent ? 'transparent' // transparent bg
    	: theme.colors?.black // default black bg
    )))
};
border-radius: ${ ({ theme }) => theme.functions?.toEm(4) };
display: ${props => (props.fullwidth || props.block ? 'flex' : 'inline-flex')};
width: ${props => (props.contain ? 'max-content' : props.noSetWidth ? 'auto' : props.fullwidth ? '100%' : props.modalSmallButton ? '195px' : props.width ?? 'auto')};
transition: all 0.3s ease;
box-shadow: inset 0 0 0 2px ${props => ({ theme }) => (props.red ? theme.colors?.error : (props.transparent ? theme.colors?.white : theme.colors?.black)) };
padding: ${props => (props.modalSmallButton ? '14.5px 0' : props.modalButton ? '14.5px 70px' : props.modalBigButton ? '14.5px 20px' : props.padding ?? '0')};
cursor: pointer;
height: ${props => () => (props.large ? '60px' // large
  : (props.small ? '40px' // small
    : (props.autoHeight ? 'auto'
      : (props.smaller ? '30px' // smaller
        : '50px' // normal
      )
    )
  ))
};
align-items: center;
justify-content: center;
font-size: ${props => props.fontSize ?? '16px'};
color: ${props => ({ theme }) => (!props.outline ? theme.colors?.white : theme.colors?.black)};
margin: ${props => (props.modalButton || props.modalBigButton ? '10px 0 0 0' : props.left ? '0 5px 0 0' : props.right ? '0 0 0 5px' : props.margin ?? '0')};

  > * {
    font-weight: 700;
		display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: ${props => ({ theme }) => (!props.outline ? theme.colors?.white : (props.red ? theme.colors?.red : theme.colors?.black))};
		padding: 0 ${props => ({ theme }) => (props.noPadding ? '0' : props.wide ? theme.functions?.toEm(40) : props.wider ? theme.functions?.toEm(60) : props.narrow ? theme.functions?.toEm(15) : theme.functions?.toEm(25)) };
		font-size: ${props => ({ theme }) => (props.large ? theme.functions?.toEm(18) // large
  : (props.small ? theme.functions?.toEm(14) // small
    : (props.smaller ? theme.functions?.toEm(12) // smaller
      : theme.functions?.toEm(16) // normal
    )
  ))
		};
  }
  
  &:hover {
		background: ${props => ({ theme }) => (props.outline ? theme.colors?.white // outline bg
		    : (props.red ? theme.colors?.red // red bg
		    	: (props.transparent ? 'transparent' // transparent
		      	: theme.colors?.primary // default bg
		    	)))
		};
    box-shadow: inset 0 0 0 ${props => () => (!props.outline ? '3px' : '4px') } ${props => ({ theme }) => (
		  props.outline ? theme.colors?.primary // outline bg
		    : (props.red ? theme.colors?.third // red bg
		      : theme.colors?.secondary // default bg
		    )
		)};

    * {
	    color: ${props => ({ theme }) => (
		  props.outline ? theme.colors?.primary // outline text color
		    : (props.red ? theme.colors?.white // red text color
		      : theme.colors?.secondary // default text color
		    )
		)};
	    border-color: ${props => ({ theme }) => (!props.outline ? theme.colors?.secondary : theme.colors?.primary) };
      	text-decoration: none;
    }
  }
  
  &.color-gold {
  	> * {
  		color: ${ ({ theme }) => theme.colors?.gold };
  	}
  	
		&:hover {
			box-shadow: inset 0 0 0 ${props => () => (!props.outline ? '3px' : '4px') } ${ ({ theme }) => theme.colors?.gold };
	
			* {
				color: ${ ({ theme }) => theme.colors?.gold };
				border-color: ${ ({ theme }) => theme.colors?.gold };
				text-decoration: none;
			}
		}
  }

	${props => ({ theme }) => props.disabled && (`
    background: ${ !props.outline ? theme.colors?.btnDisabled : theme.colors?.white };
    box-shadow: ${ !props.outline ? 'none' : `inset 0 0 0 2px ${ theme.colors?.btnDisabled }` };
    color: ${ !props.outline ? theme.colors?.white : theme.colors?.btnDisabled };
		pointer-events: none;
    cursor: not-allowed;

    > * {
	    color: ${ !props.outline ? theme.colors?.white : theme.colors?.primary };
      text-decoration: none;
    }

    &:hover {
    	background: ${ !props.outline ? theme.colors?.btnDisabled : theme.colors?.white };
      color: ${ !props.outline ? theme.colors?.white : theme.colors?.btnDisabled };
      box-shadow: ${ !props.outline ? 'none' : `inset 0 0 0 2px ${ theme.colors?.btnDisabled }` };
      
      > * {
      	pointer-events: none;
				cursor: not-allowed;
		    color: ${ !props.outline ? theme.colors?.white : theme.colors?.btnDisabled };
      }
    }
  `)}
`;

/**
 *
 * @param children
 * @param props: fullwidth, wide, outline, disabled, large, small, smaller, red
 * @returns {JSX.Element}
 * @constructor
 */
const Button = ({ children, ...props }) => (
  <ButtonStyle {...props}>
    {children}
  </ButtonStyle>
);

export default Button;
