export default {
  forgotPassword: 'Zapomenuté heslo',
  subTitle: 'Zadejte svou e-mailovou adresu',
  reset: 'Obnovit',
  placeholders: {
    email: 'E-mailová adresa',
    password: 'Heslo',
    passwordConfirmation: 'Potvrdit heslo',
  },
  passwordResetSuccessfully: 'Zkontrolujte schránku doruèené pošty, kde najdete pokyny k obnovení hesla.',
  setPassword: 'Nastavit heslo',
  setNewPassword: 'Zadejte nové heslo',
  invalidResetPasswordLink: 'Odkaz na obnovení hesla vypršel nebo je neplatný.',
};
