import React from 'react';
import { Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Container, Logo, Visual } from './styles';
import { P, H1, Button } from '../../components/Collection';
import LoadImage from '../../components/common/LoadImage';

const NotFound = () => (
  <Container>
    <Helmet>
      <title>404 | Sportya</title>
    </Helmet>
    <Link to="/">
      <Logo src={LoadImage('logo-white.svg')} />
    </Link>
    <Visual src={LoadImage('notFound-visuals/404-visual.png')} />
    <H1 color="white">
      <Trans ns="notFound" i18nKey="title">Oops. Page not found!</Trans>
    </H1>
    <P
      large
      color="white"
      maxWidth="378px"
      margin="16px 0 33px 0"
      textAlign="center"
    >
      <Trans ns="notFound" i18nKey="subtitle">You have reached the edge of the observable Sportya universe.</Trans>
    </P>
    <Button outline minWidth="200px">
      <Link to="/">
        <Trans ns="notFound" i18nKey="goHome">Go to Homepage</Trans>
      </Link>
    </Button>
    <P
      xSmall
      margin="60px 0 0 0"
      textAlign="center"
      maxWidth="360px"
      color="white"
    >
      <Trans ns="notFound" i18nKey="contact">
        Let us know how you got here, so that we can build a better world (and website) -
        {' '}
        <a href="mailto:contact@sportya.net">contact@sportya.net</a>
        .
        Thank you, friend!
      </Trans>
    </P>
  </Container>
);

export default NotFound;
