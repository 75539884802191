import React from 'react';
import dateFormat from 'dateformat';

import { Trans, useTranslation } from 'react-i18next';
import { H3, Paragraph } from '../../../../components';
import Button from '../../../../components/Button';
import Link from '../../../../components/Link';

import { Container, Group } from './styles';

const PlayerDetails = ({ accountInfo }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const clubOwner = accountInfo?.role === 'club';
  const dayCreated = dateFormat(accountInfo?.createdAt, 'd');
  const monthCreated = dateFormat(accountInfo?.createdAt, 'mmm');
  const yearCreated = dateFormat(accountInfo?.createdAt, 'yyyy');
  const membership = accountInfo?.membership?.plan?.split('-');
  const createdAt = `${dayCreated} ${monthCreated} ${yearCreated}`;
  const fullName = `${accountInfo?.firstName} ${accountInfo?.lastName}`;
  return (
    <Container>
      <H3><Trans ns="settings" i18nKey="profileSettings.playerDetails.title">Player Details</Trans></H3>
      <Group>
        <Paragraph>
          {fullName} - <a href={`mailto:${accountInfo?.email}`}>{accountInfo?.email}</a>
        </Paragraph>
      </Group>

      <Group>
        {!clubOwner ? (
          <>
            <Paragraph large bold>
              <Trans ns="settings" i18nKey="profileSettings.playerDetails.passionatePlayer">
                Passionate Tennis Player
              </Trans>
            </Paragraph>
            <Paragraph>
              <Trans ns="settings" i18nKey="profileSettings.playerDetails.since">
                Since {{ createdAt }}
              </Trans>
            </Paragraph>
          </>
        ) : (
          <>
            <Paragraph large bold>
              <Trans ns="player" i18nKey="registrationDate">
                Registration Date
              </Trans>
            </Paragraph>
            <Paragraph>
              <Trans ns="settings" i18nKey="profileSettings.playerDetails.since">
                {`${dayCreated } ${ commonT(monthCreated) } ${ yearCreated}`}
              </Trans>
            </Paragraph>
          </>
        )}
      </Group>

      <Group>
        <Paragraph large bold>
          <Trans ns="settings" i18nKey="profileSettings.playerDetails.accountType">
            Account Type
          </Trans>
        </Paragraph>
        {!clubOwner ? (
          <>
            <Paragraph>{t(`profileSettings.playerDetails.${membership[0]}`, { period: membership[1] })}</Paragraph>
            {accountInfo?.membership?.plan === 'free' && (
              <Button outline block contain>
                <Link to="/plans" className="display-block max-content">
                  <Trans ns="settings" i18nKey="profileSettings.playerDetails.upgradeToPremium">
                    Upgrade to Premium
                  </Trans>
                </Link>
              </Button>
            )}
          </>
        ) : (
          <Paragraph>
            {/* eslint-disable-next-line max-len */}
            {accountInfo?.role.charAt(0).toUpperCase() + accountInfo?.role.slice(1)} - {accountInfo?.clubInfo?.businessName}
          </Paragraph>
        )}
      </Group>
      {!clubOwner && (
        <Group>
          <Paragraph large bold>
            <Trans ns="settings" i18nKey="profileSettings.playerDetails.subscriptionValidity">
              Subscription Validity
            </Trans>
          </Paragraph>
          {accountInfo?.membership?.plan === 'free' ? (
            <Paragraph><Trans ns="subscriptions" i18nKey="plans.free.expDate">Never Expires</Trans></Paragraph>)
            : (
              <Paragraph>
                <Trans
                  ns="subscriptions"
                  i18nKey="activePlan"
                  values={{ expDate: dateFormat(accountInfo?.membership?.endDate, 'd mmmm yyyy', true) }}
                >
                  Your <strong>Premium</strong> plan is active until
                  <strong>{dateFormat(accountInfo?.membership?.endDate, 'd mmmm yyyy', true)}</strong>
                </Trans>
              </Paragraph>
            )}
        </Group>
      )}
    </Container>
  );
};

export default PlayerDetails;
