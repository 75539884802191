import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import {
  Contain, Link, P, Button,
} from '../../../../../../components/Collection';

const Container = styled(Contain)({
  margin: '18px 40px 0 auto',
  '@media (max-width: 1190px)': {
    margin: '0 auto',
    button: {
      margin: '10px 0',
    },
  },
});

const Index = ({
  listType,
  accountInfo,
  eventInfo,
  competitionInfo,
  setLevelModal,
  levelModal,
  setWithdrawModal,
  withdrawModal,
  eventStatus,
  priceInfo,
  isLoggedIn,
  isRegistered,
}) => {
  const currentPhase = eventInfo?.currentPhase?.status;
  const maintenance = competitionInfo?.status === 'MAINTENANCE';
  const hasLevel = accountInfo.gameLevel?.singles && accountInfo.gameLevel?.doubles;
  const isBeforeClosingRegistrations = new Date() < new Date(eventInfo?.phases?.playing?.endDate);
  const availableFee = priceInfo?.primary !== 'undefined undefined' && priceInfo?.secondary !== 'undefined undefined';
  const showDiscountForPremium = (
    priceInfo?.membershipDiscountedPrice
    && (!isLoggedIn || (isLoggedIn && accountInfo?.membership?.plan === 'free'))
    && isBeforeClosingRegistrations
  );
  const isPremium = accountInfo?.membership?.plan === 'premium';
  const joinPrice = isPremium
    ? (`${priceInfo?.membershipDiscountedPrice} ${priceInfo?.currency}`)
    : (`${priceInfo?.primary}`);
  const hasPlaceholder = ['openingSoon', 'sortPending', 'pending', 'resultsPending', 'ended'].includes(currentPhase);
  const hasPlaceholderAndNotRegistered = ['sortPending', 'pending', 'resultsPending', 'ended']
    .includes(currentPhase) && !isRegistered;
  const dontShowFee = ['sortPending', 'pending', 'resultsPending', 'ended'].includes(currentPhase);
  const isWL = listType === 'waitingList';
  const isML = listType === 'mainList' || listType === 'preRegistrationList';
  const wlNoWithdrawal = ['resultsPending', 'ended'].includes(currentPhase);
  const hasWithdrawal = isRegistered && ((isWL && !wlNoWithdrawal) || (isML && !hasPlaceholder));

  const state = {
    content: maintenance
      ? <Trans ns="events" i18nKey="underMaintenanceButton">Under Maintenance</Trans> : hasWithdrawal
        ? <Trans ns="events" i18nKey="joined">Joined</Trans> : ['openingSoon'].includes(eventStatus)
          ? (
            <>
              <Trans ns="events" i18nKey={`phaseStatuses.${currentPhase}`} />
              <span className="fw-normal ml5 max-content">
                {priceInfo?.membershipDiscountedPrice} {priceInfo?.currency}
              </span>
            </>
          ) : hasPlaceholder || hasPlaceholderAndNotRegistered
            ? (
              <Trans ns="events" i18nKey={`phaseStatuses.${currentPhase}`} />
            )
            : isLoggedIn
              ? (
                <>
                  <Trans ns="events" i18nKey="joinNow">Join Now</Trans>
                  <span className="fw-normal ml5">{joinPrice}</span>
                </>
              ) : '',
  };

  return (
    <Container
      direction="column"
      align="center"
    >
      <Button
        height="60px"
        minWidth="245px"
        padding="0 20px"
        margin="0 0 17px 0"
        outline={['openingSoon'].includes(eventStatus) || hasPlaceholder || isRegistered}
        ongoing={hasPlaceholder || hasPlaceholderAndNotRegistered || maintenance || isRegistered}
        {...isPremium
        && !hasPlaceholderAndNotRegistered
        && !hasPlaceholder
        && !isRegistered
        && !maintenance
        && !['openingSoon'].includes(eventStatus) && { premium: '#FFCC18', color: '#FFCC18' }}
        {...!hasLevel && !maintenance && { onClick: () => setLevelModal(!levelModal) }}
      >
        {!hasPlaceholder && isBeforeClosingRegistrations && !isRegistered && !maintenance && hasLevel
          ? (
            <Link
              {...isLoggedIn
                && hasLevel
                && { to: `/events/${eventInfo._id}/competitions/${competitionInfo?._id}/join` }
              }
            >
              {state.content}
            </Link>
          ) : state.content}
      </Button>
      {showDiscountForPremium && !isRegistered ? (
        <>
          <P small margin="0 0 5px 0">
            <Trans ns="events" i18nKey="premiumDiscount">Discount for Premium</Trans>
            :
            <strong>
              -
              {competitionInfo?.premiumDiscount ?? '-'}
              {' '}
              {competitionInfo?.currency ?? ''}
            </strong>
          </P>
          <P small textDecoration="underline" margin="0 0 5px 0">
            <Link to="/plans">
              <Trans ns="events" i18nKey="getPremium">
                Get premium
              </Trans>
            </Link>
          </P>
        </>
      ) : ''}
      {availableFee && !dontShowFee ? (
        <P small margin="0 0 5px 0">
          <Trans ns="events" i18nKey="clubEntryFee">
            Club Entry Fee:
            <strong>{{ primaryClubFee: priceInfo?.secondaryClubFee }}</strong>
          </Trans>
        </P>
      ) : ''}

      {hasWithdrawal && (
        <P bold small color="red" className="cursor-pointer" onClick={() => setWithdrawModal(!withdrawModal)}>
          <Trans ns="events" i18nKey="withdraw">Withdraw</Trans>
        </P>
      )}
    </Container>
  );
};

export default Index;
