import React, { useState } from 'react';

import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ForDonate } from './styles';

import Paragraph from '../../../../../components/Paragraph';
import H2 from '../../../../../components/H2';

import DonateIcon from '../../../../../assets/images/donate-icon.svg';
import CheckMark from '../../../../../assets/images/checkmark.svg';

const Donate = ({ setDonation, currency, donation }) => {
  const { donations } = useSelector(state => state.settings?.data);

  const availableDonations = (donations || []).filter(value => value.currency === currency);

  const [id, setId] = useState();

  const selectHandler = (val) => {
    if (id === val) {
      return 'check';
    }
  };

  if (donation === null && id) {
    setId(null);
  }

  const handleAmount = (value) => {
    setId(value);
    const [amount] = (availableDonations || []).filter(v => v.id === value);
    if (value === id) {
      setDonation(null);
      setId(null);
    } else {
      setDonation(amount);
    }
  };

  return (
    <ForDonate>
      <div className="donate">
        <div>
          <img src={DonateIcon} alt="donateIcon" />
          <H2><Trans ns="events" i18nKey="join.sprinten.title">Fa parte din schimbare!</Trans></H2>
        </div>

        <div className="contentWrapp">
          <Paragraph small>
            <Trans ns="events" i18nKey="join.sprinten.1">
              Get involved and support paediatric physiotherapy scholarships that help dozens of children with
              disabilities to enjoy exercise and to have a chance at a normal life.
            </Trans>
          </Paragraph>
          <Paragraph small>
            <Trans ns="events" i18nKey="join.sprinten.2">
              Program implemented by the ProAm Events Foundation through the SPRINTEN project, with the support
              of KinetoBebe Association.
            </Trans>
          </Paragraph>
        </div>

        <Paragraph bold><Trans ns="events" i18nKey="join.sprinten.donate">Donate now!</Trans></Paragraph>
        <div>
          {availableDonations?.map((donate, idx) => (
            <button
              type="button"
              className={selectHandler(donate?.id)}
              onClick={() => handleAmount(donate?.id)}
              key={idx}
            >
              {' '}
              {id === donate?.id ? <img src={CheckMark} alt="checkIcon" /> : ''}
              {' '}
              {donate?.amount}
              {' '}
              {donate?.currency}
            </button>
          ))}
        </div>
      </div>
    </ForDonate>
  );
};

export default Donate;
