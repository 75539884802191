export default {
  hero: {
    title: 'Welcome to \nSportya',
    desc: 'There is a lot to learn about Sportya, so we\'ll start with the essentials. Explore the guide below to get the rundown of how matches, events and rankings work.',
  },
  firstPhase: {
    title: 'What Is Sportya?',
    desc: `Sportya is a competitive sport ecosystem where tens of thousands of amateur players engage in friendly matches, leagues and events to enjoy balanced play or compete for the best spots in national and international rankings. Sportya is now open for tennis and padel, with other racket sports and team sports also being planned to be made available in the future. `,
    articles: {
      1: {
        title: 'Discover',
        desc: 'Once you join Sportya, you become part of a large and passionate community of amateur players, and you gain access to events organized in hundreds of sport clubs. We are expanding to new regions and countries, bringing you greater diversity of choice for whatever path you choose – either playing locally at partner clubs or exploring and seeking new experiences around the world.',
      },
      2: {
        title: 'Challenge',
        desc: 'Get started with a few friendly matches, join a local league if flexibility of schedule is important to you or jump right into competitive events, and fight your way to the top of the national and international rankings for amateur players.',
      },
      3: {
        title: 'Connect',
        desc: 'Keep your friends close, meet new ones, share your experiences. Build and showcase your player profile and make the most out of your favourite competitive sports.',
      },
      4: {
        title: 'Game Level',
        subtitle: 'Play Balanced Matches',
        desc: 'Every Sportya member plays at a specific Game Level for each available sport. The level determines the events that you can join and facilitates proper matching both in competitive tournament play and friendly matches.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 Game Levels',
      desc: 'We welcome all players, from starters to seasoned players. Evaluate your Game Level during account creation, get out and play and enjoy balanced matches. We offer 6 levels of play, for each sport, each determined by a mix of abilities and experience. You can play as a beginner in tennis and an intermediate player in padel, for example.',
    },
    2: {
      title: 'Singles and Doubles Levels',
      desc: 'We treat them separately in Sportya. You will initially set a level of play indicative of your Singles game potential. This will automatically be your starting level of play for your Doubles matches. As you evolve, both of your levels automatically evolve with you.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Local League',
      subtitle: 'Keep Your Play Flexible',
      desc: 'Local Leagues allow you to play one match per week, following a flexible time schedule, while keeping the spirit of the competition alive. Further details will be provided as we get closer to the official launch date. ',
    },
    2: {
      title: 'Friendly Matches',
      subtitle: 'Find New Game Partners',
      desc: 'Friendly matches are the most flexible way to experience balanced matches in Sportya. Friendly matches can be played anytime, anywhere, with other Sportya members. Our matchmaking system will help you find a suitable game partner, find a suitable location, and report the result online, contributing to your ranking and player profile. Some features are in development at the moment but worth the wait.',
    },
    comingSoon: 'Coming in 2023',
    comingSoonFM: 'Coming in Q1 2023',
    inDevelopment: 'In Development',
  },
  fifthPhase: {
    title: 'Ranked Events',
    subtitle: 'Your Best Match Awaits',
    desc: 'The bread and butter of Sportya, Ranked Events are being organized in a coherent yearly calendar, for each sport, allowing you to compete in local and global amateur rankings and aim for the top. Sportya is the only place for amateur athletes to experience the thrill of being professional players.',
    articles: {
      1: {
        title: 'Ranked Event Categories',
        desc: '1st Serve, Silver, Gold, Platinum, Special and Tournament of Champions. Each has a specific role in the calendar and entry requirements differ from one category to the other. For each event and sport, we organize one or more competitions based on Game Levels, either for Singles or Doubles.',
      },
      2: {
        title: 'Event Formats',
        desc: 'Enjoy a multitude of formats, from classic, knockout events, organized during the weekend, to group format events, night events and more.',
      },
      3: {
        title: 'Match Formats',
        desc: 'Most matches in Sportya Tennis Ranked Events are played as 2 Tie Break Sets with match tie break (10 points) at 1 set all. Padel follows a similar suite while all other sport formats will be announced as soon as we open new sports.',
      },
      4: {
        title: 'Participation Pack',
        desc: 'Besides access to the event and all corresponding implications of the event taking place in Sportya (event coordination, results, rankings, activity updates, customer care etc.), entry fees in Sportya generally include 2 new premium balls allocated per match (tennis, padel) and a bottle of water per match.',
      },
    },
  },
  sixthPhase: {
    title: 'Start Playing',
    desc: 'At the end of the day, it all comes down to this - we encourage fun and engaging competition, the foundation of sport, that ultimately leads to progress in your game and in everyday life.',
  },
  playVideo: 'Play Video',
};
