export default {
  minutesUntil: 'due in {{time}} min',
  hoursUntil: 'due in {{time}} hrs',
  daysUntil: 'due in {{time}} days',
  monthsUntil: 'due in {{time}} mos',
  yearsUntil: 'due in {{time}} yrs',
  justNow: 'just now',
  minutesAgo: '{{time}} minutes ago',
  hoursAgo: '{{time}} hours ago',
  daysAgo: '{{time}} days ago',
  monthsAgo: '{{time}} months ago',
  yearsAgo: '{{time}} years ago',
};
