import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import mixpanel from '../../../mixpanel';
import { H1, Paragraph } from '../../../components';
import { Container, PlayerSettings, SettingsMenu } from './styles';
import { getAccountInfo, mailChimpSync } from '../../../components/Layout/reducers/session';
import {
  Schedule,
  Password,
  Language,
  Personal,
  Player,
  Profile,
  BlockedPlayers,
} from './components/exports';
import BillingData from './components/BillingData';
import { store as socketStore } from '../../../components/Socket/store';

const Settings = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const openTab = query.get('openTab');
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  const [panel, setPanel] = useState(openTab ? capitalizeFirstLetter(openTab) : 'Player');
  const { accountInfo } = useSelector(state => state.session);
  const clubOwner = accountInfo?.role === 'club';
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  useEffect(() => {
    dispatch(mailChimpSync());
  }, []);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        await dispatch(getAccountInfo());
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);
      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  const handlePanel = e => {
    setPanel(e);
  };

  return (
    <Container>
      <Helmet>
        <title>
          {t('seoTitle')}
        </title>
      </Helmet>

      <H1><Trans ns="settings" i18nKey="profileSettings.title">My Settings</Trans></H1>
      <PlayerSettings>
        <SettingsMenu>
          <ul>
            <li
              onClick={() => {
                handlePanel('Player');
              }}
              className={panel === 'Player' ? 'active' : ''}
            >
              <Paragraph small bold>
                <Trans ns="settings" i18nKey="profileSettings.playerDetails.title">Account Details</Trans>
              </Paragraph>
            </li>
            <li
              onClick={() => {
                handlePanel('Password');
              }}
              className={panel === 'Password' ? 'active' : ''}
            >
              <Paragraph small bold>
                <Trans ns="settings" i18nKey="profileSettings.changePassword.title">Change Password</Trans>
              </Paragraph>
            </li>
            <li
              onClick={() => {
                handlePanel('Personal');
              }}
              className={panel === 'Personal' ? 'active' : ''}
            >
              <Paragraph small bold>
                <Trans ns="settings" i18nKey="profileSettings.personalInformation.title">Personal Information</Trans>
              </Paragraph>
            </li>
            <li
              onClick={() => {
                handlePanel('Billing');
                mixpanel.track('Click [Blocked Players] in My Account > Billing Data');
              }}
              className={panel === 'Billing' ? 'active' : ''}
            >
              <Paragraph small bold>
                <Trans ns="settings" i18nKey="profileSettings.billingData.title">Billing Data</Trans>
              </Paragraph>
            </li>
            {!clubOwner && (
              <>
                <li
                  onClick={() => {
                    handlePanel('Profile');
                  }}
                  className={panel === 'Profile' ? 'active' : ''}
                >
                  <Paragraph small bold>
                    <Trans ns="settings" i18nKey="profileSettings.profileDetails.title">Profile Details</Trans>
                  </Paragraph>
                </li>
                <li
                  onClick={() => {
                    handlePanel('Language');
                  }}
                  className={panel === 'Language' ? 'active' : ''}
                >
                  <Paragraph small bold>
                    <Trans ns="settings" i18nKey="profileSettings.languageCommunication.title">
                      Language & Communication
                    </Trans>
                  </Paragraph>
                </li>
                <li
                  onClick={() => {
                    handlePanel('Schedule');
                  }}
                  className={panel === 'Schedule' ? 'active' : ''}
                >
                  <Paragraph small bold>
                    <Trans ns="settings" i18nKey="profileSettings.availabilitySchedule.title">
                      Availability Schedule
                    </Trans>
                  </Paragraph>
                </li>
                <li
                  onClick={() => {
                    handlePanel('BlockedPlayers');
                    mixpanel.track('Click [Blocked Players] in My Account > Settings');
                  }}
                  className={panel === 'BlockedPlayers' ? 'active' : ''}
                >
                  <Paragraph small bold>
                    <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.title">Blocked Players</Trans>
                  </Paragraph>
                </li>
              </>
            )}
          </ul>
        </SettingsMenu>
        {
          {
            Player: <Player accountInfo={accountInfo} />,
            Password: <Password accountInfo={accountInfo} />,
            Personal: <Personal accountInfo={accountInfo} />,
            Billing: <BillingData accountInfo={accountInfo} />,
            Profile: <Profile accountInfo={accountInfo} />,
            Language: <Language accountInfo={accountInfo} />,
            Schedule: <Schedule accountInfo={accountInfo} />,
            BlockedPlayers: <BlockedPlayers />,
          }[panel]
        }
      </PlayerSettings>
    </Container>
  );
};

export default Settings;
