import styled from '@emotion/styled';

import Shield from '../../../../../../../assets/images/shield.svg';

export const TeamAVG = styled.div`
div{
  padding-bottom:10px;
h1{
  font-Weight: 700;
  font-size: 24px;
  line-height: 31.25px;
  text-align:center;
  color: ${({ theme }) => theme?.colors?.white};
  padding-top: 8px;
}

.teamLvlWrapp{
  height: 56px;
  width:50px;
  margin: 0 auto;
  background-image: url(${Shield}); !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1{
    margin-top:6px !important;
  }
}

}`;
