import React from 'react';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';

import { ProductStyle } from './styles';

import Paragraph from '../../../../../components/Paragraph';
import Link from '../../../../../components/Link';

const Product = ({ transaction }) => {
  const { t } = useTranslation('accounts');

  return (
    <ProductStyle>
      <div className="transaction">
        <div className="transactionId">
          <Paragraph small>
            <span>
              <Trans ns="accounts" i18nKey="wallet.mobile.id">ID</Trans>
              :
            </span>
            #
            {transaction.paymentId}
          </Paragraph>
        </div>

        <div className="transactionDate">
          <Paragraph small>
            <span>
              <Trans ns="accounts" i18nKey="wallet.mobile.date">Date</Trans>
              :
            </span>
            {dateFormat(transaction.createdAt, 'd mmmm yyyy, HH:MM')}
          </Paragraph>
        </div>

        <div className="transactionProduct">
          <Paragraph small>
            <span>
              <Trans ns="common" i18nKey="type">Type</Trans>
              :
            </span>
            <Trans ns="accounts" i18nKey={`transactionTypes.${transaction.type}`}>
              <Link to={`/events/${transaction.eventId}/competitions/${transaction.competitionId}`} />
              {{
                membershipName: t(
                  `${transaction.products[0]?.name?.split('-')[0]}`,
                  { period: Number(transaction.products[0]?.name?.replace(/[^0-9]/g, '')) },
                ),
              }}
              {{ eventName: transaction.eventName }}
            </Trans>
          </Paragraph>
        </div>

        <div className="transactionAmount">
          <Paragraph small>
            <span>
              <Trans ns="accounts" i18nKey="wallet.mobile.amount">Amount</Trans>
              :
            </span>
            {['eventRegistration', 'upgradeMembership'].includes(transaction.type) && '-'}
            {transaction.amount}
            {' '}
            {transaction.currency}
          </Paragraph>
        </div>

      </div>
    </ProductStyle>
  );
};

export default Product;
