import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../../..';
import LoadImage from '../../../../../../../../../components/common/LoadImage';
import mixpanel from '../../../../../../../../../mixpanel';
import { VisitedProfileButton } from '../../../styles';
import { InvertImage } from './styles';

const InviteToFriendlyMatch = () => {
  const history = useHistory();
  const {
    user, friendStatus, isBlocked, iAmBlocked,
  } = useContext(UserContext);

  const handleInvite = () => {
    mixpanel.track('Initiate CREATE MATCH', { sp_action_source: 'Player Profile - Invite to Match' });
    history.push('/friendly-matches/create/invite', {
      match: {
        gameType: 'singles',
        opponent: user,
      },
    });
  };

  return !isBlocked && !iAmBlocked && (
    <VisitedProfileButton
      addMargin
      margin="0 10px 10px 0"
      onClick={() => handleInvite()}
      outline={!friendStatus}
    >
      <InvertImage
        invert={friendStatus}
        src={LoadImage('friendly-matches/match-form/tennis-ball-icon.svg')}
        alt="Invite to Match"
      />
      <Trans ns="friendlyMatches" i18nKey="inviteToMatch">
        Invite to Match
      </Trans>
    </VisitedProfileButton>
  );
};

export default InviteToFriendlyMatch;
