import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { PopUp, PopUpContainer } from '../styles';

import { addRating, removeRating } from '../actions';

import LoadImage from '../../common/LoadImage';
import Paragraph from '../../Paragraph';
import Button from '../../Button';
import Modal from '../../Modal';
import ProfileCard from '../../ProfileCard';

import mixpanel from '../../../mixpanel';

const SetRatingModal = ({
  onRequestClose,
  onSubmit,
  user,
  matchId,
  gameType,
  ratingInfo,
  userPositionIndex,
  skippable = false,
  showCloseIcon = false,
  thankYouModal,
  mixpanelData,
}) => {
  const { addToast } = useToasts();
  const { t } = useTranslation('common');

  const [rating, setRating] = useState(null);

  useEffect(() => {
    if (ratingInfo?.givenRating) {
      setRating(ratingInfo?.givenRating);
    }
  }, [ratingInfo]);

  const handleAddRating = async () => {
    const tempRating = rating;
    setRating(null);
    document.getElementById("ratings").reset();
    await addRating({
      userId: user?.userId,
      payload: {
        matchId,
        rating: tempRating,
        receiverId: user?.id,
      },
      addToast,
      t,
    });

    const positions = ['Partner', 'Opponent 1', 'Opponent 2'];
    mixpanel.track('Save Rating', {
      sp_source: mixpanelData?.source
        ? mixpanelData?.source : ratingInfo?.givenRating
          ? 'Change from Profile' : 'Rate from Profile',
      sp_value: rating,
      sp_rated_player: gameType === 'singles' ? 'Opponent' : positions[userPositionIndex - 1],
      sp_match_type: mixpanelData?.matchType,
      sp_asset_type: mixpanelData?.assetType,
    });

    if (typeof onSubmit === 'function') {
      onSubmit();
    } else {
      onRequestClose();
    }
  };

  const handleRemoveRating = async () => {
    setRating(null);
    document.getElementById("ratings").reset();
    await removeRating({
      userId: user?.userId,
      payload: {
        matchId,
      },
      addToast,
      t,
    });

    mixpanel.track('Remove Rating');

    if (typeof onSubmit === 'function') {
      onSubmit();
    } else {
      onRequestClose();
    }
  };

  const handleSkipRating = async () => {
    setRating(null);
    document.getElementById("ratings").reset();
    await addRating({
      userId: user?.userId,
      payload: {
        matchId,
        status: 'skipped',
        receiverId: user?.id,
      },
      addToast,
      t,
    });

    mixpanel.track('Skip Rating');

    if (typeof onSubmit === 'function') {
      onSubmit({ skipThankYouModal: true });
    } else {
      onRequestClose();
    }
  };

  if (thankYouModal) {
    return (
      <Modal
        isOpen={thankYouModal}
        onRequestClose={onRequestClose}
        style={{ width: 400 }}
        showCloseIcon={false}
      >
        <div className="modal-content text-center">
          <img src={LoadImage('modal-check-icon.svg')} alt="" width={50} height={50} className="mb20" />
          <Paragraph large bold>
            <Trans ns="accounts" i18nKey="thankYouForRating">Thank you for your rating!</Trans>
          </Paragraph>
        </div>
        <div className="modal-footer flex-column justify-content-center">
          <Button onClick={onRequestClose} modalButton>
            <span><Trans ns="friendlyMatches" i18nKey="gotIt">Got it</Trans></span>
          </Button>
        </div>
      </Modal>
    );
  }

  let ratePlayerTitle = 'ratePlayer';
  if (gameType === 'doubles') {
    const userTypes = ['Player', 'Partner', 'Opponent', 'OpponentPartner'];
    ratePlayerTitle = `howWouldRateTitle${userTypes?.[userPositionIndex]}`;
  }

  return (
    <Modal
      showCloseIcon={showCloseIcon}
      {...showCloseIcon && {
        onRequestClose,
      }}
      isOpen
      className="rate-player-modal"
      style={{
        width: 400,
      }}
    >
      <div className="modal-header">
        <Trans ns="accounts" i18nKey={ratePlayerTitle}>Rate Player</Trans>
        <PopUpContainer inline className="ml10">
          <PopUp outline fromModal>
            <Trans ns="accounts" i18nKey="ratePlayerInfo">
              You can rate players only after a friendly or competition match. <br />
              Explain when a player can rate another player, and when it cannot, <br />
              what are ratings used for, etc lorem ipsum dolor si amet
            </Trans>
          </PopUp>
          <img src={LoadImage('info-icon.svg')} alt="Info" width={15} />
        </PopUpContainer>
      </div>

      <div className="modal-content">
        <ProfileCard user={user} gameType={gameType} />
        <form id="ratings">
          <fieldset className="rating">
            <input type="radio" id="star5" name="rating" value="5" defaultChecked={rating === 5} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="star5" title="5 stars" onClick={() => setRating(5)} />

            <input type="radio" id="star4" name="rating" value="4" defaultChecked={rating === 4} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="star4" title="4 stars" onClick={() => setRating(4)} />

            <input type="radio" id="star3" name="rating" value="3" defaultChecked={rating === 3} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="star3" title="3 stars" onClick={() => setRating(3)} />

            <input type="radio" id="star2" name="rating" value="2" defaultChecked={rating === 2} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="star2" title="2 stars" onClick={() => setRating(2)} />

            <input type="radio" id="star1" name="rating" value="1" defaultChecked={rating === 1} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="star1" title="1 stars" onClick={() => setRating(1)} />
          </fieldset>
        </form>
      </div>

      <div className="modal-footer">
        <Button
          fullwidth
          disabled={!rating}
          onClick={handleAddRating}
        >
          <span>
            <Trans ns="accounts" i18nKey="rate">Rate</Trans>
          </span>
        </Button>
      </div>
      {ratingInfo?.givenRating && (
        <Paragraph
          bold
          onClick={handleRemoveRating}
          className="text-center mt30 cursor-pointer"
        >
          <Trans ns="accounts" i18nKey="removeRating">Remove Rating</Trans>
        </Paragraph>
      )}

      {skippable && (
        <Paragraph
          bold
          onClick={handleSkipRating}
          className="text-center mt30 cursor-pointer"
        >
          <Trans ns="accounts" i18nKey="skipRating">Skip Rating</Trans>
        </Paragraph>
      )}
    </Modal>
  );
};

export default SetRatingModal;
