/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Input from '../../components/Form/Input';
import Link from '../../components/Link';
import Select from '../../components/Form/Select';
import Button from '../../components/Form/Button';
import { Button as OSButton } from '../../components/SecondButton';
import H1 from '../../components/H1';
import H3 from '../../components/H3';
import P from '../../components/Paragraph';

import { ForClubsContainer, SportyaOSBanner } from './styles';

import { submit } from './actions';
import SelectGoogleAutocomplete from '../../components/Form/SelectGoogleAutocomplete';
import { store as socketStore } from '../../components/Socket/store';
import LoadImage from '../../components/common/LoadImage';
import { Country, Flag } from '../Auth/Register/styles';

const ForClubs = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation('clubs');
  const { t: commonT } = useTranslation('common');
  const [country, setCountry] = useState([]);
  const { eligibleCountries, activeSports } = useSelector(state => state.settings?.data);
  const [userLocation, setUserLocation] = useState();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const forClubsSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form.validation.required')),
    lastName: Yup.string().required(t('form.validation.required')),
    email: Yup.string()
      .email(t('form.validation.invalidEmail'))
      .required(t('form.validation.required')),
    phone: Yup.string().required(t('form.validation.required')),
    clubName: Yup.string().required(t('form.validation.required')),
    sports: Yup.array().required(t('form.validation.required')),
    noCourts: Yup.number().required(t('form.validation.required')),
  });

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(t(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });
    };

    if (socket) {
      socket.removeAllListeners('clubs-response');
      socket.on('clubs-response', listenForResponse);

      return () => {
        socket.removeAllListeners('clubs-response');
      };
    }
  }, [socketClientId]);

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(value => ({
    value: (value.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${value.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${value.key}`)}</P>
      </Country>
    ),
  }));

  return (
    <>
      <Helmet>
        <title>{t('seoTitle1')}</title>
      </Helmet>
      <SportyaOSBanner>
        <P bold><Trans ns="clubs" i18nKey="sportyaOSCTA">Are you a Club or Organizer?</Trans></P>
        <OSButton
          noImgMargin
          outline
          height="40px"
          margin="0 0 0 20px"
          padding="0 10px"
        >
          <a href={process.env.REACT_APP_OS_URL} target="_blank" rel="noreferrer">
            <Trans ns="clubs" i18nKey="sportyaOSButton">Go to SportyaOS</Trans>
            <img src={LoadImage('external-link.svg')} className="ml5" alt="Go to SportyaOS" />
          </a>
        </OSButton>
      </SportyaOSBanner>
      <ForClubsContainer>
        <div className="row mb70">
          <div className="w50">
            <H1 className="mb40">
              <Trans ns="clubs" i18nKey="title">
                Are you a Sport Club Manager or Court / Facility Administrator?
              </Trans>
            </H1>
            <ul>
              <li>
                <P large bold>
                  <Trans ns="clubs" i18nKey="info1.title">
                    Manage local and global tournaments for amateur players
                  </Trans>
                </P>
                <P>
                  <Trans ns="clubs" i18nKey="info1.desc">
                    Personalized for your club needs and court availability, for maximum impact.
                  </Trans>
                </P>
              </li>
              <li>
                <P large bold>
                  <Trans ns="clubs" i18nKey="info2.title">Connect with new players</Trans>
                </P>
                <P className="mw-300">
                  <Trans ns="clubs" i18nKey="info2.desc">
                    Build and expand your player base with local and international players.
                  </Trans>
                </P>
              </li>
              <li>
                <P large bold>
                  <Trans ns="clubs" i18nKey="info3.title">Become a hub for amateur athletes</Trans>
                </P>
                <P>
                  <Trans ns="clubs" i18nKey="info3.desc">
                    Support level-based play and increase player engagement and loyalty.
                  </Trans>
                </P>
              </li>
              <li>
                <P large bold>
                  <Trans ns="clubs" i18nKey="info4.title">Book courts & Manage facilities</Trans>
                </P>
                <P>
                  <Trans ns="clubs" i18nKey="info4.desc">
                    Manage and book your courts and facilities. Keep players engaged, more frequently.
                  </Trans>
                </P>
              </li>
            </ul>
          </div>
          <div className="w50">
            <div className="form">
              <div className="text-center">
                <H3><Trans ns="clubs" i18nKey="form.title">Do you want to know more?</Trans></H3>
                <P medium className="mb45">
                  <Trans ns="clubs" i18nKey="form.desc">Give us some info and we’ll get back to you.</Trans>
                </P>
              </div>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  location: '',
                  clubName: '',
                  sports: null,
                  noCourts: '',
                }}
                validationSchema={forClubsSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const parsedValues = {
                    ...values,
                    location: userLocation,
                    sports: values.sports.map(({ value }) => value),
                  };

                  await submit({
                    values: parsedValues,
                    addToast,
                    setSubmitting,
                    resetForm,
                    t,
                    socketClientId,
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <Input
                      name="firstName"
                      placeholder={t('form.firstName')}
                      type="text"
                      {...{ errors, touched }}
                    />
                    <Input
                      name="lastName"
                      placeholder={t('form.lastName')}
                      type="text"
                      {...{ errors, touched }}
                    />
                    <Input
                      name="email"
                      placeholder={t('form.email')}
                      type="email"
                      {...{ errors, touched }}
                    />
                    <Input
                      name="phone"
                      placeholder={t('form.phone')}
                      type="text"
                      {...{ errors, touched }}
                    />

                    <Select
                      name="country"
                      onChange={setCountry}
                      placeholder={commonT('country')}
                      label={commonT('country')}
                      options={countries}
                      isSearchable={false}
                      height="50px"
                      lowInput
                      inputLabel
                      {...!isEmpty(country) && { labelOn: true }}
                      {...{ errors, touched }}
                    />
                    <div className="text-left">
                      <SelectGoogleAutocomplete
                        name="city"
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: [country?.value],
                          },
                        }}
                        minLengthAutocomplete={3}
                        hook={(e) => setUserLocation(e?.value)}
                        placeholder={commonT('city')}
                        label={commonT('city')}
                        disabled={!country}
                        isClearable
                        {...userLocation && {
                          value: { label: userLocation?.city, value: userLocation },
                        }}
                        {...!isEmpty(userLocation) && { lowInput: true }}
                        {...!isEmpty(userLocation) && { labelOn: true }}
                      />
                    </div>

                    <Input
                      name="clubName"
                      placeholder={t('form.clubName')}
                      type="text"
                      {...{ errors, touched }}
                    />
                    <Select
                      name="sports"
                      // label={t('form.sport')}
                      placeholder={t('form.sport')}
                      value={values.sports}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={(activeSports || []).map((value) => ({
                        value,
                        label: commonT(`sports.${value}`),
                      }))}
                      isMulti
                      {...{ errors, touched }}
                    />
                    <Input
                      name="noCourts"
                      placeholder={t('form.numberOfCourts')}
                      type="number"
                      {...{ errors, touched }}
                    />
                    <Button fullwidth black type="submit" disabled={isSubmitting}>
                      <span>{t('form.button')}</span>
                    </Button>
                  </Form>
                )}
              </Formik>

              <P small className="disclaimer text-center mt40">
                <Trans ns="clubs" i18nKey="form.ToS">
                  By registering, you confirm that you agree to the processing of your personal data by Sportya
                  as described in the <Link to="/confidentiality">Privacy Policy</Link>.
                </Trans>
              </P>
            </div>
          </div>
        </div>
        <div className="row mb70 text-center contact-info">
          <div className="w50">
            <div className="border-round-grey pt50 pb50">
              <P medium>{t('call.title')}</P>
              <H3>
                <a href="tel:+4 031 805 4042">+4 031 805 4042</a>
              </H3>
            </div>
          </div>
          <div className="w50 text-center">
            <div className="border-round-grey pt50 pb50">
              <P medium>{t('contact.title')}</P>
              <H3>
                <a href="mailto:join@sportya.net">join@sportya.net</a>
              </H3>
            </div>
          </div>
        </div>
      </ForClubsContainer>
    </>
  );
};

export default ForClubs;
