import styled from '@emotion/styled';

export const IndividualPlayer = styled.div`

.badge{
  margin-top: ${(props => (props.duo ? '20px' : '0'))};
  margin-left:9px;
  img{
    width:14px;
    height:13px;
  }
}

@media screen and (max-width: 1024px){
  .leftWrapper{
    max-width: 300px !important;
  }

  .rightWrapper{
    max-width:300px !important;

    .winsLeft{
      max-width: 100px !important;
    }

    .winsRight{
      max-width: 115px !important;
    }
  }
}
    
    .individualPlayer{
    height:22px;
    display:flex;
    justify-content:space-between;
    }

.level{
  margin-right:8px;
p{  
  color:  ${({ theme }) => theme?.colors?.white} !important};
}

.paragraphDefault{
  p{
    color: ${({ theme }) => theme?.colors?.primary};
  }
}

  p{
    margin:0;
    color: ${({ theme }) => theme?.colors?.white};
  }


  .flex{
    display:flex;
    align-items:center;
  }

  .leftWrapper{
    display:flex;
    align-items:center;
    justify-content: space-between;
    max-width: 409px;
    width:100%;

    .nameLevel{
      max-width:212px;
      width:100%;
      display:flex;
      align-items:Center;
    }

    .leftScore{
      max-width: 107px;
      width:100%;
      display:flex;
      justify-content:space-between;
      margin-top: ${(props => (props.duo ? '20px' : '0'))};
    }
  }

  .rightWrapper{
    display:flex;
    max-width: 414px;
    width:100%;
    justify-content:space-between;

      .winsLeft{
        max-width: 147px;
        width:100%;
        margin-top:${(props => (props.duo ? '20px' : '0'))};;
        display:flex;
        align-items:center;
        justify-content:space-between;
      }

      .winsRight{
        max-width: 155px;
        width:100%;
        display:flex;
        margin-top:${(props => (props.duo ? '20px' : '0'))};;
        align-items:center;
        justify-content:space-between;
      }
  }
  `;

export const IndividualPlayerGroup = styled.div`
    p{margin:0;}

  .duoGroup{
    display:flex;
    flex-direction:column;

    align-items:flex-start !important;

    .groupPlayer{
      display:flex;
    }
  }

    .individualPlayerGroup{
    margin-bottom:20px;
    align-items:center;
    display:flex;
    justify-content:space-between;

    .levelWrapp{
      width:100%;
      max-width:400px;
      display:flex;
      align-items:center;
      .level{
        margin-right:8px;
      }
    }

    .winsLosses{
      max-width:71px;
      width:100%;
      text-align:center;
    }

    .setsWon{
      max-width:82px;
      width:100%;
      text-align:center;
    }

    .gamesWon{
      max-width:97px;
      width:100%;
      text-align:center;
    }

    .rank{
      max-width:30px;
      width:100%;
      text-align:center;
    }
    }
`;
