import styled from '@emotion/styled';
import LinkWrapper from '../Link';

export const Link = styled(LinkWrapper)({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flexDirection: "column",
  textDecoration: "none",
  lineHeight: 1,
});

export const Avatar = styled.div(({ theme, size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  width: size,
  height: size,
  border: `1px solid ${theme.colors.mediumTurquoise}`,
  borderRadius: "50%",

  ".profilePicture": {
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
  },
}));

export const Initials = styled.div(({ size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: size,
  width: size,

  userSelect: 'none',
  cursor: 'pointer',

  fontWeight: 700,
  fontSize: '14px',

  '&:hover': {
    textDecoration: 'none !important',
  },
}));
