import { maxBy, minBy } from 'lodash';
import timeToString from './timeToString';

const getCourtsWorkingHours = (courts, date, allIntervals = false) => {
  const day = new Date(date).getDay() || 7;

  const defaultInterval = {
    startHour: 6,
    endHour: 24,
  };

  if (courts) {
    const courtsIntervals = (courts || [])
      .reduce((acc, { availability = [] }) => {
        const data = availability.find((av) => av.day === day && av.enabled === true);

        if (data?.priceIntervals) {
          const intervals = data.priceIntervals
            .filter(({ availabilityDate }) => !availabilityDate || availabilityDate === date);
          acc.push(...intervals);
        }

        return acc;
      }, []);

    // return intervals as array
    if (allIntervals) {
      return courtsIntervals ?? [defaultInterval];
    }

    if (courtsIntervals.length) {
      const { startHour } = minBy(courtsIntervals, 'startHour');
      const { endHour } = maxBy(courtsIntervals, 'endHour');
      if (startHour && endHour) {
        const startTime = timeToString(startHour);
        const [startTimeHour] = startTime.split(":");
        const endTime = timeToString(endHour);
        const [endTimeHour] = endTime.split(":");

        return {
          startHour: Number(startTimeHour),
          endHour: Number(endTimeHour),
        };
      }
    }
  }

  return defaultInterval;
};

export default getCourtsWorkingHours;
