import React from 'react';
import { Trans } from 'react-i18next';
import LoadImage from '../../../../../../../../components/common/LoadImage';
import {
  InfoIcon, Tooltip, TooltipContainer, TooltipText,
} from './styles';

const TooltipRankedMatches = () => (
  <TooltipContainer>
    <InfoIcon src={LoadImage('info-icon.svg')} />
    <Tooltip>
      <TooltipText>
        <Trans ns="friendlyMatches" i18nKey="rankedTooltip.title">
          Winners will receive:
        </Trans>
        {' '}
        <br />

        <Trans ns="friendlyMatches" i18nKey="rankedTooltip.1">
          +4 points per win against equal levels
          {' '}
          <br />
        </Trans>
        {' '}
        <br />

        <Trans ns="friendlyMatches" i18nKey="rankedTooltip.2">
          +2 points per win against a lower level
          {' '}
          <br />
        </Trans>
        {' '}
        <br />

        <Trans ns="friendlyMatches" i18nKey="rankedTooltip.3">
          +6 points per win against a higher level
        </Trans>
        {' '}
        <br />
      </TooltipText>
    </Tooltip>
  </TooltipContainer>
);

export default TooltipRankedMatches;
