export default {
  hero: {
    title: 'Bun venit\nîn Sportya',
    desc: 'Sunt multe de aflat despre Sportya, așa că vom începe cu elementele esențiale. Explorează ghidul de mai jos pentru a afla cum funcționează sistemul de meciuri, evenimentele și clasamentele.',
  },
  firstPhase: {
    title: 'Ce Este Sportya?',
    desc: `Sportya este un ecosistem sportiv competitiv în care zeci de mii de jucători amatori participă la meciuri amicale, ligi și evenimente pentru a se bucura de meciuri echilibrate sau pentru a concura pentru cele mai bune locuri în clasamentele naționale și internaționale. Sportya este acum disponibil pentru tenis și padel, alte sporturi cu rachetă și sporturi de echipă urmând să fie adăugate în viitor.`,
    articles: {
      1: {
        title: 'Descoperă',
        desc: 'Odată ce te alături Sportya, devii parte a unei comunități mari și pasionate de jucători amatori și ai acces la evenimente organizate în sute de cluburi sportive. Ne extindem în noi regiuni și țări și oferim o mai mare diversitate, indiferent cum vei alege să joci – aproape de reședința ta, la cluburile partenere, sau vei căuta noi experiențe în întreaga lume.',
      },
      2: {
        title: 'Provocare',
        desc: 'Începe cu câteva meciuri amicale, alătură-te unei ligi locale dacă flexibilitatea programului este importantă pentru tine sau intră direct în evenimente competitive și luptă pentru a ajunge în fruntea clasamentelor naționale și internaționale pentru jucători amatori.',
      },
      3: {
        title: 'Conectează-te',
        desc: 'Păstrează-ți prietenii aproape, întâlnește alții noi, împărtășește-ți experiențele. Construiește și evidențiază profilul tău de jucător și profită la maximum de sporturile tale competitive preferate.',
      },
      4: {
        title: 'Nivelul Jocului',
        subtitle: 'Joacă Meciuri Echilibrate',
        desc: 'Fiecare membru Sportya joacă la un Nivel de Joc specific pentru fiecare sport disponibil. Nivelul determină evenimentele la care te poți alătura și facilitează potrivirea adecvată între jucători atât în meciurile competitive din cadrul turneelor, cât și în meciurile amicale, organizate în afara turneelor.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 Niveluri de Joc',
      desc: 'Sportya este un sistem deschis pentru toți jucătorii, de la debutanți la jucători experimentați. Evaluează-ți Nivelul de Joc în timpul creării contului, joacă și bucură-te de meciuri echilibrate. Oferim 6 niveluri de joc, pentru fiecare sport, fiecare determinat de un mix de abilități și experiență. Poți juca ca începător în tenis și ca jucător intermediar în padel, de exemplu.',
    },
    2: {
      title: 'Niveluri pentru Simplu și Dublu',
      desc: 'Le tratăm separat în Sportya. Vei seta inițial un nivel de joc care să indice potențialul jocului tău de simplu. Acesta va fi automat setat ca nivel de joc pentru meciurile tale de dublu. Cu toate acestea, pe măsură ce evoluezi, nivelurile sunt tratate separat și se modifică automat în funcție de rezultatele obținute.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Ligi Locale',
      subtitle: 'Joacă Cu Maximă Flexibilitate',
      desc: 'Ligile Locale îți permit să joci un meci pe săptămână, urmând un orar flexibil, păstrând în același timp spiritul competiției viu. Mai multe detalii vor fi disponibile pe măsură ce ne apropiem de data oficială de lansare.',
    },
    2: {
      title: 'Meciuri Amicale',
      subtitle: 'Găsește Noi Parteneri De Joc',
      desc: 'Meciurile amicale sunt cea mai flexibilă modalitate de a experimenta meciuri echilibrate în Sportya. Meciurile amicale pot fi jucate oricând, oriunde, cu alți membri Sportya. Sistemul nostru de matchmaking te va ajuta să găsești un partener de joc potrivit, să identifici o locație potrivită și să raportezi rezultatul online, contribuind la clasarea și profilul tău de jucător. Unele funcții sunt în dezvoltare în acest moment, dar merită așteptarea.',
    },
    comingSoon: 'Lansare în 2023',
    comingSoonFM: 'Lansare în Q1 2023',
    inDevelopment: 'În Dezvoltare',
  },
  fifthPhase: {
    title: 'Evenimente Competitive',
    subtitle: 'Cel Mai Bun Meci Te Așteaptă',
    desc: 'Piatră de temelie a Sportya, Evenimentele competitive Sportya sunt organizate într-un calendar anual, care îți oferă experiența de a concura în clasamente locale și globale pentru amatori și de a obține o poziție de top. Sportya este singurul loc unde sportivii amatori pot experimenta emoția de a fi jucători profesioniști.',
    articles: {
      1: {
        title: 'Categorii de Evenimente Clasificate',
        desc: '1st Serve, Silver, Gold, Platinum, Special și Tournament of Champions. Fiecare dintre categoriile menționate are un rol specific în calendar iar cerințele de înscriere diferă de la o categorie la alta. Pentru fiecare eveniment și sport, organizăm una sau mai multe competiții pe baza Nivelurilor de Joc, fie pentru Simplu, fie pentru Dublu.',
      },
      2: {
        title: 'Formatul Evenimentelor',
        desc: 'Bucură-te de o multitudine de formate, de la evenimente clasice, cu eliminare directă, organizate în weekend, până la evenimente în format grupe, evenimente nocturne și multe altele.',
      },
      3: {
        title: 'Formatul Meciurilor',
        desc: 'Cele mai multe meciuri de tenis din Sportya sunt jucate ca 2 seturi până la 6, cu Tie Break decisiv (10 puncte) în caz de egalitate la seturi. Padelul urmează o schemă similară, în timp ce pentru toate celelalte sporturi, formatele meciurilor sunt anunțate imediat ce lansăm un nou sport.',
      },
      4: {
        title: 'Pachet de Participare',
        desc: 'Pe lângă accesul la eveniment și toate beneficiile asociate desfășurării evenimentului în Sportya (coordonarea evenimentului, rezultate, clasamente, actualizări de activitate, suport pentru clienți etc.), taxele de participare în evenimentele Sportya includ, de obicei, 2 mingi premium noi alocate fiecărui meci (tenis, padel) și o sticlă de apă per meci.',
      },
    },
  },
  sixthPhase: {
    title: 'Începe să Joci',
    desc: 'În final, totul se reduce la asta – încurajăm competiția distractivă și captivantă, fundamentul practicării sportului, care în cele din urmă contribuie la progres în jocul tău și în viața de zi cu zi.',
  },

  playVideo: 'Vizionați',
};
