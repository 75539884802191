import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { patch } from '../../../../common/http';
import LoadImage from '../../../../common/LoadImage';
import Notification from './component/Notification';
import ContentLoader from '../../../../ContentLoader';

import { fetchNotifications, seenNotifications } from '../../../reducers/notifications';
import { P, Link, H4 } from '../../../../Collection';
import ToggleVisible from '../../../../common/ToggleVisible';
import Paragraph from '../../../../Paragraph';

import { CloseButton, ModalBackground } from './RaiseHandStyles';
import { Bell, NotificationDropdownList, NotificationsContainer } from './styles';

const NotificationDropdown = ({ whiteBell, page }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('accounts');
  const { data: list, status } = useSelector(state => state.dashboard.notifications.list);
  const { data: unreadNotifications } = useSelector(state => state.dashboard.notifications.unreadNotifications);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const isLoading = status === 'loading';
  const didNotFetch = status === 'idle';
  const hasNoResults = status === 'succeeded' && !list?.results?.length;

  const markAsSeen = async () => {
    dispatch(fetchNotifications())
      .then(() => dispatch(seenNotifications()))
      .then(() => patch('/notifications/seen'));
  };

  useEffect(() => {
    if (didNotFetch && !hasNoResults && isComponentVisible) {
      dispatch(fetchNotifications());
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && unreadNotifications > 0) {
      markAsSeen();
    }
  }, [unreadNotifications, isComponentVisible]);

  return (
    <Bell bellWhite={whiteBell}>
      {unreadNotifications > 0 && (
        <span className={`new-notifications ${page}`}>{unreadNotifications > 9 ? '9+' : unreadNotifications}</span>
      )}

      <img
        src={LoadImage(`bell-icon${whiteBell ? '-white' : ''}.svg`)}
        alt={t('notificationButton')}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />

      {isComponentVisible && (
        <>
          <ModalBackground zIndex={3} mobileOnly />
          <NotificationDropdownList ref={ref}>
            <CloseButton
              src={LoadImage('close-icon.svg')}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
              alt="Close notifications list"
            />
            <H4 margin="5px 0 20px 23px">
              <Trans ns="accounts" i18nKey="notifications.dropdown.title">Notifications</Trans>
            </H4>
            <NotificationsContainer>
              {isLoading && (<div className="mb30"><ContentLoader title type="notifications" items={5} /></div>)}

              {!isEmpty(list?.results) && (
                list?.results
                  .slice(0, 5)
                  .map((notification, key) => <Notification key={key} notification={notification} />)
              )}

              {isEmpty(list?.results) && !isLoading && (
                <P textAlign="center" margin="20px 0">
                  <Trans ns="accounts" i18nKey="notifications.noNotifications">No notifications</Trans>
                </P>
              )}
            </NotificationsContainer>

            {!isEmpty(list?.results) && (
              <Paragraph small bold className="see-all">
                <Link to="/my-account/notifications">
                  {t('notifications.dropdown.allNotifications')}
                </Link>
              </Paragraph>
            )}
          </NotificationDropdownList>
        </>
      )}
    </Bell>
  );
};

export default NotificationDropdown;
