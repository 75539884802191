import React from 'react';
import { Trans } from "react-i18next";
import dateFormat from "dateformat";
import H4 from "../../../components/H4";
import Paragraph from "../../../components/Paragraph";
import Button from "../../../components/Button";
import Link from "../../../components/Link";
import LoadImage from "../../../components/common/LoadImage";

const EventRegistrationInfo = ({ paymentInfo }) => {
  const doublesRestrictionLevel = paymentInfo?.competition?.level;
  const doublesMaxPlayerLevel = paymentInfo?.competition?.level - 0.5;

  const compRestrictions = paymentInfo?.competition?.restrictions;
  const restrictions = [
    (compRestrictions?.accountActivity !== '' && compRestrictions?.accountActivity !== 'any'),
    (compRestrictions?.accountType !== '' && compRestrictions?.accountType !== 'any'),
    (compRestrictions?.gender !== '' && compRestrictions?.gender !== 'any'),
    (compRestrictions?.minAge !== null && compRestrictions?.minAge > 0),
    (compRestrictions?.maxAge !== null && compRestrictions?.maxAge > 0),
    (compRestrictions?.rank > 0),
    compRestrictions?.forbiddenToTeams,
    (compRestrictions?.teamAverageLevel
    && paymentInfo?.competition?.gameType === 'doubles' ? doublesRestrictionLevel > 0 : false),
  ];

  return (
    <>
      <div className="infoCard">
        <ul className="event-info fs18 d-inline-flex">
          <li>
            <Trans ns="events" i18nKey="level">Level</Trans>
            {' '}
            {paymentInfo?.competition?.level}
          </li>
          <li>
            <Trans ns="common" i18nKey={paymentInfo?.event?.category} />&nbsp;(
            <Trans ns="common" i18nKey={`sports.${paymentInfo?.event?.sportType}`}>
              {paymentInfo?.event?.sportType}
            </Trans>)
          </li>
          <li><Trans ns="events" i18nKey={paymentInfo?.competition?.gameType} /></li>
          <li>
            {paymentInfo?.competition?.mlMaxNumber}
            {' '}
            <Trans ns="common" i18nKey="players">Players</Trans>
            {' '}
            (
            {paymentInfo?.competition?.numberOfGroups > 0 && `${paymentInfo?.competition?.numberOfGroups} `}
            <Trans ns="events" i18nKey={paymentInfo?.competition?.drawModel}>Eliminatory</Trans>
            {paymentInfo?.competition?.phases?.length > 0 && (
              <>
                {' '}
                +
                <Trans
                  i18nKey={paymentInfo?.competition?.phases[0]?.drawModel}
                  ns="events"
                >{` + ${paymentInfo?.competition?.phases[0]?.drawModel}`}
                </Trans>
              </>
            )}
            )
          </li>
          {restrictions[2] ? (
            <li><Trans ns="common" i18nKey={`only${compRestrictions?.gender}`}>Gender</Trans></li>
          ) : ''}
          {restrictions[3] && !restrictions[4] ? (
            <li>
              <Trans ns="common" i18nKey="minage">Min. Age</Trans>
              {' '}
              {`${compRestrictions?.minAge}+`}
            </li>
          ) : ''}
          {restrictions[4] && !restrictions[3] ? (
            <li>
              <Trans ns="common" i18nKey="maxage">Max. Age</Trans>
              {' '}
              {compRestrictions?.maxAge}
            </li>
          ) : ''}
          {restrictions[4] && restrictions[3] ? (
            <li>
              <Trans ns="common" i18nKey="age">Age</Trans>
              {' '}
              {`${compRestrictions?.minAge ?? 0}-${compRestrictions?.maxAge}`}
            </li>
          ) : ''}
          {restrictions[0] ? (
            <li>
              <Trans ns="events" i18nKey={`min${compRestrictions?.accountActivity}event`} />
            </li>
          ) : ''}
          {restrictions[1] ? (
            <li><Trans ns="events" i18nKey={paymentInfo?.competition?.restrictions?.accountType} /></li>
          ) : ''}
          {restrictions[5] ? (
            <li>
              <Trans ns="common" i18nKey="rank">Rank</Trans>
              {' '}
              {`${compRestrictions?.rank ?? 0}+`}
            </li>
          ) : ''}
          {restrictions[6] ? (
            <li>
              <Trans ns="events" i18nKey="restrictionsDoubleLevel">
                Maximum Doubles player level
                {{ level: doublesRestrictionLevel }}
              </Trans>
            </li>
          ) : ''}
          {restrictions[7] ? (
            <li>
              <Trans ns="events" i18nKey="restrictionsTeamLevel">
                Team max.level -
                {{ level: doublesMaxPlayerLevel }}
              </Trans>
            </li>
          ) : ''}
        </ul>

        <H4>{paymentInfo?.event?.name}</H4>
        <Paragraph className="date">
          {dateFormat(paymentInfo?.event?.startDate, 'mmmm d, yyyy')}
          {' '}
          -
          {dateFormat(paymentInfo?.event?.endDate, 'mmmm d, yyyy')}
        </Paragraph>
      </div>

      <div className="friendly-matches-banner text-center">
        <Paragraph className="mb30">
          <Trans ns="paymentConfirmation" i18nKey="playFriendlyMatch">
            While you&apos;re waiting for your competition to start, you can play a
            <strong>friendly match</strong> with your friends and fellow players on Sportya.
          </Trans>
        </Paragraph>
        <Button outline>
          <Link to="/friendly-matches">
            <img
              src={LoadImage('friendly-matches/match-form/tennis-ball-icon.svg')}
              alt="Invite to Match"
              className="mr10"
            />
            <Trans ns="friendlyMatches" i18nKey="goToFriendlyMatches">Go to Friendly Matches</Trans>
          </Link>
        </Button>
      </div>
    </>
  );
};

export default EventRegistrationInfo;
