import React from 'react';
import styled from '@emotion/styled';

const TextAreaStyle = styled.div`
textarea {
    border: 1px solid ${({ theme }) => theme.colors?.lightBlue1};
    border-radius: ${({ theme }) => theme.functions?.toEm(4)};
    resize: ${(props) => () => (props.resize ? '' : 'none')};
    max-width:${({ theme }) => theme.functions?.toEm(600)}; 
    width:100%;
    height:${({ theme }) => theme.functions?.toEm(190)}; 
    outline: none;
    padding:${({ theme }) => theme.functions?.toEm(15)};
    font-family: DM Sans;
    font-size: ${({ theme }) => theme.functions?.toEm(14)};
    line-height: ${({ theme }) => theme.functions?.toEm(18)};
    pointer-events: all;
    ${({ height }) => (height ? `height: ${height};` : '')}
  }
`;

const TextArea = (props) => {
  const {
    resize,
    errors,
    touched,
    placeholder,
    label,
    name,
    value,
    type,
    onChange,
    innerRef,
    defaultValue,
    height,
    maxLength,
    ...rest
  } = props;
  const focus = () => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  };

  return (
    <TextAreaStyle resize={resize} onFocus={() => focus()} height={height}>
      <textarea
        maxLength={maxLength ?? 120}
        name={name}
        placeholder={placeholder}
        {...rest}
        value={value}
        onChange={onChange}
        ref={innerRef}
        defaultValue={defaultValue}
      />
    </TextAreaStyle>
  );
};

export default TextArea;
