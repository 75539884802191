import styled from '@emotion/styled';
import { P } from '../../../../../components/Collection';

export const Container = styled.div(({ smallerMarginTop }) => ({
  width: '100%',
  margin: `${smallerMarginTop ? '40px' : '60px'} 0 0 0`,
}));

export const PlayersContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '10px 0 0 0',
  '@media (max-width: 1225px)': {
    width: '100%',
  },
});

export const PlayerContainer = styled.div({
  display: 'flex',
  padding: '20px',
  borderRadius: '10px',
  border: '1px solid #EAF6F6',
  background: '#fff',
  maxWidth: '602px',
  width: '100%',
  height: '111px',
  margin: '0 0 9px 0',
  justifyContent: 'space-between',

  '@media (max-width: 1225px)': {
    maxWidth: 'unset',
  },
  '@media (max-width: 767px)': {
    height: 'unset',
    flexDirection: 'column',
  },
});

export const PlayerAvatarContainer = styled.div(({ isDoubles, disableMargin, isPartner }) => ({
  position: 'relative',
  width: isDoubles ? '46px' : '60px',
  minWidth: isDoubles ? '46px' : '60px',
  height: isDoubles ? '46px' : '60px',
  margin: '0 15px',
  marginBottom: isDoubles ? 20 : 0,
  zIndex: isPartner ? 1 : 2,
  textAlign: 'center',

  ...!disableMargin && {
    margin: '0 15px',
  },

  '.game-level': {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    bottom: -10,
    width: 40,
    display: 'flex',
    flexDirection: 'row',

    '&__one': {
      width: 20,
    },
  },

  '@media (max-width: 991px)': {
    marginLeft: isDoubles ? 15 : 0,
  },
}));

export const PlayerAvatar = styled.div(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  position: 'relative',
  overflow: 'hidden',

  img: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
}));

export const Initials = styled.div(({ large, isDoubles }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: isDoubles ? '45px' : large ? 80 : 60,
  width: isDoubles ? '45px' : large ? 80 : 60,
  borderRadius: '50%',
  border: '1px solid #C1E6E5',
}));

export const PlayerMetaData = styled.div(({ autoLeftMargin, justifyCenter }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...autoLeftMargin && {
    margin: '0 0 0 auto',
  },
  ...justifyCenter && {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 1225px)': {
    margin: 'unset',
    alignItems: 'unset',
    justifyContent: 'unset',
    width: '100%',
  },

  '.location': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 2,
    maxWidth: 350,

    '@media (max-width: 1200px)': {
      maxWidth: '85%',
    },
  },
}));

export const PlayerID = styled.span({
  color: '#78ADAB',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '0em',
  margin: '0 0 0 5px',
});

export const FriendIcon = styled.img({
  margin: '0 10px 0 0',
  height: '18px',
  width: '18px',
});

export const Username = styled.span({
  display: 'flex',
  verticalAlign: 'middle',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '21px',
  letterSpacing: '0em',
  textDecoration: 'none !important',
});
export const FullName = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '@media (min-width: 1225px)': {
    maxWidth: 150,
  },

  '@media (max-width: 479px)': {
    maxWidth: "32vw",
  },
});

export const Location = styled(P)({
  margin: 'unset',
});

export const Distance = styled(P)({
  margin: '4px 0 0 0',
});

export const MatchType = styled.img({
  width: '48px',
  height: '48px',
  margin: '0 10px 0 0',

  '@media (max-width: 767px)': {
    display: 'none',
  },
});

export const ReasonText = styled(P)({
  display: 'flex',
  alignItems: 'center',

  '@media (max-width: 1200px)': {
    display: 'none',
  },

  '@media (max-width: 991px)': {
    display: 'flex',
  },
});

export const OptionIconPlaceholder = styled.div({
  width: '40px',
  height: '40px',
  '@media (max-width: 900px)': {
    display: 'none',
  },
});

export const OptionIcon = styled.img(({
  isReason, noBorder, hideMobile, hideTablet, hideDesktop, dropdownIcon, margin,
}) => ({
  width: '40px',
  height: '40px',
  objectFit: 'none',
  borderRadius: 5,

  ...margin && {
    margin,
  },

  '&.initiate-conversation': {
    border: '2px solid #EAF6F6',
  },

  ...!isReason && {
    cursor: 'pointer',
  },

  ...!noBorder && {
    '&:hover': {
      border: '2px solid #7cc9c666',
    },
  },

  ...isReason && {
    height: '20px',
    width: '20px',
  },

  '@media (max-width: 1200px)': {
    display: hideTablet ? 'none' : 'unset',

    ...dropdownIcon && {
      position: 'absolute',
      top: '20px',
      right: 0,
      border: '1px solid #d3faf8',
      borderRadius: '10px',
    },
  },

  '@media (max-width: 991px)': {
    display: (hideTablet || hideMobile) ? 'none' : 'unset',

    '&.ml10': {
      margin: 0,
    },
  },

  ...hideDesktop && {
    '@media (min-width: 991px)': {
      display: 'none',
    },
  },
}));

export const FirstPart = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const SecondPart = styled.div({
  display: 'flex',
  margin: '0 0 0 auto',
  '@media (max-width: 767px)': {
    margin: 'unset',
    width: '100%',
  },
});

export const NoDataContainer = styled.div({
  maxWidth: '400px',
  margin: '60px auto 90px auto',
  '@media (max-width: 1024px)': {
    margin: '30px auto 60px auto',
  },
});
