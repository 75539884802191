import React from 'react';

import { useSelector } from 'react-redux';
import { StyledWinners } from './styles';
import Paragraph from '../../../../../components/Paragraph';
import H4 from '../../../../../components/H4';
import Level from '../Level';
import PlayerBadge from '../PlayerBadge';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';
import LoadImage from '../../../../../components/common/LoadImage';

const Winners = () => {
  const { accountInfo } = useSelector(state => state.session);
  const { data } = useSelector(state => state.events?.matches);

  // get data from the last match
  const lastMatch = data[data.length - 1];
  const duoCompetition = lastMatch?.competition?.gameType !== 'singles';
  const noOfSets = 3;

  const isFriend = (userId) => accountInfo?.friendsIds?.includes(userId);

  const nameInitials = (player) => `${(player?.firstName?.charAt(0) + player?.lastName?.charAt(0)).toUpperCase()}`;

  return (
    <StyledWinners duo={duoCompetition}>
      {/* player */}
      <div className="left">
        <div className={`player ${lastMatch?.winner === 'player' && 'champion '} firstPlayer`}>
          <div className="playerImgWrapp">
            {lastMatch?.player?.profilePicture ? (
              <img src={lastMatch?.player?.profilePicture} alt="PlayerPhoto" />
            ) : (
              <H4>
                {nameInitials(lastMatch?.player)}
              </H4>
            )}
            <div className="playerLevel">
              <Level gold={lastMatch?.winner === 'player'} small level={lastMatch?.player?.level} />
            </div>
          </div>
          <div className="playerInfo">
            <Paragraph smaller>{lastMatch?.winner === 'player' ? 'Champion' : 'Finalist'}</Paragraph>
            <div className="playerName">
              <Paragraph medium bold>
                {isFriend(lastMatch?.player?.userId) && (
                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                )}
                {`${lastMatch?.player?.firstName } ${ lastMatch?.player?.lastName}`}
              </Paragraph>
              {' '}
              {lastMatch?.winner === 'player' && <PlayerBadge winner />}
            </div>
          </div>
        </div>

        {duoCompetition ? (
          <div className={`player ${lastMatch?.winner === 'player' && 'champion'}`}>
            <div className="playerImgWrapp">

              {lastMatch?.playerPartner?.profilePicture ? (
                <img src={lastMatch?.playerPartner?.profilePicture} alt="PlayerPhoto" />
              ) : (
                <H4>
                  {nameInitials(lastMatch?.playerPartner)}
                </H4>
              )}
              <div className="playerLevel">
                <Level gold={lastMatch?.winner === 'player'} small level={lastMatch?.playerPartner?.level} />
              </div>
            </div>
            <div className="playerInfo">
              <Paragraph smaller>{lastMatch?.winner === 'player' ? 'Champion' : 'Finalist'}</Paragraph>
              <div className="playerName">
                <Paragraph medium bold>
                  {isFriend(lastMatch?.playerPartner?.userId) && (
                    <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                  )}
                  {`${lastMatch?.playerPartner?.firstName } ${ lastMatch?.playerPartner?.lastName}`}
                </Paragraph>
                {' '}
                {lastMatch?.winner === 'player' && <PlayerBadge winner />}
              </div>
            </div>
          </div>
        ) : ''}
      </div>

      {/* score */}
      <div className="scoreWrapp">
        <Paragraph small bold>Final Score</Paragraph>

        <div className="scorePoints">
          {
            new Array(noOfSets).fill().map((el, index) => {
              const [oponentScore] = (lastMatch?.opponent?.score || []).filter(
                ({ set }) => set === index + 1,
              );
              const [playerScore] = (lastMatch?.player?.score || []).filter(
                ({ set }) => set === index + 1,
              );
              return (
                <Paragraph bold className="score" key={index}>
                  {`${playerScore?.points || 0 }-${ oponentScore?.points || 0}`}
                </Paragraph>
              );
            })
          }
        </div>
      </div>

      {/* opponent */}
      <div className="right">
        <div className={`player ${lastMatch?.winner === 'opponent' && 'champion '} firstPlayer`}>
          <div className="playerImgWrapp">
            {lastMatch?.opponent?.profilePicture ? (
              <img src={lastMatch?.opponent?.profilePicture} alt="PlayerPhoto" />
            ) : (
              <H4>
                {nameInitials(lastMatch?.opponent)}
              </H4>
            )}
            <div className="playerLevel">
              <Level gold={lastMatch?.winner === 'opponent'} silver small level={lastMatch?.opponent?.level} />
            </div>
          </div>
          <div className="playerInfo">
            <Paragraph className="title" smaller>
              {lastMatch?.winner === 'opponent' ? 'Champion' : 'Finalist'}
            </Paragraph>
            <div className="playerName">
              <Paragraph medium bold>
                {isFriend(lastMatch?.opponent?.userId) && (
                  <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                )}
                {`${lastMatch?.opponent?.firstName } ${ lastMatch?.opponent?.lastName}`}
              </Paragraph>
              {lastMatch?.winner === 'opponent' && <PlayerBadge winner />}
            </div>
          </div>
        </div>

        {duoCompetition ? (
          <div className="right">
            <div className={`player ${lastMatch?.winner === 'opponent' && 'champion '}`}>
              <div className="playerImgWrapp">

                {lastMatch?.opponentPartner?.profilePicture ? (
                  <img src={lastMatch?.opponentPartner?.profilePicture} alt="PlayerPhoto" />
                ) : (
                  <H4>
                    {nameInitials(lastMatch?.opponentPartner)}
                  </H4>
                )}
                <div className="playerLevel">
                  <Level
                    gold={lastMatch?.winner === 'opponent'}
                    silver
                    small
                    level={lastMatch?.opponentPartner?.level}
                  />
                </div>
              </div>
              <div className="playerInfo">
                <Paragraph className="title" smaller>
                  {lastMatch?.winner === 'opponent' ? 'Champion' : 'Finalist'}
                </Paragraph>
                <div className="playerName">
                  <Paragraph medium bold>
                    {isFriend(lastMatch?.opponentPartner?.userId) && (
                      <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                    )}
                    {`${lastMatch?.opponentPartner?.firstName } ${ lastMatch?.opponentPartner?.lastName}`}
                  </Paragraph>
                  {lastMatch?.winner === 'opponent' && <PlayerBadge winner />}
                </div>
              </div>
            </div>
          </div>
        ) : ''}

      </div>

    </StyledWinners>
  );
};

export default Winners;
