import styled from '@emotion/styled';

export const Initials = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  width: '60px',
  border: '1px solid #7CC9C6',
  borderRadius: '50%',

  userSelect: 'none',
  cursor: 'pointer',

  fontWeight: 700,
  fontSize: '18px',

  '&:hover': {
    textDecoration: 'none !important',
  },
});
