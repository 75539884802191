import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
position: relative;
// z-index: 5;
text-align: center;
  background: ${props => ({ theme }) => (!props.outline
  ? (props.bg ? props.bg : theme.colors?.black) : theme.colors?.white)};
  border-radius: ${({ theme }) => theme.functions?.toEm(4)};
  display: block;
  border: none;
  ${props => props.max && `
  min-width: max-content;`}
  ${props => (props.maxwidth ? `
  max-width: 250px;
  width: 100%;
  @media screen and (max-width:540px){
    width: auto;
    max-width: unset;
  }
  `
    : (props.fullwidth ? 'width: 100%' : 'width: auto'))};
transition: all 0.3s ease;

  ${props => !props.bg && ` 
box-shadow: inset 0 0 0 2px ${props.theme.colors?.primary};
  `}
  font-weight: 700;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  height: fit-content;
  color: ${props => ({ theme }) => (!props.outline
      ? (props.color ? props.color : theme.colors?.white) : theme.colors?.black)};
  padding: 0 ${props => ({ theme }) => (props.wide ? theme.functions?.toEm(40) : theme.functions?.toEm(25))};
  font-size: ${props => ({ theme }) => (props.large ? theme.functions?.toEm(18) // large
        : (props.small ? theme.functions?.toEm(14) // small
          : (props.smaller ? theme.functions?.toEm(12) // smaller
            : theme.functions?.toEm(16) // normal
          )
        ))
  };
  line-height: ${props => () => (props.large ? '60px' // large
    : (props.small ? '40px' // small
      : (props.smaller ? '30px' // smaller
        : '50px' // normal
      )
    ))
  };
  
  a {
    text-decoration: none;
  }

  &:hover {
    ${props => ({ theme }) => (!props.outline
    ? (props.bg
      ? 'filter: brightness(85%)'
      : `background: ${theme.colors?.primary}`)
    : `background: ${theme.colors?.white}`)};
    ${props => (!props.bg ? `
    box-shadow: inset 0 0 0 ${(!props.outline ? '3px' : '4px')} 
    ${({ theme }) => (!props.outline ? theme.colors?.secondary : theme.colors?.primary)};
    ` : '')}
    color: ${props => ({ theme }) => (!props.outline
      ? (props.color ? props.color : theme.colors?.secondary) : theme.colors?.primary)};
  }

  &:disabled {
    background: ${props => ({ theme }) => (!props.outline ? theme.colors?.btnDisabled : theme.colors?.white)};
    box-shadow: ${props => ({ theme }) => (!props.outline ? 'none' : `inset 0 0 0 2px ${theme.colors?.btnDisabled}`)};
    cursor: not-allowed;
    color: ${props => ({ theme }) => (!props.outline ? theme.colors?.white : theme.colors?.btnDisabled)};

    &:hover {
      color: ${props => ({ theme }) => (!props.outline ? theme.colors?.white : theme.colors?.btnDisabled)};
    }
  }
`;

/**
 *
 * @param children
 * @param props: fullwidth, wide, outline, disabled, large, small, smaller
 * @returns {JSX.Element}
 * @constructor
 */
const Button = ({ children, ...props }) => (
  <StyledButton {...props}>
    {children}
    {' '}
    {props.arrow && '⟶'}
  </StyledButton>
);

export default Button;
