import filtersList from "../constants/filtersList";

const handlePhaseChange = ({
  selectedValue, toolkit, params, clubId, t,
}) => {
  const phase = filtersList(t).find(v => v.value === selectedValue.value);

  const newParams = {
    ...params,
    eventStatus: selectedValue.value,
    page: 1,
    clubId,
  };

  if (selectedValue.value === 'all') {
    delete newParams.eventStatus;
  }

  toolkit.setSelectedPhase(phase);
  toolkit.setParams(newParams);
  toolkit.setCurrentPage(0);
  toolkit.fetchClubEvents(newParams);

  return null;
};

export default handlePhaseChange;
