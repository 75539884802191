const BeforePlaying = (eventInfo, currentPhase) => {
  let hasPassed = false;

  const now = Date.now();
  const startDate = new Date(eventInfo?.phases?.playing?.startDate);
  const playingMinus24 = startDate.setDate(startDate.getDate() - 1);

  if ([
    'openingSoon',
    'preRegistrationsOpen',
    'registrationsOpen',
    'readyToPlay',
    'drawPending',
    'sortPending'].includes(currentPhase)) {
    hasPassed = now <= playingMinus24;
  }

  return hasPassed;
};

export default BeforePlaying;
