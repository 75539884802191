import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';

export const FinalConfirmation = styled.div`
  min-height: 85vh;

  .friendly-matches-banner {
    background: url('${LoadImage('friendly-matches/reg-banner.png')}') center center;
    background-size: cover;
    padding: 35px 30px;
    margin-top: 50px;
    border-radius: 10px;

    > div {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
    margin-top: 30px;
  }
}

.wrapp_confirmation {
  max-width: ${({ theme }) => theme.functions?.toEm(1234)};
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin: 0;    
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: ${({ theme }) => theme.functions?.toEm(28)} !important;
  }

  .mainConfirmation {
    max-width: 600px;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
}

.mainConfirmation {
  .event-info {
    margin-top: 25px;
    justify-content: center;
    flex-flow: wrap;

    li {
      margin: 5px 15px;
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.functions?.toEm(38)};
    line-height: 50px;
    text-align: center;
  }

  .virtualWallet {
    padding-top: 40px;
    margin: 0 auto;
    text-align: center;
    max-width: ${({ theme }) => theme.functions?.toEm(320)};

    @media screen and (max-width: 700px) {
      padding-top: 10px;
    }

    p {
      font-size: ${({ theme }) => theme.functions?.toEm(14)};
      font-weight: 400;
    }

    span {
      font-weight: 700;
    }
  }

  .buttonsWrap .btn {
    max-width: 290px !important;
  }

  .logoplaceholder {
    width: ${({ theme }) => theme.functions?.toEm(60)};
    height: ${({ theme }) => theme.functions?.toEm(60)};
    margin: 0 auto 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme?.colors?.primary};
    border-radius: 50%;

    img {
      width: 16.02px;
      height: 11.81px;
      filter: invert(1) hue-rotate(175deg);
    }
  }

  h2 {
    font-weight: 700;
    font-size: ${({ theme }) => theme.functions?.toEm(38)};
    line-height: 49.48px;
    text-align: center;
    padding-top: 20px;

    @media screen and (max-width: 768px) {
      font-size: ${({ theme }) => theme.functions?.toEm(25)};
    }

    @media screen and (max-width: 425px) {
      font-size: ${({ theme }) => theme.functions?.toEm(18)};
      margin-bottom: 0px;

    }
  }

  h4 {
    font-size: ${({ theme }) => theme.functions?.toEm(20)};
    line-height: 26.04px;
    text-align: center;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      font-size: ${({ theme }) => theme.functions?.toEm(16)};
      margin: 0 0 10px 0;
    }
  }

  .infoCard {
    background-color: ${({ theme }) => theme?.colors?.white};
    margin-top: 40px;
    border: 1px solid #EAF6F6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    @media screen and (max-width: 768px) {
      max-width: 400px;
      margin: 0 auto;
      p {
        font-size: ${({ theme }) => theme.functions?.toEm(16)};
      !important;
      }

      h3 {
        margin: 0;
      }

      div {
        max-width: ${({ theme }) => theme.functions?.toEm(350)};
      !important;
      }
    }
    @media screen and (max-width: 425px) {
      max-width: ${({ theme }) => theme.functions?.toEm(300)};;
    }

    h3 {
      margin-bottom: 10px;
    }

    div {
      margin-top: 33px;
      max-width: ${({ theme }) => theme.functions?.toEm(409)};;
      flex-wrap: wrap;
      margin-bottom: 7px;
      display: flex;
      justify-content: center;


      p {
        margin: 0;
        font-size: ${({ theme }) => theme.functions?.toEm(18)};
        line-height: 26px

      }

      .dot {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .date {
      margin: 10px 0 36px 0;
    }

  }

  .buttonsWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-gap: 10px;

    @media screen and (max-width: 425px) {
      max-width: 300px;
    }

    .btn {
      max-width: 250px;
      width: 100%;

      .back {
        color: ${({ theme }) => theme?.colors?.white};
      }

      p {
        margin: 0;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin: 20px auto;
      .btn {
        margin-top: 10px;
        max-width: 400px;

      }
    }
  }

  .booking-info {
    background-color: ${({ theme }) => theme?.colors?.white};
    margin-top: 20px;
    border: 1px solid ${({ theme }) => theme?.colors?.lightBlue};
    border-radius: 10px;
    padding: 40px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    
    hr {
      border-top: none;
      border-color: ${({ theme }) => theme?.colors?.lightBlue};
      margin: 30px 0;
    }

    .overview {
      list-style: "none";
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;

      li {
        display: flex;
        justify-content: space-between;
        
        p {
          margin: 0;
        }
        
        &.friendly-match {
          flex-direction: column;
          gap: 10px;
          
          .friendly-match__title {
            display: flex;
            gap: 10px;
          }
          
          a:hover {
            text-decoration: none;
          }
        }

        &.match-players {
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }
        

      .link {
        color: ${({ theme }) => theme?.colors?.darkTurquoise};
        text-decoration: underline;
        cursor: pointer;
      }

      ul {
        list-style: none;
        display: flex;
        gap: 10px;
      }
      }
    }

    @media screen and (max-width: 479px) {
      padding: 20px;
    }
  }
`;
