const isUndisputed = (player, opponent, double, playerPartner, opponentPartner) => {
  const flags = ['NO_SHOW', 'ABANDONED', 'BYE'];
  const hasReasons = flags.includes(player?.reason || player?.type)
    || flags.includes(opponent?.reason || opponent?.type);

  const points = {
    playerPoints: player?.score?.map(a => a?.points),
    playerPartnerPoints: playerPartner?.score?.map(a => a?.points) ?? 0,
    opponentPoints: opponent?.score?.map(a => a?.points),
    opponentPartnerPoints: opponentPartner?.score?.map(a => a?.points) ?? 0,
    method: (acc, curr) => acc + curr,
    get undisputed() {
      return double
        ? (this.playerPoints || []).reduce(this.method, 0) + (this.opponentPoints || []).reduce(this.method, 0)
        + (this.playerPartnerPoints || [])
          .reduce(this.method, 0) + (this.opponentPartnerPoints || []).reduce(this.method, 0)
        : (this.playerPoints || []).reduce(this.method, 0) + (this.opponentPoints || []).reduce(this.method, 0);
    },
  };

  return !points.undisputed && !hasReasons;
};

export default isUndisputed;
