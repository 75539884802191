import { post } from '../../../components/common/http';

export const resetPassword = async ({
  values,
  setSubmitting,
  addToast,
  history,
  t,
}) => {
  try {
    await post('/accounts/reset-password', values);

    await (
      addToast
      && addToast(t('passwordResetSuccessfully'), {
        appearance: 'success',
        autoDismiss: true,
      })
    );
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }

  if (history) {
    return history.push('/login');
  }
};
