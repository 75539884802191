const secureLink = url => {
  const [protocol, ...rest] = url.split(':');
  const isHttp = protocol === 'http' || protocol === 'https';
  const isWww = !isHttp && protocol.substring(0, 3) === 'www';
  const secureProtocol = isHttp ? 'https' : 'https:';
  const newProtocol = isWww ? `${secureProtocol}//${protocol}` : `${secureProtocol}:${rest.join(':')}`;
  return newProtocol;
};

export default secureLink;
