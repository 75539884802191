export default {
  seoTitle: 'Очаквайте скоро нови възможности | Sportya',

  item1: {
    title: 'Български',
    subtitle: '(Нов език)',
    text: `Работим неуморно по версията на Български език на платформата на Sportya. Разбираме колко важно е това за комуникацията с Вас. 
        Скоро ще е готова.`,
  },

  item2: {
    title: 'Съобщения в системата',
    text: 'Решихме да отложим пускането на новата система за съобщения, тъй като планираме да разширим нейната свързаност с други услуги на Sportya. Това е от съществено значение за повторното стартиране на Приятелските мачове, заедно с новата система за управление на вашите връзки в Sportya.',
  },

  item3: {
    title: 'Приятелски мачове',
    subtitle: '(Рестарт)',
    text: 'Това не е изцяло нова функция и играчите-ветерани я познават добре. Надстройките, които се разработват, ще ви позволят да намерите партньор за състезателен мач по-бързо от преди и с по-висока степен на уместност по отношение на конкурентоспособността и удовлетворението от мача.',
  },

  item4: {
    title: 'Списък с приятели',
    text: 'Това ще бъде новият начин да общувате с приятелите си в Sportya, да разширите мрежата си и да поддържате връзка през цялата година. Както бе споменато по-горе, по-нататъшните интеграции със системата за съобщения и приятелските мачове ще ви позволят да извлечете повече преживявания в тениса.',
  },

  item5: {
    title: 'Блог за новини',
    text: 'В Sportya вярваме, че трябва да сте част от комуникацията. Поради това нашата секция за новини и актуализации ще бъде стартирана отново като блог. Планираме да ви държим по-близо до случващото се в Sportya и да ви дадем средствата да допринесете за нейното бъдеще.',
  },

  item6: {
    title: 'Намери партньор',
    subtitle: '(за двойки)',
    text: 'Борбата за намиране на партньор за игра на двойки скоро ще приключи. Представяме Ви често искана функция: възможност за намиране на други играчи, които искат да се присъединят към отбор, за конкретно събитие. Очаквайте скоро, за събитие близо до вас.',
  },

  item7: {
    title: 'Резервации',
    text: 'Заздравяваме връзката между вас и корта. Това е Sportya Booking - много повече от просто резервационна система. Очаквайте да намерите голямо разнообразие от кортове и тясна интеграция с други услуги на Sportya. Крайният резултат – ще организирате мач по-бързо от всякога, независимо дали е приятелски или състезателен.',
  },

  item8: {
    title: 'Резултати на живо',
    text: 'За първата итерация на системата ще пуснем отново възможността както за директорите на събитията, така и за играчите да въвеждат резултати от мача, по-бързо и по-лесно от преди. По-нататък, новата система ще ни позволи да разработим както софтуерни, така и хардуерни решения, които ще трансформират изживяването ви в мача и ще ви позволят да останете фокусирани и да се насладите на играта.',
  },

  item9: {
    title: 'Управление на събития',
    subtitle: '(Клубове и организатори)',
    text: 'Ще започнем процес на дълбока интеграция с всички партньорски клубове на Sportya, свързвайки нашите услуги с техните операционни системи. Това от своя страна ще ни позволи да разработим нови услуги за играчи и да позволим на клубовете да взаимодействат с вас по-добре, предоставяйки по-бързи и по-точни услуги и цялостно изживяване за своите членове и клиенти.',
  },

  comingSoon: 'Очаквайте скоро',
  worthTheWait: 'Заслужава си чакането',

  stayTuned: 'Следете за още новини!',
};
