import { patch } from '../../../components/common/http';

export const updateAccount = async (props) => {
  const {
    id,
    payload,
    addToast,
    socketClientId,
    t,
  } = props;

  try {
    await patch(`/accounts/${id}`, { socketClientId, ...payload });
  } catch (error) {
    return (
      addToast
      && addToast(t(error?.response?.data?.message || 'UPDATE_ACCOUNT_FAILED'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};
