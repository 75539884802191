import styled from '@emotion/styled';

export const BracketStyle = styled.div`
width:100%;
overflow: hidden;
.container {
  width: 100%;
  min-width: 18em;
  margin: 20px auto;
}

.tournament-bracket--rounded{
  position: relative;
}

.qualTitle{
  position: absolute;
  left: 170px;
  top:3px;
}

.tbaWrapp{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img{
    width:20px;
    height:20px;
    margin-right: 7px;
  }
}

.positionRelative{
  position: relative;
}

.tieScore{
   position: absolute;
   right: -6px;
   top: -10px;
}

.qualificationMatchIdentifier{
   position: absolute;
   left: -40px;
   top: 42%;
   margin: 0px;
}

.colTitle{
  display:flex;
  justify-content:center;

  p{
    margin: 0;
  }
}

.bracketNavArrow{
  position: absolute;
  top: 25px;
}

.rotate{
    transform: rotate(180deg);
}

.qualificationMatch{
  position: absolute;
  left: -480px;
}

.moreQualificationsFirstMatch{
  position: absolute;
  left: -480px;
  transform: translateY(-60px);
}

.moreQualificationsFirstMatch::before {
  position: absolute !important;
  left: 348px !important;
  content: '' !important;
  display: block !important;
  width: 65px !important;
  height: 63px !important;
  top:62px;
  transform: rotate(180deg) !important;
}

.moreQualificationsFirstMatch::after{
  content: '' !important;
  display: block !important;
  position: absolute !important;
  left: 413px !important;
  top: 131px !important;
  width: 80px !important;
  height: 5px !important;
}

.moreQualificationsSecondMatch{
  position: absolute;
  left: -480px;
  transform: translateY(70px);
}

.moreQualificationsSecondMatch::after{
  position: absolute !important;
  left: 348px !important;
  content: "" !important;
  display: block !important;
  width: 65px !important;
  transform: rotate(180deg) !important;
  top: 6px !important;
  height: 65px !important;
}

.moreQualificationsSecondMatch::before{
  content: '' !important;
  display: block !important;
  position: absolute !important;
  left: 413px !important;
  top:-6px !important;
  width: 80px !important;
  height: 5px !important;
}

.trophyIcon{
  position:absolute;
  right:-9px;
  top:16px;
}

.abandonMargin { 
  margin-right: 30px;
}

.boldText {
  font-weight: bold;
}

.goldText, .goldText a {
  color: ${({ theme }) => theme?.colors?.gold} !important;
}

.mt8{
  margin-top:8px;
}

.bracketInfo{
display:flex;
algin-items:center;
height:13px;

> p {
padding: 5px 10px;
}

p{
  margin:0 7px 0 0;
  color: ${({ theme }) => theme?.colors?.darkTurquoise};
  font-size:10px;
}
}

.playerWrapp{
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  .you {
    width: 100%;
    background: #EAF6F6;
    border-radius: 4px;

    &__winner {
      background: #EAF6F61C !important;
    }
  }

  .player {
    display:flex;
    justify-content:space-between;
    align-items:center;

    .playerInfo {
      display:flex;
      align-items:center; 
      position:relative;
      min-width: 120px;
      padding: ${props => (props.type ? '3px 10px' : '5px 10px')};
      width: max-content;

      a:not(.darkBracketMatch a) {
        text-decoration: none;
        color: #10242C;

        &:hover {
          text-decoration: underline;
        }
      }

    .name{margin-left:6px;margin-right: 7px;}}
      position:relative;
      .matchNumber{
        left:-38px;
        top:${(props => (props.type ? '15px' : '0'))};
        position:absolute;
        color: ${({ theme }) => theme?.colors?.disabledBlue};
        }

    .playerScore{
      display:flex;
      position:${(props => (props.type ? 'absolute' : 'relative'))};
      width: 50px;
      right:0;
      margin-right: 5px;
      min-width: 50px;
      justify-content: flex-start;
      > div > p {
        min-width: 15px;
      }
    }
    p{
      margin:0;
    }
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.tournament-bracket {
  display: flex;
  flex-direction: column;
}
  .tournament-bracket {
    overflow:scroll;
    width: calc(100% + 20px);
    flex-direction: row;
    ::-webkit-scrollbar {
    height:9px !important;
    width:9px;
  }

{/* add padding only if we have qualifications from props +++ */}
  padding-left:${(props => props.qualifications && '495px')};

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme?.colors?.lightBlue};
  outline: none;
}
  }

.tournament-bracket-phase {
  display: flex;
  flex-direction: column;
}
  .tournament-bracket-phase {
    overflow:scroll;
    flex-direction: row;
    ::-webkit-scrollbar {
    height:9px !important;
    width:9px;
  }

{/* add padding only if we have qualifications from props +++ */}
  padding-left:${(props => props.qualifications && '495px')};

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme?.colors?.lightBlue};
  outline: none;
}
  }


.tournament-bracket__round {
  display: block;
  max-width:360px;
  width:100%;
  margin-left: 49px;
  flex: 1;
  min-width: 360px;
}
.tournament-bracket__round-title {
  color: #9e9e9e;
  font-size: 0.95rem;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  margin-bottom: 0.5em;
}
.tournament-bracket__list {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  padding-bottom: 2em;
  margin-bottom: 2em;
}
.tournament-bracket__round:last-of-type .tournament-bracket__list {
  padding-left: 0;
  border: 0;
}
.tournament-bracket__item {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  min-width:410px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2% 0;
  transition: padding 0.2s linear;
  margin-top:28px !important;
  margin-bottom:28px !important;
}
.tournament-bracket__item:nth-of-type(odd) {
  margin-right: 2%;

}
.tournament-bracket__item:nth-of-type(even) {
  margin-left: 2%;
}
.tournament-bracket__item::after {
  transition: width 0.2s linear;
}

.tournament-bracket__item {
  width: 100%;
}
.tournament-bracket__item:nth-of-type(odd),
.tournament-bracket__item:nth-of-type(even) {
  margin-left: 0;
  margin-right: 0;
}

.tournament-bracket__item {
  padding: 0.5em 1em;
  width: 100%;
}
.tournament-bracket__item:nth-of-type(odd),
.tournament-bracket__item:nth-of-type(even) {
  margin: 0;
}
.tournament-bracket__item::after {
  position: absolute;
  right: 0;
  content: '';
  display: block;
  width: 1em;
  height: 45%;
  border-right: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
}
.tournament-bracket__item:nth-of-type(odd)::after {
  top: 50%;
  border-top: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
  transform: translateY(0px);
}
.tournament-bracket--rounded .tournament-bracket__item:nth-of-type(odd)::after {
  border-top-right-radius: 0.6em;
}
.tournament-bracket__item:nth-of-type(even)::after {
  bottom: 50%;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
  transform: translateY(0px);
}
.tournament-bracket--rounded .tournament-bracket__item:nth-of-type(even)::after {
  border-bottom-right-radius: 0.6em;
}
.tournament-bracket__round:first-of-type .tournament-bracket__item {
  padding-left: 0;
}
.tournament-bracket__round:last-of-type .tournament-bracket__item {
  padding-right: 0;
}
.tournament-bracket__round:last-of-type .tournament-bracket__item::after {
  display: none;
}
.tournament-bracket__round:nth-last-of-type(2) .tournament-bracket__item::after {
}


.tournament-bracket__item::after {
  width: 10em;
}

@media (min-width: 72em) {
  .tournament-bracket__item {
    padding: 0.5em 1.5em;
  }
}

.darkBracketMatch{
  > .you {
    background: #EAF6F624 !important;
  }

  .positionRelative {
    color: white;
  }

  a {
    text-decoration: none;
    color: #fff;
      &:hover {
        text-decoration: underline;
      }
  }

  background-color:${({ theme }) => theme?.colors?.primary} !important;
  .bracketInfo{
    p {
      color: ${({ theme }) => theme?.colors?.darkTurquoise} !important;
      }
  }
 p {
   color: white;
   }
}

.tournament-bracket__match {
  height: ${(props => (props.type ? '177px' : '122px'))};
  display: flex;
  flex-direction: column;
  padding:18px 20px;
  width: max-content;
  min-width: 350px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
  outline: none;
  z-index: 2;
}

.tournament-bracket__match::before,
.tournament-bracket__match::after {
  transition: all 0.2s linear;

}
@media (max-width: 24em) {
  .tournament-bracket__match {
    padding: 0.75em 0.5em;

  }
}

.tournament-bracket__match::before,
.tournament-bracket__match::after {
  position: absolute;
  left: 0;
  z-index: 1;
  content: '';
  display: block;
  width: 1em;
  height: 10%;
  border-left: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
}
.tournament-bracket__match::before {
  bottom: 50%;
  border-bottom:1px solid ${({ theme }) => theme?.colors?.disabledBlue};
  transform: translate(0, 1px);
}
.tournament-bracket--rounded .tournament-bracket__match::before {
  border-bottom-left-radius: 0.6em;
}
.tournament-bracket__match::after {
  top: 50%;
  border-top: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
  transform: translate(0, -1px);
}
.tournament-bracket--rounded .tournament-bracket__match::after {
  border-top-left-radius: 0.6em;
}

@media (min-width: 72em) {
  .tournament-bracket__match::before,
  .tournament-bracket__match::after {

    width: 1.5em;
  }
  .tournament-bracket__match::before {
    transform: translate(0, 1px);
  }
  .tournament-bracket__match::after {
    transform: translate(0, -1px);
  }
}

.tournament-bracket__round:first-of-type .tournament-bracket__match::before,
.tournament-bracket__round:first-of-type .tournament-bracket__match::after {
  display: none;

}
.tournament-bracket__content {
  display: flex;
}
.tournament-bracket__content::after {
  content: ':';
  width: 1em;
  text-align: center;
  padding: 0.2em 0.1em;
}
@media (min-width: 38em) {
  .tournament-bracket__content::after {
    order: 1;
  }
}
.tournament-bracket__content .tournament-bracket__team:first-of-type {
  width: 50%;
  order: 0;
  text-align: right;
}
@media (min-width: 38em) and (max-width: 52em) {
  .tournament-bracket__content .tournament-bracket__team:first-of-type {
    align-items: flex-end;
  }
}
.tournament-bracket__content .tournament-bracket__team:first-of-type .tournament-bracket__country {
  order: 2;
  justify-content: flex-end;
}
@media (min-width: 24em) {
  .tournament-bracket__content .tournament-bracket__team:first-of-type .tournament-bracket__country {
    order: 0;
  }
}
@media (min-width: 38em) and (max-width: 52em) {
  .tournament-bracket__content .tournament-bracket__team:first-of-type .tournament-bracket__country {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
.tournament-bracket__content .tournament-bracket__team:first-of-type .tournament-bracket__score {
  order: 0;
}
@media (min-width: 24em) {
  .tournament-bracket__content .tournament-bracket__team:first-of-type .tournament-bracket__score {
    order: 2;
  }
}
.tournament-bracket__content .tournament-bracket__team:last-of-type {
  width: 50%;
  order: 2;
  text-align: left;
}
@media (min-width: 38em) and (max-width: 52em) {
  .tournament-bracket__content .tournament-bracket__team:last-of-type {
    align-items: flex-start;
  }
}
@media (min-width: 38em) {
  .tournament-bracket__content .tournament-bracket__team:last-of-type .tournament-bracket__country {
    justify-content: flex-start;
  }
}
@media (min-width: 38em) and (max-width: 52em) {
  .tournament-bracket__content .tournament-bracket__team:last-of-type .tournament-bracket__country {
    align-items: flex-start;
  }
}
.tournament-bracket__content .tournament-bracket__team:last-of-type .tournament-bracket__code {
  order: 1;
}
.tournament-bracket__table {
  width: 100%;
}
.tournament-bracket__caption {
  font-size: 0.8rem;
  color: #BDBDBD;
  font-weight: 300;
  padding-bottom: 0.75em;
}
.tournament-bracket__team {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media (min-width: 24em) {
  .tournament-bracket__team {
    flex-direction: column-reverse;
  }
}
.tournament-bracket__round--bronze .tournament-bracket__item::after {
  border-right:0;
}
.lit {
  background-color: pink;
}
.winner2 {
  background-color: cyan;
}

.tournament-bracket__round:first-of-type .tournament-bracket__match.qualificationMatch {

  &::after{
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 58%;
    right: 0;
    left:100%;
    background-color: ${({ theme }) => theme?.colors?.lightBlue};
  }
}

.scrollButtonsWrapp{
   position: relative;
   display: flex;
   justifyContent: space-between;
   width: 100%;
}

.navigationArrow{
  border-radius:200px;
  cursor: pointer;
  padding-left:16px;
  padding-right:16px;
  padding-top:14px;
  background-color:white;
  padding-bottom:14px;
  position: absolute;
  top: 14px;
  z-index: 1 !important;
}

.navigateArrowLeft{
  left: 13px;
}

.navigationRightArrow{
  top: 14px;
  right: 13px;
}

.qualificationTitle{
  position: absolute;
  ${'' /* top: -40px; */}
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-bottom:0px;
  margin-top:0px;
  font-size: ${({ theme }) => theme.functions?.toEm(21)} !important;
  font-weight: bold !important;
  text-align: center;
}

.emptyScore{
   display:flex;
   justify-content:center;
   margin: 0 auto;
}

`;
