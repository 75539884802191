import React from 'react';

import Paragraph from '../../../../../components/Paragraph';
import { ResultsStyleHeaderGroups, ResultsStyleHeader } from './styles';

const ResultsHeader = ({ league }) => (
  <>
    {league ? (
      <ResultsStyleHeaderGroups>
        <div className="players"><Paragraph smaller>Players</Paragraph></div>
        <div className="winsLosses"><Paragraph smaller>Wins/Losses</Paragraph></div>
        <div className="setsWon"><Paragraph smaller>Sets Won/Lost</Paragraph></div>
        <div className="gamesWon"><Paragraph smaller>Games Won/Lost</Paragraph></div>
        <div className="rank"><Paragraph smaller>Rank</Paragraph></div>
      </ResultsStyleHeaderGroups>
    ) : (
      <ResultsStyleHeader>
        <div className="headerPhase">
          <Paragraph bold smaller>Phase/Round </Paragraph>
        </div>

        <div className="headerInfo">

          <div className="headerInfoLeft">
            <Paragraph bold smaller>
              Players
            </Paragraph>
            <div className="scores">
              <Paragraph bold smaller>
                Scores
              </Paragraph>
            </div>
          </div>

          <div className="headerInfoRight">
            <div className="winsLeft">

              <Paragraph bold smaller>Wins/Losses</Paragraph>
              <Paragraph bold smaller>Sets Won/Lost</Paragraph>

            </div>
            <div className="winsRight">
              <Paragraph bold smaller>Game Won/Lost</Paragraph>
              <Paragraph bold smaller>Leaderboard</Paragraph>

            </div>
          </div>

        </div>
      </ResultsStyleHeader>
    )}
  </>
);

export default ResultsHeader;
