import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import { Contain, P } from '../../../../components/Collection';
import Select from '../../../../components/Form/Select';
import {
  fetchTabData, getActiveYears, getLevelChanges, setActiveFilters,
} from '../../reducers';
import GameType from '../components/GameType';
import { TabPlaceholder, RefPosition } from '../components/styles';

import {
  Column,
  Container,
  Event,
  EventBody,
  EventFooter,
  EventHeader,
  EventName,
  EventsContainer,
  EventTotalWonPoints,
  FiltersContainer,
  LevelChanged,
  LevelChangedDate,
  TableHeader,
  TabOptionsContainer,
} from './styles';

import EventMeta from './components/eventMeta';
import Match from './components/matches';
import LoadImage from '../../../../components/common/LoadImage';
import rankBeforeEvent from '../../../../components/common/rankBeforeEvent';
import { Badge } from '../../../../components/LevelsBadges/styles';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/ContentLoader';
import { UserContext } from '../..';
import ActivityFriendlyMatch from './components/ActivityFriendlyMatch';

const defaultOptions = {
  limit: 10,
  page: 1,
};

const ActivityTab = ({ activeSports, masterSport }) => {
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const { userId, profile } = user || {};
  const { t } = useTranslation(['common', 'friendlyMatches', 'events', 'clubs']);
  const [gameType, setGameType] = useState('singles');
  const listRef = useRef(null);

  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const {
    data,
    status,
    levelChanges,
    activeFilters,
  } = useSelector(state => state.accounts.info.tabs[profile].activity[gameType]);
  const years = useSelector(state => state.accounts.info.tabs[profile].years[gameType]);

  const [filters, setFilters] = useState({ sportType: masterSport, ...activeFilters });
  const [options, setOptions] = useState(defaultOptions);

  const events = data?.results;
  const initialFetch = (isEmpty(data) && status === 'idle');
  const hasNoResults = !events?.length && !levelChanges?.length;
  const isLoading = generalStatus === 'loading';

  useEffect(() => {
    if (options.page > 1) {
      handlePagination({ selected: 0 });
    }
  }, [gameType]);

  useEffect(() => {
    if (
      status === "succeeded"
      && !isEmpty(activeFilters)
      && !isEmpty(filters)
      && JSON.stringify(filters) !== JSON.stringify(activeFilters)
    ) {
      dispatch(setActiveFilters({
        gameType,
        profile,
        tab: 'activity',
        filters,
      }));
      dispatch(fetchTabData({
        userId,
        tab: 'activity',
        gameType,
        filters,
        options,
        profile,
      }));
    }
  }, [dispatch, filters, activeFilters]);

  useEffect(() => {
    if (initialFetch) {
      setOptions(defaultOptions);

      dispatch(setActiveFilters({
        gameType,
        profile,
        tab: 'activity',
        filters: {
          sportType: masterSport,
          ...filters,
        },
      }));

      dispatch(fetchTabData({
        userId,
        tab: 'activity',
        gameType,
        filters: {
          sportType: masterSport,
          ...filters,
        },
        options: defaultOptions,
        profile,
      }));
    }
  }, [initialFetch, dispatch, masterSport]);

  useEffect(() => {
    if (!isEmpty(events)) {
      const startDate = events?.length ? dateFormat(events?.[events?.length - 1]?.date, 'yyyy-mm-dd') : false;
      const endDate = events?.[0] && options.page > 1 ? dateFormat(events[0]?.date, 'yyyy-mm-dd') : false;
      if (startDate || endDate) {
        dispatch(getLevelChanges({
          userId,
          gameType,
          ...!filters.year && {
            startDate,
            endDate,
          },
          ...filters.year && {
            year: filters.year,
          },
          profile,
          sportType: filters.sportType,
        }));
      }
    }
  }, [events, status]);

  const activityOptions = [{
    label: t('allActivity', { ns: 'friendlyMatches' }),
    value: 'all',
  }, {
    label: t('competitions', { ns: 'events' }),
    value: 'competition',
  }, {
    label: t('friendlyMatches', { ns: 'friendlyMatches' }),
    value: 'friendly',
  },
  ];

  const getActivityOption = (type) => activityOptions.find(option => option.value === type);

  const handlePagination = (paginationData) => {
    const { selected } = paginationData || {};
    const selectedPage = selected + 1;

    if (options.page !== selectedPage) {
      const newOptions = {
        ...options,
        page: selectedPage,
      };

      setOptions(newOptions);

      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return dispatch(fetchTabData({
        userId,
        tab: 'activity',
        gameType,
        filters,
        options: newOptions,
        profile,
      }));
    }
    return null;
  };

  function handleFilters(filtersData, name) {
    const newFilters = { ...filters };
    if (!filtersData) {
      delete newFilters[name];
    } else {
      newFilters[name] = filtersData?.value;
    }

    const newOptions = {
      ...options,
      page: 1,
    };

    setOptions(newOptions);
    setFilters(newFilters);

    dispatch(setActiveFilters({
      gameType,
      profile,
      tab: 'activity',
      filters: newFilters,
    }));

    return dispatch(fetchTabData({
      userId,
      tab: 'activity',
      gameType,
      filters: newFilters,
      options: newOptions,
      profile,
    }));
  }

  const mapYears = () => {
    if (!isEmpty(years)) {
      const sortedYears = years?.slice().sort((a, b) => (a > b ? -1 : 1));

      return sortedYears?.map(year => ({
        label: year,
        value: year,
      }));
    }
  };

  const handleYears = () => {
    dispatch(getActiveYears({
      userId,
      gameType,
      profile,
      sportType: filters.sportType,
      tab: 'activity',
    }));
  };

  if (isLoading && options.page === 1) {
    return (
      <Container hasLoader>
        <Loader />
      </Container>
    );
  }

  const results = [...events || [], ...levelChanges || []]
    .slice()
    .sort((a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime());

  return (
    <Container>
      <RefPosition ref={listRef} />
      <TabOptionsContainer>
        <GameType gameType={gameType} setGameType={setGameType} />
        <FiltersContainer>
          <Select
            name="year"
            placeholder={t('allYears')}
            onChange={(e) => handleFilters(e, 'year')}
            onFocus={handleYears}
            options={mapYears()}
            label={t('year')}
            isClearable
            isSearchable={false}
            value={filters.year}
            lowInput
            {...filters.year && {
              labelOn: true,
              value: { label: filters.year, value: filters.year },
            }}
          />
          <Select
            name="matchType"
            placeholder={t('allActivity', { ns: 'friendlyMatches' })}
            onChange={(e) => handleFilters(e, 'matchType')}
            options={activityOptions}
            label={t('activity')}
            value={filters.matchType}
            lowInput
            width="150px"
            {...filters.matchType && {
              labelOn: true,
              value: getActivityOption(filters.matchType),
            }}
          />
          <Select
            name="sportType"
            placeholder={t('sports.all')}
            onChange={(e) => handleFilters(e, 'sportType')}
            options={['all', ...activeSports].map((sport) => ({
              label: t(`sports.${sport}`),
              value: sport,
            }))}
            label={t('form.sport', { ns: 'clubs' })}
            value={filters.sportType}
            lowInput
            width="150px"
            {...filters.sportType && {
              labelOn: true,
              value: ['all', ...activeSports]
                .filter(sport => sport === filters.sportType)
                .map((sport) => ({
                  label: t(`sports.${sport}`),
                  value: sport,
                })),
            }}
          />
        </FiltersContainer>
      </TabOptionsContainer>
      <EventsContainer>
        {results.map((event, key) => {
          if (event.matchType === 'competition' || event.newLevel) {
            const {
              eventName, date, level, gameType: eventGameType, category, genderRestrictions,
            } = event || { eventName: t('notAvailable') };
            const withGroups = event?.matches?.filter(a => a.group);
            const withStages = event?.matches?.filter(a => a.stage >= 0).sort((a, b) => (a.stage > b.stage ? -1 : 1));
            const matches = [...withStages || [], ...withGroups || []];
            return event?.newLevel ? (
              <LevelChanged key={key}>
                <P small margin="0 5px 0 0">
                  <Trans ns="player" i18nKey="upgradedAt">
                    Upgraded to Level
                  </Trans>
                </P>
                <Badge small className="mr5 badge" double={filters.gameType === 'doubles'}>
                  <P xSmall>
                    {event?.newLevel}
                  </P>
                </Badge>
                <LevelChangedDate>{dateFormat(event?.date, 'd mmm yyyy')}</LevelChangedDate>
              </LevelChanged>
            ) : (
              <Event key={key}>
                <EventHeader doubles={filters.gameType === 'doubles'}>
                  <P bold xSmall className="mb0 mt0">
                    <Trans ns="common" i18nKey={`sports.${event.sportType}`}>{event.sportType}</Trans>
                  </P>
                  <EventName to={`/events/${event.eventId}/competitions/${event.competitionId}`}>{eventName}</EventName>
                  <EventMeta {...{
                    category,
                    date,
                    level,
                    gameType: eventGameType,
                    clubId: event?.clubInfo?.id,
                    location: `${event?.clubInfo?.clubName}, ${event?.clubInfo?.location?.city}`,
                    restriction: genderRestrictions,
                  }}
                  />
                </EventHeader>
                <EventBody>
                  <TableHeader>
                    <Column maxWidth={140}>
                      <P xSmall>
                        <Trans ns="player" i18nKey="round">
                          Round
                        </Trans>
                      </P>
                    </Column>
                    <Column maxWidth={300}>
                      <P xSmall>
                        <Trans ns="player" i18nKey="opponent">
                          Opponent
                        </Trans>
                      </P>
                    </Column>
                    {/* <Column maxWidth={160}>ELO Rating</Column> */}
                    <Column maxWidth={250} flex justify="center">
                      <P xSmall>
                        <Trans ns="player" i18nKey="leaderboard">
                          Rank
                        </Trans>
                      </P>
                    </Column>
                    <Column maxWidth={300} flex justify="center">
                      <P xSmall>
                        <Trans ns="player" i18nKey="score">
                          Score
                        </Trans>
                      </P>
                    </Column>
                    <Column maxWidth={20} flex justify="center" xMargin="0 0 0 auto">
                      <P xSmall>
                        <Trans ns="player" i18nKey="wL">
                          W/L
                        </Trans>
                      </P>
                    </Column>
                  </TableHeader>
                  {matches?.map((match, matchKey) => (
                    <Match key={matchKey} match={match} gameType={filters.gameType} />
                  ))}
                </EventBody>
                <EventFooter>
                  {rankBeforeEvent(event, userId, user, t)}
                  <EventTotalWonPoints xSmall bold>+ {event.totalWonPoints} pts.</EventTotalWonPoints>
                </EventFooter>
              </Event>
            );
          }
          return (<ActivityFriendlyMatch event={event} key={key} user={user} />);
        })}

        {hasNoResults && !initialFetch && (
          <Contain
            width="fit-content"
            align="center"
            direction="column"
            margin="40px auto"
          >
            <TabPlaceholder src={LoadImage('tab-placeholder.png')} alt="No results found" />
            <P color="#78ADAB">
              <Trans ns="common" i18nKey="noResultsFound">
                No results found
              </Trans>
            </P>
          </Contain>
        )}
      </EventsContainer>
      {data?.totalPages > 1 && (
        <Pagination
          pageCount={data?.totalPages || 0}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePagination}
          forcePage={options.page - 1}
        />
      )}
    </Container>
  );
};

export default ActivityTab;
