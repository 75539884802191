export default {
  seoTitle: 'Zaregistrovat se | Sportya',
  tosAgree: 'Souhlasím s <1>{{ process.env.REACT_APP_SITE_NAME }}</1> <3>Smluvní vztahy a podmínky/3> a <5>Zásady ochrany soukromí</5>, které zahrnují mùj souhlas s kontaktováním ohlednì používání služeb <1>{{ process.env.REACT_APP_SITE_NAME }}</1> mimo jiné vèetnì registrace a úèasti na akcích, správy pøátelských zápasù a jakékoli jiné interní komunikace.',
  ageAgree: 'Registrací potvrzuji, že je mi nejménì <1>15 let</1>',
  createAccount: 'Vytvoøit úèet',
  alreadyHaveAnAccount: 'Již máte úèet?',
  signupWith: 'Nebo se zaregistrujte u',
  newsletterSubscribe: 'Pøihlašuji se k odbìru newsletteru <1>{{ process.env.REACT_APP_SITE_NAME }}</1> , který obsahuje informace o událostech <1>{{ process.env.REACT_APP_SITE_NAME }}</1> a další pøíležitosti hraní v mé oblasti, nové funkce a vybrané propagaèní akce a slevy tøetích stran od našich blízkých partnerù / sponzorù',
  countriesComingSoon: 'Již brzy budou dostupné další zemì!',
  title: 'Zaregistrovat se',
  subTitle: 'Platforma Sportya je k dispozici hráèùm v <1> Rumunsko, Moldavská republika, Bulharsko, Bahrajn, Maïarsko a Èeská republika.</1>',
  oldUserInfo: `<0>Už máte úèet Tenis Partener?</0> 
  Nemusíte si zakládat nový úèet Sportya. Vaše data již byla migrována a vy se mùžete <2>Pøihlásit</2> pod e-mailem a heslem úètu Tenis Partener.`,
};
