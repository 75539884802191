// store.js
import React, { createContext, useReducer } from 'react';
import {
  type as socketType,
  socketReducer,
  socketState,
} from './actions';

const initialState = {
  ...socketState,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((reducerState, action) => {
    switch (action.type) {
    case socketType:
      return socketReducer(reducerState, action);
    default:
      throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
