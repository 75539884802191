import React, { useEffect, useState, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';

import { useTranslation } from 'react-i18next';
import { store as socketStore } from '../Socket/store';
import {
  fetchLanguages,
  setCurrentLanguage,
} from '../Layout/reducers/languages';

import Select from './Select';
import { updateAccount } from '../../pages/MyAccount/Settings/components/actions';
import { getAccountInfo } from '../Layout/reducers/session';

const loggedLangSelect = ({ classes, NoLabel, LoggedIn }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useContext(socketStore);
  const {
    state: {
      socket: { socketClientId, socket },
    },
  } = globalState;

  const { accountInfo } = useSelector(state => state.session);
  const { data, status, currentLanguage } = useSelector((state) => state.languages);
  const [languages, setLanguages] = useState(currentLanguage);
  const [triggerListener, setTriggerListener] = useState(false);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchLanguages());
    }

    if (status === 'succeeded') {
      setLanguages(
        (data || []).map((language) => ({
          value: language.code,
          label: commonT(`languages.${language.code}`),
        })),
      );

      if (isEmpty(currentLanguage?.value) && !isEmpty(data)) {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : 'en';
        const [lang] = data
          .filter((language) => language.code === languageCode)
          .map((language) => ({ value: language.code, label: language.name }));

        if (triggerListener) {
          handleChangeLanguage(lang);
        }
      }
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (triggerListener) {
      const listenForResponse = async ({ success, message }) => {
        await addToast(commonT(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
        });

        if (success) {
          dispatch(getAccountInfo());
        }
      };

      if (socket) {
        socket.removeAllListeners('accounts-response');
        socket.on('accounts-response', listenForResponse);

        return () => {
          socket.removeAllListeners('accounts-response');
        };
      }
      setTriggerListener(false);
    }
  }, [socketClientId]); // eslint-disable-line

  const handleChangeLanguage = (selectedOption) => {
    setTriggerListener(true);
    let url = history.location.pathname + history.location.search;

    if (selectedOption?.value !== i18next.language) {
      if (i18next.options.fallbackLng.includes(selectedOption?.value)) {
        if (
          history.location.pathname.includes(
            `/${i18next.options.fallbackLng.toString()}`,
          )
        ) {
          url = history.location.pathname.replace(
            `/${i18next.options.fallbackLng.toString()}`,
            '',
          );
        } else {
          url = history.location.pathname.replace(`/${i18next.language}`, '');
        }
      } else {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : null;

        if (!languageCode) {
          url = `/${selectedOption?.value}${history.location.pathname}`; // add language in url
        } else {
          url = history.location.pathname.replace(
            `/${languageCode}`,
            `/${selectedOption?.value}`,
          ); // update language
        }
      }
    }

    history.replace(url, { ...history.location.state });

    i18next.changeLanguage(selectedOption?.value).then(() => {
      dispatch(setCurrentLanguage(selectedOption));
    });
  };

  return LoggedIn ? (
    <Formik
      initialValues={{
        language: accountInfo?.language ?? 'en',
      }}
      onSubmit={async ({ setSubmitting }) => {
        const payload = {
          language: currentLanguage.value,
        };

        await updateAccount({
          id: accountInfo.id,
          payload,
          addToast,
          setSubmitting,
          socketClientId,
          dispatch,
          history,
          t: commonT,
        });
      }}
    >
      {() => (
        <Form className={classes}>
          <Select
            name="language"
            placeholder={commonT(`languages.${currentLanguage.value ?? 'en'}`)}
            value={commonT(currentLanguage)}
            onChange={(lang) => handleChangeLanguage(lang)}
            options={languages}
            {...(!NoLabel && { lowPlaceholder: true })}
            {...(!NoLabel && { label: t('profileSettings.languageCommunication.language') })}
            isDisabled={status === 'loading' || isEmpty(languages)}
            isSearchable={false}
          />
        </Form>
      )}
    </Formik>
  ) : (
    <Select
      className={classes}
      placeholder={commonT(`languages.${currentLanguage.value ?? 'en'}`)}
      value={commonT(currentLanguage)}
      onChange={(lang) => handleChangeLanguage(lang)}
      options={languages}
      {...(!NoLabel && { lowPlaceholder: true })}
      {...(!NoLabel && { label: t('profileSettings.languageCommunication.language') })}
      isDisabled={status === 'loading' || isEmpty(languages)}
      isSearchable={false}
      footerLangSelect
    />
  );
};

export default loggedLangSelect;
