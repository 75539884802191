import React from 'react';

import { Badge } from './styles';
import { H3, P } from '../../../../../components/Collection';

const index = ({
  level, small, gold, silver, className,
}) => (
  <Badge gold={gold} silver={silver} small={small} {...className && { className }}>
    {small
      ? <P xSmall bold>{level}</P>
      : <H3>{level}</H3>}
  </Badge>
);

export default index;
