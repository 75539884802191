import React, { useState, useRef } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Option from './Option';
import OptionLink from '../../../FindAPlayer/components/Search/Option';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import { get } from '../../../../components/common/http';
import LoadImage from '../../../../components/common/LoadImage';
import { P } from '../../../../components/Collection';
import mixpanel from '../../../../mixpanel';

import {
  Container,
  Input,
  SearchResults,
  SearchIcon,
  ClearResults,
} from './styles';

const SearchBar = ({
  setPlayer,
  initiateConversation = true,
  placeholder = null,
  userId,
  mixpanelEvent,
  ...props
}) => {
  const { t } = useTranslation('conversations');
  const inputRef = useRef();
  const [suggestions, setSuggestions] = useState(null);
  const hasOptions = !isEmpty(suggestions);
  const { hasSearched } = useSelector(state => state?.accounts?.list);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { masterSport } = useSelector(state => state.session);

  const fetch = async player => {
    const params = new URLSearchParams({
      sportType: masterSport,
      string: player,
      ignoreBlocked: true,
    }).toString();
    const { data: { data } } = await get(`/find-players/search?${params}`);
    setSuggestions(data);
  };

  const debouncedFetch = debounce(fetch, 150);
  const debouncedFetch2 = debounce(fetch, 50);

  const handleSearching = async player => {
    if (player.length <= 60) {
      if (player.length >= 20) {
        await debouncedFetch(player);
      } else {
        await debouncedFetch2(player);
      }
      setIsComponentVisible(true);
    }
  };

  const clearResults = () => {
    setSuggestions(null);
    inputRef.current.value = '';
  };

  const showResults = () => {
    if (hasOptions) {
      return setIsComponentVisible(true);
    }
  };

  const handleKeyDown = async key => {
    if (key === 'Enter' && !hasSearched) {
      // setIsComponentVisible(false);
    }
  };

  const handleClick = (player) => {
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent);
    }
    setIsComponentVisible(false);
    setPlayer({
      id: player?._id,
      ...player,
    });
    clearResults();
  };

  return (
    <Container {...props}>
      <Input
        maxLength={60}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onClick={() => showResults()}
        onInput={e => handleSearching(e.target.value)}
        placeholder={placeholder ?? t('findPlayersAndStartConversation')}
        ref={inputRef}
      />

      <SearchIcon src={LoadImage('search-icon_black.svg')} />

      {(hasOptions || hasSearched) && (
        <ClearResults onClick={() => clearResults()}>
          <img src={LoadImage('close-icon.svg')} alt="Close" />
        </ClearResults>
      )}

      {hasOptions && isComponentVisible && (
        <SearchResults ref={ref}>
          {
            (suggestions?.results || [])
              .filter((v) => v.userId !== Number(userId))
              .map((player, key) => (
                initiateConversation
                  ? <Option player={player} key={key} onClick={() => handleClick(player)} />
                  : <OptionLink player={player} key={key} />
              ))
          }

          {!suggestions?.results?.length && (
            <P textAlign="center">
              <Trans ns="common" i18nKey="noResultsFound">No results found.</Trans>
            </P>
          )}
        </SearchResults>
      )}
    </Container>
  );
};

export default SearchBar;
