import React from 'react';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';

import { P } from '../../../../../components/Collection';
import { Column, RankContainer, TrendIcon } from '../styles';
import { LevelChanged, LevelChangedDate } from '../../Activity/styles';
import { Badge } from '../../../../../components/LevelsBadges/styles';

const Rank = ({ rank: event, isDoubles }) => {
  const { t: commonT } = useTranslation('common');
  const {
    week, createdAt, rank, trend, newLevel,
  } = event || {};

  dateFormat.i18n = {
    dayNames: [
      commonT('Sun'),
      commonT('Mon'),
      commonT('Tue'),
      commonT('Wed'),
      commonT('Thu'),
      commonT('Fri'),
      commonT('Sat'),
      commonT('Sunday'),
      commonT('Monday'),
      commonT('Tuesday'),
      commonT('Wednesday'),
      commonT('Thursday'),
      commonT('Friday'),
      commonT('Saturday'),
    ],
    monthNames: [
      commonT('Jan'),
      commonT('Feb'),
      commonT('Mar'),
      commonT('Apr'),
      commonT('May'),
      commonT('Jun'),
      commonT('Jul'),
      commonT('Aug'),
      commonT('Sep'),
      commonT('Oct'),
      commonT('Nov'),
      commonT('Dec'),
      commonT('January'),
      commonT('February'),
      commonT('March'),
      commonT('April'),
      commonT('May'),
      commonT('June'),
      commonT('July'),
      commonT('August'),
      commonT('September'),
      commonT('October'),
      commonT('November'),
      commonT('December'),
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
  };

  const trendArrow = {
    up: '▲',
    down: '▼',
    no: '—',
  };

  const trendColor = {
    up: '#00DB30',
    down: '#F42B03',
    no: '#C1E6E5',
  };

  return newLevel ? (
    <LevelChanged>
      <P small margin="0 5px 0 0">
        <Trans ns="player" i18nKey="upgradedAt">
          Upgraded to Level
        </Trans>
      </P>
      <Badge small className="mr5 badge" double={isDoubles}>
        <P xSmall>
          {newLevel}
        </P>
      </Badge>
      <LevelChangedDate>{dateFormat(event?.createdAt, 'd mmm yyyy')}</LevelChangedDate>
    </LevelChanged>
  ) : (
    <RankContainer>
      <Column maxWidth={35} flex justify="center">
        <P small bold margin="unset">
          {week}
        </P>
      </Column>
      <Column maxWidth={520} flex justify="center">
        <P small bold margin="unset">
          {dateFormat(createdAt, 'd mmm')}
        </P>
      </Column>
      <Column maxWidth={520} flex justify="center">
        <P small bold margin="unset">
          {rank}
        </P>
      </Column>
      <Column maxWidth={35} flex justify="center">
        <TrendIcon small bold trendColor={trendColor[trend]}>
          {trendArrow[trend]}
        </TrendIcon>
      </Column>
    </RankContainer>
  );
};

export default Rank;
