export default {
  friendsSeoTitle: 'Prieteni | Sportya',

  title: 'Prieteni',

  tabs: {
    allFriends: 'Toți Prietenii ({{friendsCount}})',
    friendRequests: 'Cereri Primite ({{friendsCount}})',
    sentRequests: 'Cereri Trimise ({{friendsCount}})',
  },
  unfriendText: 'Șterge Prieten {{name}}',
  unfriend: 'Șterge Prieten',
  unfriendConfirmationText: 'Ești sigur că vrei să îl stergi pe {{name}} din lista ta de prieteni?',
  noRequestsSent: 'Nu ai primit încă nicio cerere nouă de prietenie',
  noRequestsReceived: 'Nu ai trimis încă nicio cerere nouă de prietenie',
  wantToAddFirstFriend: 'Vrei să adaugi primul prieten în lista ta?',
  confirm: 'Acceptă',
  decline: 'Respinge',
  cancelRequest: 'Anulează Cererea',
  rankedMatch: 'Meci Competitiv',
  unrankedMatch: 'Meci de Antrenament',
};
