import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from "lodash";
import { get } from '../../components/common/http';

export const fetchAccounts = createAsyncThunk('accounts/fetchAccounts', async (params, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const activeParams = Object.fromEntries(Object.entries(params).filter(([, v]) => !!v));
  const filterParams = new URLSearchParams({ sportType, ...activeParams }).toString();
  const { data: { data } } = await get(`/find-players?${filterParams}`);

  return { data, sportType };
});

// eslint-disable-next-line max-len
export const fetchAccountsLocations = createAsyncThunk('events/fetchAccountsLocation', async ({ filters }, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const filterParams = new URLSearchParams({ sportType, ...filters }).toString();
  const { data: { data } } = await get(`/accounts/locations?${filterParams}`);

  return data;
});

// Slice
const accounts = createSlice({
  name: 'accounts',
  initialState: {
    data: [],
    initial: [],
    newest: [],
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
    setAccounts: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    resetAccounts: (state) => {
      state.data = state.initial;
    },
  },
  extraReducers: {
    [fetchAccounts.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccounts.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
      state.initial = action.payload.data;

      if (isEmpty(state.newest) || state.sportType !== action.payload.sportType) {
        state.newest = [...state.data?.results?.slice(0, 8)];
      }

      state.sportType = action.payload.sportType;
    },
    [fetchAccounts.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const accountsLocations = createSlice({
  name: 'locations',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    resetAccountsLocations: (state) => {
      state.data = [];
      state.status = 'idle';
    },
  },
  extraReducers: {
    [fetchAccountsLocations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccountsLocations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchAccountsLocations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const activeFilters = createSlice({
  name: 'activeFiltersAccounts',
  initialState: {
    data: {},
  },
  reducers: {
    setActiveFilters: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

export const {
  setAccounts,
  resetAccounts,
} = accounts.actions;
export const { setActiveFilters } = activeFilters.actions;
export const { resetAccountsLocations } = accountsLocations.actions;

export default {
  list: accounts.reducer,
  locations: accountsLocations.reducer,
  activeFilters: activeFilters.reducer,
};
