/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';

const cleanArray = (arr, value) => {
  // If incoming value is not an array, split it (quick convert) and then filter -> join it back into a string.
  if (arr.constructor !== Array) {
    return arr.split(',').filter((e) => e !== value).join(',');
  }
  // If already is an array, just filter and return as string
  return arr.filter((e) => e !== value).join(',');
};

function handleCheckbox(name, value, form, filters, unique) {
  let newFilters = { ...form };

  if (value === 'all' && name === 'category') {
    delete form[name];
    delete filters[name];
    delete newFilters[name];
    value = undefined;
  }

  // if (value === 'all' && name === 'gender')
  // {
  //     const list = ['male', 'female'];
  //     const hasMatchesForm = list.every(e => form[name]?.split(',').includes(e));
  //     const hasMatchesFilters = list.every(e => filters[name]?.split(',').includes(e));

  //     if (hasMatchesForm)
  //     {
  //         delete newFilters[name];
  //         delete form[name];
  //     } else
  //     {
  //         form[name] = 'male,female';
  //         newFilters[name] = 'male,female';
  //     }

  //     if (hasMatchesFilters)
  //     {
  //         delete newFilters[name];
  //         delete filters[name];
  //     } else
  //     {
  //         filters[name] = 'male,female';
  //         newFilters[name] = 'male,female';
  //     }

  //     value = undefined;
  // } else if (value === 'all' && name === 'country')
  // {
  //     const list = ['romania', 'moldova', 'bulgaria'];
  //     const hasMatchesForm = list.every(e => form[name]?.split(',').includes(e));
  //     const hasMatchesFilters = list.every(e => filters[name]?.split(',').includes(e));

  //     if (hasMatchesForm)
  //     {
  //         delete newFilters[name];
  //         delete form[name];
  //     } else
  //     {
  //         form[name] = 'romania,moldova,bulgaria';
  //         newFilters[name] = 'romania,moldova,bulgaria';
  //     }

  //     if (hasMatchesFilters)
  //     {
  //         delete newFilters[name];
  //         delete filters[name];
  //     } else
  //     {
  //         filters[name] = 'romania,moldova,bulgaria';
  //         newFilters[name] = 'romania,moldova,bulgaria';
  //     }

  //     value = undefined;
  // }

  // If our filters state already contains our selected value, delete it from the form
  if (filters[name] && filters[name]?.split(',').includes(value) && value !== undefined) {
    // Filters array and delete value
    const cleanFilters = cleanArray(filters[name], value);

    isEmpty(cleanFilters)
      ? delete filters[name]
      : filters[name] = cleanFilters;

    isEmpty(cleanFilters)
      ? delete newFilters[name]
      : newFilters[name] = cleanFilters;

    // [Debugging]
    // console.log('Removing already existing value in filters: ', value)
    //  console.log('Replacing with cleaned version: ', cleanFilters)
    // [Debugging]

    return newFilters;
  }

  // If our form state already contains our selected value, delete it from the form
  if (form[name] && form[name]?.split(',').includes(value) && value !== undefined) {
    // Filters array and delete value
    const cleanForms = cleanArray(form[name], value);

    isEmpty(cleanForms) ? delete form[name] : form[name] = cleanForms;
    isEmpty(cleanForms) ? delete newFilters[name] : newFilters[name] = cleanForms;

    // [Debugging]
    // console.log('Removing already existing value in form: ', value)
    // console.log('Replacing with cleaned version: ', cleanForms)
    // [Debugging]

    return newFilters;
  }

  // If filter category already exists in form but does not contain our new value
  if (
    (form[name]
      && !form[name].split(',').includes(value)
      && value !== undefined)
    || (filters[name]
      && !filters[name].split(',').includes(value)
      && value !== undefined)
  ) {
    if ((form[name] && !form[name].split(',').includes(value) && value !== undefined)) {
      newFilters = {
        ...form,
      };
      // Convert old values to string to avoid double arrays (eg: levelSingles: [[4,5] 6,7,8])
      const newValues = [form[name], value].join(',');
      newFilters[name] = newValues;

      // [Debugging]
      // console.log('Adding value in form: ', value, ' - ADDED')
      // [Debugging]

      if (unique) {
        form[name] = value;
        newFilters[name] = value;
      }
    }

    if (filters[name] && !filters[name].split(',').includes(value) && value !== undefined) {
      newFilters = {
        ...form,
      };
      // Convert old values to string to avoid double arrays (eg: levelSingles: [[4,5] 6,7,8])
      const newValues = [filters[name], value].join(',');
      newFilters[name] = newValues;

      // [Debugging]
      // console.log('Adding value in filters:  ', value, ' - ADDED')
      // [Debugging]

      if (unique) {
        filters[name] = value;
        newFilters[name] = value;
      }
    }
    // If filter category exists in form but does not contain our new value
  } else if (value !== undefined) { // This executes the very first time the user choses something
    // [Debugging]
    // console.log('Adding default value in form: ', value, ' - ADDED')
    // [Debugging]

    newFilters = {
      ...form,
      [name]: value,
    };
  }
  return newFilters;
}

export default handleCheckbox;
