export default {
  title: 'Intră în Cont',
  subTitle: 'Bine ai revenit',
  seoTitle: 'Log In | Sportya',
  placeholders: {
    email: 'Email',
    password: 'Parolă',
  },
  forgotPassword: 'Ai uitat parola?',
  remember: 'Tine-mă minte',
  button: 'Autentificare',
  socialSignTitle: 'Sau autentifică-te cu',
  noAccount: 'Nu ai încă un cont?',
  signUp: 'Înregistrează-te',
  termsAndConditions: 'Prin autentificarea în cont, confirm ca am citit și sunt de acord cu <1>Termenii și Condițiile</1> Sportya și <1>Politica de Confidențialitate</1>',
  form: {
    AUTH_FAILED: 'Date invalide..',
    something_wrong_happened: 'A avut loc o eroare.',
  },
};
