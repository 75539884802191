/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import styled from '@emotion/styled';

import { fetchLanguages, setCurrentLanguage } from '../reducers/languages';
import ToggleVisible from '../../common/ToggleVisible';
import LoadImage from '../../common/LoadImage';

const Selector = styled.div`
position: relative;

button 
{
  display: flex;
  margin-right: 20px;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
}

span
{
  color: ${props => props.color};
  text-transform: uppercase;
}

ul 
{
  position: absolute;
  top: 25px;
  left: -18px;
  background: white;
  padding: 4px;
  border-radius: 5px;
  color: black;
  list-style: none;
  transition: all 0.5s ease;
  border: 1px solid black;
  z-index: 10;

  ${props => props.theme.functions?.mediaQueryMax('1023px', `
  left: -25px;
  top: 20px;
  height: max-content;
  `)}

  > li
  {
    padding: 4px;
    border-radius: 3px;
    transition-duration: 0.5s;
    cursor: pointer;

    ${props => props.theme.functions?.mediaQueryMax('1023px', `
    padding: 10px;
    `)}

    &:hover
    {
      background: #00000017;
    }
  }
}

img:first-of-type
{
  margin-right: 4px;
}

img:last-of-type
{
  margin-left: 3px;
  max-width: 10px;
  height: auto;
}
`;

const LangSelect = props => {
  const { data, status, currentLanguage } = useSelector(state => state.languages);
  const [languages, setLanguages] = useState(currentLanguage);
  const [triggerListener, setTriggerListener] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: commonT } = useTranslation('common');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchLanguages());
    }

    if (status === 'succeeded') {
      setLanguages(
        (data || []).map((language) => ({
          value: language.code,
          label: commonT(`languages.${language.code}`),
        })),
      );

      if (isEmpty(currentLanguage?.value) && !isEmpty(data)) {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : 'en';
        const [lang] = data
          .filter((language) => language.code === languageCode)
          .map((language) => ({
            value: language.code,
            label: commonT(`languages.${language.code}`),
          }));

        if (triggerListener) {
          handleChangeLanguage(lang);
        }
      }
      setLoaded(true);
    }
  }, [status, dispatch]);

  const handleChangeLanguage = (selectedOption) => {
    setTriggerListener(true);
    let url = history.location.pathname + history.location.search;

    if (selectedOption?.value !== i18next.language) {
      if (i18next.options.fallbackLng.includes(selectedOption?.value)) {
        if (history.location.pathname.includes(`/${i18next.options.fallbackLng.toString()}`)) {
          url = history.location.pathname.replace(`/${i18next.options.fallbackLng.toString()}`, '');
        } else {
          url = history.location.pathname.replace(`/${i18next.language}`, '');
        }
      } else {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : null;

        if (!languageCode) {
          // eslint-disable-next-line max-len
          url = `/${selectedOption?.value}${history.location.pathname}${history.location.search}`; // add language in url
        } else {
          url = history.location.pathname.replace(
            `/${languageCode}`,
            `/${selectedOption?.value}`,
          ) + history.location.search; // update language
        }
      }
    }

    history.replace(url, { ...history.location.state });

    i18next.changeLanguage(selectedOption?.value).then(() => {
      dispatch(setCurrentLanguage(selectedOption));
    });
  };

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Selector {...((['white'].includes(props.color) ? { color: 'white' } : { color: 'black' }))}>

      <button onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <img {...(['white'].includes(props.color)
          ? { src: LoadImage('white-globe-icon.svg') }
          : { src: LoadImage('globe-icon.svg') })}
        />
        <span>{(currentLanguage.value) || 'en'}</span>
        <img
          alt="Select Language"
          src={LoadImage(['white'].includes(props.color) ? 'white-arrow-down.svg' : 'arrow-down.svg')}
        />
      </button>

      {isComponentVisible
        && (
          <ul ref={ref}>
            {!isEmpty(languages) && loaded && (
              languages.map((lang, key) => (
                <li
                  key={key}
                  onClick={() => {
                    handleChangeLanguage(lang);
                    setIsComponentVisible(!isComponentVisible);
                  }}
                >
                  <Trans ns="common" i18nKey={`languages.${lang.value}`} />
                </li>
              ))
            )}
          </ul>
        )}
    </Selector>
  );
};

export default LangSelect;
