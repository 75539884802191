import React from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';

import Link from './Link';
import Button from './Button';
import H1 from './H1';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderWrapper = styled.section`
position: relative;

.slick-slide > div {
max-width: ${ ({ theme }) => theme.container?.maxWidth };
margin: ${ ({ theme }) => theme.functions?.toEm(15) } auto;
display: block;

${({ theme }) => theme.functions?.minDesktopSmall(`
margin: ${ theme.functions?.toEm(180) } auto;
`)}

> div {
padding: 0 15px;
}
}

.slick-dots {
position: relative;
bottom: 0;

button:before {
width: 15px;
height: 15px;
opacity: 1;
border: 2px solid ${ ({ theme }) => theme.colors?.black };
    border-radius: 50%;
    content: '';
    }
    
.slick-active button:before {
      background: ${ ({ theme }) => theme.colors?.black };
    }    
}

.slick-next,
  .slick-prev {
&:before {
color: ${ ({ theme }) => theme.colors?.black };
font-family: Arial;
font-size: 30px;
}

background-image: none;
transform: none;
top: auto;
    bottom: -2px;
    z-index: 9;
    width: auto;
    height: auto;
}

.slick-prev {
    left: calc(50% - 120px);
}

.slick-next {
    right: calc(50% - 110px);
}

`;

const InnerSlider = styled.div`
display: flex !important;
justify-content: space-between;
align-items: center;
flex-direction: column;
text-align: center;

& > div {
width: 100%;
}

img {
margin-top: ${ ({ theme }) => theme.functions?.toEm(30) };
display: inline-block;
}

${({ theme }) => theme.functions?.minDesktopSmall(`
flex-direction: row;
text-align: left;

& > div {
width: 50%;
}

img {
margin-top: 0;
}
`)}
`;

const SliderComponent = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {items.map((item, key) => (
          <InnerSlider key={key}>
            <div>
              <H1 className="mb30">{item.text}</H1>
              <Button black large wide>
                <Link to={`/${item.link}`}>{item.button}</Link>
              </Button>
            </div>
            <div className="text-center">
              <img src={item.image} alt="" className="img-responsive" />
            </div>
          </InnerSlider>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default SliderComponent;
