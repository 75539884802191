import styled from '@emotion/styled';

export const ForDonate = styled.div`

.donate{
  margin-top: 40px;
  padding: 22px 30px  29px 30px ;
  height: 295px;
  background-color: ${({ theme }) => theme?.colors?.secondaryTurquoise};
  border-radius: 10px;
  color: ${({ theme }) => theme?.colors?.black};

  @media screen and (max-width: 1049px){
    min-height: 295px;
    height:auto;
    }

    @media screen and (max-width: 768px){
      div:first-of-type{
        flex-direction:column;
      }
      div:last-child{
        flex-wrap:wrap;
         justify-content: flex-start !important;
         button{margin-right: 5px; margin-top:5px; width: 25% !important; min-width: 78px;}
      }
    }

  div:first-of-type {
    display:flex;
    align-items: center;
    img{
      margin-left: 3px;
      margin-right:23px;
      }
  }

  .contentWrapp{
    margin:15px 0 30px 0;
    min-height:90px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    p{
      margin:0;
    }
    a{
      cursor:pointer;
    }
  }

  div:last-child{
    margin-top: 11px;
    max-width:600px;
    width: 100%;
    display:flex;
    justify-content: space-between;
    
    button{
      width: 91px;
      height: 40px;
      background-color: ${({ theme }) => theme?.colors?.white};
      border: 2px solid #10242C;
      font-size: ${({ theme }) => theme.functions?.toEm(14)};
      font-weight: 700;
      line-height: 18.23px;
      border-radius:4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
        width: 9.33px;
        height: 6.67px;
        object-fix: contain;
        margin-right: 10px;
      }
    }
    .check{
      background-color: ${({ theme }) => theme?.colors?.primary};
      color: ${({ theme }) => theme?.colors?.white};;
      width: 93px;
    }
  }
}
`;
