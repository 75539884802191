/** @jsxImportSource @emotion/react */
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { css, useTheme } from '@emotion/react';

import Card from '../../../components/Card';
import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import { resetPassword } from './actions';

const forgotPassword = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('forgotPassword');
  const theme = useTheme();
  const { functions, colors } = theme;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        margin: ${functions?.toEm(30)} ${functions?.toEm(15)};
      `}
    >
      <Card
        css={css`
          display: flex;
          justify-content: center;
          max-width: ${functions?.toEm(600)};
          width: 600px;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: ${functions?.toEm(340)};
          `}
        >
          <h2
            css={css`
              text-align: center;
              color: ${colors?.black};
              font-size: ${functions?.toEm(38)};
              font-weight: 700;
              margin-bottom: ${functions?.toEm(5)};
            `}
          >
            {t('forgotPassword')}
          </h2>
          <h3
            css={css`
              text-align: center;
              color: ${colors?.black};
              font-size: ${functions?.toEm(20)};
              margin-top: ${functions?.toEm(0)};
              margin-bottom: ${functions?.toEm(40)};
              font-weight: 400;
            `}
          >
            {t('subTitle')}
          </h3>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgotPassword}
            onSubmit={(values, { setSubmitting }) => resetPassword({
              values,
              addToast,
              setSubmitting,
              dispatch,
              history,
              t,
            })}
          >
            {({
              errors,
              touched,
              isSubmitting,
              isValid,
              dirty,
              values,
            }) => (
              <Form>
                <div
                  css={css`
                    margin-bottom: ${functions?.toEm(40)};
                  `}
                >
                  <Input
                    name="email"
                    placeholder={t('placeholders.email')}
                    type="email"
                    value={values.email}
                    {...{ errors, touched }}
                  />
                </div>

                <Button
                  css={css`
                    width: 100%;
                    margin: ${functions?.toEm(20)} 0;
                  `}
                  black
                  type="submit"
                  disabled={isSubmitting || !(isValid && dirty)}
                >
                  {t('reset')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </div>
  );
};

export default ForgotPassword;
