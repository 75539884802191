export default {
  title: 'Főoldal',
  seoTitle: 'Játssz lokálisan. Álmodj globálisan. Csatlakozz az amatőr játékosok növekvő nemzetközi közösségéhez | Sportya',
  stats: {
    failedToLoad: '<0>A statisztikák betöltése sikertelen:</0> {{error}}',
    activePlayers: '<0>Játékosok</0> <2>{{players}}</2>',
    activeEvents: '<0>Tervezett események {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Klubok</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Pályák</0> <2>{{courts}}</2> ',
    activeCities: '<0>Városok</0> <2>{{cities}}</2>',
    activeCountries: `<0>Országok</0> <2>Románia,
     Moldova, Bulgária,
     Bahrain</2>`,
  },

  newEvents: {
    title: 'Kiemelt események',
    tennisTitle: 'Featured Tennis Events',
    padelTitle: 'Featured Paddle Events',
  },

  clubArea: {
    title: 'Egy teniszklub vezetője vagy egy pálya üzemeltetője vagy?',

    first: {
      title: 'Helyi és globális események kezelése',
      desc: 'A klub igényeihez és a pályák elérhetőségéhez szabottan, a maximális hatás érdekében.',
    },
    second: {
      title: 'Lépj kapcsolatba új játékosokkal',
      desc: 'Építsd fel és bővítsd ki játékosbázisodat helyi és globális játékosokkal.',
    },
    third: {
      title: 'Válj a teniszrajongók központjává',
      desc: 'Támogasd a szintalapú játékot, és növeld a játékosok elkötelezettségét és hűségét.',
    },
    fourth: {
      title: 'Foglalj pályákat',
      desc: 'Kezeld és foglald le a pályáidat. Tartsd fenn játékosaid gyakoribb aktivitását.',
    },
    button: 'Tudj meg többet',
  },

  centralPanel: {
    first: {
      title: 'Játssz többet. Játszd a magad módján',
      desc: 'Értékeld a játékszintedet és válaszd ki a játékmódodat, a klasszikus, hétvégén szervezett kieséses eseményektől kezdve a csoportos formátumú eseményeken át az éjszakai eseményekig, a rugalmas Helyi ligákig, a barátságos mérkőzésekig és még sok másig.',
    },
    second: {
      title: 'Versenyezz amatőr tenisztornákon',
      desc: 'Lépj be a Sportya több száz eseményt tartalmazó naptárába, kezdd helyileg, majd lépj tovább az amatőr játékosok számára szervezett nemzetközi teniszeseményekre, amelyek mindegyike a szintalapú játék elve szerint szerveződik.',
    },
    third: {
      title: 'Csatlakozz a teniszezők nemzetközi közösségéhez',
      desc: 'Mutasd be a profilodat, lépj kapcsolatba más játékosokkal, versenyezz a helyi és globális amatőr ranglistákon, és hozd ki a legtöbbet a teniszéletedből.',
    },
    buttons: {
      first: 'Tudj meg többet',
      second: 'Csatlakozz most',
    },
  },

  slider: {
    '1stSlideText': 'Join local and international amateur sport events',
    '1stSlideBtn': 'Find an Event',
    '2ndSlideText': 'Find and Book courts and facilities in partner sport clubs',
    '2ndSlideBtn': 'Search Now',
    '3rdSlideText': 'Find a partner for friendly matches in tennis, padel, and other sports',
    '3rdSlideBtn': 'Find out More',
  },
};
