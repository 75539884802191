import styled from '@emotion/styled';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '100px',
  '@media (max-width: 650px)': {
    margin: '0 10px',
  },
});

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '600px',
  borderRadius: '10px',
  padding: '40px 125px 95px',
  border: '2px solid #EAF6F6',
  form: {
    width: '100%',
  },
  '.ToA > a': {
    color: '#9ac2c0 !important',
    textDecoration: 'underline !important',
  },
  '@media (max-width: 650px)': {
    padding: '40px 20px 60px',
  },
});

export const RememberMe = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '30px',
  div: {
    margin: 'unset',
  },
});
