export default {
  title: 'Mé události',
  seoTitle: 'Mé události',
  joined: 'Pøipojil(a) se',
  noJoined: 'Dosud jste nezadali žádnou událost.',
  followed: 'Sledováno',
  noFollowed: 'Dosud nesledujete žádnou událost.',
  closed: 'Uzavøeno',
  noClosed: 'Zatím jste nedokonèili žádnou soutìž (výsledky ovìøeny).',
  withdrawals: 'Odstoupení',
  noWithdrawals: 'Zatím nemáte žádná odstoupení.',
  findEvent: 'Najít událost',
  seeEvent: 'Zobrazit událost',
  seeCompetition: 'Pøejít na soutìž',
  registeredOnMainBoard: 'Pøipojil(a) se k hlavní listinì',
  registeredInQueueList: 'Pøipojil(a) se do poøadníku èekatelù',
  lookingForPartners: 'Hledám partnera',
};
