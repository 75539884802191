export default {
  SOMETHING_WENT_WRONG: "Și ... este OUT! Ceva nu a mers bine. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  CHANGE_PASSWORD_FAILED: "Parola nu a putut fi actualizată.",
  CHANGE_PASSWORD_SUCCESS: "Parola a fost actualizată cu succes.",
  ACCOUNT_ACTIVATED: "Contul tău a fost validat cu succes.",
  ACCOUNT_ALREADY_ACTIVATED: "Contul tău a fost deja validat.",
  COULD_NOT_ACTIVATE_ACCOUNT: "Am întâmpinat o eroare și nu am putut valida contul tău. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  ACCOUNT_ALREADY_EXIST: "Contul există deja. Te poți autentifica sau poți reseta parola contului.",
  AUTH_FAILED: "Am întâmpinat o eroare și nu te putem autentifica în cont. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  INVALID_CREDENTIALS: "Datele de autentificare sunt invalide. Te rugăm să încerci din nou.",
  INTERNAL_ERROR: "Sistemul a întâmpinat o eroare internă. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  SERVICE_UNAVAILABLE: "Oops! Ceva nu funcționează. Te rugăm să ne raportezi eroarea la contact@sportya.net pentru a o putea remedia cât mai repede.",
  UPDATE_ACCOUNT_SUCCESS: "Contul a fost actualizat cu succes.",
  UPDATE_ACCOUNT_FAILED: "Contul nu a putut fi actualizat. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  UPDATE_CLUB_SUCCESS: 'Contul de club a fost actualizat cu succes.',
  UPDATE_CLUB_FAILED: 'Contul de club nu a putut fi actualizat.',
  SESSION_EXPIRED: "Sesiunea a expirat. Te rugăm să te autentifici din nou.",
  SUCCESSFULLY_FOLLOW_EVENT: "Evenimentul a fost adăugat în lista de preferințe și va fi urmărit.",
  SUCCESSFULLY_UNFOLLOW_EVENT: "Evenimentul a fost eliminat din lista de preferințe și nu mai este urmărit.",
  FAILED_FOLLOW_EVENT: "Evenimentul nu poate fi adăugat / eliminat din lista de preferințe.",
  ADD_FRIEND_SUCCESS: "Ai trimis cu succes cererea de prietenie",
  ACCEPT_FRIEND_SUCCESS: "Felicitări! Ai un nou prieten pe Sportya",
  REMOVE_FRIEND_SUCCESS: "Jucătorul a fost șters cu succes din lista ta de Prieteni",
  DECLINE_FRIEND_SUCCESS: "Ai refuzat cu succes cererea de prietenie",
  CANCEL_FRIEND_SUCCESS: "Ai anulat cu succes cererea de prietenie trimisă",
  UNBLOCK_PLAYER_SUCCESS: 'Jucătorul a fost deblocat cu succes.',
  BLOCK_PLAYER_SUCCESS: 'Jucătorul a fost blocat cu succes.',
  SUCCESSFULLY_CREATED_REPORT: 'Jucătorul a fost raportat cu succes.',
  SUBSCRIPTION_ALREADY_ACTIVATED: "Abonamentul tău a fost deja activat.",
  COULD_NOT_AUTHORIZE_PAYMENT: "Plata nu a putut fi autorizată. Te rugăm să încerci din nou.",
  MEMBERSHIP_NOT_FOUND: "Abonamentul ales nu a fost găsit. Te rugăm să încerci din nou.",
  INSUFFICIENT_BALANCE: "Upgrade-ul abonamentului a eșuat. Balanță insuficientă.",
  AMOUNT_NOT_VALID: "Upgrade-ul abonamentului a eșuat. Sumă invalidă.",
  SUMMER_SUBSCRIPTION_ACTIVATED: "C'mon! Contul tău de Vară a fost activat cu succes.",
  PREMIUM_SUBSCRIPTION_ACTIVATED: "C'mon! Contul tău Premium {{period}} a fost activat cu succes.",
  PREMIUM_MEMBERSHIP_UPGRADE: "C'mon! Contul tău Premium {{period}} a fost activat cu succes.",
  ALREADY_REGISTERED_TO_COMPETITION: "Ești deja înscris la nr. max. de competiții de același tip, parte din acest eveniment.",
  MAX_ALLOWED_SAME_COMPETITION_ERROR: "Ești deja înscris la nr. max. de competiții de același tip, parte din acest eveniment.",
  EVENT_REGISTRATION_SUCCESSFULLY: "Te-ai înscris cu succes în competiție.",
  EVENT_WITHDRAWAL_SUCCESSFULLY: "Te-ai retras cu succes din competiție.",
  FEE_TAX_NOT_AVAILABLE: "Taxele pentru această competiție nu sunt încă setate. Te rugăm să încerci mai târziu.",
  NOT_REGISTERED_TO_COMPETITION: "Nu ești înscris în această competiție.",
  PAYMENT_NOT_FOUND: "Nu am putut calcula taxa pentru această competiție. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  INVALID_FEE_TAX: "Nu am putut calcula taxa pentru această competiție. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.",
  USER_INVALID_FEE_TAX: "Nu am putut calcula taxa competiției pentru contul tău. Te rugăm să încerci mai târziu.",
  PARTNER_USER_INVALID_FEE_TAX: "Nu am putut calcula taxa competiției pentru contul partenerului tău. Te rugăm să încerci mai târziu.",
  CURRENT_PASSWORD_NOT_MATCH: "Vechea ta parola este incorectă. Încearcă din nou.",
  USER_NOT_ALLOWED_CASE_LEVEL: "Înscrierea nu poate fi procesată. Nivelul tău de joc nu corespunde cu criteriile de înscriere pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_LEVEL: "Înscrierea nu poate fi procesată. Nivelul partenerului tău de joc nu corespunde cu criteriile de înscriere pentru această competiție.",
  ACCESS_FORBIDDEN: 'Operația nu este permisă.',
  ACCOUNT_BLOCKED: 'Contul tău este blocat. Te rugăm să ne contactezi.',
  ERROR_TIMEFRAME: "Înscrierile nu sunt deschise încă pentru această competiție.",
  USER_NOT_ALLOWED_CASE_GENDER: "Înscrierea nu poate fi procesată. Genul tău nu corespunde cu criteriile de înscriere pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_GENDER: "Înscrierea nu poate fi procesată. Genul partenerului tău de joc nu corespunde cu criteriile de înscriere pentru această competiție.",
  WITHDRAW_NOT_ALLOWED: "Retragerea de la această competiție nu mai este permisă. Te rugăm să ne contactezi pe hq@sportya.net.",
  ERROR_BANNED_USER: "Nu te poți înregistra momentan la această competiție pentru că ești banat. Așteaptă expirarea perioadei de ban înainte de a te înregistra la o nouă competiție.",
  ERROR_BANNED_PARTNER: "Jucătorul {{ partnerName }} - {{ partnerId }} nu are momentan dreptul de înregistrare la evenimente. Te rugăm să alegi un alt coechipier",
  ERROR_USER_REGISTERED_SAME_WEEK: 'Ești deja înscris într-o competiție care se desfășoară în paralel cu acest eveniment. Poți vedea evenimentele la care ești deja înscris în secțiunea Evenimentele Mele, din administrarea contului.',
  USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Înscrierea nu poate fi procesată. Tipul tău de cont nu corespunde cu criteriile de înscriere pentru această competiție (destinată doar conturilor de tip PREMIUM).",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Înscrierea nu poate fi procesată. Contul partenerului de joc nu corespunde cu criteriile de înscriere pentru această competiție (destinată doar conturilor de tip PREMIUM).",
  USER_NOT_ALLOWED_CASE_MAXAGE: "Înscrierea nu poate fi procesată. Vârsta ta nu corespunde criteriilor de înscriere pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_MAXAGE: "Înscrierea nu poate fi procesată. Vârsta partenerului de joc nu corespunde criteriilor de înscriere pentru această competiție.",
  USER_NOT_ALLOWED_CASE_MINAGE: "Înscrierea nu poate fi procesată. Vârsta ta nu corespunde criteriilor de înscriere pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_MINAGE: "Înscrierea nu poate fi procesată. Vârsta partenerului de joc nu corespunde criteriilor de înscriere pentru această competiție.",
  USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Nu ai atins nr. min. de evenimente jucate (validate) necesare pentru a te înscrie la această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Partenerul din echipă nu a atins nr. min. de evenimente jucate necesare înscrierii la această competiție.",
  USER_NOT_ALLOWED_CASE_RANK: "Nu îndeplinești criteriile de înscriere. Clasarea ta curentă nu se încadrează în limitele stabilite pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_RANK: "Partenerul tau de joc nu îndeplinește criteriile de înscriere. Clasarea lui curentă nu se încadrează în limitele stabilite pentru această competiție.",
  TEAM_NOT_ALLOWED_AVG_LEVEL: "Înscrierea nu poate fi procesată. Nivelul de joc al echipei nu corespunde cu restricția de nivel aplicată acestei competiții.",
  TEAM_LEVEL_FORBIDDEN_TO_TEAMS: "Înscrierea nu poate fi procesată. Nivelul de joc al membrilor echipei nu corespunde cu restricția de nivel aplicată acestei competiții.",
  TEAM_MUST_BE_MIXT: "Înscrierea nu poate fi procesată. Echipa ta trebuie sa fie mixtă pentru a corespunde criteriilor de înscriere pentru această competiție.",
  PARTNER_USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Înscrierea nu poate fi procesată. Înscrierea în competițiile Sportya este permisă doar jucătorilor cu vârsta de minimum 15 ani.',
  USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Înscrierea nu poate fi procesată. Înscrierea în competițiile Sportya este permisă doar jucătorilor cu vârsta de minimum 15 ani.',
	COULD_NOT_CREATE_FRIENDLY_MATCH: 'Meciul nu poate fi creat momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_UPDATED_FRIENDLY_MATCH: 'Meciul tău a fost actualizat cu succes.',
	COULD_NOT_UPDATE_FRIENDLY_MATCH: 'Meciul tău nu poate fi actualizat momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH: 'Ai acceptat cu succes invitația la meci.',
	COULD_NOT_ACCEPT_FRIENLDY_MATCH: 'Invitația la meci nu poate fi acceptată momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_DECLINED_FRIENDLY_MATCH: 'Ai refuzat cu succes invitația la meci.',
	COULD_NOT_DECLINE_FRIENLDY_MATCH: 'Invitația la meci nu poate fi refuzată momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN: 'Te-ai retras cu succes din meci.',
	COULD_NOT_WITHDRAW_FRIENLDY_MATCH: 'Retragerea ta din meci nu poate fi procesată momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_CANCELED_FRIENDLY_MATCH: 'Meciul tău a fost anulat cu succes.',
	COULD_NOT_CANCEL_FRIENLDY_MATCH: 'Meciul tău nu poate fi anulat momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_REFUSED_FRIENDLY_MATCH: 'Ai raportat cu succes meciul ca nedisputat.',
	COULD_NOT_REFUSE_FRIENLDY_MATCH: 'Meciul nu poate fi raportat ca nedisputat momentan. Te rugăm să încerci din nou mai târziu!',
	SUCCESSFULLY_SAVED_SCORE: 'Scorul tău a fost salvat cu succes.',
	COULD_NOT_SAVE_SCORE: 'Scorul tău nu poate fi salvat momentan. Te rugăm să încerci din nou mai târziu!',
  passwordMustMatch: 'Parolele nu se potrivesc. Te rugăm să încerci din nou.',
  cookieConsent: 'Acest website folosește cookies pentru a-ți oferi cea mai bună experiență. Prin accesarea Sportya, confirmi că accepți utilizarea cookies conform \n<1>Politicii de Confidențialitate</1>',
  cookieConsentButton: 'Accept',
  banned: `Contul tău a fost suspendat pentru <0>{{time}}</0>. Nu te vei putea înscrie la evenimente până pe <1>{{until}}.</1>
  Pentru mai multe detalii, poți contacta Echipa Sportya la <2>contact@sportya.net</2>`,
  missingLocation: 'Nu a încă stabilită locația evenimentului.',
  notAvailable: 'Indisponibil',
  weeks: '{{time}} săptămâni',
  singleWeek: '1 săptămână',
  rank: 'Clasament',
  free: 'Gratuit',
  age: 'Vârsta',
  minage: 'Vârsta Min.',
  maxage: 'Vârsta Max. ',
  page_not_found: 'Pagina nu a fost găsită.',
  go_back: 'Înapoi',
  january: "Ianuarie",
  february: "Februarie",
  march: "Martie",
  april: "Aprilie",
  june: "Iunie",
  july: "Iulie",
  august: "August",
  september: "Septembrie",
  october: "Octombrie",
  november: "Noiembrie",
  december: "Decembrie",
  January: "Ianuarie",
  February: "Februarie",
  March: "Martie",
  April: "Aprilie",
  June: "Iunie",
  July: "Iulie",
  August: "August",
  September: "Septembrie",
  October: "Octombrie",
  November: "Noiembrie",
  December: "Decembrie",
  Jan: "Ian",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "Mai",
  may: 'Mai',
  Jun: "Iun",
  Jul: "Iul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mie",
  Thu: "Joi",
  Fri: "Vin",
  Sat: "Sâm",
  Sun: "Dum",
  Monday: "Luni",
  Tuesday: "Marți",
  Wednesday: "Miercuri",
  Thursday: "Joi",
  Friday: "Vineri",
  Saturday: "Sâmbătă",
  Sunday: "Duminică",
  male: "Masculin",
  female: "Feminin",
  men: "Bărbat",
  women: "Femeie",
  mixt: "Mixt",
  select: "Select",
  level: "Nivel",
  continue: "Continuă",
  requiredField: "Câmp obligatoriu",
  invalidEmail: "Email invalid",
  minChars: "Minimum {{ chars }} caractere",
  maxChars: "Maximum {{ chars }} caractere",
  mustContainerSpecialChars: "Include cel puțin un caracter special (@,!,#, etc)",
  logout: "Deconectare",
  login: "Autentifică-te",
  signup: "Înregistrează-te",
  password: "Parola",
  email: "Email",
  close: 'Închide',
  clubOwner: 'Propietarul Clubului',
  firstName: "Prenume",
  lastName: "Nume",
  phoneNumber: "Număr telefon",
  openHours: 'Program',
  phone: 'Telefon',
  nrNotValid: 'Numărul de telefon nu este valid',
  imClubManager: "Sunt Manager de Club",
  moreDetails: 'Mai multe detalii',
  birthdate: 'Data de naștere',
  maintenance: 'Mentenanță',
  inMaintenance: 'În mentenanță',
  regBackSoon: 'Înscrierile se vor relua în curând',
  days: 'Zile',
  day: 'Zi',
  from: 'De la',
  to: 'Către',
  back: 'Înapoi',
  dismiss: 'Închide',
  metaDescription: 'Sportya este un fenomen dedicat unei comunități guvernate de pasiunea pentru sport, energizată de spiritul competiției, nedivizată de vârstă, sex, culoare sau alte credințe. Sportya nu are frontiere geografice, nu sunt necesare pașapoarte. Aici suntem ghidați doar de spiritul de fair-play și ne leagă un singur limbaj, limbajul SPORTULUI.',
  metaKeywords: 'sportya, tenis, evenimente tenis, competitii tenis, turnee tenis, meciuri tenis, comunitate tenis, ligi tenis, ligi locale, tenis partener, jucatori tenis, jucatori amatori, cluburi tenis, club tenis, meciuri amicale, meciuri partener, joaca tenis, cauta jucatori, cauta evenimente, terenuri tenis, sport',
  ogTitle: 'Sportya - Joacă Cel Mai Bun Meci',
  ogDescription: 'Vino alături de mine în Sportya pentru a juca meciuri amicale și a participa la turnee naționale și internaționale! \n\r Creează-ți cont folosind linkul meu unic de invitație și câștigă recompense: \n\r {{ link }}',
  saveChanges: 'Salvează Actualizările',
  languages: {
    en: "Engleză",
    bg: "Bulgară",
    ro: "Română",
    hu: "Ungară",
    cz: 'Čeština',
  },
  grass: 'Iarbă',
  clay: 'Zgură',
  artificial: 'Sintetic',
  hard: 'Hard',
  gold: "Gold",
  silver: "Silver",
  platinum: "Platinum",
  champions: "Champions",
  winners: 'Campioni',
  courtNr: 'Teren {{courtNr}}',
  premium: "Premium",
  special: "Special",
  firstServe: '1st Serve',
  league: 'League',
  allAges: "Toate vârstele",
  isLoading: 'Se încarcă...',
  type: 'Tip',
  customAge: "Alege inverval",
  location: "Locație",
  LocationGmaps: 'Localizare',
  category: "Categorie",
  month: "Lună",
  year: "An",
  all: 'Toate',
  romania: 'România',
  bulgaria: 'Bulgaria',
  moldova: 'Republica Moldova',
  bahrain: 'Bahrain',
  czech: 'Cehia',
  armenia: 'Armenia',
  hu: 'Ungaria',
  city: 'Oraș',
  countriesByKey: {
    ro: 'România',
    md: 'Republica Moldova',
    bg: 'Bulgaria',
    cz: 'Cehia',
    am: 'Armenia',
    hu: 'Ungaria',
    bh: 'Bahrain',
  },
  soonToBeAnnounced: 'Va fi anunțat în curând',

  migration: {
    1: `Migrarea datelor din platforma Tenis Partener către Sportya are loc în această perioadă și afectează vizibilitata anumitor părți din Profilele de Jucători. 
    Acestea vor putea să fie accesate în scurt timp. 
    Vă mulțumim pentru răbdare.`,
  },

  players: "Jucători",
  teams: 'Echipe',
  areYouSure: 'Sunteți sigur?',
  setLevel: 'Setați nivelul',

  allLocations: 'Toate Locațiile',
  allCategories: 'Toate Categoriile',
  allMonths: 'Toate Lunile',
  allYears: 'Toți Anii',
  allLevels: 'Toate Nivelurile',

  onlyfemale: 'Doar Feminin',
  onlymale: 'Doar Masculin',
  onlymixt: 'Doar Mixt',

  right: 'Dreapta',
  left: 'Stânga',
  searchPlayer: 'Caută un jucător',
  'Romania-All': 'România (Toate)',
  'Bulgaria-All': 'Bulgaria (Toate)',
  'Moldova-All': 'Moldova (Toate)',
  'Hungary-All': 'Ungaria (Toate)',
  'Bahrain-All': 'Bahrain (Toate)',
  'Armenia-All': 'Armenia (Toate)',
  'Czechia-All': 'Cehia (Toate)',
  officialBall: 'Minge oficială',
  eventPrizes: 'Premii eveniment',
  eventPrizesDescription: 'Premiile standard pentru un turneu clasic (Silver, Gold, Platinum, Special), pentru fiecare competiție, constau într-o medalie și o diplomă. Orice premii suplimentare vor fi actualizate în această secțiune. \n\n În cazul Ligilor Locale, vă rugăm să ne trimiteți un mesaj la contact@sportya.net, pentru a vă genera diploma digitală pentru locul 1.',
  gameLevel: 'Nivel Joc',
  show: 'Arată',
  uploadPlaceholder: 'Plasați fișierele aici sau faceți click pentru a încărca.',
  download: "Descarcă",
  viewPDF: "Vezi Tabloul de Concurs în PDF",
  clearResults: 'Șterge Căutare',
  onlyLatin: `Te rugăm să folosești doar litere din alfabetul latin. 
  Această restricție este necesară pentru a permite celorlalți jucători să te găsească mai ușor în sistem 
  (ex: pentru înscrierea unei echipe de dublu).`,
  noResultsFound: 'Nici un rezultat găsit',
  typeAtLeast3Chars: 'Introduceți cel puțin 3 caractere...',
  allActivities: 'Orice Activitate',
  eventSponsors: 'Sponsori Eveniment',
  sponsors: 'Sponsori',
  coordinators: 'Coordonatori',
  officialStore: 'Magazin Oficial',
  officialSponsors: 'Sponsori Oficiali',
  proximityRadius: 'Proximitate',
  currentLocation: 'Locația Mea',
  calendar: 'Calendar',
  custom: 'Personalizat',
  radius: 'Rază',
  country: 'Țară',
  premiumAccountMembershipUpgrade: 'SPORTYA Upgrade Cont Premium {{ period }}',
  virtualWalletTopUp: 'SPORTYA Încărcare Portofel Virtual {{ amount }} {{ currency }}',
  eventRegistration: 'SPORTYA Înscriere la Eveniment {{ eventName }}',
  partnerEventRegistration: 'SPORTYA Înscriere Partener la Eveniment {{ eventName }}',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversația a fost inițiată cu success.',
  COULD_NOT_INITIATE_CHAT: 'Nu s-a putut iniția conversația. Te rugăm să încerci mai târziu.',
  cancelPopup: {
    title: 'Ești sigur că vrei să părăsești această pagină?',
    stay: 'Rămâi',
    quit: 'Părăsește pagina',
  },
  COULD_NOT_RATE_PLAYER: 'Evaluarea nu poate fi salvată momentan. Te rugăm să încerci din nou mai târziu.',
  SUCCESSFULLY_RATED_PLAYER: 'Evaluarea a fost salvată cu succes',
  SUCCESSFULLY_DELETED_RATING: 'Evaluarea a fost retrasă cu succes.',
  PaidParking: "Parcare cu plată",
  FreeParking: "Parcare gratuită",
  EquipmentRental: "Închiriere echipament",
  Store: "Magazin",
  Lockers: "Vestiar",
  Showers: "Dușuri",
  Restaurant: "Restaurant",
  Cafeteria: "Cafenea",
  Bar: "Bar",
  VendingMachine: "Tonomat",
  SpecialAccess: "Acces special",
  KidsPlayground: "Loc de joacă pentru copii",
  referralCode: "Cod invitație (opțional)",
  INVALID_REFERRAL_CODE: 'Cod invitație invalid',
  REFERRAL_CODE_APPLIED_SUCCESSFULLY: 'Codul de invitație a fost aplicat cu succes!',
  date: "Data",
  time: "Ora",
  no: "Nu",
  yes: "Da",
  FAILED_TO_BOOK_COURT: "Terenul nu a putut fi rezervat! Încearcă din nou!",
  BOOKING_COULD_NOT_BE_CREATED: "Terenul nu a putut fi rezervat! Încearcă din nou!",
  COULD_NOT_CALCULATE_BOOKING_PRICE: "Terenul nu a putut fi rezervat! Încearcă din nou!",
  COURT_NO_LONGER_AVAILABLE: "Acest teren nu mai este disponibil! Încearcă să rezervi un alt teren.",
  START_DATE_OUTSIDE_MAX_BOOKING_INTERVAL: "Terenul nu a putut fi rezervat! Încearcă din nou!",
  BOOKING_CONFIRMED_SUCCESSFULLY: "Felicitări! Terenul ales a fost rezervat cu succes!",
  COURT_IS_NOT_AVAILABLE: "Acest teren nu mai este disponibil! Încearcă să rezervi un alt teren.",
  CLUB_BOOKING_DISABLED: "Acest teren tocmai a fost dezactivat de Club. Încearcă să rezervi un alt teren.",
  FAILED_TO_CONFIRM_BOOKING: "Rezervarea nu a putut fi confirmată. Te rugăm să încerci din nou sau să ne contactezi.",
  ALREADY_WITHDRAWN: "Te-ai retras deja din această competiție.",
  RESOURCE_NOT_FOUND: "Ruta nu a putut fi găsită.",
  BOOKING_CANCELED_SUCCESSFULLY: "Rezervarea a fost anulată cu succes.",
  FAILED_TO_CANCEL_BOOKING: "Rezervarea nu a putut fi anulată.",
  BOOKING_ALREADY_CANCELED: "Rezervarea a fost deja anulată.",
  INVALID_FRIENDLY_MATCH_SCORE_FORMAT: 'Scor nevalid pe baza formatului de meci ales!',
  INVOICE_UNAVAILABLE: 'Factura nu este disponibilă.',
  bookNow: "Rezervă acum",
  searchAndBookCourts: "<0>NOU!</0> Acum poți CĂUTA și REZERVA un teren în Cluburile Partenere Sportya.",
  sports: {
    all: "Toate Sporturile",
    tennis: "Tenis",
    padel: "Padel",
  },
};
