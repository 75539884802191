export default {
  events: 'Evenimente',
  courts: 'Terenuri',
  membership: 'Abonamente',
  comingSoon: 'În Curând',
  findEvent: 'Caută un Eveniment',
  book_court: 'Book a Court',
  findPlayers: 'Găsește un Jucător',
  forPlayers: 'Pentru Jucători',
  forClubs: 'Pentru Cluburi',
  blog: 'Blog',
  help: 'Ajutor',
  aboutUs: 'Despre Noi',
  motto: 'Joacă cel mai bun meci!',
  joinNow: 'Alătură-te acum',
  privacy: 'Politică de Confidențialitate',
  termsAndConditions: 'Termeni și condiții',
  returnPolicy: 'Politică de Retur',
  language: 'Limbă',
  english: 'Engleză',
  romanian: 'Română',
  copyrightFooter: 'Toate drepturile rezervate.',
  rules: 'Sportya  - Reguli și regulamente',
  groupsPlaying: 'Moduri de joc pentru Grupe',
  localLeaguesRules: 'Ligi locale - Reguli și regulamente',
  sportyaRomania: 'Sportya Romania',
  sportyaBulgaria: 'Sportya Bulgaria',
  sportya: 'Sportya',
  sportyaDesc: 'Misiunea noastră este să conectăm și să inspirăm sportivi din întreaga lume prin crearea de platforme competitive, evenimente și instrumente specifice care îi ajută să profite la maximum de viața lor sportivă.',
};
