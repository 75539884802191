import styled from '@emotion/styled';

export const ParentContainer = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors?.lightBlue2}`,
  borderRadius: '10px',
  padding: '20px 30px 24px 30px',
  '@media (max-width: 850px)': {
    padding: '20px 15px 10px 20px',
  },
}));

export const ColumnsContainer = styled.div({
  display: 'flex',
  '@media (max-width: 851px)': {
    display: 'none',
  },
});

export const PlayerColumn = styled.div(({ addLeftPadding }) => ({
  width: '100%',
  maxWidth: '505px',
  minWidth: '420px',
  margin: '0 auto 0 13px',
  ...addLeftPadding && {
    paddingLeft: '12px',
  },

  '@media (max-width: 850px)': {
    margin: 'unset',
    minWidth: 'unset',
  },
}));

export const RegistrationColumn = styled.div(({ bold, center }) => ({
  ...center && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width: '100%',
  maxWidth: '160px',
  textAlign: 'center',
  ...bold && {
    fontWeight: 700,
  },

  '@media (max-width: 850px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'unset',
    textAlign: 'unset',
    minWidth: '50%',
  },
}));

export const FriendlyMatchesColumn = styled.div(({ bold, center }) => ({
  ...center && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width: '100%',
  maxWidth: '160px',
  textAlign: 'center',
  ...bold && {
    fontWeight: 700,
  },

  '@media (max-width: 850px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'unset',
    textAlign: 'unset',
    minWidth: '50%',
  },
}));

export const RankingColumn = styled.div(({ bold, center }) => ({
  ...center && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width: '100%',
  maxWidth: '160px',
  textAlign: 'center',
  ...bold && {
    fontWeight: 700,
  },

  '@media (max-width: 850px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'unset',
    textAlign: 'unset',
    minWidth: '50%',
  },
}));

export const PointsColumn = styled.div(({ bold, center, right }) => ({
  ...center && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...right && {
      justifyContent: 'end',
    },
  },
  width: '100%',
  maxWidth: '60px',
  textAlign: 'right',
  ...bold && {
    fontWeight: 700,
  },

  '@media (max-width: 850px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'unset',
    textAlign: 'unset',
    minWidth: '50%',
  },
}));
