import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'onlineUsers',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setOnlineUsers(state, action) {
      state.data = action.payload;
      state.status = 'succeeded';
    },
    setUserOnline(state, action) {
      const index = state.data?.findIndex((value) => value?.userId === action?.payload?.userId);
      if (state.data) {
        if (index === -1) {
          state.data = [...state.data, action.payload];
        } else {
          state.data[index] = action.payload;
        }
      }
    },
    setUserOffline(state, action) {
      const index = state.data?.findIndex((value) => value?.userId === action?.payload?.userId);
      if (state.data && index !== -1) {
        state.data[index] = action.payload;
      }
    },
  },
  extraReducers: {
  },
});

export const {
  setOnlineUsers,
  setUserOnline,
  setUserOffline,
} = slice.actions;

export default slice.reducer;
