import styled from '@emotion/styled';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  minWidth: '100%',
  backgroundColor: '#000a12',
});

export const Logo = styled.img({
  height: '60px',
  width: '160px',
  marginTop: '10px',
});

export const Image = styled.img({
  maxWidth: '100%',
  marginTop: '15%',
});

export const Contain = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  padding: '5px',
});

export const ImageContainer = styled.div({
  maxWidth: '500px',
});

export const Back = styled.div({
  display: 'flex',
  position: 'absolute',
  top: '50px',
  left: '10%',
  img: {
    transform: 'rotate(180deg)',
    margin: '0 10px 0 0',
  },
  '@media (max-width: 600px)': {
    top: '75px',
    left: '5%',
  },
});
