import React from 'react';
import LoadImage from '../../../../../components/common/LoadImage';

const index = ({ winner, second }) => (
  <>
    {winner ? (
      <img src={LoadImage('checkmark-gold.svg')} alt="Player Won" />
    ) : (
      second ? <img src={LoadImage('silver-crown.svg')} alt="crown" />
        : <img src={LoadImage('checkmark-draw.svg')} alt="Player Won" />
    )}
  </>
);

export default index;
