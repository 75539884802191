import styled from '@emotion/styled';

export const Logo = styled.img(({
  maxWidth: '150px',

  '@media (max-width: 1280px)': {
    maxWidth: '120px',
  },

  '@media (max-width: 479px)': {
    maxWidth: '100px',
  },
}));

export const HeaderContainer = styled.div(props => ({
  ...props.blur && {
    filter: 'blur(15px)',
    userSelect: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  width: '100%',
  zIndex: 11,
  top: 0,
}));

export const Header = styled.header(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 30px',
  width: '100%',
  height: '70px',
  transition: 'all .2s ease-out',
  background: props.navbarColor,
  // ...props.mobile ? { background: props.theme.colors.lightGrey } :
  ...props.navbarProfile ? { background: props.navbarProfile }
    : props.blackNav ? { background: '#000' }
      : props.blueNav ? { background: props.theme.colors.lightBlue2 }
        : props.darkCyan ? { background: props.theme.colors.darkCyan }
          : props.hasBg && props.changeColorOnScroll ? { background: props.navbarColorOnScroll }
            : props.transparentNav ? { background: '#fff0' } : { background: props.navbarColor || '#ffffff' },

  '.onlyMobile': {
    display: 'none',
  },

  ".logo-wrapper": {
    // minWidth: 250,
  },

  '.conversations-icon': {
    position: 'relative',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    // marginRight: 10,

    '&__active': {
      background: props.theme.colors.lightBlue2,
    },

    '.new-messages': {
      position: 'absolute',
      top: '-2px',
      right: '-5px',
      border: `2px solid ${props.transparentNav ? '#092e37' : '#ffffff'}`,
      marginLeft: 5,
      background: props.theme.colors.darkRed,
      color: 'white',
      width: 22,
      height: 22,
      textAlign: 'center',
      padding: 2,
      borderRadius: '50%',
      fontSize: 11,
      fontWeight: 700,

      '&.Myaccount': {
        borderColor: props.theme.colors.lightBlue,
      },

      '&.FindPlayers': {
        borderColor: props.theme.colors.lightBlue,
      },

      '&.StartingGuide': {
        borderColor: '#106a74',
      },
    },

    '@media (max-width: 479px)': {
      marginRight: 0,
    },
  },

  '@media (max-width: 1023px)': {
    justifyContent: 'unset',
    borderBottom: '1px solid #C1E6E5',
    padding: '0 15px',
    height: '50px',

    '.onlyDesktop': {
      display: 'none',
    },

    '.onlyMobile': {
      display: 'unset',
    },
  },

}));

export const AuthSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 1023px)': {
    marginLeft: 'auto',
  },
  gap: 10,
});

export const Nav = styled.nav({
  display: 'flex',

  '@media (min-width: 1024px)': {
    backgroundColor: '#EAF6F6',
    borderRadius: 60,
    padding: "6px 9px",
  },
});

export const List = styled.ul(props => ({
  display: 'flex',
  flexDirection: props.mobile ? 'column' : 'row',
  webkitFontSmoothing: 'antialiased',
  listStyle: 'none',
  padding: 0,
  margin: 0,

  '@media (min-width: 1024px)': {
    alignItems: 'center',
  },
}));

export const Item = styled.li(props => ({
  '@media (min-width: 1339px)': {
    padding: props.mobile ? '10px 0' : '0 20px',
  },

  '@media (min-width: 1281px)': {
    padding: props.mobile ? '10px 0' : '0 15px',
  },

  '@media (max-width: 1280px)': {
    padding: props.mobile ? '10px 0' : '0 10px',
  },
  'a,p': {
    // color: props.whiteNavText ? props.theme.colors.white : props.theme.colors.black,
    color: props.theme.colors.black,

    ...props.mobile && {
      color: props.theme.colors.white,
    },
  },
  a: {
    textDecoration: 'none',
    fontWeight: 'bold',
    ...props.mobile && {
      fontSize: '16px',
    },
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '3px',
      textUnderlineOffset: '6px',
    },
    ...props.active && {
      textDecoration: 'underline',
      textDecorationThickness: '3px',
      textUnderlineOffset: '6px',
    },
  },
}));
