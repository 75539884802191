import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import LoadImage from '../common/LoadImage';

import {
  Close,
  OverlayStyle,
  ContentStyle,
  ModalWrapper,
  MobileContentStyle,
} from './styles';

ReactModal.setAppElement('#root');

function useElementHeight(elementRef) {
  const [height, setHeight] = useState(
    () => (elementRef.current ? elementRef.current.offsetHeight : 0),
  );

  useEffect(() => {
    if (!elementRef.current) return;
    const element = elementRef.current;

    const updateHeight = (entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        // Assumes horizontal writing-mode:
        // eslint-disable-next-line no-shadow
        const height = entry.borderBoxSize[0]?.blockSize;
        if (typeof height === "number") setHeight(height);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, [elementRef.current, setHeight]);

  return Math.floor(height);
}

const Modal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
  showCloseIcon = true,
  mobileFullDisplay = false,
  ...props
}) => {
  const [maxContentHeight, setMaxContentHeight] = useState(null);
  const [contentScrollable, setContentScrollable] = useState(false);
  const ref = useRef(null);
  const height = useElementHeight(ref);

  const [mainContent] = document.getElementsByClassName("ReactModal__Content");
  const [content] = document.getElementsByClassName("modal-content");
  const [modalInnerContent] = document.getElementsByClassName("modal-inner-content");

  useEffect(() => {
    if (isOpen && modalInnerContent?.clientHeight > 0 && height > 0) {
      if (
        !contentScrollable
        && modalInnerContent?.clientHeight > height
        && window.innerHeight <= mainContent?.clientHeight
      ) {
        setMaxContentHeight(height - (modalInnerContent?.clientHeight - content?.clientHeight));
        setContentScrollable(true);
      }

      if (
        contentScrollable
        && modalInnerContent?.clientHeight <= height
        && window.innerHeight > mainContent?.clientHeight
      ) {
        setContentScrollable(false);
        setMaxContentHeight(null);
      }
    }
    // eslint-disable-next-line max-len
  }, [isOpen, contentScrollable, height, modalInnerContent?.clientHeight, content?.clientHeight, mainContent?.clientHeight]);

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: OverlayStyle,
        content: {
          ...ContentStyle,
          ...style,
          ...mobileFullDisplay && window.innerWidth < 479 && { ...MobileContentStyle },
        },
      }}
    >
      <ModalWrapper
        className="modal-wrapper"
        maxContainerHeight={mainContent?.clientHeight}
        contentScrollable={contentScrollable}
        maxContentHeight={maxContentHeight}
        mobileFullDisplay={mobileFullDisplay}
        ref={ref}
        {...props}
      >
        {showCloseIcon && <Close src={LoadImage('close-icon.svg')} onClick={onRequestClose} />}
        <div className="modal-inner-content">
          {children}
        </div>
      </ModalWrapper>
    </ReactModal>
  );
};

export default Modal;
