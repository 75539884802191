export default {
  seoTitle: 'Sign Up | Sportya',
  tosAgree: 'I agree to <1>{{ process.env.REACT_APP_SITE_NAME }}</1> <3>Terms and Conditions</3> and <5>Privacy Policy</5>, which includes my consent to be contacted with regard to the use of <1>{{ process.env.REACT_APP_SITE_NAME }}</1> services including but not limited to events registration and participation, management of friendly matches, and any other internal communication.',
  ageAgree: 'By registering, I confirm I am at least <1>15 years old</1>',
  createAccount: 'Create Account',
  alreadyHaveAnAccount: 'Already have an account?',
  signupWith: 'Or sign up with',
  newsletterSubscribe: 'I subscribe to the <1>{{ process.env.REACT_APP_SITE_NAME }}</1> newsletter, containing information about <1>{{ process.env.REACT_APP_SITE_NAME }}</1> events and other playing opportunities in my region, new features and selected 3rd party promos and discounts from our close partners / sponsors',
  countriesComingSoon: 'New countries coming soon!',
  title: 'Sign Up',
  subTitle: 'Sportya is available to players in <1>Romania, Republic of Moldova, Bulgaria, Bahrain, Hungary, and Czechia.</1>',
  oldUserInfo: `<0>Already have a Tenis Partener account?</0> 
  You do not need to create a new Sportya account. Your data has already been migrated and you can <2>Log in</2> with the email and password of the Tenis Partener account.`,
};
