export default {
  title_tennis: 'Hodnocení',
  title_padel: 'Hodnocení',
  seoTitle: 'Žebøíèky hráèù | Sportya',
  lastUpdate: 'Poslední aktualizace',
  timeframe: 'Èasový rámec výpoètu hodnocení',
  searchBar: 'Vyhledat {{results}} hráèe',
  filters: {
    title: 'Filtry (všechny)',
    modalTitle: 'Filtry',
    singles: 'Dvouhry',
    doubles: 'Ètyøhry',
    gender: 'Pohlaví',
    age: 'Vìk',
    reset: 'Obnovit výchozí',
    apply: 'Použít',
  },
  level: {
    4: 'Úroveò 4',
    5: 'Úroveò 5',
    6: 'Úroveò 6',
    7: 'Úroveò 7',
    8: 'Úroveò 8',
    9: 'Úroveò 9',
  },
  playerTableCard: {
    rank: 'Hodnocení',
    player: 'Hráè',
    points: 'Body',
    participations: 'Události',
    elo: 'Hodnocení ELO',
    friendlyMatches: 'Pøátelská utkání',
    friend: 'Friend',
    rankedFriendlyMatches: 'Přátelské Soutěžní Zápasy',
    generalRank: 'Obecný Řád',
    rankedMatches: 'Soutěžní Zápasy',
    unrankedMatches: 'Tréninkové Zápasy',
    totalMatches: 'Všechny Zápasy',
  },
  noPlayersFound: 'Nebyli nalezeni žádní hráèi.',
  generalRankings_tennis: 'Obecné Žebříčky',
  generalRankings_padel: 'Obecné Žebříčky',
  friendsRankings_tennis: 'Žebříček Přátel',
  friendsRankings_padel: 'Žebříček Přátel',
};
