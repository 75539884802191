import styled from '@emotion/styled';

export const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 15,
  minHeight: 335,
}));

export const ImageWrapper = styled.div(() => ({
  width: '100%',
  height: 180,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  overflow: "hidden",
  position: "relative",

  img: {
    objectFit: "cover",
    objectPosition: "center",
    width: '100%',
    height: "inherit",
  },

  ".badge": {
    position: "absolute",
    left: 10,
    top: 10,
    padding: '4px 11px',
    backgroundColor: 'white',
    color: '#10242C',
    fontWeight: 'bold',
    borderRadius: '5px',
    fontSize: '12px',
  },

  ".exclusive-offer": {
    position: "absolute",
    right: 10,
    top: 10,
    display: "flex",
    alignItems: "center",
    gap: 5,
    padding: '2px 6px',
    backgroundColor: '#F42B03',
    color: '#ffffff',
    fontWeight: 'bold',
    borderRadius: 6,
    fontSize: '12px',
    textTransform: 'uppercase',
    border: '2px solid white',

    img: {
      width: 'auto',
    },
  },
}));

export const CourtDescription = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  padding: 20,
  boxShadow: "0px 2px 4px rgba(16, 36, 44, 0.1)",
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  flexGrow: 1,
  justifyContent: "space-between",

  p: {
    margin: 0,
    marginBottom: 5,

    "&.startingPrice": {
      marginTop: 5,
      fontSize: 10,
    },
  },

  ".clubName": {
    color: "#000",
  },

  ul: {
    listStyle: "none",
    display: "flex",
    // alignSelf: "center",
    gap: 10,

    li: {
      padding: "10px",
      background: "#10242C",
      borderRadius: 5,
      color: "#fff",
      cursor: "pointer",
      width: "100%",
      textAlign: "center",
    },
  },
}));
