import {
  bg,
  cs,
  enUS,
  hu,
  ro,
} from "date-fns/locale";
import getCountryIsoCode from "../../../components/common/getCountryIsoCode";

const getLocaleByCountry = (country) => {
  const { language = 'en' } = getCountryIsoCode(country);
  const locales = {
    ro,
    bg,
    hu,
    cz: cs,
    en: enUS,
  };
  return locales[language] || enUS;
};

export default getLocaleByCountry;
