import styled from '@emotion/styled';
import { Button, P } from '../../../components/Collection';

export const MatchContent = styled.div(() => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',

  '@media (max-width: 991px)': {
    flexDirection: 'column',
    alignItems: 'unset',
    height: 'unset',
    // padding: '20px',
  },
}));

export const MatchContainer = styled.div(({ theme, isExpanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: isExpanded ? 'fit-content' : '110px',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  padding: '20px',
  transition: '.5s all ease-out',

  '&:not(last-of-type)': {
    margin: '0 0 10px 0',
  },

  '.info-icons': {
    position: 'relative',
    marginRight: 5,

    '&:hover > .popup': {
      display: 'block',
    },

    '.popup': {
      position: 'absolute',
      display: 'none',
      padding: '5px 10px',
      borderRadius: '4px',
      // bottom: '-55px',
      maxWidth: 290,
      transform: 'translateX(-45%)',
      background: 'rgb(15, 36, 44)',
      width: 'max-content',
      zIndex: 1,
      p: {
        color: 'white',
        whiteSpace: 'unset',
      },
      '@media (max-width: 670px)': {
        // left: '0 !important',
        // top: '-40px !important',
        transform: 'unset',
      },

      '&__on-hold': {
        background: theme.colors.yellowDarker,

        p: {
          color: theme.colors.primary,
        },
      },

      '&__warning': {
        background: theme.colors.darkRed,
      },
    },

    '&__is-visible': {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '50%',

      '.popup': {
        position: 'relative',
        display: 'block',
        maxWidth: 'initial',
        width: 'auto',
        transform: 'unset',

        '&__on-hold': {
          background: 'transparent',

          p: {
            color: theme.colors.yellowDarker,
          },
        },

        '&__warning': {
          background: 'transparent',

          p: {
            color: theme.colors.darkRed,
          },
        },
      },
    },
  },

  '@media (max-width: 1200px)': {
    '.info-icons': {
      '.popup': {
        transform: 'translateX(-70%)',
      },

      '&__is-visible': {
        '.popup': {
          transform: 'unset',
        },
      },
    },
  },

  '@media (max-width: 991px)': {
    flexDirection: 'column',
    alignItems: 'unset',
    height: 'unset',

    '.info-icons': {
      maxWidth: 'initial',
      top: -30,
      marginLeft: isExpanded ? 0 : -15,
      display: isExpanded ? 'block' : 'none',

      '.popup': {
        transform: 'translateX(0%)',
      },

      '&__is-visible': {
        display: 'flex',
        top: 'initial',
        paddingBottom: 25,
      },
    },
  },
}));

export const PlayerName = styled(P)(({
  maxWidth, winner, noWidth, disalbeAdditionalMargins, margin,
}) => ({
  width: noWidth ? 'unset' : 'calc(100% + 30px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'unset',

  ...!disalbeAdditionalMargins && {
    marginTop: 10,
    marginLeft: -10,
  },

  ...winner && {
    fontWeight: 600,
  },

  ...margin && {
    margin,
  },

  ...maxWidth && {
    width: 'fit-content',
    '&:first-of-type': {
      margin: '0 0 0 0',
    },
    '&:last-of-type': {
      padding: '0 5px',
      margin: '0 0 0 0',
    },
  },

  span: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const VSText = styled(P)({
  display: 'flex',
  alignItems: 'center',
  color: '#C1E6E5',
  margin: '0 15px',
});

export const DueToP = styled(P)(({ expanded }) => ({
  fontSize: 12,
  position: 'absolute',
  width: '100%',
  bottom: -25,
  left: 0,
  textAlign: 'center',

  '@media (max-width: 991px)': {
    width: expanded ? '100%' : 'calc(100% + 70px)',
    bottom: 'auto',
    top: -25,
  },
}));

export const FirstPart = styled.div(({ isDoubles }) => ({
  display: 'flex',
  width: 400,
  maxWidth: 400,
  alignItems: 'center',

  '@media (max-width: 991px)': {
    marginBottom: isDoubles ? '40px' : '20px',
    width: 'auto',
    maxWidth: 'initial',
    justifyContent: isDoubles ? 'space-around' : 'center',
  },

  '@media (max-width: 479px)': {
    zoom: isDoubles ? 0.9 : 1,
  },
}));

export const SecondPart = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: 400,
  flexGrow: 2,

  '@media (max-width: 991px)': {
    marginBottom: 20,
    width: '100%',
  },

  '@media (max-width: 479px)': {
    width: 'auto',
  },
});

export const ThirdPart = styled.div({
  display: 'flex',
  alignItems: 'center',

  '@media (max-width: 991px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 15,
  },
});

export const ButtonContainer = styled.div({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
});

export const MainButton = styled(Button)({
  minWidth: '130px',
  padding: '0 10px',
  height: '40px',
});

export const MatchStatusBadge = styled.span(({ isYourMatch }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '16px',
  padding: '0 5px',
  margin: '0 0 0 4px',
  background: isYourMatch ? '#7CC9C6' : '#10242C',
  borderRadius: '4px',

  color: '#fff',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
}));

export const RankedIcon = styled.img({
  margin: '0 0 0 4px',
});

export const MatchGametype = styled(P)({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 2px 0',

  '.match-id': {
    marginLeft: 5,
    fontSize: 12,
    color: '#B0B0B0',
  },
});

export const BoldCapitalize = styled.span({
  fontWeight: 700,
  textTransform: 'capitalize',
  margin: 'unset',
});

export const Dropdown = styled.div(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  backgroundColor: 'white',
  top: '45px',
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '199px',
  zIndex: 10,
}));
