/** @jsxImportSource @emotion/react */
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Formik, Field, Form, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { updateAccount } from './actions';

import DatePicker from '../../../../components/Form/DatePicker';
import Button from '../../../../components/Form/Button';
import { ProfileAvailabilityWrapper } from './styles';
import CheckmarkWhite from '../../../../assets/images/checkmark-white.svg';
import { H3 } from '../../../../components/Collection';

const getWeekDays = (locale) => {
  const current = new Date();
  const week = [];
  const first = current.getDate() - current.getDay() + 1;
  current.setDate(first);
  for (let i = 0; i < 7; i++) {
    const date = new Date(+current);
    week.push(date.toLocaleDateString(locale, { weekday: 'short' }));
    current.setDate(current.getDate() + 1);
  }
  return week;
};
const weekDays = getWeekDays('en-EN');

const availabilitySchedule = ({ accountInfo }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');

  const formSchema = Yup.object().shape({
    availability: Yup.object().required(commonT('requiredField')),
  });

  const defaultStartHour = new Date(new Date().setHours(8, 0, 0)).toISOString();
  const defaultEndHour = new Date(new Date().setHours(22, 0, 0)).toISOString();

  return (
    <ProfileAvailabilityWrapper>
      <H3 margin="0 0 20px 0">
        <Trans
          ns="settings"
          i18nKey="profileSettings.availabilitySchedule.title"
        >
          Availability Schedule
        </Trans>
      </H3>
      <Formik
        enableReinitialize
        initialValues={{
          availability: {
            Mon: {
              startDate:
                accountInfo?.availability?.Mon?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Mon?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Mon?.enabled ?? 0,
            },
            Tue: {
              startDate:
                accountInfo?.availability?.Tue?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Tue?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Tue?.enabled ?? 0,
            },
            Wed: {
              startDate:
                accountInfo?.availability?.Wed?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Wed?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Wed?.enabled ?? 0,
            },
            Thu: {
              startDate:
                accountInfo?.availability?.Thu?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Thu?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Thu?.enabled ?? 0,
            },
            Fri: {
              startDate:
                accountInfo?.availability?.Fri?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Fri?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Fri?.enabled ?? 0,
            },
            Sat: {
              startDate:
                accountInfo?.availability?.Sat?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Sat?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Sat?.enabled ?? 0,
            },
            Sun: {
              startDate:
                accountInfo?.availability?.Sun?.startDate ?? defaultStartHour,
              endDate:
                accountInfo?.availability?.Sun?.endDate ?? defaultEndHour,
              enabled: accountInfo?.availability?.Sun?.enabled ?? 0,
            },
          },
        }}
        validationSchema={formSchema}
        validateOnChange
        onSubmit={async (values, { setSubmitting }) => {
          const availabilityEntries = Object.entries(values.availability);
          // eslint-disable-next-line no-unused-vars
          const availability = availabilityEntries.filter(([key, value]) => value.enabled);
          Object.assign(values, { availability: Object.fromEntries(availability) });

          await updateAccount({
            id: accountInfo.id,
            payload: values,
            addToast,
            setSubmitting,
            dispatch,
            history,
            t: commonT,
          });
        }}
      >
        {({
          isSubmitting,
          isValid,
          values,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form className="w100">
            <FieldArray
              name="availability"
              render={() => (
                <>
                  <div className="choose-day">
                    <div className="day">
                      <p className="fw-bold">{commonT('days')}</p>
                    </div>
                    <div className="interval">
                      <p className="fw-bold">{commonT('from')}</p>
                      <p className="fw-bold">{commonT('to')}</p>
                    </div>
                  </div>
                  {Object.keys(weekDays).map((key) => (
                    <div key={key} className="choose-day">
                      <div className="day">
                        <Field
                          type="checkbox"
                          id={weekDays[key]}
                          name={`availability.${weekDays[key]}.enabled`}
                          value={values.availability?.[weekDays[key]]?.checked}
                        />
                        <label htmlFor={weekDays[key]}>
                          <img src={CheckmarkWhite} alt="check" />
                          {t(`profileSettings.availabilitySchedule.${weekDays[key]}`)}
                        </label>
                      </div>
                      <div className="interval">
                        <DatePicker
                          name={`availability.${weekDays[key]}.startDate`}
                          value={
                            values.availability?.[weekDays[key]]?.startDate
                          }
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          disabled={
                            !values.availability?.[weekDays[key]]?.enabled
                          }
                        />
                        <DatePicker
                          name={`availability.${weekDays[key]}.endDate`}
                          value={
                            values.availability?.[weekDays[key]]?.endDate
                          }
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          disabled={
                            !values.availability?.[weekDays[key]]?.enabled
                          }
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            />

            <Button
              black
              type="submit"
              disabled={isSubmitting || !isValid}
              className="mt40"
            >
              {commonT('saveChanges')}
            </Button>
          </Form>
        )}
      </Formik>
    </ProfileAvailabilityWrapper>
  );
};

export default availabilitySchedule;
