export default {
  title: 'Prémium fiók igénylése',
  subtitle: 'Ha a Sportya által kínált összes funkciót ki akarod használni, és spórolni akarsz a tornák nevezési díján, válaszd a <1>Prémium</1> fiókot',
  seoTitle: 'Prémium fiók igénylése | Sportya',

  benefits: {
    title: 'Minden fiók által tartalmazott előnyök',
    1: 'Személyes játékosprofil, amely részletesen tartalmazza a mérkőzések eredményeit, a ranglistahelyezéseket és a statisztikákat',
    2: 'Országos és nemzetközi ranglistahelyezés amatőr teniszezők számára',
    3: '10% kedvezmény a TENNIS WAREHOUSE EUROPE cégnél, Európa legnagyobb szakkereskedőjénél, minden vásárlásra',
    4: 'A játék színvonala minden eseményen, beleértve az új labdákat minden egyéni mérkőzésre és az ajándéktárgyakat (eseménytől függően)',
    5: 'Barátságos mérkőzések bármely regisztrált Sportya-taggal',
    6: 'Személyes üzenetküldő és értesítési rendszer',
    7: 'Hozzáférés a mérkőzés alapú statisztikákhoz, az események eredményeihez és az összehasonlításokhoz minden Sportya-tag számára',
    8: 'Technikai támogatás (e-mailben vagy telefonon)',
  },

  info: {
    feeReduction: '1. A Prémium fiókok nevezési kedvezményei eseménytől és országtól függenek, és eseményenként 3-5 EUR között mozognak (automatikusan alkalmazva). Bizonyos naptári eseményekre, például speciális formátumú eseményekre, Platina kategóriás tornákra, nemzetközi tornákra stb. további kedvezmények vonatkozhatnak',
  },

  expirationDate: '{{value}} hónap érvényesség',
  activePlan: 'A <1>Prémium</1> csomagod <3>{{expDate}}</3>-ig aktív',

  planConfirmation: {
    bestValue: 'Legnagyobb érték',
    noExpiration: 'Nincs lejárati idő',
    currentPlan: 'Jelenlegi csomag',
    included: 'Tartalmazza',
    extend: 'Kibővítés',
    upgrade: 'Frissítés',
    learnMore: 'Tudj meg többet',
    confirmFinalPayment: 'Kérjük, erősítsd meg a <1>Prémium {{period}}</1>\' végső fizetését',
    validity: 'Érvényesség',
    holder: 'Tulajdonos',
    totalOrder: 'Teljes rendelés',
    confirmPay: 'Megerősítés és fizetés',
    availableBalance: 'Elérhető egyenleg',
    redirected: 'Átirányítjuk a következő oldalra:',
    redirecting: 'Átirányítás: Stripe',
    redirectingClean: 'Átirányítás a következő oldalra:',
  },

  bottomSection: {
    findMore: 'Tudj meg többet',
    remindMe: 'Emlékeztess később',
  },

	plans: {
		free: {
			title: 'Ingyenes fiók',
			1: 'TELJES hozzáférés a baráti meccsekhez barátokkal vagy más játékosokkal',
			2: 'TELJES ár a tornanevezésekhez',
			3: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
			expDate: 'Nincs lejárati idő',
			price: 'Ingyenes',
		},
    
    summer: {
      title: 'Nyári fiók',
      1: 'TELJES hozzáférés a baráti meccsekhez barátokkal vagy más játékosokkal',
      2: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
      3: 'KEDVEZMÉNYES ár a versenyre való nevezéseknél',
      info: 'A Nyári fiókot a pályán nagyon aktív játékosoknak ajánljuk, akik folyamatosan javítani akarják játékukat és ranglistahelyezésüket, és a nyár folyamán a lehető legtöbb tornán szeretnének részt venni, úgy PLATINA és nemzetközi tornákon, mint speciális tornákon, például a Bajnokok és a Mesterek tornáin. A Nyári fiók hozzáférést biztosít az online platform minden lehetőségéhez és előnyéhez. A tornákra való regisztráció a csökkentett árú online foglalási díj befizetését jelenti a tornára való regisztrációkor, valamint a részvételi díj megfizetését a szervezőnek a verseny helyszínén.',
      expDate: '6 hónap érvényesség',
      timePeriod: '2021. ápr. 1 - 2021. okt. 31.',
    },

		premium: {
			title: 'Prémium fiók',
			1: 'TELJES hozzáférés a baráti meccsekhez barátokkal vagy más játékosokkal',
			2: 'KEDVEZMÉNYES ár a versenyre való nevezéseknél',
			3: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
			info: 'A Prémium fiókot azoknak a játékosoknak ajánljuk, akik az év során a lehető legtöbb tornán szeretnének részt venni, úgy a PLATINA kategóriában, mint a nemzetközi tornákon és a speciális tornákon, például a Bajnokok és a Mesterek tornáin. A Prémium fiók hozzáférést biztosít az online platform minden lehetőségéhez és előnyéhez. A tornákra való regisztráció a csökkentett árú online foglalási díj befizetését jelenti a tornára való regisztrációkor, valamint a részvételi díj megfizetését a szervezőnek a verseny helyszínén.',
			expDate: '12 hónap érvényesség',
			timePeriod: '2021. jan. 1 - 2021. dec. 31.',
		},

    overlay: {
      free: {
        1: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
        2: 'TELJES ár a tornanevezésekhez',
      },

      summer: {
        1: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
        2: 'CSÖKKENTETT ár a tornanevezésekhez',
      },

      premium: {
        1: 'Hozzáférés a Platina, Bajnokok, Mesterek, nemzetközi és speciális tornákhoz',
        2: 'KEDVEZMÉNYES online regisztrációs díj + a szervezőnek fizetett tornarészvételi díj',
      },
    },
  },
};
