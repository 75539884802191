import styled from '@emotion/styled';
// import LoadImage from '../../../../../../components/common/LoadImage';

export const CameraIcon = styled.img({
  width: '20px',
  height: '20px',
});

export const ChangePhoto = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  visibility: 'hidden',
  width: '100%',
});

export const AvatarContainer = styled.div({
  position: 'relative',
});

export const Avatar = styled.div(({ bg, isMyProfile }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '160px',
  width: '160px',
  border: '2px solid #7CC9C6',
  borderRadius: '50%',
  margin: '0 auto 20px auto',
  background: bg ? `url(${bg})` : '#fff',
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  ...isMyProfile && {
    cursor: 'pointer',
    '&:hover': {
      background: '#10242C',
      backgroundSize: 'cover',
      '> div': {
        visibility: 'visible',
        color: 'white',
      },
    },
  },
}));

export const AccountType = styled.div(({ bg }) => ({
  position: 'absolute',
  bottom: '-13px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '28px',
  height: '28px',
  ...bg && {
    background: `url(${bg})`,
    backgroundSize: 'cover',
  },
  '&:hover > div': {
    visibility: 'visible',
  },

  zIndex: 1,
}));

export const AccountTypePopup = styled.div({
  visibility: 'hidden',
  display: 'flex',
  alignItems: 'center',

  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '30px',

  height: '28px',
  width: 'max-content',
  padding: '0 10px',
  background: '#10242C',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#fff',

  zIndex: 2,
});
