import React, { useContext } from 'react';
import { UserContext } from '../../../..';
import AddFriendOption from './components/AddFriend';
import ChatOption from './components/Chat';
import BlockAndReportOptions from './components/BlockAndReport';
import { VisitedProfileOptionsContainer } from '../../styles';
import InviteToFriendlyMatch from './components/InviteToFriendlyMatch';
import { Contain } from '../../../../../../../../components/Collection';

const VisitedProfileOptions = () => {
  const { iAmBlocked, isBlocked } = useContext(UserContext);

  return (
    <VisitedProfileOptionsContainer>
      {(!iAmBlocked || !isBlocked) && (
        <>
          <AddFriendOption />
          <ChatOption />
        </>
      )}
      <Contain>
        {(!iAmBlocked || !isBlocked) && (
          <InviteToFriendlyMatch />)}
        <BlockAndReportOptions />
      </Contain>
    </VisitedProfileOptionsContainer>
  );
};

export default VisitedProfileOptions;
