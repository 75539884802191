export default {
  title: 'Homepage',
  seoTitle: 'Play local. Dream global. Join a growing community of international amateur players | Sportya',
  stats: {
    failedToLoad: '<0>Failed to load statistics:</0> {{error}}',
    activePlayers: '<0>Players</0> <2>{{players}}</2>',
    activeEvents: '<0>Planned Events {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Clubs</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Courts</0> <2>{{courts}}</2> ',
    activeCities: '<0>Cities</0> <2>{{cities}}</2>',
    activeCountries:
    `<0>Countries</0> <2>Romania, Republic of
     Moldova, Bulgaria,
     Bahrain</2>`,
  },

  newEvents: {
    title: 'Featured Events',
    tennisTitle: 'Featured Tennis Events',
    padelTitle: 'Featured Padel Events',
  },

  clubArea: {
    title: 'Are you a Tennis Club Manager or Court Administrator?',

    first: {
      title: 'Manage Local and Global Events',
      desc: 'Customized for your club\'s needs, including court or facility availability, for maximum impact.',
    },
    second: {
      title: 'Become a Hub for Amateur Athletes',
      desc: 'Support organized play based on skill levels. Increase player engagement and loyalty within your club.',
    },
    third: {
      title: 'Connect with New Players',
      desc: 'Build and expand your player base.',
    },
    fourth: {
      title: 'Rent Out Your Courts and Facilities',
      desc: 'Manage and offer courts for rent to the entire Sportya community. Boost player engagement and increase playing frequency.',
    },
    button: 'Learn more',
  },

  centralPanel: {
    first: {
      title: 'Play More. Play Your Way.',
      desc: 'Evaluate your level in tennis, padel, and other sports, and choose how you want to play - from classic events, knockout tournaments organized during weekends, to group-format events, evening events, flexible Local Leagues, friendly matches, and more.',
    },
    second: {
      title: 'Sign Up for Local and International Tournaments',
      desc: 'Access a calendar of hundreds of Sportya events, play in local and international amateur tournaments, all organized by playing levels in various sports. ',
    },
    third: {
      title: 'Find and Book Courts or Playing Surfaces',
      desc: 'Search for and book available courts or playing surfaces online in partner clubs, in tennis, padel and more.',
    },
    buttons: {
      first: 'Learn More',
      second: 'Join Now',
    },
  },

  slider: {
    '1stSlideText': 'Join local and international amateur sport events',
    '1stSlideBtn': 'Find an Event',
    '2ndSlideText': 'Find and Book courts and facilities in partner sport clubs',
    '2ndSlideBtn': 'Search Now',
    '3rdSlideText': 'Find a partner for friendly matches in tennis, padel, and other sports',
    '3rdSlideBtn': 'Find out More',
  },
};
