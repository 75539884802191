import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import PubSub from 'pubsub-js';

import { get } from '../../common/http';

// Actions
export const fetchNotifications = createAsyncThunk('settings/fetchNotifications', async (e) => {
  try {
    const url = `/notifications?limit=${e?.limit || 10}&page=${e?.page || 1}&sortBy=createdAt:desc`;
    const { data: { data } } = await get(url);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchUnreadNotifications = createAsyncThunk('settings/fetchUnreadNotifications', async () => {
  const { data: { data } } = await get('/notifications/count-unread');
  return data;
});

// Slice
const slice = createSlice({
  name: 'notifications',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchNotifications.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const unreadNotifications = createSlice({
  name: 'unreadNotifications',
  initialState: {
    data: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    seenNotifications: (state) => {
      state.data = 0;
    },
    setNewNotification: (state) => {
      state.data += 1;
    },
  },
  extraReducers: {
    [fetchUnreadNotifications.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUnreadNotifications.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchUnreadNotifications.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  seenNotifications,
  setNewNotification,
} = unreadNotifications.actions;

const reducer = combineReducers({
  list: slice.reducer,
  unreadNotifications: unreadNotifications.reducer,
});

export default reducer;
