import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import session from './components/Layout/reducers/session';
import friends from './components/Friends/reducers';
import languages from './components/Layout/reducers/languages';
import settings from './components/Layout/reducers/settings';
import notifications from './components/Layout/reducers/notifications';
import onlineUsers from './components/Layout/reducers/onlineUsers';
import slides from './pages/Homepage/components/Slider/reducers';
import stats from './pages/Homepage/components/Statistics/reducers';
import upcomingCompetitions from './pages/Dashboard/components/UpcomingCompetitions/reducers';
import accountStats from './components/AccountStats/reducers';
import accountTransactions from './pages/MyAccount/Wallet/reducers';
import accountOrders from './pages/MyAccount/Orders/reducers';
import events from './pages/Events/reducers';
import featuredEvents from './pages/Events/components/FeaturedEvents/reducers';
import accountsLists from './pages/FindAPlayer/reducers';
import accountInfo from './pages/PlayerProfile/reducers';
import clubs from './pages/Clubs/reducers';
import leaderboards from './pages/Leaderboards/reducer';
import myEvents from './pages/MyAccount/Events/reducers';
import myBookings from './pages/MyAccount/Bookings/reducers';
import conversations from './pages/Chat/reducers';
import friendlyMatches from './pages/FriendlyMatches/reducers';
import invitedUsers from './pages/Referral/reducers';

const homepage = combineReducers({
  slides,
  stats,
});

const dashboard = combineReducers({
  competitions: upcomingCompetitions,
  transactions: accountTransactions,
  orders: accountOrders,
  myEvents,
  myBookings,
  notifications,
  onlineUsers,
});

const accounts = combineReducers({
  list: accountsLists.list,
  locations: accountsLists.locations,
  activeFilters: accountsLists.activeFilters,
  info: accountInfo,
  stats: accountStats,
});

const leaderboard = combineReducers(leaderboards);

const reducer = combineReducers({
  session,
  languages,
  settings,
  homepage,
  dashboard,
  leaderboard,
  events,
  featuredEvents,
  accounts,
  clubs,
  friends,
  conversations,
  friendlyMatches,
  invitedUsers,
});

const store = configureStore({
  reducer,
});

export default store;
