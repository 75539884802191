const isBetween = (date, min, max) => date.getTime() >= new Date(min).getTime()
  && date.getTime() <= new Date(max).getTime();

const eventCurrentPhase = (phases) => {
  if (phases) {
    const [currentPhase] = Object.keys(phases)
      .map((phase) => ({
        status: phase,
        startDate: phase === 'ended'
          && !phases[phase].startDate ? phases.resultsPending.startDate : phases[phase].startDate,
        endDate: (
          (phase === 'drawPending' && !phases[phase].endDate ? phases.playing.startDate : phases[phase].endDate)
          || (phase === 'resultsPending' && !phases[phase].endDate ? new Date() : phases[phase].endDate)
          || (phase === 'ended' && !phases[phase].endDate ? new Date() : phases[phase].endDate)
        ),
      }))
      .filter(
        ({ startDate, endDate }) => startDate && endDate && isBetween(new Date(), startDate, endDate),
      );

    return currentPhase || {};
  }
  return null;
};

export default eventCurrentPhase;
