import styled from '@emotion/styled';
import { Button } from '../../../../../../../../../components/Collection';

export const ReportModalHeader = styled.div({
  textAlign: 'center',
});

export const ReportModalContent = styled.div({
  maxHeight: '470px',
  overflow: 'auto',
  padding: '1px',
  '.form-group': {
    margin: '0 0 10px 0',
  },
});

export const ReportModal = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100vh - 50px)',
  width: '100%',
  maxWidth: '600px',
  padding: '40px 70px',
  background: '#fff',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: 99,
  '@media (max-width: 600px)': {
    padding: '30px 40px',
  },
  '@media (max-width: 385px)': {
    padding: '20px 30px',
  },
});

export const BlockModal = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100vh - 50px)',
  width: '100%',
  maxWidth: '600px',
  padding: '40px 70px',
  background: '#fff',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: 99,
  textAlign: 'center',
  '@media (max-width: 600px)': {
    padding: '30px 40px',
  },
  '@media (max-width: 385px)': {
    padding: '20px 30px',
  },
});

export const ModalBackground = styled.div({
  display: 'flex',
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: '#0000004d',
  zIndex: 99,
  touchAction: 'none',
  userSelect: 'none',
});

export const ContainOptions = styled.div({
  position: 'relative',
});

export const InvertImage = styled.img(({ invert }) => ({
  filter: invert ? 'invert(1)' : 'none',
}));

export const Dropdown = styled.div(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  backgroundColor: 'white',
  top: '55px',
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '199px',
  zIndex: 1000,

  '@media (max-width: 800px)': {
    left: '-50%',
  },
}));

export const DropdownItemContainer = styled.div(({ disabled, red }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  transition: 'all .2s ease-out',
  userSelect: 'none',
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.5 : 1,
  ...red && { color: '#F42B03' },

  '&:hover': {
    background: 'rgb(234, 246, 246)',
  },
  padding: '0 20px',
  width: 'max-content',
  minWidth: '100%',
  zIndex: 9,

  '> img': {
    margin: '0 10px 0 0',
  },

  '@media (max-width: 800px)': {
    height: '60px',
  },
}));

export const ThreeDotsIcon = styled.img({
  width: '20px',
  height: '5px',
});

export const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '30px 0 0 0',

  '> button': {
    margin: '0 10px 0 0',
  },

  '@media (max-width: 650px)': {
    flexDirection: 'column',
    justifyItems: 'center',
    '> button': {
      margin: '0 0 10px 0',
    },
  },
});

export const ThreeDotsButton = styled(Button)({
  width: '60px',
  minWidth: '60px',
  '@media (max-width: 800px)': {
    width: '100%',
  },
});
