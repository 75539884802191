import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import ro from './ro';
import bg from './bg';
import hu from './hu';
import cz from './cz';

// i18n.on('languageChanged', (lng) => {
//   if (i18n.options.fallbackLng.includes(lng)) {
//     if (window.location.pathname.includes(`/${ i18n.options.fallbackLng}`)) {
//     }
//   }
// });

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      ro,
      bg,
      hu,
      cz,
    },
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'ro', 'bg', 'hu', 'cz'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });
