/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Formik, Field, Form, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { updateAccount } from './actions';

import { H2, P } from '../../../components/Collection';

import DatePicker from '../../../components/Form/DatePicker';
import Button from '../../../components/Form/Button';
import { store as socketStore } from '../../../components/Socket/store';
import { ProfileAvailabilityWrapper } from './styles';
import { updateAccountInfoState } from '../../../components/Layout/reducers/session';
import CheckmarkWhite from '../../../assets/images/checkmark-white.svg';
import Logout from '../../../components/Logout';

const getWeekDays = (locale) => {
  const current = new Date();
  const week = [];
  // Starting Monday not Sunday
  const first = current.getDate() - current.getDay() + 1;
  current.setDate(first);
  for (let i = 0; i < 7; i++) {
    const date = new Date(+current);
    week.push(date.toLocaleDateString(locale, { weekday: 'short' }));
    current.setDate(current.getDate() + 1);
  }
  return week;
};
const weekDays = getWeekDays('en-EN');

const SetAvailability = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion } = useSelector(state => state.session);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const formSchema = Yup.object().shape({
    availability: Yup.object().required(commonT('requiredField')),
  });

  const defaultStartHour = new Date(new Date().setHours(8, 0, 0)).toISOString();
  const defaultEndHour = new Date(new Date().setHours(22, 0, 0)).toISOString();

  const profileCompleted = isEmpty(profileCompletion.filter((step) => !step.completed && step.step !== 'availability'));

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        await dispatch(updateAccountInfoState({
          ...accountInfo,
          ...data,
        }));
        if (profileCompleted) {
          history.push('/dashboard?congrats');
        } else {
          history.push('/dashboard');
        }
      } else {
        setIsSubmitting(false);
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);
      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  return (
    <ProfileAvailabilityWrapper>
      <div className="container">
        <Logout />
        <H2 className="mb10">{t('completeProfile.availability')}</H2>
        <P textAlign="center" className="mb45">{t('completeProfile.availabilityDescription')}</P>

        <Formik
          enableReinitialize
          initialValues={{
            availability: {
              Mon: {
                startDate: accountInfo?.availability?.Mon?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Mon?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Mon?.enabled ?? 0,
              },
              Tue: {
                startDate: accountInfo?.availability?.Tue?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Tue?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Tue?.enabled ?? 0,
              },
              Wed: {
                startDate: accountInfo?.availability?.Wed?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Wed?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Wed?.enabled ?? 0,
              },
              Thu: {
                startDate: accountInfo?.availability?.Thu?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Thu?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Thu?.enabled ?? 0,
              },
              Fri: {
                startDate: accountInfo?.availability?.Fri?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Fri?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Fri?.enabled ?? 0,
              },
              Sat: {
                startDate: accountInfo?.availability?.Sat?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Sat?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Sat?.enabled ?? 0,
              },
              Sun: {
                startDate: accountInfo?.availability?.Sun?.startDate ?? defaultStartHour,
                endDate: accountInfo?.availability?.Sun?.endDate ?? defaultEndHour,
                enabled: accountInfo?.availability?.Sun?.enabled ?? 0,
              },
            },
          }}
          validationSchema={formSchema}
          validateOnChange
          onSubmit={async (values) => {
            setIsSubmitting(true);

            const availabilityEntries = Object.entries(values.availability);
            // eslint-disable-next-line no-unused-vars
            const availability = availabilityEntries.filter(([key, value]) => value.enabled);
            Object.assign(values, { availability: Object.fromEntries(availability) });

            await updateAccount({
              id: accountInfo.id,
              payload: values,
              addToast,
              socketClientId,
              dispatch,
              history,
              t: commonT,
            });
          }}
        >
          {({
            isValid,
            values,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Form className="w100">
              <FieldArray
                name="availability"
                render={() => (
                  <>
                    <div className="choose-day">
                      <div className="day">
                        <p className="fw-bold">{commonT('days')}</p>
                      </div>
                      <div className="interval">
                        <p className="fw-bold">{commonT('from')}</p>
                        <p className="fw-bold">{commonT('to')}</p>
                      </div>
                    </div>
                    {Object.keys(weekDays).map((key) => (
                      <div key={key} className="choose-day">
                        <div className="day">
                          <Field
                            type="checkbox"
                            id={weekDays[key]}
                            name={`availability.${weekDays[key]}.enabled`}
                            value={values.availability?.[weekDays[key]]?.checked}
                          />
                          <label htmlFor={weekDays[key]}>
                            <img src={CheckmarkWhite} alt="" />
                            {t(`completeProfile.${weekDays[key]}`)}
                          </label>
                        </div>

                        <div className="interval">
                          <DatePicker
                            name={`availability.${weekDays[key]}.startDate`}
                            value={values.availability?.[weekDays[key]]?.startDate}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            disabled={!values.availability?.[weekDays[key]]?.enabled}
                          />
                          <DatePicker
                            name={`availability.${weekDays[key]}.endDate`}
                            value={values.availability?.[weekDays[key]]?.endDate}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            disabled={!values.availability?.[weekDays[key]]?.enabled}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />

              <Button
                black
                fullwidth
                type="submit"
                disabled={isSubmitting
                  || !isValid
                  || isEmpty(Object.entries(values.availability).filter(([, value]) => value.enabled))}
                className="mt40"
              >
                {!profileCompleted ? t('completeProfile.setAvailability') : t('completeProfile.completeProfile')}
              </Button>

              <Button
                outline
                fullwidth
                type="button"
                className="mt20"
                onClick={() => history.push('/dashboard')}
              >
                {t('completeProfile.skipForNow')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </ProfileAvailabilityWrapper>
  );
};

export default SetAvailability;
