export default {
  minutesUntil: 'határidő {{time}} min',
  hoursUntil: 'határidő {{time}} h',
  daysUntil: 'határidő {{time}} nap',
  monthsUntil: 'határidő {{time}} hónap',
  yearsUntil: 'határidő {{time}} év',
  minutesAgo: '{{time}} perce',
  hoursAgo: '{{time}} órával ezelőtt',
  daysAgo: '{{time}} napja',
  monthsAgo: '{{time}} hónapja',
  yearsAgo: '{{time}} évvel ezelőtt',
  justNow: 'éppen most',
};
