export default {
  goodMorning: 'Dobré ráno',
  goodAfternoon: 'Dobré odpoledne',
  goodEvening: 'Dobrý večer',
  neutralGreeting: 'Dobrý den',
  sendItAgain: 'Pošli to znovu',
  todayWeather: 'Dnes je v {{ location }} {{ weatherType }}, {{ degrees }}°C',
  unavailableWeather: 'Počasí je nedostupné',
  verifyYourEmailAddress: '<0>Zkontrolujte svůj e-mail.</0> Před použitím naší platformy si musíme ověřit vaši e-mailovou adresu.',
  profileCompleted: '<0>Váš profil je dokončen.</0> Dopřejte si plný <2>{{ siteName }}</2> zážitek!',
  passwordResetSuccessfully: 'Prosím zkontrolujte schránku doručené pošty s pokyny pro obnovení hesla.',
  confirmEmailTitle: 'Zkontrolujte svůj e-mail. <1></1> Před použitím <3>{{ process.env.REACT_APP_SITE_NAME }}</3> musíme ověřit vaši e-mailovou adresu.',
  confirmEmailSubtitle: 'Neobdrželi jste e-mail? Ověřovací e-mail odešlete znovu a dokončete potvrzení své e-mailové adresy.',
  // sendItAgain: 'Odeslat znovu',
  ACTIVATION_EMAIL_SENT_SUCCESSFULLY: 'Aktivační e-mail byl úspěšně odeslán.',
  ACCOUNT_DOES_NOT_EXIST: 'Účet neexistuje.',
  ACTIVATION_EMAIL_WAS_NOT_SENT: 'Aktivační e-mail nebyl odeslán.',
  minAgeRequired:
    `Počínaje rokem 2022 jsme zvýšili minimální věkovou hranici pro vstup na akce Sportya na 15 let. Tato změna se projevuje i v našem projektu Tenis10. <1>(www.tenis10.ro)</1>, 
  který nyní umožní v soutěžích pořádaných pro děti a juniory registraci hráčů až do věku 14 let. Váš účet Sportya zůstane aktivní,
  ale dokud nedosáhnete minimálního věku, nebudete se registrovat na akce.`,
  completeProfile: {
    title: 'Dokončete svůj profil a dopřejte si {{ siteName }}skvělé zážitky!',
    profileDetails: 'Podrobnosti o profilu',
    setProfileDetails: 'Zadat podrobnosti',
    profileVisibility: 'Viditelnost profilu',
    setProfileVisibility: 'Nastavit viditelnost',
    gameLevel: 'Úroveň hry',
    setGameLevel: 'Zvolit úroveň ',
    profilePicture: 'Profilový obrázek',
    setProfilePicture: 'Nahrajte svou fotografii',
    availability: 'Dostupnost',
    setAvailability: 'Nastavit dostupnost',
    skip: 'Vynechat',
    favoriteSport: 'Favorite Sport',
    setFavoriteSport: 'Set Favorite Sport',
  },
  welcome: {
    hello: 'Dobrý den {{username}}',
    welcomeToPlatform: 'Vítá vás {{site}}!',
    welcomeText: 'Před zahájením nám pomozte vyplnit váš profil, abyste získali co nejlepší zkušenosti. Zabere to jen minutu!',
    startBtn: 'Pusťme se do toho\'',
    logout: 'Odhlásit se',
  },
  choseLanguage: 'Vyberte svůj upřednostňovaný jazyk',
  upcomingCompetitions: 'Nadcházející soutěže',
  seeMyEvents: 'Zobrazit mé události',
  findAnEvent: 'Najít událost',
  searchForEventsOrCompetitions: 'Vyhledat soutěže ve vaší oblasti',
  gettingStarted: 'Začínáme',
  gettingStartedDescription: 'Začněte a podnikněte první kroky s <1>{{ process.env.REACT_APP_SITE_NAME }}</1>',
  letsStart: 'Pusťme se do toho',
  websiteGuides: '<0>{{siteTitle}}</0> Průvodci',
  learnMore: 'Podrobné informace',
  pendingUpdate: 'Čekající aktualizace',
  leaderboard: 'Hodnocení',
  updated: 'Aktualizováno: <1>date</1>',
  stats: 'Statistiky',
  singlesPlayed: 'Odehrané dvouhry',
  doublesPlayed: 'Odehrané čtyřhry',
  allTime: 'Za celou dobu',
  friendlyMatches: 'Přátelská utkání',
  registrationDate: 'Datum registrace',
  pvpEloCalculator: 'Kalkulačka Head2Head ELO Calculator',
  pvpEloCalculatorDescription: 'Zadejte 2 hráče a zjistěte jejich šance na vítězství v jejich přímém souboji mezi sebou.',
  calculate: 'Vypočítat',
  player: 'Hráč',
  searchPlayer: 'Vyhledat hráče',
  pageTitle: 'Řídicí panel',

  accountLeaderboard: {
    title: 'Hodnocení',
    title_tennis: 'Hodnocení',
    title_padel: 'Hodnocení',
    updated: 'Aktualizováno',
    singles: 'Dvouhry',
    doubles: 'Čtyřhry',
    position: 'Hodnocení',
  },

  guides: {
    1: {
      title: 'Sportya - Pravidla a předpisy',
      text: 'Oficiální pravidla zahrnující všechny soutěžní a technické aspekty, kterými se řídí utkání, události. žebříčky a další',
    },
    2: {
      title: 'Skupiny po 3 herních formátů — Technická příručka',
      text: 'Krátký průvodce systémem hry, žebříčky, odstoupením a postupem do vyřazovací fáze pro skupiny 3 hráčů, což je jeden z nejčastějších formátů, s nimiž se na turnajích Sportya setkáváme.',
    },
    3: {
      title: 'Místní ligy — Technická příručka a nejčastější dotazy',
      text: 'Základní informace týkající se organizování místních lig, komunikace s hráči, žebříčků a často kladených otázek, které vás mohou rychle nastartovat',
    },
  },
  competitionStats: 'Competition Stats',
  competitionStats_tennis: 'Tennis Competitions',
  competitionStats_padel: 'Padel Competitions',
  rankedMatches: '<strong>{{ranked}}</strong> Konkurenční',
  unrankedMatches: '<strong>{{unranked}}</strong> Přátelský',
};
