import styled from '@emotion/styled';

export const OrderStyle = styled.div`
    p {
        margin:0;
    }

    a {
        text-decoration: none;
    }

    .order
    {
    margin-top:20px;
    display:flex;
    justify-content:space-between;

        .id
        {
            max-width:90px;
            width:100%;
        }

        .order-id
        {
            display: none;
        }

        .order-date
        {
            display: none;
        }

        .order-type
        {
            display: none;
        }
        
        .order-price
        {
            display: none;
        }

        .order-payment
        {
            display: none;
        }

        .date
        {
            max-width:180px;
            width:100%;
        }

        .type
        {
            max-width:300px;
            width:100%;
        }

        .amount
        {
            max-width:80px;
            width:100%;
        }

        .method
        {
            max-width:120px;
            width:100%;
        }

        .details {
            max-width:100px;
            width:100%;
            position: relative;

            a {
                cursor: pointer;
            }
            
            .content-loader {
                top: -7px;
            }
        }
    }

    p{span{color:${({ theme }) => theme?.colors?.darkTurquoise}}}

    ${props => props.theme.functions?.mediaQueryMax('600px', `
        .order
        {
            display: grid;
            grid-gap: 10px;
            border-bottom: 1px solid #EAF6F6;
            padding-bottom: 20px;

            .order-id
            {
                display: inline-block;
            }

            .order-date
            {
                display: inline-block;
            }

            .order-type
            {
                display: inline-block;
            }

            .order-price
            {
                display: inline-block;
            }

            .order-payment
            {
                display: inline-block;
            }

            .type
            {
                max-width: none;
            }

            .amount
            {
                max-width: none;
            }

            .method
            {
                max-width: none;
            }
        }
    `)}
`;

export const SummaryModal = styled.div`
    padding: 20px 20px 40px 20px;
    overflow: overlay;

    .order-data
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 40px;
    }

    div
    {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .seeEvent
    {
        margin-bottom: 10px;
    }

    .order-details
    {
        margin-top: 20px;

        a
        {
            color: #10242C;
        }
    }

    .summary-box
    {
        display: block;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EAF6F6;

        .BookingFee-Amount
        {
            float: right;
        }

        .donation-Amount
        {
            float: right;
        }

        .couponValue
        {
            float: right;
        }
    }

    .shoppingBasket
    {
        margin-bottom: 20px;
        border-bottom: 1px solid #EAF6F6;
        padding-bottom: 20px;

        .product-value
        {
            float: right;
        }
    }

    .total-incl-tva
    {
        float: left;
    }

    .total-value
    {
        float: right;
    }

    .cpf-value
    {
        float: right;
    }

    .separator 
    {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        text-align: center;

        .sp-line
        {
            display: inline-block;
            border-bottom: 1px solid #EAF6F6;
            min-width: 45%;
        }

        img
        {
            margin: 0px 2% -6px 2%;
        }
    }
`;
