import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UserContext } from '../../../../../..';

import {
  Contain, P, H3, Button,
} from '../../../../../../../../../../components/Collection';
import { FriendContext } from '../../../../../../../../../Friends/components/FriendCard';
import { deleteFriend } from '../../../../../../../../../MyAccount/Settings/reducers';
import { ModalBackground, BlockModal as Modal } from '../styles';
import mixpanel from '../../../../../../../../../../mixpanel';

const UnfriendModal = ({
  handler, isPlayerProfile, friendsCount, setFriendsCount,
}) => {
  let uContext = null;

  if (useContext(UserContext)) {
    uContext = useContext(UserContext);
  } else {
    uContext = useContext(FriendContext);
  }

  const { user, socketClientId } = uContext;

  const { firstName, lastName, userId } = user || {};
  const { t } = useTranslation('friends');
  const dispatch = useDispatch();

  const unfriend = () => {
    dispatch(deleteFriend({ data: { friendUserId: userId, socketClientId } }));
    handler();
    if (isPlayerProfile) {
      mixpanel.track('Unfriend from Player Profile - click Unfriend from Player Profile');
    }
    if (typeof setFriendsCount === 'function') {
      setFriendsCount({
        ...friendsCount,
        accepted: friendsCount.accepted - 1,
      });
    }
  };

  return (
    <>
      <ModalBackground />
      <Modal>
        <Contain justify="center" direction="column" textAlign="center">
          <H3>{t('unfriendText', { name: `${firstName} ${lastName}` })}</H3>
          <P margin="15px 0 30px 0">{t('unfriendConfirmationText', { name: `${firstName} ${lastName}` })}</P>

          <Contain justify="center">
            <Button margin="0 10px 0 0" padding="0 60px" onClick={() => handler()} outline>
              <Trans ns="events" i18nKey="cancel">Cancel</Trans>
            </Button>

            <Button padding="0 60px" onClick={() => unfriend()}>
              <Trans ns="settings" i18nKey="unfriend">Unfriend</Trans>
            </Button>
          </Contain>
        </Contain>
      </Modal>
    </>
  );
};

export default UnfriendModal;
