import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserContext } from '../../..';
import {
  Contain, H3, Link, P,
} from '../../../../../../../components/Collection';
import { Badge } from '../../../../../../../components/LevelsBadges/styles';
import { OptionButton, BadgeContainer, InfoContainer } from '../styles';

const GameLevelInfo = ({ hideOnMobile }) => {
  const { masterSport } = useSelector(state => state.session);
  const { user, isMyProfile } = useContext(UserContext);
  const { gameLevel } = user || {};
  const didSetAccountLevels = !!(gameLevel?.singles && gameLevel?.doubles);

  return (
    <InfoContainer separator noLeftPadding hideOnMobile={hideOnMobile}>
      {((isMyProfile && didSetAccountLevels) || !isMyProfile)
        && (
          <>
            <BadgeContainer>
              <P small>
                <Trans ns="player" i18nKey="singleLevel" context={masterSport}>
                  Singles Level
                </Trans>
              </P>
              <Badge large>
                <H3>{gameLevel?.singles ?? '?'}</H3>
              </Badge>
            </BadgeContainer>
            <BadgeContainer>
              <P small>
                <Trans ns="player" i18nKey="doubleLevel" context={masterSport}>
                  Doubles Level
                </Trans>
              </P>
              <Badge large double>
                <H3>{gameLevel?.doubles ?? '?'}</H3>
              </Badge>
            </BadgeContainer>
          </>
        )}
      {isMyProfile && !didSetAccountLevels && (
        <Contain direction="column" textAlign="center">
          <P>
            <Trans ns="accounts" i18nKey="completeProfile.gameLevel" context={masterSport}>
              Game levels
            </Trans>
          </P>
          <OptionButton outline>
            <Link to="/my-account/game-level">
              <Trans ns="player" i18nKey="setYourGameLevel">
                Set Your Game Level
              </Trans>
            </Link>
          </OptionButton>
        </Contain>
      )}
    </InfoContainer>
  );
};

export default GameLevelInfo;
