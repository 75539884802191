import React from 'react';
import { Trans } from 'react-i18next';

import { Button, Paragraph } from '../../../../../components';
import Modal from '../../../../../components/Modal';
import mixpanel from '../../../../../mixpanel';

const LeaveChatConfirmationModal = ({
  confirmLeaveConversation,
  setConfirmLeaveConversation,
  handleLeaveConversation,
}) => (
  <Modal
    isOpen={confirmLeaveConversation}
    onRequestClose={() => setConfirmLeaveConversation(false)}
  >
    <div className="modal-header">
      <Paragraph large bold className="text-center">
        <Trans ns="conversations" i18nKey="leaveConfirmationGroup">
          Are you sure you want to leave this <br /> group chat ?
        </Trans>
      </Paragraph>
    </div>
    <div className="modal-footer justify-content-center">
      <Button outline onClick={() => setConfirmLeaveConversation(false)} className="mr10">
        <span><Trans ns="conversations" i18nKey="cancel">Cancel</Trans></span>
      </Button>
      <Button
        onClick={() => {
          handleLeaveConversation();
          mixpanel.track('Leave Group Chat');
        }}
      >
        <span><Trans ns="conversations" i18nKey="leave">Leave</Trans></span>
      </Button>
    </div>
  </Modal>
);

export default LeaveChatConfirmationModal;
