import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';

import {
  fetchLanguages,
  setCurrentLanguage,
} from '../../../../components/Layout/reducers/languages';

import Select from '../../../../components/Form/Select';
import ToggleButton from '../../../../components/Form/ToggleButton';

import { P, Contain, H3 } from '../../../../components/Collection';
import { updateAccount } from './actions';

import { Container } from './styles';
import mixpanel from '../../../../mixpanel';

const ProfileDetails = ({ accountInfo }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, status, currentLanguage } = useSelector(
    (state) => state.languages,
  );
  const [languages, setLanguages] = useState(currentLanguage);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchLanguages());
    }

    if (status === 'succeeded') {
      setLanguages(
        (data || []).map((language) => ({
          value: language.code,
          label: language.name,
        })),
      );

      if (isEmpty(currentLanguage?.value) && !isEmpty(data)) {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : 'en';
        const [lang] = data
          .filter((language) => language.code === languageCode)
          .map((language) => ({ value: language.code, label: language.name }));
        handleChangeLanguage(lang);
      }
    }
  }, [status, dispatch]);

  const handleChangeLanguage = (selectedOption) => {
    let url = history.location.pathname + history.location.search;

    if (selectedOption?.value !== i18next.language) {
      if (i18next.options.fallbackLng.includes(selectedOption?.value)) {
        if (
          history.location.pathname.includes(
            `/${i18next.options.fallbackLng.toString()}`,
          )
        ) {
          url = history.location.pathname.replace(
            `/${i18next.options.fallbackLng.toString()}`,
            '',
          );
        } else {
          url = history.location.pathname.replace(`/${i18next.language}`, '');
        }
      } else {
        const code = history.location.pathname.split('/')[1];
        const languageCode = code && code.length === 2 ? code : null;

        if (!languageCode) {
          url = `/${selectedOption?.value}${history.location.pathname}`; // add language in url
        } else {
          url = history.location.pathname.replace(
            `/${languageCode}`,
            `/${selectedOption?.value}`,
          ); // update language
        }
      }
    }

    history.replace(url, { ...history.location.state });

    i18next.changeLanguage(selectedOption?.value).then(() => {
      dispatch(setCurrentLanguage(selectedOption));
    });

    updateSetting('language', selectedOption?.value);
  };

  const updateSetting = async (setting, value) => {
    if (setting === 'emailFriendRequests') {
      mixpanel.track(`${value ? 'Enable' : 'Disable'} Email Notifications for Friend Requests`);
    }

    const payload = {
      communicationPreferences: {
        ...accountInfo?.communicationPreferences,
      },
    };

    if (setting === 'language') {
      payload.language = value;
    } else {
      payload.communicationPreferences[setting] = value;
    }

    return updateAccount({
      id: accountInfo.id,
      payload,
      addToast,
      setSubmitting: () => { },
      dispatch,
      t: commonT,
    });
  };

  return (
    <Container>
      <Contain maxWidth="444px" display="block">
        <H3 margin="0 0 20px 0">
          <Trans
            ns="settings"
            i18nKey="profileSettings.languageCommunication.title"
          >
            Language &amp; Communication
          </Trans>
        </H3>
        <Select
          name="language"
          placeholder={commonT(`languages.${currentLanguage.value}`)}
          value={{ value: accountInfo?.language, label: commonT(`languages.${accountInfo?.language}`) }}
          onChange={handleChangeLanguage}
          options={languages}
          lowPlaceholder
          isDisabled={status === 'loading' || isEmpty(languages)}
          isSearchable={false}
          label={t('profileSettings.languageCommunication.language')}
        />

        <P xSmall bold margin="45px 0 10px 0">{t('profileSettings.languageCommunication.email')?.toUpperCase()}</P>
        <ToggleButton
          className="toggle"
          active={accountInfo?.communicationPreferences?.emailChatConversations}
          handler={() => (
            updateSetting('emailChatConversations', !accountInfo?.communicationPreferences?.emailChatConversations)
          )}
        >
          <Trans ns="settings" i18nKey="profileSettings.languageCommunication.emailChatConversations">
            Email notifications for Chat Conversations
          </Trans>
        </ToggleButton>
        <ToggleButton
          className="toggle"
          active={accountInfo?.communicationPreferences?.emailFriendRequests}
          handler={() => (
            updateSetting('emailFriendRequests', !accountInfo?.communicationPreferences?.emailFriendRequests)
          )}
        >
          <Trans ns="settings" i18nKey="profileSettings.languageCommunication.emailFriendRequests">
            Email notifications for Friend Requests
          </Trans>
        </ToggleButton>
        <ToggleButton
          className="toggle"
          active={accountInfo?.communicationPreferences?.emailFriendlyMatches}
          handler={() => (
            updateSetting('emailFriendlyMatches', !accountInfo?.communicationPreferences?.emailFriendlyMatches)
          )}
        >
          <Trans ns="settings" i18nKey="profileSettings.languageCommunication.emailFriendlyMatches">
            Email notifications for Friendly Matches
          </Trans>
        </ToggleButton>

        <P xSmall bold margin="45px 0 10px 0">{t('profileSettings.languageCommunication.newsletter')?.toUpperCase()}</P>
        <ToggleButton
          className="toggle"
          active={accountInfo?.communicationPreferences?.newsletter}
          handler={() => updateSetting('newsletter', !accountInfo?.communicationPreferences?.newsletter)}
        >
          <Trans ns="settings" i18nKey="profileSettings.languageCommunication.subscribe">
            Subscribed to Sportya&apos;s newsletter (playing opportunities in my region, new features and more)
          </Trans>
        </ToggleButton>
      </Contain>
    </Container>
  );
};

export default ProfileDetails;
