import styled from '@emotion/styled';

export const MatchPlayedContainer = styled.div({});

export const AvatarRelativeContainer = styled.div({
  position: 'relative',
  width: '100%',
});

export const AvatarAbsoluteContainer = styled.div(({ isPartner, isDoubles }) => ({
  position: 'absolute',
  top: 0,
  width: 'max-content',
  zIndex: isPartner ? 1 : 2,
  left: '50%',
  transform: 'translateX(-50%)',
  ...isDoubles && {
    left: '10px',
    transform: 'unset',
    ...isPartner && {
      left: 'unset',
      right: '10px',
    },
  },
}));

export const PlayerAvatar = styled.div(({
  bg, hasInitials, winner, isDoubles,
}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  width: '60px',
  borderRadius: '50%',
  margin: '0 auto 20px auto',
  backgroundColor: '#fff',
  backgroundImage: bg ? `url(${bg})` : 'unset',
  backgroundSize: 'cover',
  ...isDoubles && hasInitials && {
    border: '2px solid #fff',
  },
  ...hasInitials && {
    border: '1px solid #10242C',
    background: '#fff',
  },
  ...winner && {
    border: '2px solid #FFCD1A',
  },
}));
