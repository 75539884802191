const handlePagination = ({
  clubId, page, toolkit, params,
}) => {
  const newParams = { ...params, page: page?.selected + 1, clubId };
  toolkit.setParams(newParams);
  toolkit.setCurrentPage(0);
  toolkit.fetchClubEvents(newParams);

  const element = document.getElementById('top');
  if (element) {
    const topPos = element.getBoundingClientRect().top + window.pageYOffset - 80;
    return window.scrollTo({
      top: topPos,
      behavior: 'smooth',
    });
  }

  return null;
};

export default handlePagination;
