const getUserPosition = (match, userId) => {
  if (userId === match.player?.userId) {
    return 'player';
  }

  if (userId === match.playerPartner?.userId) {
    return 'playerPartner';
  }

  if (userId === match.opponent?.userId) {
    return 'opponent';
  }

  if (userId === match.opponentPartner?.userId) {
    return 'opponentPartner';
  }

  return 'none';
};

export default getUserPosition;
