import styled from '@emotion/styled';

export const ClubContainer = styled.div({
  position: 'relative',
  display: 'flex',
  height: '100px',
  maxWidth: '602px',
  width: '100%',
  borderRadius: '10px',
  border: '1px solid #EAF6F6',
  margin: '0 0 10px 0',
  overflow: 'hidden',
  '@media (max-width: 550px)': {
    flexDirection: 'column',
    minHeight: '100px',
    height: 'unset',
  },
});

export const ClubAvatar = styled.img({
  width: '100px',
  height: '100%',
  borderRadius: '10px 0 0 10px',
  objectFit: 'cover',
  objectPosition: 'center',
  '@media (max-width: 550px)': {
    borderRadius: '10px 10px 0 0',
    width: '100%',
  },
});

export const ClubDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '13px 20px',
  '@media (max-width: 550px)': {
    padding: '0 20px 13px 20px',
    order: 2,
  },
});

export const DistanceContainer = styled.div({
  position: 'absolute',
  top: '20px',
  right: '20px',
  '@media (max-width: 550px)': {
    position: 'unset',
    top: 'unset',
    right: 'unset',
    order: 1,
    padding: '13px 20px 0 20px',
  },
});
