import React from 'react';

import {
  Avatar,
  Initials,
  Link,
} from './styles';

import LoadImage from '../common/LoadImage';

const nameInitials = ({ firstName, lastName }) => {
  const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
  const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
  return `${first} ${last}`;
};

const UserAvatar = ({
  user = {},
  size = 48,
}) => {
  const {
    id,
    userId,
    firstName,
    lastName,
    profilePicture,
  } = user;
  const fullName = `${firstName} ${lastName}`;

  return (
    <Link to={`/accounts/${id}`}>
      <Avatar size={size}>
        {profilePicture && (
          <img
            src={LoadImage(`/accounts/${userId}/${profilePicture}`, true)}
            alt={fullName}
            className="profilePicture"
          />
        )}
        {!profilePicture && (
          <Initials size={size}>
            {nameInitials(user)}
          </Initials>
        )}
      </Avatar>
    </Link>
  );
};

export default UserAvatar;
