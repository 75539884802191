import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, pick } from 'lodash';

import {
  AverageLevel,
  Team,
  TeamsContainer,
  TeamTitle,
} from '../../../../../FriendlyMatches/MatchForm/components/CreateMatch/MatchInvitation/styles';

import Select from '../../../../../../components/Form/Select';
import PlayerOption from '../../../../../FriendlyMatches/components/MatchFormSearchBar/components/PlayerOption';
import SearchBar from '../../../../../FriendlyMatches/components/MatchFormSearchBar';
import { P } from '../../../../../../components/Collection';

const FriendlyMatchFormData = ({
  t,
  friendlyMatch,
  setFriendlyMatch,
  matchEligibility,
}) => {
  const { accountInfo } = useSelector(state => state.session);

  const isSingles = friendlyMatch.gameType === 'singles';
  const isDoubles = friendlyMatch.gameType === 'doubles';
  const userFields = [
    'id',
    'userId',
    'firstName',
    'lastName',
    'email',
    'phone',
    'profilePicture',
    'location',
    'gameLevel',
    'rank',
  ];

  const matchTypes = [
    {
      label: t('rankedMatch', { ns: 'friendlyMatches' }),
      value: 'ranked',
    },
    {
      label: t('unrankedMatch', { ns: 'friendlyMatches' }),
      value: 'unranked',
    },
  ];

  const gameTypes = [
    {
      label: t('courtSizes.singles'),
      value: 'singles',
    },
    {
      label: t('courtSizes.doubles'),
      value: 'doubles',
    },
  ];

  const setFormValue = (value, key) => {
    const values = {
      ...friendlyMatch,
      [key]: value,
      ...(key === 'gameType') && {
        player: pick(accountInfo, userFields),
        ...(value !== friendlyMatch.gameType) && {
          playerPartner: null,
          opponent: null,
          opponentPartner: null,
        },
      },
    };
    setFriendlyMatch(values);
  };

  const getFormValue = (name) => {
    let result;

    switch (name) {
    case 'matchType':
      result = matchTypes.filter(v => v.value === friendlyMatch[name]).shift();
      break;
    case 'gameType':
      result = gameTypes.filter(v => v.value === friendlyMatch[name]).shift();
      break;
    case 'playerPartner':
    case 'opponent':
    case 'opponentPartner':
      result = friendlyMatch[name];
      break;
    default:
    }

    return result;
  };

  return (
    <>
      <Select
        name="matchType"
        placeholder={t('matchType', { ns: "friendlyMatches" })}
        onChange={({ value }) => setFormValue(value, 'matchType')}
        options={matchTypes}
        label={t('matchType', { ns: "friendlyMatches" })}
        height="50px"
        defaultValue={getFormValue('matchType')}
        alignLabel
        lowInput
      />
      {friendlyMatch.matchType && (
        <Select
          name="gameType"
          placeholder={t('gameType')}
          onChange={({ value }) => setFormValue(value, 'gameType')}
          options={gameTypes}
          label={t('gameType')}
          height="50px"
          defaultValue={getFormValue('gameType')}
          alignLabel
          lowInput
        />
      )}

      {friendlyMatch.gameType && (
        <>
          {isSingles && (
            <>
              <PlayerOption
                isDoubles={isDoubles}
                player={accountInfo}
                playerInputPlaceholder
                isMe
                handler={() => null}
              />
              <SearchBar
                isDoubles={isDoubles}
                handler={(value) => setFormValue(pick(value, userFields), 'opponent')}
                selectedOption={getFormValue('opponent')}
                hasError={!isEmpty(matchEligibility) && !matchEligibility?.isEligible()}
              />
            </>
          )}

          {isDoubles && (
            <TeamsContainer>
              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="yourTeam">
                    Your Team
                  </Trans>
                  <AverageLevel
                    type={friendlyMatch.gameType}
                    hasTenth={matchEligibility?.doubles?.average % 1 === 0.5}
                  >
                    {matchEligibility?.doubles?.average || '?'}
                  </AverageLevel>
                </TeamTitle>
                <PlayerOption
                  isDoubles={isDoubles}
                  player={accountInfo}
                  playerInputPlaceholder
                  isMe
                  handler={() => null}
                />
                <SearchBar
                  isDoubles={isDoubles}
                  isPartner
                  handler={(value) => setFormValue(pick(value, userFields), 'playerPartner')}
                  selectedOption={getFormValue('playerPartner')}
                  hasError={!isEmpty(matchEligibility) && !matchEligibility?.isEligible()}
                  teams={{
                    playerPartner: friendlyMatch.playerPartner,
                    opponent: friendlyMatch.opponent,
                    opponentPartner: friendlyMatch.opponentPartner,
                  }}
                />
              </Team>

              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="opponentTeam">
                    Opponent Team
                  </Trans>
                  <AverageLevel
                    type={friendlyMatch.gameType}
                    hasTenth={matchEligibility?.doubles?.opponentAverage % 1 === 0.5}
                  >
                    {matchEligibility?.doubles?.opponentAverage || '?'}
                  </AverageLevel>
                </TeamTitle>
                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={1}
                  handler={(value) => setFormValue(pick(value, userFields), 'opponent')}
                  selectedOption={getFormValue('opponent')}
                  hasError={!isEmpty(matchEligibility) && !matchEligibility?.isEligible()}
                  teams={{
                    playerPartner: friendlyMatch.playerPartner,
                    opponent: friendlyMatch.opponent,
                    opponentPartner: friendlyMatch.opponentPartner,
                  }}
                />
                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={2}
                  handler={(value) => setFormValue(pick(value, userFields), 'opponentPartner')}
                  selectedOption={getFormValue('opponentPartner')}
                  hasError={!isEmpty(matchEligibility) && !matchEligibility?.isEligible()}
                  teams={{
                    playerPartner: friendlyMatch.playerPartner,
                    opponent: friendlyMatch.opponent,
                    opponentPartner: friendlyMatch.opponentPartner,
                  }}
                />
              </Team>
            </TeamsContainer>
          )}

          {!isEmpty(matchEligibility) && !matchEligibility.isEligible() && (
            <P xSmall color="error">
              <Trans ns="friendlyMatches" i18nKey="playerMinMaxLevelError">
                Opponent level not compatible for your Ranked Match.  Please select a player within the Level
                Range {{ min: matchEligibility.min }} - {{ max: matchEligibility.max }}, or switch to Unranked
                Match to continue playing with the selected player.
              </Trans>
            </P>
          )}
        </>
      )}
    </>
  );
};

export default FriendlyMatchFormData;
