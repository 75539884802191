export default {
  thankYouForRegistration: 'Благодаря, че се регистрирахте!',
  congratulations: 'Поздравления!',
  topUpSuccessfully: 'Успешно добавихте {{ amount }} {{ currency }} във Вашата виртуална сметка.',
  premiumMembershipSuccessfully: 'Успешно закупихте нов Premium {{period}} акаунт',
  addedTo: {
    mainList: 'Вие сте добавени към основния списък.',
    waitingList: 'Добавени сте към списъка с чакащи.',
    preRegistrationList: 'Бяхте добавен(а) към списъка.',
  },
  level: 'Ниво',
  walletStatus: 'Заплатихте <1>{{ paid }} {{ currency }}</1> от Виртуалния портфейл. \n Баланс: <3>{{ walletBalance }} {{ currency }}</3>.',
  goToEvent: 'Посетете събитието',
  goToDashboard: 'Посетете Начална страница',
  goToVirtualWallet: 'Посети Виртуален портфейл',
  goToMyProfile: 'Отидете на личен профил',
  failed: 'Плащането не се осъществи!',
  'Invalid signature': 'Отговор: <1>Невалиден подпис</1>',
  invalidSignature: 'Отговор: <1>Невалиден подпис</1>',
  paymentProcessing: 'Плащането е разрешено и предстои да бъде обработено.',
  undefinedMembershipSuccessfully: 'Успешно закупихте нов акаунт',
  playFriendlyMatch: 'Докато чакате състезанието да започне, можете да играете <strong>приятелски мач</strong> с вашите приятели или други играчи в Sportya.',
  yourCourtIsBooked: 'Тенис кортът е запазен!',
  goToMyBookings: 'Моите резервации',
  cancellationWithRefundInfo: 'Тази резервация може да бъде анулирана за пълно възстановяване на сумата до <1>{{ hours }}ч</1> преди началния час.',
};
