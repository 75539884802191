import getUserPosition from '../../../pages/FriendlyMatches/Matches/helpers/getUserPosition';

const handleNextPlayerRating = ({
  ratePlayerData,
  setRatePlayerData,
  handleSuccess,
  skipThankYouModal,
}) => {
  const {
    userPositionIndex = 0,
    matchInfo,
    userId,
  } = ratePlayerData;
  let userTypes = ['player', 'playerPartner', 'opponent', 'opponentPartner'];
  const userPosition = getUserPosition(matchInfo, userId);
  switch (userPosition) {
  case 'playerPartner':
    userTypes = ['playerPartner', 'player', 'opponent', 'opponentPartner'];
    break;
  case 'opponent':
    userTypes = ['opponent', 'opponentPartner', 'player', 'playerPartner'];
    break;
  case 'opponentPartner':
    userTypes = ['opponentPartner', 'opponent', 'player', 'playerPartner'];
    break;
  default:
  }

  const usersPosition = userTypes
    .filter((v) => v !== userPosition)
    .filter((v) => (
      (matchInfo?.gameType === 'singles' && !['playerPartner', 'opponentPartner'].includes(v))
      || matchInfo?.gameType === 'doubles'
    ));

  // iterate through each player and show rating modal if available
  for (let i = userPositionIndex; i <= userTypes.length; i++) {
    if (usersPosition[i] && (
      matchInfo?.[usersPosition[i]]?.ratingInfo?.showModal
        || !matchInfo?.[usersPosition[i]]?.ratingInfo?.givenRating
    )) {
      return setRatePlayerData({
        ...ratePlayerData,
        playerToRate: matchInfo?.[usersPosition[i]],
        userPositionIndex: i + 1,
      });
    }
  }

  // display thank you modal
  if (!ratePlayerData?.thankYouModal && !skipThankYouModal) {
    return setRatePlayerData({
      ...ratePlayerData,
      thankYouModal: true,
    });
  }

  // close rating modal
  setRatePlayerData({
    ...ratePlayerData,
    playerToRate: null,
  });

  // handle success function if any
  if (typeof handleSuccess === 'function') {
    handleSuccess();
  }
};

export default handleNextPlayerRating;
