import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import TopSection from './components/TopSection';
import Activity from './components/Activity';
import Titles from './components/Titles';
import Ranks from './components/Rankings';
import Points from './components/Points';
import Stats from './components/Stats';

import {
  Container,
  Tab,
  TabContainer,
  TabsContainer,
} from './styles';
import { getAccountInfo } from '../../components/Layout/reducers/session';
import { updateProfileInfo } from './reducers';
import { store as socketStore } from '../../components/Socket/store';
import InviteFriends from '../Dashboard/components/InviteFriends';
import { ReferralRewardsBanner } from '../Dashboard/components';
import { fetchInvitedUsers } from '../Referral/reducers';

export const UserContext = createContext();

const PlayerProfile = ({ profileInfo, isMyProfile }) => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const openTab = query.get('openTab');
  const whereToGetDataFromRedux = isMyProfile ? 'myProfile' : 'visitedProfile';
  const [activeTab, setActiveTab] = useState(openTab || 'activity');
  const { rewardsInfo = {}, activeSports } = useSelector(state => state.settings?.data);
  const { data: invitedUsers, status: invitedUsersStatus } = useSelector(state => state.invitedUsers);
  const { masterSport } = useSelector(state => state.session);
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  useEffect(() => {
    if (invitedUsersStatus === 'idle' && isMyProfile) {
      dispatch(fetchInvitedUsers());
    }
  }, [invitedUsersStatus, isMyProfile]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        if (isMyProfile) {
          dispatch(getAccountInfo());
        } else {
          dispatch(updateProfileInfo(data));
        }
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => {
        socket.removeAllListeners('accounts-response');
      };
    }
  }, [socketClientId]);

  const active = tab => activeTab === tab;
  const tabList = [
    { label: 'Activity', value: 'activity' },
    { label: 'Titles', value: 'titles' },
    { label: 'Rankings', value: 'rankings' },
    { label: 'Points', value: 'points' },
    { label: 'Sport Stats', value: 'stats' },
  ];
  const contextValue = useMemo(() => ({
    ...profileInfo,
    profile: whereToGetDataFromRedux,
  }), [profileInfo, whereToGetDataFromRedux]);

  const { maxRewards } = rewardsInfo?.currencies?.[profileInfo?.currency] ?? rewardsInfo;
  const maxRewardsReceived = invitedUsers.length >= maxRewards;

  return (
    <>
      <TopSection user={profileInfo} isMyProfile={isMyProfile} />
      {isMyProfile && maxRewardsReceived && (<InviteFriends />)}

      <Container>
        {isMyProfile && !maxRewardsReceived && (<ReferralRewardsBanner firstBox />)}

        <TabsContainer>
          {tabList?.map((tab, key) => {
            const nsKey = tab.value === 'rankings' ? 'leaderboards' : 'player';
            const i18nKey = tab.value === 'rankings' ? 'title' : tab.value;
            return (
              <TabContainer onClick={() => setActiveTab(tab.value)} active={active(tab.value)} key={key}>
                <Tab bold active={active(tab.value)}>
                  <Trans ns={nsKey} i18nKey={i18nKey}>
                    {tab.label}
                  </Trans>
                </Tab>
              </TabContainer>
            );
          })}
        </TabsContainer>

        <UserContext.Provider value={contextValue}>
          {{
            activity: <Activity activeSports={activeSports} masterSport={masterSport} />,
            titles: <Titles activeSports={activeSports} masterSport={masterSport} />,
            rankings: <Ranks activeSports={activeSports} masterSport={masterSport} />,
            points: <Points activeSports={activeSports} masterSport={masterSport} />,
            stats: <Stats activeSports={activeSports} masterSport={masterSport} />,
          }[activeTab]}
        </UserContext.Provider>
      </Container>
    </>
  );
};

export default PlayerProfile;
