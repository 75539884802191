import React from 'react';
import { Trans } from 'react-i18next';

import { FindEvents } from '../../styles';

import Link from '../../../../components/Link';
import H4 from '../../../../components/H4';
import { Paragraph } from '../../../../components';
import LoadImage from '../../../../components/common/LoadImage';

const FindAnEventBanner = ({ hasUpcomingCompetitions, playedBefore }) => (
  <Link to="/events">
    <FindEvents className={hasUpcomingCompetitions ? 'mb40' : (playedBefore ? 'mt20' : 'mb20')}>
      <div>
        <H4>
          <Trans ns="dashboard" i18nKey="findAnEvent">Find an Event</Trans>
        </H4>
        <Paragraph>
          <Trans ns="dashboard" i18nKey="searchForEventsOrCompetitions">Search for competitions in your area</Trans>
        </Paragraph>
      </div>
      <div className="go-to-events">
        <img src={LoadImage('right-arrow-white.svg')} alt="" />
      </div>
    </FindEvents>
  </Link>
);

export default FindAnEventBanner;
