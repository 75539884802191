import styled from '@emotion/styled';
import LoadImage from '../../../../components/common/LoadImage';

export const BannerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '20px',

  borderRadius: theme.functions?.toEm(10),
  background: `url(${LoadImage('findAnEvent-icon/no-results-banner.svg')})`,
  margin: '10px 0 0 0',
}));
