/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccount } from './actions';

import Button from '../../../components/Form/Button';
import { store as socketStore } from '../../../components/Socket/store';
import { SetVisibilityWrapper } from './styles';
import { getAccountInfo } from '../../../components/Layout/reducers/session';
import Logout from '../../../components/Logout';

const SetProfileVisibility = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion } = useSelector(state => state.session);

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const formSchema = Yup.object().shape({
    profileVisibility: Yup.string().required(commonT('required')),
  });

  const getNextStep = () => {
    let list = [];
    profileCompletion?.forEach(step => {
      if (!step.completed) {
        list = [...list, step.path];
      }
    });
    return list[1];
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        dispatch(getAccountInfo());
        history.push(getNextStep());
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'accounts-response',
      );
      socket.on(
        'accounts-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'accounts-response',
        );
      };
    }
  }, [socketClientId]);

  return (
    <SetVisibilityWrapper>
      <Logout />

      <div className="container">
        <h2 className="mb10">{t('completeProfile.profileVisibility')}</h2>
        <p className="mb45">{t('completeProfile.fillProfileVisibility')}</p>

        <Formik
          initialValues={{
            profileVisibility: 'public',
          }}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const payload = values;
            await updateAccount({
              id: accountInfo.id,
              payload,
              addToast,
              setSubmitting,
              socketClientId,
              dispatch,
              history,
              t,
            });
          }}
        >
          {({
            isSubmitting,
            isValid,
          }) => (
            <Form className="w100">
              <div className="custom-radio" role="group" aria-labelledby="profileVisibility">
                <fieldset>
                  <Field type="radio" id="public" name="profileVisibility" value="public" />
                  <label htmlFor="public">
                    <span>{t('completeProfile.allPlayers')}</span>
                    <div className="description">
                      <p className="mb20">{t('completeProfile.allPlayersDescription')}</p>
                      <p className="subtitle">
                        {t('completeProfile.allPlayersSubtitle', { siteName: process.env.REACT_APP_SITE_NAME })}
                      </p>
                    </div>
                  </label>
                </fieldset>

                <fieldset>
                  <Field
                    type="radio"
                    id="friendsAndTheirFriends"
                    name="profileVisibility"
                    value="friendsAndTheirFriends"
                  />
                  <label htmlFor="friendsAndTheirFriends">
                    <span>{t('completeProfile.onlyFriendsAndTheirFriends')}</span>
                    <div className="description">
                      <p>{t('completeProfile.onlyFriendsAndTheirFriendsDescription')}</p>
                    </div>
                  </label>
                </fieldset>

                <fieldset>
                  <Field type="radio" id="friends" name="profileVisibility" value="friends" />
                  <label htmlFor="friends">
                    <span>{t('completeProfile.onlyFriends')}</span>
                    <div className="description">
                      <p>{t('completeProfile.onlyFriendsDescription')}</p>
                    </div>
                  </label>
                </fieldset>
              </div>

              <Button
                black
                fullwidth
                type="submit"
                disabled={isSubmitting || !isValid}
                arrow
                className="mt40"
              >
                {t('completeProfile.saveAndContinue')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </SetVisibilityWrapper>
  );
};

export default SetProfileVisibility;
