import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import Order from './components/Order';
import Pagination from '../../../components/Pagination';
import { H1, H3, H4 } from '../../../components';
import Paragraph from '../../../components/Paragraph';

import { Container, OrderList } from './styles';
import { fetchOrders } from './reducers';
import ContentLoader from '../../../components/ContentLoader';
import { store as socketStore } from '../../../components/Socket/store';

const Orders = () => {
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.dashboard?.orders);
  const [currentPage, setCurrentPage] = useState(0);
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const [downloading, setDownloading] = useState(null);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchOrders());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data: { invoiceUrl } = {} }) => {
      if (message !== "INVOICE_SUCCESSFULLY_FETCHED") {
        await addToast(commonT(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
        });
      }

      // INVOICE_SUCCESSFULLY_FETCHED
      if (message === 'INVOICE_SUCCESSFULLY_FETCHED' && invoiceUrl) {
        setDownloading(null);
        const anchor = document.createElement('a');
        anchor.href = invoiceUrl;
        anchor.target = '_blank';
        anchor.click();
      }
    };

    if (socket) {
      socket.removeAllListeners('payments-response');
      socket.on('payments-response', listenForResponse);
      return () => socket.removeAllListeners('payments-response');
    }
  }, [socketClientId]);

  const handlePageClick = (e) => {
    const page = e.selected + 1;

    if (currentPage !== e.selected) {
      dispatch(fetchOrders({ page }));
      setCurrentPage(e.selected);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{t('orders.seoTitle')}</title>
      </Helmet>

      <H1>
        <Trans ns="accounts" i18nKey="orders.title">My Orders</Trans>
      </H1>
      <OrderList>
        <H3>
          <Trans ns="accounts" i18nKey="orders.subtitle">Order History</Trans>
        </H3>
        {status === 'loading' ? (
          <ContentLoader title type="transactions" items={5} />
        ) : (
          <>
            {!isEmpty(data?.results) && status !== 'loading' ? (
              <>
                <div className="orderHistory">
                  <div className="orderId"><Paragraph smaller>{t('orders.orderList.orderID')}</Paragraph></div>
                  <div className="orderDate"><Paragraph smaller>{t('orders.orderList.dateTime')}</Paragraph></div>
                  <div className="orderType"><Paragraph smaller>{t('orders.orderList.orderType')}</Paragraph></div>
                  <div className="orderAmount"><Paragraph smaller>{t('orders.orderList.amount')}</Paragraph></div>
                  <div className="paymentMethod">
                    <Paragraph smaller>{t('orders.orderList.paymentMethod')}</Paragraph>
                  </div>

                  <div className="orderDetails text-right">
                    <Paragraph smaller>{t('orders.orderList.invoiceInfo')}</Paragraph>
                  </div>

                  {/* <div className='orderDetails' >
                  <Paragraph smaller>{t('orders.orderList.orderDetails')}</Paragraph>
                  </div> */}
                </div>

                <div className="ordersWrap">
                  {data?.results.map((transaction, idx) => (
                    <Order
                      key={idx}
                      transaction={transaction}
                      downloading={downloading}
                      setDownloading={setDownloading}
                    />
                  ))}
                </div>

                {data?.totalPages > 1
                  && (
                    <Pagination
                      pageCount={data?.totalPages || 0}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      initialPage={currentPage}
                    />
                  )}
              </>
            ) : (
              <div className="noOrderWrapp">
                <H4><Trans ns="accounts" i18nKey="orders.noOrders">No previous orders exist</Trans></H4>
              </div>
            )}
          </>
        )}
      </OrderList>
    </Container>
  );
};

export default Orders;
