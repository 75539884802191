import React from 'react';
import { Trans } from 'react-i18next';
import { H4, P } from '../../../../../components/Collection';
import { Stats, StatsContainer } from '../styles';

const Statistics = ({
  user,
  points,
  gameType,
  sportType,
}) => {
  if (sportType) {
    Object.assign(user, {
      gameLevel: {
        singles: user.sports?.[sportType]?.singles?.gameLevel || null,
        doubles: user.sports?.[sportType]?.doubles?.gameLevel || null,
      },
      rank: {
        singles: user.sports?.[sportType]?.singles?.rank,
        doubles: user.sports?.[sportType]?.doubles?.rank,
      },
      points: {
        singles: user.sports?.[sportType]?.singles?.points,
        doubles: user.sports?.[sportType]?.doubles?.points,
      },
      elo: {
        singles: user.sports?.[sportType]?.singles?.elo,
        doubles: user.sports?.[sportType]?.doubles?.elo,
      },
      leaderboard: {
        singles: user.sports?.[sportType]?.singles?.leaderboard,
        doubles: user.sports?.[sportType]?.doubles?.leaderboard,
      },
    });
  }

  const getTotalPoints = () => {
    let total = 0;
    if (points?.length) {
      points?.map(a => total += a.totalWonPoints);
    }
    return total;
  };

  const getTotalPenaltyPoints = () => {
    let total = 0;
    if (user?.penalties?.length) {
      user?.penalties?.map(a => total += a.points);
    }
    return total;
  };

  const summary = {
    penalties: getTotalPenaltyPoints(),
    points: {
      singles: Number(user?.points?.singles),
      doubles: Number(user?.points?.doubles),
      total: getTotalPoints(),
    },
    rank: {
      singles: Number(user?.rank?.singles),
      doubles: Number(user?.rank?.doubles),
      total: (Number(user?.rank?.singles) + Number(user?.rank?.doubles)) / 2,
    },
    competitions: {
      singles: Number(user?.leaderboard?.singles?.eventsParticipation ?? 0),
      doubles: Number(user?.leaderboard?.doubles?.eventsParticipation ?? 0),
    },
    friendlyMatches: {
      singles: Number(user?.leaderboard?.singles?.friendlyMatches?.points ?? 0),
      doubles: Number(user?.leaderboard?.doubles?.friendlyMatches?.points ?? 0),
    },
    basePoints: Number(user?.initialPoints?.[gameType]),
  };

  return (
    <StatsContainer>
      <Stats>
        <P small>
          <Trans ns="player" i18nKey="playerRank">
            Player Rank
          </Trans>
        </P>
        <H4>{summary?.rank?.[gameType] || '-'}</H4>
      </Stats>

      <Stats>
        <P small>
          <Trans ns="player" i18nKey="totalPoints">
            Total Points
          </Trans>
        </P>
        <H4>{summary?.points?.[gameType] || '-'}</H4>
      </Stats>

      <Stats>
        <P small>
          <Trans ns="player" i18nKey="competitionPoints">
            Competition Points
          </Trans>
        </P>
        <H4>{getTotalPoints() || '0'}</H4>
        <P xSmall color="darkTurquoise">
          <Trans ns="player" i18nKey="best7results">
            Best 7 results
          </Trans>
        </P>
      </Stats>

      <Stats>
        <P small>
          <Trans ns="player" i18nKey="friendlyMatchPoints">
            Ranked Friendly Match Points
          </Trans>
        </P>
        <H4>{summary?.friendlyMatches?.[gameType] ?? '0'}</H4>
      </Stats>

      <Stats noBorder>
        <P small>
          <Trans ns="player" i18nKey="basePoints">
            Base Points
          </Trans>
        </P>
        <H4>{summary?.basePoints || '-'}</H4>
      </Stats>
    </StatsContainer>
  );
};

export default Statistics;
