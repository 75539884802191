export default {
  events: 'Намерете събитие',
  courts: 'Кортове',
  membership: 'Абонаменти',
  comingSoon: 'Скоро',
  findEvent: 'Търси Събитие',
  book_court: 'Резервирайте тенис корт',
  forPlayers: 'За Играчи',
  forClubs: 'За Клубове',
  blog: 'Блог',
  help: 'Помощ',
  aboutUs: 'За нас',
  motto: 'Изиграйте най-добрия си тенис мач!',
  joinNow: 'Присъедини се сега',
  privacy: 'Политика за поверителност',
  termsAndConditions: 'Правила и условия',
  returnPolicy: 'Условия за връщане',
  language: 'Език',
  english: 'Английски',
  romanian: 'Румънски',
  copyrightFooter: 'Всички права са запазени.',
  rules: 'Sportya  - Правила и разпоредби',
  groupsPlaying: 'Режими На Игра За Групи',
  localLeaguesRules: 'Местни лиги - Правила и разпоредби',
  sportyaRomania: 'Sportya Румъния',
  sportyaBulgaria: 'Sportya България',
  sportya: 'Sportya',
  sportyaDesc: 'Нашата мисия е да свързваме и вдъхновяваме спортисти по целия свят чрез създаване на конкурентни платформи, събития и специфични инструменти, които им помагат да се възползват максимално от спортния живот.',

  findPlayers: 'Намерете играч',
  rankings: 'Класиране',
  // blog: 'Блог',
};
