import React from 'react';
import { Initials } from './styles';
import nameInitials from '../../../pages/FriendlyMatches/Matches/helpers/nameInitials';

const InitialsComponent = ({ firstName, lastName }) => (
  <Initials>
    {nameInitials({ firstName, lastName })}
  </Initials>
);

export default InitialsComponent;
