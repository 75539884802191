const handleScore = (scoreList, setNumber, value, isPlayer, isTie) => {
  const newValue = value === '' ? '' : Number(value);
  const updatedScoreForm = JSON.parse(JSON.stringify(scoreList));
  const targetScore = isPlayer ? updatedScoreForm.player.score : updatedScoreForm.opponent.score;
  const targetIndex = targetScore.findIndex((obj) => obj.set === setNumber);

  if (typeof newValue === 'number') {
    if (isTie) {
      if (targetScore[targetIndex]) {
        targetScore[targetIndex].tie = newValue;
      } else {
        targetScore.push({ set: setNumber, points: null, tie: newValue });
      }
    } else if (targetScore[targetIndex]) {
      targetScore[targetIndex].points = newValue;
    } else {
      targetScore.push({ set: setNumber, points: newValue, tie: null });
    }
  }

  return updatedScoreForm;
};

export default handleScore;
