export default {
  thankYouForRegistration: 'Îți mulțumim pentru înregistrare!',
  congratulations: 'Felicitări!',
  topUpSuccessfully: 'Ai adăugat cu succes {{ amount }} {{ currency }} în Portofelul Virtual Sportya.',
  undefinedMembershipSuccessfully: 'Contul tău a fost activat!',
  premiumMembershipSuccessfully: 'Contul tău Premium {{period}} a fost activat!',
  addedTo: {
    mainList: 'Ai fost adăugat pe Lista Principală.',
    waitingList: 'Ați fost adăugat pe Lista de Așteptare.',
    preRegistrationList: 'Ați fost adăugat pe Lista de Sortare.',
  },
  level: 'Level',
  walletStatus: 'Ai plătit <1>{{ paid }} {{ currency }}</1> din Portofelul Virtual. \n Balanță: <3>{{ walletBalance }} {{ currency }}</3>.',
  goToEvent: 'Mergi la Eveniment',
  goToDashboard: 'Mergi la Pagina Principală',
  goToVirtualWallet: 'Mergi la Portofelul Virtual',
  goToMyProfile: 'Mergi la Profilul Personal',
  failed: 'Nu am putut procesa plata!',
  'Invalid signature': 'Răspuns: <1>Semnatura invalidă</1>',
  invalidSignature: 'Răspuns: <1>Semnatura invalidă</1>',
  paymentProcessing: 'Plata a fost autorizată și urmează a fi procesată.',
  playFriendlyMatch: 'În timp ce aștepți să înceapă competiția, poți juca un <strong>meci amical</strong> cu prietenii sau alți jucători pe Sportya',
  yourCourtIsBooked: 'Terenul a fost rezervat!',
  goToMyBookings: 'Mergi la Rezervările Mele',
  cancellationWithRefundInfo: 'Această rezervare poate fi anulată cu o rambursare completă cu până la <1>{{ hours }}h</1> înainte de Ora de Începere.',
};
