import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  get, post, put, remove,
} from '../../../components/common/http';

// Actions
export const fetchBlockedPlayers = createAsyncThunk('accounts/blockedPlayers', async () => {
  const { data: { data } } = await get('/blocked-players');
  return data;
});

export const unblockPlayer = createAsyncThunk('accounts/unblockPlayer', async (requestData) => {
  const { data: { data } } = await put('/player/unblock', requestData);
  return data;
});

export const blockPlayer = createAsyncThunk('accounts/blockPlayer', async (requestData) => {
  const { data: { data } } = await put('/player/block', requestData);
  return data;
});

export const reportPlayer = createAsyncThunk('accounts/reportPlayer', async (requestData) => {
  const { data: { data } } = await post('/accounts/reports', requestData);
  return data;
});

export const addFriend = createAsyncThunk('accounts/addFriend', async (requestData) => {
  const { data: { data } } = await post('/friends/add', requestData);
  return data;
});

export const deleteFriend = createAsyncThunk('accounts/deleteFriend', async (requestData) => {
  const { data: { data } } = await remove('/friends/delete', requestData);
  return data;
});

export const acceptFriend = createAsyncThunk('accounts/acceptFriend', async (requestData) => {
  const { data: { data } } = await put('/friends/accept', requestData);
  return data;
});

// Slice
const blockedPlayers = createSlice({
  name: 'blockedPlayers',
  initialState: {
    data: [],
    initial: [],
    hasSearched: false,
    newest: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchBlockedPlayers.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchBlockedPlayers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.results;
      state.initial = action.payload;
    },
    [fetchBlockedPlayers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default {
  list: blockedPlayers.reducer,
};
