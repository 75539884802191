/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import { Badge, BadgeImage } from '../../../../components/Event/styles';
import { P, Contain } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

const Container = styled.div({
  display: 'flex',
  '@media (max-width: 1120px)': {
    flexDirection: 'column',
  },
});

const List = styled.ul({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 1120px)': {
    flexDirection: 'column',
    alignItems: 'unset',
  },
});

const Item = styled.li({
  margin: '0 0 0 10px',
  '&:first-of-type': {
    margin: '0 10px 0 0',
    listStyle: 'none',
  },
  p: {
    margin: 'unset',
  },
  '@media (max-width: 1120px)': {
    listStyle: 'none',
    margin: '10px 0 0 0',
  },
});

const currentPhaseStatus = ({ eventInfo, maintenance }) => {
  const { t } = useTranslation('events');

  const nextPhaseStartDate = end => {
    const order = ['gold', 'platinum', 'champions'].includes(eventInfo?.category)
      ? [
        'openingSoon',
        'preRegistrationsOpen',
        'sortPending',
        'registrationsOpen',
        'drawPending',
        'readyToPlay',
        'playing',
        'resultsPending',
      ]
      : [
        'openingSoon',
        'registrationsOpen',
        'drawPending',
        'readyToPlay',
        'playing',
        'resultsPending',
      ];

    if (!isEmpty(eventInfo?.currentPhase)) {
      const indexOfPhase = order.indexOf(eventInfo?.currentPhase?.status);
      const nextPhase = order[indexOfPhase + 1];
      const nextPhaseStart = end ? eventInfo?.phases[nextPhase]?.endDate : eventInfo?.phases[nextPhase]?.startDate;

      return dateFormat(nextPhaseStart, 'd mmmm, HH:MM');
    }
  };

  const display = {
    phase: !['resultsPending', 'ended'].includes(eventInfo?.currentPhase?.status),
    startDate: ['openingSoon', 'preRegistrationsOpen', 'sortPending'].includes(eventInfo?.currentPhase?.status),
    endDate: ['preRegistrationsOpen', 'registrationsOpen', 'drawPending', 'readyToPlay', 'playing']
      .includes(eventInfo?.currentPhase?.status),
    category: eventInfo?.category,
    currentPhase: eventInfo?.currentPhase?.status,
  };

  const badgeBgs = {
    firstServe: '#E9E9E9',
    silver: '#B0B0B0',
    gold: '#FFCC18',
    platinum: '#5854FF',
    special: '#CB5DFF',
    champions: '#CB5DFF',
    league: '#39D377',
  };

  const badgeColor = {
    firstServe: 'darkGrey',
  };

  return (!isEmpty(eventInfo?.currentPhase) && !maintenance) ? (
    <Container>
      {display.category && (
        <Contain
          margin="0 10px 0 0"
        >
          <Contain
            align="center"
            height="38px"
          >
            <Badge bg={badgeBgs?.[eventInfo?.category]}>
              <BadgeImage src={LoadImage(`${eventInfo?.category}-category-icon.svg`)} alt={eventInfo?.category} />
              <P
                xSmall
                bold
                color={badgeColor?.[eventInfo?.category] || 'white'}
              >
                <Trans ns="events" i18nKey={`filtersList.${eventInfo?.category}`}>{eventInfo?.category}</Trans>
              </P>
            </Badge>
            {display?.currentPhase && (
              <Contain align="center" height="38px">
                <P bold margin="unset">
                  <Trans ns="events" i18nKey={display?.currentPhase}>Unavailable</Trans>
                </P>
              </Contain>
            )}
          </Contain>
        </Contain>
      )}
      {display.phase && (
        <List>
          {display.startDate && (
            <Item>
              <P>
                <Trans ns="events" i18nKey={`phaseStatuses.${eventInfo?.currentPhase.status}StartDate`}>
                  Start date:
                  {{
                    date: ['preRegistrationsOpen'].includes(eventInfo?.currentPhase.status)
                      ? (dateFormat(eventInfo?.currentPhase.endDate, 'd mmmm, HH:MM'))
                      : nextPhaseStartDate(),
                  }}
                  {{
                    listType: ['gold', 'platinum'].includes(eventInfo?.category)
                      ? t('phaseStatuses.sortingList')
                      : t('phaseStatuses.mainList'),
                  }}
                </Trans>
              </P>
            </Item>
          )}

          {display.endDate && (
            <Item>
              <P>
                <Trans ns="events" i18nKey={`phaseStatuses.${eventInfo?.currentPhase?.status}EndDate`}>
                  End date:
                  {' '}
                  {{
                    date: ['preRegistrationsOpen'].includes(eventInfo?.currentPhase?.status)
                      ? nextPhaseStartDate(true)
                      : ['drawPending', 'readyToPlay', 'playing'].includes(eventInfo?.currentPhase?.status)
                        ? dateFormat(eventInfo?.phases?.playing?.endDate, 'd mmmm, HH:MM')
                        : dateFormat(eventInfo?.currentPhase?.endDate, 'd mmmm, HH:MM'),
                  }}
                </Trans>
              </P>
            </Item>
          )}
        </List>
      )}
    </Container>
  ) : maintenance ? (
    <P bold>
      <Trans ns="common" i18nKey="inMaintenance">Maintenance</Trans>
    </P>
  ) : '';
};

export default currentPhaseStatus;
