import styled from '@emotion/styled';
import { P } from '../../../../components/Collection';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '196px',
  borderRadius: '10px',
  border: '1px solid #EAF6F6',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  margin: '0 0 20px 0',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
    height: 'unset',
    padding: '20px',
  },
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const LevelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '136px',
  height: '100%',
  minWidth: '143px',
  borderRight: '1px solid #EAF6F6',
  '@media (max-width: 1190px)': {
    borderRight: 'unset',
  },
});

export const FollowIcon = styled.img({
  margin: '0 10px 0 0',
});

export const DataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const Info = styled.div({
  padding: '40px 40px 0 40px',
  width: 'fit-content',
  '@media (max-width: 1190px)': {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'unset',
  },
});

export const Lists = styled.div({
  display: 'flex',
  padding: '0 0 40px 40px',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
  },
});

export const Data = styled.div({
  display: 'flex',
  height: 'inherit',
  '@media (max-width: 1190px)': {
    flexDirection: 'column',
  },
});

export const Item = styled(P)({});

export const Value = styled.span({
  height: '100%',
  margin: '0 0 0 5px',
  fontWeight: 400,
  '&:after': {
    content: '","',
  },
  '&:last-of-type:after': {
    content: '""',
  },
});

export const Badge = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  height: 'max-content',
  borderRadius: '20px',
  width: 'fit-content',
  margin: '10px 10px 10px 0',
  background: props.theme.colors?.[props.bg],
  color: props.theme.colors?.[props.textcolor],
  '@media (max-width: 1190px)': {
    margin: '10px 0',
  },
}));

export const List = styled.span({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Checkmark = styled.img({});
