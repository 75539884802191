import styled from '@emotion/styled';
import Container from '../../components/Container';

export const ForPlayers = styled(Container)(props => ({
  display: 'flex',
  marginBottom: props.theme.functions.toEm(65),
  img: {
    maxWidth: '600px',
    width: '100%',
    height: '100%',
    '@media (max-width: 1040px)': {
      marginBottom: '40px',
    },
  },
  '@media (max-width: 1040px)': {
    flexDirection: 'column',
    alignItems: 'center',
    '.listing': {
      padding: 'unset',
    },
  },
}));

export const List = styled.ul({
  maxWidth: '500px',
});

export const Item = styled.li(props => ({
  marginBottom: props.theme.functions.toEm(50),
  maxWidth: '410px',
  paddingLeft: props.theme.functions.toEm(20),
  '&::marker': {
    content: '"✓"',
    fontSize: props.theme.functions.toEm(22),
    fontFamily: 'system-ui',
    fontWeight: 'bold',
    '@media (max-width: 450px)': {
      content: '""',
    },
  },
}));

export const ForClubs = styled(Container)(props => ({
  marginBottom: props.theme.functions?.toEm(65),
  '.container': {
    border: '2px solid #F2F2F2',
    borderRadius: '10px',
    padding: `${props.theme.functions?.toEm(20)} ${props.theme.functions?.toEm(20)}`,
  },
  '@media (max-width: 930px)': {
    '.listing': {
      margin: '0 auto',
      flexDirection: 'column',
    },
  },
}));
