export default {
  SOMETHING_WENT_WRONG: "És… KINT van! Valami elromlott. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  CHANGE_PASSWORD_FAILED: "A jelszót nem sikerült frissíteni.",
  CHANGE_PASSWORD_SUCCESS: "A jelszó sikeresen frissítve.",
  ACCOUNT_ACTIVATED: "A fiókod sikeresen érvényesítve.",
  ACCOUNT_ALREADY_ACTIVATED: "A fiókod már érvényesítve van.",
  COULD_NOT_ACTIVATE_ACCOUNT: "Hiba történt, és a fiókodat nem tudtuk érvényesíteni. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  ACCOUNT_ALREADY_EXIST: "A fiók már létezik. Kérjük, jelentkezz be, vagy vátoztasd meg a jelszavad.",
  AUTH_FAILED: "Hiba történt, és nem tudtunk azonosítani. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  INVALID_CREDENTIALS: "Érvénytelen bejelentkezési adatok. Kérjük, ellenőrizd a megadott adatokat, és próbáld újra.",
  INTERNAL_ERROR: "Belső szolgáltatási hiba. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  SERVICE_UNAVAILABLE: "Hoppá! Valami nem működik. Kérjük, jelentse a hibát a contact@sportya.net címen, hogy a lehető leghamarabb kijavíthassuk.",
  UPDATE_ACCOUNT_SUCCESS: "A fiók sikeresen frissítve.",
  UPDATE_ACCOUNT_FAILED: "A fiókot nem tudtuk frissíteni. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  UPDATE_CLUB_SUCCESS: 'A klub fiókja sikeresen frissítve.',
  UPDATE_CLUB_FAILED: 'A klub fiókját nem tudtuk frissíteni.',
  SESSION_EXPIRED: "A munkamenet lejárt. Kérjük, jelentkezz be újra.",
  SUCCESSFULLY_FOLLOW_EVENT: "Most már követed az eseményt.",
  SUCCESSFULLY_UNFOLLOW_EVENT: "Megszüntetted az esemény követését.",
  FAILED_FOLLOW_EVENT: "Az esemény követése/követésének megszüntetése nem sikerült.",
  ADD_FRIEND_SUCCESS: "Sikeresen elküldted a baráti kérést",
  ACCEPT_FRIEND_SUCCESS: "Gratulálunk! Új barátod van a Sportya",
  REMOVE_FRIEND_SUCCESS: "A játékost sikeresen eltávolítottuk az ismerősök listájáról",
  DECLINE_FRIEND_SUCCESS: "Sikeresen elutasítottad az ismerősként való felkérést",
  CANCEL_FRIEND_SUCCESS: "Sikeresen törölted az elküldött baráti kérést",
  UNBLOCK_PLAYER_SUCCESS: 'A játékos blokkolását sikeresen feloldották.',
  BLOCK_PLAYER_SUCCESS: 'A játékost sikeresen blokkolták.',
  SUCCESSFULLY_CREATED_REPORT: 'A játékost sikeresen feljelentették.',
  SUBSCRIPTION_ALREADY_ACTIVATED: "A tagságod már aktiválva volt.",
  COULD_NOT_AUTHORIZE_PAYMENT: "Nem tudtuk engedélyezni a fizetést. Kérjük, próbáld újra.",
  MEMBERSHIP_NOT_FOUND: "A kiválasztott tagság nem található. Kérjük, próbáld újra.",
  INSUFFICIENT_BALANCE: "A tagság frissítése nem sikerült. Fedezethiány.",
  AMOUNT_NOT_VALID: "A tagság frissítése nem sikerült. Érvénytelen összeg.",
  SUMMER_SUBSCRIPTION_ACTIVATED: "C'mon! Nyári fiókodat sikeresen aktiváltuk.",
  PREMIUM_SUBSCRIPTION_ACTIVATED: "C'mon! Prémium fiókodat sikeresen aktiváltuk.",
  ALREADY_REGISTERED_TO_COMPETITION: "Ön már regisztrált a max. nr. azonos típusú versenyek, erre az eseményre.",
  MAX_ALLOWED_SAME_COMPETITION_ERROR: "Már regisztráltál az ugyanilyen típusú versenyek maximális számára ezen az eseményen.",
  EVENT_REGISTRATION_SUCCESSFULLY: "Sikeresen regisztráltál a versenyre.",
  EVENT_WITHDRAWAL_SUCCESSFULLY: "Sikeresen visszaléptél a versenyről.",
  FEE_TAX_NOT_AVAILABLE: "A verseny díjai még nincsenek meghatározva. Kérjük, próbáld újra később.",
  NOT_REGISTERED_TO_COMPETITION: "Nem regisztráltál erre a versenyre.",
  PAYMENT_NOT_FOUND: "Nem sikerült kiszámítani a verseny díjának adóját. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  INVALID_FEE_TAX: "Nem sikerült kiszámítani a verseny díjának adóját. Dolgoznunk kell ezen, ezért kérjük, segíts nekünk, és tudasd velünk a hq@sportya.net címen, hogy mi történt.",
  USER_INVALID_FEE_TAX: "Nem sikerült kiszámítani a verseny díjának a felhasználói fiókodat terhelő adóját. Kérjük, próbáld újra később.",
  PARTNER_USER_INVALID_FEE_TAX: "Nem sikerült kiszámítani a verseny díjának a partner felhasználói fiókodat terhelő adóját. Kérjük, próbáld újra később.",
  CURRENT_PASSWORD_NOT_MATCH: "Régi jelszavad helytelen. Kérjük, próbáld újra.",
  USER_NOT_ALLOWED_CASE_LEVEL: "A regisztrációt nem lehet feldolgozni. A játékszinted nem felel meg a verseny regisztrációs feltételeinek.",
  PARTNER_USER_NOT_ALLOWED_CASE_LEVEL: "A regisztrációt nem lehet feldolgozni. A csapatpartnered játékszintje nem felel meg a verseny regisztrációs feltételeinek.",
  ACCESS_FORBIDDEN: 'Hozzáférés letiltva.',
  ACCOUNT_BLOCKED: 'A fiókod blokkolva van. Kérjük, lépj kapcsolatba velünk.',
  ERROR_TIMEFRAME: "Erre a versenyre még nem nyílt meg a regisztráció.",
  USER_NOT_ALLOWED_CASE_GENDER: "A regisztrációt nem lehet feldolgozni. A nemed nem felel meg a verseny regisztrációs feltételeinek.",
  PARTNER_USER_NOT_ALLOWED_CASE_GENDER: "A regisztrációt nem lehet feldolgozni. A csapatpartnered neme nem felel meg a verseny regisztrációs feltételeinek.",
  WITHDRAW_NOT_ALLOWED: 'Az eseményről való visszalépés többé nem megengedett. Kérjük, vedd fel velünk a kapcsolatot a hq@sportya.net címen.',
  ERROR_BANNED_USER: 'Nem csatlakozhatsz ehhez a versenyhez, mert jelenleg le vagy tiltva. Kérjük, várd meg, amíg lejár a letiltási időszak, mielőtt csatlakoznál egy új versenyhez.',
  ERROR_BANNED_PARTNER: '{{partnerName}} – {{partnerId}} játékos jelenleg le van tiltva az eseményekre való regisztrációtól. Kérjük, válassz más játékost partnerként.',
  ERROR_USER_REGISTERED_SAME_WEEK: `Már regisztráltál egy versenyre, amely ezzel az eseménnyel egy időben zajlik. Tekintse meg azokat az eseményeket, amelyekre már regisztrált a Saját események részben, amely fiókkezelése része.`,
  USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: 'A regisztrációt nem lehet feldolgozni. Az Ön fióktípusa (INGYENES) nem egyezik a verseny regisztrációs feltételeivel (csak PREMIUM fiókok esetén).',
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: 'A regisztrációt nem lehet feldolgozni. Csapatpartnerének fióktípusa (INGYENES) nem egyezik a verseny regisztrációs feltételeivel (csak PREMIUM fiókok).',
  USER_NOT_ALLOWED_CASE_MAXAGE: 'A regisztrációt nem lehet feldolgozni. Az életkorod nem felel meg a verseny regisztrációs feltételeinek.',
  PARTNER_USER_NOT_ALLOWED_CASE_MAXAGE: 'A regisztrációt nem lehet feldolgozni. A csapatpartnered életkora nem felel meg a verseny regisztrációs feltételeinek.',
  USER_NOT_ALLOWED_CASE_MINAGE: 'A regisztrációt nem lehet feldolgozni. Az életkorod nem felel meg a verseny regisztrációs feltételeinek.',
  PARTNER_USER_NOT_ALLOWED_CASE_MINAGE: 'A regisztrációt nem lehet feldolgozni. A csapatpartnered életkora nem felel meg a verseny regisztrációs feltételeinek.',
  USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: 'Nincs meg a minimális lejátszott (érvényesített) eseményszámod, amely szükséges ahhoz, hogy csatlakozz ehhez a versenyhez.',
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "A csapatpartnerednek nincs meg a minimális lejátszott (érvényesített) eseményszáma, amely szükséges ahhoz, hogy csatlakozzatok ehhez a versenyhez",
  USER_NOT_ALLOWED_CASE_RANK: "Nem felelsz meg a regisztrációs feltételeknek. A jelenlegi ranglistahelyezésed nem esik az erre a versenyre meghatározott keretek közé.",
  PARTNER_USER_NOT_ALLOWED_CASE_RANK: "A csapatpartnered nem felel meg a regisztrációs feltételeknek. Jelenlegi ranglistahelyezése nem esik az erre a versenyre meghatározott keretek közé.",
  TEAM_NOT_ALLOWED_AVG_LEVEL: "A regisztrációt nem lehet feldolgozni. A csapatszinted nem felel meg az erre a versenyre érvényes szintkorlátozásnak.",
  TEAM_LEVEL_FORBIDDEN_TO_TEAMS: "A regisztrációt nem lehet feldolgozni. A szintek a csapatodban nem felelnek meg az erre a versenyre érvényes szintkorlátozásnak.",
  TEAM_MUST_BE_MIXT: "A regisztrációt nem lehet feldolgozni. A csapatnak vegyesnek kell lennie, hogy megfeleljen a verseny regisztrációs feltételeinek.",
  COULD_NOT_CREATE_FRIENDLY_MATCH: 'A teniszmérkőzés jelenleg nem hozható létre. Kérlek, próbáld újra később!',
  SUCCESSFULLY_UPDATED_FRIENDLY_MATCH: 'A barátságos mérkőzésed sikeresen frissítve.',
  COULD_NOT_UPDATE_FRIENDLY_MATCH: 'A barátságos mérkőzésed jelenleg nem frissíthető. Kérlek, próbáld újra később!',
  SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH: 'Sikeresen elfogadtad a mérkőzésre szóló meghívást.',
  COULD_NOT_ACCEPT_FRIENLDY_MATCH: 'A mérkőzés meghívója jelenleg nem fogadható el. Kérlek, próbáld újra később!',
  SUCCESSFULLY_DECLINED_FRIENDLY_MATCH: 'Sikeresen visszautasítottad a mérkőzésre szóló meghívást.',
  COULD_NOT_DECLINE_FRIENLDY_MATCH: 'A mérkőzésre való meghívást jelenleg nem lehet visszautasítani. Kérlek, próbáld újra később!',
  SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN: 'A mérkőzésről való visszalépésedet sikeresen feldolgoztuk.',
  COULD_NOT_WITHDRAW_FRIENLDY_MATCH: 'A mérkőzésről való visszalépését jelenleg nem tudjuk feldolgozni. Kérlek, próbáld újra később!',
  SUCCESSFULLY_CANCELED_FRIENDLY_MATCH: 'A mérkőzésedet sikeresen töröltük.',
  COULD_NOT_CANCEL_FRIENLDY_MATCH: 'A mérkőzésed jelenleg nem törölhető. Kérlek, próbáld újra később!',
  SUCCESSFULLY_REFUSED_FRIENDLY_MATCH: 'A mérkőzést le nem játszottnak jelentetted.',
  COULD_NOT_REFUSE_FRIENLDY_MATCH: 'A mérkőzés jelenleg nem jelenthető lejátszottként. Kérlek, próbáld újra később!',
  SUCCESSFULLY_SAVED_SCORE: 'A pontszám sikeresen elmentve.',
  COULD_NOT_SAVE_SCORE: 'Pontszáma jelenleg nem menthető. Kérlek, próbáld újra később!',
  passwordMustMatch: 'A jelszavak nem egyeznek. Kérjük, próbálja újra.',
  missingLocation: 'Ennek az eseménynek a helyszíne még nincs meghatározva.',
  rank: 'Ranglista',
  age: 'Életkor',
  minage: 'Mimimális életkor',
  maxage: 'Maximális életkor',
  january: "Január",
  february: "Február",
  march: "Március",
  april: "Április",
  may: "Május",
  june: "Június",
  july: "Július",
  august: "Augusztus",
  september: "Szeptember",
  october: "Október",
  november: "November",
  december: "December",
  Mon: "Hé",
  Tue: "Ke",
  Wed: "Sze",
  Thu: "Csü",
  Fri: "Pé",
  Sat: "Szo",
  Sun: "Va",
  Sunday: "Vasárnap",
  Monday: "Hétfő",
  Tuesday: "Kedd",
  Wednesday: "Szerda",
  Thursday: "Csütörtök",
  Friday: "Péntek",
  Saturday: "Szombat",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Már",
  Apr: "Ápr",
  Jun: "Jún",
  Jul: "Júl",
  Aug: "Aug",
  Sep: "Szep",
  Oct: "Okt",
  Nov: "Nov",
  Dec: "Dec",
  January: "Január",
  February: "Február",
  March: "Március",
  April: "Április",
  May: "Május",
  June: "Június",
  July: "Július",
  August: "Augusztus",
  September: "Szeptember",
  October: "Október",
  November: "November",
  December: "December",
  male: "Férfi",
  female: "Nő",
  men: "Férfiak",
  women: "Nők",
  mixt: "Vegyes",
  select: "Válassza ki",
  level: "Szint",
  maintenance: 'Karbantartás',
  inMaintenance: 'Karbantartás alatt',
  regBackSoon: 'Hamarosan újraindul a regisztráció',
  continue: "Folytatás",
  requiredField: "Kötelező mező",
  nrNotValid: 'Érvénytelen telefonszám',
  invalidEmail: "Érvénytelen e-mail",
  minChars: "Minimum {{ karakter }} karakter",
  maxChars: "Maximum {{ karakter }} karakter",
  mustContainerSpecialChars: "Tartalmazzon legalább egy speciális karaktert (@,!,# stb.)",
  logout: "Kijelentkezés",
  login: "Bejelentkezés",
  signup: "Regisztráció",
  password: "Jelszó",
  email: "Email",
  grass: 'Fű',
  clay: 'Salak',
  artificial: 'Mű',
  hard: 'Kemény',
  close: 'Bezárás',
  firstName: "Keresztnév",
  lastName: "Vezetéknév",
  phoneNumber: "Telefonszám",
  phone: 'Telefon',
  imClubManager: "Klubvezető vagyok",
  clubOwner: 'Klubtulajdonos',
  moreDetails: 'Részletek',
  birthdate: 'Születési dátum',
  days: 'Napok',
  day: 'Nap',
  from: 'Feladó',
  to: 'Címzett',
  back: 'Vissza',
  openHours: 'Nyitvatartás',
  dismiss: 'Elvetés',
  metaDescription: 'A Sportya az a hely, amelynek mindannyian részesei akarunk lenni, egy nemzet, amelyet a sport iránti szenvedélyünk irányít. A versenyszellem tölt fel minket energiával. Kor, nem, bőrszín vagy egyéb meggyőződés nem számít. A Sportya földjének nincsenek földrajzi határai, nem kell útlevél, és csak a fair play vezérel bennünket. Mindannyian ugyanazt a nyelvet beszéljük, és ez a SPORT nyelve.',
  metaKeywords: 'sportya, tenisz, teniszesemények, teniszversenyek, tenisztornák, teniszmérkőzések, teniszközösség, teniszligák, helyi ligák, teniszpartner, teniszjátékosok, amatőr játékosok, teniszklubok, barátságos mérkőzések, teniszezni, játékosokat találni, eseményeket találni, teniszpályák, legjobb mérkőzés, sportok',
  ogTitle: 'Sportya – Hozza össze a legjobb meccsét',
  ogDescription: 'Csatlakozz hozzám a Sportyához, hogy barátságos mérkőzéseket játszhass, és részt vegyél hazai és nemzetközi versenyeken! \n\r Hozd létre fiókodat az egyedi meghívólink segítségével, és szerezz jutalmakat: \n\r {{ link }}',
  saveChanges: 'Változtatások mentése',
  languages: {
    en: "English",
    bg: "Български",
    ro: "Română",
    hu: "Magyar",
    cz: 'Čeština',
  },
  gold: "Arany",
  silver: "Ezüst",
  platinum: "Platina",
  champions: "Bajnokok",
  winners: 'Győztesek',
  courtNr: '{{courtNr}} pálya',
  premium: "Prémium",
  special: "Speciális",
  firstServe: 'Első adogatás',
  league: 'Liga',
  allAges: "Minden életkor",
  type: 'Típus',
  customAge: "Válasszon egyéni életkort",
  location: "Elhelyezkedés",
  LocationGmaps: 'Elhelyezkedés',
  category: "Kategória",
  month: "Hónap",
  year: "Év",
  premiumAccountMembershipUpgrade: 'SPORTYA Tagság felminősítése Prémium {{ period }} fiókra',
  virtualWalletTopUp: 'SPORTYA Virtuális pénztárca – {{ amount }} {{ currency }} feltöltése',
  eventRegistration: 'SPORTYA Regisztráció a következőre: {{ eventName }}',
  partnerEventRegistration: 'SPORTYA Regisztráció partner a következőre: {{ eventName }}',
  cookieConsent: 'Ez a weboldal cookie-kat használ, hogy a legjobb élményt nyújthassa. A Sportya használatával Ön hozzájárul a cookie-k használatához az <1>Adatvédelmi irányelvek<1> című dokumentumban foglaltaknak megfelelően',
  cookieConsentButton: 'Cookie-k engedélyezése',
  all: 'Mind',
  romania: 'Románia',
  bulgaria: 'Bulgária',
  moldova: 'Moldova',
  hungary: 'Magyarország',
  country: 'Ország',
  migration: {
    1: `Folyamatban van az adatok migrációja a Tenisz Partnertől a Sportya felé.
     Ez idő alatt játékosprofilod egy része nem lesz elérhető.
     Köszönjük a türelmedet.`,
  },

  players: "Játékosok",
  teams: 'Csapatok',
  areYouSure: 'Biztos vagy ebben?',
  setLevel: 'Szint kiválasztása',

  allLocations: 'Minden helyszín',
  allCategories: 'Minden kategória',
  allMonths: 'Minden hónap',
  allYears: 'Minden év',
  allLevels: 'Minden szint',

  onlyfemale: 'Csak nők',
  onlymale: 'Csak férfiak',
  onlymixt: 'Csak vegyes',

  right: 'Jobb',
  left: 'Bal',
  searchPlayer: 'Játékos keresése',
  'Romania-All': 'Románia (Mind)',
  'Bulgaria-All': 'Bulgária (Mind)',
  'Moldova-All': 'Moldova (Mind)',
  officialBall: 'Hivatalos labda',
  eventPrizes: 'Események díjai',
  eventPrizesDescription: 'A klasszikus tornák  (Ezüst, Arany, Platina, Speciális torna) standard díjai minden versenyszámban egy éremből és egy oklevélből állnak. Az esetleges további díjakat ebben a részben frissítjük. \n\n Helyi liga esetén kérjük, küldj üzenetet a contact@sportya.net címre, hogy elkészíthessük az 1. helyért járó digitális okleveledet.',
  gameLevel: 'Játékszint',
  uploadPlaceholder: 'Húzd ide a fájlokat, vagy kattints a feltöltéshez.',
  download: "Letöltés",
  viewPDF: "Tekintsd meg a főtáblát PDF-ben",
  clearResults: 'Eredmények törlése',
  onlyLatin: `Kérjük, csak a latin ábécé betűit használd. 
  Ez a korlátozás azért szükséges, hogy a többi játékos könnyebben megtalálhasson a Sportya-ban
  (kivéve: páros csapat regisztrálása egy eseményre).`,

  PREMIUM_MEMBERSHIP_UPGRADE: "C'mon! Prémium {{period}} fiókja sikeresen aktiválva lett.",
  PARTNER_USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'A regisztrációt nem lehet feldolgozni. A Sportya versenyeken a játékosoknak legalább 15 évesnek kell lenniük.',
  USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'A regisztrációt nem lehet feldolgozni. A Sportya versenyeken a játékosoknak legalább 15 évesnek kell lenniük.',
  banned: `átékosfiókod kitiltva van a következőre: <0>{{time}}</0>. Új versenyekre nem tud regisztrálni eddig: <1>{{until}}.</1>
Ha további részletekre van szüksége, forduljon a Sportya Teamhez - <2>contact@sportya.net</2>`,
  notAvailable: 'Nem elérhető',
  weeks: '{{time}} hétig',
  singleWeek: '1 hét',
  free: 'Ingyenes',
  custom: 'Egyedi',
  bahrain: 'Bahrain',
  armenia: 'Örményország',
  czechRepublic: 'Csehország',
  city: 'Város',
  countriesByKey: {
    ro: 'Románia',
    md: 'Moldova',
    bg: 'Bulgária',
    cz: 'Csehország',
    am: 'Örményország',
    hu: 'Magyarország',
    bh: 'Bahrain',
  },
  soonToBeAnnounced: 'Hamarosan bejelentésre kerül',
  'Hungary-All': 'Magyarország (Minden)',
  'Bahrain-All': 'Bahrain (Minden)',
  'Armenia-All': 'Örményország (Minden)',
  'Czechia-All': 'Csehország (Minden)',
  noResultsFound: 'Nincs találat',
  typeAtLeast3Chars: 'Írjon be legalább 3 karaktert...',
  allActivities: 'Minden tevékenység',
  eventSponsors: 'Rendezvény szponzorok',
  sponsors: 'Szponzorok',
  coordinators: 'Koordinátorok',
  officialStore: 'Hivatalos bolt',
  officialSponsors: 'Hivatalos szponzorok',
  proximityRadius: 'Közelség',
  currentLocation: 'Saját hely',
  calendar: 'Naptár',
  isLoading: 'Betöltés alatt van...',
  radius: 'Sugár',
  page_not_found: 'Az oldal nem található.',
  go_back: 'Menjen vissza',
  show: 'Előadás',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversation was successfully initiated.',
  COULD_NOT_INITIATE_CHAT: 'Conversation could not be initiated. Try again later.',
  cancelPopup: {
    title: 'Biztos, hogy elhagyja ezt az oldalt?',
    stay: 'Maradok az oldalon',
    quit: 'Elhagyom az oldalt',
  },
  COULD_NOT_RATE_PLAYER: 'Az értékelés jelenleg nem menthető. Kérlek, próbáld újra később.',
  SUCCESSFULLY_RATED_PLAYER: 'Sikeresen elmentette a játékos értékelését.',
  SUCCESSFULLY_DELETED_RATING: 'A minősítést sikeresen visszavonták.',
  PaidParking: "Fizetős parkolás",
  FreeParking: "Ingyenes parkolás",
  EquipmentRental: "Felszerelés kölcsönzés",
  Store: "Bolt",
  Lockers: "Szekrények",
  Showers: "Zuhanyzók",
  Restaurant: "Étterem",
  Cafeteria: "KÁVÉZÓ",
  Bar: "Bár",
  VendingMachine: "Automata",
  SpecialAccess: "Speciális hozzáférés",
  KidsPlayground: "Gyerek játszótér",
  referralCode: "Meghívó kód (nem kötelező)",
  INVALID_REFERRAL_CODE: 'Érvénytelen meghívókód',
  REFERRAL_CODE_APPLIED_SUCCESSFULLY: 'A meghívó kód alkalmazása sikeresen megtörtént!',
  date: "Dátum",
  time: "Óra",
  no: "Nem",
  yes: "Igen",
  FAILED_TO_BOOK_COURT: "A teniszpályát nem lehetett ütemezni! Próbáld újra!",
  BOOKING_COULD_NOT_BE_CREATED: "A teniszpályát nem lehetett ütemezni! Próbáld újra!",
  COULD_NOT_CALCULATE_BOOKING_PRICE: "A teniszpályát nem lehetett ütemezni! Próbáld újra!",
  COURT_NO_LONGER_AVAILABLE: "Ez a föld már nem elérhető! Próbáljon másikat foglalni.",
  START_DATE_OUTSIDE_MAX_BOOKING_INTERVAL: "A teniszpályát nem lehetett ütemezni! Próbáld újra!",
  BOOKING_CONFIRMED_SUCCESSFULLY: "Gratulálunk! A kiválasztott teniszpályát sikeresen lefoglaltuk!",
  COURT_IS_NOT_AVAILABLE: "Ez a föld már nem elérhető! Próbáljon másikat foglalni.",
  CLUB_BOOKING_DISABLED: "Ezt a pályát a klub deaktiválta. Próbáljon másikat foglalni.",
  FAILED_TO_CONFIRM_BOOKING: "A foglalást nem sikerült megerősíteni. Kérjük, próbálja újra, vagy lépjen kapcsolatba velünk.",
  ALREADY_WITHDRAWN: "Ön már visszalépett a versenytől.",
  RESOURCE_NOT_FOUND: "Route could not be found.",
  BOOKING_CANCELED_SUCCESSFULLY: "A foglalás sikeresen törölve.",
  FAILED_TO_CANCEL_BOOKING: "A foglalást nem lehetett lemondani.",
  BOOKING_ALREADY_CANCELED: "A foglalást már törölték.",
  INVALID_FRIENDLY_MATCH_SCORE_FORMAT: 'Érvénytelen pontszám a kiválasztott meccsformátum alapján!',
  INVOICE_UNAVAILABLE: 'A számla nem elérhető.',
  bookNow: "Book Now",
  searchAndBookCourts: "<0>BIG NEWS!</0> You can now SEARCH and BOOK a court in Sportya Partner Clubs",
  sports: {
    all: "All Sports",
    tennis: "Tennis",
    padel: "Padel",
  }
};
