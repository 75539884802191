import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from "lodash";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ExtendedContainer, SeeAll } from './styles';

import { fetchSummary } from '../../../FriendlyMatches/reducers';
import {
  Button, H1, H3, H4, Paragraph,
} from '../../../../components';
import LoadImage from '../../../../components/common/LoadImage';
import LinkWrapper from '../../../../components/Link';
import { getLocalDate } from '../../../FriendlyMatches/helpers';

const FriendlyMatches = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.friendlyMatches?.summary);
  const { accountInfo } = useSelector(state => state.session);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchSummary());
    }
  }, [status, dispatch]);

  const sportsByData = Object.keys(data);

  return (
    <ExtendedContainer fullwidth>
      <H3>
        <Trans ns="dashboard" i18nKey="friendlyMatches">Friendly Matches</Trans>

        {(data?.totalMatches > 0 || data?.totalRequests > 0) && (
          <SeeAll to="/friendly-matches">
            <Trans ns="friendlyMatches" i18nKey="goToFriendlyMatches">Go to Friendly Matches</Trans> {'>'}
          </SeeAll>
        )}
      </H3>

      {sportsByData.map((sport) => (
        <div key={sport} className="mt20">
          <Paragraph large bold className="text-capitalize mb10">{sport}</Paragraph>
          {(data[sport]?.totalMatches > 0 || data[sport]?.totalRequests > 0) && (
            <section className="friendly-matches">
              {data[sport]?.latestRequest && (
                <LinkWrapper to="/friendly-matches/requests" className="friendly-matches__box">
                  <div className="total text-center">
                    <H1>{data[sport]?.totalRequests}</H1>
                    <Paragraph medium bold>
                      <Trans ns="friendlyMatches" i18nKey="requestsArrow">Requests</Trans>
                      <img className="ml10" src={LoadImage('right-arrow.svg')} alt="" />
                    </Paragraph>
                  </div>

                  <Paragraph smaller className="mb10">
                    <Trans ns="friendlyMatches" i18nKey="latestRequest">Latest Request</Trans>
                  </Paragraph>

                  <div className="match-info">
                    <img
                      src={LoadImage(`friendly-matches/matches/${data[sport]?.latestRequest?.gameType}-match.svg`)}
                      alt=""
                    />
                    <div>
                      <Paragraph bold className="match-type">
                        <Trans ns="friendlyMatches" i18nKey={`${data[sport]?.latestRequest?.gameType}Match`}>
                          {data[sport]?.latestRequest?.gameType}
                          {' '}
                          Match
                        </Trans>
                        {data[sport]?.latestRequest?.ranked && (
                          <span className="ranked">
                            <img src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="" />
                          </span>
                        )}
                        {data[sport]?.latestRequest?.player?.id === accountInfo?.id && (
                          <span className="your-match">
                            <Trans ns="friendlyMatches" i18nKey="yourMatch">Your Match</Trans>
                          </span>
                        )}
                      </Paragraph>
                      <Paragraph className={`location ${!data[sport]?.nextMatch ? 'location__fullwidth' : ''}`}>
                        {data[sport]?.latestRequest?.pastMatch
                          ? dateFormat(getLocalDate(data[sport]?.latestRequest?.startDate), 'dd mmmm (ddd)')
                          : dateFormat(getLocalDate(data[sport]?.latestRequest?.startDate), 'dd mmmm (ddd), HH:MM')}
                        {' '}
                        -
                        {data[sport]?.latestRequest?.location?.name}
                      </Paragraph>
                    </div>
                  </div>
                </LinkWrapper>
              )}

              {data[sport]?.nextMatch && (
                <LinkWrapper to="/friendly-matches/available" className="friendly-matches__box">
                  <div className="total text-center">
                    <H1>{data[sport]?.totalMatches}</H1>
                    <Paragraph medium bold>
                      <Trans ns="friendlyMatches" i18nKey="total">Total</Trans>
                      <img className="ml10" src={LoadImage('right-arrow.svg')} alt="" />
                    </Paragraph>
                  </div>

                  <Paragraph smaller className="mb10">
                    <Trans ns="friendlyMatches" i18nKey="nextMatch">Next Match</Trans>
                  </Paragraph>

                  <div className="match-info">
                    <img
                      src={LoadImage(`friendly-matches/matches/${data[sport]?.nextMatch?.gameType}-match.svg`)}
                      alt=""
                    />
                    <div>
                      <Paragraph bold className="match-type">
                        <Trans ns="friendlyMatches" i18nKey={`${data[sport]?.nextMatch?.gameType}Match`}>
                          {data[sport]?.nextMatch?.gameType}
                          {' '}
                          Match
                        </Trans>
                        {data[sport]?.nextMatch?.ranked && (
                          <span className="ranked">
                            <img src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="" />
                          </span>
                        )}
                        {data[sport]?.nextMatch?.player?.id === accountInfo?.id && (
                          <span className="your-match">
                            <Trans ns="friendlyMatches" i18nKey="yourMatch">Your Match</Trans>
                          </span>
                        )}
                      </Paragraph>
                      <Paragraph className={`location ${!data[sport]?.latestRequest ? 'location__fullwidth' : ''}`}>
                        {data[sport]?.nextMatch?.pastMatch
                          ? dateFormat(getLocalDate(data[sport]?.nextMatch?.startDate), 'dd mmmm (ddd)')
                          : dateFormat(getLocalDate(data[sport]?.nextMatch?.startDate), 'dd mmmm (ddd), HH:MM')}
                        {' '}
                        -
                        {data?.nextMatch?.location?.name}
                      </Paragraph>
                    </div>
                  </div>
                </LinkWrapper>
              )}
            </section>
          )}
        </div>
      ))}

      {isEmpty(sportsByData) && (
        <div className="no-friendly-matches">
          <H4>
            <Trans ns="friendlyMatches" i18nKey="noRequestsOrMatchesDescription">
              Invite your friends or other players to a new match, or add your already played match to Sportya
            </Trans>
          </H4>
          <div className="buttons">
            <Button outline>
              <LinkWrapper to="/friendly-matches">
                <img
                  src={LoadImage('friendly-matches/match-form/tennis-ball-icon.svg')}
                  alt="Invite to Match"
                  className="mr10"
                />
                <Trans ns="friendlyMatches" i18nKey="goToFriendlyMatches">
                  Go to Friendly Matches
                </Trans>
              </LinkWrapper>
            </Button>

            <LinkWrapper to="/friendly-matches/create/played" className="add-played-match">
              <img
                src={LoadImage('friendly-matches/start-match/button-circle-plus-white.svg')}
                alt="Invite to Match"
                className="mr10"
              />
              <Trans ns="friendlyMatches" i18nKey="addPlayedMatch">
                Add Played Match
              </Trans>
            </LinkWrapper>
          </div>
        </div>
      )}
    </ExtendedContainer>
  );
};

export default FriendlyMatches;
