import styled from '@emotion/styled';
import { Paragraph as P } from '../../../components';
import ComponentContainer from '../../../components/Container';

export const Container = styled(ComponentContainer)({
  margin: '0 auto 80px auto',

  h1: {
    marginTop: 20,
    marginBottom: 40,
  },
});

export const TabsContainer = styled.div({
  display: 'flex',
  height: '50px',
  width: '100%',
  margin: '0 0 40px 0',
  '@media (max-width: 910px)': {
    flexDirection: 'column',
    height: 'unset',
  },
});

export const TabContainer = styled.div(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '238px',
  cursor: 'pointer',
  borderBottom: '3px solid #EAF6F6',

  '@media (max-width: 910px)': {
    width: '100%',
    height: '50px',
  },

  '&:hover': {
    borderBottom: '3px solid #10242C',
    '> p': {
      color: '#10242C',
    },
  },

  ...active && {
    borderBottom: '3px solid #10242C',
    '> p': {
      color: '#10242C',
    },
  },

  '&:not(:last-of-type)': {
    margin: '0 11px 0 0',
  },
}));

export const Tab = styled(P)({
  color: '#78ADAB',
  '&:hover': {
    color: '#10242C',
  },
});

export const TabContent = styled.div({
  minHeight: '350px',
});

export const BookingInfoContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid ${ ({ theme }) => theme.colors?.lightBlue };
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(16, 36, 44, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  ${props => props.theme.functions?.mediaQueryMax('650px', `
    flex-direction: column;
  `)}
  
  ${props => props.theme.functions?.mediaQueryMin('1002px', `
    justify-content: space-between;
    max-height: 150px;
  `)}

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: ${ ({ theme }) => theme.colors?.lightBlue };
    border-radius: 4px;
    margin-right: 30px;
    min-width: 90px;
    
    ${props => props.theme.functions?.mediaQueryMax('650px', `
      width: 100%;
      margin-right: unset;
    `)}

    > * {
      line-height: 1;
    }
  }

  a {
    text-decoration: none;
  }
  
  .friendly-match {
    flex: none;
    list-style: none;
    padding: 0 50px;

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    ${props => props.theme.functions?.mediaQueryMax('991px', `
      padding: 25px;
      padding-top: 0;
    `)}
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 130px;

    > a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
    }
    
    .booking-id {
      opacity: 0.5;
      margin-left: 10px;
    }

    ul {
      padding-left: 0;
  
      li {
        display: inline-block;
        margin-right: 15px;
        text-transform: capitalize;
        
        &:after {
          content: "•";
          position: absolute;
          padding: 0 5px;
        }
        
        &:last-child:after {
          display: none;
        }
      }
      
      &.event-details li {
        font-size: ${ ({ theme }) => theme?.functions?.toEm(16) };
      }
    }
    
    .booking-status {
      text-transform: uppercase;
      
      &__canceled {
        color: ${ ({ theme }) => theme?.colors.darkRed };
      }

      &__pending {
        color: ${ ({ theme }) => theme?.colors.yellowDarker };
      }
    }


    ${props => props.theme.functions?.mediaQueryMax('650px', `
      text-align: center;
      
      > a {
        justify-content: center;
      }
    `)}
    
  }
  
  .go-to-booking {
    a {
      padding: 20px;
    }
  }
`;
