export default {
  seoTitle: 'New Features Coming Soon | Sportya',

  item1: {
    title: 'Limba Bulgară',
    subtitle: '(Traducere integrală)',
    text: 'Ne străduim să pregătim varianta în limba bulgară a website-ului. Știm cât de critic este acest lucru. Ne concentrăm și ne dorim să lansăm opțiunea de traducere integrală cât mai rapid.',
  },

  item2: {
    title: 'Mesagerie pentru Jucători',
    text: 'Am decis să amânăm lansarea noului sistem de mesagerie, deoarece intenționăm să-i extindem inteconectivitatea cu alte servicii Sportya. Acesta va fi esențial pentru relansarea meciurilor amicale, împreună cu noul sistem de gestionare a conexiunilor tale, în Sportya.',
  },

  item3: {
    title: 'Meciuri Amicale',
    subtitle: '(Relansare)',
    text: 'Nu este o caracteristică complet nouă. Cu siguranță, jucătorii veterani o știu prea bine. Actualizările care se dezvoltă vă vor permite să găsiți un partener pentru un meci competitiv mai rapid și cu un grad mai ridicat de relevanță în ceea ce privește competitivitatea, cât și experiența jocului în sine.',
  },

  item4: {
    title: 'Lista de Prieteni',
    text: 'Va fi noul mod de a-ți dezvolta rețeaua socială, de a interacționa și de a păstra legătura cu prietenii tăi, pe tot parcursul anului. Așa cum am menționat mai sus, integrările suplimentare cu sistemul de mesagerie și de meciuri amicale vor aduce un nou nivel de experiență care îți vor permite să te bucuri de compania altora și de jocul de tenis.',
  },

  item5: {
    title: 'Blog pentru Știri și Actualizări',
    text: 'În Sportya, credem că ar trebui să vă alăturați conversației. Ca atare, secțiunea noastră pentru Știri și Actualizări va fi relansată ca Blog. Planificăm să vă ținem mai aproape de ceea ce se întâmplă în Sportya și să vă oferim mijloacele de a contribui la viitorul său.',
  },

  item6: {
    title: 'Gasește-ți un Partener de Echipă',
    subtitle: '(pentru Dublu)',
    text: 'Știm că este dificil să găsești un partener de echipă pentru meciurile de dublu. Așadar, introducem o funcționalitate frecvent solicitată: posibilitatea de a găsi alți jucători interesați să se alăture unei echipe, pentru un anumit eveniment. În curând, la un turneu din apropierea ta.',
  },

  item7: {
    title: 'Sistem de Rezervări Online',
    text: 'Reducem distanța dintre tine și următorul tău meci. Lansăm sistemul de Rezervări Online Sportya și totuși, mult mai mult decât simpla rezervare a unui teren de tenis. Te poți aștepta să găsești o varietate de terenuri aproape de tine, dar și integrări strânse cu alte servicii Sportya. Rezultatul final - vei juca un meci mai repede ca oricând, indiferent dacă este un meci amical sau un meci competitiv.',
  },

  item8: {
    title: 'Scoruri Live',
    text: 'Pentru prima iterație a sistemului, vom relansa funcționalitatea atât pentru organizatori, cât și pentru jucători, respectiv posibilitatea de a introduce rezultatele meciurilor, mai rapid și mai ușor. Avem, de asemenea, o surpriză planificată în legătură cu această funcționalitate.',
  },

  item9: {
    title: 'Managementul Evenimentelor',
    subtitle: '(Cluburi și organizatori)',
    text: 'Vom începe un proces de integrare a serviciilor Sportya cu toate cluburile organizatoare, conectând serviciile noastre la sistemele lor operaționale. Acest lucru ne va permite să dezvoltăm noi servicii și să le permitem cluburilor să interacționeze cu jucătorii la un nivel mai profund, oferind servicii mai rapide și mai precise și o experiență generală mai bună pentru membrii lor.',
  },

  comingSoon: 'În Curând',
  worthTheWait: 'Merită Așteptarea',

  stayTuned: 'Rămâneți la curent pentru a afla ce urmează să lansăm!',
};
