export default {
  forgotPassword: 'Am uitat parola',
  subTitle: 'Introdu e-mailul tău mai jos',
  reset: 'Resetare',
  placeholders: {
    email: 'Adresă de e-mail',
    password: 'Parola',
    passwordConfirmation: 'Confirm parola',
  },
  passwordResetSuccessfully: 'Te rugăm să îți verifici e-mail-ul pentru instrucțiuni de resetare a parolei.',
  setPassword: 'Setează parola',
  setNewPassword: 'Adaugă noua parolă în câmpul de mai jos.',
  invalidResetPasswordLink: 'Link-ul de resetare a parolei este expirat sau invalid.',
};
