export default {
  chats: 'Conversații',
  startChat: 'Începe o Conversație',
  findPlayersAndStartConversation: 'Găsește jucători și începe o conversație',
  seeFriendsList: 'Vezi Lista de Prieteni',
  disconnected: 'Deconectat. Se încearcă reconectarea...',
  isTyping: '{{ user }} tastează...',
  online: 'Online',
  offline: 'Offline',
  viewProfile: 'Vezi Profil',
  startGroupChat: 'Începe o Conversație de Grup cu {{ user }}',
  muteConversation: 'Oprește Notificările',
  unmuteConversation: 'Reactivează Notificările',
  leaveConversation: 'Părăsește Conversația',
  deleteConversation: 'Șterge Conversația',
  allMessagesLoaded: 'Au fost încărcate toate mesajele.',
  messageDeleted: 'Acest mesaj a fost șters.',
  system: 'Sistem',
  deleteMessage: 'Șterge Mesaj',
  typeMessage: 'Scrie un mesaj...',
  groupChat: 'Conversație de Grup',
  groupMembers: 'Participanți',
  noOfParticipants: '{{ participants }} participanți',
  groupName: 'Tu, {{ groupNamesList }}',
  addParticipants: 'Adaugă Participanți',
  seeGroupMembers: 'Vezi Participanți',
  muteGroup: 'Oprește Notificările',
  unmuteGroup: 'Reactivează Notificările',
  leaveGroup: 'Părăsește Conversația',
  deleteGroup: 'Șterge Conversația',
  cancel: 'Anulează',
  createGroup: 'Creează',
  yourFriends: 'Prietenii tăi',
  add: 'Adaugă',
  you: 'Tu',
  leave: 'Părăsește',
  delete: 'Șterge',
  playerBlocked: 'Acest jucător este blocat',
  left: 'Left',
  openOrStartChat: 'Deschide una dintre conversațiile existente <br/> sau inițiază o conversație nouă.',
  leaveConfirmationGroup: 'Sigur vrei să părăsești această <br/> conversație de grup?',
  deleteConfirmationGroup: 'Sigur vrei să ștergi această <br/> conversație de grup?',
  deleteConfirmationConversation: 'Sigur vrei să ștergi această conversație?',
  USER_HAS_LEFT_CONVERSATION: '<i><strong>{{ user }}</strong> a părăsit conversația.</i>',
  PLAYER_ADDED_TO_CONVERSATION: '<i><strong>{{ user }}</strong> a fost adăugat în conversație de către <strong>{{ addedBy }}</strong>.</i>',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversația a fost inițiată cu succes.',
  COULD_NOT_INITIATE_CHAT: 'Conversația nu a putut fi inițiată. Încearcă mai târziu.',
  SUCCESSFULLY_MUTED_CONVERSATION: 'Notificările pentru această conversație au fost oprite.',
  SUCCESSFULLY_UNMUTED_CONVERSATION: 'Notificările pentru această conversație au fost reactivate.',
  SUCCESSFULLY_LEFT_CONVERSATION: 'Ai părăsit conversația cu succes.',
  SUCCESSFULLY_DELETED_CONVERSATION: 'Conversația a fost ștearsă cu succes.',
  INTERNAL_ERROR: 'Sistemul a întâmpinat o eroare internă. Ajută-ne să îmbunătățim serviciul - te rugăm să ne spui ce s-a întâmplat la hq@sportya.net.',
  tryAgain: 'Încearcă din nou',
  failedToLoadConversations: 'Conversațiile nu au putut fi încărcate.',
  leftGroupChat: 'Nu mai poți trimite mesaje în aceasta conversație de grup deoarece nu mai ești participant.',
  unreadMessages: '{{ messages }} mesaje noi',
};
