import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import LoadImage from '../../../../../common/LoadImage';
import { P } from '../../../../../Collection';

export const Container = styled.div(props => ({
  display: 'flex',
  p: { color: '#45C5C0' },
  justifyContent: 'center',
  padding: '1px 10px',
  borderRadius: '30px',
  background: props.theme.colors.lightGrey,

  ...props.premium && {
    background: '#10242C',

    border: `2px solid ${props.theme.colors.yellow}`,
    p: { color: props.theme.colors.yellow },
  },
  img: {
    marginRight: '5px',
  },
}));

export const Membership = ({ membership }) => {
  const { t } = useTranslation('header');
  return (
    <Container premium={membership === 'premium'}>
      <img src={LoadImage(`header/${membership}.svg`)} alt="" />
      <P xSmall bold minWidth="max-content">{t(membership || 'free')}</P>
    </Container>
  );
};

export default Membership;
