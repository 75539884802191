import mixpanel from 'mixpanel-browser';
import React, { createContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Contain } from '../../../../../components/Collection';
// eslint-disable-next-line max-len
import BlockModal from '../../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/components/BlockModal';
import SmallProfilePicture from '../SmallProfilePicture';
import { UserIdSpan } from '../styles';
import { unblockPlayer } from '../../reducers';

export const BlockedContext = createContext();

const BlockedPlayerRow = ({ player, socketClientId }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [unblockId, setUnblockId] = useState(0);

  const contextValue = useMemo(() => ({
    user: player.blockedUser,
    socketClientId,
    isBlocked: true,
  }), [player]);

  const unblock = () => {
    mixpanel.track('Confirm [Unblock] in confirmation dialog');
    dispatch(unblockPlayer({ blockedUserId: unblockId, socketClientId }));
    setModal(!modal);
  };

  const modalHandler = (data) => {
    if (!modal) {
      mixpanel.track('Unblock Player from Blocked Players in Settings');
    }

    setUnblockId(data?.blockedUserId);
    setModal(true);
  };

  return (
    <>
      <Contain margin="0 0 20px 0" justify="space-between" align="center">
        <Contain>
          <SmallProfilePicture player={player.blockedUser} />
          <Contain margin="8px 0 0 10px">
            {' '}
            {`${player.blockedUser.firstName} ${player.blockedUser.lastName}`}
            {' '}
            <UserIdSpan>
              #
              {player.blockedUser.userId}
            </UserIdSpan>
          </Contain>
        </Contain>
        <Button onClick={() => { modalHandler(player); }} small outline className="unblockBtn">
          <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.unblock">Unblock</Trans>
        </Button>
      </Contain>
      <BlockedContext.Provider value={contextValue}>
        {modal && (
          <BlockModal
            modalHandler={() => setModal(!modal)}
            block={() => null}
            unblock={unblock}
            fullName={`${player?.blockedUser?.firstName} ${player?.blockedUser?.lastName}`}
            isUserBlocked
          />
        )}
      </BlockedContext.Provider>
    </>
  );
};

export default BlockedPlayerRow;
