import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import mixpanel from '../../../../mixpanel';

import {
  PlanStyle, InfoModal, BuyModal, StripeStyle,
} from './styles';

import { H2, H4, Paragraph } from '../../../../components';
import Perk from '../Perk';
import Button from '../../../../components/Form/Button';
import Modal from '../../../../components/Modal/Modal';
import LoadImage from '../../../../components/common/LoadImage';
import LinkWrapper from '../../../../components/Link';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import { authorizePayment } from '../../actions';
import BillingDataSelector from '../../../../components/BillingDataSelector';

const customStyles = {
  container: styles => ({
    ...styles,
    lineHeight: 1,
    cursor: 'pointer',
    marginBottom: 14,
  }),
  menu: styles => ({
    ...styles,
    zIndex: 2,
  }),
  control: styles => ({
    ...styles,
    boxShadow: 'none',
    minHeight: '18px',
    marginTop: 0,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0 8px 0 8px',
    border: '2px solid #10242C',
    height: '50px',
  }),
  singleValue: styles => ({
    ...styles,
    margin: '0',
    marginTop: '0px',
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.1s ease',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: 0,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  dropdownIndicator: styles => ({
    ...styles,
    color: '#10242C',
  }),
};

const Plan = (props) => {
  const {
    id,
    type,
    image,
    period,
    perks,
    timePeriod,
    info,
    currentPlan,
    extend,
    isFree,
    price,
    oldPrice,
    currency,
    bestValue,
    upgrade,
    accountInfo,
    isLoading,
    setIsLoading,
  } = props;

  const [modal, setModal] = useState(false);
  const [buyModal, setBuyModal] = useState(false);
  const [stripeModal, setStripeModal] = useState(false);
  const history = useHistory();

  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const { t: planT } = useTranslation('subscriptions');
  const { t } = useTranslation('events');
  const [billingAs, setBillingAs] = useState();

  const title = period ? `Premium ${period}` : commonT('free');
  const hasCurrency = !!accountInfo?.currency;
  const options = [
    { value: 'virtualWallet', label: t('join.payment.virtualWallet') },
    { value: 'creditCard', label: t('join.payment.creditCard') },
  ];

  const formSchema = Yup.object().shape({
    planId: Yup.string().required(commonT('required')),
    paymentType: Yup.string().required(commonT('required')),
    amount: Yup.number().required(commonT('required')).when('paymentType', {
      is: 'virtualWallet',
      then: Yup.number().max(accountInfo?.walletBalance),
      otherwise: Yup.number(),
    }),
  });

  const modalHandler = () => {
    setModal(!modal);
  };

  const handleSubmit = async ({ amount, method, paymentType }, { setSubmitting }) => {
    setIsLoading(true);

    if (paymentType === 'creditCard') {
      setBuyModal(false);
      setStripeModal(true);
    }

    const payload = {
      productId: id,
      method,
      paymentType,
      products: [],
      billingAs,
    };

    const product = {
      id,
      name: commonT(`${type}AccountMembershipUpgrade`, { period }),
      price: Number(amount).toFixed(2),
      quantity: 1,
      type: method,
    };

    payload.products.push(product);

    await authorizePayment({
      payload,
      addToast,
      setSubmitting,
      history,
      t: commonT,
    });

    mixpanel.track('Initiate Payment', {
      sp_initiate_payment_method: paymentType === 'creditCard' ? 'credit card' : 'virtual wallet',
    });
  };

  const trackButtonClick = () => {
    mixpanel.track('Get Premium', {
      sp_get_premium_plan_type: `${type } ${ period}`,
      sp_get_premium_first_premium: !extend,
      sp_get_premium_is_logged_in: !!accountInfo,
    });
  };

  return (
    <PlanStyle upgrade={upgrade} type={type} currentPlan={currentPlan} extend={extend}>
      {bestValue && (
        <div className="bestValue">
          <Paragraph bold smaller>
            <Trans ns="subscriptions" i18nKey="planConfirmation.bestValue">Best Value</Trans>
          </Paragraph>
        </div>
      )}
      <div className="imgWrapp">
        {period ? <Paragraph bold small>{period}</Paragraph> : ''}
        <img src={image} alt="plan-icon" />
      </div>
      <H4>{title}</H4>
      {type === 'free' ? (
        <Paragraph small bold>
          <Trans ns="subscriptions" i18nKey="planConfirmation.noExpiration">No Expiration</Trans>
        </Paragraph>
      ) : (
        <div className="expirationDate">
          <Paragraph small bold>{planT('expirationDate', { value: period })}</Paragraph>
          {/* <Paragraph small>{timePeriod}</Paragraph> */}
        </div>
      )}
      <div className="perksWrapp">
        {perks.map((perk, idx) => <Perk planType={type} txt={perk.text} type={perk.type} key={idx} />)}
      </div>
      <div className="priceWrapp">
        {oldPrice && (
          <Paragraph bold>
            <s>
              {oldPrice} {currency}
            </s>
          </Paragraph>
        )}
        <H4>
          {price} {currency}
        </H4>
      </div>
      <div className="buttonWrapp">
        {currentPlan
          ? (
            <Paragraph bold medium className="planButton">
              <Trans ns="subscriptions" i18nKey="planConfirmation.currentPlan">Current Plan</Trans>
            </Paragraph>
          )
          : (isFree
            ? (
              <Paragraph bold medium className="planButton">
                <Trans ns="subscriptions" i18nKey="planConfirmation.included">Included</Trans>
              </Paragraph>
            )
            : (extend && accountInfo && !isFree
              ? (
                <div className="cursor-pointer" onClick={() => { setBuyModal(true); trackButtonClick(); }}>
                  <Paragraph bold medium type={type} className="planButton">
                    <Trans ns="subscriptions" i18nKey="planConfirmation.extend">Extend</Trans>
                  </Paragraph>
                </div>
              )
              : (accountInfo
                ? (
                  <div className="cursor-pointer" onClick={() => { setBuyModal(true); trackButtonClick(); }}>
                    <Paragraph medium bold type={type} className="planButton">
                      <Trans ns="subscriptions" i18nKey="planConfirmation.upgrade">Upgrade</Trans>
                    </Paragraph>
                  </div>
                )
                : (
                  <Paragraph medium bold className="planButton">
                    <LinkWrapper to="/login/plans" onClick={() => trackButtonClick()}>
                      <Trans ns="subscriptions" i18nKey="planConfirmation.upgrade">Upgrade</Trans>
                    </LinkWrapper>
                  </Paragraph>
                ))))}
      </div>

      {modal && (
        <Modal smallRadius wide type2 noborder nobackground hide={() => modalHandler()} isShowing>
          <InfoModal>
            <H2>{title}</H2>
            <Paragraph>{info}</Paragraph>
          </InfoModal>
        </Modal>
      )}

      {buyModal && (
        <Modal smallRadius type2 noborder small nobackground hide={() => setBuyModal(false)} isShowing>
          <BuyModal>
            <div className="modalHeader">
              <H2>
                Premium
                {period}
              </H2>
              <Paragraph className="confirmTitle">
                <Trans ns="subscriptions" i18nKey="planConfirmation.confirmFinalPayment" values={{ period }}>
                  Please confirm your final payment for
                  <strong>
                    Premium
                    {period}
                  </strong>
                </Trans>
              </Paragraph>

              <div className="modalInfo mb0">
                <div className="info">
                  <Paragraph small>
                    <Trans ns="subscriptions" i18nKey="planConfirmation.validity">Validity</Trans>
                  </Paragraph>
                  <Paragraph small>{timePeriod}</Paragraph>
                </div>
                <hr />
                <div className="info">
                  <Paragraph small>
                    <Trans ns="subscriptions" i18nKey="planConfirmation.holder">Holder</Trans>
                  </Paragraph>
                  <Paragraph small>
                    {accountInfo?.firstName} {accountInfo?.lastName}
                  </Paragraph>
                </div>
                <hr />
                <div className="info">
                  <Paragraph small>
                    <Trans ns="subscriptions" i18nKey="planConfirmation.totalOrder">Total Order</Trans>
                  </Paragraph>
                  <Paragraph small>
                    {price} {currency}
                  </Paragraph>
                </div>
                {accountInfo && (
                  <>
                    <hr />
                    <div className="billing-data">
                      <BillingDataSelector key={id} billingAs={billingAs} setBillingAs={setBillingAs} />
                    </div>
                  </>
                )}
              </div>

              <Formik
                initialValues={{
                  method: 'upgradeMembership',
                  paymentType: '',
                  amount: price ?? '',
                  planId: id ?? '',
                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values, setFieldValue, isSubmitting, isValid, dirty,
                }) => (
                  <Form>
                    <div className="selectWrapp">
                      <Select
                        placeholder={t('join.payment.selectMethod')}
                        name="paymentType"
                        onChange={({ value }) => setFieldValue('paymentType', value)}
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                      />

                      {values.paymentType === 'virtualWallet' && (
                        // eslint-disable-next-line max-len
                        <div className={`balanceWrapp ${values.amount > accountInfo?.walletBalance ? 'balanceWrapp__negative-amount' : ''}`}>
                          <Paragraph small bold>

                            {values.amount > accountInfo?.walletBalance ? (
                              <>
                                <div className="topup-popup">
                                  <LinkWrapper to="/my-account/wallet">
                                    <Trans ns="events" i18nKey="topUpNow">Top-Up Now</Trans>
                                  </LinkWrapper>
                                </div>
                                <Trans ns="events" i18nKey="join.payment.insufficientFunds">Insufficient Funds</Trans>
                              </>
                            ) : (
                              <Trans
                                ns="subscriptions"
                                i18nKey="planConfirmation.availableBalance"
                              >Available Balance
                              </Trans>
                            )}
                          </Paragraph>

                          <Paragraph small bold>
                            {accountInfo?.walletBalance.toFixed(2)} {accountInfo?.currency}
                          </Paragraph>
                        </div>
                      )}
                      <Button
                        fullwidth
                        large
                        type="submit"
                        disabled={(isSubmitting || !(isValid && dirty)) || isLoading || !hasCurrency}
                        className="mt25"
                      >
                        <Trans ns="subscriptions" i18nKey="planConfirmation.confirmPay">Confirm & Pay</Trans>
                      </Button>

                      {values.paymentType === 'creditCard' && (
                        <div className="stripe-logo">
                          <img src={LoadImage('powered-by-stripe.svg')} alt="Stripe" />
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </BuyModal>
        </Modal>
      )}

      {stripeModal && (
        <Modal
          smallRadius
          hideClose
          type2
          noborder
          small
          nobackground
          hide={() => setStripeModal(false)}
          isShowing
        >
          <StripeStyle>
            <img src={LoadImage('stripe-logo.svg')} alt="" className="mb20" />
            <Paragraph>
              <Trans ns="subscriptions" i18nKey="planConfirmation.redirecting">Redirecting to Stripe...</Trans>
            </Paragraph>
            <LoadingSpinner className="loader" />
          </StripeStyle>
        </Modal>
      )}
    </PlanStyle>
  );
};

export default Plan;
