import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import { Container } from './styles';

const GameFormatInfo = () => {
  const { t } = useTranslation('rules');

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle3')}</title>
      </Helmet>
      <div className="row mb60 mt70">
        <div className="col-sm-12">
          <H1 className="mb30">
            <Trans ns="rules" i18nKey="gameFormatInfo.mainTitle">HOW TO PLAY IN GROUPS OF 3</Trans>
          </H1>
          <P large className="mb40">
            <Trans ns="rules" i18nKey="gameFormatInfo.mainDescription">
              One of the most sought after gameplay formats is the <strong>Group-of-3 System</strong>, with two
              matches in classic format, scheduled for each player.
              The format provides a richer experience for participants, and the technical implementation standard,
              adapted to Sportya events, is described below:
              <strong>Group rankings / Separation in a Group</strong>
              • <strong>In case of a tie</strong>, in order to establish the ranking of a group, the separation
              between players is made based on
              the result of the direct match. Specifically, if a group is composed of three players, each having
              the same number of victories, the differentiation between players is made taking into account, in order:
              the set score, the games score, the highest number of sets / games won. If one of the above criteria
              results in a better or weaker player than the other two, a direct victory will be considered between
              the two remaining players.
              • <strong>In case of a perfect tie</strong>, the order in the group will be determined by drawing lots.

              <strong>Group qualification</strong>
              Within the Sportya events, 2 qualification alternatives from a Group-of-3 can be applied:
              • <strong>Only the first place qualifies.</strong>
              • <strong>The first two ranked players qualify.</strong>

              In the situation where the format of an event includes the rule of qualifying one or more of the
              2nd places from different groups, the distinction between the 2nd places is made according to:
              the victory-defeat ratio, the set score, the games score, number of sets won and number of games won.
              In case of a perfect tie, the 2nd place in the group of the best seeded player will qualify.

              If there are only two players in a group (according to the number of entries or following a withdrawal
              from both matches), in order to make the comparison between the 2nd place in a 2-player group and a
              3-player group, the results of the matches with the last ranked player are eliminated and the same
              criteria are considered (practically, only the results between the 2nd place against the
              1st place in each group will count).

              <strong>Withdrawals</strong>
              If a player shows up for a match and no longer plays the second one (for whatever reason),
              the score in the second match will be 6/0 6/0 for the group ranking.
              <strong>
                In the case of the super tiebreak, it will be considered a set won 1-0 (one game to zero).
              </strong>

              <strong>
                Adjustment of the participation fee / Partial return as a result of withdrawals from the group
              </strong>
              Within Sportya events, the following situations may arise as a result of withdrawals from a group:
              • <strong>The play of a single match in a group, caused by a withdrawal</strong>, will mean a decrease
              in the tournament fee to the value of a classic knockout tournament fee,
              unless both players in the group qualify, in which case the fee charged will not be adjusted.
              The refund will be requested as follows: from the organizer, for the Club Entry Fee,
              and by email (contact@sportya.net) to Sportya, for the Sportya Service Fee paid online.
              After entering the results, the money will be credited to the Sportya Virtual Wallet.
              • <strong>In the event of two withdrawals from a group</strong> , the third player is promoted directly
              to the next stage and the participation fee is adjusted to the level of a knockout tournament fee.

              <strong>Technical characteristics for the upper phase / Knockout draw. Scoring</strong>
              The knockout draw that follows the play in the groups will have predetermined positions
              or a draw will be announced for some of them. The scoring grids remain the usual ones, defined in
              the Sportya Rulebook, the points being awarded according to the number of players on
              the main draw, from the moment of the draw.
            </Trans>
          </P>
        </div>
      </div>
    </Container>
  );
};

export default GameFormatInfo;
