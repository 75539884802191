import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  post, put, remove, get,
} from '../../../../../../../../components/common/http';

export const fetchBlockedPlayers = createAsyncThunk('accounts/blockedPlayers', async () => {
  const { data: { data } } = await get('/blocked-players');
  return data;
});

export const unblockPlayer = createAsyncThunk('accounts/unblockPlayer', async (requestData) => {
  await put('/player/unblock', requestData);
  return requestData;
});

export const blockPlayer = createAsyncThunk('accounts/blockPlayer', async (requestData) => {
  await put('/player/block', requestData);
  return requestData;
});

export const addFriend = createAsyncThunk('accounts/addFriend', async (requestData) => {
  const { data: { data } } = await post('/friends/add', requestData);
  return data;
});

export const deleteFriend = createAsyncThunk('accounts/deleteFriend', async (requestData) => {
  const { data: { data } } = await remove('/friends/delete', requestData);
  return data;
});

export const acceptFriend = createAsyncThunk('accounts/acceptFriend', async (requestData) => {
  const { data: { data } } = await put('/friends/accept', requestData);
  return data;
});

const blockedPlayers = createSlice({
  name: 'blockedPlayers',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [blockPlayer.fulfilled]: (state, action) => {
      const playerToAdd = { blockedUser: { id: action.payload?.blockedUserId }, user: { id: {} } };
      state.data.push(playerToAdd);
    },
    [unblockPlayer.fulfilled]: (state, action) => {
      const { blockedUserId } = action?.payload || {};
      const newList = state.data?.filter((v) => v?.blockedUserId !== blockedUserId);
      state.data = newList;
    },
    [fetchBlockedPlayers.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchBlockedPlayers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.results;
    },
    [fetchBlockedPlayers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default {
  list: blockedPlayers.reducer,
};

// This code exports a series of async thunk functions that make API calls to manage blocked players,
// friend requests and the like, then exports a single list property that contains the state
// and reducer of the blocked players slice from the `createSlice` function of the `reduxjs/toolkit`.
// This can be use to update the state in the store.
