import styled from '@emotion/styled';

export const ModalContainer = styled.div(() => ({
  position: 'relative',
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",

  ".modal-header": {
    paddingBottom: "20px !important",
    borderBottom: "1px solid #EAF6F6",
  },

  ".modal-content": {
    minHeight: 300,
  },

  ".modal-footer": {
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    borderTop: "1px solid #EAF6F6",
  },

  label: {
    marginBottom: "0px !important",
  },
}));
