export default {
  thankYouForRegistration: 'Köszönjük a regisztrációt!',
  congratulations: 'Gratulálunk!',
  topUpSuccessfully: 'Sikeresen feltöltöttél {{ amount }} {{ currency }} összeget a Virtuális pénztárcádba.',
  undefinedMembershipSuccessfully: 'Sikeresen vásároltál egy új fiókot',
  premiumMembershipSuccessfully: 'Sikeresen vásároltál egy új Prémium {{period}} fiókot',
  addedTo: {
    mainList: 'Felvettek a Főlistára.',
    waitingList: 'Felvettek a Várólistára.',
    preRegistrationList: 'Felvettek a Rendezőlistára.',
  },
  level: 'Szint',
  walletStatus: 'kifizettél <1>{{ paid }} {{ currency }}</1> összeget a virtuális pénztárcádból. \n Fennmaradó egyenleg: <3>{{ walletBalance }} {{ currency }}</3>.',
  goToEvent: 'Visszalépés az Eseményhez',
  goToDashboard: 'Visszalépés a Műszerfalamra',
  goToVirtualWallet: 'Továbblépés a Virtuális pénztárcához',
  goToMyProfile: 'Továbblépés a Profilomhoz',
  failed: 'A fizetés feldolgozása sikertelen!',
  'Invalid signature': 'Ok: <1>Érvénytelen aláírás</1>',
  invalidSignature: 'Ok: <1>Érvénytelen aláírás</1>',
  paymentProcessing: 'A fizetést engedélyezték, jelenleg feldolgozás alatt áll.',
  playFriendlyMatch: 'Amíg vársz a verseny kezdetére, <strong>barátságos meccset</strong> játszhatsz barátaiddal vagy más játékosokkal a Sportya.',
  yourCourtIsBooked: 'A teniszpálya le van foglalva!’',
  goToMyBookings: 'Foglalásaim',
  cancellationWithRefundInfo: 'Ez a foglalás a kezdési időpont előtt legfeljebb <1>{{ hours }}h</1> lemondható a teljes visszatérítés ellenében.',
};
