import React, { useEffect, useState } from 'react';
import { isEmpty, pick } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { ModalContainer } from '../../../styles';

import SearchBar from '../../SearchBar';
import LoadImage from '../../../../../components/common/LoadImage';
import { Button, Paragraph } from '../../../../../components';
import Modal from '../../../../../components/Modal';
import { get } from '../../../../../components/common/http';
import { setFriends } from '../../../../../components/Friends/reducers';

import { addConversation, addParticipants } from '../../../actions';
import mixpanel from '../../../../../mixpanel';

const GroupChatModal = ({
  conversationId,
  groupChatModal,
  handleGroupChatModal,
  addParticipantsFlag,
  accountInfo,
  conversationInfo,
}) => {
  const { t } = useTranslation('conversations');
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { data: { results: friendsList } } = useSelector(state => state.friends);

  const [groupMembers, setGroupMembers] = useState([]);
  const [player, setPlayer] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (friendsList === undefined) {
      get(`/friends?status=accepted&limit=200&sortBy=firstName:asc`).then(({ data: { data } }) => {
        dispatch(setFriends(data));
      });
    }
  }, [friendsList]);

  useEffect(() => {
    if (player) {
      appendUserToGroupChat(player);
    }
  }, [player]);

  useEffect(() => {
    // hide modal after members were added into conversation
    if (
      !isEmpty(conversationInfo)
      && addParticipantsFlag
      && groupChatModal
      && !isEmpty((conversationInfo?.members || [])
        .filter(({ userId }) => !!groupMembers.filter((member) => member.userId === userId).shift()))
    ) {
      onRequestClose();
    }
  }, [conversationInfo]);

  const onRequestClose = () => {
    setGroupMembers([]);
    handleGroupChatModal('close');
  };

  const removeMember = (userId) => {
    const members = groupMembers.filter((member) => member.userId !== userId);
    setGroupMembers(members);
  };

  const findMember = (userId) => (!!groupMembers.filter((member) => member.userId === userId).shift()
      || !!conversationInfo?.members.filter((member) => member.userId === userId).shift()
  );

  const appendUserToGroupChat = (user) => {
    const member = pick(user, ['id', 'userId', 'firstName', 'lastName', 'profilePicture']);
    const memberAlreadyExist = groupMembers.filter(({ userId }) => userId === user?.userId).shift();

    if (isEmpty(memberAlreadyExist)) {
      setGroupMembers([...groupMembers, member]);
    }
  };

  const handleAfterOpen = () => {
    if (!addParticipantsFlag) {
      appendUserToGroupChat(conversationInfo?.userInfo);
    }
  };

  const handleSubmitAddParticipants = async () => {
    setSubmitting(true);

    const payload = {
      participants: groupMembers,
    };

    await addParticipants({
      conversationId,
      payload,
      addToast,
      t,
      setSubmitting,
    });

    setSubmitting(false);
  };

  const handleSubmitGroupChat = async () => {
    setSubmitting(true);

    const payload = {
      members: [...groupMembers],
      type: 'group',
    };

    await addConversation({
      payload,
      addToast,
      t,
      setSubmitting,
    });

    onRequestClose();

    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={groupChatModal}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      mobileFullDisplay
    >
      <ModalContainer>
        <div className="modal-header">
          {t(addParticipantsFlag ? 'addParticipants' : 'groupChat')}
        </div>

        <div className="modal-content">
          <SearchBar setPlayer={setPlayer} className="p0" userId={accountInfo?.userId} />
          <ul className="group-members">
            {groupMembers.map((member, key) => (
              <li key={key}>
                <figure>
                  {((
                    !addParticipantsFlag
                    && isEmpty(conversationInfo?.members.filter(({ userId }) => userId === member?.userId).shift())
                  ) || addParticipantsFlag) && (
                    <div
                      className="remove"
                      onClick={() => {
                        removeMember(member?.userId);
                        mixpanel.track('Remove Participant from Group Chat (Deselect at Creation)');
                      }}
                    >
                      <img src={LoadImage('close-icon.svg')} alt="Remove" />
                    </div>
                  )}

                  {member?.profilePicture
                    ? (
                      <div className="image">
                        <img
                          src={LoadImage(`accounts/${member?.userId}/${member?.profilePicture}`, true)}
                          alt={`${member?.firstName} ${member?.lastName}`}
                          width={100}
                        />
                      </div>
                    )
                    : (
                      <div className="initials">
                        <span>{member.firstName?.charAt(0)} {member.lastName?.charAt(0)}</span>
                      </div>
                    )}
                </figure>
                <p>{member.firstName} {member.lastName}</p>
              </li>
            ))}
          </ul>

          {!isEmpty((friendsList || []).filter(({ userId }) => !findMember(userId))) && (
            <>
              <Paragraph>
                <Trans ns="conversations" i18nKey="yourFriends">Your Friends</Trans>
              </Paragraph>

              <ul className="friends-list">
                {(friendsList || [])
                  .filter(({ userId }) => !findMember(userId))
                  .map(({ user: member }, key) => (
                    <li key={key}>
                      <figure className="smaller mr10">
                        {member?.profilePicture
                          ? (
                            <div className="image">
                              <img
                                src={LoadImage(`accounts/${member?.userId}/${member?.profilePicture}`, true)}
                                alt={`${member?.firstName} ${member?.lastName}`}
                                width={100}
                              />
                            </div>
                          )
                          : (
                            <div className="initials">
                              <span>{member.firstName?.charAt(0)} {member.lastName?.charAt(0)}</span>
                            </div>
                          )}
                      </figure>
                      <p>{member?.firstName} {member?.lastName} <span>#{member?.userId}</span></p>
                      <div className="add">
                        <Button
                          outline
                          smaller
                          onClick={() => {
                            appendUserToGroupChat(member);
                            mixpanel.track('Add Friend to Group Chat (Select at Creation)');
                          }}
                        >
                          <span><Trans ns="conversations" i18nKey="add">Add</Trans></span>
                        </Button>
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>

        <div className="modal-footer">
          <Button
            outline
            onClick={() => {
              onRequestClose();
              mixpanel.track('Cancel Group Chat (Creation)');
            }}
            className="mr10"
          >
            <span><Trans ns="conversations" i18nKey="cancel">Cancel</Trans></span>
          </Button>
          {addParticipantsFlag && (
            <Button disabled={submitting || !groupMembers.length} onClick={handleSubmitAddParticipants}>
              <span><Trans ns="conversations" i18nKey="addParticipants">Add Participants</Trans></span>
            </Button>
          )}

          {!addParticipantsFlag && (
            <Button
              disabled={submitting || groupMembers.length < 2}
              onClick={() => {
                handleSubmitGroupChat();
                mixpanel.track('Create Group Chat (Confirmation button)');
              }}
            >
              <span><Trans ns="conversations" i18nKey="createGroup">Create Group</Trans></span>
            </Button>
          )}
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default GroupChatModal;
