import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

import { InfoContainer, InfoIcon, Popup } from '../../../styles';
import { Container, PlayersContainer } from '../WantToPlay/styles';
import { CategoryTitle } from '../../styles';

import LoadImage from '../../../../../components/common/LoadImage';
import Player from '../Player';
import ContentLoader from '../../../../../components/ContentLoader';

import { fetchOtherTopPicks } from '../../../reducers';

const TopPicks = ({ masterSport }) => {
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state?.session);
  const { data, status, sportType } = useSelector(state => state?.friendlyMatches?.otherTopPicks);

  useEffect(() => {
    if (
      (!isEmpty(accountInfo) && status === 'idle')
      || (sportType && sportType !== masterSport && status === 'succeeded')
    ) {
      dispatch(fetchOtherTopPicks({
        limit: 6,
      }));
    }
  }, [status, accountInfo, sportType, masterSport]);

  return (
    <Container>
      {status === 'succeeded' && data?.length > 0 && (
        <>
          <CategoryTitle large bold>
            <Trans ns="friendlyMatches" i18nKey="otherTopPicks">Other Top Picks</Trans>
            <InfoContainer>
              <InfoIcon src={LoadImage('friendly-matches/info.svg')} alt="Find out more" />
              <Popup>
                <Trans ns="friendlyMatches" i18nKey="otherTopPicksPopUp">
                  Players you previously met in friendly or competition matches and/or your eligible friends on Sportya
                </Trans>
              </Popup>
            </InfoContainer>
          </CategoryTitle>

          <PlayersContainer>
            {data?.map((result, key) => <Player key={key} data={result} topPicks />)}
          </PlayersContainer>
        </>
      )}

      {status === 'loading' && <ContentLoader />}
    </Container>
  );
};

export default TopPicks;
