/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import H1 from '../../components/H1';
import P from '../../components/Paragraph';

import Menu from './components/menu';
import { Container } from './styles';

const CookiePolicy = () => {
  const { t } = useTranslation('rules');
  const menuItems = [];

  for (let j = 0; j < 6; j++) {
    const sectionNr = j + 1;

    menuItems.push({
      id: `section${sectionNr}`,
      title: t(`cookiePolicy.section${sectionNr}.subtitle1`),
    });
  }

  return (
    <Container>
      <div className="row mb60 mt70">
        <div className="col-sm-3 position-relative">
          <Menu menuItems={menuItems} />
        </div>
        <div className="col-sm-9">
          <H1 className="mb30">{t('cookiePolicy.mainTitle')}</H1>
          <P large className="mb40">{t('cookiePolicy.mainDescription')}</P>

          <div id="section1">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section1.subtitle">
                  What is our purpose
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section1.p1">
                  We do not use intrusive cookies on the Website to collect your personal information. We use industry-standard third-party cookies, such as Google Analytics, to track your online behavior and obtain aggregated statistical information in the following ways:
                  • We track the number of visits on the Website, as well as from where did each visitor come from (i.e., the website visited before you arrived on the Website), and where does each visitor go after visiting the Website (i.e., the website you visit after leaving us).

                  • A cookie is stored on your device for no more than one (1) year and is linked to your IP address. Cookies are small text files that your browser stores on your device. This allows us to track if you return to the Website.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section2">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section2.subtitle">
                  How to manage cookies
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section2.p1">
                  Unless you have set your browser settings to refuse cookies, our systems will issue cookies every time you access the Website.
                  You can refuse to accept cookies by activating the setting in your browser that allows you to refuse the storage of cookies. This will delete all the details in the cookie. However, if you select this setting, you may not be able to access portions of the Website or use certain features.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section3">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section3.subtitle">
                  Third-party cookies
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section3.p1">
                  While browsing on the Website, you may also notice content from third parties. We may also offer you the opportunity to make additional contact with us and share information with others by using social networks such as Facebook, LinkedIn, Instagram or Twitter. This content is added to keep you up-to-date with carefully selected information that you might consider relevant or of interest, but this may allow third party vendors to store additional cookies on your device, over which we have no control.
                  Therefore, we suggest that you check the websites of these third parties and their cookie policies, for more information regarding their cookies and how you can manage them.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section4">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section4.subtitle">
                  Cookies used on Sportya.net
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section4.p1">
                  The Website contains links to third-party websites. If you follow a link to any of these third party Websites, please note that these websites have their own privacy and cookie policies and that we accept no responsibility or liability whatsoever for these policies. Please check these policies before submitting any personal data to other third-party websites.
                  Below is a conclusive example of a cookie used by Sportya.net including details of its purpose and other information that you may find useful. Please note, however, that if you choose to access third-party sites through Sportya.net, in addition to the one mentioned below, other cookies may be added.

                  Third-party cookie from Google Inc. // Purpose

                  Google Analytics sets cookies to allow us to track the use of pages and services on our sites.

                  // What kind of data is kept?Duration of the visit, page views, place, operating system, browser, input path, visit number.

                  // Link to personal data? No.

                  // Type of cookie? Permanent.

                  // Third-party cookies? Yes, Google Inc.

                  // Third-party policy http://www.google.co.uk/intl/en/policies/privacy/
                </Trans>
              </P>
            </div>
          </div>

          <div id="section5">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section5.subtitle">
                  Policy changes
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section5.p1">
                  The terms of this Policy may change periodically. We will post any material changes to this Policy through appropriate notices, either on this website or by contacting you through other communication channels.
                </Trans>
              </P>
            </div>
          </div>

          <div id="section6">
            <div>
              <P large bold className="mb40">
                <Trans ns="rules" i18nKey="terms.section6.subtitle">
                  CONTACT
                </Trans>
              </P>
              <P large className="mb40">
                <Trans ns="rules" i18nKey="terms.section6.p1">
                  Questions, comments and requests regarding this policy may be directed to us, via corporate@sportya.net.
                </Trans>
              </P>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CookiePolicy;
