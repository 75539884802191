import React from 'react';
import { useDispatch } from 'react-redux';

import LoadImage from '../../../../common/LoadImage';

import { hideRaiseHandModal } from '../../../reducers/session';

import RaiseHandForm from '../../../../../pages/FriendlyMatches/RaiseHand/RaiseHandForm';

import {
  ModalBackground,
  CloseButton,
  RaiseHandDropDownList,
} from './RaiseHandStyles';

const RaiseHandModal = ({ account }) => {
  const dispatch = useDispatch();
  const setVisible = () => {
    dispatch(hideRaiseHandModal());
  };

  return (
    <>
      <ModalBackground />

      <RaiseHandDropDownList>
        <CloseButton
          src={LoadImage('close-icon.svg')}
          onClick={() => { setVisible(); }}
          alt="Close raise hand modal"
          notMobileOnly
        />

        <RaiseHandForm account={account} setVisible={setVisible} />

      </RaiseHandDropDownList>
    </>
  );
};

export default RaiseHandModal;
