import styled from '@emotion/styled';
import ComponentContainer from '../../../../components/Container';
import ProfileAvailabilityBackground from '../../../../assets/images/bg-set-availability.png';

export const Container = styled(ComponentContainer)`
    margin-bottom: 80px;

    button[type=submit] {
        max-width: max-content;
        width: 100%;

        ${props => props.theme.functions?.mediaQueryMax('600px', `
      max-width: none;
      width: 100%;
    `)}
    }

    [class*="-ValueContainer"] {
        overflow: visible;
    }

    .nm {
        margin: 0 !important;
    }

    ${props => props.theme.functions?.mediaQueryMax('600px', `
      display: flex;
      justify-content: center;
      flex-direction: column;
    `)}

    h3 {
        ${props => props.theme.functions?.mediaQueryMax('600px', `
          text-align: center;
        `)}
    }

    > form {
        display: grid;
        max-width: 444px;

        ${props => props.theme.functions?.mediaQueryMax('600px', `
          max-width: 100%;
          width: 100%;
          margin: 0 auto;
      `)}
        .form-group {
            margin: 0 0 ${({ theme }) => theme.functions?.toEm(20)} 0;

            [class*="-container"] {
                margin: unset;
            }
        }

        > input {
            height: 50px;
            padding: 10px;
            background: #FFFFFF;


            border: 1px solid #78ADAB;
            box-sizing: border-box;
            border-radius: 4px;

            font-weight: bold;
            font-size: 14px;
            line-height: 18px;

            margin-bottom: 30px;
        }

        > p {
            margin: 20px 0px 10px 0px;
        }
    }
`;

export const Group = styled.div`
    //margin-top: 40px;
    border: 1px solid ${props => props.theme.colors.lightTurquoise};
    border-radius: 10px;
    padding: 20px 20px 30px 20px;
    max-width: 444px;
    margin-bottom: 20px;

    > p
    {
        margin-top: 10px;
        margin-bottom: 10px;

        ${props => props.theme.functions?.mediaQueryMax('600px', `
          text-align: center;
        `)}
    }


    ${props => props.theme.functions?.mediaQueryMax('600px', `
      max-width: 100%;
      
      > button {
        margin: 0 auto;
      }
    `)}

    > .wrap-content
    {
        display: flex;
        justify-content: space-around;
        max-width: 444px;

        ${props => props.theme.functions?.mediaQueryMax('600px', `
          width: 100%;
          margin: 0 auto;
        `)}

        > button
        {
            width: 48%;
        }
    }

    a:hover 
    {
      text-decoration: none;
    }

    ${props => props.theme.functions?.mediaQueryMax('600px', `
      > .display-block
      {
        margin: 10px auto;
      }
   `)}
`;

export const GroupSelect = styled.div`
    display: flex;
    justify-content: space-between;

    > .form-group {
        width: 30%;
        [class*="-control"] {
          border: 1px solid #78ADAB;
        }
    }

    > 
`;

export const UserIdSpan = styled.span`
    margin-left: 5px;
    color: #78ADAB;
`;

export const BlockedPlayers = styled.div`
${props => props.theme.functions?.mediaQueryMax('600px', `
    h3
    {
      text-align: center;
    }
  `)}
  
  .unblockBtn {
    width: auto;
    height: 30px;
    padding: 7px 17.5px;
    font-size: 12px;
  }

  .player__avatar {
    width: 38px;
    height: 38px;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid #78ADAB;

    img {
      border-radius: 100%;
    }
  }

  .container {
    width: 100%;
    max-width: 350px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 ${ ({ theme }) => theme.functions?.toEm(15) };
  }
  
  ${props => props.theme.functions?.minDesktopSmall(`
    background: url(${ProfileAvailabilityBackground}) center center;
    background-repeat: no-repeat;
    background-size: 80vw;
  `)}  
`;

export const ProfileAvailabilityWrapper = styled.div`
  ${props => props.theme.functions?.mediaQueryMax('600px', `
    h3
    {
      text-align: center;
    }
  `)}

  button
  {
    max-width: max-content;
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 1;
  }
  
  .react-datepicker__header--time--only {
    display: none;
  }

  .react-datepicker__time-list {
    list-style: none;
    background: #fff;
    width: 113px;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 5px 10px;
    max-height: 300px;
    overflow: auto;
    li {
      cursor: pointer;
      margin: 10px 0;
      &:first-of-type {
        margin: 0 0 10px 0;
      }
      &:last-of-type {
        margin: 10px 0 0 0;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 350px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 ${ ({ theme }) => theme.functions?.toEm(15) };
  }
  
  .choose-day {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .day {
      min-width: ${ ({ theme }) => theme.functions?.toEm(80) };
      margin-right: ${ ({ theme }) => theme.functions?.toEm(20) };
  
      [type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 0;
        cursor: pointer;
      }
      
      img {
        position: absolute;
        visibility: hidden;
      }

      label {
        display: block;
        border-radius: ${ ({ theme }) => theme.functions?.toEm(4) };
        padding: ${ ({ theme }) => theme.functions?.toEm(10) };
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 2px ${ ({ theme }) => theme.colors?.black };
        cursor: pointer;
        text-align: center;
        background: ${props => ({ theme }) => (props.black ? theme.colors?.black : theme.colors?.white)};
        font-weight: bold;
      }
    
      input[type="checkbox"]:checked + label {
          background-color: ${ ({ theme }) => theme.colors?.black };
          color: ${ ({ theme }) => theme.colors?.white };
  
          img {
            visibility: visible;
            position: relative;
            margin-right: 10px;
          }
      }
      
      ${props => props.theme.functions?.minDesktopSmall(`
        margin-right: ${ props.theme.functions?.toEm(35) };
      `)}
    }
    
    .interval {
      width: 100%;
      display: flex;
      position: relative;
      
      p {
        width: 50%;
      }
      
      > div {
        width: inherit;

        &:nth-of-type(1) {
          margin-right: 10px;
        }

        input {
          display: block;
          border-radius: ${ ({ theme }) => theme.functions?.toEm(4) };
          padding: ${ ({ theme }) => theme.functions?.toEm(10) };
          box-shadow: inset 0 0 0 1px ${ ({ theme }) => theme.colors?.black };
          cursor: pointer;
          text-align: left;
          background: ${props => ({ theme }) => (props.black ? theme.colors?.black : theme.colors?.white)};
          font-weight: bold;
          width: 100%;
        }
      }
    }
  }
  
  ${props => props.theme.functions?.minDesktopSmall(`
    background: url(${ProfileAvailabilityBackground}) center center;
    background-repeat: no-repeat;
    background-size: 80vw;
  `)}  
`;

export const CustomRadio = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 444px;
    gap: 10px;

    label {
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 5;
        background: ${props => ({ theme }) => (props.black ? theme.colors?.black : theme.colors?.white)};
        border-radius: ${({ theme }) => theme.functions?.toEm(4)};
        display: flex;
        transition: all 0.3s ease;
        justify-content: center;
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors?.black};
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        color: ${props => ({ theme }) => (props.black ? theme.colors?.white : theme.colors?.black)};
        padding: 11px;

        img {
            visibility: hidden;
            position: absolute;
        }
    }

    [type="radio"] {
        opacity: 0;
        position: absolute;
        width: 0;
        cursor: pointer;
    }

    input[type="radio"]:checked + label {
        background-color: #10242C;
        color: #ffffff;

        img {
            visibility: visible;
            position: relative;
            margin-right: 10px;
        }
    }
`;
