import styled from '@emotion/styled';
import LoadImage from '../../../../../components/common/LoadImage';

export const Prizes = styled.div`
display: flex;
padding: 40px 0;
overflow: auto;
${props => props.theme.functions?.mediaQueryMax('650px', `
flex-direction: column;
overflow: unset;
`)}`;

export const Prize = styled.div`
display: flex;
flex-direction: column;
padding: 10px 0;
margin: 20px;
min-height: 202px;
min-width: 202px;
height: 202px;
width: 100%;
max-width: 252px;
text-align: center;
justify-content: center;
border-radius: 10px;
background: ${({ place }) => (place === 1 ? '#10242C;' : place === 2 ? '#FFFFFF;' : place > 2 ? '#EAF6F6;' : '')}
color: ${({ place }) => (place === 1 ? '#FFE071;' : '#10242C;')}
${({ place }) => (place === 1 ? `
> h4
{
    color: #FFE071;
}

> p
{
    color: #FFE071;
}` : `
> h4
{
    color: #10242C;
}

> p
{
    color: #10242C;
}`)}
${({ place }) => (place === 2 ? 'border: 2px solid #10242C;' : '')}

${props => props.theme.functions?.mediaQueryMax('650px', `
margin: 20px auto;
`)}
`;

export const Medal = styled.div`
display: flex;
flex-direction: column;
justify-content: end;
background: url('${({ place }) => (place === 1 ? LoadImage('firstplace-medal.svg') : LoadImage('medal.svg'))}');
background-repeat: no-repeat;
background-position: center;
height: 36px;
width: 24px;
margin: 0 auto;
position: relative;

> span
{
    font-weight: 600;
    position: absolute;
    left: 1px;
    bottom: 2px;
    width: 100%;
}
`;
