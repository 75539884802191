import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';

import { Container } from './styles';

import { updateAccount } from './actions';

import { H3 } from '../../../../components';
import FormInput from '../../../../components/Form/Input';
import Button from '../../../../components/Form/Button';
import { Country, Flag } from '../../../Auth/Register/styles';
import LoadImage from '../../../../components/common/LoadImage';
import { P } from '../../../../components/Collection';
import Select from '../../../../components/Form/Select';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const BillingData = ({ accountInfo }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { eligibleCountries } = useSelector(state => state.settings.data);

  const formSchema = Yup.object().shape({
    individual: Yup.object().shape({
      fullName: Yup.string().required(commonT('requiredField')),
      address: Yup.string().required(commonT('requiredField')),
      phoneNumber: Yup.string().matches(phoneRegExp, commonT('nrNotValid')).required(commonT('requiredField')),
      city: Yup.string().required(commonT('requiredField')),
      country: Yup.string().required(commonT('requiredField')),
    }),
    company: Yup.object().shape({
      companyName: Yup.string(),
      cui: Yup.string().when('companyName', {
        is: (value) => !!value, then: Yup.string().required(commonT('requiredField')),
      }),
      regNumber: Yup.string(),
      phoneNumber: Yup.string().when('companyName', {
        is: (value) => !!value, then: Yup.string().required(commonT('requiredField')),
      }).matches(phoneRegExp, commonT('nrNotValid')),
      address: Yup.string().when('companyName', {
        is: (value) => !!value, then: Yup.string().required(commonT('requiredField')),
      }),
      city: Yup.string().when('companyName', {
        is: (value) => !!value, then: Yup.string().required(commonT('requiredField')),
      }),
      country: Yup.string().when('companyName', {
        is: (value) => !!value, then: Yup.string().required(commonT('requiredField')),
      }),
    }),
  });

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(eligibleCountry => ({
    value: eligibleCountry.name,
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${eligibleCountry.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${eligibleCountry.key}`)}</P>
      </Country>
    ),
  }));

  return (
    <Container>
      <Formik
        initialValues={{
          individual: {
            fullName: accountInfo?.billingData?.individual?.fullName || '',
            address: accountInfo?.billingData?.individual?.address || '',
            phoneNumber: accountInfo?.billingData?.individual?.phoneNumber || '',
            city: accountInfo?.billingData?.individual?.city || '',
            country: accountInfo?.billingData?.individual?.country || '',
          },
          company: {
            companyName: accountInfo?.billingData?.company?.companyName || '',
            cui: accountInfo?.billingData?.company?.cui || '',
            regNumber: accountInfo?.billingData?.company?.regNumber || '',
            address: accountInfo?.billingData?.company?.address || '',
            phoneNumber: accountInfo?.billingData?.company?.phoneNumber || '',
            city: accountInfo?.billingData?.company?.city || '',
            country: accountInfo?.billingData?.company?.country || '',
          },
        }}
        validationSchema={formSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await updateAccount({
            id: accountInfo.id,
            payload: {
              billingData: values,
            },
            addToast,
            setSubmitting,
            dispatch,
            t: commonT,
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
          values,
          setFieldValue,
        }) => (
          <Form>
            <H3 className="mb20">
              <Trans ns="settings" i18nKey="profileSettings.billingData.individualData">Individual Data</Trans>
            </H3>
            <FormInput
              name="individual.fullName"
              type="text"
              placeholder={t('profileSettings.billingData.fullName')}
              label={t('profileSettings.billingData.fullName')}
              hasError={Boolean(
                getIn(touched, 'individual.fullName')
                && getIn(errors, 'individual.fullName'),
              )}
              {...values.individual?.fullName && {
                value: values.individual?.fullName,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="individual.phoneNumber"
              type="tel"
              placeholder={commonT('phoneNumber')}
              solidPlaceholder
              hasError={Boolean(
                getIn(touched, 'individual.phoneNumber')
                && getIn(errors, 'individual.phoneNumber'),
              )}
              {...values.individual?.phoneNumber && {
                value: values.individual?.phoneNumber,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="individual.address"
              type="text"
              placeholder={t('profileSettings.billingData.address')}
              label={t('profileSettings.billingData.address')}
              hasError={Boolean(
                getIn(touched, 'individual.address')
                && getIn(errors, 'individual.address'),
              )}
              {...values.individual?.address && {
                value: values.individual?.address,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="individual.city"
              type="text"
              placeholder={t('profileSettings.billingData.city')}
              label={t('profileSettings.billingData.city')}
              hasError={Boolean(
                getIn(touched, 'individual.city')
                && getIn(errors, 'individual.city'),
              )}
              {...values.individual?.city && {
                value: values.individual?.city,
              }}
              {...{ errors, touched }}
            />

            <Select
              name="individual.country"
              onChange={(e) => setFieldValue("individual.country", e.value)}
              placeholder={t('profileSettings.billingData.country')}
              label={t('profileSettings.billingData.country')}
              options={countries}
              isSearchable={false}
              height="50px"
              lowInput
              inputLabel
              hasError={Boolean(
                getIn(touched, 'individual.country')
                && getIn(errors, 'individual.country'),
              )}
              {...values.individual?.country
              && {
                defaultValue: countries.filter(countryOption => countryOption.value === values.individual?.country),
              }}
              {...(values.individual?.country) && { labelOn: true }}
              {...{ errors, touched }}
            />

            <H3 className="mb20">
              <Trans ns="settings" i18nKey="profileSettings.billingData.companyData">Company Data</Trans>
            </H3>

            <FormInput
              name="company.companyName"
              type="text"
              placeholder={t('profileSettings.billingData.companyName')}
              label={t('profileSettings.billingData.companyName')}
              hasError={Boolean(
                getIn(touched, 'company.companyName')
                && getIn(errors, 'company.companyName'),
              )}
              {...values.company?.companyName && {
                value: values.company?.companyName,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="company.cui"
              type="text"
              placeholder={t('profileSettings.billingData.cui')}
              label={t('profileSettings.billingData.cui')}
              hasError={Boolean(
                getIn(touched, 'company.cui')
                && getIn(errors, 'company.cui'),
              )}
              {...values.company?.cui && {
                value: values.company?.cui,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="company.regNumber"
              type="text"
              placeholder={t('profileSettings.billingData.regNumber')}
              label={t('profileSettings.billingData.regNumber')}
              hasError={Boolean(
                getIn(touched, 'company.regNumber')
                && getIn(errors, 'company.regNumber'),
              )}
              {...values.company?.regNumber && {
                value: values.company?.regNumber,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="company.phoneNumber"
              type="tel"
              placeholder={commonT('phoneNumber')}
              solidPlaceholder
              hasError={Boolean(
                getIn(touched, 'company.phoneNumber')
                && getIn(errors, 'company.phoneNumber'),
              )}
              {...values.company?.phoneNumber && {
                value: values.company?.phoneNumber,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="company.address"
              type="text"
              placeholder={t('profileSettings.billingData.address')}
              label={t('profileSettings.billingData.address')}
              hasError={Boolean(
                getIn(touched, 'company.address')
                && getIn(errors, 'company.address'),
              )}
              {...values.company?.address && {
                value: values.company?.address,
              }}
              {...{ errors, touched }}
            />

            <FormInput
              name="company.city"
              type="text"
              placeholder={t('profileSettings.billingData.city')}
              label={t('profileSettings.billingData.city')}
              hasError={Boolean(
                getIn(touched, 'company.city')
                && getIn(errors, 'company.city'),
              )}
              {...values.company?.city && {
                value: values.company?.city,
              }}
              {...{ errors, touched }}
            />

            <Select
              name="company.country"
              onChange={(e) => setFieldValue("company.country", e.value)}
              placeholder={t('profileSettings.billingData.country')}
              label={t('profileSettings.billingData.country')}
              options={countries}
              isSearchable={false}
              height="50px"
              lowInput
              inputLabel
              hasError={Boolean(
                getIn(touched, 'company.country')
                && getIn(errors, 'company.country'),
              )}
              {...values.company?.country
              && {
                defaultValue: countries.filter(countryOption => countryOption.value === values.company?.country),
              }}
              {...(values.company?.country) && { labelOn: true }}
              {...{ errors, touched }}
            />

            <Button type="submit" disabled={isSubmitting || (!isValid && dirty)}>
              <Trans ns="common" i18nKey="saveChanges">Save Changes</Trans>
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BillingData;
