import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

// styles
import { ConversationListStyle } from "../../styles";

// components
import LoadImage from "../../../../components/common/LoadImage";
import Avatar from "../Avatar";
import LastMessage from "./components/LastMessage";
import ConversationName from "./components/ConversationName";

const ConversationsList = ({
  conversations,
  conversationId,
  typingMessages,
  systemMessages,
  shortName,
  findPlayerSearchbar,
  isBlocked,
}) => {
  const history = useHistory();
  const { accountInfo } = useSelector(state => state?.session);
  const refs = (conversations || []).reduce((acc, value) => {
    // eslint-disable-next-line no-param-reassign
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  if (isEmpty(conversations)) return null;

  const openConversation = async (id) => {
    history.push(`/conversations/${id}`);
  };

  useEffect(() => {
    if (conversationId && conversationId !== 'undefined') {
      refs[conversationId].current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [conversationId]);

  return (
    <ConversationListStyle className={findPlayerSearchbar ? 'search-bar-visible' : ''}>
      <ul>
        {conversations.map((conversation, key) => {
          const typingMessage = typingMessages[conversation.id];
          const systemMessage = systemMessages.includes(conversation.lastMessage?.message);
          const currentConversation = conversationId === conversation.id ? 'is-active' : undefined;
          const hasLeftGroup = conversation?.type === 'group' && (conversation?.leftBy || [])
            .includes(accountInfo?.userId);

          // show new initiated chats only to initiator if no messages were added yet
          if (isEmpty(conversation?.lastMessage) && conversation?.initiatorId !== Number(accountInfo?.userId)) return;

          return (
            <li
              key={key}
              className={currentConversation}
              onClick={() => openConversation(conversation.id)}
              ref={refs[conversation.id]}
            >
              <Avatar conversation={conversation} accountInfo={accountInfo} />

              <div className="conversation-info">
                <ConversationName
                  conversation={conversation}
                  accountInfo={accountInfo}
                  isBlocked={isBlocked(conversation)}
                />

                <LastMessage
                  conversation={conversation}
                  typingMessage={typingMessage}
                  systemMessage={systemMessage}
                  shortName={shortName}
                  isBlocked={isBlocked(conversation)}
                />
              </div>

              <div className="conversation-flags">
                {(conversation?.mutedBy || []).includes(accountInfo?.userId) && (
                  <img className="muted" src={LoadImage('muted-bell-icon.svg')} alt="Conversation Muted" />
                )}
                {conversation?.newMessages > 0 && !hasLeftGroup && (
                  <span className="new-messages">
                    {conversation?.newMessages > 9 ? '9+' : conversation?.newMessages}
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </ConversationListStyle>
  );
};

export default ConversationsList;
