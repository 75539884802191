import styled from '@emotion/styled';

export const TooltipContainer = styled.div({
  display: 'inline',
  position: 'relative',
  margin: '0 0 0 5px',
  '&:hover > div': {
    visibility: 'visible',
  },
});

export const TooltipText = styled.span({
  color: '#fff',
  fontSize: '12px',
  lineHeight: '15px',
});

export const Tooltip = styled.div({
  visibility: 'hidden',
  display: 'flex',
  minWidth: '245px',
  padding: '10px',
  position: 'absolute',
  background: '#10242C',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  color: '#fff',
  top: '-100px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 2,
});

export const InfoIcon = styled.img({
  width: '15px',
});
