import styled from '@emotion/styled';
import { Link } from '../../../../components/Collection';

export const SeeAll = styled(Link)({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  margin: '0 0 0 auto',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const SliderWrapper = styled.div(props => ({
  maxWidth: `${props.homepage ? '1200px' : '100%'}`,
  position: 'relative',
  background: props.theme.colors.primary,
  padding: '55px 35px',
  margin: '0 auto',
  width: '100%',

  '.slick-slide>div': {
    padding: '0 15px',
  },

  '.slick-prev::before': {
    content: '"<"',
  },
  '.slick-next::before': {
    content: '">"',
  },
  '.slick-prev, .slick-next': {
    display: 'block',
    width: '44px',
    height: '44px',
    position: 'absolute',
    top: `${(props.dashboard || props.homepage) ? '40%' : '47%'}`,
    padding: '0',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    zIndex: '1',
  },
  '.slick-prev::before, .slick-next::before': {
    fontFamily: 'DM Sans',
    fontSize: '30px',
    lineHeight: '44px',
    color: '#000',
    display: 'block',
    height: '44px',
    width: '44px',
    background: '#fff',
    borderRadius: '50%',
    opacity: 1,
  },
  '@media (max-width: 800px)': {
    '.slick-dots': {
      bottom: '-35px',
      width: 'max-content',
      left: '50%',
      transform: 'translateX(-50%)',
      li: {
        'button::before': {
          background: '#fff',
          borderRadius: '50%',
          height: '8px',
          width: '8px',
          color: 'unset',
          opacity: '50%',
        },
        '&.slick-active button::before': {
          opacity: 1,
        },
      },
    },
  },
  ...(props.homepage || props.dashboard) && {
    background: 'unset',
    padding: 'unset',
    margin: '0 auto',
    '.slick-list': {
      height: '450px',
      margin: `0 ${props.dashboard ? '-15px' : '-40px'}`,
    },
    '.slick-dots': {
      bottom: '40px',
      li: {
        '&.slick-active button::before': {
          background: '#10242C',
          borderRadius: '50%',
          height: '12px',
          width: '12px',
          opacity: 1,
          color: 'unset',
        },
        'button::before': {
          borderRadius: '50%',
          border: '2px solid #10242C',
          height: '12px',
          width: '12px',
          color: 'unset',
        },
      },
    },
    '@media (max-width: 1220px)': {
      padding: `0 ${props.homepage ? '20px' : '0'}`,
      overflow: 'hidden',

      '.slick-list': {
        margin: '0 -15px',
      },
    },
    '@media (min-width: 1300px)': {
      '.slick-list': {
        margin: `0 ${props.dashboard ? '-15px' : '-40px'}`,
      },
      '.slick-next': {
        right: -50,
      },
      '.slick-prev': {
        left: -50,
      },
    },
  },
}));

export const InnerSlider = styled.div({
  display: 'flex !important',
  justifyContent: 'center',
  height: '350px',
});

export const Event = styled(Link)(({ homepage, dashboard, space }) => ({
  height: '350px',
  width: '347px',
  textDecoration: 'none !important',
  border: '2px solid #fff',
  borderRadius: '20px',
  position: 'relative',
  background: '#fff',
  ...(homepage === "true" || dashboard === "true") && {
    boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
    ...space && { width: space },
  },
}));

export const EventHeader = styled.img({
  objectFit: 'cover',
  objectPosition: 'center',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  width: '100%',
  height: '192px',
});

export const NewEvent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '190px',
  border: '2px solid #fff',
  background: '#FFE071',
  padding: '0 10px',
  position: 'absolute',
  top: '-15px',
});

export const EventDetails = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  padding: '10px',
});

export const RedirectToEvent = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 5,
  left: '50%',
  transform: 'translateX(-50%)',
});
