import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Banner,
  BannerText,
  ButtonIcon,
  NewMatchButton,
  NewMatchButtonBanner,
} from '../styles';

import { P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import mixpanel from '../../../../mixpanel';

const StartMatchBanner = ({ setHasGameLevel }) => {
  const history = useHistory();
  const { accountInfo } = useSelector(state => state.session);

  const handleCreateMatch = () => {
    if (!accountInfo?.gameLevel?.singles && !accountInfo?.gameLevel?.doubles) {
      setHasGameLevel(false);
      return;
    }

    mixpanel.track('Initiate NEW MATCH INVITE', { sp_action_source: 'blank' });
    const url = '/friendly-matches/create';
    history.push(url);
  };

  const handleAddPlayedMatch = () => {
    if (!accountInfo?.gameLevel?.singles && !accountInfo?.gameLevel?.doubles) {
      setHasGameLevel(false);
      return;
    }

    mixpanel.track('Initiate ADD PLAYED MATCH', { sp_action_source: 'blank' });
    const url = '/friendly-matches/create/played';
    history.push(url);
  };

  return (
    <Banner>
      <BannerText fontSize="24">
        <Trans ns="friendlyMatches" i18nKey="inviteFriends">
          Invite your friends or other players and play a great match together
        </Trans>
      </BannerText>
      <NewMatchButtonBanner outline onClick={() => handleCreateMatch()}>
        <ButtonIcon src={LoadImage('friendly-matches/start-match/button-trophy.svg')} alt="Create Match!" />
        <P bold>
          <Trans ns="friendlyMatches" i18nKey="createNewMatchInvite">Create Match</Trans>
        </P>
      </NewMatchButtonBanner>
      <NewMatchButton onClick={() => handleAddPlayedMatch()}>
        <ButtonIcon
          src={LoadImage('friendly-matches/start-match/button-circle-plus-white.svg')}
          alt="Add Played Match!"
        />
        <P bold margin="0 0 0 10px" color="white">
          <Trans ns="friendlyMatches" i18nKey="addPlayedMatch">Add Played Match</Trans>
        </P>
      </NewMatchButton>
    </Banner>
  );
};

export default StartMatchBanner;
