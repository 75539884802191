const deleteFromScoreForm = (scoreForm, setNumber, isPlayer, isTie) => {
  const updatedScoreForm = JSON.parse(JSON.stringify(scoreForm));
  const targetScore = isPlayer ? updatedScoreForm.player.score : updatedScoreForm.opponent.score;

  if (isTie) {
    const targetSet = targetScore.find((obj) => obj.set === setNumber);
    if (targetSet) {
      targetSet.tie = null;
    }
  } else {
    const filteredScoreForm = targetScore.filter((obj) => obj.set !== setNumber);
    if (isPlayer) {
      updatedScoreForm.player.score = filteredScoreForm;
    } else {
      updatedScoreForm.opponent.score = filteredScoreForm;
    }
  }

  return updatedScoreForm;
};

export default deleteFromScoreForm;
