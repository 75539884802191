import styled from '@emotion/styled';

export const ResultsStyle = styled.div`
overflow:hidden;
hr{
  margin-top:10px; 
  height: 1px;
  background-color: ${({ theme }) => theme?.colors?.lightBlue};
  margin-left:-3000px;
  margin-right:-300px;
  border: none;
}

  .resultsWrapp{
    margin-top:10px;
    width: 100%;
    height:auto;
    display:flex;
    justify-content:space-between;
    
    .results{
      width: 100%;
      max-width: ${(props => (props.league ? '989px' : '100%'))};
    }

  @media screen and (max-width: 1024px){
    flex-direction:column;

    .results{
      min-width: 740px;
    }
  }

  @media screen and (max-width: 830px){
    overflow-x:scroll;

   ::-webkit-scrollbar {
    height:2px !important;
  }

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme?.colors?.lightBlue};;
  outline: none;
}

  }
  } 
`;
