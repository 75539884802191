import styled from '@emotion/styled';
import ComponentContainer from '../../components/Container';

export const LeaderboardStyle = styled.div`
  padding: 0 0 40px 0;

  h1{
    font-size: ${({ theme }) => theme.functions?.toEm(48)};
    line-height: 62px;
  }

  .leaderboardHeader{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:30px;
    .leaderboardHeaderInfo{
      display:flex;
      flex-direction:column;
      align-items:flex-end;
      justify-content:space-between;
      min-height:36px;
      p{margin:0}
    }
  }

  .selectWrapp{
    margin-top:30px;
  }

  .filtersWrapp{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;

    &.mb10 {
      margin-bottom: 10px;
    },

    .filtersTabs {
      max-width:400px;
      width:100%;
      display:flex;
      justify-content:space-between;
      
      .selectedTab {
        color: #000000;
      }

      .tab {
        color: #78ADAB;
      }

      .selectedTab:hover {
        cursor: pointer;
      }

      .tab:hover {
        cursor: pointer;
      }
    }

    .filtersButtons{
      max-width:200px;
      width:100%;
      display:flex;
      justify-content:space-between;

      button{
        width:95px;
        height:50px;
        font-size: ${({ theme }) => theme.functions?.toEm(16)};
        font-weight:700;
        background-color: ${({ theme }) => theme.colors?.white};
        color: ${({ theme }) => theme.colors?.darkTurquoise};
        border:none;
        cursor:pointer;
        outline:none;
        border-radius:4px;
      }
      
      .selectedFilter{
        background-color:${({ theme }) => theme.colors?.lightBlue2};
        color: ${({ theme }) => theme.colors?.primary};
      }

    }
      .filterModalButton{
        .filters-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 2px;
          background: #000;
          border-radius: 50%;
          height: 21px;
          width: 21px;
        }
      }
  }

  .levelSelectorWrapp{
    min-height:50px;
    margin-bottom:21px;
    display:flex;
    justify-content:space-between;
  }


  ${props => props.theme.functions?.mediaQueryMax('768px', `
  .leaderboardHeader{
    flex-direction:column;
    align-items:flex-start;
    .leaderboardHeaderInfo{
      align-items:flex-start;
    }
  }
  .filtersWrapp{
    flex-direction:column;
    align-items:flex-start;

    > div:last-of-type
    {
      margin-top: 20px;
    }

    .filterModalButton{
      margin-left:20px;
    }
  }

    .levelSelectorWrapp{
      width:100%;
      overflow-x:scroll;

      > div
      {
        p
        {
          width: max-content;
          margin: 0 10px;
        }
      }
    }
  `)}
`;

export const FilterModalStyle = styled.div`
padding:10px 25px 11px  25px;
      h2{
        font-size:${({ theme }) => theme.functions?.toEm(28)};
        line-height:36px;
        text-align:center;
        padding-bottom:22px;
      }

      hr{
        height: 1px;
        background-color: ${({ theme }) => theme.colors?.lightBlue2};
        border: none;
        margin: 0 -50px 40px -50px;
      }

      .optionsWrapp{
        display:flex;
        margin-top:17px;
        padding-bottom:23px;
        div{
          max-width:219px;
          width:100%;
        }
      }

      .modalFooter{
        display:flex;
        align-items:center;
        justifyContent:flex-start;
        margin-top:-20px;
        .resetDefault{
          margin-right:72px;
          color: ${({ theme }) => theme.colors?.mediumTurquoise};
          cursor:pointer; 
        }
      }

      .btnWrapp{
        max-width:254px;
        width:100%;
        p{
          color:${({ theme }) => theme.colors?.white};
        }
      }`;

export const Container = styled(ComponentContainer)`
  .hello {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

export const TabsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',

  // .filtersTabs {

  //   .selectedTab {
  //     color: #000000;
  //   }

  //   .tab {
  //     color: #78ADAB;
  //   }

  //   .selectedTab:hover {
  //     cursor: pointer;
  //   }

  //   .tab:hover {
  //     cursor: pointer;
  //   }
  // }
});

export const TabsBtnContainer = styled.div({
  display: 'flex',
  justifyContent: 'start',
  gap: '40px',
});

export const TabsBtn = styled.h2((props) => ({
  color: props.selected ? '#000000' : '#78ADAB',
  cursor: 'pointer',
}));
