const getRegistrationFee = ({
  user,
  competitionInfo,
  competitions,
  playerCurrency,
  registered,
}) => {
  if (user && competitionInfo && competitions) {
    const currency = playerCurrency ?? user.currency ?? 'RON';
    const membershipPlan = user?.membership?.plan ?? 'free';
    const [fees] = (competitionInfo?.fee || []).filter((fee) => fee.currency === (currency ?? 'RON'));
    const data = competitions
      .map((competition) => {
        const listTypes = ['mainList', 'waitingList', 'preRegistrationList'];
        return listTypes
          .map((listType) => competition[listType]
            .filter(({
              userId, partnerId, status, competitionId,
            }) => status === 'CONFIRMED'
            && (Number(user?.userId) === partnerId || Number(user?.userId) === userId)
              && competitionId !== competitionInfo?.competitionId))
          .filter((index) => index.length)
          .shift();
      })
      .filter((index) => index);
    // eslint-disable-next-line no-nested-ternary

    return Number(membershipPlan === 'premium' ? fees?.premium
      : (membershipPlan === 'free' && (registered ? data?.length > 1 : data?.length > 0))
        ? fees?.freeDiscounted : fees?.free);
  }

  return false;
};

export default getRegistrationFee;
