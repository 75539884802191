import styled from '@emotion/styled';

export const GroupStyle = styled.div`
  max-width:373px;
  width:100%;
  margin:${props => () => (props.phase ? '0 auto' : '7px')};

.boldText {
  font-weight: bold;
}

.positionRelative {
  position: relative;
}

.tieScore{
   position: absolute;
   right: -6px;
   top: -10px;
}


  .groupTitle{
  text-align:center;
  margin-bottom:40px;
  margin-top:25px;
  }

  .groupCard{
  border:2px solid ${({ theme }) => theme?.colors?.lightBlue};
  border-radius:4px;
  padding:20px 20px 10px 20px;
  .mainPlayerList{
    margin-bottom:20px;
    .rank{
      text-align:right;
      margin-right:25px;
    }
  .listPlayer{
  display:flex;
  justify-content:space-between;
  max-width:299px;
  width:100%;
  margin-bottom:15px;
  margin-left:21px;

  .you {
    width: max-content;
    background: #EAF6F6;
    border-radius: 4px;
  }

  p{
    margin:0;
  }

  .listPlayerInfo{
    display:flex;
    align-items:center;
    width: 100%;
    padding: 6px 10px;

    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    div{
      margin-right:6px;
    }
  }
  }

.listPlayerRankg{
  max-width:60px;
  width:100%;
  min-height:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:${({ theme }) => theme?.colors?.lightBlue};
  border-radius:3px;
}
  }

{/* match style*/}
  .matchCard{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
    padding:18px 20px;
    margin-bottom:10px;
    border-radius: 4px;
    min-height:122px;
    p{
    margin:0;
    word-break: break-word;
    }   
    .playersWrapp{
      min-height:${props => () => (props.absolute ? '100px' : '57px')};
      display:flex;
      flex-direction:column;
      justify-content:space-between;
    .cardListPlayer{
      display:flex;
      justify-content:space-between;
      .cardListPlayerInfoWrapp{
        display:flex;
        flex-direction:column;
        height: max-content;
        img{
          margin-left:8px;
        }
        div{
          margin-right:6px;
        }

        .you {
          width: max-content;
          background: #EAF6F6;
          border-radius: 4px;
        }

        .cardListPlayerInfo{
          display:flex;
          max-width:500px;
          width:100%;

          > p > a {
            text-decoration: none;
            color: #10242C;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

      .scoreWrapp{
        position: relative;
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-left: auto;
      }
    }
    .matchInfo{
      color:${({ theme }) => theme?.colors?.darkTurquoise};
      margin:0 0 13px 0;
      font-size:10px;
      span{margin: 0 7px 0 7px;}
    }
  }
}

.partner{
  margin-top:5px;
}

.trophyIcon{
  position:absolute;
  right:-22px;
  top:16px;
}

.relative{
  position: relative
}

.playerScore{
      display:flex;
      position:${(props => (props.absolute ? 'absolute' : ''))};
      width: 50px;
      right:0;
      top:${(props => (props.absolute ? '10px' : '13px'))};;
      margin-right: 5px;
      justify-content: flex-start;
      > div > p {
        min-width: 15px;
      }
    }

    .emptyScore{
      display:flex;
      justify-content:center;
      margin: 0 auto;
    }

   @media screen and (max-width:768px){
      margin:0 auto;
      .groupCard{
        .mainPlayerList{
          max-width:274px;
          width:100%;
          margin:0 auto;
          padding-bottom:10px;
          .rank{
            margin:0 17px 0 0;
          }
          .listPlayer{
            margin:0 auto;
            padding-bottom:10px;
          }
        }
      }
    }
`;
