import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Feature from './components/Feature';
import { H3 } from '../../components';

import LoadImage from '../../components/common/LoadImage';
import { Container, Features, Features2 } from './styles';

const ComingSoon = () => {
  const { t } = useTranslation('comingSoon');

  const comingSoonContent = [
    {
      icon: LoadImage('comingsoon-visuals/globe.svg'),
      title: t('item1.title'),
      desc: t('item1.text'),
      label: true,
      type: '0',
      extra:
      {
        hasAdditional: true,
        additionalText: t('item1.subtitle'),
      },
    },
    {
      icon: LoadImage('comingsoon-visuals/messages.svg'),
      title: t('item2.title'),
      desc: t('item2.text'),
      label: true,
      type: '0',
    },
    {
      icon: LoadImage('comingsoon-visuals/friendly-matches.svg'),
      title: t('item3.title'),
      desc: t('item3.text'),
      label: true,
      type: '0',
    },
    {
      icon: LoadImage('comingsoon-visuals/messages-2.svg'),
      title: t('item4.title'),
      desc: t('item4.text'),
      label: true,
      type: '0',
    },
  ];

  const comingFeatures = [
    {
      icon: LoadImage('comingsoon-visuals/news.png'),
      title: t('item5.title'),
      desc: t('item5.text'),
      label: true,
      type: '1',
    },
    {
      icon: LoadImage('comingsoon-visuals/meetYourMatch.png'),
      title: t('item6.title'),
      desc: t('item6.text'),
      label: true,
      type: '1',
      extra:
      {
        hasAdditional: true,
        additionalText: t('item6.subtitle'),
      },
    },
    {
      icon: LoadImage('comingsoon-visuals/booking.png'),
      title: t('item7.title'),
      desc: t('item7.text'),
      label: true,
      type: '1',
    },
    {
      icon: LoadImage('comingsoon-visuals/liveScores.png'),
      title: t('item8.title'),
      desc: t('item8.text'),
      label: true,
      type: '1',
    },
    {
      icon: LoadImage('comingsoon-visuals/eventsManagement.png'),
      title: t('item9.title'),
      desc: t('item9.text'),
      label: true,
      type: '1',
      extra:
      {
        hasAdditional: true,
        additionalText: t('item9.subtitle'),
      },
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>

      <img src={LoadImage('ComingSoonHeader.svg')} className="mb60" alt="" />

      <Features>
        {comingSoonContent.map((content, idx) => (
          <Feature
            key={idx}
            type={content.type}
            icon={content.icon}
            title={content.title}
            desc={content.desc}
            label={content.label}
            {...content.extra}
          />
        ))}
      </Features>

      <Features2>
        {comingFeatures.map((content, idx) => (
          <Feature
            key={idx}
            type={content.type}
            icon={content.icon}
            title={content.title}
            desc={content.desc}
            label={content.label}
            {...content.extra}
          />
        ))}

        <div className="stay-tuned">
          <img src={LoadImage('comingsoon-visuals/stay-tuned.svg')} alt="" />
          <H3>
            {' '}
            <Trans ns="comingSoon" i18nKey="stayTuned">Stay Tuned for More</Trans>
          </H3>
        </div>
      </Features2>

    </Container>
  );
};

export default ComingSoon;
