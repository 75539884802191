/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import LoadImage from '../common/LoadImage';

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.functions?.toEm(20)}; 
  position: relative;
  
  label, input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  label {
    font-size: 14px;
    color: #cccccc;
    position: absolute;
    pointer-events: none;
    top: 15px;
    left: 17px;
    transition: all 0.1s;
    opacity: 0;
    
    a {
      color: ${({ theme }) => theme.colors?.secondary};
      text-decoration: underline;
    }
    
    &.filled {
      top: 9px;
      bottom: 0;
      opacity: 1;
      font-size: 10px;
      color: #000000;
    }
  }

  input {
      color: ${({ theme }) => theme.colors?.primary};
      outline: none;
      width: 100%;
      padding: 16px 15px;
      font-size: ${({ theme }) => theme.functions?.toEm(14)};
      font-weight: bold;
      border: 1px solid #78ADAB;
      border-radius: 4px;
      transition: all 0.1s;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        font-weight: 700;
        color: ${({ theme }) => theme.colors?.primary};
      }

      &::placeholder {
        font-weight: 700;
        color: ${({ theme }) => theme.colors?.primary};
        ${props => (props.solidPlaceholder ? 'opacity: 1;' : '')}
      }

      &:focus::placeholder {
        color: transparent;
      }

      &:focus,
      &:active {
        outline: 0;
        border-color: ${({ theme }) => theme.colors?.primary};
        padding: 22px 15px 10px;
        
        & + label {
          top: 9px;
          bottom: 0;
          opacity: 1;
          font-size: 10px;
          color: #000000;      
        }
      }
      
      &.filled {
        padding: 22px 15px 10px;
      }

      &.error {
        border: 2px solid ${({ theme }) => theme.colors?.error};
      }
  }
  
  &.checkbox {
    display: flex;
    align-items: center;

    input {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it
    
      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        left: 0;
      }
    
      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 2px solid ${props => (props.forcedError ? ({ theme: { colors } }) => colors?.red
  : ({ theme }) => theme.colors?.primary)};
        border-radius: 4px;
        margin-bottom: 5px;
      }
    
      // Box hover
      &:hover + label:before {
        // background: ${({ theme }) => theme.colors?.primary};
      }
    
      // Box checked
      &:checked + label:before {
        background: ${({ theme }) => theme.colors?.primary};
      }
      
      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }
    
      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: ${({ theme }) => theme.colors?.primary};
      }
    
      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
      }
  
      & + label { 
        color: ${({ theme }) => theme.colors?.primary};
        opacity: 1;
        pointer-events: all;
        position: relative;
        font-size: 14px;
        top: 0;
        line-height: 1.5;
      }
    }
  }
  
  .show-password {
    position: absolute;
    top: 14px;
    right: 10px;
    padding: 5px;
    cursor: pointer;  
  }

  .error-message {
      color: ${({ theme }) => theme.colors?.red};
      font-weight: 500;
      font-size: 10px;
      text-align: center;
      margin-top: ${({ theme }) => theme.functions?.toEm(5)};        
  }
`;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Input = (props) => {
  const {
    solidPlaceholder,
    id,
    hasError,
    errors,
    touched,
    placeholder,
    label,
    name,
    value,
    type,
    forcedError,
    ...rest
  } = props;
  const labelValue = placeholder || label;
  const [togglePass, setTogglePass] = useState(false);

  const toggleShowPass = () => {
    setTogglePass(!togglePass);
  };

  return (
    <InputWrapper className={type} solidPlaceholder={solidPlaceholder} forcedError={forcedError && !value}>
      <Field
        name={name}
        placeholder={placeholder}
        type={(type === 'password' && togglePass) ? 'text' : type}
        id={id}
        className={(hasError || (errors
          && errors[name] && touched && touched[name])) ? `error ${value ? 'filled' : ''}` : value ? 'filled' : ''}
        {...rest}
      />
      {labelValue && <label htmlFor={id} className={value ? 'filled' : ''}>{labelValue}</label>}

      {type === 'password' && (
        <img src={LoadImage('eye-icon.svg')} alt="" className="show-password" onClick={toggleShowPass} />
      )}

      <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
    </InputWrapper>
  );
};

export default Input;
