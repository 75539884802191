import React from 'react';
import ReactPaginate from 'react-paginate';

import { PaginationStyle } from './styles';
import LoadImage from '../common/LoadImage';

const Pagination = ({ section, ...props }) => (
  <>
    {props.pageCount > 1
      && (
        <PaginationStyle section={section}>
          <div className="pagination">
            <ReactPaginate
              previousLabel={<img src={LoadImage('pagination-left-arrow.svg')} alt="Previous Page" />}
              activeClassName="activePage"
              nextLabel={<img src={LoadImage('pagination-right-arrow.svg')} alt="Next Page" />}
              nextClassName="nextArrow"
              {...props}
            />
          </div>
        </PaginationStyle>
      )}
  </>
);

export default Pagination;
