import styled from '@emotion/styled';

export const ResultsPhase = styled.div`

 @media screen and (max-width: 1024px){
        margin: 21px 0 15px 25px;
  }
      max-width: 169px;
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      margin-top: ${props => () => (props.league ? '0' : '21px')};
      p{
        margin:0;
      }
      p:first-of-type{
        margin-bottom:5px;
      }
      p:not(:first-of-type){
        color: ${({ theme }) => theme?.colors?.darkTurquoise};
      }
`;
