/** @jsxImportSource @emotion/react */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import RomaniaJoacaTenis from '../../components/Campaign/RomaniaJoacaTenis';
import DuracellAndPringles from '../../components/Campaign/DuracellAndPringles';

import Slider from './components/Slider';
import Statistics from './components/Statistics';
// import Events from "./components/Events";
import LoadImage from '../../components/common/LoadImage';
import Sponsors from '../../components/Sponsors';

import {
  Button, Contain, P as Paragraph, H2, Link,
} from '../../components/Collection';
import {
  ForClubs, ForPlayers, Item, List,
} from './styles';
import FeaturedEvents from '../Events/components/FeaturedEvents';

// Feature outdated
// import Clubs from "./components/Clubs";

const Homepage = ({ isLoggedIn }) => {
  const { t } = useTranslation('homepage');

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>

      <Slider />
      <Statistics />
      {/* <RomaniaJoacaTenis /> */}
      <DuracellAndPringles />
      <FeaturedEvents homepage amount={3} />
      {/* {!!clubs.length && false &&
        <Clubs clubs={clubs} />
      } */}

      <ForPlayers>
        <img src={LoadImage('homepage/court.png')} alt="Playing Court" />
        <Contain
          direction="column"
          padding="0 0 0 85px"
          margin="auto 0"
          className="listing"
        >
          <List>
            <Item>
              <Paragraph large bold>
                <Trans ns="homepage" i18nKey="centralPanel.first.title">Play more. Play your way</Trans>
              </Paragraph>
              <Paragraph small>
                <Trans ns="homepage" i18nKey="centralPanel.first.desc">
                  Rate your game level and choose your play, from classic, knockout events, organized during
                  the weekend, to group format events, night events, flexible Local Leagues, friendly matches and more.
                </Trans>
              </Paragraph>
            </Item>
            <Item>
              <Paragraph large bold>
                <Trans ns="homepage" i18nKey="centralPanel.second.title">Compete in amateur tennis tournaments</Trans>
              </Paragraph>
              <Paragraph small>
                <Trans ns="homepage" i18nKey="centralPanel.second.desc">
                  Access a calendar of hundreds of Sportya events, start local and advance to international
                  tennis events for amateur players, all organized on level-based play.
                </Trans>
              </Paragraph>
            </Item>
            <Item>
              <Paragraph large bold>
                <Trans ns="homepage" i18nKey="centralPanel.third.title">
                  Join an international community of tennis players
                </Trans>
              </Paragraph>
              <Paragraph small>
                <Trans ns="homepage" i18nKey="centralPanel.third.desc">
                  Showcase your profile, connect with other players, compete in local and
                  global amateur rankings and make the most out of your tennis life.
                </Trans>
              </Paragraph>
            </Item>
          </List>
          <Contain
            justify="space-evenly"
            maxWidth="320px"
            width="100%"
            margin="0 auto"
          >
            <Button outline width="150px">
              <Link to="/for-players">
                <Trans ns="homepage" i18nKey="centralPanel.buttons.first">Learn more</Trans>
              </Link>
            </Button>
            <Button width="150px">
              <Link to="/register">
                <Trans ns="homepage" i18nKey="centralPanel.buttons.second">Join now ⟶</Trans>
              </Link>
            </Button>
          </Contain>
        </Contain>
      </ForPlayers>

      <ForClubs>
        <Contain
          className="container"
          direction="column"
        >
          <H2 className="text-center mb50">
            <Trans ns="homepage" i18nKey="clubArea.title">Are you a Tennis Club Manager or Court Administrator?</Trans>
          </H2>
          <Contain
            justify="space-around"
            className="listing"
          >
            <List>
              <Item>
                <Paragraph large bold>
                  <Trans ns="homepage" i18nKey="clubArea.first.title">Manage local and global events</Trans>
                </Paragraph>
                <Paragraph>
                  <Trans ns="homepage" i18nKey="clubArea.first.desc">
                    Personalized for your club needs and court availability, for maximum impact.
                  </Trans>
                </Paragraph>
              </Item>
              <Item>
                <Paragraph large bold>
                  <Trans ns="homepage" i18nKey="clubArea.third.title">Become a hub for tennis enthusiasts</Trans>
                </Paragraph>
                <Paragraph>
                  <Trans ns="homepage" i18nKey="clubArea.third.desc">
                    Support level-based play and increase player engagement and loyalty.
                  </Trans>
                </Paragraph>
              </Item>
            </List>
            <List>
              <Item>
                <Paragraph large bold>
                  <Trans ns="homepage" i18nKey="clubArea.second.title">Connect with new players</Trans>
                </Paragraph>
                <Paragraph>
                  <Trans ns="homepage" i18nKey="clubArea.second.desc">
                    Build and expand your player base with local and global players.
                  </Trans>
                </Paragraph>
              </Item>
              <Item>
                <Paragraph large bold>
                  <Trans ns="homepage" i18nKey="clubArea.fourth.title">Book courts</Trans>
                </Paragraph>
                <Paragraph>
                  <Trans ns="homepage" i18nKey="clubArea.fourth.desc">
                    Manage and book your courts. Keep players engaged, more frequently.
                  </Trans>
                </Paragraph>
              </Item>
            </List>
          </Contain>
          <Button width="215px" margin="0 auto">
            <Link to="/for-clubs">
              <Trans ns="homepage" i18nKey="clubArea.button">Learn more</Trans>
            </Link>
          </Button>
        </Contain>
      </ForClubs>

      <Sponsors />
    </>
  );
};

export default Homepage;
