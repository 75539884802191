export default {
  title: 'Přihlásit se',
  subTitle: 'Vítejte nazpět',
  seoTitle: 'Přihlásit se | Sportya',
  placeholders: {
    email: 'E-mail',
    password: 'Heslo',
  },
  forgotPassword: 'Zapomenuté heslo?',
  remember: 'Pamatovat si mě',
  button: 'Přihlásit se',
  socialSignTitle: 'Nebo se přihlásit pomocí',
  noAccount: 'Nemáte účet?',
  termsAndConditions: 'Přihlášením potvrzuji, že jsem si přečetl(a) a souhlasím se zněním sekcí <1>Smluvní vztahy a podmínky</1> a <1>Zásady ochrany soukromí</1>',
  form: {
    AUTH_FAILED: 'Neplatná oprávnění.',
    something_wrong_happened: 'Vyskytl se nějaký problém.',
  },
};
