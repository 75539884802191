/* eslint-disable max-len */
import styled from '@emotion/styled';

export const PlanStyle = styled.div`
  ${props => ({ theme }) => props.type === 'free' && `border: 2px solid ${theme?.colors?.lightBlue};`}
  background-color: ${props => ({ theme }) => (props.type === 'free' ? `${theme?.colors?.white}` : (props.type === 'summer' ? `${theme?.colors?.summer}` : `${theme?.colors?.primary}`))};
  border-radius: 10px;
  max-width: 286px;
  min-height: 600px;
  width: 100%;
  position: relative;
  padding: 38px 12px 20px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bestValue {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -14px;
    height: 28px;
    border-radius: 22px;
    width: 93px;
    border: 2px solid ${({ theme }) => theme?.colors?.primary};
    background-color: ${({ theme }) => theme?.colors?.yellow};

    p {
      margin: 0;
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    max-width: 386px;
    .perksWrapp {
      margin: 0 auto;
    }
  }

  .imgWrapp {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 21px;

    > p {
      position: absolute;
      margin: 0;
      top: 19px;
      color: black;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  h4 {
    font-size: ${({ theme }) => theme.functions?.toEm(24)};
    line-height: 31px;
    text-align: center;
    color: ${props => ({ theme }) => (props.type === 'premium' ? `${theme?.colors?.white}` : `${theme?.colors?.primary}`)};
  }

  p {
    color: ${props => ({ theme }) => (props.type === 'premium' ? `${theme?.colors?.white}` : `${theme?.colors?.primary}`)};
    text-align: center;
    margin: 29px 0 49px;
  }

  .expirationDate {
    margin: 20px 0 40px;

    p {
      margin: 0;
    }
  }

  .perksWrapp {
    max-width: 262px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .priceWrapp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 33px 0;
    height: 52px;

    p {
      margin: 0;

    }
  }

  .buttonWrapp {
    max-width: 230px;
    width: 100%;
    margin: 0 auto;

    p {
      margin: 0px;
      color: ${({ theme }) => theme?.colors?.white};
    }

    a {
      text-decoration: none;
    }

    .planButton {
      color: ${props => ({ theme }) => (props.type === 'premium' && !props.currentPlan ? theme?.colors?.primary
  : props.type === 'premium' && props.currentPlan ? theme?.colors?.white
    : props.type === 'summer' && props.extend ? theme?.colors?.primary
      : props.type === 'summer' && !props.currentPlan ? theme?.colors?.white : theme?.colors.primary)} !important;
      margin-top: 33px;
      height: 60px;
      width: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: ${props => ({ theme }) => (props.type === 'premium' && !props.currentPlan ? theme?.colors?.yellow
        : props.type === 'summer' && props.extend ? 'transparent'
          : (props.type === 'summer' && !props.currentPlan ? theme?.colors?.primary : 'transparent'))};

      cursor: ${props => (props.type !== 'free' && !props.currentPlan ? 'pointer' : '')};
      text-decoration: none;

      p {
        color: red !important;
      }

      a {
        color: ${props => (props.type === 'premium' ? '#10242C' : 'white')};
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }


  .learnMore {
    margin: 17px auto 0;
    line -height: 18px;
    cursor: pointer;
    width: 73px;
  }
`;

export const InfoModal = styled.div`
  padding: 20px 20px 42px 20px;

  h2 {
    font-size: ${({ theme }) => theme?.functions?.toEm(28)};
    line-height: 36px;
    margin-bottom: 19px;
    margin-left: 5px;

  }

  p {
    max-width: 818px;
    font-size: ${({ theme }) => theme?.functions?.toEm(18)};
    margin: 0 0 0 5px;
  }
`;

export const BuyModal = styled.div`
  .modalHeader {
    padding: 45px 0 60px 0;

    h2 {
      font-size: ${({ theme }) => theme?.functions?.toEm(28)} !important;
      line-height: 36px;
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      color: ${({ theme }) => theme?.colors.primary};
    }

    .confirmTitle {
      text-align: center;
      max-width: 342px;
      width: 100%;
      margin: 0 auto;
    }

    .modalInfo {
      max-width: 341px;
      width: 100%;
      margin: 40px auto 30px;

      hr {
        border: 1px solid ${({ theme }) => theme?.colors?.lightBlue2};
        margin: 10px 0 10px 0;
      }

      .info {
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }

      .billing-data {
          gap: 5px;
          display: flex;
          flex-direction: column;   
      }
    }

    form {
        margin: auto;
    }

    .confirmPaymentInfo {
      width: 100%;
      max-width: 80%;
      margin: 40px auto 0;

      hr {
        border: 1px solid ${({ theme }) => theme?.colors?.lightBlue2};
        margin: 10px 0 10px 0;
      }

      .info {
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }

      .buttons {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        @media screen and (max-width: 500px) {
          flex-direction: column;
          > button:last-of-type {
            margin-top: 20px;
          }
        }

        button {
          margin: 0 5px;
        }
      }
    }
  }

  .selectWrapp {
    max-width: 341px;
    width: 100%;
    margin: 0 auto;

    .css-yk16xz-control {
      height: 50px;
    }

    .submitButton {
      margin-top: 60px;
      z-index: 0;
    }
  }

  .balanceWrapp {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }

    &__negative-amount {
      p {
        position: relative;
        color: ${({ theme }) => theme?.colors?.error};

        .topup-popup {
          position: absolute;
          width: max-content;
          background: black;
          text-align: center;
          border-radius: 5px;
          margin-top: 30px;
          padding: 10px;
          animation: fadeIn .5s linear;
          z-index: 1;
          cursor: pointer;

          > a {
            color: #fff;
            text-decoration: none;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 20%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent black transparent;
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }

  .stripe-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`;
export const StripeStyle = styled.div`
  padding: 40px;
  max-width: 429px;
  width: 100%;
  height: 294px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: ${({ theme }) => theme?.functions?.toEm(24)};
    line-height: 31px;
    margin-top: 10px;
  }
`;
