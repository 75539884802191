import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';
import { Container } from './styles';

import Loader from '../../../../components/ContentLoader';
// import ELO from './components/Elo';
import Rankings from './components/Ranking';
import Stats from './components/Stats';
import FriendlyMatchStats from './components/FriendlyMatchStats';
import { Contain, P } from '../../../../components/Collection';
import Select from '../../../../components/Form/Select';
import { H4 } from '../../../../components';
import { FiltersContainer, TabOptionsContainer } from '../Activity/styles';
import { Badge } from '../../../../components/LevelsBadges/styles';
import { UserContext } from '../../index';

const StatsTab = ({ activeSports, masterSport }) => {
  const user = useContext(UserContext);
  const { t } = useTranslation('common');
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const [sportType, setSportType] = useState(masterSport);
  const isLoading = generalStatus === 'loading';

  if (isLoading) {
    return (
      <Container hasLoader>
        <Loader />
      </Container>
    );
  }
  const gameLevel = {
    singles: user.sports?.[sportType]?.singles?.gameLevel || null,
    doubles: user.sports?.[sportType]?.doubles?.gameLevel || null,
  };

  return (
    <Container>
      <TabOptionsContainer>
        <Contain align="center">
          <H4 className="mr10">{t(`sports.${sportType}`)}</H4>
          <Badge small className="mr5 badge">
            <P xSmall>{gameLevel.singles || "-"}</P>
          </Badge>
          <P small margin="0 15px 0 0">
            <Trans ns="player" i18nKey="singleLevel">Singles Level</Trans>
          </P>
          <Badge small className="mr5 badge" double>
            <P xSmall>{gameLevel.doubles || "-"}</P>
          </Badge>
          <P small margin="0 5px 0 0">
            <Trans ns="player" i18nKey="doubleLevel">Doubles Level</Trans>
          </P>
        </Contain>
        <FiltersContainer>
          <Select
            name="sportType"
            placeholder={t('sports.all')}
            onChange={(e) => setSportType(e.value)}
            options={activeSports.map((sport) => ({
              label: t(`sports.${sport}`),
              value: sport,
            }))}
            label={t('form.sport', { ns: 'clubs' })}
            value={sportType}
            lowInput
            width="150px"
            {...sportType && {
              labelOn: true,
              value: ['all', ...activeSports]
                .filter(sport => sport === sportType)
                .map((sport) => ({
                  label: t(`sports.${sport}`),
                  value: sport,
                })),
            }}
          />
        </FiltersContainer>
      </TabOptionsContainer>
      <Contain gap={20} className="stats-boxes">
        <Rankings sportType={sportType} />
        <Stats sportType={sportType} />
        <FriendlyMatchStats sportType={sportType} />
        {/* <ELO sportType={sportType} /> */}
      </Contain>
    </Container>
  );
};

export default StatsTab;
