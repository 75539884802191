import styled from '@emotion/styled';

export const BaskedItemStyle = styled.div`
    margin-top:${({ theme }) => theme.functions?.toEm(10)} !important;
.productName{
  max-width:291px;
  width:100%;
}
p{
  max-width:265px;
  font-size:${({ theme }) => theme.functions?.toEm(16)} !important;
}
  .price{
    display:flex;
    justify-content: flex-end;
    min-width:101px;
  }
  div{
    margin-top:10px;
    display: flex;
    justify-content: space-between;
  }
`;

export const ChangeButton = styled.div`
    margin:0  !important;
    p{
      font-size:${({ theme }) => theme.functions?.toEm(12)} !important;
      margin:2px 0 24px 0 !important;
      cursor: pointer;
    }
`;
