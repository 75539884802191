import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import { ModalContainer } from '../../../styles';

import LoadImage from '../../../../../components/common/LoadImage';
import Modal from '../../../../../components/Modal';
import ToggleVisible from '../../../../../components/common/ToggleVisible';
import LeaveChatConfirmationModal from './LeaveChatConfirmationModal';

import { addConversation } from '../../../actions';

const SeeMembersListModal = ({
  handleSeeGroupMembers,
  seeGroupMembersModal,
  setSeeGroupMembersModal,
  conversationInfo,
  accountInfo,
  handleLeaveConversation,
}) => {
  const { t } = useTranslation('conversations');
  const { addToast } = useToasts();
  const history = useHistory();
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const [confirmLeaveConversation, setConfirmLeaveConversation] = useState(false);

  const initiateConversation = async (player) => {
    await handleSeeGroupMembers();
    setIsComponentVisible(false);

    const payload = {
      members: [player],
    };

    await addConversation({
      payload,
      addToast,
      t,
    });
  };

  return (
    <>
      <Modal
        isOpen={seeGroupMembersModal}
        onRequestClose={handleSeeGroupMembers}
        conversationInfo={conversationInfo}
      >
        <ModalContainer>
          <div className="modal-header">
            <Trans ns="conversations" i18nKey="groupMembers">Group Members</Trans>
          </div>

          <div className="modal-content">
            <ul className="members-list">
              {(conversationInfo?.members || []).map((member, key) => (
                <li key={key}>
                  <figure className="smaller mr10">
                    {member?.profilePicture ? (
                      <div className="image">
                        <img
                          src={LoadImage(`accounts/${member?.userId}/${member?.profilePicture}`, true)}
                          alt={`${member?.firstName} ${member?.lastName}`}
                          width={100}
                        />
                      </div>
                    ) : (
                      <div className="initials">
                        <span>{member.firstName.charAt(0)} {member.lastName.charAt(0)}</span>
                      </div>
                    )}
                  </figure>
                  <p>
                    {member?.firstName} {member?.lastName}
                    <span>#{member?.userId} {member?.userId === accountInfo?.userId ? `(${t('you')})` : ''}</span>
                    {(conversationInfo?.leftBy || []).includes(member?.userId) && (<span>({t('left')})</span>)}
                  </p>

                  <div
                    ref={ref}
                    className={`
                      actions
                      mr10
                      cursor-pointer
                      ${isComponentVisible === member.userId ? 'show-options' : ''}
                    `}
                    onClick={() => setIsComponentVisible(member.userId)}
                  >
                    <img src={LoadImage('three-dots-icon.png')} alt="Actions" />
                    <ul className={`
                      options
                      ${isComponentVisible === member.userId ? '' : 'hidden'}
                      ${conversationInfo?.members.length - 1 === key ? 'is-last' : ''}
                      `}
                    >
                      <li onClick={() => history.push(`/accounts/${member.id}`)}>
                        <img src={LoadImage('view-profile-outline-icon.svg')} alt="" />
                        <Trans ns="conversations" i18nKey="viewProfile">View Profile</Trans>
                      </li>
                      {member.userId !== accountInfo?.userId ? (
                        <li onClick={() => initiateConversation(member)}>
                          <img src={LoadImage('start-group-chat-icon.svg')} alt="" />
                          <Trans ns="conversations" i18nKey="chat">Chat</Trans>
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            setIsComponentVisible(false);
                            setSeeGroupMembersModal(false);
                            setConfirmLeaveConversation(true);
                          }}
                        >
                          <img src={LoadImage('leave-group-chat-icon.svg')} alt="" />
                          <Trans ns="conversations" i18nKey="leaveGroup">Leave Group Chat</Trans>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </ModalContainer>
      </Modal>

      <LeaveChatConfirmationModal
        confirmLeaveConversation={confirmLeaveConversation}
        setConfirmLeaveConversation={setConfirmLeaveConversation}
        handleLeaveConversation={handleLeaveConversation}
      />
    </>
  );
};

export default SeeMembersListModal;
