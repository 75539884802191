import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  Container,
  Content,
  CopyButton,
  Icon,
  InviteURL,
  SocialMedia,
  SocialMediaContainer,
  URLContainer,
} from './style';
import LoadImage from '../../../../components/common/LoadImage';
import { P } from '../../../../components/Collection';
import mixpanel from '../../../../mixpanel';
import { copyText, encode } from '../../../Referral';

const InviteFriends = ({ dashboard }) => {
  const { accountInfo } = useSelector(state => state.session);
  const { t } = useTranslation('accounts');
  const inviteURLholder = useRef(null);

  const inviteURL = `${process.env.REACT_APP_WEB_URL}/join/${encode(accountInfo)}`;

  const emailBody = t('referral.shareToMedia.email.body', { inviteLink: inviteURL });
  const twitterText = t('referral.shareToMedia.twitter', { inviteLink: inviteURL });
  const facebookText = t('referral.shareToMedia.facebook', { inviteLink: inviteURL });
  let facebookHref = `https://www.facebook.com/dialog/share?href=${inviteURL}`;
  facebookHref += `&app_id=429689722395295&display=popup&quote=${facebookText}`;

  return (
    <Container isDashboard={dashboard}>
      <Content>
        <P small bold margin="0 0 10px 0">
          <Trans ns="accounts" i18nKey="referral.invite.playTogether">
            Invite your friends and play together
          </Trans>
        </P>
        <URLContainer>
          <InviteURL
            playerProfile
            value={`${process.env.REACT_APP_WEB_URL}/join/${encode(accountInfo)}`}
            disabled
            ref={inviteURLholder}
          />
          <CopyButton
            playerProfile
            onClick={() => { copyText(inviteURLholder); mixpanel.track('Copy Invitation URL (click in Copy button)'); }}
          >
            <Icon src={LoadImage('copy.svg')} alt="Copy referral link" />
            <P color="white" margin="unset">
              <Trans ns="accounts" i18nKey="referral.copy">
                Copy
              </Trans>
            </P>
          </CopyButton>
          <SocialMediaContainer>
            <SocialMedia
              onClick={() => mixpanel.track('Share invitation via Facebook (click in Facebook button)')}
              href={facebookHref}
              target="_blank"
            >
              <img src={LoadImage('social-media/facebook.svg')} alt="Share to Facebook" />
            </SocialMedia>
            <SocialMedia
              onClick={() => mixpanel.track('Share invitation via Twitter (click on Twitter button)')}
              href={`https://twitter.com/intent/tweet?url=${inviteURL}&text=${twitterText}&hashtag=Sportya`}
              target="_blank"
            >
              <img src={LoadImage('social-media/twitter.svg')} alt="Share to Twitter" />
            </SocialMedia>
            <SocialMedia
              onClick={() => mixpanel.track('Share invitation via Email (click on Email button)')}
              href={`mailto:?to=&body=${emailBody}&subject=${t('referral.shareToMedia.email.title')}`}
              target="_blank"
            >
              <img src={LoadImage('social-media/email.svg')} alt="Share through Email" />
            </SocialMedia>
          </SocialMediaContainer>
        </URLContainer>
      </Content>
    </Container>
  );
};

export default InviteFriends;
