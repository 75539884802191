import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Row, Column } from './styles';
import { P, H2, Alert } from '../../../../components/Collection';

import { fetchStats } from './reducers';
import ContentLoader from '../../../../components/ContentLoader';

const Statistics = () => {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector(state => state.homepage?.stats);
  const {
    players, events, clubs, cities,
  } = data ?? {};
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchStats());
    }
  }, [status, dispatch]);

  if (status === 'loading') {
    return <ContentLoader />;
  }

  return error ? (
    <Alert warn className="m80">
      <Trans ns="homepage" i18nKey="stats.failedToLoad">
        <strong>Failed to load statistics:</strong>
        {' '}
        {{ error }}
      </Trans>
    </Alert>
  ) : (
    <Row>
      <Column>
        <Trans ns="homepage" i18nKey="stats.activePlayers">
          <P medium bold>Players</P>
          {' '}
          <H2 className="mt20">{{ players }}</H2>
        </Trans>
      </Column>

      <Column>
        <Trans ns="homepage" i18nKey="stats.activeEvents">
          <P medium bold>
            Planned Events
            {{ currentYear }}
          </P>
          {' '}
          <H2 className="mt20">{{ events }}</H2>
        </Trans>
      </Column>

      <Column>
        <Trans ns="homepage" i18nKey="stats.activeClubs">
          <P medium bold>Clubs</P>
          {' '}
          <H2 className="mt20">{{ clubs }}</H2>
        </Trans>
      </Column>

      <Column>
        <Trans ns="homepage" i18nKey="stats.activeCities">
          <P medium bold>Cities</P>
          {' '}
          <H2 className="mt20">{{ cities }}</H2>
        </Trans>
      </Column>

      <Column>
        <Trans ns="homepage" i18nKey="stats.activeCountries">
          <P medium bold>Countries</P>
          {' '}
          <P bold size={20} textAlign="center">
            Romania, Republic of
            Moldova, Bulgaria,
            Bahrain,
          </P>
        </Trans>
      </Column>
    </Row>
  );
};

export default Statistics;
