import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Paragraph, Modal } from '../../../../../components';
import SelectComponent from '../../../../../components/Form/Select';
import TextArea from '../../../../../components/TextArea';
import ToggleVisible from '../../../../../components/common/ToggleVisible';
import LoadImage from '../../../../../components/common/LoadImage';
import { post } from '../../../../../components/common/http';
import { formatMatchId } from '../../../helpers';
import mixpanel from '../../../../../mixpanel';
import { updateMatchReportedBy } from '../../../reducers';

const ReportMatch = ({ isModalVisible: matchInfo, setIsModalVisible }) => {
  const { t } = useTranslation('friendlyMatches');
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state?.session);
  const [reason, setReason] = useState(null);
  const [matchId, setMatchId] = useState(null);
  const editorRef = useRef(null);
  const { isComponentVisible: isReportConfirmed, setIsComponentVisible: setIsReportConfirmed } = ToggleVisible(false);

  useEffect(() => {
    if (matchInfo?.matchId) {
      setMatchId(matchInfo?.matchId);
    }
  }, [matchInfo]);

  const reportReasons = [
    { value: 'incorrect-score', label: t('reportReasons.incorrectScore') },
    { value: 'undisputed-match', label: t('reportReasons.undisputedMatch') },
    { value: 'other', label: t('reportReasons.other') },
  ];

  const submitReport = async () => {
    const payload = {
      reported: {
        id: matchInfo?.id,
        matchId,
      },
      reason: reason?.value,
      reasonDescription: editorRef?.current.value,
    };

    post('/friendly-matches/reports', payload)
      .then(() => {
        setIsModalVisible(false);
        setIsReportConfirmed(true);
        dispatch(updateMatchReportedBy({
          list: 'scores',
          matchId,
          userId: accountInfo?.userId,
        }));
      })
      .catch(e => {
        console.log(e);
      });

    mixpanel.track('Friendly matches MATCH REPORTED', {
      sp_asset_type: matchInfo.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
      sp_match_type: matchInfo.ranked ? 'Ranked' : 'Unranked',
      sp_game_type: matchInfo.gameType,
      sp_game_location_city: matchInfo.location?.city,
      sp_game_location_country: matchInfo.location?.country,
      sp_game_format: matchInfo.format,
      sp_report_reason: reason?.value,
      sp_match_id: formatMatchId(matchInfo.matchId),
    });
  };

  return (
    <>
      <Modal
        isOpen={matchInfo}
        onRequestClose={() => setIsModalVisible(false)}
        className="report-match-modal"
      >
        <div className="modal-header">
          <Paragraph large bold className="text-center">
            <Trans ns="friendlyMatches" i18nKey="reportMatch">Report Match</Trans>
          </Paragraph>
        </div>
        <div className="modal-content">
          <Paragraph className="text-center mb40">
            <Trans ns="friendlyMatches" i18nKey="matchId">
              Match ID: {{ matchId: formatMatchId(matchId) }}
            </Trans>
          </Paragraph>

          <SelectComponent
            placeholder={t('reportReasons.selectTheReason')}
            labelOn
            lowPlaceholder
            options={reportReasons}
            label={t('reportReasons.reason')}
            onChange={setReason}
            value={reason}
            height="50px"
            isSearchable={false}
          />

          <TextArea
            placeholder={t('reportReasons.reportTextAreaInfo')}
            innerRef={editorRef}
            defaultValue=""
            height="100px"
          />
        </div>
        <div className="modal-footer flex-column justify-content-center mb20">
          <Button onClick={submitReport} disabled={!reason}>
            <span><Trans ns="friendlyMatches" i18nKey="report">Report</Trans></span>
          </Button>

          <Button outline onClick={() => setIsModalVisible(false)} modalButton>
            <span><Trans ns="friendlyMatches" i18nKey="cancel">Cancel</Trans></span>
          </Button>
        </div>
      </Modal>

      {isReportConfirmed && (
        <Modal
          isOpen={isReportConfirmed}
          onRequestClose={() => setIsReportConfirmed(false)}
          style={{ width: 400 }}
          showCloseIcon={false}
        >
          <div className="modal-content text-center">
            <img src={LoadImage('modal-check-icon.svg')} alt="" width={50} height={50} />
            <Paragraph large bold>
              <Trans ns="friendlyMatches" i18nKey="reportedMatch">You reported this match</Trans>
            </Paragraph>
            <Paragraph className="mb40">
              <Trans ns="friendlyMatches" i18nKey="matchId">
                Match ID:
                {{ matchId: formatMatchId(matchId) }}
              </Trans>
            </Paragraph>
            <Paragraph className="">
              <Trans ns="friendlyMatches" i18nKey="reportedMatchDescription">
                We will review your report and get back to you if any information is needed.
              </Trans>
            </Paragraph>
          </div>
          <div className="modal-footer flex-column justify-content-center">
            <Button onClick={() => setIsReportConfirmed(false)} modalButton>
              <span><Trans ns="friendlyMatches" i18nKey="gotIt">Got it</Trans></span>
            </Button>
          </div>
        </Modal>
      )}

    </>
  );
};

export default ReportMatch;
