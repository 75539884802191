import styled from '@emotion/styled';
import { Link, P } from '../../../../components/Collection';

export const Container = styled.div(({ hasLoader }) => ({
  ...hasLoader && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  minHeight: '350px',
}));

export const TitlesContainer = styled.div(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  border: `1px solid ${border}`,
  borderRadius: '10px',
  overflow: 'hidden',
  margin: '0 0 20px 0',
}));

export const TitleHeader = styled.div(({ bg }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '60px',
  padding: '0 27px',
  ...bg && {
    background: bg,
  },
}));

export const TitleContent = styled.div({
  display: 'flex',
  padding: '20px 30px',
  '@media (max-width: 950px)': {
    flexDirection: 'column',
  },
});

export const TitleMatches = styled.div(({ addMarginTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  '@media (max-width: 950px)': {
    width: '100%',
    ...addMarginTop && {
      margin: '40px 0 0 0',
    },
  },
}));

export const TitleHeaderIcon = styled.img({
  margin: '0 10px 0 0',
});

export const Title = styled(P)({
  margin: '0 0 20px 0',
});

export const Matches = styled(Link)({
  color: '#78ADAB',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  margin: '0 0 10px 0',
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const Date = styled.span({
  color: '#10242C',
  '&:before': {
    content: '"•"',
    color: '#10242C',
    margin: '0 10px',
    textDecoration: 'none !important',
  },
});
