import styled from '@emotion/styled';

export const Icon = styled.img({
  width: '36px',
  height: '36px',
  float: 'left',
  marginRight: '7px',
});

export const Paragraph = styled.p`
  color: ${props => ({ theme }) => (props.color ? theme?.colors?.[props.color] : theme?.colors?.primary)};
  font-size: ${({ theme }) => theme?.functions?.toEm(16)};
  line-height: inherit;
  font-family: "DM Sans";
  font-weight: ${props => () => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => ({ theme }) => (props.larger ? theme.functions?.toEm(24) // larger
  : (props.large ? theme.functions?.toEm(20) // large
    : (props.medium ? theme.functions?.toEm(18) // medium
      : (props.small ? theme.functions?.toEm(14) // small
        : (props.smaller ? theme.functions?.toEm(12) // smaller
          : theme.functions?.toEm(16) // normal
        )
      )
    )
  ))
  };
  ${props => (props.margin ? `margin: ${props.margin};` : '5px 0')};

  @media (min-width: 520px) {
    margin-left: ${props => (props.noMarginLeft ? '0px' : '60px')};
  }
`;
