import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from '../../../../../../../components/Collection';
import LoadImage from '../../../../../../../components/common/LoadImage';
import { MyProfileButton, MyProfileOptionsContainer } from '../styles';

const MyProfileOptions = () => (
  <MyProfileOptionsContainer>
    <MyProfileButton margin="0 10px 0 0">
      <Link to="/my-account/events">
        <img src={LoadImage('tennis-racket-icon.svg')} alt="My events" />
        <Trans ns="header" i18nKey="menu.myEvents">
          My Events
        </Trans>
      </Link>
    </MyProfileButton>
    <MyProfileButton>
      <Link to="/my-account/settings">
        <img src={LoadImage('cog-icon.svg')} alt="My settings" />
        <Trans ns="header" i18nKey="menu.mySettings">
          My Settings
        </Trans>
      </Link>
    </MyProfileButton>
  </MyProfileOptionsContainer>
);

export default MyProfileOptions;
