import styled from '@emotion/styled';

export const Container = styled.div({
  margin: '0px 0px 10px 0px',
  position: 'relative',
  height: '150px',
  background: '#10242C',
  borderRadius: '10px',
  padding: '0 30px',
});

export const SearchBar = styled.input({
  width: '100%',
  height: '50px',
  border: '1px solid #C1E6E5',
  borderRadius: '4px',
  padding: '20px 40px',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#10242C',
  ':focus': {
    outline: 'none',
    outlineWidth: 0,
  },
  '::placeholder': {
    textOverflow: 'ellipsis',
    opacity: '100%',
    color: '#10242C',
  },
});

export const SearchResults = styled.div(({ customTop }) => ({
  position: 'absolute',
  top: customTop || '125px',
  left: 0,
  maxHeight: '319px',
  height: 'max-content',
  width: '100%',
  padding: '23px 15px',
  background: '#fff',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  zIndex: 1,
  overflow: 'auto',
}));

export const ClearResults = styled.span(({ isOnFriendlyMatchesCreateMatches }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  right: '10px',
  top: '93px',
  transform: 'translate(0, -50%)',
  cursor: 'pointer',
  img: {
    marginRight: '5px',
  },
  '@media (max-width: 483px)': {
    top: isOnFriendlyMatchesCreateMatches ? '25px' : '107px',
  },
}));

export const SearchIcon = styled.img({
  position: 'absolute',
  height: '20px',
  width: '20px',
  left: '10px',
  top: '63%',
  transform: 'translateY(-62%)',
  pointerEvents: 'none',
  '@media (max-width: 483px)': {
    top: '72%',
  },
});

export const ContainerPlayers = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #C1E6E5',
  borderRadius: '10px',
  padding: '20px 40px 20px 40px',
  margin: '0 0 40px 0',
  '@media (max-width: 650px)': {
    height: 'unset',
  },
});

export const PlayerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  '@media (max-width: 650px)': {
    flexDirection: 'column',
  },
});

export const Player = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: {
    margin: 'unset',
  },
  '.change': {
    margin: '10px 0 0 0',
    color: '#78ADAB',
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
  '.you': {
    margin: '10px 0 0 0',
    color: '#78ADAB',
  },
  '.id': {
    color: '#78ADAB',
    fontWeight: 400,
  },
  '@media (max-width: 650px)': {
    margin: '20px 0',
  },
});

export const AvatarContainer = styled.div({
  position: 'relative',
  width: '80px',
  height: '80px',
  margin: '0 0 12px 0',
  '.badge': {
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export const Avatar = styled.img({
  height: '80px',
  width: '80px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '50%',
});

export const Initials = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80px',
  width: '80px',
  borderRadius: '50%',
  border: '1px solid #000',
});
