import styled from '@emotion/styled';

export const ModalContainer = styled.div(({ theme }) => ({
  position: 'relative',

  ".modal-header": {
    textAlign: "left !important",
    paddingLeft: "0 !important",
    marginBottom: "0 !important",

    span: {
      marginLeft: -15,
    },
  },

  ".modal-content": {
    // overflow: "inherit",
    // maxHeight: "fit-content",

    ".form-group": {
      marginBottom: 10,
    },
  },

  ".modal-footer": {
    display: "flex",
    flexDirection: "column",
  },

  ".overview": {
    paddingTop: 20,
    borderTop: `1px solid ${theme.colors.lightTurquoise}`,
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    gap: 10,

    li: {
      display: "flex",
      justifyContent: "space-between",

      "&.match-players": {
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
      },

      ".link": {
        color: theme.colors.darkTurquoise,
        textDecoration: "underline",
        cursor: "pointer",
      },

      ul: {
        listStyle: "none",
        display: "flex",
        gap: 10,
      },
    },
  },

  ".pricing": {
    listStyle: "none",

    li: {
      borderRadius: 4,
      border: `1px solid ${theme.colors.lightTurquoise}`,
      marginBottom: 10,

      label: {
        padding: "14px 20px",
      },

      "&.is-disabled": {
        opacity: 0.4,
        cursor: "not-allowed",

        input: {
          cursor: "not-allowed",
        },
      },

      "> div": {
        marginBottom: 0,

        span: {
          top: 13,
          left: 15,
        },
      },
    },

    label: {
      width: "100%",

      p: {
        margin: 0,
      },
    },
  },
}));
